import { connect } from 'react-redux';

import WorkflowRolesSelection from 'components/system/WorkflowRolesSelection';
import WorkflowContactSelection from 'components/system/WorkflowContactSelection';
import ReactSelect from 'components/functional/inputs/ReactSelect';
import SearchCollections from "components/system/Search/Collections";

import timeValues from '../../_helpers/timeValues'

const ActionSendForm = ({ node, onInputChange, selected_division }) => {
    return (
        <div>
    
            <div className='sidebar-section border-bottom'>

                <SearchCollections
                    collection={'event_templates'}
                    title="**Select The Event To Create"
                    placeholder="Search..."
                    value={node.data.event_template}
                    onChange={(obj) => onInputChange('event_template', obj.value)}
                    filter={{
                        division: selected_division._id,
                    }}
                /> 

            </div>
    
            <div className='sidebar-section border-bottom'>
                <ReactSelect
                    title={"**Event Date"}
                    description="The amount of time from when this item is created until when the event will show on the calendar."
                    formGroup={true}
                    placeholder=""
                    onChange={(obj) => onInputChange('unix_start', obj.value)}
                    options={timeValues}
                    value={node.data.unix_start}
                />    
            </div>

            <div className='sidebar-section border-bottom'>
                <h2>Originating User</h2>
                <WorkflowRolesSelection 
                    roles={node.data.from} 
                    onChange={(from) => onInputChange('from', from)} 
                    multiSelect={false}
                />
            </div>
            
            <div className='sidebar-section border-bottom'>
                <h2>Additionally Invited Users</h2>
                <WorkflowRolesSelection 
                    roles={node.data.users} 
                    onChange={(users) => onInputChange('users', users)} 
                    multiSelect={true}
                />
            </div>

            <div className='sidebar-section border-bottom'>
                <h2>Added Contacts</h2>
                <WorkflowContactSelection 
                    contacts={node.data.workflow_contacts} 
                    onChange={(workflow_contacts) => onInputChange('workflow_contacts', workflow_contacts)} 
                />
            </div>


            <div className='sidebar-section border-bottom'>
                <p className='text-sm'>
                    <i className="fas fa-info-circle text-info" /> This action creates an event scheduled at a set interval after the action runs. 
                </p>
                <p className='text-sm mb-0'>
                    To create an event based on a date found within a document, email, text message, etc. you will need to run an assistant to do so instead of creating one here through an action.
                </p>
            </div>
    
        </div>
    )
}



const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(ActionSendForm);