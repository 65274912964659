import LocationsEdit from './components/Edit'
import LocationsAll from './All'

import IntuideskSectionWrapper from 'components/markup/layout/IntuideskSectionWrapper'

const SystemLocations = ({match, section}) => (
    <IntuideskSectionWrapper 
        title="Blocked"
        editTitle="Blocked Numbers"
        match={match}
        section={section}
        innerSection={'call_blocked_numbers'}
        Edit={LocationsEdit}
        All={LocationsAll}
    />
)

export default SystemLocations;