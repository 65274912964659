import { connect } from 'react-redux';
import ReactSelect from 'components/functional/inputs/ReactSelect';

const SettingsTimeZone = ({title, description, timezone, setTimezone, timezones}) => (
    <ReactSelect 
        formGroup={true}
        title={title ? title : "Timezone"}
        description={description ? description : ''}
        value={timezone}
        onChange={(obj) => setTimezone(obj.value)}
        options={timezones.map(tz => {
            return { value: tz.code, label: tz.name }
        })}
    />

)

const mapStateToProps = state => {
    return {
        timezones: state.config.TIMEZONES,
    };
};

export default connect(mapStateToProps, '')(SettingsTimeZone);