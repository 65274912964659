import ReactSelect from 'components/functional/inputs/ReactSelect';
import { useCallback } from 'react';
import { connect } from 'react-redux';
import { Badge } from 'reactstrap';

const WorkflowSidebarNotifications = ({ stepToEdit, onRemoveArrayField, onAddArrayField, onSetStepToEdit, setUnsavedChanges, roles }) => {

    const onChange = useCallback((field, value, remove) => {

            let _notifications = JSON.parse(JSON.stringify(stepToEdit.notifications));

            if(!_notifications[field].includes(value)) {
                _notifications[field].push(value);
            } else if(remove) {
                _notifications[field] = _notifications[field].filter(a => a !== value)
            }

            onSetStepToEdit('notifications', _notifications);
            setUnsavedChanges(true)

    }, [stepToEdit.notifications])
   
    const renderRoleName = useCallback((_id) => {
        const role = roles.find(r => r._id === _id)  
        return role ? role.name : ''
    }, [roles])

    return (
        <div>
    
            <h2 className='text-warning'>Notifications</h2>
            <p className='text-sm '>The following categories can be assigned notifications upon different events that may happen. Assign the roles you would like to get notifications to them below.</p>

            <hr />

            <h3 className='text-left text-uppercase'>Document Events</h3>

            <ReactSelect 
                formGroup={true}
                title="Document Uploaded"
                value={""}
                onChange={(obj) => onChange('document_uploaded', obj.value)}
                options={roles.filter(r => !stepToEdit.notifications.document_uploaded.includes(r._id)).map((role, i) => {
                    return { value: role._id, label: role.name }
                })}
            />

            {stepToEdit.notifications.document_uploaded.length ? stepToEdit.notifications.document_uploaded.map((role, i) => (
                <Badge key={role} color="success" className="ml-0 mb-2 text-left mr-3">
                    {renderRoleName(role)}
                    <span className="pr-2 pl-3 cursor-pointer float-right" onClick={() => onChange('document_uploaded', role, true)}>
                        <i className="fas fa-times " />
                    </span>
                </Badge>
            )) : null}
            
            <ReactSelect 
                formGroup={true}
                title="Document Expired"
                value={""}
                onChange={(obj) => onChange('document_expired', obj.value)}
                options={roles.filter(r => !stepToEdit.notifications.document_expired.includes(r._id)).map((role, i) => {
                    return { value: role._id, label: role.name }
                })}
            />

            {stepToEdit.notifications.document_expired.length ? stepToEdit.notifications.document_expired.map((role, i) => (
                <Badge key={role} color="success" className="ml-0 mb-2 text-left mr-3">
                    {renderRoleName(role)}
                    <span className="pr-2 pl-3 cursor-pointer float-right" onClick={() => onChange('document_expired', role, true)}>
                        <i className="fas fa-times " />
                    </span>
                </Badge>
            )) : null}
           
            <ReactSelect 
                formGroup={true}
                title="Document Category Updated"
                value={""}
                onChange={(obj) => onChange('document_category_updated', obj.value)}
                options={roles.filter(r => !stepToEdit.notifications.document_category_updated.includes(r._id)).map((role, i) => {
                    return { value: role._id, label: role.name }
                })}
            />

            {stepToEdit.notifications.document_category_updated.length ? stepToEdit.notifications.document_category_updated.map((role, i) => (
                <Badge key={role} color="success" className="ml-0 mb-2 text-left mr-3">
                    {renderRoleName(role)}
                    <span className="pr-2 pl-3 cursor-pointer float-right" onClick={() => onChange('document_category_updated', role, true)}>
                        <i className="fas fa-times " />
                    </span>
                </Badge>
            )) : null}
          
            <ReactSelect 
                formGroup={true}
                title="Document Sent For Signature"
                value={""}
                onChange={(obj) => onChange('document_sent', obj.value)}
                options={roles.filter(r => !stepToEdit.notifications.document_sent.includes(r._id)).map((role, i) => {
                    return { value: role._id, label: role.name }
                })}
            />

            {stepToEdit.notifications.document_sent.length ? stepToEdit.notifications.document_sent.map((role, i) => (
                <Badge key={role} color="success" className="ml-0 mb-2 text-left mr-3">
                    {renderRoleName(role)}
                    <span className="pr-2 pl-3 cursor-pointer float-right" onClick={() => onChange('document_sent', role, true)}>
                        <i className="fas fa-times " />
                    </span>
                </Badge>
            )) : null}
           
            <ReactSelect 
                formGroup={true}
                title="Document Signed"
                value={""}
                onChange={(obj) => onChange('document_signed', obj.value)}
                options={roles.filter(r => !stepToEdit.notifications.document_signed.includes(r._id)).map((role, i) => {
                    return { value: role._id, label: role.name }
                })}
            />

            {stepToEdit.notifications.document_signed.length ? stepToEdit.notifications.document_signed.map((role, i) => (
                <Badge key={role} color="success" className="ml-0 mb-2 text-left mr-3">
                    {renderRoleName(role)}
                    <span className="pr-2 pl-3 cursor-pointer float-right" onClick={() => onChange('document_signed', role, true)}>
                        <i className="fas fa-times " />
                    </span>
                </Badge>
            )) : null}
           
            <hr />
            
            <h3 className='text-left text-uppercase'>Phone Events</h3>

            <ReactSelect 
                formGroup={true}
                title="Incoming Call"
                value={""}
                onChange={(obj) => onChange('incoming_call', obj.value)}
                options={roles.filter(r => !stepToEdit.notifications.incoming_call.includes(r._id)).map((role, i) => {
                    return { value: role._id, label: role.name }
                })}
            />

            {stepToEdit.notifications.incoming_call.length ? stepToEdit.notifications.incoming_call.map((role, i) => (
                <Badge key={role} color="success" className="ml-0 mb-2 text-left mr-3">
                    {renderRoleName(role)}
                    <span className="pr-2 pl-3 cursor-pointer float-right" onClick={() => onChange('incoming_call', role, true)}>
                        <i className="fas fa-times " />
                    </span>
                </Badge>
            )) : null}
           
            <ReactSelect 
                formGroup={true}
                title="Incoming Text"
                value={""}
                onChange={(obj) => onChange('incoming_text', obj.value)}
                options={roles.filter(r => !stepToEdit.notifications.incoming_text.includes(r._id)).map((role, i) => {
                    return { value: role._id, label: role.name }
                })}
            />

            {stepToEdit.notifications.incoming_text.length ? stepToEdit.notifications.incoming_text.map((role, i) => (
                <Badge key={role} color="success" className="ml-0 mb-2 text-left mr-3">
                    {renderRoleName(role)}
                    <span className="pr-2 pl-3 cursor-pointer float-right" onClick={() => onChange('incoming_text', role, true)}>
                        <i className="fas fa-times " />
                    </span>
                </Badge>
            )) : null}
          
            <ReactSelect 
                formGroup={true}
                title="Outgoing Call"
                value={""}
                onChange={(obj) => onChange('outgoing_call', obj.value)}
                options={roles.filter(r => !stepToEdit.notifications.outgoing_call.includes(r._id)).map((role, i) => {
                    return { value: role._id, label: role.name }
                })}
            />

            {stepToEdit.notifications.outgoing_call.length ? stepToEdit.notifications.outgoing_call.map((role, i) => (
                <Badge key={role} color="success" className="ml-0 mb-2 text-left mr-3">
                    {renderRoleName(role)}
                    <span className="pr-2 pl-3 cursor-pointer float-right" onClick={() => onChange('outgoing_call', role, true)}>
                        <i className="fas fa-times " />
                    </span>
                </Badge>
            )) : null}
           
            <ReactSelect 
                formGroup={true}
                title="Outgoing Text"
                value={""}
                onChange={(obj) => onChange('outgoing_text', obj.value)}
                options={roles.filter(r => !stepToEdit.notifications.outgoing_text.includes(r._id)).map((role, i) => {
                    return { value: role._id, label: role.name }
                })}
            />

            {stepToEdit.notifications.outgoing_text.length ? stepToEdit.notifications.outgoing_text.map((role, i) => (
                <Badge key={role} color="success" className="ml-0 mb-2 text-left mr-3">
                    {renderRoleName(role)}
                    <span className="pr-2 pl-3 cursor-pointer float-right" onClick={() => onChange('outgoing_text', role, true)}>
                        <i className="fas fa-times " />
                    </span>
                </Badge>
            )) : null}

            <hr />

            <h3 className='text-left text-uppercase'>Email Events</h3>

          
            <ReactSelect 
                formGroup={true}
                title="Incoming Email"
                value={""}
                onChange={(obj) => onChange('incoming_email', obj.value)}
                options={roles.filter(r => !stepToEdit.notifications.incoming_email.includes(r._id)).map((role, i) => {
                    return { value: role._id, label: role.name }
                })}
            />

            {stepToEdit.notifications.incoming_email.length ? stepToEdit.notifications.incoming_email.map((role, i) => (
                <Badge key={role} color="success" className="ml-0 mb-2 text-left mr-3">
                    {renderRoleName(role)}
                    <span className="pr-2 pl-3 cursor-pointer float-right" onClick={() => onChange('incoming_email', role, true)}>
                        <i className="fas fa-times " />
                    </span>
                </Badge>
            )) : null}
           
            <ReactSelect 
                formGroup={true}
                title="Outgoing Email"
                value={""}
                onChange={(obj) => onChange('outgoing_email', obj.value)}
                options={roles.filter(r => !stepToEdit.notifications.outgoing_email.includes(r._id)).map((role, i) => {
                    return { value: role._id, label: role.name }
                })}
            />

            {stepToEdit.notifications.outgoing_email.length ? stepToEdit.notifications.outgoing_email.map((role, i) => (
                <Badge key={role} color="success" className="ml-0 mb-2 text-left mr-3">
                    {renderRoleName(role)}
                    <span className="pr-2 pl-3 cursor-pointer float-right" onClick={() => onChange('outgoing_email', role, true)}>
                        <i className="fas fa-times " />
                    </span>
                </Badge>
            )) : null}
          
            <hr />

            <h3 className='text-left text-uppercase'>Workflow Events</h3>
          
            <ReactSelect 
                formGroup={true}
                title="Step Moved"
                description="This notifies the roles below when this step is moved to."
                value={""}
                onChange={(obj) => onChange('step_moved', obj.value)}
                options={roles.filter(r => !stepToEdit.notifications.step_moved.includes(r._id)).map((role, i) => {
                    return { value: role._id, label: role.name }
                })}
            />

            {stepToEdit.notifications.step_moved.length ? stepToEdit.notifications.step_moved.map((role, i) => (
                <Badge key={role} color="success" className="ml-0 mb-2 text-left mr-3">
                    {renderRoleName(role)}
                    <span className="pr-2 pl-3 cursor-pointer float-right" onClick={() => onChange('step_moved', role, true)}>
                        <i className="fas fa-times " />
                    </span>
                </Badge>
            )) : null}
          
            <ReactSelect 
                formGroup={true}
                title="Task Completed"
                value={""}
                onChange={(obj) => onChange('task_completed', obj.value)}
                options={roles.filter(r => !stepToEdit.notifications.task_completed.includes(r._id)).map((role, i) => {
                    return { value: role._id, label: role.name }
                })}
            />

            {stepToEdit.notifications.task_completed.length ? stepToEdit.notifications.task_completed.map((role, i) => (
                <Badge key={role} color="success" className="ml-0 mb-2 text-left mr-3">
                    {renderRoleName(role)}
                    <span className="pr-2 pl-3 cursor-pointer float-right" onClick={() => onChange('task_completed', role, true)}>
                        <i className="fas fa-times " />
                    </span>
                </Badge>
            )) : null}
           
            <ReactSelect 
                formGroup={true}
                title="Form Completed"
                value={""}
                onChange={(obj) => onChange('form_completed', obj.value)}
                options={roles.filter(r => !stepToEdit.notifications.form_completed.includes(r._id)).map((role, i) => {
                    return { value: role._id, label: role.name }
                })}
            />

            {stepToEdit.notifications.form_completed.length ? stepToEdit.notifications.form_completed.map((role, i) => (
                <Badge key={role} color="success" className="ml-0 mb-2 text-left mr-3">
                    {renderRoleName(role)}
                    <span className="pr-2 pl-3 cursor-pointer float-right" onClick={() => onChange('form_completed', role, true)}>
                        <i className="fas fa-times " />
                    </span>
                </Badge>
            )) : null}

        </div>
    )
}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(WorkflowSidebarNotifications);