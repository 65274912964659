import React, { useCallback, useState, useEffect } from 'react';

import ReactSelect from 'components/functional/inputs/ReactSelect';

import { connect } from 'react-redux';

import CompleteOutstandingItems from './CompleteOutstandingItems';
import CompleteTask from './CompleteTask';
import SendForm from './SendForm';
import SendDocumentForSignature from './SendDocumentForSignature';
import MoveStep from './MoveStep';
import CreateOutstandingItem from './CreateOutstandingItem';
import CreateEvent from './CreateEvent';
import CreateTask from './CreateTask';
import SendEmail from './SendEmail';
import SendText from './SendText';

const SidebarRightAction = ({ selectedNode, onNodeUpdated }) => {

    const [node, setNode] = useState(selectedNode)

    const onInputChange = useCallback((field, value) => {
        const n = JSON.parse(JSON.stringify(node));
        n.data[field] = value;
        setNode(n)
    }, [node])

    useEffect(() => {
        setNode(JSON.parse(JSON.stringify(selectedNode)))
    }, [selectedNode])
   
    return (
        <div>

            <div className='sidebar-section border-bottom'>
                <div className="sidebar-item pb-0"><h2>Perform Action</h2></div>

            </div>

            <div className='sidebar-section border-bottom bg-secondary'>
                <ReactSelect
                    title="**Select Action"
                    formGroup={true}
                    onChange={(obj) => onInputChange('type', obj.value)}
                    options={[
                        { label: 'Send Email', value: 'send_email' },
                        { label: 'Send Text Message', value: 'send_text' },
                        { label: 'Create Task', value: 'create_task' },
                        { label: 'Create Outstanding Item', value: 'create_outstanding_item' },
                        { label: 'Create Event', value: 'create_event' },
                        // { label: 'Create Deadline(s)', value: 'create_deadline' },
                        { label: 'Move Step', value: 'move_step' },
                        { label: 'Send Document For Signature', value: 'send_document_for_signature' },
                        { label: 'Send Form Request', value: 'send_form' },
                        { label: 'Complete Task', value: 'complete_task' },
                        { label: 'Complete Outstanding Items', value: 'complete_outstanding_items' },
                    ]}
                    value={node.data.type}
                />    
            </div>

            {node.data.type === 'complete_outstanding_items' ? (

                <CompleteOutstandingItems 
                    node={node} 
                    onInputChange={onInputChange} 
                />

            ) : node.data.type === 'complete_task' ? (

                <CompleteTask 
                    node={node} 
                    onInputChange={onInputChange} 
                />

            ) : node.data.type === 'send_form' ? (

                <SendForm 
                    node={node} 
                    onInputChange={onInputChange} 
                />

            ) : node.data.type === 'send_document_for_signature' ? (

                <SendDocumentForSignature 
                    node={node} 
                    onInputChange={onInputChange} 
                />

            ) : node.data.type === 'move_step' ? (

                <MoveStep 
                    node={node} 
                    onInputChange={onInputChange} 
                />

            ) : node.data.type === 'create_outstanding_item' ? (

                <CreateOutstandingItem 
                    node={node} 
                    onInputChange={onInputChange} 
                />

            ) : node.data.type === 'create_event' ? (

                <CreateEvent 
                    node={node} 
                    onInputChange={onInputChange} 
                />

            ) : node.data.type === 'create_task' ? (

                <CreateTask 
                    node={node} 
                    onInputChange={onInputChange} 
                />

            ) : node.data.type === 'send_email' ? (

                <SendEmail 
                    node={node} 
                    onInputChange={onInputChange} 
                />

            ) : node.data.type === 'send_text' ? (

                <SendText 
                    node={node} 
                    onInputChange={onInputChange} 
                />

            ) : ''}

            <div className='sidebar-section text-right'>
                <button onClick={() => onNodeUpdated(node)} className='btn btn-success'>Save Node</button>
            </div>

        </div>

    )

}



const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SidebarRightAction);