import { useCallback } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from "reactstrap";


import SearchCollections from 'components/system/Search/Collections';
import ObjectFinder from 'components/system/Objects/Finder';

const SystemEventEdit = ({eventTemplate, setEventTemplate, selected_division}) => {

    const onAddNotification = useCallback((outcome) => {
        const newEventTemplate = JSON.parse(JSON.stringify(eventTemplate))

        const event_outcomes = newEventTemplate.event_outcomes ? newEventTemplate.event_outcomes : []
        event_outcomes.push(outcome);
        
        newEventTemplate.event_outcomes = event_outcomes;
        setEventTemplate(newEventTemplate)

    }, [eventTemplate, setEventTemplate])
   
    const onRemoveOutcome = useCallback((index) => {
        const newEventTemplate = JSON.parse(JSON.stringify(eventTemplate))

        const notification = newEventTemplate.event_outcomes
        notification.splice(index, 1)

        newEventTemplate.event_outcomes = notification;
        setEventTemplate(newEventTemplate)
    }, [eventTemplate, setEventTemplate])

    return (
        <div>

            <Row>
                <Col lg={5}>
                    <h2>Selected Outcomes</h2>

                    <SearchCollections
                        collection="event_outcomes" 
                        title="Outcomes will show up in the order they appear on this page."
                        hideSelected={true}

                        onChange={(obj) => onAddNotification(obj.value)}
                        filter={{
                            division: selected_division._id
                        }}
                    /> 
                </Col>

                <Col lg={7}>
                    <div>
                        <h2>Possible Outcomes</h2>

                        <div className="table-responsive border">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Outcome</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {eventTemplate.event_outcomes && eventTemplate.event_outcomes.length ? eventTemplate.event_outcomes.map((outcome, i) => (
                                        <tr key={outcome}>
                                            <td className="text-capitalize">
                                                <ObjectFinder collection="event_outcomes" _id={outcome} />
                                            </td>
                                                
                                            <td className='text-right'>
                                                <button onClick={() => onRemoveOutcome(i)} className="btn btn-danger">Remove</button>
                                            </td>
                                        </tr>
                                    )) : (
                                        <tr>
                                            <td><i className="fas fa-info-circle mr-2 text-info" /> No Outcomes Selected</td>
                                            <td></td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </Col>
            </Row>
          
        </div>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SystemEventEdit);