import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Modal, FormGroup, Input, Row, Col, UncontrolledTooltip } from "reactstrap";

import SendEmailsTo from './SendEmailsTo';

import { addArchkAlert } from 'store/functions/system/system'

import Associations from 'components/system/Associations'

const ModalSettings = ({showModal, toggleModal, onSetForm, form, config}) => {
    const [state, setState] = useState(JSON.parse(JSON.stringify(form)));
    const [errors, setErrors] = useState([]);

    const onSetState = (field, e) => {
        const newState = JSON.parse(JSON.stringify(state));
        let value = e.target ? e.target.value : e;

        if(field === 'name' && value && value.length > config.FORM_MAX_NAME_LENGTH) {
            value = value.slice(0, config.FORM_MAX_NAME_LENGTH)
        }

        newState[field] = value;
        setState(newState);
    }

    const onSave = useCallback(async (state) => {

        const errs = [];

        if(!state.name) errs.push('name');

        setErrors(errs);

        if(errs.length) return;

        onSetForm(state);
        toggleModal();
        addArchkAlert({type: 'success', body: `Form Settings Updated.`})

    }, [onSetForm, toggleModal])

    useEffect(() => {
        if(showModal) {
            setState(JSON.parse(JSON.stringify(form)))
        }
    }, [showModal, form])

    return (
        <Modal
            className="modal-dialog-centered page-form-modal"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">Form Settings</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">

                <FormGroup>
                    <label className="form-control-label">
                        Form Name* {' '}
                        <i id="archk-icon-form-name" className="fas fa-info-circle text-info ml-2 mr-2" /> {' '}
                        <UncontrolledTooltip
                            delay={0}
                            trigger="hover focus"
                            target="archk-icon-form-name"
                        >
                            {`This name is how you identify the form and will be shown to clients filling it out.`}
                        </UncontrolledTooltip>
                        {' '}
                        {state.name && state.name.length >= config.FORM_MAX_NAME_LENGTH ? (
                            `(A form's name is limited to ${config.FORM_MAX_NAME_LENGTH} characters)`
                        ) : null}
                    </label>
                    
                    <Input 
                        type="text"
                        value={state.name}
                        onChange={(e) => onSetState('name', e)}
                        invalid={errors.includes('name')}
                    />
                    
                </FormGroup>

                <Row>
                    <Col lg={6}>
                        <FormGroup>
                            <label className="form-control-label">
                                Clear On Submit* {' '}
                                <i id="archk-icon-form-clear" className="fas fa-info-circle text-info ml-2 mr-2" /> {' '}
                                <UncontrolledTooltip
                                    delay={0}
                                    trigger="hover focus"
                                    target="archk-icon-form-clear"
                                >
                                    {`If set to "Yes" this form will erase all answers when it is submitted.`}
                                </UncontrolledTooltip>
                            </label>
                            <Input 
                                type="select"
                                value={state.clear_on_submit === 'true' || state.clear_on_submit === true ? 'true' : 'false'}
                                onChange={(e) => onSetState('clear_on_submit', e.target.value === 'true' || e.target.value === true ? true : false)}
                                invalid={errors.includes('clear_on_submit')}
                            >
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </Input>
                        </FormGroup>
                    </Col>

                    <Col lg={6}>
                        <FormGroup>
                            <label className="form-control-label">
                                Is Active* {' '}
                                <i id="archk-icon-form-active" className="fas fa-info-circle text-info ml-2 mr-2" /> {' '}
                                <UncontrolledTooltip
                                    delay={0}
                                    trigger="hover focus"
                                    target="archk-icon-form-active"
                                >
                                    {`If the form is not active contacts will not be allowed to open or submit it.`}
                                </UncontrolledTooltip>
                            </label>
                            <Input 
                                type="select"
                                value={state.active === 'true' || state.active === true ? 'true' : 'false'}
                                onChange={(e) => onSetState('active', e.target.value === 'true' || e.target.value === true ? true : false)}
                                invalid={errors.includes('active')}
                            >
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>

                <FormGroup>
                    <label className="form-control-label">
                        Redirect URL*{' '}
                        <i id="archk-icon-form-redirect" className="fas fa-info-circle text-info ml-2 mr-2" /> {' '}
                        <UncontrolledTooltip
                            delay={0}
                            trigger="hover focus"
                            target="archk-icon-form-redirect"
                        >
                            {`If a redirect URL is specified, upon completing this form the client will be redirected to this link.`}
                        </UncontrolledTooltip>
                    </label>
                    <Input 
                        type="text"
                        value={state.redirect_url}
                        onChange={(e) => onSetState('redirect_url', e.target.value)}
                        invalid={errors.includes('redirect_url')}
                    />
                </FormGroup>

            </div>

          
            <div className="modal-body border-top">
                <SendEmailsTo 
                    answers={state.send_emails_to}
                    onChange={(e) => onSetState('send_emails_to', e)}
                    hasErr={false}
                    config={config}
                />
            </div>

            {state._id ? (
                 <div className="modal-body bg-secondary border-top">
                    <Associations 
                        type="form" 
                        _id={state._id} 
                        classNames="border p-3 bg-secondary" 
                    />
                 </div>
            ) : ''}

            <div className="modal-footer text-right">
                <button className="btn btn-success" onClick={() => onSave(state)}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            </div>

        </Modal>

    )

}

ModalSettings.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}

export default ModalSettings
