import { Input, FormGroup, Row, Col } from "reactstrap";
import { connect } from 'react-redux';
import { useCallback, useState, useEffect } from 'react';

import api from 'api'

import Modalify from 'components/functional/modals/Modalify'

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';
import { setCompany as refreshCompany } from 'store/functions/company/company'

const CompanyUpdateNames = ({onSaved, showModal, toggleModal, viewing_user}) => {

    const [company, setCompany] = useState(null)
    const [err, setErr] = useState(null)

    const fetchData = useCallback(async () => {
        const company = await api.companies.findById(viewing_user.company)
        if(!company.data) return setErr(true);
        setCompany(company.data)
    }, [viewing_user.company])

    const onInputChange = useCallback(( field, value) => {
        const newCompany = Object.assign({}, company)
        newCompany[field] = value;
        setCompany(newCompany);
    }, [company])

    const onSave = useCallback(async () => {
        setErr(false)
        let newState = Object.assign({}, company);

        if(!newState.name) {
            return setErr([`A company must have a name.`])
        }

        setCompany(newState)

        // toggle loaders and update/create the company
        toggleStandardLoader(true)
        let saved = await api.companies.update(newState._id, {
            name            : newState.name,
            display_name    : newState.display_name,
            legal_name      : newState.legal_name,
            contact_name    : newState.contact_name,
        }, true);
        toggleStandardLoader(false)

        // show error messages if unsuccessful
        if(saved.success) {
            toggleAlertBS(false, "Company Saved Successfully")
            fetchData();
            refreshCompany();
            if(toggleModal) toggleModal();
            if(onSaved) onSaved(saved.data)
        } else {
            return setErr(saved.message);
        }
    
    }, [company, fetchData, toggleModal, onSaved])

    useEffect(() => {
        if(toggleModal) {
            if(showModal) {
                fetchData()
            } else {
                setErr(null)
                setCompany('')
            }
        } else {
            fetchData()
        }
    }, [showModal, toggleModal, fetchData])

    return (
        <Modalify 
            showModal={showModal}
            toggleModal={toggleModal}
            modalTitle={company && company._id ? "Update Company" : "Create Company"}
            err={err}
            footer={(
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            )}
            sections={!company ? [] :  [
                (
                    <div>
                        <h2>Company Point Of Contact</h2>

                        <FormGroup>
                            <label className="form-control-label">First and Last Name*</label>
                            <Input 
                                type="text"
                                value={company.contact_name || ''}
                                onChange={e => onInputChange('contact_name', e.target.value)}
                            />
                        </FormGroup>

                    </div>
                ),
                (
                    <div>
                        <h2>Company Names</h2>

                        <FormGroup>
                            <label className="form-control-label">Internal Name*</label>
                            <p className="text-sm mb-0"><i className="fas fa-info-circle text-info"></i> This is the name of your company that will be shown <b className="text-underline">inside of IntuiDesk.</b> Example: ACME</p>
                            <Input 
                                type="text"
                                value={company.name || ''}
                                onChange={e => onInputChange('name', e.target.value)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <label className="form-control-label">Display Name*</label>
                            <p className="text-sm mb-0"><i className="fas fa-info-circle text-info"></i> This is the name of your company that will <b className="text-underline">show up for contacts.</b> Example: ACME Products</p>
                            <Input 
                                type="text"
                                value={company.display_name || ''}
                                onChange={e => onInputChange('display_name', e.target.value)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <label className="form-control-label">Legal Name*</label>
                            <p className="text-sm mb-0"><i className="fas fa-info-circle text-info"></i> This is the <b className="text-underline">registered name of your company.</b> Example: ACME Products, LLC</p>
                            <Input 
                                type="text"
                                value={company.legal_name || ''}
                                onChange={e => onInputChange('legal_name', e.target.value)}
                            />
                        </FormGroup>

                    </div>
                )
            ]}
        />
    )

}


const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(CompanyUpdateNames);