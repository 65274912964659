import { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap'

const ApiError = ({err, load}) => {

    const [privilegeErr, setPrivilegeErr] = useState(false);

    useEffect(() => {
        try {
            if(err && err.length) {
                const required = err.find(e => e && e.includes('required to access'))
                if(required) {
                    setPrivilegeErr(required)
                }
            }
        } catch(e) {}
    }, [err])

    return (
        <div className="bg-white border py-3 px-5 rounded archk-api-error">
            <Row>
                <div className="col-auto align-self-center" style={{width: 75}}>
                    <h2 className="display-4 mb-0">
                        {!privilegeErr && Array.isArray(err) ? (
                            <i className={`fas fa-info-circle mr-2 text-info`} />
                        ) : (
                            <i className={`fas fa-exclamation-triangle mr-2 text-${privilegeErr ? 'warning' : 'danger'}`} />
                        )}
                    </h2>
                </div>
                <Col className="align-self-center">
                    <p className="mb-0">
                        {privilegeErr ? (
                            <span className="text-capitalize">Your account does not have access to either view, update, or create this resource. {privilegeErr}</span>
                        ) : Array.isArray(err) ? (
                            err.map((e, i) => (
                                <p className="text-sm mb-0" key={i}>-{e && e._message ? e._message : e}</p>
                            ))
                        ) : (
                            <span>We are experiencing an internal error, please check back later or contact support if the problem persists: <span className="text-underline text-dark">{load.PLATFORM.EMAIL}</span></span>
                        )}</p>
                </Col>
            </Row>
          
        </div>
    )

}

const mapStateToProps = state => {
	return {
	    load: state.load,
	};
};

export default connect(mapStateToProps, '')(ApiError);
