import { Row, Col } from "reactstrap";
import { connect } from 'react-redux';
import { useCallback, useState } from 'react';

import Privilege  from "./Privilege";

const UsersEditPrivilegesDeveloper = ({user, setUser, onPrivilegeChange, PRIVILEGES}) => {

    const [standardPrivileges] = useState([
        PRIVILEGES['SYSTEM.WEBHOOKS.READ'],
        PRIVILEGES['SYSTEM.WEBHOOKS.WRITE'],
    ])

    const assignAll = useCallback(() => {
        const newUser = Object.assign({}, user)
        standardPrivileges.forEach(privilege => {
            if(!newUser.privileges.includes(privilege)) {
                newUser.privileges.push(privilege)
            }
        })
     
        setUser(newUser);
    }, [standardPrivileges, setUser, user])
   
    const removeAll = useCallback(() => {
        const newUser = Object.assign({}, user)
        standardPrivileges.forEach(privilege => {
            newUser.privileges = newUser.privileges.filter(p => p !== privilege)
        })
     
        setUser(newUser);
    }, [standardPrivileges, setUser, user])

    return (
        <div>

            <Row>
                <Col xs={6} className="align-self-center">
                    <h2>Developer Privileges</h2>
                </Col>
                <Col xs={6} className="align-self-center text-right">
                    <button onClick={removeAll} className="btn btn-outline-warning">Remove All</button>
                    <button onClick={assignAll} className="btn btn-outline-info">Assign All</button>
                </Col>
            </Row>

            <Row>
                <Col lg={6}>
                    
                    <h5>Webhooks</h5>
                    <Privilege 
                        privilege={PRIVILEGES['SYSTEM.WEBHOOKS.READ']}
                        user={user}
                        title="Can View System Webhooks"
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['SYSTEM.WEBHOOKS.WRITE']}
                        user={user}
                        title="Can Update / Create System Webhooks"
                        onPrivilegeChange={onPrivilegeChange}
                    />

                </Col>
                <Col lg={6}>


                </Col>
            </Row>

        </div>
    )

}


const mapStateToProps = state => {
	return {
	    PRIVILEGES: state.config.PRIVILEGES,
	};
};

export default connect(mapStateToProps, '')(UsersEditPrivilegesDeveloper);