import { connect } from 'react-redux';

import SearchCollections from 'components/system/Search/Collections';

import Category from './Category';
import CustomField from './CustomField';
import Disposition from './Disposition';


const SystemCallFlowEdit = ({callFlow, onInputChange, selected_division, onAddRule, onRemoveRule, workflows}) => {

    return (
        <div>

            <SearchCollections
                addNull={true} 
                collection="call_flows" 
                title="Route To Use When Closed"
                description="If assigned, this call flow will be used whenever a call is placed outside business hours. If no flow is selected and someone calls into this flow the number will play a short message and then hangup."
                value={callFlow.flow_closed}
                onChange={(obj) => onInputChange('flow_closed', obj.value)}
                filter={{
                    division: selected_division._id
                }}
            /> 
            <SearchCollections
                addNull={true} 
                collection="call_flows" 
                title="Route To Use When Caller Is Unknown"
                description={`If assigned, this call flow will be used whenever a "New / Unknown" contact calls in. This is primarily the case with new leads calling.`}
                value={callFlow.flow_unknown}
                onChange={(obj) => onInputChange('flow_unknown', obj.value)}
                filter={{
                    division: selected_division._id
                }}
            /> 

            <hr />

            <div className='p-3 bg-secondary border'>
                <p className='text-sm mb-'><i className="fas fa-info-circle text-info" /> The following rules will take affect in the order they are added below AFTER taking into account whether this flow is open or a contact is considered unknown based on the settings above. The rules work in a Most Specific to Least Specific to least specific way and the first item that matches will redirect the call to corresponding flow it is associated with. </p>
                <p className='text-sm mb-0'>First a check is done for matching custom fields, then for a specific disposition, and then a workflow category. Multiple rules can be setup for multiple categories, dispositions, etc. Note that if the contact calling in has multiple matters the matter that had it's last step moved most recently will be used.</p>
            </div>

            <hr />


            <CustomField 
                callFlow={callFlow}
                workflows={workflows}
                onInputChange={onInputChange}
            />

            <Disposition 
                callFlow={callFlow}
                workflows={workflows}
                onInputChange={onInputChange}
            />

            <Category 
                callFlow={callFlow}
                workflows={workflows}
                onInputChange={onInputChange}
            />
          
          

        </div>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	    company: state.company,
	};
};

export default connect(mapStateToProps, '')(SystemCallFlowEdit);