import IntuideskSection from "components/markup/layout/IntuideskSection";

const DevelopersView = ({match}) => (
    <IntuideskSection 
        title="Developers"
        section="developers"
        defaultRoute="users"
        match={match}
        pages={[
        ]}
    />
)

export default DevelopersView;