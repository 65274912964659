import { Row, Col, Input, FormGroup } from "reactstrap";
import { useCallback, useState, useEffect } from 'react';

import api from 'api'

import Modalify from 'components/functional/modals/Modalify'

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

const UsersPassword = ({_id, onSaved, showModal, toggleModal}) => {

    const [user, setUser] = useState(null)
    const [err, setErr] = useState(null)

    const fetchData = useCallback(async () => {
        const user = await api.users.findById(_id)
        if(!user.data) return setErr(true);
        setUser(user.data)
    }, [_id])

    const onInputChange = useCallback(( field, value) => {
        const newUser = Object.assign({}, user)
        newUser[field] = value;
        setUser(newUser);
    }, [user])

    const onSave = useCallback(async () => {

        setErr(false)
        let newState = Object.assign({}, user);

        if(!newState.given_name || !newState.family_name || !newState.email) {
            return setErr([`A user must have a first name, last name, and email address.`])
        }

        if(!newState._id) {
            if(!newState.password || !newState.passwordConfirm) {
                return setErr([`You must enter the password for this user before proceeding.`])
            }    
        }
        
        setUser(newState)

        // toggle loaders and update/create the user
        toggleStandardLoader(true)
        let saved = await api.auth.admin.updatePassword({ user: newState._id, password: newState.password, passwordConfirm: newState.passwordConfirm });
        toggleStandardLoader(false)

        // show error messages if unsuccessful
        if(saved.success) {
            toggleAlertBS(false, "User Saved Successfully")
            fetchData();
            if(toggleModal) toggleModal();
            if(onSaved) onSaved(saved.data)
        } else {
            return setErr(saved.message);
        }
    
    }, [user, fetchData, toggleModal, onSaved])

    useEffect(() => {
        if(toggleModal) {
            if(showModal) {
                fetchData()
            } else {
                setErr(null)
                setUser('')
            }
        } else {
            fetchData()
        }
    }, [showModal, toggleModal, fetchData])

    return (
        <Modalify 
            showModal={showModal}
            toggleModal={toggleModal}
            size="md"
            modalTitle={'Update Password'}
            err={err}
            footer={(
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            )}
            sections={!user ? [] :  [
                (
                    <h4>{user.display_name}</h4>
                ),
                ( 
                    <div>    
                        <Row>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Password*</label>
                                    <Input 
                                        type="password"
                                        value={user.password || ''}
                                        onChange={e => onInputChange('password', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Confirm Password*</label>
                                    <Input 
                                        type="password"
                                        value={user.passwordConfirm || ''}
                                        onChange={e => onInputChange('passwordConfirm', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                        </Row>
        
                    </div>
                )
            ]}
        />
    )

}

export default UsersPassword