import { Row, Col, Input, FormGroup } from "reactstrap";
import { connect } from 'react-redux';
import { useCallback, useState, useEffect } from 'react';

import api from 'api'

import Modalify from 'components/functional/modals/Modalify'

import { toggleStandardLoader } from 'store/functions/system/system';


import InputStates from 'components/markup/inputs/States'

import Associations from 'components/system/Associations'

const EmailSendersView = ({_id, onSaved, showModal, toggleModal, selected_division}) => {

    const [emailSender, setEmailSender] = useState(null)
    const [err, setErr] = useState(null)

    const fetchData = useCallback(async () => {
        if(!_id || _id === 'create') return setEmailSender({ privileges: [] });
        const user = await api.email_senders.findById(_id)
        if(!user.data) return setErr(true);
        setEmailSender(user.data)
    }, [_id])

    const onInputChange = useCallback(( field, value) => {
        const newEmailSender = Object.assign({}, emailSender)
        newEmailSender[field] = value;
        setEmailSender(newEmailSender);
    }, [emailSender])

    const onSave = useCallback(async () => {

        setErr(false)
        let newState = Object.assign({}, emailSender);

        if(
            !newState.name || 
            !newState.company_name || 
            !newState.website || 
            !newState.address_reply ||
            !newState.address_from ||
            !newState.address_line_1 ||
            !newState.city ||
            !newState.state ||
            !newState.postal_code
        ) {
            return setErr([`An email sender must have all required fields filled out.`])
        }
        
        setEmailSender(newState)

        let saved;

        // toggle loaders and update/create the user
        toggleStandardLoader(true)
        if(newState._id) {
            saved = await api.email_senders.update(newState._id, newState, true);
        } else {
            saved = await api.email_senders.create({...newState, division: selected_division._id}, true);
        }
        toggleStandardLoader(false)

        // show error messages if unsuccessful
        if(saved.success) {
            fetchData();
            if(toggleModal) toggleModal();
            if(onSaved) onSaved(saved.data)
        } else {
            return setErr(saved.message);
        }
    
    }, [emailSender, fetchData, toggleModal, onSaved, selected_division._id])

    useEffect(() => {
        if(toggleModal) {
            if(showModal) {
                fetchData()
            } else {
                setErr(null)
                setEmailSender('')
            }
        } else {
            fetchData()
        }
    }, [showModal, toggleModal, fetchData])

    return (
        <Modalify 
            showModal={showModal}
            toggleModal={toggleModal}
            modalTitle={emailSender && emailSender._id ? "Update Email Sender" : "Create Email Sender"}
            err={err}
            footer={(
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            )}
            sections={!emailSender ? [] :  [
                (
                    <div>

                        <h2>Name</h2>
        
                        <Row>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Sender Name*</label>
                                    <p className="text-sm mb-0">Shows as the name when sending an email</p>
                                    <Input 
                                        type="text"
                                        value={emailSender.name || ''}
                                        onChange={e => onInputChange('name', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Company Name*</label>
                                    <p className="text-sm mb-0">Shows in the footer of an email</p>
                                    <Input 
                                        type="text"
                                        value={emailSender.company_name || ''}
                                        onChange={e => onInputChange('company_name', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                ),
                (
                    <div>

                        <h2>Contact Info</h2>
                        <FormGroup>
                            <label className="form-control-label">Website*</label>
                            <p className="text-sm mb-0">Shows in the footer of an email, improves deliverability and lowers spam rate.</p>
                            <Input 
                                type="text"
                                value={emailSender.website || ''}
                                onChange={e => onInputChange('website', e.target.value)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <label className="form-control-label">Phone*</label>
                            <p className="text-sm mb-0">Shows in the footer of an email, improves deliverability and lowers spam rate.</p>
                            <Input 
                                type="text"
                                value={emailSender.phone || ''}
                                onChange={e => onInputChange('phone', e.target.value)}
                            />
                        </FormGroup>
                    </div>
                ),
                (
                    <div>

                        <h2>Email Headers</h2>
            
                        <Row>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Sending Email Address*</label>
                                    <Input 
                                        type="text"
                                        value={emailSender.address_from || ''}
                                        onChange={e => onInputChange('address_from', e.target.value)}
                                    />
                                </FormGroup>      
                            </Col>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Reply To Email Address*</label>
                                    <Input 
                                        type="text"
                                        value={emailSender.address_reply || ''}
                                        onChange={e => onInputChange('address_reply', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                        </Row>
                    </div>
                ),
                (
                    <div>

                        <h2>Email Sender Address</h2>
        
                        <Row>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Address Line 1*</label>
                                    <Input 
                                        type="text"
                                        value={emailSender.address_line_1 || ''}
                                        onChange={e => onInputChange('address_line_1', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Address Line 2</label>
                                    <Input 
                                        type="text"
                                        value={emailSender.address_line_2 || ''}
                                        onChange={e => onInputChange('address_line_2', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                        </Row>
        
                        <Row>
                            <Col lg={4}>
                                <FormGroup>
                                    <label className="form-control-label">City*</label>
                                    <Input 
                                        type="text"
                                        value={emailSender.city || ''}
                                        onChange={e => onInputChange('city', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                            <Col lg={4}>
                                <InputStates 
                                    value={emailSender.state}
                                    onChange={state => onInputChange('state', state)}
                                />
                            </Col>
                            <Col lg={4}>
                                <FormGroup>
                                    <label className="form-control-label">Zip*</label>
                                    <Input 
                                        type="text"
                                        value={emailSender.postal_code || ''}
                                        onChange={e => onInputChange('postal_code', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                        </Row>
                    </div>
                ),
                ...(emailSender._id ? [(
                    <Associations 
                        type="email_sender" 
                        _id={emailSender._id} 
                        classNames="border p-3 bg-secondary" 
                    />
        
                )] : []),
            ]}
        />
    )

}

const mapStateToProps = state => {
	return {
        selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(EmailSendersView);