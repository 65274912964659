
/*
Documentation

*** make sure to declare all action types in actions.js one folder level back

*/

import * as actionTypes from '../../actions';

const initialState = {
    open: false,
    matter: null,
    task: null,
    matter_document: null,
    url: '/v1/documents',
    onUpload: null
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.SET_DOCUMENT_CENTER:
            return {
                ...state,
                ...action.payload,
            }

        default: return state;

    }

}

export default reducer;
