import { Row, Col } from "reactstrap";
import { connect } from 'react-redux';
import { useCallback, useState } from 'react';

import Privilege  from "./Privilege";

const UsersEditPrivilegesStandard = ({user, setUser, onPrivilegeChange, PRIVILEGES}) => {

    const [standardPrivileges] = useState([
        PRIVILEGES['AUTOMATIONS.READ'],
        PRIVILEGES['AUTOMATION_ASSIGNMENTS.READ'],
        PRIVILEGES['AUTOMATION_ASSIGNMENTS.WRITE'],

        PRIVILEGES['BILLING.READ'],
        PRIVILEGES['BILLING.WRITE'],

        PRIVILEGES['CALL_CENTER.READ'],
        PRIVILEGES['CALL_CENTER.WRITE'],
        PRIVILEGES['CALLS.READ'],

        PRIVILEGES['CALL_PHONE_NUMBERS.READ'],
        PRIVILEGES['CALL_FLOWS.READ'],

        PRIVILEGES['COMPANIES.READ'],

        PRIVILEGES['CONTACTS.READ'],
        PRIVILEGES['CONTACTS.WRITE'],
        PRIVILEGES['CONTACT_TYPES.READ'],

        PRIVILEGES['CUSTOM_FIELDS.READ'],
        PRIVILEGES['CUSTOM_VIEWS.READ'],

        PRIVILEGES['DIVISIONS.READ'],

        PRIVILEGES['DOCUMENTS.READ'],
        PRIVILEGES['DOCUMENTS.WRITE'],

        PRIVILEGES['EMAIL_SENDERS.READ'],
        PRIVILEGES['EMAIL_TEMPLATES.READ'],

        PRIVILEGES['EVENTS.READ'],
        PRIVILEGES['EVENTS.WRITE'],

        PRIVILEGES['EVENT_TEMPLATES.READ'],
        PRIVILEGES['EVENT_OUTCOMES.READ'],
        
        PRIVILEGES['EMAILS.READ'],
        PRIVILEGES['EMAILS.WRITE'],

        PRIVILEGES['FORM_ANSWERS.READ'],
        PRIVILEGES['FORM_SUBMISSIONS.READ'],
        PRIVILEGES['FORMS.READ'],

        PRIVILEGES['LEADS.WRITE'],
        PRIVILEGES['LEADS.READ'],

        PRIVILEGES['LEAD_SOURCES.READ'],

        PRIVILEGES['LOCATIONS.READ'],

        PRIVILEGES['MATTER_DOCUMENTS.READ'],
        PRIVILEGES['MATTER_DOCUMENTS.WRITE'],


        PRIVILEGES['MATTERS.READ'],
        PRIVILEGES['MATTERS.WRITE'],
        PRIVILEGES['MATTER_PROGRESS_LOGS.READ'],

        PRIVILEGES['NOTES.READ'],
        PRIVILEGES['NOTES.WRITE'],

        PRIVILEGES['NOTIFICATIONS.ME'],

        PRIVILEGES['OUTSTANDING_ITEMS.READ'],
        PRIVILEGES['OUTSTANDING_ITEMS.WRITE'],

        PRIVILEGES['PAYMENTS.READ'],
        PRIVILEGES['PAYMENTS.WRITE'],

        PRIVILEGES['PAGE_HISTORIES.READ'],
        PRIVILEGES['PAGE_HISTORIES.WRITE'],

        PRIVILEGES['PIPELINES.READ'],

           
        PRIVILEGES['SIGNING.EVENTS.READ'],
        PRIVILEGES['SIGNING.DOCUMENTS.READ'],
        PRIVILEGES['SIGNING.DOCUMENTS.WRITE'],
        
        PRIVILEGES['STRING_TEMPLATES.READ'],

        PRIVILEGES['SIGNING.TEMPLATES.READ'],
       
        PRIVILEGES['TEXTS.READ'],
        PRIVILEGES['TEXTS.WRITE'],
        
        PRIVILEGES['TEXT_TEMPLATES.READ'],

        PRIVILEGES['TIME_ENTRY_TEMPLATES.READ'],
        PRIVILEGES['TASKS.READ'],
        PRIVILEGES['TASKS.WRITE'],
       
        PRIVILEGES['TAGS.READ'],

        
        PRIVILEGES['SOCIAL_SECURITY_NUMBERS.WRITE'],
       
        PRIVILEGES['SUPPORT_TICKETS.READ'],
        PRIVILEGES['SUPPORT_TICKETS.WRITE'],
        
        PRIVILEGES['USERS.READ'],
        PRIVILEGES['USERS.SELF'],
        
        PRIVILEGES['WIKIS.READ'],

        PRIVILEGES['WORKFLOWS.READ'],

    ])

    const assignAll = useCallback(() => {
        const newUser = Object.assign({}, user)
        standardPrivileges.forEach(privilege => {
            if(!newUser.privileges.includes(privilege)) {
                newUser.privileges.push(privilege)
            }
        })
     
        setUser(newUser);
    }, [standardPrivileges, setUser, user])
   
    const removeAll = useCallback(() => {
        const newUser = Object.assign({}, user)
        standardPrivileges.forEach(privilege => {
            newUser.privileges = newUser.privileges.filter(p => p !== privilege)
        })
     
        setUser(newUser);
    }, [standardPrivileges, setUser, user])

    return (
        <div>

            <Row>
                <Col xs={6} className="align-self-center">
                    <h2>Standard Privileges</h2>
                </Col>
                <Col xs={6} className="align-self-center text-right">
                    <button onClick={removeAll} className="btn btn-outline-warning">Remove All</button>
                    <button onClick={assignAll} className="btn btn-outline-info">Assign All</button>
                </Col>
            </Row>

            <Row>
                <Col lg={6}>
                <h5 className="text-uppercase">Automations</h5>
                <Privilege 
                    privilege={PRIVILEGES['AUTOMATIONS.READ']}
                    user={user}
                    title="Can View Automations"
                    onPrivilegeChange={onPrivilegeChange}
                    // description="Required to be able to view what automations are going on"
                />   
                    <Privilege 
                    privilege={PRIVILEGES['AUTOMATION_ASSIGNMENTS.READ']}
                    user={user}
                    title="Can view assigned automations."
                    onPrivilegeChange={onPrivilegeChange}
                />
                <Privilege 
                    privilege={PRIVILEGES['AUTOMATION_ASSIGNMENTS.WRITE']}
                    user={user}
                    title="Can update/delete assigned automations."
                    onPrivilegeChange={onPrivilegeChange}
                />

                <h5 className="text-uppercase">Billing</h5>
                    <Privilege 
                        privilege={PRIVILEGES['BILLING.READ']}
                        user={user}
                        title="Can View Billing Information"
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['BILLING.WRITE']}
                        user={user}
                        title="Can Update Billing Information, Cards, Plans, ETC."
                        onPrivilegeChange={onPrivilegeChange}
                    />

                    <h5 className="text-uppercase">Companies</h5>
                    <Privilege 
                        privilege={PRIVILEGES['COMPANIES.READ']}
                        user={user}
                        title="Can View Company Info"
                        onPrivilegeChange={onPrivilegeChange}
                        // description="This is required to view things like company logo, name, etc."
                    />

                    <h5 className="text-uppercase">Call Center</h5>
                    <Privilege 
                        privilege={PRIVILEGES['CALL_CENTER.READ']}
                        user={user}
                        title="Can View Information Related To Communication (Texts, Calls, ETC.)"
                        onPrivilegeChange={onPrivilegeChange}
                        // description={<span><i className="fas fa-exclamation-triangle text-warning mr-2" /> Opens up access to phone numbers and matter info</span>}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['CALL_CENTER.WRITE']}
                        user={user}
                        title="Can Make Calls, Send Texts, ETC."
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['CALLS.READ']}
                        user={user}
                        title="Can view calls and call logs."
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['CALL_PHONE_NUMBERS.READ']}
                        user={user}
                        title="Can view phone numbers."
                        onPrivilegeChange={onPrivilegeChange}
                    />
                  
                    <Privilege 
                        privilege={PRIVILEGES['CALL_FLOWS.READ']}
                        user={user}
                        title="Can view call flows."
                        onPrivilegeChange={onPrivilegeChange}
                    />
                   

                    <h5 className="text-uppercase">Contacts</h5>
                    <Privilege 
                        privilege={PRIVILEGES['CONTACTS.READ']}
                        user={user}
                        title="Can View Contacts"
                        onPrivilegeChange={onPrivilegeChange}
                        // description="The above privileges are required for a user to be able to view contact information"
                    />
                    <Privilege 
                        privilege={PRIVILEGES['CONTACTS.WRITE']}
                        user={user}
                        title="Can Update / Create Contact Info"
                        onPrivilegeChange={onPrivilegeChange}
                        // description="Required if this user needs to change contact details or create contacts"
                    />
                    <Privilege 
                        privilege={PRIVILEGES['CONTACT_TYPES.READ']}
                        user={user}
                        title="Can View Contact Types"
                        onPrivilegeChange={onPrivilegeChange}
                        // description="Required to be able to create contacts"
                    />   

                    <h5 className="text-uppercase">Custom Fields & Views</h5>
                    <Privilege 
                        privilege={PRIVILEGES['CUSTOM_FIELDS.READ']}
                        user={user}
                        title="Can View Custom Fields"
                        onPrivilegeChange={onPrivilegeChange}
                        // description="Required to be able to finish some tasks and view various data"
                    />   
                    <Privilege 
                        privilege={PRIVILEGES['CUSTOM_VIEWS.READ']}
                        user={user}
                        title="Can see custom views created on the admin dashboard."
                        onPrivilegeChange={onPrivilegeChange}
                    />

                    <h5 className="text-uppercase">Divisions</h5>
                    <Privilege 
                        privilege={PRIVILEGES['DIVISIONS.READ']}
                        user={user}
                        title="Can View Divisions"
                        onPrivilegeChange={onPrivilegeChange}
                        // description="Required for just about every use case"
                    />

                    <h5 className="text-uppercase">Documents</h5>
                    <Privilege 
                        privilege={PRIVILEGES['DOCUMENTS.READ']}
                        user={user}
                        title="Can View Documents"
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['DOCUMENTS.WRITE']}
                        user={user}
                        title="Can Update / Create / Upload Documents"
                        onPrivilegeChange={onPrivilegeChange}
                        // description="Any users who need to see or upload document will need the above privileges"
                    />
                   

                    <h5 className="text-uppercase">EMAILS</h5>
                    <Privilege 
                        privilege={PRIVILEGES['EMAILS.READ']}
                        user={user}
                        title="Can Read Emails"
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['EMAILS.WRITE']}
                        user={user}
                        title="Can Send Emails"
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['EMAIL_SENDERS.READ']}
                        user={user}
                        title="Can View Email Senders"
                        onPrivilegeChange={onPrivilegeChange}
                        // description="Required to be able to send most templated emails"
                    />   
                    <Privilege 
                        privilege={PRIVILEGES['EMAIL_TEMPLATES.READ']}
                        user={user}
                        title="Can View Email Templates"
                        onPrivilegeChange={onPrivilegeChange}
                        // description="Required to be able to send and associate any template emails"
                    />   
                    
                    <h5 className="text-uppercase">Events</h5>
                    <Privilege 
                        privilege={PRIVILEGES['EVENTS.READ']}
                        user={user}
                        title="Can View Events"
                        onPrivilegeChange={onPrivilegeChange}
                        // description="Required to be able to complete some tasks"
                    />   
                    <Privilege 
                        privilege={PRIVILEGES['EVENTS.WRITE']}
                        user={user}
                        title="Can Finish / Update Tasks"
                        onPrivilegeChange={onPrivilegeChange}
                        // description="Required to be able to complete some tasks"
                    />   

                    <Privilege 
                        privilege={PRIVILEGES['EVENT_TEMPLATES.READ']}
                        user={user}
                        title="Can View Event Templates Within The System"
                        onPrivilegeChange={onPrivilegeChange}
                        // description="Required to create an event"
                    />
                   
                    <Privilege 
                        privilege={PRIVILEGES['EVENT_OUTCOMES.READ']}
                        user={user}
                        title="Can View Event Outcomes Within The System"
                        onPrivilegeChange={onPrivilegeChange}
                        // description="Required to finish an event"
                    />

                    <h5 className="text-uppercase">Forms</h5>
                    <Privilege 
                        privilege={PRIVILEGES['FORMS.READ']}
                        user={user}
                        title="Can View System Forms And Request Them From Contacts"
                        onPrivilegeChange={onPrivilegeChange}
                        // description="Required to be able to send out system forms for completion"
                    />   
                    <Privilege 
                        privilege={PRIVILEGES['FORM_ANSWERS.READ']}
                        user={user}
                        title="Can View Form Answers"
                        onPrivilegeChange={onPrivilegeChange}
                        // description="Required to view any answers on a system form"
                    />
                    <Privilege 
                        privilege={PRIVILEGES['FORM_SUBMISSIONS.READ']}
                        user={user}
                        title="Can View Form Submissions"
                        onPrivilegeChange={onPrivilegeChange}
                        // description="Required to be able to view and download a final form submission"
                    />   

                    <h5 className="text-uppercase">Leads</h5>
                    <Privilege 
                        privilege={PRIVILEGES['LEADS.READ']}
                        user={user}
                        title="Can view leads."
                        onPrivilegeChange={onPrivilegeChange}
                    />

                    <Privilege 
                        privilege={PRIVILEGES['LEAD_SOURCES.READ']}
                        user={user}
                        title="Can view lead sources."
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['LEADS.WRITE']}
                        user={user}
                        title="Can create/update leads."
                        onPrivilegeChange={onPrivilegeChange}
                    />

                    <h5 className="text-uppercase">Locations</h5>
                    <Privilege 
                        privilege={PRIVILEGES['LOCATIONS.READ']}
                        user={user}
                        title="Can View Locations Within The System"
                        onPrivilegeChange={onPrivilegeChange}
                        // description="Required to create appointments, assign locations, etc.."
                    />
                   
                </Col>

                <Col lg={6}>

                <h5 className="text-uppercase">Matters</h5>
                    <Privilege 
                        privilege={PRIVILEGES['MATTERS.READ']}
                        user={user}
                        title="Can View Matters"
                        onPrivilegeChange={onPrivilegeChange}
                        // description="The above privileges are required for a user to be able to view matter information"
                    />
                    <Privilege 
                        privilege={PRIVILEGES['MATTERS.WRITE']}
                        user={user}
                        title="Can Update / Create Matters"
                        onPrivilegeChange={onPrivilegeChange}
                        // description="Required if this user needs to interact with most of the features in IntuiDesk"
                    />
                    <Privilege 
                        privilege={PRIVILEGES['MATTER_PROGRESS_LOGS.READ']}
                        user={user}
                        title="Can View Matter Progress Logs"
                        onPrivilegeChange={onPrivilegeChange}
                        // description="Required to be able to view information about matters being progressed by users"
                    />   
                    <Privilege 
                        privilege={PRIVILEGES['MATTER_DOCUMENTS.READ']}
                        user={user}
                        title="Can View Matter Document Entries, Update Their Status, And Add Documents To Documents To Categories"
                        onPrivilegeChange={onPrivilegeChange}
                    />   
                    <Privilege 
                        privilege={PRIVILEGES['MATTER_DOCUMENTS.WRITE']}
                        user={user}
                        title="Can Create / Update Matter Documents"
                        onPrivilegeChange={onPrivilegeChange}
                        description="Gives the user the ability to create matter document categories on a matter."
                    />

                   <h5 className="text-uppercase">Notifications</h5>
                    <Privilege 
                        privilege={PRIVILEGES['NOTIFICATIONS.ME']}
                        user={user}
                        title="Can view notifications specific to themselves."
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    
                    <h5 className="text-uppercase">Notes</h5>
                    <Privilege 
                        privilege={PRIVILEGES['NOTES.READ']}
                        user={user}
                        title="Can read notes."
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['NOTES.WRITE']}
                        user={user}
                        title="Can create notes."
                        onPrivilegeChange={onPrivilegeChange}
                    />
                  
                    <h5 className="text-uppercase">Outstanding Items</h5>
                    <Privilege 
                        privilege={PRIVILEGES['OUTSTANDING_ITEMS.READ']}
                        user={user}
                        title="Can view outstanding items."
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['OUTSTANDING_ITEMS.WRITE']}
                        user={user}
                        title="Can create/update outstanding items."
                        onPrivilegeChange={onPrivilegeChange}
                    />

                    <h5 className="text-uppercase">Page Histories</h5>
                    <Privilege 
                        privilege={PRIVILEGES['PAGE_HISTORIES.READ']}
                        user={user}
                        title="Can View Recent Pages Visited"
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['PAGE_HISTORIES.WRITE']}
                        user={user}
                        title="Can Log Pages Visited"
                        onPrivilegeChange={onPrivilegeChange}
                    />
                   
                   <h5 className="text-uppercase">Payments</h5>
                    <Privilege 
                        privilege={PRIVILEGES['PAYMENTS.WRITE']}
                        user={user}
                        title="Can Charge Payments"
                        onPrivilegeChange={onPrivilegeChange}
                    />

                    
                    <h5 className="text-uppercase">Pipelines</h5>
                    <Privilege 
                        privilege={PRIVILEGES['PIPELINES.READ']}
                        user={user}
                        title="Can View Pipelines"
                        onPrivilegeChange={onPrivilegeChange}
                        // description="Required to be able to view the pipeline page on the dashboard"
                    />   

                    <h5 className="text-uppercase">Support Tickets</h5>
                    <Privilege 
                        privilege={PRIVILEGES['SUPPORT_TICKETS.READ']}
                        user={user}
                        title="Can view support tickets."
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['SUPPORT_TICKETS.WRITE']}
                        user={user}
                        title="Can update support tickets."
                        onPrivilegeChange={onPrivilegeChange}
                    />

                    <h5 className="text-uppercase">Signature Documents</h5>   
                    <Privilege 
                        privilege={PRIVILEGES['SIGNING.EVENTS.READ']}
                        user={user}
                        title="Can View Events Of Documents Sent For Signature"
                        onPrivilegeChange={onPrivilegeChange}
                        // description="Required to view any documents sent out for signature"
                    />   
                    <Privilege 
                        privilege={PRIVILEGES['SIGNING.DOCUMENTS.READ']}
                        user={user}
                        title="Can View Documents Sent For Signature"
                        onPrivilegeChange={onPrivilegeChange}
                        // description="Required to view events like opening, signing, etc. for any sent documents for signature"
                    />   

                    <h5 className="text-uppercase">Signature Templates</h5>
                    <Privilege 
                        privilege={PRIVILEGES['SIGNING.TEMPLATES.READ']}
                        user={user}
                        title="Can view template documents that get sent for signature."
                        onPrivilegeChange={onPrivilegeChange}
                    />

                    <Privilege 
                        privilege={PRIVILEGES['SIGNING.DOCUMENTS.WRITE']}
                        user={user}
                        title="Can Update / Create Documents Sent For Signature"
                        onPrivilegeChange={onPrivilegeChange}
                        // description="Required to be able to send documents out for signature"
                    />   

                   

                    <h5 className="text-uppercase">Social Security Numbers</h5>
                    <Privilege 
                        privilege={PRIVILEGES['SOCIAL_SECURITY_NUMBERS.WRITE']}
                        user={user}
                        title="Can enter social security numbers on a contacts profile."
                        onPrivilegeChange={onPrivilegeChange}
                    />
                  
                    <h5 className="text-uppercase">String Templates</h5>
                    <Privilege 
                        privilege={PRIVILEGES['STRING_TEMPLATES.READ']}
                        user={user}
                        title="Can View String Templates Within The System"
                        onPrivilegeChange={onPrivilegeChange}
                        // description="Required for some emails and text messages"
                    />

                    <h5 className="text-uppercase">Tags</h5>
                    <Privilege 
                        privilege={PRIVILEGES['TAGS.READ']}
                        user={user}
                        title="Can View Tags Within The System"
                        onPrivilegeChange={onPrivilegeChange}
                        // description="Required for various associations."
                    />
                    
                   
                    <h5 className="text-uppercase">Tasks</h5>
                    <Privilege 
                        privilege={PRIVILEGES['TASKS.READ']}
                        user={user}
                        title="Can View Tasks"
                        onPrivilegeChange={onPrivilegeChange}
                        // description="Required to be able to move steps"
                    />   
                    <Privilege 
                        privilege={PRIVILEGES['TASKS.WRITE']}
                        user={user}
                        title="Can Finish / Update Tasks"
                        onPrivilegeChange={onPrivilegeChange}
                        // description="Required to be able to move steps"
                    />   

                    <h5 className="text-uppercase">TEXTS</h5>
                    <Privilege 
                        privilege={PRIVILEGES['TEXTS.READ']}
                        user={user}
                        title="Can Read Text Messages"
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['TEXTS.WRITE']}
                        user={user}
                        title="Can Send Text Messages"
                        onPrivilegeChange={onPrivilegeChange}
                    />
                     <Privilege 
                        privilege={PRIVILEGES['TEXT_TEMPLATES.READ']}
                        user={user}
                        title="Can View Text Templates"
                        onPrivilegeChange={onPrivilegeChange}
                        // description="Required to be able to send and associate any text emails"
                    />   

                    <h5 className="text-uppercase">Time Entry Templates</h5>
                    <Privilege 
                        privilege={PRIVILEGES['TIME_ENTRY_TEMPLATES.READ']}
                        user={user}
                        title="Can View Standard Time Entries"
                        onPrivilegeChange={onPrivilegeChange}
                        // description="Required for logging time worked"
                    />

                    <h5 className="text-uppercase">Users</h5>
                    <Privilege 
                        privilege={PRIVILEGES['USERS.SELF']}
                        user={user}
                        title="Can Update Self Profile"
                        onPrivilegeChange={onPrivilegeChange}
                        // description="Required to be able to be able to update themselves (name, email, phone, etc)"

                    />
                    <Privilege 
                        privilege={PRIVILEGES['USERS.READ']}
                        user={user}
                        title="Can View Other Users"
                        onPrivilegeChange={onPrivilegeChange}
                        // description="Required to be able to view, assign, and navigate any areas related to other company users"
                    />

                    <h5 className="text-uppercase">Wikis</h5>
                    <Privilege 
                        privilege={PRIVILEGES['WIKIS.READ']}
                        user={user}
                        title="Can View Wikis Within The System"
                        onPrivilegeChange={onPrivilegeChange}
                        // description="Required to view wikis"
                    />

                    <h5 className="text-uppercase">Workflows</h5>
                    <Privilege 
                        privilege={PRIVILEGES['WORKFLOWS.READ']}
                        user={user}
                        title="Can View System Workflows"
                        onPrivilegeChange={onPrivilegeChange}
                        // description="Required to be able to create a matter."
                    />


                </Col>
            </Row>

        </div>
    )

}


const mapStateToProps = state => {
	return {
	    PRIVILEGES: state.config.PRIVILEGES,
	};
};

export default connect(mapStateToProps, '')(UsersEditPrivilegesStandard);