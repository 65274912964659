import { useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Card, Container, Badge, Row, Col, CardHeader, CardTitle, CardBody, CardFooter, FormGroup } from "reactstrap";

import api from 'api';
import { Link } from 'react-router-dom';

import { Redirect } from 'react-router-dom'
import Table from 'components/functional/tables/Standard';
import TableIcons from 'components/markup/icons/TableIcons';
import ApiError from "components/markup/layout/ApiError";
import ObjectFinder from 'components/system/Objects/Finder';

import Circle from 'components/markup/loading/Circle';
import TextButtonRow from 'components/markup/layout/TextButtonRow';
import A from 'components/markup/links/A'

import moment from 'moment'

import { formatCurrency } from 'utils/currency';
import formatText from 'utils/formatText';
import DatePicker from "react-datepicker";

import BarLine from 'components/charts/BarLine';


const LocationsAll = ({setTab, match, selected_division, DOMAINS}) => {

    const [ start, setStart ] = useState(moment().startOf('month').toDate())
    const [ end, setEnd ] = useState(moment().endOf('month').toDate())
    const [ redirect, setRedirect ] = useState(false)
    const [ data, setData ] = useState(null)
    const [ error, setError ] = useState(false);


    // helper for this.downloadCSV and this.query
    const fetchData = useCallback(() => new Promise (async resolve => {
        setData(null);
        const _data = await api.lead_sources.analyze(match.params._id, parseInt(moment(start).format('X')), parseInt(moment(end).format('X')), selected_division._id)
        if(!_data.success) setError(_data.message)

        // console.log(_data.data.lead_source.division)
        // console.log(selected_division._id)
        // if(_data.data.lead_source.division !== selected_division._id) return setRedirect('/marketing/lead_sources')
        setData(_data.data)
    }), [match.params._id, start, end])

    const getPercent = useCallback((number, total) => {
        return ((number / total) * 100).toFixed(2)
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])
 
    if(redirect) return <Redirect to={redirect} />
    if(error) return <ApiError err={error} />
    if(!data) return <div className="pt-6"><Circle /></div>

    return (

        <>

        <Card className="rounde px-5 mb-0">
            <CardBody>
                <Row>
                    <Col md={6} className="align-self-bottom">
                        <p className="mb--3 text-muted"><small>Lead Source:</small></p>
                        <h2 className="mb-0 display-4 mb-0">{match.params._id ? data.lead_source.name  : 'All Sources' } </h2>
                        <p className="mt--3 text-muted mb-0"><small>Division: <ObjectFinder collection="divisions" _id={data.lead_source.division} /></small></p>

                    </Col>

                    <Col md={6} className="align-self-bottom">
                        <div className="ml-auto" style={{maxWidth: 400}}>
                            <Row>
                                <Col md={6}>
                                    <FormGroup className="mb-0">
                                        <div className="mb--3">
                                        <label className="form-control-label">Start Date</label>
                                        <DatePicker selected={start} onChange={(date) => setStart(date)} />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup className="mb-0">
                                    <div className="mb--3">

                                        <label className="form-control-label">End Date</label>
                                        <DatePicker selected={end} onChange={(date) => setEnd(moment(date).endOf('day').toDate())} />
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>

        <Container fluid className={window.location.href.includes('marketing') ? '' : 'pt-4'}>

            <h2 className="display-2 text-info mt-0 pt-0"><i className="fas fa-users mr-2" /> Lead Data Analysis</h2>

            <Row>

                <Col md={4}>
                    <Card className='text-center'>
                        <CardHeader>
                            <CardTitle className="mb-0">Estimated Revenue Per Hire</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <h2 className='display-2 mb-0'>
                                {formatCurrency(data.leads.avg_value)}
                            </h2>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className='text-center'>
                        <CardHeader>
                            <CardTitle className="mb-0">Estimated Income Per Lead Today</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <h2 className='display-2 mb-0'>
                                {formatCurrency(data.leads.avg_value * ( .01 * data.leads.avg_conversion_percent))}
                            </h2>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className='text-center'>
                        <CardHeader>
                            <CardTitle className="mb-0">Estimated Conversion Percentage</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <h2 className='display-2 mb-0'>
                                {data.leads.avg_conversion_percent}%
                            </h2>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col md={4}>
                    <Card className='text-center'>
                        <CardHeader>
                            <CardTitle className="mb-0">New Leads</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <h2 className='display-2 mb-0'>
                                {data.leads.leads_total}{' '}
                                <small><small className='text-muted'>Total</small></small>
                            </h2>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className='text-center'>
                        <CardHeader>
                            <CardTitle className="mb-0">Duplicate Leads</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <h2 className='display-2 mb-0'>
                                {data.leads.leads_duplicate}{' '}
                                <small><small className='text-danger'>{data.leads.leads_duplicate ? getPercent(data.leads.leads_duplicate, data.leads.leads_total) : 0}%</small></small>
                            </h2>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className='text-center'>
                        <CardHeader>
                            <CardTitle className="mb-0">Unique Leads</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <h2 className='display-2 mb-0'>
                                {data.leads.leads_unique}{' '}
                                <small><small className='text-success'>{data.leads.leads_unique ? getPercent(data.leads.leads_unique, data.leads.leads_total) : 0}%</small></small>
                            </h2>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col xl={6}>
                    <BarLine 
                        type="line"
                        title={`Leads By Hour`}
                        labels={[  '12AM', '1AM', '2AM', '3AM', '4AM', '5AM', '6AM', '7AM', '8AM', '9AM', '10AM', '11AM', '12PM', '1PM', '2PM', '3PM', '4PM', '5PM', '6PM', '7PM', '8PM', '9PM', '10PM', '11PM'  ]}
                        datasets={[
                            {
                                label: 'Number Of Leads',
                                data: Object.keys(data.leads.hour_breakdown).map(key => data.leads.hour_breakdown[key]),
                            },

                        ]}
                    />
                </Col>

                <Col xl={6}>
                    <BarLine 
                        type="line"
                        title={`Leads By Day`}
                        labels={[  'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'  ]}
                        datasets={[
                            {
                                label: 'Number Of Leads',
                                data: Object.keys(data.leads.day_breakdown).map(key => data.leads.day_breakdown[key]),
                            },

                        ]}
                    />
                </Col>
                
            </Row>
{/* business_hours_breakdown */}

            <hr />

            <h2 className="display-2 text-info"><i className="fas fa-gavel mr-2" /> Matter Data Analysis</h2>

            
            <Row>

                <Col xl={3} md={6}>
                    <Card className='text-center'>
                        <CardHeader>
                            <CardTitle className="mb-0">Found Matters</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <h2 className='display-3 mb-0'>
                                {data.matters.total_matters}{' '}
                                <small><small className='text-muted'>Total</small></small>
                            </h2>
                        </CardBody>
                    </Card>
                </Col>
                <Col xl={3} md={6}>
                    <Card className='text-center'>
                        <CardHeader>
                            <CardTitle className="mb-0">Invoice Total</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <h2 className='display-3 mb-0'>
                                {formatCurrency(data.matters.billing.total)}
                            </h2>
                        </CardBody>
                    </Card>
                </Col>
                <Col xl={3} md={6}>
                    <Card className='text-center'>
                        <CardHeader>
                            <CardTitle className="mb-0">Collection Percentage</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <h2 className='display-3 mb-0'>
                                {data.matters.billing.total ? getPercent(data.matters.billing.paid, data.matters.billing.total ) : 0}%
                            </h2>
                        </CardBody>
                    </Card>
                </Col>
                <Col xl={3} md={6}>
                    <Card className='text-center'>
                        <CardHeader>
                            <CardTitle className="mb-0">Paid To Date</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <h2 className='display-3 mb-0'>
                                {formatCurrency(data.matters.billing.paid)}
                            </h2>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col md={6}>

                    <Card>
                        <div className="table-responsive">
                            <table className="table">
                            <thead>
                                <tr>
                                    <th>Current Step Breakdown</th>
                                    <th className="text-right"># Of Matters</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(data.matters.current_steps).length ? Object.keys(data.matters.current_steps).map(_id => (
                                    <tr key={_id}>
                                        <td>
                                            {_id === 'empty' ? 'No Assigned Step' : <ObjectFinder collection="workflow_steps" _id={_id} />}
                                        </td>
                                        <td className="text-right">
                                            {data.matters.current_steps[_id]}
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td>*No Data Found</td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                            </table>
                        </div>
                    </Card>
                   
                    <Card>
                        <div className="table-responsive">
                            <table className="table">
                            <thead>
                                <tr>
                                    <th>Current Departments</th>
                                    <th className="text-right"># Of Matters</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(data.matters.division_departments).length ? Object.keys(data.matters.division_departments).map(_id => (
                                    <tr key={_id}>
                                        <td>
                                            {_id === 'empty' ? 'No Assigned Department' : <ObjectFinder collection="division_departments" _id={_id} />}
                                        </td>
                                        <td className="text-right">
                                            {data.matters.division_departments[_id]}
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td>*No Data Found</td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                            </table>
                        </div>
                    </Card>
                   
                    <Card>
                        <div className="table-responsive">
                            <table className="table">
                            <thead>
                                <tr>
                                    <th>Assigned Tags</th>
                                    <th className="text-right"># Of Matters</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(data.matters.tags).length ? Object.keys(data.matters.tags).map(_id => (
                                    <tr key={_id}>
                                        <td>
                                            {_id === 'empty' ? 'No Assigned Tags' : <ObjectFinder collection="tags" _id={_id} />}
                                        </td>
                                        <td className="text-right">
                                            {data.matters.tags[_id]}
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td>*No Data Found</td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                            </table>
                        </div>
                    </Card>
                   
                    <Card>
                        <div className="table-responsive">
                            <table className="table">
                            <thead>
                                <tr>
                                    <th>Recurring Billing</th>
                                    <th className="text-right"># Of Matters</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(data.matters.recurring_breakdowns).length ? Object.keys(data.matters.recurring_breakdowns).map(_id => (
                                    <tr key={_id}>
                                        <td>
                                            {_id === 'empty' ? 'No Plan Setup' : _id }
                                        </td>
                                        <td className="text-right">
                                            {data.matters.recurring_breakdowns[_id]}
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td>*No Data Found</td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                            </table>
                        </div>
                    </Card>

                </Col>
                <Col md={6}>

                    <Card>
                        <div className="table-responsive">
                            <table className="table">
                            <thead>
                                <tr>
                                    <th>Current Step Categories</th>
                                    <th className="text-right"># Of Matters</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(data.matters.workflow_step_categories).length ? Object.keys(data.matters.workflow_step_categories).map(_id => (
                                    <tr key={_id}>
                                        <td>
                                            {_id === 'empty' ? 'No Assigned Category' : <ObjectFinder collection="workflow_step_categories" _id={_id} />}
                                        </td>
                                        <td className="text-right">
                                            {data.matters.workflow_step_categories[_id]}
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td>*No Data Found</td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                            </table>
                        </div>
                    </Card>
                  
                    <Card>
                        <div className="table-responsive">
                            <table className="table">
                            <thead>
                                <tr>
                                    <th>Current Disposition Breakdown</th>
                                    <th className="text-right"># Of Matters</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(data.matters.workflow_dispositions).length ? Object.keys(data.matters.workflow_dispositions).map(_id => (
                                    <tr key={_id}>
                                        <td>
                                            {_id === 'empty' ? 'No Assigned Disposition' : <ObjectFinder collection="workflow_dispositions" _id={_id} />}
                                        </td>
                                        <td className="text-right">
                                            {data.matters.workflow_dispositions[_id]}
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td>*No Data Found</td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                            </table>
                        </div>
                    </Card>
                  
                    <Card>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>State Breakdown</th>
                                        <th className="text-right"># Of Matters</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(data.matters.states).length ? Object.keys(data.matters.states).map(_id => (
                                        <tr key={_id}>
                                            <td>
                                                {_id === 'empty' ? 'No Assigned State' : _id}
                                            </td>
                                            <td className="text-right">
                                                {data.matters.states[_id]}
                                            </td>
                                        </tr>
                                    )) : (
                                        <tr>
                                            <td>*No Data Found</td>
                                            <td></td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </Card>
                   
                    <Card>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Workflow Breakdown</th>
                                        <th className="text-right"># Of Matters</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(data.matters.workflows).length ? Object.keys(data.matters.workflows).map(_id => (
                                        <tr key={_id}>
                                            <td>
                                                {_id === 'empty' ? 'No Assigned Workflow' : <ObjectFinder collection="workflows" _id={_id} />}
                                            </td>
                                            <td className="text-right">
                                                {data.matters.workflows[_id]}
                                            </td>
                                        </tr>
                                    )) : (
                                        <tr>
                                            <td>*No Data Found</td>
                                            <td></td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </Card>

                </Col>
            </Row>
            

            <Row>
                <Col md={6}>
                    <h2 className="display-2 text-info">Recent Matters </h2>

                    <Card>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Matter</th>
                                        <th>Disposition</th>
                                        <th>Created At</th>
                                        <th className="text-right"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.matters.matters.map(matter => (
                                        <tr key={matter._id}>
                                            <td>{matter.name}</td>
                                            <td>{matter.workflow_disposition ? <ObjectFinder collection="workflow_dispositions" _id={matter.workflow_disposition} /> : '-'}</td>
                                            <td>{moment.unix(matter.created_at).format('MM/DD/YYYY h:mm A')}</td>
                                            <td className="text-right">
                                                <A href={`${DOMAINS.APP}/matters/${matter.id}`} className="btn btn-success"><i className="fas fa-link mr-2" /> View</A>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <CardFooter className="text-right">Showing Most Recent 100</CardFooter>
                    </Card>
                </Col>
                <Col md={6}>
                    <h2 className="display-2 text-info">Recent Leads </h2>

                    <Card>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Phone</th>
                                        <th>Email</th>
                                        <th className="text-right"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.leads.leads.map(lead => (
                                        <tr key={lead._id}>
                                            <td>{lead.display_name}</td>
                                            <td>{lead.phone ? formatText(lead.phone).phone() : '-'}</td>
                                            <td>{lead.email ? lead.email : '-'}</td>
                                            <td className="text-right">
                                                <button className="btn btn-success"><i className="fas fa-link mr-2" /> View</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <CardFooter className="text-right">Showing Most Recent 100</CardFooter>

                    </Card>
                </Col>
            </Row>


        </Container>

        </>
    )

}

const styles = {
    badge: {
        width: 100  
    }
}

const mapStateToProps = state => {
	return {
        DOMAINS: state.load.DOMAINS,
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(LocationsAll);

// {
//     "leads": {
//         * "leads_total": 32,
//         * "leads_duplicate": 19,
//         * "leads_unique": 13,
//         "audit_total": 0,
//         "audit_exists": 0,
//         "audit_non_existent": 0,
//         "avg_value": 1088.89,
//         "avg_conversion_percent": 3.95,
//         "business_hours_breakdown": {
//             "total": 32,
//             "true": 24,
//             "false": 8
//         },
//         "day_breakdown": {
//             "total": 32,
//             "mon": 0,
//             "tue": 0,
//             "wed": 0,
//             "thu": 0,
//             "fri": 0,
//             "sat": 0,
//             "sun": 0,
//             "true": 6
//         },
//         "hour_breakdown": {
//             "0": 0,
//             "1": 0,
//             "2": 0,
//             "3": 0,
//             "4": 0,
//             "5": 0,
//             "6": 0,
//             "7": 0,
//             "8": 0,
//             "9": 0,
//             "10": 0,
//             "11": 0,
//             "12": 0,
//             "13": 0,
//             "14": 0,
//             "15": 0,
//             "16": 0,
//             "17": 0,
//             "18": 0,
//             "19": 0,
//             "20": 0,
//             "21": 0,
//             "22": 0,
//             "23": 0,
//             "total": 32,
//             "true": 1
//         },
//         "value_total": 1000,
//         "value_estimated": 40
//     },
//     "matters": {
//         "current_steps": {
//             "646f55eb40ae4b147b77a9f1": 1,
//             "646f558040ae4b147b77a9b7": 1
//         },
//         "division_departments": {
//             "empty": 2
//         },
//         "workflow_dispositions": {
//             "65a727ab3fc7004549e12ebe": 1,
//             "65aa9506da25b9807ee8ba71": 1
//         },
//         "workflow_step_categories": {
//             "64441bceac4ea79953ecae6d": 2
//         },
//         "tags": {
//             "empty": 2
//         },
//         "workflows": {
//             "637bdfb091787355b0ea9501": 2
//         },
//         "states": {
//             "empty": 2
//         },
//         "last_payment_statuses": {
//             "empty": 2
//         },
//         "recurring_breakdowns": {
//             "empty": 2
//         },
//         "billing": {
//             "_id": null,
//             "total": 1200,
//             "unpaid": 1200,
//             "paid": 0
//         },
//         "next_payment_success_rate": 0
//     }
// }