import React, { useCallback, useEffect, useState } from 'react';
import { FormGroup, Row, Col } from 'reactstrap'
import ObjectFinder from 'components/system/Objects/Finder';

import AsyncSelect from 'react-select/async';

import throttle from 'utils/throttle'
import _selectBase from './_selectBase'

import formatText from 'utils/formatText'

const ReactSelectAsync = ({formGroup, title, description, onChange, value, search, hideSelected, includeProps, hideTitle, includeFields, onBlur, placeholder, menuPlacement, disabled, showEnhanced, collection}) => {

    const [options, setOptions] = useState(null);

    const loadData = useCallback(async (query, cb) => {
        const data = await search(query)

        const results = !data.success ? [
            { 
                label: <span><i className="fas fa-times text-danger mr-3 " />Something's not right, please try again</span>, 
                value: 'err' 
            }
        ] :  data.data.documents.map(d => {
                let label = d.name;
                let emailIsIdentifier = false
                if(d.display_name) {
                    label = d.display_name
                } else if(d.given_name && d.family_name) {
                    label = formatText(d.given_name + ' ' + d.family_name).capitalize();
                } else if(d.email) {
                    label = d.email
                    emailIsIdentifier = true
                } else if(d.phone) {
                    formatText(d.phone).phone()
                } else if(d.outcome) {
                    label = d.outcome
                } else if(d.name) {
                    label = d.name
                } else if(d.description) {
                    label = d.description
                }

                if(!emailIsIdentifier && includeFields && includeFields.includes('email') && d.email) {
                    label += ' - ' + d.email
                }

                if(showEnhanced) {
                    if(showEnhanced === 'users') {
                        label = (
                            <Row className=''>
                                <Col md={9} className="align-self-center">
                                    <div>{label}</div>
                                    <div className='mt--2'>
                                        {d.division_departments && d.division_departments.length ? d.division_departments.map((department, i) => (
                                            <span className='text-sm'>{i !== 0 ? ', ' : ''}<ObjectFinder collection="division_departments" _id={department} /></span>
                                        )) : (
                                            <span className='text-muted text-sm'>No Departments Found</span>
                                        )}
                                    </div>
                                </Col>
                                <Col md={3} className="align-self-center text-right">
                                    {d.agent_can_receive_calls && !d.agent_current_call ? (
                                        <span className='archk-available-circle '><i className="fas fa-check" /></span>
                                    ) : d.agent_can_receive_calls && d.agent_current_call ? (
                                    // ) : d._id === "658092fea3b5e21e75636968" ? (
                                        <span className='archk-on-call-circle '><i className="fas fa-headset" /></span>
                                    ) : (
                                        <span className='archk-unavailable-circle '><i className="fas fa-times" /></span>
                                    )}
                                </Col>
                            </Row>
                        )
                    } else if(showEnhanced === 'contacts') {

                        label = (
                            <div>
                                {d.display_name ? (
                                    <div>
                                        <div>{d.display_name}</div>
                                        <p className='text-mute text-sm mb-0 modal-d-none'>{d.email ? d.email + ' - ' : ''}{d.phone ? formatText(d.phone).phone() : ''}</p>
                                    </div>
                                ) : (
                                    <div>{d.email ? d.email + ' - ' : ''}{d.phone ? formatText(d.phone).phone() : ''}</div>
                                )}
                                
                            </div>
                        )
                    } else if(showEnhanced === 'call_phone_numbers') {

                        label = (
                            <div>
                                {d.name + ' - ' + formatText(d.number).phone()}
                             
                            </div>
                        )
                    }
    

                } 


                let obj = { label: label, value: d._id, obj: d };
                if(includeProps && includeProps.length) {
                    includeProps.forEach(prop => {
                        obj[prop] = d[prop]
                    })
                }

                if(disabled) obj.isDisabled = true
              
                return obj
            }
        )

        if(cb) cb(results)
        setOptions(results)
    }, [search, includeFields, showEnhanced, includeProps, disabled])

    const getInitial = useCallback(async () => {
        loadData((data) => {
            setOptions(data);
        })
    }, [loadData])

    useEffect(() => {
        getInitial();
    }, [getInitial]);

    let foundObj = options ? options.find(o => o.value === value) : null;

    // if results are not deep enough try to populate the field with object finder should a collection be passed in
    if(!foundObj && value && collection) { 
        foundObj = { label: <ObjectFinder collection={collection} _id={value} />, value: value }
    } else if(!foundObj) {
        foundObj = value;
    }

    const select = (
        <div className='position-relative'>
            {disabled ? <div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10, background: 'rgba(0,0,0,.1)'}} /> : ''}
            <AsyncSelect
                {..._selectBase}
                isClearable
                menuPlacement={menuPlacement ? menuPlacement : undefined}
                placeholder={placeholder}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                value={hideSelected ? '' : foundObj}
                defaultOptions={options}
                loadOptions={throttle(loadData, 1000, 3)}
                onChange={(obj) => obj ? onChange(obj) : onChange({})}
                onBlur={onBlur ? onBlur : () => {}}
                disabled={disabled}
            />
        </div>

    )

    if(formGroup) return (
        <FormGroup>

            {hideTitle ? '' : title ? <label className="form-control-label">{title}</label> : null}
            {description ? <p className="text-sm mb-0">{description}</p> : null}
            {select}
        </FormGroup>
    )

    return select

}

export default ReactSelectAsync;