import { connect } from 'react-redux';

import WorkflowRolesSelection from 'components/system/WorkflowRolesSelection';
import WorkflowContactSelection from 'components/system/WorkflowContactSelection';

import SearchCollections from "components/system/Search/Collections";

const ActionSendEmail = ({ node, onInputChange, selected_division }) => {

    return (
        <div>
    
            <div className='sidebar-section border-bottom'>
                <SearchCollections
                    collection={'email_templates'}
                    title="**Select The Email To Send"
                    placeholder="Search..."
                    value={node.data.email_template}
                    onChange={(obj) => onInputChange('email_template', obj.value)}
                    filter={{
                        division: selected_division._id,
                    }}
                /> 
            </div>
          
            <div className='sidebar-section border-bottom'>
                <SearchCollections
                    collection={'email_senders'}
                    title="**Select The Default Email Sender"
                    placeholder="Search..."
                    value={node.data.email_sender}
                    onChange={(obj) => onInputChange('email_sender', obj.value)}
                    filter={{
                        division: selected_division._id,
                    }}
                /> 
            </div>

            <div className='sidebar-section border-bottom'>
                <h2>From User</h2>
                <WorkflowRolesSelection 
                    roles={node.data.user} 
                    onChange={(user) => onInputChange('user', user)} 
                    multiSelect={false}
                    useNullOption={true}
                />
            </div>

            <div className='sidebar-section border-bottom'>
                <h2>To User(s)</h2>
                <WorkflowRolesSelection 
                    roles={node.data.workflow_roles} 
                    onChange={(user) => onInputChange('workflow_roles', user)} 
                    multiSelect={true}
                />
            </div>

            <div className='sidebar-section border-bottom'>
                <h2>To Contacts</h2>
                <WorkflowContactSelection 
                    contacts={node.data.workflow_contacts} 
                    onChange={(workflow_contacts) => onInputChange('workflow_contacts', workflow_contacts)} 
                />
            </div>


            <div className='sidebar-section border-bottom'>
                <p className='text-sm'>
                    <i className="fas fa-info-circle text-info" /> This action sends the selected email when it runs. 
                </p>
                <p className='text-sm mb-0'>
                    Select a user to send from that persons direct email address or an email sender if you wish to send a marketing or transactional email..
                </p>
            </div>
    
        </div>
    )
}



const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(ActionSendEmail);