import { useState, useCallback } from 'react';
import { Card, CardHeader, CardTitle, CardFooter, CardBody, Input, FormGroup } from 'reactstrap';

import ReactSelect from 'components/functional/inputs/ReactSelect';

import api from 'api';

import { toast } from 'react-toastify'

const WorkflowEditRoles = ({workflow, onSetWorkflow, onSetUnsavedChanges}) => {

    const [state, setState] = useState({
        name: workflow.name,
        active: workflow.active,
    })

    const { name, active } = state


    const onSetState = useCallback((field, value) => {
        const _state = JSON.parse(JSON.stringify(state));
        _state[field] = value
        onSetUnsavedChanges(true)
        setState(_state)
    }, [state, onSetUnsavedChanges])

    const onSave = useCallback(async () => {
        if(!state.name) return toast.info('This workflow must have a name.')

        const saved = await api.workflows.update(workflow._id, state)
        onSetUnsavedChanges(false);

        if(saved.success) {
            toast.success('Workflow Updated Successfully')
        } else {
            toast.error(`Something's not right, please try again.`)
        }

    }, [workflow._id, state, onSetUnsavedChanges])


    return (

        <Card>
            <CardHeader>
                <CardTitle className="mb-0">Workflow Settings</CardTitle>
            </CardHeader>

            <CardBody>
                <FormGroup>
                    <label className="form-control-label">Workflow Name*</label>
                    <Input 
                        type="text"
                        value={name}
                        onChange={(e) => onSetState('name', e.target.value)}
                    />
                </FormGroup>

                <ReactSelect 
                    formGroup={true}
                    title="Active*"
                    description="If a workflow is active, staff members may use it to create cases. For a workflow to be active it must have at least 1 step and 1 role assigned"
                    value={active}
                    onChange={(obj) => onSetState('active', obj.value)}
                    options={[
                        { value: false, label: 'No' },
                        { value: true, label: 'Yes' },
                    ]}
                />

            </CardBody>

            <CardFooter className='text-right'>
                <button onClick={onSave} className="btn btn-success">
                    <i className="fas fa-save mr-2 " /> Save Settings
                </button>
            </CardFooter>
        </Card>

    )

}

export default WorkflowEditRoles;