import { useEffect } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input } from 'reactstrap'
import ReactSelect from 'components/functional/inputs/ReactSelect';
import SearchCollections from 'components/system/Search/Collections';

const WorkflowSidebarDisposition = ({ stepToEdit, onSetStepToEdit, dispositions, stepCategories, errs, selected_division }) => {

    useEffect(() => {
        const input = document.getElementById('archk-workflow-step-name')
        if(input) input.focus()
    }, [])

    return (
        <div>
    
            <h2 className='text-warning'>STEP INFO</h2>
    
            <FormGroup>
                <label className="form-control-label">Step Name</label>
                <Input 
                    id="archk-workflow-step-name"
                    type="text"
                    value={stepToEdit.name}
                    onChange={e => onSetStepToEdit('name', e.target.value)}
                />
                <p className="form-control-invalid">Step Name Required</p>
            </FormGroup>
    
            <ReactSelect 
                formGroup={true}
                title="Step Disposition"
                value={stepToEdit.workflow_disposition}
                onChange={(obj) => onSetStepToEdit('workflow_disposition', obj.value)}
                options={
                    [
                        { value: null, label: <span><i className="fas fa-times text-danger mr-2 " />None</span> },
                        ...dispositions.map((category, i) => {
                            return { value: category._id, label: category.name }
                        })
                    ]
                }
            />
    
            <ReactSelect 
                formGroup={true}
                title="Step Category"
                value={stepToEdit.workflow_step_category}
                onChange={(obj) => onSetStepToEdit('workflow_step_category', obj.value)}
                options={
                    [
                        { value: null, label: <span><i className="fas fa-times text-danger mr-2 " />None</span> },
                        ...stepCategories.map((disposition, i) => {
                            return { value: disposition._id, label: disposition.name }
                        })
                    ]
                }
            />

            <SearchCollections
                collection="division_departments" 
                title={"Assigned Department"}
                value={stepToEdit.division_department}
                addNull={true}
                onChange={(obj) => onSetStepToEdit('division_department', obj.value === 'none' ? null : obj.value)}
                filter={{
                    division: selected_division._id
                }}
            /> 

            <ReactSelect 
                formGroup={true}
                title="Remove Pending Items"
                description="If set to Yes, moving a matter to this step will mark all tasks, and documents sent for signature complete."
                value={stepToEdit.remove_pending_items}
                onChange={(obj) => onSetStepToEdit('remove_pending_items', obj.value)}
                options={
                    [
                       { value: false, label: 'No' },
                       { value: true, label: 'Yes' },
                    ]
                }
            />
    
            {!stepToEdit.name && errs.includes('name') && (
                <div className='alert alert-warning'><i className="fas fa-info-circle text-in" /> This step must have a name.</div>
            )}
    
        </div>
    )
}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(WorkflowSidebarDisposition);