import EventTemplatesEdit from './components/Edit'
import EventTemplatesAll from './All'

import IntuideskSectionWrapper from 'components/markup/layout/IntuideskSectionWrapper'

const SystemEventTemplates = ({match, section}) => (
    <IntuideskSectionWrapper 
        title="Event Templates"
        editTitle="Event Template"
        match={match}
        section={section}
        innerSection={'event_templates'}
        Edit={EventTemplatesEdit}
        All={EventTemplatesAll}
    />
)

export default SystemEventTemplates;