import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import Question from './Question';
import update from 'immutability-helper';

const QuestionList = ({allQuestions, nestedLevel, parentId, parentTree, onInjectQuestion, setMasterQuestions, onSelect, onDragEnd, unsavedQuestion, page}) => {

    const [questionToShow, setQuestionsToShow] = useState([]);
    const [newParentTree, setNewParentTree] = useState([]);
    const acceptNested = nestedLevel + '-' +  parentId

    const [, drop] = useDrop({ accept: acceptNested });

    const nextNest = (parseInt(nestedLevel) + 1).toString()
    // const questionNumber = questionToShow ? questionToShow.length : 0;

    // returns a question in the list by id
    const findQuestion = useCallback((id) => {
        const question = allQuestions.filter((c) => `${c.id}` === id)[0];
        if(!question) return {};
        return { question, index: allQuestions.indexOf(question) };
    }, [allQuestions]);
        
    // moves a questions order by drag and drop
    const moveQuestion = useCallback((id, atIndex) => {
        const { question, index } = findQuestion(id);
        if(!question) return;

        const newQuestions = update(allQuestions, {
            $splice: [
                [index, 1],
                [atIndex, 0, question],
            ],
        });

        setMasterQuestions(newQuestions, newParentTree)
    }, [findQuestion, allQuestions, setMasterQuestions, newParentTree]);

    useEffect(() => {
        const _questionToShow = allQuestions.filter(q => q.page === page);
        setQuestionsToShow(_questionToShow)
    }, [page, allQuestions])

    useEffect(() => {
        const _newParentTree = JSON.parse(JSON.stringify(parentTree))
        if(parentId) _newParentTree.push(parentId);
        setNewParentTree(_newParentTree)
    }, [parentTree, parentId])

    return (
        <div ref={drop} className="question-wrapper bg-white">
            {questionToShow && questionToShow.length ? questionToShow.map((question, i) => (
                <Question 
                    i={i}
                    key={question.id} 
                    question={question} 
                    moveQuestion={moveQuestion} 
                    onDragEnd={onDragEnd} 
                    acceptNested={acceptNested}
                    findQuestion={findQuestion}
                    onInjectQuestion={onInjectQuestion}
                    newParentTree={newParentTree}
                    unsavedQuestion={unsavedQuestion}
                    onSelect={() => onSelect(question, allQuestions, newParentTree)}
                >
                    {question.branch_on_answer ? (
                        <div className={`nested-level nested-level-${nextNest}`}>
                            <QuestionList 
                                nestedLevel={nextNest} 
                                allQuestions={question.questions} 
                                parentId={question.id} 
                                parentTree={newParentTree}
                                setMasterQuestions={setMasterQuestions}
                                onSelect={onSelect}
                                onDragEnd={onDragEnd}
                                page={page}
                                // newParentTree={newParentTree}
                                onInjectQuestion={onInjectQuestion}
                                unsavedQuestion={unsavedQuestion}
                            /> 
                        </div>
                    ): null}
                </Question>
            )) : null}


            {nestedLevel === '1' ? (
                <button className="btn btn-sm btn-block btn-sm btn-outline-warning mt-4" onClick={() => onInjectQuestion(allQuestions[allQuestions.length - 1], parentTree)}>
                    <i className="fas fa-plus " /> Add Question
                </button>
            ) : null}
        </div>       
    )
};

export default memo(QuestionList)