import { Input, FormGroup } from "reactstrap";
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import { useCallback, useState, useEffect } from 'react';

import api from 'api'

import Modalify from 'components/functional/modals/Modalify'

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

const baseState = {
    name: '',
    outcomes: []
}

const SystemCustomDatesEdit = ({_id, onSaved, showModal, toggleModal, selected_division}) => {

    const [date, setDate] = useState(baseState)
    const [err, setErr] = useState(null)
    const [redirect, setRedirect] = useState(null)

    const fetchData = useCallback(async () => {
        if(!_id || _id === 'create') return setDate(baseState);
        const date = await api.custom_dates.findById(_id)
        if(!date.data) return setErr(true);
        setDate(date.data)
    }, [_id])

    const onInputChange = useCallback(( field, value) => {
        const newState = JSON.parse(JSON.stringify(date))
        newState[field] = value;
        setDate(newState);
    }, [date])

    const onSave = useCallback(async () => {
        setErr(false)
        let newState = JSON.parse(JSON.stringify(date))

        if(!newState.name) {
            return setErr([`A custom date must have a name.`])
        }
        
        setDate(newState)

        let saved;

        // toggle loaders and update/create the date
        toggleStandardLoader(true)
        if(newState._id) {
            saved = await api.custom_dates.update(newState._id, newState);
        } else {
            saved = await api.custom_dates.create({...newState, division: selected_division._id});
        }
        toggleStandardLoader(false)

        // show error messages if unsuccessful
        if(saved.success) {
            toggleAlertBS(false, "Date Saved Successfully")
            fetchData();
            if(toggleModal) toggleModal();
            if(onSaved) onSaved(saved.data)
        } else {
            return setErr(saved.message);
        }
    
    }, [date, fetchData, toggleModal, onSaved, selected_division._id])

    useEffect(() => {
        if(toggleModal) {
            if(showModal) {
                fetchData()
            } else {
                setErr(null)
                setDate(baseState)
            }
        } else {
            fetchData()
        }
    }, [showModal, toggleModal, fetchData])

    useEffect(() => {
        if(!selected_division._id) {
            toggleAlertBS('info', 'A division must be created for editing a custom date.')
            setRedirect('/system/divisions/create')
        }
    }, [selected_division._id])

    if(redirect) return <Redirect to={redirect} />

    return (
        <Modalify 
            size="md"
            showModal={showModal}
            toggleModal={toggleModal}
            modalTitle={date && date._id ? "Update Custom Date" : "Create Custom Date"}
            err={err}
            footer={(
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            )}
            sections={!date ? [] :  [
                (
                    <div>

                        <FormGroup>
                            <label className="form-control-label">Name*</label>
                            <Input 
                                type="text"
                                value={date.name || ''}
                                onChange={e => onInputChange('name', e.target.value)}
                            />
                        </FormGroup>
                    </div>
                ),
                // ...(date._id ? [(
                //     <Associations 
                //         type="date" 
                //         _id={date._id} 
                //         classNames="border p-3 bg-secondary" 
                //     />
                // )] : []),
            ]}
        />
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SystemCustomDatesEdit);