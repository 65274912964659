
import moment from 'moment';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Container } from 'reactstrap';

const ThreatDetected = () => {
    const time = moment().valueOf()

    return (
        <Container style={{maxWidth: 600}}>
            <Card className="mt-4">
                <CardHeader>
                    <CardTitle className="mb-0">
                        <i className="fas fa-exclamation-triangle mr-2 text-danger " /> 
                        Your account may have been compromised!
                    </CardTitle>
                </CardHeader>
                <CardBody className="bg-secondary">
                    <p className="text-sm mb-0"><b className="text-underline">If you believe this is a mistake or would like to reinstate your IntuiDesk user account</b> please contact IntuiDesk Support.</p>
                </CardBody>
                <CardFooter>
                <p className="text-sm mb-0">
                    <small>
                        <b>Time: {' '}
                            { moment.utc(time, 'x').format('MMMM Do, YYYY')  }
                            {' at '}
                            { moment.utc(time, 'x').format('hh:mm A')  } (GMT)
                        </b>
                    </small>
                </p>
                </CardFooter>
            </Card>
        </Container>
    )
}

export default ThreatDetected;
