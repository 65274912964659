
import { connect } from 'react-redux';
import IntuideskSection from "components/markup/layout/IntuideskSection";

import CallFlows from './CallFlows'
import CallPhoneNumbers from './CallPhoneNumbers'
import CallQueues from './CallQueues'
import CallBlockedNumbers from './CallBlockedNumbers'

const CommunicationView = ({match, selected_division}) => (
    <IntuideskSection 
        title={`Communication ${selected_division ? ' - ' + selected_division.name : ''}`}
        section="communication"
        defaultRoute="call_phone_numbers"
        match={match}
        pages={[
                // { value: 'edit', icon: 'fas fa-paper-plane', name: 'Email Senders',  Component: IntuideskSection },
                // { value: 'integrations', icon: 'fas fa-envelope', name: 'Email Templates',  Component: IntuideskSection },
                // { value: 'billing', icon: 'fas fa-mobile', name: 'Text Templates',  Component: IntuideskSection },
                { value: 'call_phone_numbers', icon: 'fas fa-phone', name: 'Phone Numbers',  Component: CallPhoneNumbers },
                { value: 'call_flows', icon: 'fas fa-code-branch', name: 'Call Routing',  Component: CallFlows },
                { value: 'call_queues', icon: 'fas fa-users', name: 'Call Queues',  Component: CallQueues },
                { value: 'call_blocked_numbers', icon: 'fas fa-times', name: 'BlackList',  Component: CallBlockedNumbers },
                // { value: 'branding', icon: 'fas fa-users', name: 'User Roles',  Component: IntuideskSection },
                // { value: 'billing', icon: 'fas fa-scroll', name: 'Scripts',  Component: IntuideskSection },
        ]}
    />
)


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(CommunicationView);

