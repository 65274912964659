import { connect } from 'react-redux';

import SearchCollections from "components/system/Search/Collections";

const ActionCompleteTask = ({ node, onInputChange, selected_division }) => (
    <div>

        <div className='sidebar-section border-bottom'>
            <SearchCollections
                collection={'task_templates'}
                title="**Select The Task To Complete"
                placeholder="Search..."
                value={node.data.task_template}
                onChange={(obj) => onInputChange('task_template', obj.value)}
                filter={{
                    division: selected_division._id,
                }}
            /> 
        </div>

        <div className='sidebar-section border-bottom'>
            <p className='text-sm'>
                <i className="fas fa-info-circle text-info" /> This action will mark all tasks on a matter that match the template selected above as complete. 
            </p>
            <p className='text-sm mb-0'>
                Note the word <b className='text-underline'>ALL</b> - If multiples tasks are unfinished that share the task template above they will be marked as finished when this task runs.
            </p>
        </div>

    </div>
)



const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(ActionCompleteTask);