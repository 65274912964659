import WorkflowContactSelection from 'components/system/WorkflowContactSelection';

const AutomationsEditEmailToContacts = ({toContacts, setToContacts}) => {

    return (
        <div>

            <h2>To Parties</h2>

            <WorkflowContactSelection 
                contacts={toContacts} 
                onChange={(workflow_contacts) => setToContacts(workflow_contacts)} 
            />
        </div>
    )

}

export default AutomationsEditEmailToContacts