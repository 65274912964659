const minute = 60
const hour = 3600
const day = 86400

const timeValues = [
    { label: 'Immediately', value: 0 },
    { label: '1 Minute', value: minute },
    { label: '5 Minutes', value: minute * 5 },
    { label: '10 Minutes', value: minute * 10 },
    { label: '15 Minutes', value: minute * 15 },
    { label: '20 Minutes', value: minute * 20 },
    { label: '30 Minutes', value: minute * 30 },
    { label: '45 Minutes', value: minute * 45 },
    { label: '1 Hour', value: hour },
    { label: '2 Hours', value: hour * 2 },
    { label: '3 Hours', value: hour * 3 },
    { label: '4 Hours', value: hour * 4 },
    { label: '8 Hours', value: hour * 8 },
    { label: '16 Hours', value: hour * 16 },
    { label: '1 Day', value: day * 1 },
    { label: '2 Days', value: day * 2 },
    { label: '3 Days', value: day * 3 },
    { label: '4 Days', value: day * 4 },
    { label: '5 Days', value: day * 5 },
    { label: '6 Days', value: day * 6 },
    { label: '7 Days', value: day * 7 },
    { label: '10 Days', value: day * 10 },
    { label: '14 Days', value: day * 14 },
    { label: '21 Days', value: day * 21 },
    { label: '28 Days', value: day * 28 },
    { label: '30 Days', value: day * 30 },
    { label: '60 Days', value: day * 60 },
    { label: '90 Days', value: day * 90 },
    { label: '180 Days', value: day * 180 },
    { label: '365 Days', value: day * 365 },
]

export default timeValues