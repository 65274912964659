import { Input, FormGroup } from "reactstrap";
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import { useCallback, useState, useEffect } from 'react';

import api from 'api'

import Modalify from 'components/functional/modals/Modalify'
import Circle from 'components/markup/loading/Circle'

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';
import ReactSelect from 'components/functional/inputs/ReactSelect'

import Answers from './Answers'
import LinkedFields from './LinkedFields'

import SearchCollections from "components/system/Search/Collections";

import Associations from 'components/system/Associations'

const baseState = {
    name: '',
    description: '',
    type: '',
    ai_require_in_extraction: false,
    answers: [],
    linked_fields: [],
}

const SystemCustomFieldsEdit = ({_id, onSaved, showModal, toggleModal, selected_division}) => {

    const [customField, setCustomField] = useState(null)
    const [loading, setLoading] = useState(null)
    const [err, setErr] = useState(null)
    const [redirect, setRedirect] = useState(null)

    const fetchData = useCallback(async () => {
        if(!_id || _id === 'create') return setCustomField(baseState);
        const customField = await api.custom_fields.findById(_id)
        if(!customField.data) return setErr(true);
        setCustomField(customField.data)
    }, [_id])

    const onInputChange = useCallback(( field, value) => {
        const newCustomField = Object.assign({}, customField)
        newCustomField[field] = value;
        setCustomField(newCustomField);
    }, [customField])

    const onSave = useCallback(async () => {
        setErr(false)
        let newState = Object.assign({}, customField);

        if(!newState.name || !newState.type) {
            return setErr([`A custom_field must have a name and a type.`])
        }
        if((newState.type === 'select' || newState.type === 'checkbox') && newState.answers.length < 2) {
            return setErr([`A custom field must have at least 2 options if the type is "Select".`])
        }
        
        setCustomField(newState)

        let saved;

        // toggle loaders and update/create the customField
        toggleStandardLoader(true)
        if(newState._id) {
            saved = await api.custom_fields.update(newState._id, newState, true);
        } else {
            saved = await api.custom_fields.create({...newState, division: selected_division._id}, true);
        }
        toggleStandardLoader(false)

        // show error messages if unsuccessful
        if(saved.success) {
            toggleAlertBS(false, "Custom Field Saved Successfully")
            fetchData();
            setLoading(true)
            setTimeout(() => setLoading(false), 500)
            if(toggleModal) toggleModal();
            if(onSaved) onSaved(saved.data)
        } else {
            return setErr(saved.message);
        }
    
    }, [customField, fetchData, toggleModal, onSaved, selected_division._id])

    useEffect(() => {
        if(toggleModal) {
            if(showModal) {
                fetchData()
            } else {
                setErr(null)
                setCustomField(baseState)
            }
        } else {
            fetchData()
        }
    }, [showModal, toggleModal, fetchData])

    useEffect(() => {
        if(!selected_division._id) {
            toggleAlertBS('info', 'A division must be created for editing a custom_field.')
            setRedirect('/system/divisions/create')
        }
    }, [selected_division._id])

    if(redirect) return <Redirect to={redirect} />

    return (
        <Modalify 
            showModal={showModal}
            toggleModal={toggleModal}
            modalTitle={customField && customField._id ? "Update Custom Field" : "Create Custom Field"}
            err={err}
            footer={(
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            )}
            sections={!customField ? [] : loading ? <Circle /> :  [
                (
                    <div>

                      
                        <FormGroup>
                            <label className="form-control-label">Name*</label>
                            <Input 
                                type="text"
                                value={customField.name || ''}
                                onChange={e => onInputChange('name', e.target.value)}
                            />
                        </FormGroup>
                       
                        <FormGroup>
                            <label className="form-control-label">Description</label>
                            <Input 
                                type="text"
                                value={customField.description || ''}   
                                onChange={e => onInputChange('description', e.target.value)}
                            />
                        </FormGroup>

                        <ReactSelect 
                            formGroup={true}
                            title="Field Type*"
                            value={customField.type}
                            onChange={(obj) => onInputChange('type', obj.value)}
                            options={[
                                { value: 'text', label: 'Text' },
                                { value: 'select', label: 'Drop Down' },
                                { value: 'checkbox', label: 'Check Box' },
                                { value: 'number', label: 'Number' },
                                { value: 'phone', label: 'Phone Number' },
                                { value: 'address', label: 'Address' },
                                { value: 'state', label: 'State' },
                                { value: 'date', label: 'Date' },
                                { value: 'yes-no', label: 'Yes or No' },
                                { value: 'contact_type', label: 'Contact Type' },
                                { value: 'location_type', label: 'Location Type' },
                                { value: 'user', label: 'User' },
                            ]}
                        />
                       
                        {customField.type === 'contact_type' ? (
                            <SearchCollections
                                collection={'contact_types'}
                                title={<span>Select A Contact Type</span>}
                                value={customField.contact_type}
                                onChange={(obj) => onInputChange('contact_type', obj.value)}
                                filter={{
                                    division: selected_division._id,
                                    deleted: false
                                }}
                            /> 
                        ) : ''}
                      
                        {customField.type === 'location_type' ? (
                            <SearchCollections
                                collection={'location_types'}
                                title={<span>Select A Location Type</span>}
                                placeholder="Search Location Types..."
                                value={customField.location_type}
                                onChange={(obj) => onInputChange('location_type', obj.value)}
                                filter={{
                                    division: selected_division._id,
                                }}
                            /> 
                        ) : ''}

                        {customField.type === 'select' || customField.type === 'checkbox' ? (
                            <Answers 
                                answers={customField.answers}
                                onAnswerChange={(_answers) => onInputChange('answers', _answers)}
                            />
                        ) : ''}
                      
                        {customField.type === 'select' ? (
                            <LinkedFields 
                                setCustomField={setCustomField}
                                parentField={customField}
                                linkedFields={customField.linked_fields}
                                onAnswerChange={(_answers) => onInputChange('answers', _answers)}
                            />
                        ) : ''}

                        <hr />

                        <ReactSelect 
                            formGroup={true}
                            title="AI: Try To Populate This Field When Extracting Data*"
                            value={customField.ai_require_in_extraction}
                            onChange={(obj) => onInputChange('ai_require_in_extraction', obj.value)}
                            options={[
                                { value: false, label: 'No' },
                                { value: true, label: 'Yes' },
                            ]}
                        />

                    </div>
                ),

                ...(customField._id ? [(
                    <Associations 
                        type="custom_field" 
                        _id={customField._id} 
                        classNames="border p-3 bg-secondary" 
                    />
                )] : []),
            ]}
        />
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SystemCustomFieldsEdit);