import { useCallback } from 'react';
import { connect } from 'react-redux';

import ObjectFinder from 'components/system/Objects/Finder';
import SearchCollections from 'components/system/Search/Collections';

import { Badge } from 'reactstrap';

const SystemCallFlowEdit = ({task, onSetTask, selected_division}) => {

    const users_voicemail = task.users_voicemail;

    const onAddUser = useCallback((user) => {
        const _users = users_voicemail ? JSON.parse(JSON.stringify(users_voicemail)) : [];
        if(!_users.includes(user) ) _users.push(user)
        onSetTask('users_voicemail', _users)
    }, [users_voicemail, onSetTask])
    
    const onRemoveUser = useCallback((user) => {
        let _users = users_voicemail ? JSON.parse(JSON.stringify(users_voicemail)) : [];
        _users = _users.filter(u => u !== user);
        onSetTask('users_voicemail', _users)
    }, [users_voicemail, onSetTask])

    return (
        <div className='mb-4'>
                    
            <hr />

            <div>
                <SearchCollections
                    collection="users" 
                    title={`Select User(s) To Assign The Voicemail To`}
                    value={null}
                    onChange={(obj) => onAddUser(obj.value)}
                    filter={{
                        division: selected_division._id
                    }}
                /> 

                <p className='text-sm mb-0'>Selected Users</p>

                {users_voicemail && users_voicemail.length ? users_voicemail.map((u, i) => (
                    <Badge key={u} color="success" className='text-sm'>
                        <ObjectFinder collection="users" _id={u} />
                        <i className="fas fa-times pl-3 cursor-pointer" onClick={() => onRemoveUser(u)} />
                    </Badge>
                )) : (
                    <Badge color="warning">No Users Selected</Badge>
                )}

            </div>

        </div>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SystemCallFlowEdit);