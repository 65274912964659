import TagsEdit from './components/Edit'
import TagsAll from './All'

import IntuideskSectionWrapper from 'components/markup/layout/IntuideskSectionWrapper'

const SystemTags = ({match, section}) => (
    <IntuideskSectionWrapper 
        title="Tags"
        editTitle="Tag"
        match={match}
        section={section}
        innerSection={'tags'}
        Edit={TagsEdit}
        All={TagsAll}
    />
)

export default SystemTags;