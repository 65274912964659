import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row } from "reactstrap";


import BarLine from 'components/charts/BarLine';
import PieArea from 'components/charts/PieArea';

import Table from './Table';

const LocationsAll = ({setTab, selected_division}) => {

    

    return (

        <>


        <Row>

            <Col md={6} className="">
                <BarLine 
                    type="line"
                    title="Predicted 12 Month Revenue"
                    labels={[  '-Jan', '-Feb', '-Mar', '-Apr', '-May', '**Jun', '+July', '+Aug', '+Sep', '+Oct', '+Nov', '+Dec' ]}
                    datasets={[
                        {
                            label: 'Division Revenue',
                            data: [
                                104500, 120412, 140143, 80134, 100314, 129831, 150932, 180943, 200314, 194143, 220134, 194133
                            ],
                        },
                        {
                            label: 'Division Costs',
                            data: [
                                90415, 93134, 87134, 94234, 94123, 85143, 85143, 85143, 85143, 85143, 85143, 85143
                            ],
                        },
                    ]}
                />
              
            </Col>

           <Col md={6}>
                <Row>
                    <Col md={6} className="text-center ">
                        <Card className='text-center bg-secondary'>
                            <CardHeader className='bg-secondary'>
                                <CardTitle className="mb-0">Last 6 Months Of Leads</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-3 mb-0'>502</h2>
                            </CardBody>
                            <CardFooter className='bg-secondary'>
                                12 Weeks Ago: <b className='text-dark font-weight-bold '>$29,583</b>
                            </CardFooter>
                        </Card>

                        <i className="fas fa-arrow-down mb-4" />

                        <Card className='text-center'>
                            <CardHeader className='bg-secondary'>
                                <CardTitle className="mb-0">Hires</CardTitle>
                            </CardHeader>
                            <CardBody className='bg-secondary'>
                                <h2 className='display-3 mb-0'>104 <small><small className='text-muted pl-4'>33%</small></small></h2>
                            </CardBody>
                            <CardFooter className='bg-secondary'>
                                Cost: <b className='text-dark font-weight-bold '>$60,491</b>
                            </CardFooter>
                        </Card>

                        <i className="fas fa-arrow-down mb-4" />

                        <Card className='text-center'>
                            <CardHeader className='bg-secondary'>
                                <CardTitle className="mb-0">Won</CardTitle>
                            </CardHeader>
                            <CardBody className='bg-secondary'>
                                <h2 className='display-3 mb-0'>20 <small><small className='text-muted pl-4'>22%</small></small></h2>
                            </CardBody>
                            <CardFooter className='bg-secondary'>
                                Value: <b className='text-dark font-weight-bold '>$10,583</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md={6} className="text-center">
                        <Card className='text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Last 6 Months Of Leads</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-3 mb-0'>502 </h2>
                            </CardBody>
                            <CardFooter>
                            Cost: <b className='text-dark font-weight-bold '>$60,491</b>
                            </CardFooter>
                        </Card>

                        <i className="fas fa-arrow-down mb-4" />

                        <Card className='text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Expected Hires</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-3 mb-0'>154 <small><small className='text-muted pl-4'>50%</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                Value: <b className='text-dark font-weight-bold '>$129,583</b>
                            </CardFooter>
                        </Card>

                        <i className="fas fa-arrow-down mb-4" />

                        <Card className='text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Expected Won</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-3 mb-0'>100 <small><small className='text-muted pl-4'>75%</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                Value: <b className='text-dark font-weight-bold '>$100,583</b>
                            </CardFooter>
                        </Card>

                        <i className="fas fa-arrow-down mb-4" style={{transform: 'rotate(45deg)'}} />

                    </Col>
                </Row>

                <Card className='text-center'>
                    <CardHeader>
                        <CardTitle className="mb-0">Expected Profit:</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <h2 className='display-3 mb-0'>$243,000</h2>
                    </CardBody>

                </Card>


           </Col>

        </Row>


{/* 

        <BarLine 
            horizontal={true}
            type="bar"
            title="Overall Lead Pipeline"
            labels={[ 'Pipeline']}
            datasets={[
                {
                    label: 'Dead',
                    data: [8],
                },
                {
                    label: 'DNQ',
                    data: [14],
                },
                {
                    label: 'Potential',
                    data: [7],
                },
                {
                    label: 'Converted',
                    data: [1],
                },
            ]}
        />

        <Table /> */}

        </>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(LocationsAll);