import { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux';
import { Modal, FormGroup, Input, Badge } from "reactstrap";
import { useCallback } from 'react';

import ApiError from 'components/markup/layout/ApiError'
import Circle from 'components/markup/loading/Circle'

import api from 'api'

import { toast } from 'react-toastify'

import SearchCollections from "components/system/Search/Collections";
import ObjectFinder from 'components/system/Objects/Finder';

import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';

Quill.register('modules/imageResize', ImageResize);

const ModalDivisionOverride = ({showModal, toggleModal, selected_division, user_id, onSaved}) => {

    const ref = useRef(null)

    const [override, setOverride] = useState(null)
    const [err, setErr] = useState(null)

    const onInputChange = useCallback((field, e) => {
        const val = e.target.value;
        const _override = JSON.parse(JSON.stringify(override))

        _override[field] = val;
        setOverride(_override)
    }, [override])
 
    const onAddLeadSource = useCallback((source) => {
        const _override = JSON.parse(JSON.stringify(override))
        if(!_override.viewable_lead_sources) _override.viewable_lead_sources = [];
        if(!_override.viewable_lead_sources.includes(source)) _override.viewable_lead_sources.push(source)

        setOverride(_override)
    }, [override])
   
    const onRemoveLeadSource = useCallback((source) => {
        let _override = JSON.parse(JSON.stringify(override))
        _override.viewable_lead_sources = _override.viewable_lead_sources.filter(a => a !== source);
        setOverride(_override)
    }, [override])

    const onSave = useCallback(async () => {
        if(!override.title) return setErr([`A title must be entered to set a division override`]) 
        // if(!override.email) return setErr([`An email must be entered to set a division override`]) 
        if(!override.rate_hourly && override.rate_hourly !== 0) return setErr([`A hourly rate must be entered to set a division override`]) 
        if(!override.rate_billable && override.rate_billable !== 0) return setErr([`A billable rate must be entered to set a division override`]) 

        const updated = await api.users.divisionOverride(user_id, {
            ...override,
            rate_hourly: parseInt(override.rate_hourly),
            rate_billable: parseInt(override.rate_billable)
        })
        if(!updated.success) return setErr(updated.message)

        toast.success(`Division override successfully updated`)
        toggleModal()
        onSaved()

    }, [override, user_id, toggleModal, onSaved])

    const fetchUser = useCallback(async () => {
        const user = await api.users.findById(user_id)
        if(!user.data) return setErr(user.message)

        const override = user.data.division_overrides.find(d => d.division === selected_division._id);
        if(override) {
            setOverride(override)
        } else {
            setOverride({
                title                   : user.data.title,
                email                   : user.data.email,
                rate_hourly             : user.data.rate_hourly,
                rate_billable           : user.data.rate_billable,
                division                : selected_division._id,
                viewable_lead_sources   : []
            })
        }
    }, [user_id, selected_division._id])

    useEffect(() => {
        fetchUser()
    }, [fetchUser])

    return (

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">Division Overrides</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>x</span>
                </button>
            </div>

            <div className="modal-body border-bottom bg-secondary">
                <p className="text-sm mb-0">Division Overrides are used to change key information about a user depending on the division they are working in. For instance their title on an email, email address, and billable rate.</p>
            </div>

            {!override ? <Circle className="py-4" /> : (
                  <div className="modal-body">

                    <FormGroup>
                        <label className='form-control-label'>User Title</label>
                        <Input 
                            type="text"
                            value={override.title}
                            onChange={(e) => onInputChange('title', e)}
                        />
                    </FormGroup>
                    <hr />
                    <div className=''>
                        <FormGroup>
                            <label className='form-control-label'>User Email & Signature</label>
                            <Input 
                                type="text"
                                value={override.email}
                                onChange={(e) => onInputChange('email', e)}
                            />
                        </FormGroup>

                        <ReactQuill 
                            id="test"
                            defaultValue={override.email_signature}
                            value={override.email_signature}
                            ref={ref}
                            onChange={(value) => onInputChange('email_signature', { target: { value: value } })} 
                            theme="snow"
                            modules={{
                                toolbar: [
                                 [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                 ["bold", "italic", "underline"],
                                 ["link", "image"],
                                ],
                                imageResize: {
                                    modules: ["Resize", "DisplaySize"],
                                  },
                               }}
                        />
                    </div>
                    <hr />
                    <FormGroup>
                        <label className='form-control-label'>Hourly Rate</label>
                        <Input 
                            value={override.rate_hourly}
                            onChange={(e) => onInputChange('rate_hourly', e)}
                        />
                    </FormGroup>
                    
                    <FormGroup>
                        <label className='form-control-label'>Billable Rate</label>
                        <Input 
                            value={override.rate_billable}
                            onChange={(e) => onInputChange('rate_billable', e)}
                        />
                    </FormGroup>

                    <SearchCollections
                        collection={'lead_sources'}
                        description="If set, when this user creates a new matter the following lead source will be affixed to it."
                        title={<span>Lead Source </span>}
                        placeholder="Search Lead Sources..."
                        value={override.lead_source}
                        onChange={(obj) => onInputChange('lead_source', { target: { value: obj.value } })}
                        filter={{
                            division: selected_division._id,
                        }}
                    /> 
                    {override.lead_source ? (
                        <Badge 
                            color='warning ' 
                            className='cursor-pointer' 
                            onClick={() => onInputChange('lead_source', { target: { value: '' } })} 
                        >
                            Remove Source
                        </Badge>
                    ) : ''}

                    <hr />

                    <SearchCollections
                        collection={'lead_sources'}
                        description="If set and a user's permissions are not set to view all lead sources, only matters and leads from the following sources will appear for them to view."
                        title={<span>Viewable Lead Sources</span>}
                        hideSelected={true}
                        placeholder="Search Lead Sources..."
                        value={{}}
                        onChange={(obj) => onAddLeadSource(obj.value)}
                        filter={{
                            division: selected_division._id,
                        }}
                    /> 

                    {override.viewable_lead_sources && override.viewable_lead_sources.length ? override.viewable_lead_sources.map(s => (
                        <Badge color="info" >
                            <ObjectFinder collection="lead_sources" _id={s} /> <i onClick={() => onRemoveLeadSource(s)} className="fas fa-times pl-3 cursor-pointer" />
                        </Badge>
                    )) : ''}

    
                    {err ? (
                        <div>
                            <hr />
                            <ApiError err={err} />
                        </div>
                    ) : ''}
    
                </div>
            )}

          

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>

                <button className="btn btn-success" onClick={onSave}>
                    Save Override
                </button>
            </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
	    selected_division: state.state.selected_division,
    };
};

export default connect(mapStateToProps, '')(ModalDivisionOverride);