import { useCallback, useEffect, useState } from 'react';
import { Modal } from 'reactstrap';

import StepTracker from 'components/functional/progress/StepTracker'

import getMarkerErrors from '../../_functions/getMarkerErrors'

import ModalErrors from './ModalErrors'
import Tab1 from './Tab1'
import Tab2 from './Tab2'
import Tab3 from './Tab3'
import Tab4 from './Tab4'

const SendDocument = ({showModal, toggleModal, isTemplate, markers, contacts, onSendDocument, requiredInternalAnswers, updateMarkerName}) => {

    const [validationErr, setValidationErr] = useState(false);
    const [errs, setErrs] = useState([]);
    const [tab, setTab] = useState(1);

    const [requiredAnswers, setRequiredAnswers] = useState({})

    const [usePassword, setUsePassword] = useState({
        password1: false,
        password2: false,
        password3: false,
        password4: false,
    })

    const [hints, setHints] = useState({
        hint1: '',
        hint2: '',
        hint3: '',
        hint4: '',
    })
    
    const [passwords, setPasswords] = useState({
        password1: '',
        password2: '',
        password3: '',
        password4: '',
    })

    const onSetRequiredAnswers = useCallback((field, value) => {
        const _requiredAnswers = JSON.parse(JSON.stringify(requiredAnswers));
        _requiredAnswers[field] = value;
        setRequiredAnswers(_requiredAnswers);
    }, [requiredAnswers])

    const onSetHints = useCallback((field, value) => {
        const _hints = JSON.parse(JSON.stringify(hints));
        _hints[field] = value;
        setHints(_hints);
    }, [hints])

    const onSetPasswords = useCallback((field, value) => {
        const _passwords = JSON.parse(JSON.stringify(passwords));
        _passwords[field] = value;
        setPasswords(_passwords);
    }, [passwords])
  
    const onSetUsePasswords = useCallback((field, value) => {
        const _usePassword = JSON.parse(JSON.stringify(usePassword));
        _usePassword[field] = value;
        setUsePassword(_usePassword);
    }, [usePassword])

    const onMoveOn = useCallback(() => {

        if(tab === 2) {
            let err = false;
            requiredInternalAnswers.forEach(answer => {
                if(!requiredAnswers[answer._id]) {
                    return err = true;
                }
            })
            if(err) return setValidationErr('All answers must be filled out before moving on.')
        } if(tab === 3) {
            if(usePassword.password1) {
                if(!passwords.password1 || !hints.hint1) {
                    return setValidationErr('If a password is enabled it must have both a hint and a valid password added.')
                }
            }
            if(usePassword.password2) {
                if(!passwords.password2 || !hints.hint2) {
                    return setValidationErr('If a password is enabled it must have both a hint and a valid password added.')
                }
            }
            if(usePassword.password3) {
                if(!passwords.password3 || !hints.hint3) {
                    return setValidationErr('If a password is enabled it must have both a hint and a valid password added.')
                }
            }
            if(usePassword.password4) {
                if(!passwords.password4 || !hints.hint4) {
                    return setValidationErr('If a password is enabled it must have both a hint and a valid password added.')
                }
            }
        }
        setValidationErr(false);
        setTab(tab + 1);
    }, [tab, passwords, hints, usePassword, requiredInternalAnswers, requiredAnswers])
    
    const onMoveBack = useCallback(() => {
        setTab(tab - 1);
        setValidationErr(false);
    }, [tab])

    useEffect(() => {
        if(showModal) {
            setErrs(getMarkerErrors(markers, contacts, isTemplate))
            setTab(1)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal])

    if(errs.length) return <ModalErrors showModal={showModal} toggleModal={toggleModal} errs={errs} />

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Send Document</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body bg-secondary py-3">
                <StepTracker 
                    current={tab}
                    steps={['Review Parties', 'Confirm Fields', 'Security', 'Send']}
                />
            </div>
           
            {tab === 1 ? (
                <Tab1 
                    contacts={contacts} 
                />
            ) : tab === 2 ? (
                <Tab2 
                    markers={markers} 
                    isTemplate={isTemplate} 
                    requiredInternalAnswers={requiredInternalAnswers} 
                    updateMarkerName={updateMarkerName} 
                    onSetRequiredAnswers={onSetRequiredAnswers} 
                    requiredAnswers={requiredAnswers} 
                />
            ) : tab === 3 ? (
                <Tab3 
                    contacts={contacts} 
                    onSetHints={onSetHints}
                    onSetPasswords={onSetPasswords}
                    onSetUsePasswords={onSetUsePasswords}
                    usePassword={usePassword}
                    hints={hints}
                    passwords={passwords}
                /> 
            ) : tab === 4 ? (
                <Tab4 
                    contacts={contacts}
                    hints={hints}
                    passwords={passwords}
                    usePassword={usePassword}
                />
            )
            : null}

            {validationErr ? (
                <div className="modal-body bg-secondary">
                    <p className="text-sm mb-0 text-danger text-center">{validationErr}</p>
                </div>
            ): null}

            <div className="modal-footer">
            {tab !== 1 ? (
                <button onClick={onMoveBack} className="btn btn-outline-warning">
                    <i className="fas fa-arrow-left " /> Back
                </button>
            ) : null}
            {tab !== 4 ? (
                <button onClick={onMoveOn} className="btn btn-outline-success">
                    <span>Next <i className="fas fa-arrow-right ml-2 " /></span>
                </button>
            ) : (
                <button onClick={() => onSendDocument(usePassword, passwords, hints, requiredAnswers)} className="btn btn-outline-success">
                    <span>Send <i className="fas fa-paper-plane ml-2 " /></span>
                </button>
            )}
            </div>

        </Modal>
    )
}

export default SendDocument;
