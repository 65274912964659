import { useCallback, useState } from "react";
import { connect } from 'react-redux';
import { Card, Badge } from "reactstrap";

import api from 'api';

import ConfirmationModal from 'components/functional/modals/Confirmation';
import ModalToggler from 'components/functional/modals/Toggler';
import Table from 'components/functional/tables/Standard';
import TableIcons from 'components/markup/icons/TableIcons';
import ApiError from "components/markup/layout/ApiError";
import TextButtonRow from 'components/markup/layout/TextButtonRow';
import Circle from 'components/markup/loading/Circle';

import CallPhoneNumbersEdit from '../components/Edit';

import formatText from 'utils/formatText';

import { toast } from 'react-toastify'
import { setAssociationErrors } from 'store/functions/system/system'

const CallPhoneNumbersAll = ({setTab, selected_division}) => {

    const [ loading, setLoading ] = useState(false);
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ queueToDelete, setCallQueueToDelete ] = useState(false)
    const [ error, setError ] = useState(false);


    // helper for this.downloadCSV and this.query
    const fetchData = useCallback((params, isCSV, shouldReload) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(shouldReload) setLoading(true)
        const query = await api.call_queues.search({ ...params, filter: { division: selected_division._id }, isCSV })
        if(shouldReload) setLoading(false)

        if(!query.data) return setError(query.message)
        return resolve(query.data)
    }), [sizePerPage, selected_division._id])

    // run query to get data from the server
    const query = useCallback(async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }, [fetchData])

    const onDeleteCallPhoneNumber = useCallback(async (location) => {
        const deleted = await api.call_queues.delete(queueToDelete._id);
      
        if(deleted.success) {
            fetchData(null, null, true);
            toast.success('Phone Number Successfully Removed')
        } else {
            if(deleted.message[0] && deleted.message[0].err === 'associations') {
                setAssociationErrors(deleted.message[0].associations)
            } else {
                toast.error(`Internal Error, Please Try Again`)
            }
        }
    }, [fetchData, queueToDelete])
 
    const columns = [
        {
            dataField: "given_name",
            text: 'Name',
            headerFormatter: () => {
                return <div id="archk-tutorial-contacts-name">Name</div>
            },
            formatter: (cell, row) => {
                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )
                return (
                    <span className="cursor-pointer">
                        <ModalToggler component={CallPhoneNumbersEdit} _id={row._id} onSaved={() => fetchData(null, null, true)}>
                            {row.name}
                        </ModalToggler>
                    </span>
                )
                
            },
        },

        
        {
            dataField: "priority",
            text: 'Priority',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : row.priority
            )
        },
        {
            dataField: "users",
            text: 'Users',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : row.users.length
            )
        },
        {
            dataField: "supervisors",
            text: 'Supervisors',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : row.supervisors.length
            )
        },
        {
            dataField: "max_wait_time",
            text: 'Max Wait Time',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : row.max_wait_time ? formatText(row.max_wait_time).secondsToTime() : '-'
            )
        },
    
        {
            dataField: "callback",
            text: 'Callback',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : row.callback ? 
                    <Badge style={{width: 80}} color="success" className="text-sm">Yes</Badge> : 
                    <Badge style={{width: 80}} color="danger" className="text-sm">No</Badge>
            )
        },
      
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right',},
            text: 'Actions',
            headerFormatter: () => {
                return <div id="archk-tutorial-contacts-actions">Actions</div>
            },
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                         <TableIcons
                            icons={[
                                { 
                                    icon: 'fas fa-edit',
                                    color: 'success', 
                                    wrapper: ModalToggler, 
                                    component: CallPhoneNumbersEdit,
                                    onSaved: () => fetchData(null, null, true),
                                    _id: row._id
                                },
                                {  
                                    icon: 'fas fa-trash', 
                                    color: 'danger',
                                    onClick: () => setCallQueueToDelete(row)
                                },
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(error) return <ApiError err={error} />
    if(loading) return <div><Circle /></div>

    return (

        <>

            <TextButtonRow 
                title={"Call Queues"}
                button={(
                    <div>
                        <button onClick={() => setTab('create')} className="btn btn-success"><i className="fas fa-plus mr-2 " /> Add New</button>
                    </div>
                )}
            />

            <Card className="card-color card-primary table-fixed">
                <Table
                    tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                    placeholder="Search..."
                    query={query}
                    columns={columns}
                    data={tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}
                    sizePerPage={sizePerPage}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>

            <ConfirmationModal 
                showModal={queueToDelete ? true : false}
                toggleModal={() => setCallQueueToDelete(null)}
                title="Delete Call Queue"
                body={(
                    <span>
                        Are you sure you wish to delete the call queue {queueToDelete ? queueToDelete.name : ''}? If so make sure your phone systems is not currently directing calls here before doing so.
                    </span>
                )}
                onConfirmation={onDeleteCallPhoneNumber}
            />

        </>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(CallPhoneNumbersAll);