import { Input, FormGroup, Row, Col } from "reactstrap";
import { connect } from 'react-redux';
import { useCallback, useState, useEffect } from 'react';

import api from 'api'

import Modalify from 'components/functional/modals/Modalify'
import InputStates from 'components/markup/inputs/States'

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';
import { setCompany as refreshCompany } from 'store/functions/company/company'

const CompanyUpdateAddresses = ({onSaved, showModal, toggleModal, viewing_user}) => {

    const [company, setCompany] = useState(null)
    const [err, setErr] = useState(null)

    const fetchData = useCallback(async () => {
        const company = await api.companies.findById(viewing_user.company)
        if(!company.data) return setErr(true);
        setCompany(company.data)
    }, [viewing_user.company])

    const onInputChange = useCallback(( field, value) => {
        const newCompany = Object.assign({}, company)
        newCompany[field] = value;
        setCompany(newCompany);
    }, [company])

    const onSave = useCallback(async () => {
        setErr(false)
        let newState = Object.assign({}, company);

        if(!newState.name) {
            return setErr([`A company must have a name.`])
        }
        
        setCompany(newState)

        // toggle loaders and update/create the company
        toggleStandardLoader(true)
        let saved = await api.companies.update(newState._id, {
            address_line_1            : newState.address_line_1,
            address_line_2            : newState.address_line_2,
            city                      : newState.city,
            state                     : newState.state,
            postal_code               : newState.postal_code,
          
            billing_address_line_1    : newState.billing_address_line_1,
            billing_address_line_2    : newState.billing_address_line_2,
            billing_city              : newState.billing_city,
            billing_state             : newState.billing_state,
            billing_postal_code       : newState.billing_postal_code,
        }, true);
        toggleStandardLoader(false)

        // show error messages if unsuccessful
        if(saved.success) {
            toggleAlertBS(false, "Company Saved Successfully")
            fetchData();
            refreshCompany();
            if(toggleModal) toggleModal();
            if(onSaved) onSaved(saved.data)
        } else {
            return setErr(saved.message);
        }
    
    }, [company, fetchData, toggleModal, onSaved])

    useEffect(() => {
        if(toggleModal) {
            if(showModal) {
                fetchData()
            } else {
                setErr(null)
                setCompany('')
            }
        } else {
            fetchData()
        }
    }, [showModal, toggleModal, fetchData])

    return (
        <Modalify 
            showModal={showModal}
            toggleModal={toggleModal}
            modalTitle={company && company._id ? "Update Company" : "Create Company"}
            err={err}
            footer={(
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            )}
            sections={!company ? [] :  [
                (
                    <div>

                        <h2>Company Address</h2>
        
                        <Row>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Address Line 1</label>
                                    <Input 
                                        type="text"
                                        value={company.address_line_1 || ''}
                                        onChange={e => onInputChange('address_line_1', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Address Line 2</label>
                                    <Input 
                                        type="text"
                                        value={company.address_line_2 || ''}
                                        onChange={e => onInputChange('address_line_2', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                        </Row>
        
                        <Row>
                            <Col lg={4}>
                                <FormGroup>
                                    <label className="form-control-label">City</label>
                                    <Input 
                                        type="text"
                                        value={company.city || ''}
                                        onChange={e => onInputChange('city', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                            <Col lg={4}>
                                <InputStates 
                                    value={company.state}
                                    onChange={state => onInputChange('state', state)}
                                />
                            </Col>
                            <Col lg={4}>
                                <FormGroup>
                                    <label className="form-control-label">Zip</label>
                                    <Input 
                                        type="text"
                                        value={company.postal_code || ''}
                                        onChange={e => onInputChange('postal_code', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                        </Row>
                    </div>
                ),
                (
                    <div>

                        <h2>Billing Address</h2>
        
                        <Row>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Address Line 1</label>
                                    <Input 
                                        type="text"
                                        value={company.billing_address_line_1 || ''}
                                        onChange={e => onInputChange('billing_address_line_1', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Address Line 2</label>
                                    <Input 
                                        type="text"
                                        value={company.billing_address_line_2 || ''}
                                        onChange={e => onInputChange('billing_address_line_2', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                        </Row>
        
                        <Row>
                            <Col lg={4}>
                                <FormGroup>
                                    <label className="form-control-label">City</label>
                                    <Input 
                                        type="text"
                                        value={company.billing_city || ''}
                                        onChange={e => onInputChange('billing_city', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                            <Col lg={4}>
                                <InputStates 
                                    value={company.billing_state}
                                    onChange={state => onInputChange('billing_state', state)}
                                />
                            </Col>
                            <Col lg={4}>
                                <FormGroup>
                                    <label className="form-control-label">Zip</label>
                                    <Input 
                                        type="text"
                                        value={company.billing_postal_code || ''}
                                        onChange={e => onInputChange('billing_postal_code', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                        </Row>
                    </div>
                )
            ]}
        />
    )

}


const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(CompanyUpdateAddresses);