const TableStandardPagination = ({ totalDocuments, size, page, onSizeChange, onPageChange }) => {

    const hasPrev = (page - 1) * size > 0
    const hasPrev2 = (page - 2) * size > 0

    const hasNext = totalDocuments > page * size
    const hasNext2 = totalDocuments > (page + 1) * size

    return (
        <div className="row react-bootstrap-table-pagination">

            <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6">
                <div className="dataTables_length" >
                    <label>
                        {' Show '} 
                        <select 
                            value={size.toString()} 
                            onChange={e => onSizeChange(e.target.value)} 
                            aria-controls="datatable-basic" 
                            className="form-control form-control-sm"
                        >
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select> 
                        {' '}
                        entries. 
                    </label>
                </div>
                <span className="react-bootstrap-table-pagination-total">
                    {'  '}
                    Showing rows {((page - 1) * size ) + 1} 
                    {' to ' }
                    {page * size > totalDocuments ? totalDocuments : page * size}
                    {' of '}
                    {totalDocuments}
                </span>
            </div>

            <div className="react-bootstrap-table-pagination-list col-md-6 col-xs-6 col-sm-6 col-lg-6">
                <ul className="pagination react-bootstrap-table-page-btns-ul">

                    <li className={hasPrev ? 'page-item' : 'disabled page-item'} title="first page" onClick={() => onPageChange(1)} >
                        <button className="page-link">&lt;&lt;</button>
                    </li>

                    <li className={hasPrev ? 'page-item' : 'disabled page-item'} onClick={() => onPageChange(page - 1)} >
                        <button className="page-link">&lt;</button>
                    </li>

                    {hasPrev2 ? (
                        <li  className="page-item" onClick={() => onPageChange(page - 2)} > 
                            <button className="page-link">{page - 2}</button> 
                        </li>
                    ) : ''}
                    
                    {hasPrev ? (
                        <li className="page-item" onClick={() => onPageChange(page - 1)} >
                            <button className="page-link">{page - 1}</button>
                        </li>
                    ) : ''}

                    <li className="active page-item">
                        <button className="page-link">{page}</button>
                    </li>

                    {hasNext ? (
                        <li className="page-item" onClick={() => onPageChange(page + 1)} >
                            <button className="page-link">{page + 1}</button>
                        </li>
                    ) : ''}
                    
                    {hasNext2 ? (
                        <li className="page-item" onClick={() => onPageChange(page + 2)} >
                            <button className="page-link">{page + 2}</button>
                        </li>
                    ) : ''}

                    <li className={hasNext ? 'page-item' : 'disabled page-item'} onClick={() => onPageChange(page + 1)} >
                        <button className="page-link">&gt;</button>
                    </li>

                    <li className={hasNext ? 'page-item' : 'disabled page-item'} onClick={() => onPageChange(Math.ceil(totalDocuments / size))} >
                        <button className="page-link">&gt;&gt;</button>
                    </li>
                </ul>
            </div>

        </div>
    )

}



export default TableStandardPagination