import DivisionsEdit from './components/Edit'
import DivisionsAll from './All'

import IntuideskSectionWrapper from 'components/markup/layout/IntuideskSectionWrapper'

const SystemDivisions = ({match, section}) => (
    <IntuideskSectionWrapper 
        title="Divisions"
        editTitle="Division"
        match={match}
        section={section}
        innerSection={'divisions'}
        Edit={DivisionsEdit}
        All={DivisionsAll}
    />
)

export default SystemDivisions;