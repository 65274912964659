import { Col, Row } from "reactstrap";

import { openDocumentCenter } from 'store/functions/document_center';
import { onDownloadDocument } from 'utils/documents';

import ObjectFinder from 'components/system/Objects/Finder';

const SystemCallFlowEdit = ({task, onUpload}) => (
    <div>

        <hr />

        <Row>
            <Col md={6} className="align-self-center">
                <p className='text-sm mb-0'>Play The Audio Clip:</p>
                <p className='text-sm mb-0'>
                    {task.audio_clip ? 
                        <b className='text-dark font-weight-bold'>
                            <ObjectFinder collection="company_documents" _id={task.audio_clip} />{' '}
                            <i onClick={() => onDownloadDocument(task.audio_clip, 'company_documents')}  className="fas fa-download ml-2 text-info cursor-pointer" />
                        </b> : 
                        <span><i className="fas fa-info-circle text-warning mr-2" /> No Audio Selected</span>
                    }
                </p>
            </Col>

            <Col className='text-right align-self-center'>
                <button onClick={() => openDocumentCenter({url: '/v1/company_documents', onUpload: onUpload})} className="btn btn-info">Upload Documents</button>
            </Col>
        </Row>

        <hr />

    </div>
)

export default SystemCallFlowEdit