
function formatObject (obj) {

    this.obj = obj

}

// capitalize each word in a string
formatObject.prototype.name = function (fallbackName) {

    const obj = this.obj

    if(!obj) return fallbackName ? fallbackName : "No Name"

    if(obj.given_name && obj.family_name) {

        return obj.given_name + ' ' + obj.family_name

    } else if(obj.given_name) {

        return obj.given_name

    } else if(obj.family_name) {

        return obj.family_name

    }

    return fallbackName ? fallbackName : "No Name"


}

formatObject.prototype.address = function () {

    const obj = this.obj
    if(!obj) return '-'

    let line1 = ''
    let line2 = '';

    if(obj.address_line_1 && obj.address_line_2) {
        line1 = obj.address_line_1 + ' - ' + obj.address_line_2;
    } else if(obj.address_line_1) {
        line1 = obj.address_line_1;
    }
    
    if(obj.city) line2 += obj.city;
    if(obj.state) line2 = line2 ? line2 + ', ' + obj.state : obj.state;
    if(obj.postal_code) line2 = line2 ? line2 + ' ' + obj.postal_code : obj.postal_code;

    return line1 && line2 ? <div>{line1}<div>{line2}</div></div> : line1 ? line1 : line2 ? line2 : '-'
}

const exports = (string) => new formatObject(string)


export default exports