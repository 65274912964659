// linked_fields: {
//     type: [{
//         custom_field           : { type: Schema.Types.ObjectId, ref: 'lead_sources', required: true },
//         answer_map: {
//             type: [{
//                 answer          : { type: String, required: true },
//                 linked_answer   : { type: String, default: '' },
//             }],
//             default : []
//         },
//     }],
//     default : []
// },

import { useState, useCallback, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardFooter, CardBody, Row, Col, Input, FormGroup } from 'reactstrap';

import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import SearchCollections from "components/system/Search/Collections";
import Circle from 'components/markup/loading/Circle'
import ReactSelect from 'components/functional/inputs/ReactSelect';


import api from 'api';

const baseState = {
    custom_field: '',
    answer_map: []
}

const Field = ({ linkedField, parentField, onAnswerMapChange, onRemoveField }) => {

    const [err, setErr] = useState(false)
    const [field, setField] = useState(null)

    const fetchField = useCallback(async () => {
        const _field = await api.custom_fields.findById(linkedField.custom_field);
        if(_field.data) return setField(_field.data)
        setErr(true)
    }, [linkedField.custom_field])

    const onSetAnswer = useCallback((parentAnswer, childValue) => {
        const _linkedField = JSON.parse(JSON.stringify(linkedField));
        const index = _linkedField.answer_map.findIndex(aa => aa.answer === parentAnswer);
        const obj = { answer: parentAnswer, linked_answer: childValue };

        if(index !== -1) {
            _linkedField.answer_map[index] = obj;
        } else {
            _linkedField.answer_map.push(obj)
        }

        onAnswerMapChange(_linkedField.answer_map)
    }, [linkedField, onAnswerMapChange])

    useEffect(() => {
        fetchField()
    }, [fetchField])

    return (
        <CardFooter className='bg-secondary border-bottom'>
            {!field ? (
                <Circle className="py-3" />
            ) : err ? (
                <div><i className="fas fa-exclamation-triangle mr-2 text-danger" /> Field Not Found, Set For Deletion</div>
            ) : (
                <div>

                    <table className='table border'>
                        <thead>
                            <tr>
                                <th style={{width: '45%'}}>{field.name}</th>
                                <th style={{width: '45%'}}>Value Map</th>
                                <th className='text-right'>
                                    <button onClick={onRemoveField} className='btn btn-danger' style={{width: 110}}>Remove</button>
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {parentField.answers.map((answer, i) => {
                                const value = linkedField.answer_map.find(a => a.answer === answer);
                                return (
                                    <tr key={i}>
                                        <td>{answer}</td>
                                       
                                        <td>
                                            <ReactSelect
                                                className="mb-0"
                                                menuPlacement="top"
                                                title={""}
                                                formGroup={false}
                                                placeholder=""
                                                hideTitle={true}
                                                onChange={(obj) => onSetAnswer(answer, obj.value)}
                                                options={field.answers.map(aa => { return { label: aa, value: aa }})}
                                                value={value ? value.linked_answer : null}
                                            />    
                                        </td>
                                        <td></td>
                                    </tr>    
                                )
                            })}
                        </tbody>
                    </table>

                </div>
            )}

        </CardFooter>
    )
}

const CustomFieldsEditAnswers = ({linkedFields, parentField, selected_division, setCustomField}) => {

    const [linkedField, setLinkedField] = useState('')

    const _handleKeyDown = useCallback((e) => {
		if (e.key === 'Enter') document.getElementById("archk-add-answer-button").click();
	}, [])

    const onAnswerMapChange = useCallback((custom_field_id, answer_map) => {
        const field = JSON.parse(JSON.stringify(parentField));
        const index = field.linked_fields.findIndex(i => i.custom_field === custom_field_id);

        if(index !== -1) {
            field.linked_fields[index].answer_map = answer_map
        } else {
            field.linked_fields.push({
                custom_field: custom_field_id,
                answer_map
            })
        }
        setCustomField(field)
    }, [parentField, setCustomField])

    const onAddField = useCallback((custom_field_id) => {
        if(!custom_field_id) return;

        const _parent = JSON.parse(JSON.stringify(parentField));
        const _fields = _parent.linked_fields;
        if(_fields.some(f => f.custom_field === custom_field_id)) {
            return toast.info(`The field you have tried to add has already been linked.`)
        }

        _fields.push({
            custom_field: custom_field_id,
            answer_map: []
        })

        setCustomField(_parent);
    }, [parentField, setCustomField])

    const onRemoveField = useCallback((custom_field_id) => {
        if(!custom_field_id) return;

        const _parent = JSON.parse(JSON.stringify(parentField));
        let _fields = _parent.linked_fields;
        _fields = _fields.filter(f => f.custom_field !== custom_field_id)
        _parent.linked_fields = _fields;

        setCustomField(_parent);
    }, [parentField, setCustomField])

    return (
        <>
        <Card>
            <CardHeader>
                <CardTitle>Add Linked Fields</CardTitle>
                <p className="text-sm mb-0"><i className="fas fa-info-circle mr-2 info-circle text-info " /> Adding in fields below allows you to create links between multiple custom fields. For instance, if the answer given for this field is X set the answer of another field to Y. </p>
            </CardHeader>

            <CardFooter>
                <CardFooter>
                    <SearchCollections
                        collection={'custom_fields'}
                        title={<span>Search To Add Field</span>}
                        value={linkedField}
                        onChange={(obj) => setLinkedField(obj.value)}
                        filter={{
                            division: selected_division._id,
                        }}
                    /> 
                </CardFooter>
            </CardFooter>

            <CardFooter className='text-right'>
                <button onClick={() => onAddField(linkedField)} className='btn btn-success'>Add Linked Field</button>
            </CardFooter>
            
            {parentField.linked_fields.map((linkedField, i) => (
                <Field 
                    key={i} 
                    linkedField={linkedField} 
                    parentField={parentField} 
                    onAnswerMapChange={(answer_map) => onAnswerMapChange(linkedField.custom_field, answer_map)} 
                    onRemoveField={() => onRemoveField(linkedField.custom_field)} 
                />
            ))}

          

        </Card>

        </>
    )

}


const mapStateToProps = state => {
    return {
        selected_division: state.state.selected_division,
    };
};

export default connect(mapStateToProps, '')(CustomFieldsEditAnswers);