import IntuideskSection from "components/markup/layout/IntuideskSection";

import Users from './Users'

const SystemView = ({match}) => (
    <IntuideskSection 
        title="Staffing"
        section="staffing"
        defaultRoute="users"
        match={match}
        pages={[
            { value: 'users', icon: 'fas fa-users', name: 'Users', Component: Users },
        ]}
    />
)

export default SystemView;