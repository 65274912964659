import { Input, FormGroup } from "reactstrap";
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import { useCallback, useState, useEffect, useRef } from 'react';

import api from 'api'

import Modalify from 'components/functional/modals/Modalify'

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

import ReactQuill from 'react-quill';
import { toast } from 'react-toastify'

import Associations from 'components/system/Associations'

const SystemMatterDocumentsEdit = ({_id, onSaved, showModal, toggleModal, selected_division}) => {

    const reactQuillRef = useRef();
    const [matter_doc, setMatterDocument] = useState(null)
    const [err, setErr] = useState(null)
    const [redirect, setRedirect] = useState(null)

    const fetchData = useCallback(async () => {
        if(!_id || _id === 'create') return setMatterDocument({});
        const matter_doc = await api.matter_documents.findById(_id)
        if(!matter_doc.data) return setErr(true);
        setMatterDocument(matter_doc.data)
    }, [_id])

    const onInputChange = useCallback(( field, value) => {
        const newMatterDocument = Object.assign({}, matter_doc)
        newMatterDocument[field] = value;
        setMatterDocument(newMatterDocument);
    }, [matter_doc])

    const onSave = useCallback(async () => {
        setErr(false)
        let newState = Object.assign({}, matter_doc);

        if(!newState.name) {
            return setErr([`A matter doc must have a name.`])
        }
        
        setMatterDocument(newState)

        let saved;

        // toggle loaders and update/create the matter_doc
        toggleStandardLoader(true)
        if(newState._id) {
            saved = await api.matter_documents.update(newState._id, newState, true);
        } else {
            saved = await api.matter_documents.create({...newState, division: selected_division._id}, true);
        }
        toggleStandardLoader(false)

        // show error messages if unsuccessful
        if(saved.success) {
            toast.success("Matter Document Saved Successfully")
            if(newState._id) {
                fetchData();
            } else {
                setMatterDocument({})
            }
            if(toggleModal) toggleModal();
            if(onSaved) onSaved(saved.data)
        } else {
            return setErr(saved.message);
        }
    
    }, [matter_doc, fetchData, toggleModal, onSaved, selected_division._id])

    useEffect(() => {
        if(toggleModal) {
            if(showModal) {
                fetchData()
            } else {
                setErr(null)
                setMatterDocument('')
            }
        } else {
            fetchData()
        }
    }, [showModal, toggleModal, fetchData])

    useEffect(() => {
        if(!selected_division._id) {
            toggleAlertBS('info', 'A division must be created for editing a matter doc.')
            setRedirect('/system/divisions/create')
        }
    }, [selected_division._id])

    if(redirect) return <Redirect to={redirect} />

    return (
        <Modalify 
            showModal={showModal}
            toggleModal={toggleModal}
            modalTitle={matter_doc && matter_doc._id ? "Update Matter Doc" : "Create Matter Doc"}
            err={err}
            footer={(
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            )}
            sections={!matter_doc ? [] :  [
                (
                    <div>

                        <h2>Document Info</h2>

                        <FormGroup>
                            <label className="form-control-label">Name*</label>
                            <Input 
                                type="text"
                                value={matter_doc.name || ''}
                                onChange={e => onInputChange('name', e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup className="hide-toolbar">
                            <label className="form-control-label">Description To Client*</label>
                            <ReactQuill 
                                defaultValue={matter_doc.description || ''}
                                ref={reactQuillRef}
                                onChange={(value) => onInputChange('description', value)} 
                                theme="snow"
                                modules={{
                                    toolbar: [
                                        ["bold", "italic"],
                                    ]
                                }}
                            />
                        </FormGroup>
                        <FormGroup className="hide-toolbar">
                            <label className="form-control-label">Description To Staff*</label>
                            <ReactQuill 
                                defaultValue={matter_doc.description_internal || ''}
                                ref={reactQuillRef}
                                onChange={(value) => onInputChange('description_internal', value)} 
                                theme="snow"
                                modules={{
                                    toolbar: [
                                        ["bold", "italic"],
                                    ]
                                }}
                            />
                        </FormGroup>
                    </div>
                ),
                ...(matter_doc._id ? [(
                    <Associations 
                        type="matter_document" 
                        _id={matter_doc._id} 
                        classNames="border p-3 bg-secondary" 
                    />
                )] : []),
                // (
                //     <div>

                //         <h2>Document Expiration</h2>

                //         <ReactSelect 
                //             formGroup={true}
                //             title="Location Types"
                //             description="Document expiration allows you to set"
                //             value={matter_doc.expiry_interval}
                //             onChange={(obj) => onInputChange('expiry_interval', obj.value)} 
                //             options={[
                //                 { label: '1st of the month', value: '1st of month' },
                //                 { label: '15th of the month', value: '15th of month' },
                //                 { label: '3 Months', value: '3 Months' },
                //                 { label: '6 Months', value: '6 Months' },
                //                 { label: '12 Months', value: '12 Months' },
                //                 { label: '1st of the year', value: '1st of  year' },
                //                 { label: '1 Day', value: (day * 1).toString() },
                //                 { label: '2 Days', value: (day * 2).toString() },
                //                 { label: '3 Days', value: (day * 3).toString() },
                //                 { label: '4 Days', value: (day * 4).toString() },
                //                 { label: '5 Days', value: (day * 5).toString() },
                //                 { label: '6 Days', value: (day * 6).toString() },
                //                 { label: '7 Days', value: (day * 7).toString() },
                //                 { label: '10 Days', value: (day * 10).toString() },
                //                 { label: '14 Days', value: (day * 14).toString() },
                //                 { label: '21 Days', value: (day * 21).toString() },
                //                 { label: '28 Days', value: (day * 28).toString() },
                //                 { label: '30 Days', value: (day * 30).toString() },
                //                 { label: '45 Days', value: (day * 45).toString() },
                //                 { label: '60 Days', value: (day * 60).toString() },
                //                 { label: '90 Days', value: (day * 90).toString() },
                //                 { label: '120 Days', value: (day * 120).toString() },
                //             ]}
                //         />

                //     </div>
                // ),
            ]}
        />
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SystemMatterDocumentsEdit);