import { useCallback, useEffect, useState } from "react";
import { Card, CardHeader, Input } from "reactstrap";
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux';

import moment from "moment";

import api from 'api';
import { toast } from 'react-toastify'

import TextButtonRow from 'components/markup/layout/TextButtonRow'
import ConfirmationModal from 'components/functional/modals/Confirmation'
import TableIcons from 'components/markup/icons/TableIcons'
import ModalToggler from 'components/functional/modals/Toggler'
import LoadingTable from 'components/markup/loading/Table';
import ApiError from "components/markup/layout/ApiError";

import { toggleAlertBS } from "store/functions/system/system";
import { setSelectedDivision } from 'store/functions/state'
import { getSoftRefreshLink } from 'utils/urls'

import DivisionsEdit from '../components/Edit'
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const SystemDivisionAll = ({setTab, selected_division}) => {
    const [ tableData, setTableData ] = useState(null)
    const [ divisionToDelete, setDivisionToDelete ] = useState(false)
    const [ error, setError ] = useState(false);
    const [ search, setSearch ] = useState('');
    const [ redirect, setRedirect ] = useState(null);

    const onExportIDs = useCallback(async (_id) => {
        toast.info(`Your download has started.`)
        const result = await api.divisions.exportIDs(_id)

        if(!result.success) return toast.error(`Your download has failed, refresh your page.`)

        toast.success(`Your download has finished.`)
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + result.data;
        hiddenElement.target = '_blank';
        hiddenElement.download = `division_key_map.csv`;
        hiddenElement.click();          

    }, [])

    // helper for this.downloadCSV and this.query
    const fetchData = useCallback((params, isCSV) => new Promise (async resolve => {
        const query = await api.divisions.find()
        
        if(query.data) {
            return setTableData(query.data)
        } else {
            setError(query.message)
        }
    }), [])

    const onDeleteDivision = useCallback(async () => {
        const deleted = await api.divisions.delete(divisionToDelete._id);
        if(deleted.success) {
            fetchData();
            toggleAlertBS(false, 'Division Successfully Removed')
            if(divisionToDelete._id === selected_division._id) {
                cookies.set('archk-selected-division', '', { path: '/' });
                setRedirect(getSoftRefreshLink())
                setSelectedDivision()
            }
      
        } else {
            toggleAlertBS(true, `Something's not right, please try again.`)
        }
    }, [fetchData, divisionToDelete, selected_division._id])

    const shouldRender = useCallback((division) => {
        if(!search) return true;

        const s = search.toLowerCase().trim();
        const n = division.name.toLowerCase().trim();
        if(n.includes(s)) return true;
        if(n === s) return true;

        return false;
    }, [search])

    useEffect(() => {
        fetchData()
    }, [fetchData])
 
    if(error) return <ApiError err={error} />
    if(redirect) return <Redirect to={redirect} />

    const results = search && tableData ? tableData.filter(division => shouldRender(division)) : tableData

    return (

        <>

            <TextButtonRow 
                title={"All Divisions"}
                button={(
                    <button onClick={() => setTab('create')} className="btn btn-success"><i className="fas fa-plus mr-2 " /> Add Division</button>
                )}
            />

            <Card className="card-color card-primary table-fixed">
                <CardHeader className="pl-2 pr-4 pb pt-3 pb-3 ml-1">
                    <Input 
                        className="ml-1"
                        style={{width: 190, maxWidth: '100'}}
                        placeholder="Search..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </CardHeader>
                {!results ?  <LoadingTable /> : (
                    <div className="table-responsive ">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Division</th>
                                    <th className="text-right">Created</th>
                                    <th className="text-right">Date</th>
                                </tr>
                            </thead>

                            <tbody>
                                {results.length ? (
                                    results.map(division => (
                                        <tr key={division._id}>
                                            <td>
                                                <ModalToggler component={DivisionsEdit} onSaved={fetchData} _id={division._id}>
                                                    <span className="cursor-pointer">{division.name}</span>
                                                </ModalToggler>
                                            </td>

                                            <td className="text-right">
                                                <div>{moment.unix(division.created_at).format('MMM Do, YYYY')}</div>
                                            </td>

                                            <td className="text-right">
                                                <TableIcons
                                                    icons={[
                                                        {  
                                                            icon: 'fas fa-file', 
                                                            color: 'info',
                                                            tooltip: `Download Division Key Map`,
                                                            onClick: () => onExportIDs(division._id)
                                                        },
                                                        { 
                                                            icon: 'fas fa-edit',
                                                            color: 'success', 
                                                            tooltip: 'Edit',
                                                            wrapper: Link, 

                                                            _id: division._id,
                                                            to: `/company/divisions/${division._id}`
                                                        },
                                                        {  
                                                            icon: 'fas fa-trash', 
                                                            color: 'danger',
                                                            tooltip: 'Delete',
                                                            onClick: () => setDivisionToDelete(division)
                                                        },
                                                        
                                                    ]}
                                                />

                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td>No Divisions Found</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}

                            </tbody>
                        </table>
                    </div>
                )}

            </Card>

            <ConfirmationModal 
                showModal={divisionToDelete ? true : false}
                toggleModal={() => setDivisionToDelete(null)}
                title="Delete Division"
                body={(
                    <span>
                        Are you sure you wish to delete the division {divisionToDelete ? divisionToDelete.name : ''}? This will prevent any associations from being attached to it in the future.
                    </span>
                )}
                onConfirmation={onDeleteDivision}
            />

        </>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SystemDivisionAll);