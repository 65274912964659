import { useCallback, useState } from "react";
import { connect } from 'react-redux';
import { Row, Col, Card } from "reactstrap";

import api from 'api';
import { Link } from 'react-router-dom';

import ModalToggler from 'components/functional/modals/Toggler';
import Table from 'components/functional/tables/Standard';
import TableIcons from 'components/markup/icons/TableIcons';
import ApiError from "components/markup/layout/ApiError";

import Circle from 'components/markup/loading/Circle';
import TextButtonRow from 'components/markup/layout/TextButtonRow';

const XX = ({title}) => (
    <div>

        <TextButtonRow 
            title={title}
            // button={(
            //     <Link to="/marketing/lead_sources/create" className="btn btn-success"><i className="fas fa-plus mr-2" /> Add New</Link>
            // )}
        />

        <Card className="card-color card-primary table-fixed">
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Source</th>
                            <th>Cost</th>
                            <th>Leads</th>
                            <th>CPL</th>
                            <th>CPA</th>
                            <th>Potential</th>
                            <th>No Hire</th>
                            <th>DNQ</th>
                            <th>Hired</th>
                            <th>Won</th>
                            <th>Est. Wins</th>
                            <th>Profit</th>
                            <th>Est. Profit</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>TLS PPC</td>
                            <td>$20,000</td>
                            <td>502</td>
                            <td>$240</td>
                            <td>$543</td>
                            <td>350 (30%)</td>
                            <td>350 (30%)</td>
                            <td>98 (30%)</td>
                            <td>120 (30%)</td>
                            <td>100 (20%)</td>
                            <td>150 (25%)</td>
                            <td>$124,000</td>
                            <td>$315,563</td>
                        </tr>
                      
                        <tr>
                            <td>TLS PPC</td>
                            <td>$20,000</td>
                            <td>502</td>
                            <td>$240</td>
                            <td>$543</td>
                            <td>350 (30%)</td>
                            <td>350 (30%)</td>
                            <td>98 (30%)</td>
                            <td>120 (30%)</td>
                            <td>100 (20%)</td>
                            <td>150 (25%)</td>
                            <td>$124,000</td>
                            <td>$315,563</td>
                        </tr>
                      
                        <tr>
                            <td>TLS PPC</td>
                            <td>$20,000</td>
                            <td>502</td>
                            <td>$240</td>
                            <td>$543</td>
                            <td>350 (30%)</td>
                            <td>350 (30%)</td>
                            <td>98 (30%)</td>
                            <td>120 (30%)</td>
                            <td>100 (20%)</td>
                            <td>150 (25%)</td>
                            <td>$124,000</td>
                            <td>$315,563</td>
                        </tr>
                      
                        <tr>
                            <td>TLS PPC</td>
                            <td>$20,000</td>
                            <td>502</td>
                            <td>$240</td>
                            <td>$543</td>
                            <td>350 (30%)</td>
                            <td>350 (30%)</td>
                            <td>98 (30%)</td>
                            <td>120 (30%)</td>
                            <td>100 (20%)</td>
                            <td>150 (25%)</td>
                            <td>$124,000</td>
                            <td>$315,563</td>
                        </tr>
                      
                        <tr>
                            <td>TLS PPC</td>
                            <td>$20,000</td>
                            <td>502</td>
                            <td>$240</td>
                            <td>$543</td>
                            <td>350 (30%)</td>
                            <td>350 (30%)</td>
                            <td>98 (30%)</td>
                            <td>120 (30%)</td>
                            <td>100 (20%)</td>
                            <td>150 (25%)</td>
                            <td>$124,000</td>
                            <td>$315,563</td>
                        </tr>
                      
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>   
                            <td></td>   
                            <td></td>
                            <td>TOTAL:</td>
                            <td>$124,301</td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </Card>
    </div>
)

const LocationsAll = ({setTab, selected_division}) => {

    const [ loading, setLoading ] = useState(false);
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ error, setError ] = useState(false);
    const [ type, setType ] = useState('day');
    const [startDate, setStartDate] = useState(new Date());



    // helper for this.downloadCSV and this.query
    const fetchData = useCallback((params, isCSV, shouldReload) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(shouldReload) setLoading(true)
        const query = await api.locations.search({ ...params, filter: { deleted: false, division: selected_division._id }, isCSV })
        if(shouldReload) setLoading(false)

        if(!query.data) return setError(query.message)
        return resolve(query.data)
    }), [sizePerPage, selected_division._id])

    // run query to get data from the server
    const query = useCallback(async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }, [fetchData])

   
 
    const columns = [
        {
            dataField: "given_name",
            text: 'Matter',
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )
                return (
                    'TLS PPC'
                )
                
            },
        },
        {
            dataField: "address_line_1",
            text: 'Category',
            formatter: (cell, row) => (
                <div>
                    <div>PPC</div>
                </div>
            )
        },

        {
            dataField: "address_line_1",
            text: 'Last Lead',
            formatter: (cell, row) => `5:23PM 5/04/2023`
        },
        {
            dataField: "address_line_1",
            text: 'Created',
            formatter: (cell, row) => `5:23PM 5/01/2023`
        },
       
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right',},
            text: 'Actions',
            headerFormatter: () => {
                return <div id="archk-tutorial-contacts-actions">Actions</div>
            },
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                         <TableIcons
                            icons={[
                              
                                { 
                                    icon: 'fas fa-edit',
                                    color: 'success', 
                                    wrapper: ModalToggler, 
                                    onSaved: () => fetchData(null, null, true),
                                    _id: row._id
                                },
                                { 
                                    icon: 'fas fa-chart-line',
                                    color: 'info', 
                                    wrapper: ModalToggler, 
                                    onSaved: () => fetchData(null, null, true),
                                    _id: row._id
                                },
                                { 
                                    icon: 'fas fa-edit',
                                    color: 'warning', 
                                    wrapper: ModalToggler, 
                                    onSaved: () => fetchData(null, null, true),
                                    _id: row._id
                                },  
                             
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(error) return <ApiError err={error} />
    if(loading) return <div><Circle /></div>

    return (

        <>

        <XX title="Leads Last 30 Days"/>
        <XX title="Leads Last 60 Days"/>
        <XX title="Leads Last 90 Days"/>
        <XX title="Leads Last 180 Days"/>
        <XX title="Leads Last 365 Days"/>


        </>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(LocationsAll);