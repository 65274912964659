import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';

export default memo(({ id, data, isConnectable }) => {
  return (
    <>
        <Handle
            id="left"
            type="target"
            position={Position.Left}
            style={{ top: 15 }}
            isConnectable={isConnectable}
        />

        <div className="">
            <div className='node-header'>
                <i className="fa-solid fa-location-arrow text-info mr-2" /> Perform Action
            </div>
           
            <div className='node-body'>
                <div className='node-item text-capitalize'>{data.type ? data.type.replace(/_/g , ' ') : <span className='text-danger'>Action Not Set</span>}</div>
            </div>

            <div className='node-footer'>
                <div><small>Execution ID:{id}</small></div>
            </div>

        </div>

        <Handle
            id="right"
            type="source"
            position={Position.Right}
            style={{ top: 10 }}
            isConnectable={isConnectable}
        />
        </>
  );
});