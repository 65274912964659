import store from 'store';
import * as actionTypes from '../../actions';

export const setSocket = (socket) => {
    store.dispatch({
        type: actionTypes.SET_SOCKET,
        payload: socket
    });
}

