import { Input, FormGroup, Row, Col } from "reactstrap";
import { useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux'

import api from 'api'
import Modalify from 'components/functional/modals/Modalify'

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';
import ReactSelect from 'components/functional/inputs/ReactSelect'

const baseState = {
    cost_type: 'user_rate',
    seconds: 0,
}

const minute = 60;

const SystemTimeEntryEdit = ({_id, onSaved, showModal, toggleModal, selected_division}) => {

    const [timeEntry, setTimeEntry] = useState(null)
    const [err, setErr] = useState(null)

    const showRateField = timeEntry && (timeEntry.cost_type === 'fixed' || timeEntry.cost_type === 'hourly') ? true : false;

    const fetchData = useCallback(async () => {
        if(!_id || _id === 'create') return setTimeEntry(baseState);
        const user = await api.time_entry_templates.findById(_id)
        if(!user.data) return setErr(true);
        setTimeEntry(user.data)
    }, [_id])

    const onInputChange = useCallback(( field, value) => {
        const newTimeEntry = Object.assign({}, timeEntry)
        newTimeEntry[field] = value;
        setTimeEntry(newTimeEntry);
    }, [timeEntry])
    
  
    const onSave = useCallback(async () => {
        setErr(false)
        let newState = Object.assign({}, timeEntry);
        
        let saved;

        if(newState.cost) newState.cost = parseInt(newState.cost)
        if(newState.seconds) newState.seconds = parseInt(newState.seconds)

        // toggle loaders and update/create the user
        toggleStandardLoader(true)
        if(newState._id) {
            saved = await api.time_entry_templates.update(newState._id, newState, true);
        } else {
            saved = await api.time_entry_templates.create({...newState, division: selected_division._id}, true);
        }
        toggleStandardLoader(false)

        // show error messages if unsuccessful
        if(saved.success) {
            toggleAlertBS(false, "Time Entry Saved Successfully")
            fetchData();
            if(toggleModal) toggleModal();
            if(onSaved) onSaved(saved.data)
        } else {
            return setErr(saved.message);
        }
    
    }, [timeEntry, fetchData, toggleModal, onSaved, selected_division._id])

    useEffect(() => {
        if(toggleModal) {
            if(showModal) {
                fetchData()
            } else {
                setErr(null)
                setTimeEntry(baseState)
            }
        } else {
            fetchData()
        }
    }, [showModal, toggleModal, fetchData])

    return (
        <Modalify 
            showModal={showModal}
            toggleModal={toggleModal}
            modalTitle={timeEntry && timeEntry._id ? "Update Time Entry" : "Create Time Entry"}
            err={err}
            footer={(
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            )}
            sections={!timeEntry ? [] :  [
                (
                    <div>

                        <h2>Time Entry Info</h2>

                        <FormGroup>
                            <label className="form-control-label">Time Entry Title</label>
                            <Input 
                                type="text"
                                value={timeEntry.title || ''}
                                onChange={e => onInputChange('title', e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label className="form-control-label">Description</label>
                            <Input 
                                type="textarea"
                                value={timeEntry.description || ''}
                                onChange={e => onInputChange('description', e.target.value)}
                            />
                        </FormGroup>
                    </div>
                ),
                (
                    <div>

                        <h2>Time Entry Tracking</h2>
        
                        <Row>
                            <Col lg={showRateField ? 6 : 12}>
                                <ReactSelect 
                                    formGroup={true}
                                    title="Cost Type"
                                    value={timeEntry.cost_type}
                                    onChange={(obj) => onInputChange('cost_type', obj.value)}
                                    options={[
                                        { value: 'user_rate', label: 'User Rate' },
                                        { value: 'matter_rate', label: 'Matter Rate' },
                                        { value: 'fixed', label: 'Fixed Rate' },
                                        { value: 'hourly', label: 'Flat Hourly Rate' },
                                        { value: 'non_billable', label: 'Non Billable' },
                                    ]}
                                />
                            </Col>
                            {showRateField ? (
                                <Col lg={6}>
                                    <FormGroup>
                                        <label className="form-control-label">
                                            {timeEntry.cost_type === 'fixed' ? 'Fixed Cost' : timeEntry.cost_type === 'hourly' ? 'Cost Per Hour' : ''}
                                        </label>
                                        <Input 
                                            disabled={timeEntry.cost_type === 'fixed' || timeEntry.cost_type === 'hourly' ? false : true }
                                            type="number"
                                            value={timeEntry.cost || ''}
                                            onChange={e => onInputChange('cost', e.target.value)}
                                        />
                                    </FormGroup>    
                                </Col>
                            ) : ''}
                           
                        </Row>
                        {showRateField ? '' : (
                            <FormGroup>
                                <ReactSelect 
                                    formGroup={true}
                                    title={timeEntry.cost_type === 'non_billable' ?  "Time To Appear On Invoice" : "Time To Bill For"}
                                    description={timeEntry.cost_type === 'non_billable' ?  "This number will show on an invoice but will not affect the value of the invoice" : "This is the default amount of time this time entry will bill for."}
                                    value={timeEntry.seconds}
                                    onChange={(obj) => onInputChange('seconds', obj.value)}
                                    options={[
                                        { value: 0, label: '-' },
                                        { value: minute * 1, label: '1 Minute' },
                                        { value: minute * 2, label: '2 Minutes' },
                                        { value: minute * 3, label: '3 Minutes' },
                                        { value: minute * 4, label: '4 Minutes' },
                                        { value: minute * 5, label: '5 Minutes' },
                                        { value: minute * 6, label: '6 Minutes' },
                                        { value: minute * 7, label: '7 Minutes' },
                                        { value: minute * 8, label: '8 Minutes' },
                                        { value: minute * 9, label: '9 Minutes' },
                                        { value: minute * 10, label: '10 Minutes' },
                                        { value: minute * 11, label: '11 Minutes' },
                                        { value: minute * 12, label: '12 Minutes' },
                                        { value: minute * 13, label: '13 Minutes' },
                                        { value: minute * 14, label: '14 Minutes' },
                                        { value: minute * 15, label: '15 Minutes' },
                                        { value: minute * 16, label: '16 Minutes' },
                                        { value: minute * 17, label: '17 Minutes' },
                                        { value: minute * 18, label: '18 Minutes' },
                                        { value: minute * 19, label: '19 Minutes' },
                                        { value: minute * 20, label: '20 Minutes' },
                                        { value: minute * 21, label: '21 Minutes' },
                                        { value: minute * 22, label: '22 Minutes' },
                                        { value: minute * 23, label: '23 Minutes' },
                                        { value: minute * 24, label: '24 Minutes' },
                                        { value: minute * 25, label: '25 Minutes' },
                                        { value: minute * 26, label: '26 Minutes' },
                                        { value: minute * 27, label: '27 Minutes' },
                                        { value: minute * 28, label: '28 Minutes' },
                                        { value: minute * 29, label: '29 Minutes' },
                                        { value: minute * 30, label: '30 Minutes' },
                                        { value: minute * 31, label: '31 Minutes' },
                                        { value: minute * 32, label: '32 Minutes' },
                                        { value: minute * 33, label: '33 Minutes' },
                                        { value: minute * 34, label: '34 Minutes' },
                                        { value: minute * 35, label: '35 Minutes' },
                                        { value: minute * 36, label: '36 Minutes' },
                                        { value: minute * 37, label: '37 Minutes' },
                                        { value: minute * 38, label: '38 Minutes' },
                                        { value: minute * 39, label: '39 Minutes' },
                                        { value: minute * 40, label: '40 Minutes' },
                                        { value: minute * 41, label: '41 Minutes' },
                                        { value: minute * 42, label: '42 Minutes' },
                                        { value: minute * 43, label: '43 Minutes' },
                                        { value: minute * 44, label: '44 Minutes' },
                                        { value: minute * 45, label: '45 Minutes' },
                                        { value: minute * 46, label: '46 Minutes' },
                                        { value: minute * 47, label: '47 Minutes' },
                                        { value: minute * 48, label: '48 Minutes' },
                                        { value: minute * 49, label: '49 Minutes' },
                                        { value: minute * 50, label: '50 Minutes' },
                                        { value: minute * 51, label: '51 Minutes' },
                                        { value: minute * 52, label: '52 Minutes' },
                                        { value: minute * 53, label: '53 Minutes' },
                                        { value: minute * 54, label: '54 Minutes' },
                                        { value: minute * 55, label: '55 Minutes' },
                                        { value: minute * 56, label: '56 Minutes' },
                                        { value: minute * 57, label: '57 Minutes' },
                                        { value: minute * 58, label: '58 Minutes' },
                                        { value: minute * 59, label: '59 Minutes' },
                                        { value: minute * 60, label: '1 Hour' },
                                        { value: minute * 65, label: '1 Hour 5 Minutes' },
                                        { value: minute * 70, label: '1 Hour 10 Minutes' },
                                        { value: minute * 75, label: '1 Hour 15 Minutes' },
                                        { value: minute * 80, label: '1 Hour 20 Minutes' },
                                        { value: minute * 85, label: '1 Hour 25 Minutes' },
                                        { value: minute * 90, label: '1 Hour 30 Minutes' },
                                        { value: minute * 95, label: '1 Hour 35 Minutes' },
                                        { value: minute * 100, label: '1 Hour 40 Minutes' },
                                        { value: minute * 105, label: '1 Hour 45 Minutes' },
                                        { value: minute * 110, label: '1 Hour 50 Minutes' },
                                        { value: minute * 115, label: '1 Hour 55 Minutes' },
                                        { value: minute * 120, label: '2 Hours' },
                                        { value: minute * 125, label: '2 Hours 5 Minutes' },
                                        { value: minute * 130, label: '2 Hours 10 Minutes' },
                                        { value: minute * 135, label: '2 Hours 15 Minutes' },
                                        { value: minute * 140, label: '2 Hours 20 Minutes' },
                                        { value: minute * 145, label: '2 Hours 25 Minutes' },
                                        { value: minute * 150, label: '2 Hours 30 Minutes' },
                                        { value: minute * 155, label: '2 Hours 35 Minutes' },
                                        { value: minute * 160, label: '2 Hours 40 Minutes' },
                                        { value: minute * 165, label: '2 Hours 45 Minutes' },
                                        { value: minute * 170, label: '2 Hours 50 Minutes' },
                                        { value: minute * 175, label: '2 Hours 55 Minutes' },
                                        { value: minute * 180, label: '3 Hours' },
                                        { value: minute * 195, label: '3 Hours 15 Minutes' },
                                        { value: minute * 210, label: '3 Hours 30 Minutes' },
                                        { value: minute * 225, label: '3 Hours 45 Minutes' },
                                        { value: minute * 240, label: '4 Hours' },
                                        { value: minute * 255, label: '4 Hours 15 Minutes' },
                                        { value: minute * 270, label: '4 Hours 30 Minutes' },
                                        { value: minute * 285, label: '4 Hours 45 Minutes' },
                                        { value: minute * 300, label: '5 Hours' },
                                        { value: minute * 315, label: '5 Hours 15 Minutes' },
                                        { value: minute * 330, label: '5 Hours 30 Minutes' },
                                        { value: minute * 345, label: '5 Hours 45 Minutes' },
                                        { value: minute * 360, label: '6 Hours' },
                                        { value: minute * 375, label: '6 Hours 15 Minutes' },
                                        { value: minute * 390, label: '6 Hours 30 Minutes' },
                                        { value: minute * 405, label: '6 Hours 45 Minutes' },
                                        { value: minute * 420, label: '7 Hours' },
                                        { value: minute * 435, label: '7 Hours 15 Minutes' },
                                        { value: minute * 450, label: '7 Hours 30 Minutes' },
                                        { value: minute * 465, label: '7 Hours 45 Minutes' },
                                        { value: minute * 480, label: '8 Hours' },
                                        { value: minute * 495, label: '8 Hours 15 Minutes' },
                                        { value: minute * 510, label: '8 Hours 30 Minutes' },
                                        { value: minute * 525, label: '8 Hours 45 Minutes' },
                                        { value: minute * 540, label: '9 Hours' },
                                        { value: minute * 555, label: '9 Hours 15 Minutes' },
                                        { value: minute * 570, label: '9 Hours 30 Minutes' },
                                        { value: minute * 585, label: '9 Hours 45 Minutes' },
                                        { value: minute * 600, label: '10 Hours' },
                                        { value: minute * 615, label: '10 Hours 15 Minutes' },
                                        { value: minute * 630, label: '10 Hours 30 Minutes' },
                                        { value: minute * 645, label: '10 Hours 45 Minutes' },
                                        { value: minute * 660, label: '11 Hours' },
                                        { value: minute * 675, label: '11 Hours 15 Minutes' },
                                        { value: minute * 690, label: '11 Hours 30 Minutes' },
                                        { value: minute * 705, label: '11 Hours 45 Minutes' },
                                        { value: minute * 720, label: '12 Hours' },
                                    ]}
                                />
                            </FormGroup>    
                        )}
                        
                    </div>
                ),


            ]}
        />
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SystemTimeEntryEdit);