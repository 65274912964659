import { connect } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import api from 'api';

import Circle from 'components/markup/loading/Circle'

const WorkflowContactSelection = ({ selected_division, contacts = [], onChange }) => {

    const [err, setErr] = useState(null)

    const [workflows, setWorkflows] = useState(null)
    const [workflowContacts, setWorkflowContacts] = useState(null)

    const isChecked = (_workflow, contact_id) => {
        const found = contacts.find(r => r.workflow_contact === contact_id)
        return found ? true : false
    }

    const fetchWorkflows = useCallback(async () => {
        const query = await api.workflows.find()
        if(!query.success) return setErr(true)

        const _workflows = query.data.filter(workflow => workflow.division === selected_division._id)
        setWorkflows(_workflows)

        let counter = 0;
        let results = []

        _workflows.forEach(async w => {

            const data = await api.workflow_contacts.find(w._id);
            if(data.data) results = results.concat(data.data)
            counter++;
            if(counter === _workflows.length) {

                setWorkflowContacts(results)
            }
        })

    }, [selected_division._id,])

    useEffect(() => {
        fetchWorkflows()
    }, [fetchWorkflows])

    if(err) return <div className='alert alert-danger'>An Unexpected Error Has Occurred, Please Refresh Your Page</div>
    if(!workflows || !workflowContacts) return <Circle />

    return (
        <div>
    
            {workflows && workflows.length ? workflows.map(workflow => (
                <form key={workflow._id}>
                    <h4>Workflow: {workflow.name}</h4>

                    {workflowContacts.map(contact => contact.workflow === workflow._id ? (
                        <div key={contact._id + isChecked(workflow._id, contact._id)} className="custom-control custom-checkbox mb-3">
                            <input
                                className="custom-control-input"
                                id={`archk-workflow-cc-${contact._id}`}

                                checked={isChecked(workflow._id, contact._id)}
                                type="checkbox"
                                onChange={e => {
                                    let _contacts = JSON.parse(JSON.stringify(contacts))
                                    const contactIndex = _contacts.findIndex(r => r.workflow === workflow._id && r.workflow_contact === contact._id)

                                    if(contactIndex === -1)  {
                                        _contacts.push({
                                            workflow: workflow._id,
                                            workflow_contact: contact._id
                                        })
                                    } else {
                                        _contacts = _contacts.filter(r => r.workflow_contact !== contact._id)
                                    }
                                
                                    onChange(_contacts)
                                }}
                            />
                            <label className="custom-control-label" htmlFor={`archk-workflow-cc-${contact._id}`}>
                                {contact.name}
                            </label>
                        </div>
                    ) : '')}
                </form>
            )) : (
                <div className='alert alert-warning mb-0'>At least one workflow must be created before this action can run.</div>
            )}

        </div>
    )
}



const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(WorkflowContactSelection);