import { Input, FormGroup } from "reactstrap";
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import { useCallback, useState, useEffect } from 'react';

import api from 'api'

import Modalify from 'components/functional/modals/Modalify'

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

import Associations from 'components/system/Associations'

const SystemContactTypesEdit = ({_id, onSaved, showModal, toggleModal, selected_division}) => {

    const [contact_type, setContactType] = useState(null)
    const [err, setErr] = useState(null)
    const [redirect, setRedirect] = useState(null)

    const fetchData = useCallback(async () => {
        if(!_id || _id === 'create') return setContactType({});
        const contact_type = await api.contact_types.findById(_id)
        if(!contact_type.data) return setErr(true);
        setContactType(contact_type.data)
    }, [_id])

    const onInputChange = useCallback(( field, value) => {
        const newContactType = Object.assign({}, contact_type)
        newContactType[field] = value;
        setContactType(newContactType);
    }, [contact_type])

    const onSave = useCallback(async () => {
        setErr(false)
        let newState = Object.assign({}, contact_type);

        if(!newState.name) {
            return setErr([`A contact type must have a name.`])
        }
        
        setContactType(newState)

        let saved;

        // toggle loaders and update/create the contact_type
        toggleStandardLoader(true)
        if(newState._id) {
            saved = await api.contact_types.update(newState._id, newState, true);
        } else {
            saved = await api.contact_types.create({...newState, division: selected_division._id}, true);
        }
        toggleStandardLoader(false)

        // show error messages if unsuccessful
        if(saved.success) {
            toggleAlertBS(false, "Contact Type Saved Successfully")
            fetchData();
            if(toggleModal) toggleModal();
            if(onSaved) onSaved(saved.data)
        } else {
            return setErr(saved.message);
        }
    
    }, [contact_type, fetchData, toggleModal, onSaved, selected_division._id])

    useEffect(() => {
        if(toggleModal) {
            if(showModal) {
                fetchData()
            } else {
                setErr(null)
                setContactType('')
            }
        } else {
            fetchData()
        }
    }, [showModal, toggleModal, fetchData])

    useEffect(() => {
        if(!selected_division._id) {
            toggleAlertBS('info', 'A division must be created for editing a contact type.')
            setRedirect('/system/divisions/create')
        }
    }, [selected_division._id])

    if(redirect) return <Redirect to={redirect} />

    return (
        <Modalify 
            showModal={showModal}
            toggleModal={toggleModal}
            modalTitle={contact_type && contact_type._id ? "Update Contact Type" : "Create Contact Type"}
            err={err}
            footer={(
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            )}
            sections={!contact_type ? [] :  [
                (
                    <div>

                        <FormGroup>
                            <label className="form-control-label">Name*</label>
                            <Input 
                                type="text"
                                value={contact_type.name || ''}
                                onChange={e => onInputChange('name', e.target.value)}
                            />
                        </FormGroup>

                        {/* <div className="text-danger">!!!!!Need to add a category where phone, email, address, etc is required on a contact if this type is set</div> */}
                    </div>
                ),
                ...(contact_type._id ? [(
                    <Associations 
                        type="contact_type" 
                        _id={contact_type._id} 
                        classNames="border p-3 bg-secondary" 
                    />
                )] : []),
            ]}
        />
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SystemContactTypesEdit);