import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row } from "reactstrap";


import BarLine from 'components/charts/BarLine';
import PieArea from 'components/charts/PieArea';

import Table from './Table';

const LocationsAll = ({setTab, selected_division}) => {

    

    return (

        <>

        <Row>
            <Col md={6}>


                <Row>
                    <Col md={6}>
                        <Card className='text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Completed Today</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-2 mb-0'>49 <small><small className='text-muted pl-4'>+12%</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                12 Weeks Ago: <b className='text-dark font-weight-bold '>39</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card className='text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Missed Today</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-2 mb-0'>9</h2>
                            </CardBody>
                            <CardFooter>
                                Avg Overdue: <b className='text-dark font-weight-bold '>8 Minutes</b>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>

            </Col>

            <Col md={3}>
             
                <Card className='text-center'>
                    <CardHeader>
                        <CardTitle className="mb-0">New Events Today</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <h2 className='display-2 mb-0'>30</h2>
                    </CardBody>
                    <CardFooter>
                        12 Weeks Ago: <b className='text-dark font-weight-bold '>22</b>
                    </CardFooter>
                </Card>

            </Col>

            <Col md={3}>

                <Card className='text-center'>
                    <CardHeader>
                        <CardTitle className="mb-0">Next 24 Hours</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <h2 className='display-2 mb-0'>28 <small><small className='text-muted pl-4'>+87%</small></small></h2>
                    </CardBody>
                    <CardFooter>
                        12 Weeks Ago: <b className='text-dark font-weight-bold '>19</b>
                    </CardFooter>
                </Card>
            </Col>
            
   
        </Row>

        <Table />

        </>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(LocationsAll);