import { memo } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { toggleAlertBS } from 'store/functions/system/system';

const Page = ({ id, item, moveItem, onFinalDrop, findItem, acceptNested, onSetPage, page, unsavedQuestion, i}) => {
    const originalIndex = findItem(id).index;

    const [{ isDragging }, drag] = useDrag({
        type: acceptNested,
        accept: acceptNested,
        // item: { id, originalIndex, type: acceptNested },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        item: (z, b) => {
            if(unsavedQuestion) {
                return toggleAlertBS('info', 'The question you have selected has unsaved changes. Please save current question you are on before changing the overall page order.')
            }
            return  { id, originalIndex, type: acceptNested }
        },
        end: (item, monitor) => {
            const { id: droppedId, originalIndex } = item;
            const didDrop = monitor.didDrop();

            if (!didDrop) {
                moveItem(droppedId, originalIndex);
            } else {
                onFinalDrop(droppedId, originalIndex)
            }
        },
    }, [id, originalIndex, moveItem]);
    
    const [, drop] = useDrop({
        accept: acceptNested,
        canDrop: () => false,
        hover({ id: draggedId }) {
            if (draggedId !== id) {
                const { index: overIndex } = findItem(id);
                moveItem(draggedId, overIndex);
            }
        },
    }, [findItem, moveItem]);

    let className = '';

    if(page === i) className += ' active bg-gradient-warning'
    if(isDragging) className += ' li-drag'
    
    return (
        <li  
            ref={(node) => drag(drop(node))} 
            onClick={() => onSetPage(i)} 
            data-id={item.id} 
            className={className}
        >
            P{i + 1}
        </li>
    );
} 

export default memo(Page)