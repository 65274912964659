import { Modal } from 'reactstrap';

import SendEmailsTo from './SendEmailsTo';

const ModalNotifications = ({showModal, toggleModal, configuration, send_emails_to, onSetState}) => (
    <Modal
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={toggleModal}
        size="md"
    >
        <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Save Template</h5>
            <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggleModal}
            >
                <span aria-hidden={true}>×</span>
            </button>
        </div>

        <div className="modal-body">
            <SendEmailsTo 
                answers={send_emails_to}
                configuration={configuration}
                onChange={(answers) => onSetState('send_emails_to', answers)}
            />
        </div>

        <div className="modal-footer">
            <button onClick={toggleModal} className="btn btn-outline-warning">
                <i className="fas fa-arrow-left mr-2" /> Back
            </button>
        </div>

    </Modal>
)

export default ModalNotifications
