import { useCallback, useEffect, useState } from 'react';

import ModalToggler from 'components/functional/modals/Toggler'
import InsertSteps from './InsertStep';


const WorkflowsEditMain = ({steps, onAddStep, findStep, onRemoveStep, setStep}) => {

    const [stepOptions, setStepOptions] = useState([])

    const createMarkup = useCallback((allSteps) => {
        let hasBranched = []
        let previousSteps = {  }

        const renderList = (_steps, lastStep, isFirst, depth = 0) => {
            let listMarkup = [];
            if(isFirst) _steps = steps.filter(s => s.is_first === true);

            _steps.forEach(s => {
                if( !previousSteps[s]) previousSteps[s] = depth
            })

            for (let i = 0; i < _steps.length; i++) {
                const step = findStep(_steps[i])

                const id = step._id ? step._id : step.id ? step.id : '';
                let internalMarkup = <></>

                let hasChildren = step.next_steps.length && !hasBranched.includes(id);
                if(previousSteps[_steps[i]]) {
                    if(depth > previousSteps[_steps[i]]) hasChildren = false;
                }

                if(hasChildren) {
                    hasBranched.push(id)
                    internalMarkup = renderList(step.next_steps, step, false, depth + 1)
                }
    
                listMarkup.push((
                    <li key={id} className={`position-relative ${hasChildren ? 'archk-can-branch' : ''}`}>

                        {hasChildren ? (
                            <span 
                                style={{
                                    position: 'absolute',
                                    display: 'inline-block',
                                    top: 5,
                                    left: 8,
                                    height: 10,
                                    width: 10,
                                    background: 'none',
                                    cursor: 'pointer',
                                    zIndex: 100
                                }}
                                onClick={() => {
                                    const ul = document.getElementById(`archk-ul-${step._id}`)
                                    ul.style.display = ul.style.display === 'none' ? 'block' : 'none'
                                }}
                            />
                        ) : ''}

                        <span onClick={() => setStep(step)} className="cursor-pointer archk-workflow-main-step-span">
                        {step.name} 
                        </span>
                        
                        <i
                            className="fas fa-trash ml-2 text-danger " 
                            onClick={() => onRemoveStep(step, lastStep)}
                        />
                        {!step.next_steps.length ? (
                            <ModalToggler 
                                component={InsertSteps} 
                                stepOptions={stepOptions.filter(s => {
                                    if(s.value._id === step._id) return false
                                    if(_steps.some(ss => ss._id === s.value._id)) return false
                                    return true
                                })} 
                                steps={steps}
                                onAddStep={(_step) => onAddStep(_step, step)}
                            >
                                <i className="fas fa-code-branch text-info ml-2 " />
                            </ModalToggler>
                        ) : null}

                        {hasChildren ? (
                            <span id={`archk-ul-${step._id}`}>
                                {internalMarkup}
                            </span>
                        ) : ''}
                       
                    </li>
                ))
            }

            return (
                <ul className={`${isFirst ? 'archk-workflow-main-ul-first' : 'archk-workflow-main-ul'}`}>
                    <span 
                        className={`archk-workflow-main-step-name ${isFirst ? 'archk-workflow-main-step-name-first' : ''}`}
                    >
                        {listMarkup}
                    </span>
                    {((isFirst && !_steps.length) || !isFirst) && (
                        <li className="archk-workflow-main-insert-step">
                            <ModalToggler 
                                component={InsertSteps} 
                                stepOptions={stepOptions.filter(s => {
                                    if(_steps.some(ss => ss === s.value._id)) return false

                                    return true
                                })} 
                                steps={steps}
                                onAddStep={(_step) => onAddStep(_step, lastStep)}
                            >
                                <button className="btn btn-success btn-sm">Insert Step</button>
                            </ModalToggler>
                        </li>
                    )}
                </ul>
            );
        }

        return renderList(allSteps, null, true)
    // }, [steps, stepOptions, onAddStep, findStep ,onRemoveStep ])
    }, [steps, stepOptions, onAddStep, findStep ,onRemoveStep, setStep ])

    useEffect(() => {
        setStepOptions(steps.map(step => {
            return { value: step, label: step.name }
        }))
    }, [steps])

    return (
        <div className="archk-workflow-main">
            <div className="pt-3">
                {createMarkup(steps)}
            </div>
        </div>
    )
}

export default WorkflowsEditMain;