import { connect } from 'react-redux';
import React, { useState, useEffect, useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import { Row } from 'reactstrap';

import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';

import ColFlow from './ColFlow'
import ColPage from './ColPage'
import SelectedQuestion from './SelectedQuestion'
import Header from './Header'

import { v4 as uuidv4 } from 'uuid';

import { addArchkAlert } from 'store/functions/system/system'

import ConfirmationModal from 'components/functional/modals/Confirmation'
import Circle from 'components/markup/loading/Circle'

// change this out for state to induce a simulated stress test with 800 questions
// import Items from './Items'

import state from './state'

import api from 'api'

const FormEdit = ({match, device, selected_division}) => {
    const [showUnsavedQuestionModal, setShowUnsavedQuestionModal] = useState(false);

    const [err, setErr] = useState(false)
    const [customFields, setCustomFields] = useState([])
    const [config, setConfig] = useState(false)
    const [formId, setFormId] = useState(false)
    const [redirect, setRedirect] = useState(0)
    // the current page we are viewing questions for
    const [unsavedForm, setUnsavedForm] = useState(false)
    const [unsavedQuestion, setUnsavedQuestion] = useState(false)
    const [page, setPage] = useState(0)
    // if we have loaded yet
    const [loaded, setLoaded] = useState(false)
    // full form without questions property
    const [form, setForm] = useState({})
    // all questions of the form
    const [questions, setQuestions] = useState([]);
    // const [questions, setQuestions] = useState(Items);

    const [pageQuestions, setPageQuestions] = useState(0)
    
    // the selected question
    const [selected, setSelected] = useState({
        question: {},
        parentTree: []
    });

    const preventPageUnload = useCallback((event) => {
        // Cancel the event as stated by the standard.
        event.preventDefault();
        // Chrome requires returnValue to be set.
        event.returnValue = '';
    }, [])

    const getQuestionPageCount = useCallback((page, increment) => {

        if(increment) return pageQuestions + increment;

        let _pageQuestions = questions.filter(p => p.page === page);
        let totalQuestions = 0;

        const count = (_questions) => {
            for (let i = 0; i < _questions.length; i++) {
                const question = _questions[i];
                totalQuestions++;

                if(question.questions.length) {
                    count(question.questions)   
                }
            }
        }
        count(_pageQuestions);
        return totalQuestions;
    }, [questions, pageQuestions])

    const onSetUnsavedForm = useCallback((bool) => {
        if(bool !== unsavedForm) {
            setUnsavedForm(bool);
            if(bool) {
                window.addEventListener('beforeunload', preventPageUnload);
            } else {
                window.removeEventListener('beforeunload', preventPageUnload);
            }
        }
    }, [unsavedForm, preventPageUnload])

    const onSetUnsavedQuestion = useCallback((bool) => {
        if(bool !== unsavedQuestion) {
            setUnsavedQuestion(bool);
            if(bool) onSetUnsavedForm(true)
        }
    }, [unsavedQuestion, onSetUnsavedForm])

    const onSetForm = useCallback((form) => {
        setForm(form)
        onSetUnsavedForm(true)
    }, [onSetUnsavedForm])

    const createNewQuestion = useCallback((_page) => {
        return {
            ...JSON.parse(JSON.stringify(state.question)),
            id: uuidv4(),
            page: _page
        }
    }, [])

    const onSetPage = useCallback((_page, isNew) => {
        if(unsavedQuestion) {
            return toggleAlertBS('info', 'The question you have selected has unsaved changes. Please save (or delete) the current question you are updating before changing pages.')
        }
        setSelected({
            question: {},
            parentTree: []
        })
        if(isNew) {
            setPageQuestions(1)
        } else {
            setPageQuestions(getQuestionPageCount(_page))
        }        
        setPage(_page)

        const sidebar = document.getElementById('archk-col-flow');
        if(sidebar) {
            sidebar.scroll({top:0})
        }

    }, [getQuestionPageCount, unsavedQuestion])

    // try to highlight the current selected question
    const tryHighlight = useCallback((question) => {

        if(!question) question = selected.question

        const lastSelected = document.querySelector(`.archk-selected-question`);
        const nextSelected = document.querySelector(`[data-id="${question.id}"]`);
        const input = document.getElementById('archk-question-name')

        if(lastSelected) {
            lastSelected.classList.remove('archk-selected-question')
        }

        if(nextSelected) {
            nextSelected.classList.add('archk-selected-question');
            nextSelected.scrollIntoView({block: 'center'})

            if(input) input.focus();
        } else if(question.id) {
            setTimeout(() => {
                tryHighlight(question)
            }, 300)
        }
    }, [selected.question])

     // saves a question to state with the question as an object and 
    // the map of the questions parent tree
    const onSelect = useCallback((question, questionSet, parentTree, bypassAlert) => {

        if(unsavedQuestion && !bypassAlert) {
            return setShowUnsavedQuestionModal({question, questionSet, parentTree, bypassAlert})
        }
        question = question ? JSON.parse(JSON.stringify(question)) : {};

        tryHighlight(question);

        setUnsavedQuestion(false)
        setSelected({
            question: question,
            parentTree: parentTree ? parentTree : [],
            questionSet: questionSet ? questionSet : [],
        })
    }, [tryHighlight, unsavedQuestion])

    const onInjectQuestion = useCallback((question, newParentTree, bypassAlert, passedQuestions) => {

        const allQuestions = passedQuestions ? JSON.parse(JSON.stringify(passedQuestions)) : JSON.parse(JSON.stringify(questions))

        if(unsavedQuestion && !bypassAlert) {
            setShowUnsavedQuestionModal(false)
            return toggleAlertBS('info', 'The question you have selected has unsaved changes. Please save (or delete) the current question you are updating before adding a new question.')
        }

        onSetUnsavedForm(true);

        const questionNumber = getQuestionPageCount(question.page);

        if(questionNumber >= config.MAX_PAGE_QUESTIONS) {
            return toggleAlertBS('info', `Pages are limited to a ${config.MAX_PAGE_QUESTIONS} question max including all nested branches`)
        }

        const newQuestion = createNewQuestion(page);

        let q = allQuestions
        newParentTree.forEach(parentId => {
            const index = q.findIndex(i => i.id === parentId);
            q = q[index].questions;
        })

        const index = q.findIndex(i => i.id === question.id);
        q.splice([index + 1], 0,  newQuestion);

        setPageQuestions(questionNumber + 1)
        setQuestions(allQuestions)
        onSelect(newQuestion, null, newParentTree, bypassAlert)
    }, [page, questions, getQuestionPageCount, createNewQuestion, unsavedQuestion, onSetUnsavedForm, onSelect, config.MAX_PAGE_QUESTIONS])


    // remove question from state
    const onQuestionDeleted = useCallback((question) => {
        onSetUnsavedForm(true);
        const _questions = JSON.parse(JSON.stringify(questions));

        let q = _questions
        selected.parentTree.forEach(parentId => {
            const index = q.findIndex(i => i.id === parentId);
            q = q[index].questions;
        })

        const index = q.findIndex(i => i.id === question.id);
        q.splice(index, 1);

        setUnsavedQuestion(false)
        setPageQuestions(getQuestionPageCount(null, -1 - question.questions.length))
        setQuestions(_questions)
        setSelected({
            question: {},
            parentTree: []
        })
    }, [questions, selected.parentTree, getQuestionPageCount, onSetUnsavedForm]);

    // updates a question
    const onQuestionSaved = useCallback((question) => {

        let checkBranch = false;

        if(selected.parentTree && selected.parentTree.length >= config.MAX_NEST_LENGTH - 1 && (question.branch_on_answer || question.type === 'loop')) {
            checkBranch = true;
        }

        if(checkBranch) {
            return toggleAlertBS('info', `Questions can have at most ${config.MAX_NEST_LENGTH} branch splits. Please unselect "Create Branch On Answer" or select a different question type before saving the question.`)
        }

        onSetUnsavedForm(true);

        let newQuestion;

        if((question.branch_on_answers.length || question.type === 'loop' || question.type === 'month-loop' || question.type === 'two-week-loop' || question.type === 'one-week-loop' ) && !question.questions.length) {
            newQuestion = createNewQuestion(page);
            question.questions = [newQuestion]

            if(question.type === 'loop') {
                question.branch_on_answer = true;
                question.branch_on_answers = ['1','2','3','4','5','6','7','8','9','10'];
            } else if(question.type === 'month-loop') {
                question.branch_on_answer = true;
            } else if(question.type === 'two-week-loop') {
                question.branch_on_answer = true;
            } else if(question.type === 'one-week-loop') {
                question.branch_on_answer = true;
            }
        }

        const _questions = JSON.parse(JSON.stringify(questions));
        question.error = false;
        let q = _questions
        selected.parentTree.forEach(parentId => {
            const index = q.findIndex(i => i.id === parentId);
            q = q[index].questions;
        })

        const index = q.findIndex(i => i.id === question.id);
        q[index] = question;

        setUnsavedQuestion(false);
        setQuestions(_questions)
        addArchkAlert({type: 'success', body: 'Question Saved Successfully.', timeout: 1500})
        if(newQuestion) {
            onSelect(newQuestion, null, selected.parentTree.concat(question.id), true)
        } else {
            // no next question in list? add and select a new question
            if(!q[index + 1]) {
                onInjectQuestion(question, selected.parentTree, true, _questions)
            }
        }
    }, [questions, page, selected.parentTree, createNewQuestion, onSetUnsavedForm, onSelect, onInjectQuestion, config.MAX_NEST_LENGTH])


    // fired when drag ends
    const onDragEnd = useCallback(() => {
        onSelect();
    },[onSelect])

    
    // copies over any changes made to new master state of the questions list
    const setMasterQuestions = useCallback((newQuestions, parentTree) => {
        onSetUnsavedForm(true);
        // if we've changed the master questions branch we can set it right away
        if(!parentTree.length) {
            return setQuestions(newQuestions)
        }

        // let questionsCopy = [...questions];
        let questionsCopy = JSON.parse(JSON.stringify(questions));

        // the the full question set and the found question in the tree
        let questionSet = questionsCopy;
        let parentQuestion = {};

        // for each parent go down the tree setting the question set and parent question
        for (let i = 0; i < parentTree.length; i++) {
            const parentId = parentTree[i];

            parentQuestion = questionSet.find(set => set.id === parentId);
            questionSet = parentQuestion.questions
        }

        // set the questions of the deepest parent to be the new questions
        parentQuestion.questions = newQuestions

        return setQuestions(questionsCopy)

    }, [questions, onSetUnsavedForm]);
    
    const questionErrors = useCallback((question) => {
        let _errs = [];

        if(!question.name || question.name.length < 2) _errs.push('name')

        if(question.type === 'select') {
            if(question.answers.length < 2) _errs.push('answersSelect')
        } else if(question.type === 'checkbox') {
            if(question.answers.length < 1) _errs.push('answersCheckbox')
        }

        if(question.branch_on_answer && !question.branch_on_answers.length) {
            if(question.type !== 'loop') {
                if(question.type !== 'month-loop') {
                    if(question.type !== 'two-week-loop') {
                        if(question.type !== 'one-week-loop') {
                            _errs.push('branchOnAnswers')
                        }
                    }
                }
            }
        }

        if(question.highlight_on_answer && !question.highlight_on_answers.length) {
            _errs.push('highlightOnAnswers')
        }
        return _errs;
    }, []) 

    // fake an id property for every question recursively
    const setIds = useCallback((allQuestions) => {
        const setIds = (questions) => {
            questions.forEach(question => {
                if(!question.id) {
                    question.id = uuidv4();
                }
                // run recursively if branch questions exist
                if(question.questions) {
                    setIds(question.questions)
                }
            })
        }

        setIds(allQuestions)
        return allQuestions;
    }, [])

    const questionRequiresSelectAnswers = useCallback((q) => {
        if(q.type === 'select' || q.type === 'checkbox') return true;
        return false;
    }, [])
    
    const questionCanBranch = useCallback((q) => {

        const foundCustomField = q.type === 'custom-field' && q.custom_field ? customFields.find(f => f._id === q.custom_field) : {};

        if(foundCustomField) {
            if(
                q.type === 'select' ||
                q.type === 'checkbox' ||
                q.type === 'yes-no' ||
                q.type === 'state' ||
                q.type === 'loop' ||
                q.type === 'month-loop' ||
                q.type === 'two-week-loop' ||
                q.type === 'one-week-loop' ||
    
                foundCustomField.type === 'select' ||
                foundCustomField.type === 'checkbox' ||
                foundCustomField.type === 'yes-no' ||
                foundCustomField.type === 'state'
            ) return true;
        }

        return false;
    }, [customFields])

    const onAddPage = useCallback(() => {
        if(unsavedQuestion) {
            return toggleAlertBS('info', 'The question you have selected has unsaved changes. Please save (or delete) the current question you are updating before adding a new page.')
        }
        
        onSetUnsavedForm(true);

        const _form = JSON.parse(JSON.stringify(form))
        const _questions = JSON.parse(JSON.stringify(questions))
        const _page = _form.pages;

        if(_form.pages >= config.MAX_FORM_PAGES) {
            return toggleAlertBS('info', `A form may have at most ${config.MAX_FORM_PAGES} pages`);
        }

        const newQuestion = createNewQuestion(_page)

        // push a new question for the page
        _questions.push(newQuestion)
        // set the new number of pages
        _form.pages = _page + 1;
        // update form
        setForm(_form)
        // set updated question
        setQuestions(_questions)
        // set the new page to show
        onSetPage(_page, true)
        // auto select the first new question on the page
        onSelect(newQuestion)

    }, [form, questions, onSelect, onSetPage, createNewQuestion, unsavedQuestion, onSetUnsavedForm, config.MAX_FORM_PAGES])

    const onRemovePage = useCallback((page) => {

        onSetUnsavedForm(true);

        const _form = JSON.parse(JSON.stringify(form))
        _form.pages = _form.pages - 1;
        onSetPage(page - 1 < -1 ? -1 : page - 1)
        setForm(_form)

        const newQuestions = questions.filter(q => q.page !== page);
        newQuestions.forEach(q => {
            if(q.page > page) q.page = q.page - 1;
        })
        setQuestions(newQuestions);
    }, [form, questions, onSetPage, onSetUnsavedForm])

      // 1. save state as question will be removed
    // 2. check fields are valid and all pages link
    // 3. either update or create the form
    // 4. if creating a form redirect to the all forms page
    const onSaveToDB = useCallback(async () => {

        let invalidQuestions = [];
        let invalidPages = []
        const _questions = JSON.parse(JSON.stringify(questions));

        const getErrors = (__questions) => {
            __questions.forEach(question => {
                const _errs = questionErrors(question);
                if(_errs.length) {
                    question.error = true;
                    invalidQuestions.push(question);
                    if(!invalidPages.includes(question.page)) invalidPages.push(question.page)
                } else {
                    question.error = false
                }
                if(question.questions) {
                    getErrors(question.questions)
                }
            })
        }
      
        getErrors(_questions)

        for (let i = 0; i < form.pages; i++) {
            const foundPageQuestion = _questions.some(q => q.page === i)
            if(!foundPageQuestion && !invalidPages.includes(i)) invalidPages.push(i)
        }

        if(invalidQuestions.length) {
            const pageString = invalidPages.map((p, i) => i === 0 ? parseInt(p + 1): ' ' + parseInt(p + 1))
            toggleAlertBS('info', `Please correct the errors on page(s) "${pageString}" before saving this form. Invalid questions will be highlighted in red on the left hand column.`)
            return setQuestions(_questions)
        }

        if(invalidPages.length) {
            const pageString = invalidPages.map((p, i) => i === 0 ? parseInt(p + 1): ' ' + parseInt(p + 1))
            toggleAlertBS('info', `Page(s) "${pageString}" do not have any questions associated with them. Please either remove the page or at at least one question to every page.`)
            return setQuestions(_questions)
        }

        if(!form.pages) {
            return toggleAlertBS('info', 'A form must have at least one page before it can be saved.')
        }
        if(!questions.length) {
            return toggleAlertBS('info', 'A form must have at least one question before it can be saved.')
        }

        toggleStandardLoader(true);

        const data = { ...form, questions: questions, division: selected_division._id }

        // update existing
        if(formId) {
            const saved =  await api.forms.update(formId, data);
            if(saved.success) {
                toggleAlertBS(false, 'Form saved successfully.');
                onSetUnsavedForm(false);
            } else {
                toggleAlertBS(true, `Something's not right, please try again.`)
            }
        // save new
        } else {
            const saved =  await api.forms.create(data);
            if(saved.success) {
                toggleAlertBS(false, 'Form saved successfully.');
                setFormId(saved.data._id)
                onSetUnsavedForm(false);
            } else {
                toggleAlertBS(true, `Something's not right, please try again.`)
            }
        }

        toggleStandardLoader(false);


    }, [questions, questionErrors, form, formId, onSetUnsavedForm, selected_division._id])

     // return form from database if _id is set
     const fetchForm = useCallback(async(_id) => {
        const form = await api.forms.findById(_id);
        if(form.data) {
            setForm({
                _id               : form.data._id,
                name              : form.data.name,
                active            : form.data.active,
                clear_on_submit   : form.data.clear_on_submit,
                send_emails_to    : form.data.send_emails_to,
                pages             : form.data.pages,
                redirect_url      : form.data.redirect_url,
                custom_form      : form.data.custom_form,
                division      : form.data.division,
            })

            setQuestions(setIds(form.data.questions))
            setLoaded(true);
            setPage(0)
         
        } else {
            setRedirect('/dashboard/forms/all');
        }
    }, [setIds])

    const fetchConfig = useCallback(async () => {
        const _config = await api.forms.getConfig();
        if(!_config.data) return setErr(true)
        setConfig(_config.data)
    }, [])
    
    const fetchCustomFields = useCallback(async () => {
        const _customFields = await api.custom_fields.find(selected_division._id);
        if(!_customFields.data) return setErr(true)
        setCustomFields(_customFields.data)
    }, [selected_division._id])

    useEffect(() => {

        const _id = match.params.form_id
        fetchConfig()
        fetchCustomFields();

        // if _id is new we are creating a new form
        if(_id === 'new') {
            const newQuestion = createNewQuestion(0)
            setForm(JSON.parse(JSON.stringify(state.form)));
            setQuestions([newQuestion]);
            onSelect(newQuestion)
            setLoaded(true);
        } else {
            fetchForm(_id);
            setFormId(_id)
        }

        setPageQuestions(getQuestionPageCount(0))

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // zap dashboard specific
    useEffect(() => {

        if(!selected_division._id) {
            toggleAlertBS('info', 'A division must be created for editing a form.')
            setRedirect('/system/divisions/create')
        }

        document.body.classList.add('bg-white')
        return () => {
            document.body.classList.remove('bg-white')
        }
       
    }, [selected_division._id])

    if(err) return <div className="alert alert-warning text-center" style={{marginLeft: -200}}><i className="fas fa-exclamation-triangle mr-2 " /> Please refresh your page and try again.</div>
    if(redirect) return <Redirect to={redirect} />
    if(!loaded) return <div className="py-6"><Circle /></div>

    if(!device.info.isLG) {
        return (
            <p className="text-center my-4 ml-auto mr-auto " style={{maxWidth: '90%', width: 800}}>
                <i className="fas fa-info-circle text-info mr-2 " />The form builder must be accessed on a laptop or desktop device over 1000px wide. If you are on a laptop or desktop and this message appears try maximizing your browser or zooming out.
            </p>
        )
    }

    return (
        <div id="page-form">

            <ConfirmationModal 
                showModal={showUnsavedQuestionModal ? true : false}
                toggleModal={() => setShowUnsavedQuestionModal(false)}
                title="Proceed With Unsaved Changes"
                body={(
                    <span>The question you are on currently has unsaved changes. Are you sure you wish to discard these changes and select a new question?</span>
                )}
                onConfirmation={() => onSelect(showUnsavedQuestionModal.question, showUnsavedQuestionModal.questionSet, showUnsavedQuestionModal.parentTree, true)}
            />

            {form.custom_form ? (
                <div className="archk-custom-form">
                    <p className="text-center mt-4 ml-auto mr-auto" style={{maxWidth: 800}}><i className="fas text-info fa-info-circle mr-2 " /> This is a custom form and cannot have any questions edited. You may update the form configuration such as the form name, and submission rules by clicking the <b className="text-info">blue</b> "Form Settings" button in the top right corner of the screen.</p>
                </div>
            ) : null}

            <Header 
                form={form}
                onSetForm={onSetForm}
                onSaveToDB={onSaveToDB}
                unsavedForm={unsavedForm}
                config={config}
                selected_division={selected_division}
            />

            <Row>

                <ColPage 
                    page={page}
                    onAddPage={onAddPage}
                    onSetPage={onSetPage}
                    form={form}
                    questions={questions} 
                    setQuestions={setQuestions}
                    unsavedQuestion={unsavedQuestion}
                    setUnsavedForm={setUnsavedForm}
                    config={config}
                />

                <ColFlow 
                    onInjectQuestion={onInjectQuestion}
                    setMasterQuestions={setMasterQuestions}
                    questions={questions} 
                    onSelect={onSelect} 
                    onDragEnd={onDragEnd}
                    pageQuestions={pageQuestions}
                    page={page}
                    onRemovePage={onRemovePage}
                    unsavedQuestion={unsavedQuestion}
                />

                <SelectedQuestion 
                   selected={selected}
                   onQuestionSaved={onQuestionSaved}
                   onQuestionDeleted={onQuestionDeleted}
                   questionRequiresSelectAnswers={questionRequiresSelectAnswers}
                   questionCanBranch={questionCanBranch}
                   onSetUnsavedQuestion={onSetUnsavedQuestion}
                   unsavedQuestion={unsavedQuestion}
                   questionErrors={questionErrors}
                   config={config}
                   customFields={customFields}
                />

            </Row>
        </div>
    )

}

const mapStateToProps = state => {
	return {
	    device: state.device,
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(FormEdit);