import { Input, FormGroup } from "reactstrap";
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import { useCallback, useState, useEffect } from 'react';

import api from 'api'

import Modalify from 'components/functional/modals/Modalify'

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

const SystemWorkflowsEdit = ({workflow_id, onSaved, showModal, toggleModal, selected_division}) => {

    const [workflow, setWorkflow] = useState(null)
    const [err, setErr] = useState(null)
    const [redirect, setRedirect] = useState(null)

    const fetchData = useCallback(async () => {
        if(!workflow_id) return setWorkflow({});
        const workflow = await api.workflows.findById(workflow_id)
        if(!workflow.data) return setErr(true);
        setWorkflow(workflow.data)
    }, [workflow_id])

    const onInputChange = useCallback(( field, value) => {
        const newWorkflow = Object.assign({}, workflow)
        newWorkflow[field] = value;
        setWorkflow(newWorkflow);
    }, [workflow])

    const onSave = useCallback(async () => {
        setErr(false)
        let newState = Object.assign({}, workflow);

        if(!newState.name) {
            return setErr([`A workflow must have a name.`])
        }
        
        setWorkflow(newState)

        let saved;

        toggleStandardLoader(true)
        if(newState._id) {
            saved = await api.workflows.update(newState._id, newState, true);
        } else {
            saved = await api.workflows.create({...newState, division: selected_division._id}, true);
        }
        toggleStandardLoader(false)

        // show error messages if unsuccessful
        if(saved.success) {
            toggleAlertBS(false, "Workflow Saved Successfully")
            fetchData();
            if(toggleModal) toggleModal();
            if(onSaved) onSaved(saved.data)
        } else {
            return setErr(saved.message);
        }
    
    }, [workflow, fetchData, toggleModal, onSaved, selected_division._id])

    useEffect(() => {
        if(toggleModal) {
            if(showModal) {
                fetchData()
            } else {
                setErr(null)
                setWorkflow('')
            }
        } else {
            fetchData()
        }
    }, [showModal, toggleModal, fetchData])

    useEffect(() => {
        if(!selected_division._id) {
            toggleAlertBS('info', 'A division must be created for editing a workflow.')
            setRedirect('/system/divisions/create')
        }
    }, [selected_division._id])

    if(redirect) return <Redirect to={redirect} />

    return (
        <Modalify 
            showModal={showModal}
            toggleModal={toggleModal}
            modalTitle={workflow && workflow._id ? "Update Workflow" : "Create Workflow"}
            err={err}
            footer={(
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            )}
            sections={!workflow ? [] :  [
                (
                    <div>

                        <FormGroup>
                            <label className="form-control-label">Name*</label>
                            <Input 
                                type="text"
                                value={workflow.name || ''}
                                onChange={e => onInputChange('name', e.target.value)}
                            />
                        </FormGroup>
                    </div>
                ),
            ]}
        />
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SystemWorkflowsEdit);