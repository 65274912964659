import { useCallback, useState } from "react";
import { Link } from 'react-router-dom'

import { Card, Badge } from "reactstrap";
import moment from 'moment';

import formatText from 'utils/formatText';
import api from 'api';

import TextButtonRow from 'components/markup/layout/TextButtonRow'
import Circle from 'components/markup/loading/Circle'
import ConfirmationModal from 'components/functional/modals/Confirmation'
import TableIcons from 'components/markup/icons/TableIcons'
import ModalToggler from 'components/functional/modals/Toggler'
import Table from 'components/functional/tables/Standard';
import NameCircle from 'components/markup/misc/NameCircle';
import ApiError from "components/markup/layout/ApiError";

import UsersEdit from '../components/Edit'
import UsersPassword from '../components/Password'
import { toast }  from 'react-toastify'

import ObjectFinder from 'components/system/Objects/Finder';

const UsersAll = ({setTab}) => {

    const [ hideTable, setHideTable ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ userToDelete, setUserToDelete ] = useState(false)
    const [ error, setError ] = useState(false);

    // helper for this.downloadCSV and this.query
    const fetchData = useCallback((params, isCSV, shouldReload) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(shouldReload) setLoading(true)
        const query = await api.users.search({ ...params, filter: { deleted: false }, isCSV })
        if(shouldReload) setLoading(false)

        if(!query.data) return setError(query.message)
        return resolve(query.data)
    }), [sizePerPage])

    // run query to get data from the server
    const query = useCallback(async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }, [fetchData])

    const onDeleteUser = useCallback(async (user) => {
        const deleted = await api.users.delete(userToDelete._id);
        if(!deleted.success) return toast.error(`Something's not right, please try again.`)
        
        setHideTable(true)
        setTimeout(() => setHideTable(false), 350)
    }, [userToDelete])
 
    const columns = [
        {
            dataField: "given_name",
            text: 'Name',
            headerFormatter: () => {
                return <div id="archk-tutorial-contacts-name">Name</div>
            },
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )
                return (
                    <Link to={`/staffing/users/${row._id}`}>
                        <span className="cursor-pointer ">
                            <NameCircle width={27} contact={row} style={{marginRight: 5, position: 'relative', top: -8}} />{' '}     
                            <span style={{display: 'inline-block', width: 'calc(100% - 38px)'}}>
                                <div className="mb--2">{row.display_name}</div>
                                <div>{row.username}</div>
                            </span>
                        </span>
                    </Link>
                )
                
            },
        },
        {
            dataField: "title",
            text: 'Title',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : row.title
            )
        },
        {
            dataField: "email",
            text: 'Email',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' :
                <div>
                    {row.email && <div key={row._id}><i className="text-muted fas fa-envelope mr-2" /> {row.email}</div>}
                    <div>
                        {row.phone && <div key={row.created_at} > <i className="text-muted fas fa-mobile mr-2" /> {formatText(row.phone).phone()} </div>}
                    </div>
                </div>
            )
        },
       
        {
            dataField: "divisions",
            text: 'Divisions',
            isDummyField: true,
            headerStyle: {width: 200},
            formatter: (cell, row) => (
                row.name === 'no data' ? '' :
                !row.divisions || (row.divisions && !row.divisions.length) ? '-' : (
                    row.divisions.map((division, i) => (
                        <Badge key={i} color="warning" className="ml-0 mr-2 my-1">
                            <ObjectFinder collection="divisions" _id={division} />
                        </Badge>
                    ))
                )
            )
        },
        {
            dataField: "created_at",
            text: 'Created',
            formatter: (cell, row) => row.name === 'no data' ? '' : moment.unix(row.created_at).format('MMM Do, YYYY')
        },
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right',},
            text: 'Actions',
            headerFormatter: () => {
                return <div id="archk-tutorial-contacts-actions">Actions</div>
            },
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                        <TableIcons
                            icons={[
                                { 
                                    icon: 'fas fa-lock',
                                    color: 'warning', 
                                    wrapper: ModalToggler, 
                                    component: UsersPassword,
                                    _id: row._id,
                                    onSaved: () => fetchData(null, null, true)
                                },
                                { 
                                    icon: 'fas fa-edit',
                                    color: 'success', 
                                    wrapper: Link, 
                                    to: `/staffing/users/${row._id}`
                                },
                                {  
                                    icon: 'fas fa-trash', 
                                    color: 'danger',
                                    onClick: () => setUserToDelete(row)
                                },
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(error) return <ApiError err={error} />
    if(loading) return <div><Circle /></div>

    return (

        <>

            <TextButtonRow 
                title={"All Users"}
                button={(
                    <button onClick={() => setTab('create')} className="btn btn-success">
                        <i className="fas fa-plus mr-2 " /> Add User
                    </button>
                )}
            />

            <Card className="card-color card-primary table-fixed">
                {hideTable ? <Circle /> : (
                    <Table
                        tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                        placeholder="Search..."
                        query={query}
                        columns={columns}
                        data={tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                        totalDocuments={tableData.total_documents}
                        sizePerPage={sizePerPage}
                        onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                    />
                )}
            </Card>

            <ConfirmationModal 
                showModal={userToDelete ? true : false}
                toggleModal={() => setUserToDelete(null)}
                title="Delete User"
                body={(
                    <span>
                        Are you sure you wish to delete the user {userToDelete ? userToDelete.display_name : ''}? This will prevent the user from logging in or coming up in search results in the future.
                    </span>
                )}
                onConfirmation={onDeleteUser}
            />

        </>
    )

}

export default UsersAll;