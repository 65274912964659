import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row } from "reactstrap";

import { useHistory, useLocation } from "react-router-dom";
import BarLine from 'components/charts/BarLine';
import PieArea from 'components/charts/PieArea';
import { useCallback, useEffect, useState } from 'react';

import ApiError from 'components/markup/layout/ApiError';
import Circle from 'components/markup/loading/Circle'

import api from 'api'
import moment from 'moment';

import { formatCurrency } from 'utils/currency';
import formatText from 'utils/formatText';


const MarketingOverview = ({range, selected_division}) => {

    const location = useLocation()

    const [data, setData] = useState(null)
    const [err, setErr] = useState(null)

    const renderNumber = useCallback((number) => {
        if(!number) return '$0.00'
        return formatCurrency(number)
    }, [])
   
    const renderPercentChange = useCallback((currentNumber, pastNumber) => {
        if(!pastNumber) return <span className='text-muted'>+NA%</span>
        if(!currentNumber && !pastNumber) return <span className='text-muted'>+0%</span>
        if(currentNumber && !pastNumber) return <span className='text-muted'>+NA%</span>
        const number = parseFloat(((currentNumber / pastNumber) - 1).toFixed(4));
        return number < 0 ? <span className='text-danger'>{number * 100}%</span> : <span className='text-success'>+{number % 100}%</span>
    }, [])

    const fetchData = useCallback(async () => {
        setData(null)
        let type = 
            location.pathname.includes(30) ? 30 : 
            location.pathname.includes(60) ? 60 : 
            location.pathname.includes(90) ? 90 : 
            location.pathname.includes(180) ? 180 : 
            location.pathname.includes(365) ? 365 : 
            30;
        
        let date = 
            type === 30 ? parseInt(moment().endOf('day').format('X')) :
            type === 60 ? parseInt(moment().endOf('day').format('X')) :
            type === 90 ? parseInt(moment().endOf('week').format('X')) :
            type === 180 ? parseInt(moment().endOf('month').add(1, 'seconds').format('X')) :
            parseInt(moment().endOf('month').add(1, 'seconds').format('X'))

        const _data = await api.marketing.overview({
            division: selected_division._id,
            type: type,
            date: date
        })

        if(!_data.success) return setErr(data.message ? data.message : 'Server Error')
        setData(_data.data)
    }, [location, selected_division._id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(err) return <ApiError error={err} />
    if(!data) return <div className='py-6'><Circle /></div>

    return (

        <>


        <Row>

            <Col md={12}>
                <BarLine 
                    type="line"
                    title={`Leads & Hires Last ${range} Days`}
                    labels={data.timeSeries.labels}
                    datasets={data.timeSeries.datasets}
                />

                <Row>
                    <Col md={3}>
                        <Card className='text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Estimated Value</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{renderNumber(data.current.estimatedValue)} <small><small className='pl-4'>{renderPercentChange(data.current.estimatedValue, data.past.estimatedValue)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                12 Weeks Ago: <b className='text-dark font-weight-bold '>{renderNumber(data.past.estimatedValue)}</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className='text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Actual Value</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{renderNumber(data.current.billingTotal)} <small><small className='pl-4'>{renderPercentChange(data.current.billingTotal, data.past.billingTotal)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                12 Weeks Ago: <b className='text-dark font-weight-bold '>{renderNumber(data.past.billingTotal)}</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className='text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Amount Spent</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{renderNumber(data.current.cost)} <small><small className='pl-4'>{renderPercentChange(data.current.cost, data.past.cost)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                12 Weeks Ago: <b className='text-dark font-weight-bold '>{renderNumber(data.past.cost)}</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className='text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Avg Hired Value</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{renderNumber(data.current.avgHiredValue)} <small><small className='pl-4'>{renderPercentChange(data.current.avgHiredValue, data.past.avgHiredValue)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                12 Weeks Ago: <b className='text-dark font-weight-bold '>{renderNumber(data.past.avgHiredValue)}</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className='text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Avg Paid On Matter</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{renderNumber(data.current.billingPaid)} <small><small className='pl-4'>{renderPercentChange(data.current.billingPaid, data.past.billingPaid)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                12 Weeks Ago: <b className='text-dark font-weight-bold '>{renderNumber(data.past.billingPaid)}</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className='text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Cost Per Lead</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{renderNumber(data.current.costPerLead)} <small><small className='pl-4'>{renderPercentChange(data.current.costPerLead, data.past.costPerLead)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                12 Weeks Ago: <b className='text-dark font-weight-bold '>{renderNumber(data.past.costPerLead)}</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className='text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Cost Per Acquisition</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{renderNumber(data.current.costPerAcquisition)} <small><small className='pl-4'>{renderPercentChange(data.current.costPerAcquisition, data.past.costPerAcquisition)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                12 Weeks Ago: <b className='text-dark font-weight-bold '>{renderNumber(data.past.costPerAcquisition)}</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className='text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Cost Per Win</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{renderNumber(data.current.costPerWin)} <small><small className='pl-4'>{renderPercentChange(data.current.costPerWin, data.past.costPerWin)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                12 Weeks Ago: <b className='text-dark font-weight-bold '>{renderNumber(data.past.costPerWin)}</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    
                    <Col md={3}>
                        <Card className='text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Total Leads</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{data.current.leads} <small><small className='pl-4'>{renderPercentChange(data.current.leads, data.past.leads)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                12 Weeks Ago: <b className='text-dark font-weight-bold '>{data.past.leads}</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className='text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Total Potential</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{data.current.potential} <small><small className='pl-4'>{renderPercentChange(data.current.potential, data.past.potential)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                12 Weeks Ago: <b className='text-dark font-weight-bold '>{data.past.potential}</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    
                    <Col md={3}>
                        <Card className='text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Total Hires</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{data.current.hires} <small><small className='pl-4'>{renderPercentChange(data.current.hires, data.past.hires)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                12 Weeks Ago: <b className='text-dark font-weight-bold '>{data.past.hires}</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    
                    <Col md={3}>
                        <Card className='text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Total Won</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{data.current.won} <small><small className='pl-4'>{renderPercentChange(data.current.won, data.past.won)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                12 Weeks Ago: <b className='text-dark font-weight-bold '>{data.past.won}</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    
                    <Col md={3}>
                        <Card className='text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Total DNQ</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{data.current.dnq} <small><small className='pl-4'>{renderPercentChange(data.current.dnq, data.past.dnq)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                12 Weeks Ago: <b className='text-dark font-weight-bold '>{data.past.dnq}</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className='text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Total No Hire</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{data.current.noHire} <small><small className='pl-4'>{renderPercentChange(data.current.noHire, data.past.noHire)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                12 Weeks Ago: <b className='text-dark font-weight-bold '>{data.past.noHire}</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className='text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Total Refer Out</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{data.current.referOut} <small><small className='pl-4'>{renderPercentChange(data.current.referOut, data.past.referOut)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                12 Weeks Ago: <b className='text-dark font-weight-bold '>{data.past.referOut}</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    
                    <Col md={3}>
                        <Card className='text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Contributing Sources</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{data.current.contributingSources} <small><small className='pl-4'>{renderPercentChange(data.current.contributingSources, data.past.contributingSources)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                12 Weeks Ago: <b className='text-dark font-weight-bold '>{data.past.contributingSources}</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    
                    <Col md={3}>
                        <Card className='text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Avg Time To Convert</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>
                                    {formatText(data.current.timeTillHired).secondsToTime()} 
                                    <small><small className='pl-4'><span className={data.current.timeTillHired > data.past.timeTillHired ? 'text-danger' : 'text-success'}>{data.current.timeTillHired > data.past.timeTillHired ? <i className="fas fa-arrow-up" /> : <i className="fas fa-arrow-down" />}</span> </small></small>
                                </h2>
                            </CardBody>
                            <CardFooter>
                                12 Weeks Ago: <b className='text-dark font-weight-bold '>{formatText(data.past.timeTillHired).secondsToTime()}</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className='text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Avg Time Till Won</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>
                                    {formatText(data.current.timeTillWon).secondsToTime()}
                                    <small><small className='pl-4'><span className={data.current.timeTillWon > data.past.timeTillWon ? 'text-danger' : 'text-success'}>{data.current.timeTillWon > data.past.timeTillWon ? <i className="fas fa-arrow-up" /> : <i className="fas fa-arrow-down" />}</span> </small></small>

                                </h2>
                            </CardBody>
                            <CardFooter>
                                12 Weeks Ago: <b className='text-dark font-weight-bold '>{formatText(data.past.timeTillWon).secondsToTime()}</b>
                            </CardFooter>
                        </Card>
                    </Col>
             
                    <Col md={3}>
                        <Card className='text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Leads In Business Hours</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{data.current.inBusinessHours} <small><small className='pl-4'>{renderPercentChange(data.current.inBusinessHours, data.past.inBusinessHours)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                12 Weeks Ago: <b className='text-dark font-weight-bold '>{data.past.inBusinessHours}</b>
                            </CardFooter>
                        </Card>
                    </Col>

                    <Col md={3}>
                        <Card className='text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Leads Outside Business Hours</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>{data.current.leads - data.current.inBusinessHours} <small><small className='pl-4'>{renderPercentChange(data.current.leads - data.current.inBusinessHours, data.past.leads - data.past.inBusinessHours)}</small></small></h2>
                            </CardBody>
                            <CardFooter>
                                12 Weeks Ago: <b className='text-dark font-weight-bold '>{data.past.leads - data.past.inBusinessHours}</b>
                            </CardFooter>
                        </Card>
                    </Col>

                
                    {/* <Col md={3}>
                        <Card className='text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Best Performing Source</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>TLS PPC</h2>
                            </CardBody>
                            <CardFooter>
                                CPA: <b className='text-dark font-weight-bold '>$253</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className='text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Worst Performing Source</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>LSAs</h2>
                            </CardBody>
                            <CardFooter>
                                CPA: <b className='text-dark font-weight-bold '>$608</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className='text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Highest Paid Source</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>LSAs</h2>
                            </CardBody>
                            <CardFooter>
                                CPA: <b className='text-dark font-weight-bold '>$608</b>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className='text-center'>
                            <CardHeader>
                                <CardTitle className="mb-0">Lowest Paid Source</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <h2 className='display-4 mb-0'>LSAs</h2>
                            </CardBody>
                            <CardFooter>
                                CPA: <b className='text-dark font-weight-bold '>$608</b>
                            </CardFooter>
                        </Card>
                    </Col> */}

                </Row>
            </Col>

            {/* <Col md={3}>
                <PieArea 
                    type="pie"
                    title="Source Breakdown"
                    labels={['TLS', 'Google', 'Facebook', "Unknown"]}
                    dataset={[12, 19, 3, 5]}
                />

                <PieArea 
                    type="pie"
                    title="Hired Breakdown"
                    labels={['TLS', 'Google', 'Facebook', "Unknown"]}
                    dataset={[486, 240, 194, 600]}
                />

                <PieArea 
                    type="pie"
                    title="Won Breakdown"
                    labels={['TLS', 'Google', 'Facebook', "Unknown"]}
                    dataset={[486, 240, 194, 600]}
                />
            </Col> */}


        </Row>


      
    </>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(MarketingOverview);