import { useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Card, CardHeader, Input } from "reactstrap";

import api from 'api';

import TextButtonRow from 'components/markup/layout/TextButtonRow'
import ConfirmationModal from 'components/functional/modals/Confirmation'
import TableIcons from 'components/markup/icons/TableIcons'
import ModalToggler from 'components/functional/modals/Toggler'

import TagsEdit from '../components/Edit'

import moment from "moment";
import LoadingTable from 'components/markup/loading/Table';

import ApiError from "components/markup/layout/ApiError";

import { toast } from 'react-toastify'
import { setAssociationErrors } from 'store/functions/system/system'

const SystemTagAll = ({setTab, selected_division}) => {
    const [ tableData, setTableData ] = useState(null)
    const [ tagToDelete, setTagToDelete ] = useState(false)
    const [ error, setError ] = useState(false);
    const [ search, setSearch ] = useState('');

    // helper for this.downloadCSV and this.query
    const fetchData = useCallback(() => new Promise (async resolve => {
        const query = await api.tags.find()
        
        if(query.data) {
            return setTableData(query.data.filter(tag => tag.division === selected_division._id))
        } else {
            setError(query.message)
        }
    }), [selected_division._id])

    const onDeleteTag = useCallback(async () => {
        const deleted = await api.tags.delete(tagToDelete._id);
        
        if(deleted.success) {
            fetchData();
            toast.success('Tag Successfully Removed')
        } else {
            if(deleted.message[0] && deleted.message[0].err === 'associations') {
                setAssociationErrors(deleted.message[0].associations)
            } else {
                toast.error(`Internal Error, Please Try Again`)
            }
        }
    }, [fetchData, tagToDelete])

    const shouldRender = useCallback((tag) => {
        if(!search) return true;
        
        const s = search.toLowerCase().trim();
        const n = tag.name.toLowerCase().trim();
        if(n.includes(s)) return true;
        if(n === s) return true;

        return false;
    }, [search])


    useEffect(() => {
        fetchData()
    }, [fetchData])
 
    if(error) return <ApiError err={error} />

    const results = search && tableData ? tableData.filter(tag => shouldRender(tag)) : tableData

    return (

        <>

            <TextButtonRow 
                title={"All Tags"}
                button={(
                    <button onClick={() => setTab('create')} className="btn btn-success"><i className="fas fa-plus mr-2 " /> Add New</button>
                )}
            />

            <Card className="card-color card-primary table-fixed">
                <CardHeader className="pl-2 pr-4 pb pt-3 pb-3 ml-1">
                    <Input 
                        className="ml-1"
                        style={{width: 190, maxWidth: '100'}}
                        placeholder="Search..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </CardHeader>
                
                {!results ?  <LoadingTable /> : (
                    <div className="table-responsive ">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Tag</th>
                                    <th className="text-right">Created</th>
                                    <th className="text-right">Date</th>
                                </tr>
                            </thead>

                            <tbody>
                                {results.length ? (
                                    results.map(tag => (
                                        <tr key={tag._id}>
                                            <td>
                                                <ModalToggler component={TagsEdit} onSaved={fetchData} _id={tag._id}>
                                                    <span className="cursor-pointer">{tag.name}</span>
                                                </ModalToggler>
                                            </td>

                                            <td className="text-right">
                                                <div>{moment.unix(tag.created_at).format('MMM Do, YYYY')}</div>
                                            </td>

                                            <td className="text-right">
                                                <TableIcons
                                                    icons={[
                                                        { 
                                                            icon: 'fas fa-edit',
                                                            color: 'success', 
                                                            wrapper: ModalToggler, 
                                                            component: TagsEdit,
                                                            onSaved: fetchData,
                                                            _id: tag._id
                                                        },
                                                        {  
                                                            icon: 'fas fa-trash', 
                                                            color: 'danger',
                                                            onClick: () => setTagToDelete(tag)
                                                        },
                                                    ]}
                                                />

                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td>No Tags Found</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}

                            </tbody>
                        </table>
                    </div>
                )}

            </Card>

            <ConfirmationModal 
                showModal={tagToDelete ? true : false}
                toggleModal={() => setTagToDelete(null)}
                title="Delete Tag"
                body={(
                    <span>
                        Are you sure you wish to delete the tag {tagToDelete ? tagToDelete.name : ''}? This will prevent any associations from being attached to it in the future.
                    </span>
                )}
                onConfirmation={onDeleteTag}
            />

        </>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SystemTagAll);