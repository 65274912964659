/*
Documentation

this reducer holds all the actions we can dispatch for our global user object

*** make sure to declare all action types in actions.js one folder level back

*/

import * as actionTypes from '../../actions';

const initialState = {
    alerts: {
        show:     false,
        text:     null,
        type:     null,
    },
    login_redirect: '',
    loader: false,
    standardLoader: false,
    alertBS: {
        error: false,
        text: null
    },
    archk_alerts: [],
    association_errors: []

}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.SET_ASSOCIATION_ERRORS:

            return {
                ...state,
                association_errors: action.payload
            }
      
        case actionTypes.TOGGLE_SYSTEM_ALERT:

            return {
                ...state,
                alerts: {
                    show: action.payload.boolean,
                    text: action.payload.text,
                    type: action.payload.type,
                }
            }

        case actionTypes.TOGGLE_SYSTEM_LOADER:

            return {
                ...state,
                loader: action.payload,
            }

        case actionTypes.SET_LOGIN_REDIRECT:

            return {
                ...state,
                login_redirect: action.payload,
            }

        case actionTypes.TOGGLE_STANDARD_LOADER:

            return {
                ...state,
                standardLoader: action.payload,
            }
        
        case actionTypes.SET_ARCHK_ALERTS:

            return {
                ...state,
                archk_alerts: action.payload,
            }

        case actionTypes.TOGGLE_SYSTEM_ALERT_BS:

            if(action.payload && action.payload.error !== undefined && action.payload.text) {

                return {
                    ...state,
                    alertBS: {
                        error: action.payload.error,
                        text: action.payload.text,
                    }
                }

            } else {

                return {
                    ...state,
                    alertBS: null
                }

            }

        default:

            return state;

    }

}

export default reducer;
