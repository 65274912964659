import React from "react";
import { connect } from 'react-redux';
import {  Redirect, Route, Switch } from "react-router-dom";

// import Footer from "./components/Footer";
import Navbar from "./components/Navbar/index.js";
// import Sidebar from "./components/Sidebar/index.js";
import Notifications from "./components/Notifications/index.js";
import routes from "./routes";

import { setCompany } from 'store/functions/company/company'
import { setConfig } from 'store/functions/config/config'
// import { setUsers } from 'store/functions/users/users'

import setDivisions from 'store/functions/divisions'
// import setForms from 'store/functions/forms'
// import setTags from 'store/functions/tags'
// import setContactTypes from 'store/functions/contact_types'
import { setSelectedDivision } from 'store/functions/state'

import { JOIN_COMPANY_ROOM } from 'sockets/events';

import ArchkTutorial from 'components/functional/tutorial'
import ArchkAlerts from 'components/functional/alerts'
import Upload from 'components/system/Upload';
import AssociationsError from 'components/system/Associations/Error.js';

import hasPrivilege from 'utils/hasPrivilege'

class Dashboard extends React.Component {
    state = {
        loaded: false,
        sidenavOpen: true,
        showMobileNav: false,
    };

    //get routes for this layout
    getRoutes = routes => routes().map((prop, key) => {
        return (
            <Route
                exact path={`${prop.path}`}
                component={prop.component}
                key={key}
            />
        )
    })

    toggleSidenav = e => {

        if (document.body.classList.contains("g-sidenav-pinned")) {
            document.body.classList.remove("g-sidenav-show");
            document.body.classList.remove("g-sidenav-pinned");
            document.body.classList.remove("noScroll");
        } else {
            document.body.classList.add("g-sidenav-pinned");
            document.body.classList.remove("g-sidenav-hidden");   
            document.body.classList.add("noScroll");
        }

        this.setState({ sidenavOpen: !this.state.sidenavOpen });
    };

    setScreenSize = () => {

        const { device } = this.props

        if(!device.isLG) {
            // document.body.classList.remove("g-sidenav-show");
            // document.body.classList.remove("g-sidenav-pinned");
            // document.body.classList.remove("g-sidenav-hidden");
            this.setState({ sidenavOpen: false, showMobileNav: true });
        } else {
            // document.body.classList.add("g-sidenav-pinned");
            this.setState({ sidenavOpen: true, showMobileNav: false });
        }

    }

    onCreditChange = (data) => {
        // double check company match
        if(this.props.viewing_user.company === data.company) {
            setCompany()
        }
    }

    onSocketReconnect = () => {
        this.props.socket.emit(JOIN_COMPANY_ROOM, this.props.viewing_user.company)
    }

    componentDidCatch = (e) => {

        //don't redirect if we are developing
        if(window.location.hostname !== 'localhost') {
            this.setState({redirect: `/errors/standard?error=${e}&referrerurl=${window.location.href}`})
        }

    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.setScreenSize)
        this.props.socket.off("connect", this.onSocketReconnect);
    }

    componentDidMount = async () => {

        if(!this.props.company._id) {

            await setConfig()
            await Promise.all([
                setCompany(),
                setDivisions(),
            ])
            setSelectedDivision();

            if((window.location.pathname === '/' || window.location.pathname === '/overview') && !hasPrivilege('VIEW.ADMIN_DASHBOARD.OVERVIEW') ) {
                if(hasPrivilege('VIEW.ADMIN_DASHBOARD.MARKETING')) this.setState({redirect: '/marketing'}, () => {
                    this.setState({redirect: null})
                })
                if(hasPrivilege('VIEW.ADMIN_DASHBOARD.ANALYZE')) this.setState({redirect: '/analyze'}, () => {
                    this.setState({redirect: null})
                })
                if(hasPrivilege('VIEW.ADMIN_DASHBOARD.COMMUNICATE')) this.setState({redirect: '/communication'}, () => {
                    this.setState({redirect: null})
                })
                if(hasPrivilege('VIEW.ADMIN_DASHBOARD.AUTOMATE')) this.setState({redirect: '/automations'}, () => {
                    this.setState({redirect: null})
                })
                if(hasPrivilege('VIEW.ADMIN_DASHBOARD.STAFFING')) this.setState({redirect: '/staffing'}, () => {
                    this.setState({redirect: null})
                })
                if(hasPrivilege('VIEW.AI')) this.setState({redirect: '/ai'}, () => {
                    this.setState({redirect: null})
                })
            }

        }

        this.props.socket.on("connect", this.onSocketReconnect);

        this.setScreenSize()
        this.setState({loaded: true})

        window.addEventListener('resize', this.setScreenSize)

    }

    render() {

        const { redirect, loaded, sidenavOpen, showMobileNav } = this.state
        const { device, company, tutorial } = this.props;

        if(redirect) return <Redirect to={redirect} />;
        if(!loaded) return <div />;
        // if(!company.agreement_accepted_at) return <Redirect to="/actions/agreement" />;
        if(company.banned) return <Redirect to="/actions/banned" />;


        return (
            <div className="">

                <ArchkTutorial 
                    isOpen={tutorial.showTutorial}
                    steps={tutorial.steps}
                />

                <AssociationsError />
                <ArchkAlerts />
                <Upload />

                {/* <Sidebar 
                    {...this.props}
                    toggleSidenav={this.toggleSidenav}
                    sidenavOpen={sidenavOpen}
                /> */}

                <div className={`bg-toggler ${device.currentScreenWidth < 1000 && sidenavOpen ? 'show' : null}`} onClick={this.toggleSidenav}/>

                <div className="wave" />

                <div className="main-content content-wrapper" ref="mainContent" onClick={this.closeSidenav} >

                    <Navbar {...this.props} showMobileNav={showMobileNav} toggleSidenav={this.toggleSidenav} />

                    <Notifications />

                    <div className="dashboard-content">
                        <Switch>
                            {this.getRoutes(routes)}
                            <Redirect from="*" to="/" />
                        </Switch>
                    </div>

                    {/* <Footer /> */}

                </div>

            </div>

        );
    }
}

const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	    device: state.device.info,
	    company: state.company,
	    tutorial: state.tutorial.tutorial,
	    load: state.load,
	    socket: state.socket,
	};
};

export default connect(mapStateToProps, '')(Dashboard);

