import React, { useState } from 'react';
import { FormGroup, Input, Badge, UncontrolledTooltip } from 'reactstrap';

const InputAnswers = ({answers, onChange, hasErr, config}) =>  {

    const [answerText, setAnswerText] = useState('');

    // on enter try to add an answer
    const _handleKeyDown = (e) => {
		if (e.key === 'Enter') onAddAnswer()
	}

    // adds an answer
    const onAddAnswer = () => {
        // validate answer is not blank and does not already exist
        if(!answerText) return;
        if(answers.includes(answerText)) return;

        // add the answer and fire the onChange event
        const newAnswers = [...answers];

        if(newAnswers.length > config.FORM_MAX_SEND_TO_EMAILS) return;

        newAnswers.push(answerText);

        setAnswerText('')
        onChange(newAnswers)
    }

    // remove an answer from state and fire the onChange vent
    const onRemoveAnswer = (answer) => {
        let newAnswers = [...answers];
        newAnswers = newAnswers.filter(a => a !== answer);

        onChange(newAnswers)
    }

    return (
        <div className="">
             <FormGroup>
                <label className="form-control-label">
                    Send Emails On Submit To:{' '}
                    <i id="archk-icon-form-emails" className="fas fa-info-circle text-info ml-2 mr-2" /> {' '}
                    <UncontrolledTooltip
                        delay={0}
                        trigger="hover focus"
                        target="archk-icon-form-emails"
                    >
                        {`When this form is submitted, any email in this list will be notified.`}
                    </UncontrolledTooltip>
                    {answers.length >= config.FORM_MAX_SEND_TO_EMAILS ? (
                         `(A form can send to a maximum of ${config.FORM_MAX_SEND_TO_EMAILS} emails upon submission)`
                    ) : '(Press Enter To Add)'}
                </label>
                <Input 
                    onKeyDown={_handleKeyDown}
                    type="text"
                    disabled={answers.length >= config.FORM_MAX_SEND_TO_EMAILS}
                    value={answerText}
                    onChange={(e) => setAnswerText(e.target.value)}
                    invalid={hasErr && answers.length < 2}
                    valid={answers.length >= 2}
                />
            </FormGroup>

            {answers && answers.length ? (
                <div className="possible-options">
                    {answers.map((a, i) => (
                        <Badge color="success" className="mb-3 mr-0 ml-3" key={i}>
                            {a}<i onClick={() => onRemoveAnswer(a)} className="cursor-pointer fas fa-times mx-3 " />
                        </Badge>
                    ))}
                </div>
            ) : (
                <p className="text-sm text-warning font-weight-bold mb-0">
                    No emails given. When submitted, this form will <b>NOT</b> notify any party via email.
                </p>
            )}

        </div>
    )
};

export default React.memo(InputAnswers);

