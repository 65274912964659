import Circle from 'components/markup/loading/Circle';
import { useCallback, useEffect, useState } from 'react';
import { Card, CardHeader, CardTitle, Col, FormGroup, Input, Row } from 'reactstrap';

import api from 'api';
import { toast } from 'react-toastify';

import RowRole from './RoleRow';

const WorkflowEditRoles = ({workflow}) => {

    const [role, setRole] = useState('')
    const [roles, setRoles] = useState(workflow.roles)

    const _handleKeyDown = useCallback((e) => {
		if (e.key === 'Enter') document.getElementById("archk-add-role-button").click();
	}, [])

    const onAdd = useCallback(async () => {
        if(!role) return;
        const _roles = JSON.parse(JSON.stringify(roles));

        const created = await api.workflow_roles.create({
            workflow: workflow._id,
            name: role,
        })
        if(created.success) {
            _roles.push(created.data)
            setRoles(_roles);
            setRole('')   
        } else {
            toast.error("Something went wrong creating this role, please try again and make sure the role is unique.")
        }
      
    }, [roles, role, workflow._id])
    
    const onDelete = useCallback(async (_role) => {
        let _roles = JSON.parse(JSON.stringify(roles));

        const deleted = await api.workflow_roles.delete(_role._id)
        if(deleted.success) {
            _roles = _roles.filter(r => r._id !== deleted.data._id)
            setRoles(_roles);
        } else {
            toast.error("Something went wrong deleting this role, please try again.")
        }
    }, [roles])

    const fetchData = useCallback(async () => {
        const data = await api.workflow_roles.find(workflow._id);
        if(data.data) setRoles(data.data)
    }, [workflow._id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <Card>
            <CardHeader>
                <CardTitle>Add Workflow Roles</CardTitle>
                <p className="text-sm mb-0"><i className="fas fa-info-circle mr-2 info-circle text-info " /> Roles allow you to assign users to a matter. For instance, in a workflow designated for a Bankruptcy case you may create the roles "Attorney", "Paralegal", "Client Liaison".</p>

            </CardHeader>
            <CardHeader>
            
                <label className="form-control-label">Role Name*</label>

                <Row>
                    <div className="col-auto" style={{width: 'calc(100% - 168px)'}}>
                        <FormGroup>
                            <Input 
                                onKeyDown={_handleKeyDown}
                                type="text"
                                value={role}
                                onChange={(e) => setRole(e.target.value)}

                            />
                        </FormGroup>
                    </div>
                    <Col>
                        <button 
                            id="archk-add-role-button"
                            onClick={onAdd} 
                            className="btn btn-outline-info btn-block"
                        >
                            <i className="fas fa-plus mr-2 " /> Add Role
                        </button>
                    </Col>
                </Row>
            </CardHeader>

            {!roles ? <Circle /> : (
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Workflow Roles</th>
                                <th>Field Map ID</th>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {roles.length ? roles.map((role) => <RowRole role={role} onDelete={onDelete} />) : (
                                <tr>
                                    <td>No roles have been created for this workflow</td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}


        </Card>
    )

}

export default WorkflowEditRoles;