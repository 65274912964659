import { useHistory } from 'react-router-dom';

import { Row, Col, Input } from 'reactstrap';

import Favicon from 'assets/img/brand/Favicon.png'

const Header = ({docName, onSetState, showingParty, markers, isTemplate, toggleShowModalSaveTemplate, toggleShowModalSendDocument, configuration}) => {

    const history = useHistory();

    return (
        <div className="archk-template-docs-header border-bottom">
            <Row className="border-bottom pt-2">
                <Col lg={4}>
                    <p className="text-sm mb-0">Document Signing</p>
                </Col>
                <Col lg={2}>
                    <p className="text-sm mb-0"><i className="fas fa-user-edit text-info mr-2 " /> Editing </p>
                </Col>
                <Col lg={2}>
                    <p className="text-sm mb-0 text-right"><i className="fas fa-map-pin mr-2 text-warning " /> Total Markers</p>
                </Col>
                <Col lg={4}>
                    <p className="text-sm mb-0 text-right">Navigation & Actions</p>
                </Col>
            </Row>
            <Row className="py-3">
                
                <Col lg={4} className="align-self-center">
                    <Row>
                        
                        <div className="col-auto pl-0 pr-0" style={{width: 35}}>
                            <img src={Favicon} className="w-100" alt="IntuiDesk Favicon" />
                        </div>

                        <Col >
                            <Input 
                                value={docName}
                                onChange={(e) => {
                                    const val = e.target.value;
                                    onSetState('docName', val && val.length >= configuration.MAX_NAME_LENGTH ? val.slice(0, configuration.MAX_NAME_LENGTH) : val)
                                }}
                            />
                        </Col>

                    </Row>
                </Col>

                <Col lg={2} className="align-self-center archk-template-docs-header-center">
                    <p className="text-cente mb-0">
                        {showingParty === 0 ? 'Document Markup' : <span>Party: #{showingParty}</span>}
                    </p>
                </Col>
                <Col lg={2} className="align-self-center archk-template-docs-header-center">
                    <p className="text-right mb-0">
                        {markers.filter(m => m.party === showingParty).length}/{markers.length}
                    </p>
                </Col>

                <Col lg={4} className="align-self-center text-right">
                    <button onClick={() => history.goBack()} className="btn btn-outline-danger mr-3">
                        <i className="fas fa-arrow-left " /> Back
                    </button>

                    <button 
                        className={`btn btn-outline-success`} 
                        onClick={() => {
                            if(isTemplate) {
                                return toggleShowModalSaveTemplate();
                            } else {
                                return toggleShowModalSendDocument();
                            }
                        }}
                    >
                        {isTemplate ? (
                            <span><i className="fas fa-save mr-2 " /> Save</span>
                        ) : (
                            <span><i className="fas fa-paper-plane mr-2 " /> Send</span>
                        )}
                    </button>
                </Col>

            </Row>
        </div>
    )

}

export default Header;