import { memo, useState } from 'react';
import { Row, Col } from 'reactstrap';

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import ConfirmationModal from 'components/functional/modals/Confirmation'

import QuestionList from './QuestionList';

const ColFlow = ({questions, setMasterQuestions, onSelect, onInjectQuestion, newQuestion, onDragEnd, pageQuestions, onRemovePage, unsavedQuestion, page}) => {

    const [showDeletePage, setShowDeletePage] = useState(false);

    return (

        <Col id="archk-col-flow" lg={4} className="col col-flow z-depth-">

            <ConfirmationModal 
                showModal={showDeletePage ? true : false}
                toggleModal={() => setShowDeletePage(false)}
                title="Delete Page"
                body={(
                    <span>Are you sure you wish to delete page {page + 1}? All questions for the page will be removed?</span>
                )}
                onConfirmation={() => onRemovePage(page)}
            />

            {page === -1 ? (
                <Row className="archk-col-header pl-4">
                    <Col lg={12} className="align-self-center">
                        <h3>Questions <small>- no page selected</small></h3>
                    </Col>
                </Row>
            ) : (
                <Row className="archk-col-header pl-4">
                    <Col lg={7} className="align-self-center">
                        <h3>Questions ({pageQuestions}) <small>- P{page + 1}</small></h3>
                    </Col>
                    <Col lg={5} className="align-self-center text-right pl-0">
                        <button onClick={() => setShowDeletePage(true)} className="btn btn-sm btn-outline-danger" style={{top: 0}}>
                            <i className="fas fa-trash mr-2 " /> Delete Page
                        </button>
                    </Col>
                </Row>
            )}

            <DndProvider backend={HTML5Backend}>
                <QuestionList 
                    allQuestions={questions}
                    nestedLevel="1"
                    parentTree={[]}
                    setMasterQuestions={setMasterQuestions}
                    onSelect={onSelect}
                    newQuestion={newQuestion}
                    onDragEnd={onDragEnd}
                    page={page}
                    onInjectQuestion={onInjectQuestion}
                    unsavedQuestion={unsavedQuestion}
                />
            </DndProvider>

        </Col>
      
    )
};

export default memo(ColFlow);