import Circle from './Circle';

const LoadingOverlay = () => (
    <div style={{zIndex: 999, position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, background: 'rgba(0,0,0,.5)'}}>
        <div style={{position: 'relative', top: 'calc(50% - 25px'}}>
        <Circle />
        </div>
    </div>

)

export default LoadingOverlay;