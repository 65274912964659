import { useCallback, useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { Row, Col } from "reactstrap";

import { Modal } from "reactstrap";
import { toast } from "react-toastify";

import api from 'api';

import Circle from 'components/markup/loading/Circle'
import ModalToggler from "components/functional/modals/Toggler";
import ObjectFinder from 'components/system/Objects/Finder';
import SearchCollections from 'components/system/Search/Collections';
import ReactSelect from 'components/functional/inputs/ReactSelect';
import TableIcons from 'components/markup/icons/TableIcons'

const ModalAddRule = ({showModal, toggleModal, selected_division, onAddRule, workflows, rule}) => {

    const [flowOpen, setFlowOpen] = useState(rule ? rule.flow_open :null)
    const [flowClosed, setFlowClosed] = useState(rule ? rule.flow_closed :null)
    const [workflow, setWorkflow] = useState(rule ? rule.workflow :null)
    const [category, setCategory] = useState(rule ? rule.workflow_step_category :null)

    const [err, setErr] = useState(null)
    const [stepCategories, setStepCategories] = useState(null)

    const onSave = useCallback(() => {
        if(!workflow || ! category || !flowOpen || !flowClosed) {
            return toast.info(`A workflow, category and both open and closed routes must be specified to add a rule`)
        }

        onAddRule({
            workflow                : workflow,
            workflow_step_category    : category,
            flow_open               : flowOpen,
            flow_closed             : flowClosed,
        })

        toggleModal()
    }, [workflow, category, flowOpen, flowClosed, onAddRule, toggleModal])

    const onSetWorkflow = useCallback(async (_workflow, keepCategory) => {
        setWorkflow(_workflow)
        setStepCategories(null)
        if(!keepCategory) setCategory(null)
        const _stepCategories = await api.workflow_step_categories.find(_workflow);
        if(!_stepCategories.data) return setErr("Please reload the page");

        setStepCategories(_stepCategories.data)
    }, [])

    useEffect(() => {
        if(showModal && rule) {
            setWorkflow(rule.workflow)
            setFlowOpen(rule.flow_open)
            setFlowClosed(rule.flow_closed)
            setCategory(rule.workflow_step_category)
        }
    }, [rule, showModal])

    return (

        <Modal
           className="modal-dialog-centered"
           isOpen={showModal}
           toggle={toggleModal}
           size="md"
        >

           <div className="modal-header">
               <h5 className="modal-title">Add A Category Rule</h5>
               <button
                   aria-label="Close"
                   className="close"
                   data-dismiss="modal"
                   type="button"
                   onClick={toggleModal}
                >
                   <span aria-hidden={true}>×</span>
               </button>
           </div>

           <div className="modal-body">
                {err ? "Something's not right, please refresh your page" : !workflows ? <Circle /> : (
                        <div>
                            <ReactSelect 
                                formGroup={true}
                                title="Select A Workflow"
                                value={workflow}
                                onChange={(obj) => onSetWorkflow(obj.value)}
                                options={workflows.map(w => {
                                    return { value: w._id, label: w.name }
                                })}
                            />

                            {workflow ? (
                                !stepCategories ? <Circle /> : (
                                    <div>
                                        <ReactSelect 
                                            formGroup={true}
                                            title="Select A Workflow Step Category"
                                            value={category}
                                            onChange={(obj) => setCategory(obj.value)}
                                            options={stepCategories.map(w => {
                                                return { value: w._id, label: w.name }
                                            })}
                                        />

                                        {category ? (
                                            <div>
                                                <SearchCollections
                                                    collection="call_flows" 
                                                    title="Redirect To Flow When OPEN"
                                                    value={flowOpen}
                                                    onChange={(obj) => setFlowOpen(obj.value)}
                                                    filter={{
                                                        division: selected_division._id
                                                    }}
                                                /> 
                                                <SearchCollections
                                                    collection="call_flows" 
                                                    title="Redirect To Flow When CLOSED"
                                                    value={flowClosed}
                                                    onChange={(obj) => setFlowClosed(obj.value)}
                                                    filter={{
                                                        division: selected_division._id
                                                    }}
                                                /> 
                                            </div>
                                    ) : ''}
                                    </div>
        
                                )
                            ) : ''}

                        </div>
                    )}
           </div>

           <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
                <button className="btn btn-success" onClick={onSave}>
                    Save Rule
                </button>
           </div>

        </Modal>

    )
}

const SystemCallFlowEdit = ({callFlow, onInputChange, selected_division, workflows}) => {

    // const [categories, setCategories] = useState(callFlow.category_routing)
    const categories = callFlow.category_routing

    const onAddRule = useCallback((rule) => {
        const _categories = JSON.parse(JSON.stringify(categories))
        const foundIndex = _categories.findIndex(i => i.workflow_step_category === rule.workflow_step_category)

        if(foundIndex !== -1) {
            _categories[foundIndex] = rule
        } else {
            _categories.push(rule)
        }
        onInputChange('category_routing' ,_categories)
    }, [categories, onInputChange])

    const onRemoveRule = useCallback((index) => {
        const _categories = JSON.parse(JSON.stringify(categories))
        _categories.splice(index, 1)
        onInputChange('category_routing', _categories);
    }, [categories, onInputChange])

    return (
        <div>

            <Row>
                <Col md={6} className="align-self-center">
                    <h2 className="mb-0">Category Routing</h2>
                </Col>
                <Col md={6} className="align-self-center text-right">
                    <ModalToggler component={ModalAddRule} onAddRule={onAddRule} selected_division={selected_division} workflows={workflows}>
                        <button className="btn btn-sm btn-info"><i className="fas fa-plus mr-2" /> Add Rule</button>
                    </ModalToggler>
                </Col>
            </Row>

            <div className="table-responsive mb-4">
                <table className="table border mt-3">
                    <thead>
                        <tr>
                            <th>Workflow</th>
                            <th>Disposition</th>
                            <th>Flow When Open</th>
                            <th>Flow When Closed</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {categories.length ? categories.map((d, i) => (
                            <tr>
                                <td>{d.workflow.name ? d.workflow.name : <ObjectFinder collection="workflows" _id={d.workflow} />}</td>
                                <td>{d.workflow_step_category.name ? d.workflow_step_category.name : <ObjectFinder collection="workflow_step_categories" _id={d.workflow_step_category} />}</td>
                                <td>{d.flow_open.name ? d.flow_open.name : <ObjectFinder collection="call_flows" _id={d.flow_open} />}</td>
                                <td>{d.flow_closed.name ? d.flow_closed.name : <ObjectFinder collection="call_flows" _id={d.flow_closed} />}</td>
                                <td className="text-right" >
                                    <TableIcons
                                        icons={[
                                            {  
                                                icon: 'fas fa-edit', 
                                                color: 'success',
                                                wrapper: ModalToggler, 
                                                component: ModalAddRule, 
                                                selected_division, 
                                                onAddRule, 
                                                workflows,
                                                rule: d
                                            },
                                            {  
                                                icon: 'fas fa-trash', 
                                                color: 'danger',
                                                onClick: () => onRemoveRule(i)
                                            },

                                        ]}
                                    />
                                </td>
                            </tr>
                        )) : (
                            <tr>
                                <td><i className="fas fa-info-circle mr-2 text-info" /> No rules have been created.</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

        </div>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SystemCallFlowEdit);