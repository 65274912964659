import EmailSenders from './EmailSenders'
import EmailTemplates from './EmailTemplates'
import TextTemplates from './TextTemplates'
import Automations from './Automations'
import AutomationAssignments from './AutomationAssignments'

import IntuideskSection from "components/markup/layout/IntuideskSection";

const AutomationsView = ({match}) => (
    <IntuideskSection 
        title="Automations"
        section="automations"
        defaultRoute="automations"
        match={match}
        pages={[
            { value: 'automations', icon: 'fas fa-code-branch', name: 'Creator Studio', Component: Automations },
            { value: 'assignments', icon: 'fas fa-route', name: 'Assignments', Component: AutomationAssignments },
            { value: 'email_senders', icon: 'fas fa-paper-plane', name: 'Senders', Component: EmailSenders },
            { value: 'email_templates', icon: 'fas fa-envelope', name: 'Emails', Component: EmailTemplates },
            { value: 'text_templates', icon: 'fas fa-mobile', name: 'Texts', Component: TextTemplates },
            // { value: 'settings', icon: 'fas fa-cogs', name: 'Settings'   },
        ]}
    />
)

export default AutomationsView;