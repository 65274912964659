import ContactTypesEdit from './components/Edit'
import ContactTypesAll from './All'

import IntuideskSectionWrapper from 'components/markup/layout/IntuideskSectionWrapper'

const SystemContactTypes = ({match, section}) => (
    <IntuideskSectionWrapper 
        title="Contact Types"
        editTitle="Contact Type"
        match={match}
        section={section}
        innerSection={'contact_types'}
        Edit={ContactTypesEdit}
        All={ContactTypesAll}
    />
)

export default SystemContactTypes;