import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Modal, FormGroup } from "reactstrap";

import ReactSelect from 'components/functional/inputs/ReactSelect';

const InsertSteps = ({showModal, toggleModal, onAddStep, stepOptions}) => {

    const [selectedStep, setSelectedStep] = useState(null);

    const onSelect = useCallback(() => {
        if(!selectedStep) return;
        onAddStep(selectedStep.value)
        toggleModal();
    }, [selectedStep, onAddStep, toggleModal])

    useEffect(() => {
        if(!showModal) setSelectedStep(null)
    }, [showModal])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >

            <div className="modal-header">
                <h5 className="modal-title">Add A Step</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">
                <FormGroup>
                    <label className="form-control-label">Select Step To Insert*</label>
                    <ReactSelect
                        placeholder="Search..."
                        onChange={(step) => setSelectedStep(step)}
                        options={stepOptions}
                    />
                </FormGroup>

            </div>

            <div className="modal-footer text-right">
                <button className="btn btn-success" onClick={onSelect}>
                    <i className="fas fa-save mr-2" />
                    Insert
                </button>
            </div>

        </Modal>

    )

}

InsertSteps.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}

export default InsertSteps
