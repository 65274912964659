import WorkflowsAll from './All'
import WorkflowsEdit from './components/Edit'

import IntuideskSectionWrapper from 'components/markup/layout/IntuideskSectionWrapper'

const SystemWorkflows = ({match, section}) => (
    <IntuideskSectionWrapper 
        title="Workflows"
        editTitle="Workflow"
        match={match}
        section={section}
        innerSection={'workflows'}
        Edit={WorkflowsEdit}
        All={WorkflowsAll}
    />
)

export default SystemWorkflows;