import StringTemplatesEdit from './components/Edit'
import StringTemplatesAll from './All'

import IntuideskSectionWrapper from 'components/markup/layout/IntuideskSectionWrapper'

const CompanyStringTemplates = ({match, section}) => (
    <IntuideskSectionWrapper 
        title="StringTemplates"
        editTitle="String Template"
        match={match}
        section={section}
        innerSection={'String Templates'}
        Edit={StringTemplatesEdit}
        All={StringTemplatesAll}
    />
)

export default CompanyStringTemplates;