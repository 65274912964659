/*
Documentation

This file holds all the socket events to listen for within this system
this should correspond exactly to /server/sockets/socketEvents.js in the server
code base of this project

*/

export const JOIN_COMPANY_ROOM    = 'JOIN_COMPANY_ROOM';
export const LEAVE_ROOM           = 'LEAVE_ROOM';

export const NOTIFICATION_CREATED = 'NOTIFICATION_CREATED';
export const NOTIFICATION_UPDATED = 'NOTIFICATION_UPDATED';

export const COMPANY_UPDATED      = 'COMPANY_UPDATED';

export const LOGOUT               = 'LOGOUT';