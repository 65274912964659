


import Admin from "./Admin";
import Developer from "./Developer";
import Functional from "./Functional";
import Security from "./Security";
import Standard from "./Standard";
import Views from "./Views";

import ViewAdminDashboard from "./ViewAdminDashboard";
import ViewAppDashboard from "./ViewAppDashboard";
import ViewManagementDashboard from "./ViewManagementDashboard";

const Advanced = ({ user, setUser, onPrivilegeChange }) => (
    <div>
        <hr className="mt-0"/>
        <ViewAppDashboard 
            user={user}
            setUser={setUser} 
            onPrivilegeChange={onPrivilegeChange}
        />
        <hr />
        <ViewManagementDashboard 
            user={user}
            setUser={setUser} 
            onPrivilegeChange={onPrivilegeChange}
        />
        <hr />
        <ViewAdminDashboard 
            user={user}
            setUser={setUser} 
            onPrivilegeChange={onPrivilegeChange}
        />
        <hr />
        <Standard 
            user={user}
            setUser={setUser} 
            onPrivilegeChange={onPrivilegeChange}
        />
        <hr />
        <Admin 
            user={user}
            setUser={setUser} 
            onPrivilegeChange={onPrivilegeChange}
        />
        <hr />
        <Functional 
            user={user}
            setUser={setUser} 
            onPrivilegeChange={onPrivilegeChange}
        />
        <hr />
        <Security 
            user={user}
            setUser={setUser} 
            onPrivilegeChange={onPrivilegeChange}
        />
        <hr />
    
        <Views 
            user={user}
            setUser={setUser} 
            onPrivilegeChange={onPrivilegeChange}
        />
        <hr />
    
        <Developer 
            user={user}
            setUser={setUser} 
            onPrivilegeChange={onPrivilegeChange}
        />
    </div>
)

export default Advanced