import { useCallback } from 'react';
import { connect } from 'react-redux';

import SearchCollections from 'components/system/Search/Collections';
import ObjectFinder from 'components/system/Objects/Finder';
import ReactSelect from 'components/functional/inputs/ReactSelect';
import PhoneInput from 'react-phone-input-2'

import { Badge, FormGroup } from 'reactstrap';

const SystemCallFlowEdit = ({task, onSetTask, selected_division}) => {

    const users_dial = task.users_dial;
    const no_answer_users_voicemail =  task.no_answer_users_voicemail;

    const onAddUser = useCallback((type, user) => {
        const u = type === 'users_dial' ? users_dial : no_answer_users_voicemail
        const _users = u ? JSON.parse(JSON.stringify(u)) : [];
        if(!_users.includes(user) ) _users.push(user)
        onSetTask(type, _users)
    }, [users_dial, no_answer_users_voicemail, onSetTask])
    
    const onRemoveUser = useCallback((type, user) => {
        const u = type === 'users_dial' ? users_dial : no_answer_users_voicemail
        let _users = u ? JSON.parse(JSON.stringify(u)) : [];
        _users = _users.filter(u => u !== user);
        onSetTask(type, _users)
    }, [users_dial, no_answer_users_voicemail, onSetTask])

    return (
        <div className='mb-4'>
                    
            <div className='bg-secondary p-3 border'>
                <p className='text-sm mb-0'><i className="fas fa-info-circle text-info mr-2" /> You have selected the Sequential Dial option. Here you can add a list of users below who will be called one at a time until someone picks up OR the call is routed out to do no one answering.</p>
            </div>

            <hr />

            <SearchCollections
                collection="users" 
                title={`Select User To Dial`}
                value={null}
                onChange={(obj) => onAddUser('users_dial', obj.value)}
                filter={{
                    division: selected_division._id
                }}
            /> 

            <p className='text-sm mb-0'>Selected Users</p>

            {users_dial && users_dial.length ? users_dial.map((u, i) => (
                <Badge key={u} color="success" className='text-sm'>
                    <ObjectFinder collection="users" _id={u} /> ({i + 1})
                    <i className="fas fa-times pl-3 cursor-pointer" onClick={() => onRemoveUser('users_dial', u)} />
                </Badge>
            )) : (
                <Badge color="warning">No Users Selected</Badge>
            )}

            <hr />

            <ReactSelect 
                formGroup={true}
                title="No Answer Routing"
                description={`Should the called parties not pickup what should happen to the call?`}
                value={task.no_answer_type}
                onChange={(obj) => onSetTask('no_answer_type', obj.value)}
                options={[
                    { value: 'voicemail_queue', label: 'Leave Voicemail - Queue' },
                    { value: 'voicemail_users', label: 'Leave Voicemail - Users' },
                    { value: 'outbound_dial', label: 'Dial Number ' },
                    { value: 'route_to_queue', label: 'Route To Queue ' },
                ]}
            />

            {task.no_answer_type === 'voicemail_users' ? (
                <div>
                     <SearchCollections
                        collection="users" 
                        title={`Select User(s) To Assign The Voicemail To`}
                        value={null}
                        onChange={(obj) => onAddUser('no_answer_users_voicemail', obj.value)}
                        filter={{
                            division: selected_division._id
                        }}
                    /> 

                    <p className='text-sm mb-0'>Selected Users</p>

                    {no_answer_users_voicemail && no_answer_users_voicemail.length ? no_answer_users_voicemail.map((u, i) => (
                        <Badge key={u} color="success" className='text-sm'>
                            <ObjectFinder collection="users" _id={u} />
                            <i className="fas fa-times pl-3 cursor-pointer" onClick={() => onRemoveUser('no_answer_users_voicemail', u)} />
                        </Badge>
                    )) : (
                        <Badge color="warning">No Users Selected</Badge>
                    )}

                </div>
            ) : task.no_answer_type === 'voicemail_queue' ? (
                <SearchCollections
                    collection="call_queues" 
                    title={`Select The Users This Voicemail Will Be Assigned To`}
                    value={task.no_answer_call_queue_voicemail}
                    onChange={(obj) => onSetTask(`no_answer_call_queue_voicemail`, obj.value)}
                    filter={{
                        division: selected_division._id
                    }}
                /> 
            ) : task.no_answer_type === 'outbound_dial' ? (
                <FormGroup>
                    <label className="form-control-label">Phone Number</label>

                    <PhoneInput
                        country={'us'}
                        value={task.no_answer_phone_number || ''}
                        onChange={phone => onSetTask('no_answer_phone_number', phone)}
                    />
                </FormGroup>    
            ) : task.no_answer_type === 'route_to_queue' ? (
                <SearchCollections
                    collection="call_queues" 
                    title={`Select Queue`}
                    value={task.no_answer_call_queue_dial}
                    onChange={(obj) => onSetTask(`no_answer_call_queue_dial`, obj.value)}
                    filter={{
                        division: selected_division._id
                    }}
                /> 
            ) : ''}

        </div>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SystemCallFlowEdit);