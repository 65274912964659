import * as actionTypes from '../../actions';

const initialState = []

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CONTACT_TYPES:
            return action.payload

        default: return state;

    }
}

export default reducer;
