// https://reactflow.dev/api-reference/types/node-props 

import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';

import ObjectFinder from 'components/system/Objects/Finder';


export default memo(({ id, selected, data, isConnectable }) => {
  return (
    <>
        <Handle
            id="left"
            type="target"
            position={Position.Left}
            style={{ top: 15 }}
            isConnectable={isConnectable}
        />

        <div className={`node ${selected ? 'node-selected' : ''}`}>
            <div className='node-header'>
            <i className="fa-solid fa-computer text-success mr-2" /> Run Assistant
                
            </div>
           
            <div className='node-body'>
                <div className='node-item text-capitalize'>
                    {data.ai_assistant ? (
                        <ObjectFinder _id={data.ai_assistant} collection="ai_assistants" />
                    ) : (
                        <span className="text-danger">Assistant Not Set</span>
                    )}
                </div>
            </div>

            <div className='node-footer'>
                <div><small>Execution ID:{id}</small></div>
            </div>

        </div>

        <Handle
            id="right"
            type="source"
            position={Position.Right}
            style={{ top: 10 }}
            isConnectable={isConnectable}
        />
        </>
  );
});