import { useState, useCallback } from 'react';

import { Row, Col, Input, FormGroup, Card, CardHeader, CardTitle, CardBody, CardFooter } from 'reactstrap';
import { connect } from 'react-redux'
import { toast } from 'react-toastify';

import SearchCollections from 'components/system/Search/Collections'
import ObjectFinder from 'components/system/Objects/Finder';


const SelectTag = ({ tagAnswers, onInputChange, selected_division }) => {

    const [option, setOption] = useState('');
    const [tag, setTag] = useState('');

    const onAddAnswer = useCallback(() => {
        const _tagAnswers = JSON.parse(JSON.stringify(tagAnswers));

        if(!option || !tag) return toast.info(`Each item pair must have a set option and tag associated`)

        _tagAnswers.push({ option, tag });
        onInputChange('tag_answers', _tagAnswers)

        setOption('')

    }, [onInputChange, option, tag, tagAnswers])

    const onRemoveAnswer = useCallback((index) => {
        let _tagAnswers = JSON.parse(JSON.stringify(tagAnswers));
        _tagAnswers.splice(index, 1);
        onInputChange('tag_answers', _tagAnswers)

    }, [onInputChange, tagAnswers])

    return (
        <div>

            <Card>
                <CardHeader>
                    <Row>
                        <Col md={6} className='align-self-center'>
                            <CardTitle className="mb-0">Add Answer Pair</CardTitle>
                        </Col>

                        <Col md={6} className='align-self-center text-right'>
                            <button className='btn btn-sm btn-success' onClick={onAddAnswer}>Add Answer</button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <label className='form-control-label'>Option Description</label>
                                <Input 
                                    type="text"
                                    value={option}
                                    onChange={e => setOption(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <SearchCollections
                                collection="tags"
                                title={`Select The Tag To Assign To This Answer`}
                                value={tag}
                                onChange={(obj) => setTag(obj.value)}
                                filter={{
                                    division: selected_division._id
                                }}
                            /> 
                           
                        </Col>
                    </Row>
                </CardBody>

                {tagAnswers.length ? (
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Option</th>
                            <th>Associated Tag</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        {tagAnswers.map((t, i) => (
                            <tr key={i}>
                                <td>{t.option}</td>
                                <td><ObjectFinder collection="tags" _id={t.tag} /></td>
                                <td className='text-right'> 
                                    <i className="fas fa-trash text-danger pl-3 cursor-pointer" onClick={() => onRemoveAnswer(i)} />     
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <CardFooter>
                    <p className='text-sm mb-0'>No Tag Options Have Been Created.</p>
                </CardFooter>
            )}
            </Card>

           

        </div>
    )
}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SelectTag);
