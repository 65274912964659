import IntuideskSection from "components/markup/layout/IntuideskSection";

import { Redirect } from 'react-router-dom';

import { useState, useEffect } from 'react'

import Dashboard from './Dashboard'
import Leads from './Leads'
import Communication from './Communication'
import Tasks from './Tasks'
import Events from './Events'
import Staff from './Staff'

import hasPrivilege from 'utils/hasPrivilege'

const OverviewView = ({match}) => {

    return (
        <IntuideskSection 
            title="Overview"
            section="overview"
            defaultRoute="dashboard"
            match={match}
            pages={[
                { value: 'dashboard', icon: 'fas fa-chart-line', name: 'Dashboard', Component: Dashboard },
                { value: 'leads', icon: 'fas fa-user-plus', name: 'Leads', Component: Leads },
                { value: 'communication', icon: 'fas fa-headset', name: 'Communication', Component: Communication },
                { value: 'tasks', icon: 'fas fa-list', name: 'Tasks', Component: Tasks },
                { value: 'events', icon: 'fas fa-calendar', name: 'Events', Component: Events },
                { value: 'staff', icon: 'fas fa-users', name: 'Staff', Component: Staff },
                // { value: 'charts', icon: 'fas fa-chart-pie', name: 'Charts', Component: Charts },
            ]}
        />
    )
}

export default OverviewView;