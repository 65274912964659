// import r from './r'

import store from 'store';
import keys from 'keys';

// SEVER, PROCESSING, TALK

import Axios from 'axios';

// r stands for request
const request = (domain, method, url, data) => {
    return new Promise (async resolve => {

        const domains = store.getState().load.DOMAINS;
        const baseDomain = domains ? domains[domain] : keys.API_URL;
    
        try {
            const result = await Axios({
                method,
                url: baseDomain + url,
                headers: {
                    authorization: `Bearer ${keys.SYSTEM_API_KEY}`
                },
                withCredentials: true,
                data,
            })

            resolve(result.data)

        } catch(e) {
            console.log(e)
            try {
                const err = JSON.parse(JSON.stringify(e.response.data))
                console.log(err)
                if(err && err.error === 'THREAT DETECTED') {
                    return window.location.href = '/errors/threat'
                }

                return resolve({success: false, message: err})
            } catch(e) {
                console.log(e)
                return resolve({success: false, message: e})
            }
        
        }

    })
}

export default request;