import { useCallback, useEffect, useState } from 'react';
import ReactSelect from "components/functional/inputs/ReactSelect"

import api from 'api';

const SearchWorkflowLocations = ({filter, onChange, value, title, formGroup, hideSelected}) => {

    const [contacts, setContacts] = useState([])

    const fetchData = useCallback(async () => {
        const data = await api.workflow_locations.find()
        if(data.data) {
            if(filter && filter.division) data.data = data.data.filter(d => d.division === filter.division);
            if(filter && filter.workflow) data.data = data.data.filter(d => d.workflow === filter.workflow);
            let options = data.data.map(d => {
                return { label: d.name, value: d._id }
            })
            setContacts(options)
        }
    }, [filter])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <ReactSelect 
            hideSelected={hideSelected}
            formGroup={formGroup || true}
            title={title || 'Search'}
            value={value}
            onChange={onChange}
            options={contacts}
        /> 
    )
}


// only rerender this when collection changes to prevent prop changes from firing hundreds of searches
const Wrapper = ({filter, onChange, value, title, formGroup, hideSelected, collection}) => {

    const [_collection, setCollection] = useState(collection);
    const [_filter, setFilter] = useState(filter);
    const [_title, setTitle] = useState(title);
    const [_formGroup, setFormGroup] = useState(formGroup);
    const [_hideSelected, setHideSelected] = useState(hideSelected);

    useEffect(() => {
        setCollection(collection)
        setFilter(filter)
        setTitle(title)
        setFormGroup(formGroup)
        setHideSelected(hideSelected)
        // eslint-disable-next-line
    }, [collection])

    return (
        <SearchWorkflowLocations 
            filter={_filter}
            title={_title}
            formGroup={_formGroup}
            hideSelected={_hideSelected}
            collection={_collection}
            onChange={onChange}
            value={value}
        />
    )

}

export default Wrapper;