import { useCallback } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Col, FormGroup, Row } from 'reactstrap';

import ReactSelect from 'components/functional/inputs/ReactSelect';
import SearchCollections from 'components/system/Search/Collections';

import { v4 } from 'uuid';

const WorkflowSidebarForms = ({stepToEdit, setStepToEdit, selected_division, contacts, errs}) => {

    const onAddForm = useCallback((field, value) => {
        const _stepToEdit = JSON.parse(JSON.stringify(stepToEdit));
        const docs = _stepToEdit.forms;
        docs.push({
            id                    : v4(),
            form                  : '' ,
            send_on_move          : false ,
            send_notifications    : false ,
            send_to_contact       : contacts[0] ? contacts[0]._id : null,
        })

        setStepToEdit(_stepToEdit);
    }, [stepToEdit, contacts, setStepToEdit])

    const onRemoveForm = useCallback((id) => {
        const _stepToEdit = JSON.parse(JSON.stringify(stepToEdit));
        let docs = _stepToEdit.forms;

        _stepToEdit.forms = docs.filter(t => {
            if(t.id) return t.id !== id
            return t._id !== id
        })
        setStepToEdit(_stepToEdit);
    }, [stepToEdit, setStepToEdit])

    const onChange = useCallback((id, field, value) => {
        const _stepToEdit = JSON.parse(JSON.stringify(stepToEdit));
        const step = _stepToEdit.forms.find(s => s._id === id || s.id === id)
        if(!step) return;
        step[field] = value;
        setStepToEdit(_stepToEdit);
    }, [stepToEdit, setStepToEdit])
    
    return (
        <>
            <Row className='mb-3'>
                <Col xs={6} className="align-self-center">
                    <h2 className='text-warning'>STEP FORMS</h2>
                </Col>
                <Col xs={6} className="align-self-center text-right">
                    <button className='btn btn-sm btn-info' onClick={onAddForm}><i className="fas fa-plus mr-2" /> Add New</button>
                </Col>
            </Row>
        
            {stepToEdit.forms.length ? stepToEdit.forms.map((doc, i) => {
                const id = doc.id ? doc.id : doc._id ;
                return (
                    <Card key={id} className="z-depth-0 ">
                        <CardBody className='label-block'>
                            <FormGroup className='mb-0'>
                                <label className='form-control-label'>
                                    <Row>
                                        <Col xs={6} className="align-self-center">
                                        Form #{i + 1}
                                        </Col>
                                        <Col xs={6} className="align-self-center text-right">
                                            <i onClick={() => onRemoveForm(id)} className="fas fa-trash mr-2 text-danger cursor-pointer pl-3" />
                                        </Col>
                                    </Row>
                                </label>
                            </FormGroup>
                            <SearchCollections
                                collection="forms" 
                                value={doc.form}
                                onChange={(obj) => onChange(id, 'form', obj.value)}
                                filter={{
                                    division: selected_division._id
                                }}
                            /> 

                            <ReactSelect 
                                formGroup={true}
                                title="Send Form On Move"
                                value={doc.send_on_move}
                                onChange={(obj) => onChange(id, 'send_on_move', obj.value)}
                                options={
                                    [
                                        { value: false, label: <span><i className="fas fa-times text-danger mr-2 " />No</span> },
                                        { value: true, label: <span><i className="fas fa-check text-success mr-2 " />Yes</span> },
                                    ]
                                }
                            />

                            {doc.send_on_move ? (
                                <div>

                                    <ReactSelect 
                                         formGroup={true}
                                         title="Which Contact Will This Form Be Assigned To?"
                                         value={doc.send_to_contact}
                                         onChange={(obj) => onChange(id, 'send_to_contact', obj.value)}
                                        options={contacts.map((role, i) => {
                                            return { value: role._id, label: role.name }
                                        })}
                                    />

                                    <ReactSelect 
                                        formGroup={true}
                                        title="Email / Text Notifications Upon Move"
                                        value={doc.send_notifications}
                                        onChange={(obj) => onChange(id, 'send_notifications', obj.value)}
                                        options={
                                            [
                                                { value: false, label: <span><i className="fas fa-times text-danger mr-2 " />No</span> },
                                                { value: true, label: <span><i className="fas fa-check text-success mr-2 " />Yes</span> },
                                            ]
                                        }
                                    />

                                </div>
                            ) : ''}
                            
                        
                        </CardBody>
                    </Card>
            )}) : (
                <p className='text-sm mb-0'><i className="fas fa-info-circle text-info" /> No Forms Have Been Added</p>
            )}


            {errs.includes('form') ? (
                <div className='alert alert-warning'>
                    <i className="fas fa-info-circle" /> Each template requires an associated form to be selected.
                </div>
            ) : ''}
          
        </>
    )
}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(WorkflowSidebarForms);