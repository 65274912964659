import { connect } from 'react-redux';

import SearchCollections from "components/system/Search/Collections";

const ActionMoveStep = ({ node, onInputChange, selected_division }) => {

    return (
        <div>
    
            <div className='sidebar-section border-bottom'>
                <SearchCollections
                    collection={'workflow_steps'}
                    title="**Select The Step To Jump To"
                    placeholder="Search..."
                    value={node.data.workflow_step}
                    onChange={(obj) => onInputChange('workflow_step', obj.value)}
                    filter={{
                        division: selected_division._id,
                    }}
                /> 
            </div>
    
            <div className='sidebar-section border-bottom'>
                <p className='text-sm'>
                    <i className="fas fa-info-circle text-info" /> This action automatically moves the step of a matter to the one selected above. 
                </p>
                <p className='text-sm mb-0'>
                    Note that this <b className='text-underline'>bypasses</b> all task and other forms of validation and will trigger al
                </p>
            </div>
    
        </div>
    )
}



const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(ActionMoveStep);