import moment from 'moment';
import PropTypes from 'prop-types'

import { Modal } from "reactstrap";

const TR = ({type, describing, field, body, config}) => {

    const isSelected = type === describing

    return (
        <tr className={isSelected ? 'bg-success': null}>
            <td className="pl-4"><b className={isSelected ? "pl-2 text-white" : 'pl-2'}>{field}</b></td>
            <td className={isSelected ? "text-white" : ''}>
                {isSelected ? <span className='text-uppercase'>(Currently Selected) - </span> : null}
                {body}{' '}
               
            </td>
        </tr>
    )

}

const ModalExplainTypes = ({showModal, toggleModal, type, config}) => (
    <Modal
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={toggleModal}
        size="lg"
    >

        <div className="modal-header">
            <h5 className="modal-title">Question Types Explained</h5>
            <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggleModal}
            >
                <span aria-hidden={true}>×</span>
            </button>
        </div>

        <div className='modal-body'>
            <p className='text-sm mb-0'>
            NOTE: All questions with a * in their name will update a field on a contact record when answered.
            </p>
        </div>

        {/* <div className="modal-body"> */}
            <div className="table-responsive">
                <table className="table mb-0">
                    <thead>
                        <tr>
                            <th className="pl-4" style={{width: 120}}><b className="pl-2">Type</b></th>
                            <th className="pl-4"><b className="pl-2">Explanation</b></th>
                        </tr>
                    </thead>
                    <tbody>
                        <TR 
                            type={type}
                            describing={'given-name'}
                            field={'First Name'}
                            body={(
                                <span>This question will be used to update a contacts first name inside their profile</span>
                            )}
                        />
                        <TR 
                            type={type}
                            describing={'family-name'}
                            field={'Last Name'}
                            body={(
                                <span>This question will be used to update a contacts first name inside their profile</span>
                            )}
                        />
                        <TR 
                            type={type}
                            describing={'email'}
                            field={'Primary Email Address'}
                            body={(
                                <span>This question will be used to update a contacts email address on file. Note that if an invalid email is given the question will be answered but the contacts profile will not be updated.</span>
                            )}
                        />
                        <TR 
                            type={type}
                            describing={'email-2'}
                            field={'Secondary Email Address'}
                            body={(
                                <span>This question will be used to update a contacts secondary email address on file. Note that if an invalid email is given the question will be answered but the contacts profile will not be updated.</span>
                            )}
                        />
                        <TR 
                            type={type}
                            describing={'phone'}
                            field={'Primary Phone Number'}
                            body={(
                                <span>This question will be used to update a contacts phone number on file. Note that if an invalid number is given the question will be answered but the contacts profile will not be updated.</span>
                            )}
                        />
                        <TR 
                            type={type}
                            describing={'phone-2'}
                            field={'Secondary Phone Number'}
                            body={(
                                <span>This question will be used to update a contacts secondary phone number on file. Note that if an invalid number is given the question will be answered but the contacts profile will not be updated.</span>
                            )}
                        />
                        <TR 
                            type={type}
                            describing={'social-security'}
                            field={'Social Security Number'}
                            body={(
                                <span>This question maps to a contacts social security number, it will show as redacted on the form after the page is closed.</span>
                            )}
                        />
                        <TR 
                            type={type}
                            describing={'address-contact'}
                            field={'Contact Address'}
                            body={(
                                <span>This question will pull all related address fields onto a contacts profile.</span>
                            )}
                        />
                        <TR 
                            type={type}
                            describing={'text'}
                            field={'Text'}
                            body={(
                                <span>Users will be able to answer this question by typing in a free form answer up to <b className="text-underline">{config.MAX_TEXT_LENGTH}</b> characters</span>
                            )}
                        />
                        <TR 
                            type={type}
                            describing={'textarea'}
                            field={'Large Text'}
                            body={(
                                <span>Users will be able to answer this question by typing in a free form answer up to <b className="text-underline">{config.MAX_TEXTAREA_LENGTH}</b> characters</span>
                            )}
                        />
                        <TR 
                            type={type}
                            describing={'number'}
                            field={'Number'}
                            body={(
                                <span>Users will be prompted to enter a number without any alphabetical or special characters</span>
                            )}
                        />
                        <TR 
                            type={type}
                            describing={'address-unassigned'}
                            field={'Unassigned Address'}
                            body={(
                                <span>Contacts will be prompted to enter an address however this will NOT overwrite the address associated with the contact.</span>
                            )}
                        />
                        <TR 
                            type={type}
                            describing={'phone-unassigned'}
                            field={'Unassigned Phone'}
                            body={(
                                <span>Contacts will be prompted to enter a valid phone number however this will NOT overwrite a phone number associated with the contact.</span>
                            )}
                        />
                        <TR 
                            type={type}
                            describing={'state'}
                            field={'State'}
                            body={(
                                <span>Contacts will be prompted to select from 1 of 50 US states</span>
                            )}
                        />
                        <TR 
                            type={type}
                            describing={'yes-no'}
                            field={'Yes or No'}
                            body={(
                                <span>Users will be able to answer this question by select either <b className="text-underline">"yes" or "no"</b></span>
                            )}
                        />
                        <TR 
                            type={type}
                            describing={'select'}
                            field={'Drop Down'}
                            body={(
                                <span>Users will be able to answer this question by selecting a <b className="text-underline">predetermined answer</b> that you create</span>
                            )}
                        />
                        <TR 
                            type={type}
                            describing={'checkbox'}
                            field={'Checkbox'}
                            body={(
                                <span>Users will be able to answer this question by selecting <b className="text-underline">one or more</b> check boxes pre-filled with answers you create</span>
                            )}
                        />
                        <TR 
                            type={type}
                            describing={'date'}
                            field={'Date'}
                            body={(
                                <span>Users answer this question by selecting a day such as <b className="text-underline">{moment().format('M/DD/YYYY')}</b></span>
                            )}
                        />
                     
                        <TR 
                            type={type}
                            describing={'loop'}
                            field={'Answer Loop'}
                            body={(
                                <span>Users must answer this question by <b className="text-underline">selecting a number 0 - {config.MAX_LOOP_SIZE}.</b> For the number they select, users will be given multiple loops of all branch questions. For instance, a loop question can read "Please enter the number of bank accounts you have". If a user selects "3" they will be given <b className="text-underline">three sets of branch questions</b> to answer</span>
                            )}
                        />
                      
                        <TR 
                            type={type}
                            describing={'signature'}
                            field={'Signature'}
                            body={(
                                <span>Users must <b className="text-underline">add their signature</b> to the form by either using their finger (mobile / tablet) or mouse (desktop)</span>
                            )}
                        />

                    </tbody>
                </table>
            </div>
        {/* </div> */}

        <div className="modal-footer text-right">
            <button className="btn btn-success" onClick={toggleModal}>
                Close
            </button>
        </div>

    </Modal>
)

ModalExplainTypes.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}

export default ModalExplainTypes
