import ReactSelect from 'components/functional/inputs/ReactSelect';

import { FormGroup } from 'reactstrap';

import getDoc from './utils/getDoc'
import formatDoc from './utils/formatDoc'

const ObjSearch = ({objs, onChange, value, label, description}) => (
    <FormGroup>
        {label ? <label className="form-control-label">{label}</label> : null}
        {description ? <p className="text-sm mb-0 mt--2">{description}</p> : null}
        <ReactSelect
            placeholder="Search..."
            onChange={onChange}
            options={objs.map(a => formatDoc(a))}
            value={getDoc(objs, value)}
        />
    </FormGroup>
)

export default ObjSearch