// tasks: {
//     type: [{
//         task_template           : { type: Schema.Types.ObjectId, ref: 'task_templates', required: true },
//         assigned_roles          : [{ type: Schema.Types.ObjectId, ref: 'workflow_roles', default: [] }],
//         complete_before_move    : { type: Boolean, required: true, default: false },
            // due,
//         create_tasks_on_finish: {
//             type: [{
//                 task_template   : { type: Schema.Types.ObjectId, ref: 'task_templates', required: true },
//                 assigned_roles  : { type: Array, default: [], required: true, },
//                 due             : { type: Number, default: 0, required: true, },
//             }],
//             required: true,
//             default: []
//         },
//     }],
//     default : []
// },


import { useCallback } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Col, FormGroup, Row, Badge } from 'reactstrap';

import ReactSelect from 'components/functional/inputs/ReactSelect';
import SearchCollections from 'components/system/Search/Collections';

import { v4 } from 'uuid';

const dueDates = [
    { value: '0', label: 'Never' },
    { value: 'immediately', label: 'Immediately' },
    { value: '1st_of_the_month', label: '1st Of The Month' },
    { value: '15th_of_the_month', label: '15th Of The Month' },
    { value: '15m', label: 'In 15 Minutes' },
    { value: '30m', label: 'In 30 Minutes' },
    { value: '45m', label: 'In 45 Minutes' },
    { value: '60m', label: 'In 1 Hour' },
    { value: '90m', label: 'In 1.5 Hours' },
    { value: '120m', label: 'In 2 Hours' },
    { value: '180m', label: 'In 3 Hours' },
    { value: '240m', label: 'In 4 Hours' },
    { value: '300m', label: 'In 5 Hours' },
    { value: '360m', label: 'In 6 Hours' },
    { value: '420m', label: 'In 7 Hours' },
    { value: '480m', label: 'In 8 Hours' },
    { value: '540m', label: 'In 9 Hours' },
    { value: '600m', label: 'In 10 Hours' },
    { value: '660m', label: 'In 11 Hours' },
    { value: '720m', label: 'In 12 Hours' },
    { value: '780m', label: 'In 13 Hours' },
    { value: '840m', label: 'In 14 Hours' },
    { value: '900m', label: 'In 15 Hours' },
    { value: '960m', label: 'In 16 Hours' },
    { value: '1020m', label: 'In 17 Hours' },
    { value: '1080m', label: 'In 18 Hours' },
    { value: '1140m', label: 'In 19 Hours' },
    { value: '1200m', label: 'In 20 Hours' },
    { value: '1260m', label: 'In 21 Hours' },
    { value: '1320m', label: 'In 22 Hours' },
    { value: '1380m', label: 'In 23 Hours' },
    { value: '1', label: 'In 1 day' },
    { value: '2', label: 'In 2 days' },
    { value: '3', label: 'In 3 days' },
    { value: '4', label: 'In 4 days' },
    { value: '5', label: 'In 5 days' },
    { value: '6', label: 'In 6 days' },
    { value: '7', label: 'In 7 days' },
    { value: '10', label: 'In 10 days' },
    { value: '14', label: 'In 14 days' },
    { value: '14', label: 'In 21 days' },
    { value: '28', label: 'In 28 days' },
    { value: '30', label: 'In 30 days' },
    { value: '45', label: 'In 45 days' },
    { value: '60', label: 'In 60 days' },
    { value: '75', label: 'In 75 days' },
    { value: '90', label: 'In 90 days' },
    { value: '120', label: 'In 120 days' },
    { value: '150', label: 'In 150 days' },
    { value: '180', label: 'In 180 days' },
    { value: '210', label: 'In 210 days' },
    { value: '240', label: 'In 240 days' },
    { value: '270', label: 'In 270 days' },
    { value: '300', label: 'In 300 days' },
    { value: '365', label: 'In 365 days' },
]

const WorkflowSidebarTasksOnCompletion = ({stepToEdit, setStepToEdit, workflowCopy, task, roles, contacts}) => {

    const onAddTask = useCallback(() => {
        const _stepToEdit = JSON.parse(JSON.stringify(stepToEdit));
        const docs = task.create_tasks_on_finish;
        docs.push({
            id                      : v4(),
            task_template           : '' ,
            due                     : 0,
            assigned_roles          : [] ,
            assigned_contacts       : [] ,
        })
        const index = stepToEdit.tasks.findIndex(t => t._id ? t._id === task._id : t.id === task.id)
        _stepToEdit.tasks[index].create_tasks_on_finish = docs

        setStepToEdit(_stepToEdit);
    }, [stepToEdit, setStepToEdit, task])

    const onRemoveTask = useCallback((id) => {
        const _stepToEdit = JSON.parse(JSON.stringify(stepToEdit));
        const index = stepToEdit.tasks.findIndex(t => t._id ? t._id === task._id : t.id === task.id)

        let docs = task.create_tasks_on_finish;

        _stepToEdit.tasks[index].create_tasks_on_finish = docs.filter(t => {
            const _id = t._id ? t._id : t.id
            return _id !== id
        })
        setStepToEdit(_stepToEdit);
    }, [stepToEdit, setStepToEdit, task])

    const onAddRole = useCallback((id, value) => {
        const _stepToEdit = JSON.parse(JSON.stringify(stepToEdit));
        const index = stepToEdit.tasks.findIndex(t => t._id ? t._id === task._id : t.id === task.id)
        if(index === -1) return;
        let t = _stepToEdit.tasks[index]
        const c = t.create_tasks_on_finish.find(c => c._id ? c._id === id : c.id === id)
        if(!c.assigned_roles.includes(value)) c.assigned_roles.push(value)
        setStepToEdit(_stepToEdit);
    }, [stepToEdit, setStepToEdit, task])
    
    const onRemoveRole = useCallback((id, value) => {
        const _stepToEdit = JSON.parse(JSON.stringify(stepToEdit));
        const index = stepToEdit.tasks.findIndex(t => t._id ? t._id === task._id : t.id === task.id)
        if(index === -1) return;
        const t = _stepToEdit.tasks[index]
        const c = t.create_tasks_on_finish.find(c => c._id ? c._id === id : c.id === id)
        c.assigned_roles = c.assigned_roles.filter(r => r !== value)
        setStepToEdit(_stepToEdit);
    }, [stepToEdit, setStepToEdit, task])
    
    const onAddContact = useCallback((id, value) => {
        const _stepToEdit = JSON.parse(JSON.stringify(stepToEdit));
        const index = stepToEdit.tasks.findIndex(t => t._id ? t._id === task._id : t.id === task.id)
        if(index === -1) return;
        let t = _stepToEdit.tasks[index]
        const c = t.create_tasks_on_finish.find(c => c._id ? c._id === id : c.id === id)
        // if(!c.assigned_contacts.includes(value)) c.assigned_contacts.push(value)
        c.assigned_contacts = [value]
        setStepToEdit(_stepToEdit);
    }, [stepToEdit, setStepToEdit, task])
    
    const onRemoveContact = useCallback((id, value) => {
        const _stepToEdit = JSON.parse(JSON.stringify(stepToEdit));
        const index = stepToEdit.tasks.findIndex(t => t._id ? t._id === task._id : t.id === task.id)
        if(index === -1) return;
        const t = _stepToEdit.tasks[index]
        const c = t.create_tasks_on_finish.find(c => c._id ? c._id === id : c.id === id)
        c.assigned_contacts = c.assigned_contacts.filter(r => r !== value)
        setStepToEdit(_stepToEdit);
    }, [stepToEdit, setStepToEdit, task])
    
    const onChange = useCallback((id, field, value) => {
        const _stepToEdit = JSON.parse(JSON.stringify(stepToEdit));
        const index = stepToEdit.tasks.findIndex(t => t._id ? t._id === task._id : t.id === task.id)
        if(index === -1) return;
        const t = _stepToEdit.tasks[index]
        const c = t.create_tasks_on_finish.find(c => c._id ? c._id === id : c.id === id)
        c[field] = value
        setStepToEdit(_stepToEdit);
    }, [stepToEdit, setStepToEdit, task])

    const renderRoleName = useCallback((_id) => {
        const role = roles.find(r => r._id === _id)  
        return role ? role.name : ''
    }, [roles])
    const renderContactName = useCallback((_id) => {
        const contact = contacts.find(r => r._id === _id)  
        return contact ? contact.name : ''
    }, [contacts])
    
    return (
        <>
           
            {task.create_tasks_on_finish.length ? (
                <div style={{position: 'relative'}} className="mt-4 bg-secondary p-4 rounded">

                    <span 
                        style={{
                            borderBottomLeftRadius: 10,
                            position: 'absolute',
                            top: 15,
                            left: 20,
                            height: 40,
                            width: 12,
                            borderBottom: 'solid 2px #8898aa', 
                            borderLeft: 'solid 2px #8898aa'
                        }}
                    >
                        <i 
                            style={{position: 'relative', top: 29.5, left: 10}} 
                            className="fas fa-arrow-right text-muted" 
                        />
                    </span>

                    <h2 className='ml-7 mb-0'>Tasks Created When Finished:</h2>

                    {task.create_tasks_on_finish.map((doc, i) => {
                    const id = doc.id ? doc.id : doc._id ;
                    return (
                        <Card key={id} className="z-depth-0 ml-7 mt-4 ">
                            <CardBody className='label-block'>
                                <FormGroup className='mb-0'>
                                    <label className='form-control-label'>
                                        <Row>
                                            <Col xs={9} className="align-self-center">
                                                Task #{i + 1}
                                            </Col>
                                            <Col xs={3} className="align-self-center text-right">
                                                <i onClick={() => onRemoveTask(id)} className="fas fa-trash mr-2 text-danger cursor-pointer pl-3" />
                                            </Col>
                                        </Row>
                                    </label>
                                </FormGroup>
                                <SearchCollections
                                    collection="task_templates" 
                                    value={doc.task_template}
                                    onChange={(obj) => onChange(id, 'task_template', obj.value)}
                                    filter={{
                                        workflow: workflowCopy._id
                                    }}
                                /> 

                                <ReactSelect 
                                    formGroup={true}
                                    title="Due"
                                    value={doc.due}
                                    onChange={(obj) => onChange(id, 'due', obj.value)}
                                    options={dueDates}
                                />

                                <ReactSelect 
                                    formGroup={true}
                                    title="Assigned To Role(s)"
                                    value={""}
                                    onChange={(obj) => onAddRole(id, obj.value)}
                                    options={roles.map((role, i) => {
                                        return { value: role._id, label: role.name }
                                    })}
                                />

                                {doc.assigned_roles.length ? doc.assigned_roles.map((role) => (
                                    <Badge key={role} color="success" className="ml-0 mb-2 text-left mr-3">
                                        {renderRoleName(role)}
                                        <span className="pr-2 pl-3 cursor-pointer float-right" onClick={() => onRemoveRole(id, role)}>
                                            <i className="fas fa-times " />
                                        </span>
                                    </Badge>
                                )) : null}
                              
                                <ReactSelect 
                                    formGroup={true}
                                    title="Text Recipient"
                                    value={""}
                                    onChange={(obj) => onAddContact(id, obj.value)}
                                    options={contacts.map((role, i) => {
                                        return { value: role._id, label: role.name }
                                    })}
                                />

                                {doc.assigned_contacts.length ? doc.assigned_contacts.map((role) => (
                                    <Badge key={role} color="success" className="ml-0 mb-2 text-left mr-3">
                                        {renderContactName(role)}
                                        <span className="pr-2 pl-3 cursor-pointer float-right" onClick={() => onRemoveContact(id, role)}>
                                            <i className="fas fa-times " />
                                        </span>
                                    </Badge>
                                )) : null}
                            
                            </CardBody>
                        </Card>
                    )})}
                </div>
            ) : null}

            <div className='text-right'>
                <button className='btn btn-info' onClick={onAddTask}>
                    <i className="fas fa-plus mr-2" /> Add Task When Completed
                </button>
            </div>

        </>
    )
}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(WorkflowSidebarTasksOnCompletion);