import { Input, FormGroup, Badge } from "reactstrap";
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import { useCallback, useState, useEffect } from 'react';

import api from 'api'

import Modalify from 'components/functional/modals/Modalify'
import Circle from 'components/markup/loading/Circle'

import { toggleStandardLoader } from 'store/functions/system/system';

import { toast } from 'react-toastify'

import ReactSelect from 'components/functional/inputs/ReactSelect'
import SearchCollectionsWorkflowContacts from 'components/system/Search/Collections/WorkflowContacts'
import SearchCollectionsWorkflowLocations from 'components/system/Search/Collections/WorkflowLocations'
import SearchCollectionsWorkflowRoles from 'components/system/Search/Collections/WorkflowRoles'

import SearchCollections from 'components/system/Search/Collections'

import SelectTag from './SelectTag'

// company               : { type: Schema.Types.ObjectId, ref: 'companies', required: true },
// division              : { type: Schema.Types.ObjectId, ref: 'divisions', required: true },
// time_entry_template   : { type: Schema.Types.ObjectId, ref: 'time_entry_templates', default: '' },
// custom_field          : { type: Schema.Types.ObjectId, ref: 'custom_fields',  default: null },
// text_template         : { type: Schema.Types.ObjectId, ref: 'text_templates',  default: null },
// email_template        : { type: Schema.Types.ObjectId, ref: 'email_templates',  default: null },
// event_template        : { type: Schema.Types.ObjectId, ref: 'event_templates',  default: null },
// tag                   : { type: Schema.Types.ObjectId, ref: 'tags',  default: null },
// form                  : { type: Schema.Types.ObjectId, ref: 'forms',  default: null },
// signing_template      : { type: Schema.Types.ObjectId, ref: 'signing_templates',  default: null },
// matter_document       : { type: Schema.Types.ObjectId, ref: 'matter_documents',  default: null },
// automations           : { type: [{ type: Schema.Types.ObjectId, ref: 'automations', }], default: [], required: true, },

// // custom_field, send_text, send_email, create_event, assign_tag, send_form, send_document_for_signature, upload, confirmation
// type                  : { type: String, required: true, },

// name                  : { type: String, required: true, },
// description           : { type: String, },
// priority           : { type: String, },

const baseState = {
    automations: [],
    priority: 5,
    // type: 'create_event'
}

const SystemTasksEdit = ({_id, onSaved, showModal, toggleModal, selected_division, workflow}) => {

    const [task, setTask] = useState(null)
    const [loading, setLoading] = useState(null)
    const [err, setErr] = useState(null)
    const [redirect, setRedirect] = useState(null)
    const [timeEntry, setTimeEntry] = useState(null)

    const fetchData = useCallback(async () => {
        if(!_id || _id === 'create') return setTask(baseState);
        const task = await api.task_templates.findById(_id)
        if(!task.data) return setErr(true);
        setTask(task.data)
        setTimeEntry(task.data.time_entry)
    }, [_id])

    const onInputChange = useCallback(( field, value) => {
        const newTask = JSON.parse(JSON.stringify(task))
        newTask[field] = value;
        setTask(newTask);
    }, [task])

    const onAddAutomations = useCallback((_id) => {
        const newTask = JSON.parse(JSON.stringify(task))
        if(!newTask.automations.includes(_id)) {
            newTask.automations.push(_id)
            setTask(newTask);
        }
    }, [task])
    const onRemoveAutomations = useCallback((_id) => {
        const newTask = JSON.parse(JSON.stringify(task))
        newTask.automations = newTask.automations.filter(a => a !== _id);
        setTask(newTask);
    }, [task])

    const onSave = useCallback(async () => {
        setErr(false)
        let newState = JSON.parse(JSON.stringify(task));

        if(Number.isNaN(newState.priority)) {
            return toast.error('Priority must be a number')
        } else if(newState.priority < 1) {
            return toast.error('Priority must a number between 1 and 10')
        } else if(newState.priority > 10) {
            return toast.error('Priority must a number between 1 and 10')
        }

        // if(!newState.subject || !newState.body) {
        //     return setErr([`A task must have a name and a body.`])
        // }
        newState.time_entry_template = timeEntry
        newState.priority = parseInt(newState.priority)
        
        let saved;

        // toggle loaders and update/create the task
        toggleStandardLoader(true)
        if(newState._id) {
            saved = await api.task_templates.update(newState._id, newState);
        } else {
            saved = await api.task_templates.create({...newState, division: selected_division._id, workflow: workflow._id});
        }
        toggleStandardLoader(false)

        // show error messages if unsuccessful
        if(saved.success) {
            toast.success("Task Saved Successfully")
            fetchData();
            setLoading(true)
            setTimeout(() => setLoading(false), 500)
            if(toggleModal) toggleModal();
            if(onSaved) onSaved(saved.data)
        } else {
            return setErr(saved.message);
        }
    
    }, [task, fetchData, toggleModal, onSaved, selected_division._id, workflow._id, timeEntry])

    useEffect(() => {
        if(toggleModal) {
            if(showModal) {
                fetchData()
            } else {
                setErr(null)
                setTask(baseState)
            }
        } else {
            fetchData()
        }
    }, [showModal, toggleModal, fetchData])

    useEffect(() => {
        if(!selected_division._id) {
            toast.info('A division must be created for editing a task.')
            setRedirect('/system/divisions/create')
        }
    }, [selected_division._id])

    if(redirect) return <Redirect to={redirect} />

    return (
        <Modalify 
            showModal={showModal}
            toggleModal={toggleModal}
            modalTitle={task && task._id ? "Update Task" : "Create Task"}
            err={err}
            footer={(
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            )}
            sections={!task ? [] : loading ? <Circle /> :  [
                (
                    <div>

                        <h2>Task Info</h2>

                        <FormGroup>
                            <label className="form-control-label">Name*</label>
                            <Input 
                                type="text"
                                value={task.name || ''}
                                onChange={e => onInputChange('name', e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label className="form-control-label">Description</label>
                            <Input 
                                type="textarea"
                                value={task.description || ''}
                                onChange={e => onInputChange('description', e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label className="form-control-label">Priority*</label>
                            <p className="text-sm mb-0">A number between 1 (highest priority) and 10 (lowest priority)</p>
                            <Input 
                                type="number"
                                value={task.priority || ''}
                                onChange={e => onInputChange('priority', e.target.value)}
                            />
                        </FormGroup>
                    </div>
                ),
                (
                    <div>

                        <h2>Task Type</h2>

                        <ReactSelect 
                            formGroup={true}
                            title="Select Type"
                            value={task.type}
                            onChange={(obj) => onInputChange('type', obj.value)}
                            options={[
                                // custom_field, send_text, send_email, create_event, assign_tag, send_form, send_document_for_signature, upload, confirmation  assign_contact, assign_location
                                { value: 'send_text', label: 'Send A Text' },
                                { value: 'send_email', label: 'Send An Email' },
                                { value: 'assign_location', label: 'Assign A Location' },
                                { value: 'assign_contact', label: 'Assign A Contact' },
                                { value: 'assign_role', label: 'Assign A Role' },
                                { value: 'create_event', label: 'Schedule An Event' },
                                { value: 'send_form', label: 'Send Out A Form' },
                                { value: 'send_document_for_signature', label: 'Send A Document For Signature' },
                                { value: 'upload', label: 'Upload A Document' },
                                { value: 'set_identifier', label: 'Set Matter Identifier' },
                                { value: 'assign_tag', label: 'Assign Tag If Answered "Yes"' },
                                { value: 'select_tag', label: 'Assign Tag Based On Preset Options' },
                                { value: 'confirmation', label: 'Confirm Something Is Done' },
                                { value: 'create_outstanding_item', label: 'Create An Outstanding Item' },
                                { value: 'custom_field', label: 'Custom Field' },

                            ]}
                        />

                        {task.type === 'send_text' ? (
                            <SearchCollections
                                collection="text_templates"
                                title={`Select A Text Template`}
                                value={task.text_template}
                                onChange={(obj) => onInputChange('text_template', obj.value)}
                                filter={{
                                    division: selected_division._id
                                }}
                            /> 
                        ) : task.type === 'send_email' ? (
                            <SearchCollections
                                collection="email_templates"
                                title={`Select An Email Template`}
                                value={task.email_template}
                                onChange={(obj) => onInputChange('email_template', obj.value)}
                                filter={{
                                    division: selected_division._id
                                }}
                            /> 
                        ) : task.type === 'assign_contact' ? (
                            <SearchCollectionsWorkflowContacts
                                title={`Select The Workflow Contact`}
                                value={task.workflow_contact}
                                onChange={(obj) => onInputChange('workflow_contact', obj.value)}
                                filter={{
                                    workflow: workflow._id
                                }}
                            /> 
                        ) : task.type === 'assign_role' ? (
                            <SearchCollectionsWorkflowRoles
                                title={`Select The Role To Assign`}
                                value={task.workflow_role}
                                onChange={(obj) => onInputChange('workflow_role', obj.value)}
                                filter={{
                                    workflow: workflow._id
                                }}
                            /> 
                        ) : task.type === 'assign_location' ? (
                            <SearchCollectionsWorkflowLocations
                                title={`Select The Workflow Location`}
                                value={task.workflow_location}
                                onChange={(obj) => onInputChange('workflow_location', obj.value)}
                                filter={{
                                    division: selected_division._id
                                }}
                            /> 
                        ) : task.type === 'create_event' ? (
                            <SearchCollections
                                collection="event_templates"
                                value={task.event_template}
                                onChange={(obj) => onInputChange('event_template', obj.value)}
                                filter={{
                                    division: selected_division._id
                                }}
                            /> 
                        ) : task.type === 'send_form' ? (
                            <SearchCollections
                                collection="forms"
                                title={`Select The Form To Send`}
                                value={task.form}
                                onChange={(obj) => onInputChange('form', obj.value)}
                                filter={{
                                    division: selected_division._id
                                }}
                            /> 
                        ) : task.type === 'send_document_for_signature' ? (
                            <SearchCollections
                                collection="signing_templates"
                                title={`Select The Document To Send For Signature`}
                                value={task.signing_template}
                                onChange={(obj) => onInputChange('signing_template', obj.value)}
                                filter={{
                                    division: selected_division._id
                                }}
                            /> 
                        ) : task.type === 'upload' ? (
                            <SearchCollections
                                collection="matter_documents"
                                title={`Select The Matter Document To Upload To`}
                                value={task.matter_document}
                                onChange={(obj) => onInputChange('matter_document', obj.value)}
                                filter={{
                                    division: selected_division._id
                                }}
                            /> 
                        ) : task.type === 'assign_tag' ? (
                            <SearchCollections
                                collection="tags"
                                title={`Select The Tag To Assign If the Answer Is Yes`}
                                value={task.tag}
                                onChange={(obj) => onInputChange('tag', obj.value)}
                                filter={{
                                    division: selected_division._id
                                }}
                            /> 
                        ) : task.type === 'select_tag' ? (
                                <SelectTag 
                                    onInputChange={onInputChange}
                                    tagAnswers={task.tag_answers || []}
                                />
                        ) : task.type === 'custom_field' ? (
                            <SearchCollections
                                collection="custom_fields"
                                title={`Select The Custom Field To Fill Out`}
                                value={task.custom_field}
                                onChange={(obj) => onInputChange('custom_field', obj.value)}
                                filter={{
                                    division: selected_division._id
                                }}
                            /> 
                        ) : ''}

                    </div>
                ),
                // (
                //     <div>

                //         <h2>Task Automations</h2>

                //         <SearchCollections
                //             collection="automations"
                //             title={`Select an automation to run upon task completion`}
                //             value={""}
                //             onChange={(obj) => onAddAutomations(obj.value)}
                //             filter={{
                //                 division: selected_division._id
                //             }}
                //         /> 

                //         {task.automations ? task.automations.map(t => (
                //             <Badge color="success">
                //                 <ObjectFinder collection="automations" _id={t} /> 
                //                 <i className="fas fa-times pl-3 cursor-pointer" onClick={() => onRemoveAutomations(t)} />
                //             </Badge>
                //         )) : (
                //             <Badge color="warning">No Automations Selected</Badge>
                //         )}

                //     </div>
                // ),
                // (
                //     <div>

                //         <h2>Time Tracking</h2>

                //         <SearchCollections
                //             collection="time_entry_templates"
                //             title={`Select an time entry to be automatically billed to the case when this task is finished`}
                //             value={timeEntry}
                //             onChange={(obj) => setTimeEntry(obj.value)}
                //             filter={{
                //                 division: selected_division._id
                //             }}
                //         /> 


                //     </div>
                // ),
            ]}
        />
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SystemTasksEdit);
