import { Modal } from "reactstrap";

const ValidationModal = ({showModal, toggleModal, errs}) => (
    <Modal
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={toggleModal}
        size="md"
    >

        <div className="modal-header">
            <h5 className="modal-title">Validation Error</h5>
            <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggleModal}
            >
                <span aria-hidden={true}>×</span>
            </button>
        </div>

        <div className="modal-body border-bottom bg-secondary">
            {errs.map((e, i) => (
                <p key={i} className="text-sm mb-0 text-warning font-weight-bold">{e}</p>
            ))}
        </div>

        <div className="modal-body border-top">
            <p className="text-sm mb-0">You must correct the above errors before saving this process.</p>
        </div>

        <div className="modal-footer">
            <button className="btn btn-outline-warning" onClick={toggleModal}>
                Close
            </button>
        </div>

    </Modal>
)

export default ValidationModal