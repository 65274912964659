const state = {

    question: {
        type: 'text',
        answers: [],
        questions: [],
        name: '',
        description: '',
        required: true,
        branch_on_answer: false,
        branch_on_answers: [],
        highlight_on_answer: false,
        highlight_on_answers: [],
        custom_error_message: false,
        custom_error_message_text: '',
        page: 0,
        redirect_url: '',

    },

    form: {
        name              : 'New Form',
        active            : true,
        send_emails_to    : [],
        pages             : 1,
        clear_on_submit   : false,
    }

}

export default state;