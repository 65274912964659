
import * as actionTypes from '../../actions';
import store from '../../index';
import api from 'api';

export const setConfig = async() => {
    return new Promise( async resolve => {

        const config = await api.load.config();

        if(config.success) {

            store.dispatch({ type: actionTypes.SET_CONFIG, payload: config.data });
            resolve({success: true, data: config.data});

        } else {

            resolve({success: false});

        }

    })

}
