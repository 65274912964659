import React, { useState } from 'react';

const ButtonDelete = ({onQuestionDeleted, disabled}) =>  {

    const [confirmed, setConfirmed] = useState(false);

    const onConfirm = () => {
        setConfirmed(true);
        setTimeout(() => {
            setConfirmed(false)
        }, 20000)
    }

    if(confirmed) return (
        <button className="btn btn-danger" style={{position: 'relative', top: 0}} onClick={() => {
            onQuestionDeleted();
            setConfirmed(false)
        }}>
            Are You Sure?
        </button>
    )

    return (
        <button disabled={disabled} className="btn btn-outline-danger" style={{position: 'relative', top: 0}} onClick={onConfirm}>
            <i className="fas fa-trash mr-2 " /> Delete Question
        </button>
    )
};

export default React.memo(ButtonDelete);

