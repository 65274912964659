
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Nav, Row, UncontrolledDropdown } from "reactstrap";

const AnalyticsPagesMattersNavbar = ({divisions, selected_division, company, onSetDivision, title, PLATFORM}) => {
   
    return (
        <div id="desktop-navbar-top" className="bg-dark z-depth-1">

            <Container  fluid>

                <div className="header mb-0">

                    <div className="header-body">
                        <Row className="align-items-center py-2">

                            <Col xs="4" >
                                <ul className="navbar-icons">
                                    <li>
                                        <h2 className="text-white dis mb-0">
                                            <img src={PLATFORM.FAVICON} style={{width: 20, position: 'relative', top: -2}} alt="Intuidesk" className="mr-3"/>
                                            {company.name}
                                        </h2>
                                    </li>
                                </ul>
                            </Col>

                            <Col xs="4" className='text-center text-uppercase'>
                                <h2 className="text-white dis mb-0"><i className="fas fa-chart-pie mr-2 text-success" /> {title}</h2>
                            </Col>

                            <Col xs="4"  className="text-right header-right">

                                <Nav className="align-items-center ml-auto ml-md-0 pl-5 "  navbar>
                                    <UncontrolledDropdown nav>
                                        <DropdownToggle className="nav-link text-white pr-0 cursor-pointer font-weight-bold" color="" tag="a">
                                            {selected_division.name ? selected_division.name : "NO DIVISION SELECTED"} <i className="fas fa-caret-down ml-2 px-0 " />
                                        </DropdownToggle>

                                        <DropdownMenu end>
                                            <div className="dropdown-content">
                                                <DropdownItem  className="noti-title" header tag="div">
                                                    <h6 className="text-overflow m-0 ">Selected Division</h6>
                                                </DropdownItem>

                                                {divisions.map(division => (
                                                    <DropdownItem key={division._id} onClick={() => onSetDivision(division)} >
                                                        {division.name}
                                                    </DropdownItem>
                                                ))}
                                            </div>
                                        </DropdownMenu>

                                    </UncontrolledDropdown>

                                    <UncontrolledDropdown nav>
                                        <DropdownToggle className="nav-link pr-0 cursor-pointer font-weight-bold" color="" tag="a">
                                            <i className="fas fa-cogs text-white" />
                                        </DropdownToggle>

                                        <DropdownMenu end>
                                            <div className="dropdown-content">
                                                <DropdownItem  className="noti-title" header tag="div">
                                                    <h6 className="text-overflow m-0">My Account</h6>
                                                </DropdownItem>

                                                <DropdownItem tag={Link} to="/settings" >
                                                    <i className="ni ni-single-02" />
                                                    <span>Settings</span>
                                                </DropdownItem>

                                                <DropdownItem tag={Link} to="/logout" >
                                                    <i className="ni ni-user-run" />
                                                    <span>Logout</span>
                                                </DropdownItem>
                                            </div>
                                        </DropdownMenu>

                                    </UncontrolledDropdown>

                                </Nav>
                            </Col>

                        </Row>
                    </div>

                </div>

            </Container>
        </div>

    )
}



const mapStateToProps = state => {
	return {
	    company: state.company,
	    divisions: state.divisions,
	    selected_division: state.state.selected_division,
	    PLATFORM: state.load.PLATFORM,
	};
};

export default connect(mapStateToProps, '')(AnalyticsPagesMattersNavbar);