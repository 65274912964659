import { useEffect } from 'react';
import { connect } from 'react-redux';

import SearchCollections from 'components/system/Search/Collections';

import { Badge } from 'reactstrap';

import ObjectFinder from 'components/system/Objects/Finder';

const WorkflowSidebarDisposition = ({ stepToEdit, onAddArrayField, onRemoveArrayField, selected_division }) => {


    return (
        <div>
    
            <h2 className='text-warning'>Background Processes</h2>
    
            <SearchCollections
                collection="background_processes" 
                title="Add Process"
                value={null}
                onChange={(obj) => onAddArrayField('background_processes', obj.value)}
                filter={{
                    division: selected_division._id
                }}
            /> 

            {stepToEdit.background_processes.length ? stepToEdit.background_processes.map((automation, i) => (
                <Badge key={automation} color="success" className="ml-0 mb-2 text-left mr-3">
                    <ObjectFinder collection="background_processes" _id={automation} />
                    <span className="pr-2 pl-3 cursor-pointer float-right" onClick={() => onRemoveArrayField('background_processes', automation)}>
                        <i className="fas fa-times " />
                    </span>
                </Badge>
            )) : null}

            <hr className='my-2'/>

            <p className='text-sm'><i className="fas text-info fa-info-circle mr-2" /> All processes added above will be queued to run when their trigger is met for any matters on this step.</p>
        

        </div>
    )
}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(WorkflowSidebarDisposition);