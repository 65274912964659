import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input } from 'reactstrap';

import WorkflowRolesSelection from 'components/system/WorkflowRolesSelection';
import SearchCollections from "components/system/Search/Collections";

const SidebarRightInternalNotification = ({ selectedNode, onNodeUpdated, selected_division }) => {

    const [node, setNode] = useState(selectedNode)

    const onInputChange = useCallback((field, value) => {
        const n = JSON.parse(JSON.stringify(node));
        n.data[field] = value;
        setNode(n)
    }, [node])

    useEffect(() => {
        setNode(JSON.parse(JSON.stringify(selectedNode)))
    }, [selectedNode])
   
    return (
        <div>

            <div className='sidebar-section border-bottom'>
                <div className="sidebar-item pb-0"><h2>Internal Notification</h2></div>
            </div>
    
            <div className='sidebar-section border-bottom'>

                <FormGroup>
                    <label className='form-control-label'>**Notification Subject</label>
                    <Input
                        type="text"
                        value={node.data.subject}
                        onChange={e => onInputChange('subject', e.target.value)}
                    />
                </FormGroup>

                <FormGroup>
                    <label className='form-control-label'>**Notification Body</label>
                    <Input
                        type="text"
                        value={node.data.body}
                        onChange={e => onInputChange('body', e.target.value)}
                    />
                </FormGroup>

            </div>
            <div className='sidebar-section border-bottom'>

                <SearchCollections
                    collection={'users'}
                    title="If you wish a specific user to always receive a notification when this fires select them below"
                    placeholder="Search..."
                    value={node.data.user}
                    onChange={(obj) => onInputChange('user', obj.value)}
                    filter={{
                        division: selected_division._id,
                    }}
                /> 

            </div>

            <div className='sidebar-section border-bottom'>
                <h2>Roles To Notify</h2>
                <WorkflowRolesSelection 
                    roles={node.data.workflow_roles} 
                    onChange={(workflow_roles) => onInputChange('workflow_roles', workflow_roles)} 
                    multiSelect={true}
                />
            </div>

            <div className='sidebar-section text-right'>
                <button onClick={() => onNodeUpdated(node)} className='btn btn-success'>Save Node</button>
            </div>


        </div>
    )

}



const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SidebarRightInternalNotification);