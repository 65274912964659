import React, { useState } from 'react';
import { FormGroup, Input, Badge } from 'reactstrap';

const InputAnswers = ({answers, onChange, errs, config}) =>  {

    const [answerText, setAnswerText] = useState('');

    // on enter try to add an answer
    const _handleKeyDown = (e) => {
		if (e.key === 'Enter') onAddAnswer()
	}

    // adds an answer
    const onAddAnswer = () => {
        // validate answer is not blank and does not already exist
        if(!answerText) return;
        if(answers.includes(answerText)) return;

        // add the answer and fire the onChange event
        const newAnswers = [...answers];
        newAnswers.push(answerText);

        if(newAnswers.length > config.MAX_QUESTION_ANSWERS) return;

        setAnswerText('')
        onChange(newAnswers)
    }

    // remove an answer from state and fire the onChange vent
    const onRemoveAnswer = (answer) => {
        let newAnswers = [...answers];
        newAnswers = newAnswers.filter(a => a !== answer);

        onChange(newAnswers)
    }

    const hasCheckboxErr = errs.includes('answersCheckbox') && (answers.length < 2) ? true : false;
    const hasSelectErr = errs.includes('answersSelect') && (answers.length < 2) ? true : false;
    const hasErr = hasCheckboxErr ? true : hasSelectErr ? true : false

    return (
        <div className="">
             <FormGroup>
                <label className="form-control-label">
                    Question Answers{' '}
                    {answers.length >= config.MAX_QUESTION_ANSWERS ? (
                        `(A question can have up to ${config.MAX_QUESTION_ANSWERS} possible answers.)`
                    ) : '(Press Enter To Add)'}
                </label>
                {hasCheckboxErr ? (
                    <p className="text-sm text-danger mb-0">You must include at least 2 answers for this question.</p>
                ) : hasSelectErr ? (
                    <p className="text-sm text-danger mb-0">You must include at least 2 answers for this question.</p>
                ) : null}
                <Input 
                    onKeyDown={_handleKeyDown}
                    type="text"
                    value={answerText}
                    onChange={(e) => setAnswerText(e.target.value && e.target.value.length > config.MAX_SELECT_ANSWER_LENGTH ? e.target.value.slice(0, config.MAX_SELECT_ANSWER_LENGTH) : e.target.value)}
                    invalid={hasErr}
                    valid={!hasErr}
                />
            </FormGroup>

            {answers.length ? (
                <div className="possible-options">
                    {answers.map((a, i) => (
                        <Badge color="success" className="mb-3 mr-0 ml-3" style={{whiteSpace: 'pre-line'}} key={i}>
                            {a}<i onClick={() => onRemoveAnswer(a)} className="cursor-pointer fas fa-times mx-3 " />
                        </Badge>
                    ))}
                </div>
            ) : (
                <div className="possible-options">
                    <Badge color="warning" className="mb-0 mr-0 ml-3">No Answers Created</Badge>
                </div>
                // <p className="text-sm text-danger font-weight-bold mb-0">At least 2 answers must be given for this question type.</p>
            )}

        </div>
    )
};

export default React.memo(InputAnswers);

