import WorkflowRolesSelection from 'components/system/WorkflowRolesSelection';

const AutomationsEditEmailWorkflowCCRoles = ({ccRoles, setCCRoles}) => {
    return (
        <div>

            <h2>CC Roles</h2>
            
            <WorkflowRolesSelection 
                roles={ccRoles} 
                onChange={(workflow_roles) => setCCRoles(workflow_roles)} 
                multiSelect={true}
            />
        </div>
    )

}

export default AutomationsEditEmailWorkflowCCRoles