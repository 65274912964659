import { Input, FormGroup } from "reactstrap";
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import { useCallback, useState, useEffect } from 'react';

import api from 'api'

import Modalify from 'components/functional/modals/Modalify'

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

const SystemAiAssistantsEdit = ({_id, onSaved, showModal, toggleModal, selected_division}) => {

    const [assistant, setAssistant] = useState(null)
    const [err, setErr] = useState(null)
    const [redirect, setRedirect] = useState(null)

    const fetchData = useCallback(async () => {
        if(!_id || _id === 'create') return setAssistant({});
        const assistant = await api.ai_assistants.findById(_id)
        if(!assistant.data) return setErr(true);
        setAssistant(assistant.data)
    }, [_id])

    const onInputChange = useCallback(( field, value) => {
        const newState = JSON.parse(JSON.stringify(assistant))
        newState[field] = value;
        setAssistant(newState);
    }, [assistant])

    const onSave = useCallback(async () => {
        setErr(false)
        let newState = JSON.parse(JSON.stringify(assistant));

        if(!newState.name) return setErr([`An assistant must have a name.`])
        if(!newState.assistant_id) return setErr([`An assistant must have an open ai assistant id attached.`])
        
        setAssistant(newState)

        let saved;

        // toggle loaders and update/create the assistant
        toggleStandardLoader(true)
        if(newState._id) {
            saved = await api.ai_assistants.update(newState._id, newState, true);
        } else {
            saved = await api.ai_assistants.create({...newState, division: selected_division._id}, true);
        }
        toggleStandardLoader(false)

        // show error messages if unsuccessful
        if(saved.success) {
            toggleAlertBS(false, "Assistant Saved Successfully")
            fetchData();
            if(toggleModal) toggleModal();
            if(onSaved) onSaved(saved.data)
        } else {
            return setErr(saved.message);
        }
    
    }, [assistant, fetchData, toggleModal, onSaved, selected_division._id])

    useEffect(() => {
        if(toggleModal) {
            if(showModal) {
                fetchData()
            } else {
                setErr(null)
                setAssistant('')
            }
        } else {
            fetchData()
        }
    }, [showModal, toggleModal, fetchData])

    useEffect(() => {
        if(!selected_division._id) {
            toggleAlertBS('info', 'A division must be created for editing a assistant.')
            setRedirect('/system/divisions/create')
        }
    }, [selected_division._id])

    if(redirect) return <Redirect to={redirect} />

    return (
        <Modalify 
            size="sm"
            showModal={showModal}
            toggleModal={toggleModal}
            modalTitle={assistant && assistant._id ? "Update assistant" : "Create assistant"}
            err={err}
            footer={(
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            )}
            sections={!assistant ? [] :  [
                (
                    <div>

                        <FormGroup>
                            <label className="form-control-label">Name*</label>
                            <Input 
                                type="text"
                                value={assistant.name || ''}
                                onChange={e => onInputChange('name', e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label className="form-control-label">Open AI Assistant ID*</label>
                            <Input 
                                type="text"
                                value={assistant.assistant_id || ''}
                                onChange={e => onInputChange('assistant_id', e.target.value)}
                            />
                        </FormGroup>
                    </div>
                ),
            ]}
        />
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SystemAiAssistantsEdit);