import { useCallback, useState } from "react";
import { connect } from 'react-redux';
import { Badge, Card } from "reactstrap";

import api from 'api';

import Table from 'components/functional/tables/Standard';
import TableIcons from 'components/markup/icons/TableIcons';
import ApiError from "components/markup/layout/ApiError";
import ObjectFinder from 'components/system/Objects/Finder';

import TextButtonRow from 'components/markup/layout/TextButtonRow';
import A from 'components/markup/links/A';
import Circle from 'components/markup/loading/Circle';

import moment from 'moment';

const LocationsAll = ({selected_division, DOMAINS}) => {

    const [ loading, setLoading ] = useState(false);
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ error, setError ] = useState(false);


    // helper for this.downloadCSV and this.query
    const fetchData = useCallback((params, isCSV, shouldReload) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(shouldReload) setLoading(true)

        let filter = { division: selected_division._id || null }

        const query = await api.automation_assignments.search({ ...params, filter, isCSV })
        if(shouldReload) setLoading(false)

        if(!query.data) return setError(query.message)
        return resolve(query.data)
    }), [sizePerPage, selected_division._id])

    // run query to get data from the server
    const query = useCallback(async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }, [fetchData])

    const columns = [
        {
            dataField: "given_name",
            text: 'Matter / Last Run',
            formatter: (cell, row) => row.name === 'no data' ? (
                <div style={{minHeight: 150}}>No Data To Show</div> 
            ) : (
                <A href={`${DOMAINS.APP}/matters/${row.matter}?tab=Automations`}>
                    <ObjectFinder collection="matters" _id={row.matter} />
                    <div>{moment.unix(row.last_run_date).format('MM/DD/YYYY h:mm A')}</div>
                </A>

            )
        },
        {
            dataField: "history",
            text: 'Last Action',
            formatter: (cell, row) => {
                if(row.name === 'no data') return '';

                const history = row.history[row.history.length - 1];
                if(!history) return '-'

                return (
                    <div>
                        {history.result.length > 30 ? history.result.slice(0,30) + '...' : history.result}
                    </div>
                )
            }
        },
        {
            dataField: "automation",
            text: 'Automation',
            formatter: (cell, row) => row.name === 'no data' ? '' : (
                <div>
                    <ObjectFinder collection="automations" _id={row.automation} />
                </div>
            )
        },
        {
            dataField: "started_by",
            text: 'Started By',
            formatter: (cell, row) => row.name === 'no data' ? '' : (
                <div>
                    {row.created_by}
                </div>
            )
        },
        
        {
            dataField: "finished",
            text: 'Active',
            formatter: (cell, row) => row.name === 'no data' ? '' : (
                <div>
                    {row.finished ? <Badge color="danger">No</Badge> : <Badge color="success">Yes</Badge>}
                </div>
            )
        },
        {
            dataField: "next_run_date",
            text: 'Next Run Date',
            formatter: (cell, row) => row.name === 'no data' ? '' : (
                <div>
                    {row.finished ? '-' : moment.unix(row.next_run_date).format('MM/DD/YYYY h:mm A')}
                </div>
            )
        },
        
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right',},
            text: 'Actions',
            headerFormatter: () => {
                return <div id="archk-tutorial-contacts-actions">Actions</div>
            },
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                         <TableIcons
                            icons={[
                                {
                                    icon: 'fas fa-edit',
                                    color: 'success', 
                                    wrapper: A, 
                                    href: `${DOMAINS.APP}/matters/${row.matter}?tab=Automations`,
                                },
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(error) return <ApiError err={error} />
    if(loading) return <div><Circle /></div>

    return (
        <div>
            <TextButtonRow 
                title={
                    <span>Assigned Automations</span>
                }

            />

            <Card className="card-color card-primary table-fixed">
                <Table
                    tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                    placeholder="Search..."
                    query={query}
                    columns={columns}
                    data={tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}
                    sizePerPage={sizePerPage}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>
        </div>
    )

}

const styles = {
    badge: {
        width: 100  
    }
}

const mapStateToProps = state => {
	return {
        DOMAINS: state.load.DOMAINS,
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(LocationsAll);