import { useCallback, useEffect, useState } from "react";
import { Card, CardHeader, Input } from "reactstrap";
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router-dom'

import moment from "moment";

import api from 'api';

import TextButtonRow from 'components/markup/layout/TextButtonRow'
import ConfirmationModal from 'components/functional/modals/Confirmation'
import TableIcons from 'components/markup/icons/TableIcons'
import ModalToggler from 'components/functional/modals/Toggler'
import LoadingTable from 'components/markup/loading/Table';
import ApiError from "components/markup/layout/ApiError";
import { CopyToClipboard } from 'react-copy-to-clipboard';


import { toggleAlertBS } from "store/functions/system/system";

import StringTemplatesEdit from '../components/Edit'

const CopyId = ({id}) => {

    const [copied, setCopied] = useState(false);

    const onCopy = () => {
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, [1000])
    }

    return (
        <CopyToClipboard 
            text={`[[string_template.${id}]]`}
            onCopy={onCopy}
        >
            <span className={copied ? 'text-success' : ' cursor-pointer'}>{id} <i className="fas fa-copy ml-2 text-success"></i></span> 
    </CopyToClipboard>
    )
}


const CompanyStringTemplatesAll = ({setTab}) => {
    const [ tableData, setTableData ] = useState(null)
    const [ stringTemplateToDelete, setTemplateToDelete ] = useState(false)
    const [ error, setError ] = useState(false);
    const [ search, setSearch ] = useState('');
    const [ redirect, setRedirect ] = useState(null);

    // helper for this.downloadCSV and this.query
    const fetchData = useCallback((params, isCSV) => new Promise (async resolve => {
        const query = await api.string_templates.find()
        
        if(query.data) {
            return setTableData(query.data)
        } else {
            setError(query.message)
        }
    }), [])

    const onDeleteTemplate = useCallback(async () => {
        const deleted = await api.string_templates.delete(stringTemplateToDelete._id);
        if(deleted.success) {
            fetchData();      
        } else {
            toggleAlertBS(true, `Something's not right, please try again.`)
        }
    }, [fetchData, stringTemplateToDelete])

    const shouldRender = useCallback((stringTemplate) => {
        if(!search) return true;

        const s = search.toLowerCase().trim();
        const n = stringTemplate.name.toLowerCase().trim();
        if(n.includes(s)) return true;
        if(n === s) return true;

        return false;
    }, [search])

    useEffect(() => {
        fetchData()
    }, [fetchData])
 
    if(error) return <ApiError err={error} />
    if(redirect) return <Redirect to={redirect} />

    const results = search && tableData ? tableData.filter(stringTemplate => shouldRender(stringTemplate)) : tableData

    return (

        <>

            <TextButtonRow 
                title={"All String Templates"}
                button={(
                    <button onClick={() => setTab('create')} className="btn btn-success"><i className="fas fa-plus mr-2 " /> Add Template</button>
                )}
            />

            <Card className="card-color card-primary table-fixed">
                <CardHeader className="pl-2 pr-4 pb pt-3 pb-3 ml-1">
                    <Input 
                        className="ml-1"
                        style={{width: 190, maxWidth: '100'}}
                        placeholder="Search..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </CardHeader>
                {!results ?  <LoadingTable /> : (
                    <div className="table-responsive ">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>String Template</th>
                                    <th className="text-right">ID</th>
                                    <th className="text-right">Value</th>
                                    <th className="text-right"></th>
                                </tr>
                            </thead>

                            <tbody>
                                {results.length ? (
                                    results.map(stringTemplate => (
                                        <tr key={stringTemplate._id}>
                                            <td>
                                                <ModalToggler component={StringTemplatesEdit} onSaved={fetchData} _id={stringTemplate._id}>
                                                    <span className="cursor-pointer">{stringTemplate.name}</span>
                                                </ModalToggler>
                                            </td>

                                            <td className="text-right">
                                                <CopyId id={stringTemplate.id} />
                                            </td>

                                            <td className="text-right">
                                                <div>{stringTemplate.value}</div>
                                            </td>

                                            <td className="text-right">
                                                <TableIcons
                                                    icons={[
                                                        { 
                                                            icon: 'fas fa-edit',
                                                            color: 'success', 
                                                            wrapper: ModalToggler, 
                                                            component: StringTemplatesEdit,
                                                            onSaved: fetchData,
                                                            _id: stringTemplate._id
                                                        },
                                                        {  
                                                            icon: 'fas fa-trash', 
                                                            color: 'danger',
                                                            onClick: () => setTemplateToDelete(stringTemplate)
                                                        },
                                                    ]}
                                                />

                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td>No String Templates Found</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}

                            </tbody>
                        </table>
                    </div>
                )}

            </Card>

            <ConfirmationModal 
                showModal={stringTemplateToDelete ? true : false}
                toggleModal={() => setTemplateToDelete(null)}
                title="Delete String Template"
                body={(
                    <span>
                        Are you sure you wish to delete this String Template {stringTemplateToDelete ? stringTemplateToDelete.name : ''}? Make sure you check all texts and emails to remove this template first.
                    </span>
                )}
                onConfirmation={onDeleteTemplate}
            />

        </>
    )

}

export default CompanyStringTemplatesAll