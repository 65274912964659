import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Nav, NavItem, NavLink } from "reactstrap";

import { toggleStandardLoader } from 'store/functions/system/system';
import { toast } from 'react-toastify'

import Force from './Force';
import Hours from './Hours';
import Info from './Info';
import Routing from './Routing';
import Tasks from './Tasks';

import ApiError from 'components/markup/layout/ApiError'
import api from 'api';

import Associations from 'components/system/Associations'


const baseState = {

    name                  : '',
    // audit_percent         : 2,

    record                : true,
    force_closed          : false,
    force_redirect        : '',

    timezone              : 'America/Chicago',
    disposition_routing   : [],
    category_routing      : [],
    custom_field_routing  : [],
    
    gather_intent         : false,
    gather_intent_clip    : null,

    flow_closed           : undefined,
    flow_unknown          : undefined,
    unknown_consideration : 0,

    tasks: [],

    times_open                  : {
        mon: { start: 32400, end: 61200 },
        tue: { start: 32400, end: 61200 },
        wed: { start: 32400, end: 61200 },
        thu: { start: 32400, end: 61200 },
        fri: { start: 32400, end: 61200 },
        sat: { start: 0, end: 0 },
        sun: { start: 0, end: 0 },
    },

}

const SystemCallFlowEdit = ({_id, onSaved, showModal, toggleModal, selected_division, company}) => {

    const [tab, setTab] = useState('info')
    const [callFlow, setCallFlow] = useState(null)
    const [workflows, selectWorkflows] = useState(null)

    const [err, setErr] = useState(null)


    const fetchData = useCallback(async () => {
        if(!_id || _id === 'create') return setCallFlow({ ...baseState, timezone: company.timezone });
        const data = await api.call_flows.findById(_id)
        if(!data.data) return setErr(data.message);
        setCallFlow(data.data)
    }, [_id, company.timezone])
  
    const onInputChange = useCallback(( field, value) => {
        const newCallFlow = JSON.parse(JSON.stringify(callFlow))
        newCallFlow[field] = value;
        setCallFlow(newCallFlow);
    }, [callFlow])
  
    const onTasksChange = useCallback(( value) => {
        const newCallFlow = JSON.parse(JSON.stringify(callFlow))
        newCallFlow.tasks[0] = value;
        setCallFlow(newCallFlow);
    }, [callFlow])

    const onSave = useCallback(async () => {

        setErr(false)
        let newState = JSON.parse(JSON.stringify(callFlow));

        if(!newState.name) {
            return setErr([`A call flow must have a name.`])
        }
        if(newState.hotline_time && !newState.hotline_action) {
            return setErr([`A call flow must have a number to dial if "Time In Hotline" is set`])
        }
        
        let saved;

        // toggle loaders and update/create the user
        toggleStandardLoader(true)
        if(newState._id) {
            saved = await api.call_flows.update(newState._id, newState, true);
        } else {
            saved = await api.call_flows.create({...newState, division: selected_division._id}, true);
        }
        toggleStandardLoader(false)

        // show error messages if unsuccessful
        if(saved.success) {
            toast.success("Call Flow Saved Successfully")
            fetchData();
            if(toggleModal) toggleModal();
            if(onSaved) onSaved(saved.data)
        } else {
            return setErr(saved.message);
        }
    
    }, [callFlow, fetchData, toggleModal, onSaved, selected_division._id])


    const fetchWorkflows = useCallback(async () => {
        if(workflows) return;
        const _workflows = await api.workflows.find();
        if(!_workflows.data) return setErr(["Please reload the page"]);

        selectWorkflows(_workflows.data.filter(d => d.division === selected_division._id))
       
    }, [selected_division._id, workflows])

    useEffect(() => {
        fetchWorkflows()
    }, [fetchWorkflows])

    useEffect(() => {
        if(toggleModal) {
            if(showModal) {
                fetchData()
            } else {
                setErr(null)
                setCallFlow({ ...baseState, timezone: company.timezone })
            }
        } else {
            fetchData()
        }
    }, [showModal, toggleModal, fetchData, company.timezone])


    if(!callFlow) return <></>
    if(err && !callFlow) return <ApiError err={err} />

    return (
        <div>

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">{callFlow.name}</CardTitle>
                </CardHeader>
                <CardBody>

                <Nav className="nav-fill flex-column flex-sm-row" id="tabs-text" pills role="tablist" >

                    <NavItem>
                        <NavLink
                            aria-selected={tab === 'info'}
                            className={`mb-sm-3 mb-md-0 ${tab === 'info' ? 'active' : ''}`}
                            onClick={e => setTab('info')}
                            role="tab"
                        >
                            Info
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink
                            aria-selected={tab === 'routing'}
                            className={`mb-sm-3 mb-md-0 ${tab === 'routing' ? 'active' : ''}`}
                            onClick={e => setTab('routing')}
                            role="tab"
                        >
                            Routing
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink
                            aria-selected={tab === 'tasks'}
                            className={`mb-sm-3 mb-md-0 ${tab === 'tasks' ? 'active' : ''}`}
                            onClick={e => setTab('tasks')}
                            role="tab"
                        >
                            Tasks
                        </NavLink>
                    </NavItem>
                    
                    <NavItem>
                        <NavLink
                            aria-selected={tab === 'hours'}
                            className={`mb-sm-3 mb-md-0 ${tab === 'hours' ? 'active' : ''}`}
                            onClick={e => setTab('hours')}
                            role="tab"
                        >
                            Hours
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink
                            aria-selected={tab === 'force'}
                            className={`mb-sm-3 mb-md-0 ${tab === 'force' ? 'active' : ''}`}
                            onClick={e => setTab('force')}
                            role="tab"
                        >
                            Force
                        </NavLink>
                    </NavItem>

                </Nav>
            </CardBody>
            
            <CardFooter>
                {tab === 'info' ? (
                    <Info callFlow={callFlow} onInputChange={onInputChange} />
                ) : tab === 'force' ? (
                    <Force callFlow={callFlow} onInputChange={onInputChange} />
                ) : tab === 'routing' ? (
                    <Routing callFlow={callFlow} onInputChange={onInputChange} workflows={workflows} />
                ) : tab === 'tasks' ? (
                    <Tasks callFlow={callFlow} onInputChange={onInputChange} workflows={workflows} onTasksChange={onTasksChange} />
                ) : tab === 'hours' ? (
                    <Hours callFlow={callFlow} onInputChange={onInputChange} />
                ) : ''}
            </CardFooter>

            {callFlow._id ? (
                 <CardFooter>
                    <Associations 
                        type="call_flow" 
                        _id={callFlow._id} 
                        classNames="border p-3 bg-secondary" 
                    />
                 </CardFooter>
            ) : ''}

            {err ? (
                <CardFooter>
                     <ApiError err={err} />
                </CardFooter>
            ) : ''}

            <CardFooter className='text-right'>
                <button className='btn btn-success' onClick={onSave}><i className="fas fa-save mr-2" /> Save</button>
            </CardFooter>

        </Card>
              
           
               
            
        </div>
    )

}




const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	    company: state.company,
	};
};

export default connect(mapStateToProps, '')(SystemCallFlowEdit);