import store from 'store'
import moment from 'moment-timezone'

export const docIsNotDownloaded = (document) => {

    if(!document) return false;

    const feb16th2022 = 1644987600;

    // don't show document before the update
    if(document.created_at < feb16th2022) return false;

    if(document.status === 'completed' && !document.seen) return true

    return false;

}

export const getCompanyLink = (company) => {
    if(!company) return '';
    return company.custom_link ? company.custom_link : company.name.toLowerCase()
}

export const getLoginUrl = (noRedirect) => {
    const state = store.getState();
    if(noRedirect) return `${state.load.DOMAINS.AUTH}`;
    return `${state.load.DOMAINS.AUTH}?redirect=${encodeURI(window.location.href)}`
}
export const getLogoutURL = (noRedirect) => {
    const state = store.getState();
    if(noRedirect) return `${state.load.DOMAINS.AUTH}/logout`;
    return `${state.load.DOMAINS.AUTH}/logout?redirect=${encodeURI(window.location.href)}`
}

export const hasPrivilege = (privilege) => {
    const state = store.getState();
    const privileges = state.auth.viewing_user.privileges;
    const PRIVILEGES = state.config.PRIVILEGES;
    if(!PRIVILEGES[privilege]) throw new Error(`Privilege ${privilege} not found.`)

    return privileges.includes(privilege) ? true : false;
}

export const getTime = (time, nonUnix) => {
    const viewing_user = store.getState().auth.viewing_user;
    const timezone = viewing_user.settings.timezone ? viewing_user.settings.timezone : 'America/New_York'
    if(nonUnix) return moment(time).tz(timezone);
    return moment.unix(time).tz(timezone);
}

export const getUser = (user_id) => {
    const users = store.getState().users;
    return users.find(u => u._id === user_id);
}

export const getNameFromReduxById = (property, _id) => {
    const collection = store.getState()[property][property];
    const found = collection.find(doc => doc._id === _id);
    return found ? found.name : ''
}