import { useCallback, useState } from "react";
import { Link } from 'react-router-dom';
import { Card } from "reactstrap";
import { connect } from 'react-redux';

import api from 'api';

import TextButtonRow from 'components/markup/layout/TextButtonRow'
import Circle from 'components/markup/loading/Circle'
import ConfirmationModal from 'components/functional/modals/Confirmation'
import TableIcons from 'components/markup/icons/TableIcons'
import Table from 'components/functional/tables/Standard';
import ApiError from "components/markup/layout/ApiError";

import { toggleAlertBS } from "store/functions/system/system";

import ObjectFinder from 'components/system/Objects/Finder';

const PipelinesAll = ({setTab, selected_division}) => {

    const [ loading, setLoading ] = useState(false);
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ pipelineToDelete, setPipelineToDelete ] = useState(false)
    const [ error, setError ] = useState(false);

    // helper for this.downloadCSV and this.query
    const fetchData = useCallback(() => new Promise (async resolve => {

        const query = await api.pipelines.find(selected_division._id)
        if(!query.data) return setError(query.message)

        return resolve({
            skip: 0,
            limit: 15,
            documents: query.data,
            total_documents: query.data.length
        })
    }), [selected_division._id])

    // run query to get data from the server
    const query = useCallback(async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }, [fetchData])

    const onDeletePipeline = useCallback(async (user) => {
        const deleted = await api.pipelines.delete(pipelineToDelete._id);
        if(deleted.success) {
            fetchData(null, null, true);
            toggleAlertBS(false, 'Pipeline Successfully Removed')
        } else {
            toggleAlertBS(true, `Something's not right, please try again.`)
        }
    }, [fetchData, pipelineToDelete])
 
    const columns = [
        {
            dataField: "given_name",
            text: 'Name',
            headerFormatter: () => {
                return <div>Name</div>
            },
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div>No Data To Show</div>
                )
                return (
                    <Link to={`/system/pipelines/${row._id}`} _id={row._id} onSaved={() => fetchData(null, null, true)}>
                        <span className="cursor-pointer text-capitalize">
                            {row.name}
                        </span>
                    </Link>
                )
                
            },
        },
        {
            dataField: "users",
            text: 'Users',
            formatter: (cell, row) => row.name === 'no data' ? '' : row.users.map((u, i) => (
                <span>{i !== 0 ? ', ' : ''} <ObjectFinder collection="users" _id={u} /></span>
            ))

        },
        {
            dataField: "workflow_steps",
            text: 'Steps',
            formatter: (cell, row) => row.name === 'no data' ? '' : row.workflow_steps.map((u, i) => (
                <span>{i !== 0 ? ', ' : ''} <ObjectFinder collection="workflow_steps" _id={u} /></span>
            ))

        },
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right',},
            text: 'Actions',
            headerFormatter: () => {
                return <div>Actions</div>
            },
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                        <TableIcons
                            icons={[
                                { 
                                    icon: 'fas fa-edit',
                                    color: 'success', 
                                    wrapper: Link, 
                                    to: `/system/pipelines/${row._id}`,
                                    // component: EmailTemplatesEdit,
                                    _id: row._id,
                                    onSaved: () => fetchData(null, null, true)
                                },
                                {  
                                    icon: 'fas fa-trash', 
                                    color: 'danger',
                                    onClick: () => setPipelineToDelete(row)
                                },
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(error) return <ApiError err={error} />
    if(loading) return <div><Circle /></div>

    return (

        <>

            <TextButtonRow 
                title={"All Pipelines"}
                button={(
                    <button onClick={() => setTab('create')} className="btn btn-success"><i className="fas fa-plus mr-2 " /> Add Pipeline</button>
                )}
            />

            <Card className="card-color card-primary table-fixed">
                <Table
                    tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                    placeholder="Search..."
                    query={query}
                    columns={columns}
                    data={tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}
                    sizePerPage={sizePerPage}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>

            <ConfirmationModal 
                showModal={pipelineToDelete ? true : false}
                toggleModal={() => setPipelineToDelete(null)}
                title="Delete Pipeline"
                body={(
                    <span>
                        Are you sure you wish to delete this pipeline {pipelineToDelete ? pipelineToDelete.name : ''}? 
                    </span>
                )}
                onConfirmation={onDeletePipeline}
            />

        </>
    )

}


const mapStateToProps = state => {
	return {
        selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(PipelinesAll);