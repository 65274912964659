import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import Page from './Page';
import update from 'immutability-helper';

const PageList = ({allQuestions, nestedLevel, parentId, page, pages, onSetPage, onAddPage, setQuestions, unsavedQuestion, setUnsavedForm, config}) => {

    const acceptNested = nestedLevel + '-' +  parentId

    const [items, setItems] = useState([]);
    const [, drop] = useDrop({ accept: acceptNested });

    // returns a question in the list by id
    const findItem = useCallback((id) => {
        const item = items.filter((c) => `${c.id}` === id)[0];
        if(!item) return {}
        return { item, index: items.indexOf(item) };
    }, [items]);


    // moves a questions order by drag and drop
    const moveItem = useCallback((id, atIndex) => {
        const { item, index } = findItem(id);
        if(!item) return;

        const newItems = update(items, {
            $splice: [
                [index, 1],
                [atIndex, 0, item],
            ],
        });

        return setItems(newItems);
    }, [findItem, items]);

    const onFinalDrop = useCallback((id, atIndex) => {
        const { item, index } = findItem(id);
        if(!item) return;

        const newItems = update(items, {
            $splice: [
                [index, 1],
                [atIndex, 0, item],
            ],
        });

        // item id is the page number
        const originalPage = item.id;
        const newPage = index;

        if(originalPage === newPage) return;
        const movedUp = originalPage < newPage ? true : false;

        let questions = JSON.parse(JSON.stringify(allQuestions));
        
        const movedPageQuestions = JSON.parse(JSON.stringify(questions.filter(q => q.page === originalPage)));
        movedPageQuestions.forEach(p => p.page = newPage );

        questions = questions.filter(q => q.page !== originalPage)

        questions.forEach(q => {
            if(movedUp) {
                if(q.page < originalPage) return;
                if(q.page > newPage) return;
                q.page = q.page - 1;
            } else {
                if(q.page > originalPage) return;
                if(q.page < newPage) return;
                q.page = q.page + 1;
            }
        })

        
        questions = questions.concat(movedPageQuestions);
        setQuestions(questions);
        onSetPage(newPage)
        setUnsavedForm(true)
        return setItems(newItems);



    }, [allQuestions, findItem, items, setQuestions, onSetPage, setUnsavedForm])


    useEffect(() => {
        if(!pages) return setItems([]);

        let markup = []

        for (let i = 0; i < pages; i++) {
            markup.push({id: i})
        }
        return setItems(markup);
    }, [pages, page])

    return (
        <>
        <ul ref={drop} >
            {items && items.length ? items.map((item, i) => (
                <Page 
                    i={i}
                    key={item.id} 
                    id={`${item.id}`} 
                    item={item} 
                    moveItem={moveItem} 
                    acceptNested={acceptNested}
                    findItem={findItem}
                    onFinalDrop={onFinalDrop}
                    onSetPage={onSetPage}
                    page={page}
                    unsavedQuestion={unsavedQuestion}
               />
            )) : null}

        </ul>       
        <ul className="pr-0 mb-0">
            <li onClick={onAddPage}>
                <i className={pages >= config.MAX_FORM_PAGES ? "fas fa-plus text-muted" : "fas fa-plus text-success"} />
            </li>
        </ul>
        </>
    )
};

export default memo(PageList)