import { useState, useEffect, useCallback } from 'react';

import ColEdit from '../ColEdit'
import ColSettings from '../ColSettings'

import state from '../state';

import ButtonDelete from './ButtonDelete'
import ButtonSave from './ButtonSave'

import { addArchkAlert } from 'store/functions/system/system'

const SelectedQuestion = ({selected, onQuestionSaved, onQuestionDeleted, questionRequiresSelectAnswers, questionCanBranch, unsavedQuestion, onSetUnsavedQuestion, questionErrors, config, customFields}) => {

    const [question, setQuestion]               = useState({});
    const [errs, setErrs]                   = useState([]);

    // handles all standard input change
    const onInputChange = useCallback((e, field) => {
        onSetUnsavedQuestion(true)
        const q = Object.assign({}, question);

        let value = e.target ? e.target.value : e;
        if(value === 'false') value = false;
        if(value === 'true') value = true;

        if(field === 'description' && value && value.length > config.MAX_DESCRIPTION_LENGTH) {
            value = value.slice(0, config.MAX_DESCRIPTION_LENGTH)   
        }
        if(field === 'name' && value && value.length > config.MAX_NAME_LENGTH) {
            value = value.slice(0, config.MAX_NAME_LENGTH)   
        }

        if(field === 'answers') {

            let branch_on_answers = []
            let highlight_on_answer = []

            question.branch_on_answers.forEach(b => {
                if(value.includes(b)) branch_on_answers.push(b);
            })
            question.highlight_on_answers.forEach(b => {
                if(value.includes(b)) highlight_on_answer.push(b);
            })

            q['branch_on_answers'] = branch_on_answers;
            q['highlight_on_answers'] = highlight_on_answer;
        }

        q[field] = value;

        if(field === 'type') {
            q.branch_on_answers = [];
            q.highlight_on_answers = [];
        }
       
        setQuestion(q)
    }, [question, onSetUnsavedQuestion, config])

    const saveQuestion = useCallback(() => {

        let _errs = questionErrors(question);

        if(_errs.length) {
            addArchkAlert({type: 'info', body: 'Please check form fields before saving.', timeout: 4000})
            return setErrs(_errs);
        }

        onQuestionSaved(question);

    }, [question, onQuestionSaved, questionErrors])


    useEffect(() => {
        const baseState = JSON.parse(JSON.stringify(state.question))
        setQuestion({...baseState, ...selected.question});
        setErrs([])
    }, [selected.question])

    return (
        <>
             <ColEdit 
                selected={selected}
                setQuestion={setQuestion}
                question={question}
                onInputChange={onInputChange}
                questionRequiresSelectAnswers={questionRequiresSelectAnswers}
                errs={errs}
                unsavedQuestion={unsavedQuestion}
                config={config}
                customFields={customFields}
            />

            <ColSettings 
                setQuestion={setQuestion}
                question={question}
                onInputChange={onInputChange}
                questionCanBranch={questionCanBranch}
                errs={errs}
                config={config}
                customFields={customFields}
            />

            <div className="save-question text-right bg-secondary pr-4">
                <ButtonDelete disabled={!question.id} onQuestionDeleted={() => onQuestionDeleted(question ? question : {})} />
                <ButtonSave   disabled={!question.id} unsavedQuestion={unsavedQuestion} onQuestionSaved={saveQuestion} />
            </div>
           
            
        </>
    )
}

export default SelectedQuestion