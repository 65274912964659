import { useState, useCallback, memo, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom';

import InternalSidebar from 'components/markup/layout/InternalSidebar'

import TextButtonRow from 'components/markup/layout/TextButtonRow'

import Names from './Names'
import Addresses from './Addresses'
import Availability from './Availability'
import Alerts from './Alerts'
import Keys from './Keys'
import AISettings from './AISettings'

const IntuideskSectionWrapper = ({match, innerSection, section, editLink}) => {
    const [lastParam, setLastParam] = useState(match.params.nav2 ? match.params.nav2 : 'all')
    const [tab, setTab] = useState(match.params.nav2 ? match.params.nav2 : 'all')
    const [redirect, setRedirect] = useState(false);

    const onSetTab = useCallback((tab) => {
        if(tab === 'create' && editLink) return setRedirect(editLink)
        setTab(tab)
        window.history.pushState('data', innerSection, `/${section}/edit/${tab.toLowerCase()}`);
        window.scrollTo(0, 0);
    }, [section, innerSection, editLink])

    useEffect(() => {
        if(tab === 'create' && editLink) return setRedirect(editLink)
    }, [tab, editLink])

    useEffect(() => {
        const mParam = match.params.nav2 ? match.params.nav2 : 'names';
        if(lastParam === mParam) return;
        if(mParam && mParam !== tab) onSetTab(mParam)
        setLastParam(mParam)
    }, [tab, match.params.nav2, onSetTab, lastParam])

    if(redirect) return <Redirect to={redirect} />

    return (
        <InternalSidebar
            title={"Company Info"}
            tab={tab}
            setTab={onSetTab}
            tabs={[
                { value: 'names', name: 'Names' },
                { value: 'addresses', name: 'Addresses' },
                { value: 'availability', name: 'Availability' },
                // { value: 'communication', name: 'Communication' },
                { value: 'alerts', name: 'Alerts' },
                // { value: 'branding', name: 'Branding' },
                { value: 'keys', name: 'API Keys' },
                { value: 'ai', name: 'AI Settings' },
            ]}
        >

            {tab === 'names' ? (
                <Names />
            ) : tab === 'addresses' ? (
                <Addresses />
            )  : tab === 'availability' ? (
                <Availability />
            )  : tab === 'alerts' ? (
                <Alerts />
            ) : tab === 'keys' ? (
                <Keys />
            ) : tab === 'ai' ? (
                <AISettings />
            ) : ''}


           
        </InternalSidebar>
    )

}

export default memo(IntuideskSectionWrapper);




// default_email_sender    : { type: Schema.Types.ObjectId, ref: 'email_senders' },
// default_phone_number    : { type: Schema.Types.ObjectId, ref: 'call_phone_numbers' },

// name                    : { type: String, required: true },
// display_name            : { type: String, required: true },
// legal_name              : { type: String, required: true },

// contact_name              : { type: String, required: true },

// notification_emails     : { type: Array, default: [] },

// address_line_1          : { type: String, default: '' },
// address_line_2          : { type: String, default: '' },
// city                    : { type: String, default: '' },
// state                   : { type: String, default: '' },
// postal_code             : { type: String, default: '' },

// billing_address_line_1  : { type: String, default: '' },
// billing_address_line_2  : { type: String, default: '' },
// billing_city            : { type: String, default: '' },
// billing_state           : { type: String, default: '' },
// billing_postal_code     : { type: String, default: '' },

// timezone                : { type: String, default: 'America/New_York', required: true },

// branding : {
//     type: {
//         logo          : { type: Schema.Types.ObjectId, ref: 'company_documents', default: '' },
//         logo_width    : { type: String, default: '' },
//     },
//     default: {},
//     required: true
// },


// times_open: {
//     type: {
//         mon : {
//             type: {
//                 start   : { type: Number },
//                 end     : { type: Number },
//             },
//         },
//         tue : {
//             type: {
//                 start   : { type: Number },
//                 end     : { type: Number },
//             },
//         },
//         wed : {
//             type: {
//                 start   : { type: Number },
//                 end     : { type: Number },
//             },
//         },
//         thur : {
//             type: {
//                 start   : { type: Number },
//                 end     : { type: Number },
//             },
//         },
//         fri : {
//             type: {
//                 start   : { type: Number },
//                 end     : { type: Number },
//             },
//         },
//         sat : {
//             type: {
//                 start   : { type: Number },
//                 end     : { type: Number },
//             },
//         },
//         sun : {
//             type: {
//                 start   : { type: Number },
//                 end     : { type: Number },
//             },
//         },
//     },
//     default : {
//         mon: { start: 32400, end: 61200 },
//         tue: { start: 32400, end: 61200 },
//         wed: { start: 32400, end: 61200 },
//         thu: { start: 32400, end: 61200 },
//         fri: { start: 32400, end: 61200 },
//         sat: { start: 0, end: 0 },
//         sun: { start: 0, end: 0 },
//     },
//     required: true
// },