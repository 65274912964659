import { Input, FormGroup, Row, Col, Badge } from "reactstrap";
import { useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux'

import api from 'api'

import Modalify from 'components/functional/modals/Modalify'
import InputStates from 'components/markup/inputs/States'

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';
import ReactSelect from 'components/functional/inputs/ReactSelect'
import PhoneInput from 'react-phone-input-2'

import AddressLookup from "components/markup/inputs/AddressLookup";

const baseState = {
    location_types: []
}

const SystemLocationEdit = ({_id, onSaved, showModal, toggleModal, selected_division}) => {

    const [location, setLocation] = useState(null)
    const [locationTypes, setLocationTypes] = useState([])
    const [rep, setRep] = useState({})
    const [err, setErr] = useState(null)

    const fetchTypes = useCallback(async () => {
        const types = await api.location_types.find()
        if(!types.data) return setErr(true);
        setLocationTypes(types.data.filter(d => d.division === selected_division._id))
    }, [selected_division._id])

    const fetchData = useCallback(async () => {
        if(!_id || _id === 'create') return setLocation(baseState);
        const user = await api.locations.findById(_id)
        if(!user.data) return setErr(true);
        setLocation(user.data)
    }, [_id])

    const onStateChange = useCallback(( obj ) => {
        let newLocation = JSON.parse(JSON.stringify(location))
        newLocation = { ...location, ...obj };
        setLocation(newLocation);
    }, [location])
  
    const onInputChange = useCallback(( field, value) => {
        const newLocation = JSON.parse(JSON.stringify(location))
        newLocation[field] = value;
        setLocation(newLocation);
    }, [location])
    
    const onRepInputChange = useCallback(( field, value) => {
        const newRep = JSON.parse(JSON.stringify(rep))
        newRep[field] = value;
        setRep(newRep);
    }, [rep])

  
    const onAddRep = useCallback(() => {
        const newLocation = JSON.parse(JSON.stringify(location))
        if(!rep.name) return;

        const reps = newLocation.representatives ? newLocation.representatives : []
        reps.push(rep);

        if(reps.length > 10) return toggleAlertBS('info', 'You may assign a maximum of 10 representatives per location')
        
        newLocation.representatives = reps;
        setLocation(newLocation)
        setRep({})

    }, [location, rep])

    const onRemoveRep = useCallback((index) => {
        const newLocation = JSON.parse(JSON.stringify(location))

        const reps = newLocation.representatives
        reps.splice(index, 1)
   
        newLocation.representatives = reps;
        setLocation(newLocation)
    }, [location])
   
    const onAddType = useCallback((_id) => {
        const newLocation = JSON.parse(JSON.stringify(location))

        const types = newLocation.location_types ? newLocation.location_types : []
        if(!types.includes(_id) )types.push(_id);

        newLocation.location_types = types;
        setLocation(newLocation)
    }, [location])
 
    const onRemoveType = useCallback((index) => {
        const newLocation = JSON.parse(JSON.stringify(location))

        const types = newLocation.location_types
        types.splice(index, 1)
   
        newLocation.location_types = types;
        setLocation(newLocation)
    }, [location])
   


    const onSave = useCallback(async () => {
        setErr(false)
        let newState = JSON.parse(JSON.stringify(location));

        if(!newState.name) {
            return setErr([`A location must have a name.`])
        }
        if(!newState.location_types.length) {
            return setErr([`A location must have a at least 1 type associated with it.`])
        }
        
        let saved;

        // toggle loaders and update/create the user
        toggleStandardLoader(true)
        if(newState._id) {
            saved = await api.locations.update(newState._id, newState, true);
        } else {
            saved = await api.locations.create({...newState, division: selected_division._id}, true);
        }
        toggleStandardLoader(false)

        // show error messages if unsuccessful
        if(saved.success) {
            toggleAlertBS(false, "Location Saved Successfully")
            fetchData();
            if(toggleModal) toggleModal();
            if(onSaved) onSaved(saved.data)
        } else {
            return setErr(saved.message);
        }
    
    }, [location, fetchData, toggleModal, onSaved, selected_division._id])

    useEffect(() => {
        if(toggleModal) {
            if(showModal) {
                fetchData()
                fetchTypes()
            } else {
                setErr(null)
                setLocation(baseState)
            }
        } else {
            fetchData()
            fetchTypes()
        }
    }, [showModal, toggleModal, fetchData, fetchTypes])

    return (
        <Modalify 
            showModal={showModal}
            toggleModal={toggleModal}
            modalTitle={location && location._id ? "Update Location" : "Create Location"}
            err={err}
            footer={(
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            )}
            sections={!location ? [] :  [
                (
                    <div>

                        <h2>Identifiers</h2>
        
                        <FormGroup>
                            <label className="form-control-label">Location Name</label>
                            <Input 
                                type="text"
                                value={location.name || ''}
                                onChange={e => onInputChange('name', e.target.value)}
                            />
                        </FormGroup>

                        <ReactSelect 
                            formGroup={true}
                            title="Location Types"
                            value={{}}
                            onChange={(obj) => onAddType(obj.value)}
                            options={locationTypes.map(type => {
                                return { value: type._id, label: type.name }
                            })}
                        />

                        <div>
                            {location.location_types ? location.location_types.map((tt, i) => {
                                const type = locationTypes.find(t => t._id === tt)
                                return <Badge color="success">{type ? type.name : ''} <i className="fas fa-times pl-3 cursor-pointer" onClick={() => onRemoveType(i)} /></Badge>
                            }) : ''}
                        </div>
                    </div>
                ),
                (
                    <div>

                        <h2>Location Address</h2>
        
                        <Row>
                            <Col lg={6}>
                                {/* <FormGroup>
                                    <label className="form-control-label">Address Line 1</label>
                                    <Input 
                                        type="text"
                                        value={location.address_line_1 || ''}
                                        onChange={e => onInputChange('address_line_1', e.target.value)}
                                    />
                                </FormGroup>     */}
                                <AddressLookup 
                                    title="Address Line 1"
                                    value={location.address_line_1}
                                    onChange={val => onInputChange('address_line_1', val)}
                                    onSelect={onStateChange}
                                />

                            </Col>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Address Line 2</label>
                                    <Input 
                                        type="text"
                                        value={location.address_line_2 || ''}
                                        onChange={e => onInputChange('address_line_2', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                        </Row>
        
                        <Row>
                            <Col lg={4}>
                                <FormGroup>
                                    <label className="form-control-label">City</label>
                                    <Input 
                                        type="text"
                                        value={location.city || ''}
                                        onChange={e => onInputChange('city', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                            <Col lg={4}>
                                <InputStates 
                                    value={location.state}
                                    onChange={state => onInputChange('state', state)}
                                />
                            </Col>
                            <Col lg={4}>
                                <FormGroup>
                                    <label className="form-control-label">Zip</label>
                                    <Input 
                                        type="text"
                                        value={location.postal_code || ''}
                                        onChange={e => onInputChange('postal_code', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                        </Row>
                    </div>
                ),
                (
                    <div>

                        <h2>Location Contact Info</h2>
            
                        <Row>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Email</label>
                                    <Input 
                                        type="text"
                                        value={location.email || ''}
                                        onChange={e => onInputChange('email', e.target.value)}
                                    />
                                </FormGroup>      
                                <FormGroup>
                                    <label className="form-control-label">Email #2</label>
                                    <Input 
                                        type="text"
                                        value={location.email_2 || ''}
                                        onChange={e => onInputChange('email_2', e.target.value)}
                                    />
                                </FormGroup>      
                                <FormGroup>
                                    <label className="form-control-label">Email #3</label>
                                    <Input 
                                        type="text"
                                        value={location.email_3 || ''}
                                        onChange={e => onInputChange('email_3', e.target.value)}
                                    />
                                </FormGroup>      
                            </Col>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Phone</label>
                                    <PhoneInput
                                        country={'us'}
                                        value={location.phone || ''}
                                        onChange={phone => onInputChange('phone', phone)}
                                    />
                                </FormGroup>      
                                <FormGroup>
                                    <label className="form-control-label">Phone #2</label>
                                    <PhoneInput
                                        country={'us'}
                                        value={location.phone_2 || ''}
                                        onChange={phone => onInputChange('phone_2', phone)}
                                    />
                                </FormGroup>      
                                <FormGroup>
                                    <label className="form-control-label">Phone #3</label>
                                    <PhoneInput
                                        country={'us'}
                                        value={location.phone_3 || ''}
                                        onChange={phone => onInputChange('phone_3', phone)}
                                    />
                                </FormGroup>      
                            </Col>
                        </Row>
                    </div>
                ),
                (
                    <div>

                        <h2>Location Fax Numbers</h2>
            
                        <Row>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Fax #1</label>
                                    <PhoneInput
                                        country={'us'}
                                        value={location.fax_number || ''}
                                        onChange={phone => onInputChange('fax_number', phone)}
                                    />
                                </FormGroup>      
                            </Col>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Fax #2</label>
                                    <PhoneInput
                                        country={'us'}
                                        value={location.fax_number_2 || ''}
                                        onChange={phone => onInputChange('fax_number_2', phone)}
                                    />
                                </FormGroup>     
                            </Col>
                           
                        </Row>


                    </div>
                ),
                (
                    <div>

                        <h2>Location Representatives</h2>
        
                        <FormGroup>
                            <label className="form-control-label">Rep Name*</label>
                            <Input 
                                type="text"
                                value={rep.name || ''}
                                onChange={e => onRepInputChange('name', e.target.value)}
                            />
                        </FormGroup>    

                        <Row>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Email</label>
                                    <Input 
                                        type="text"
                                        value={rep.email || ''}
                                        onChange={e => onRepInputChange('email', e.target.value)}
                                    />
                                </FormGroup>      
                                <FormGroup>
                                    <label className="form-control-label">Email #2</label>
                                    <Input 
                                        type="text"
                                        value={rep.email_2 || ''}
                                        onChange={e => onRepInputChange('email_2', e.target.value)}
                                    />
                                </FormGroup>         
                            </Col>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Phone</label>
                                    <PhoneInput
                                        country={'us'}
                                        value={rep.phone || ''}
                                        onChange={phone => onRepInputChange('phone', phone)}
                                    />
                                </FormGroup>      
                                <FormGroup>
                                    <label className="form-control-label">Phone #2</label>
                                    <PhoneInput
                                        country={'us'}
                                        value={rep.phone_2 || ''}
                                        onChange={phone => onRepInputChange('phone_2', phone)}
                                    />
                                </FormGroup>      
                            </Col>
                        </Row>

                        <FormGroup>
                            <label className="form-control-label">Notes*</label>
                            <Input 
                                type="textarea"
                                value={rep.notes || ''}
                                onChange={e => onRepInputChange('notes', e.target.value)}
                            />
                        </FormGroup>    


                        <div className="text-right mb-3" onClick={onAddRep}>
                            <button className="btn btn-outline-success"><i className="fas fa-plus mr-2 " /> Add Rep</button>
                        </div>

                    </div>
                ),
                (
                    <div>


                          {location.representatives && location.representatives.length ? (
                            <div className="table-responsive border">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Rep Name</th>
                                            <th>Emails</th>
                                            <th>Phone Numbers</th>
                                            <th>Notes</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {location.representatives.map((rep, i) => (
                                            <tr key={i}>
                                                <td>{rep.name}</td>
                                                <td>
                                                    {rep.email ? <div>{rep.email}</div> : ''}
                                                    {rep.email_3 ? <div>{rep.email_3}</div> : ''}
                                                </td>
                                                <td>
                                                    {rep.phone ? <div>{rep.phone}</div> : ''}
                                                    {rep.phone_3 ? <div>{rep.phone_3}</div> : ''}
                                                </td>
                                                <td>{rep.notes}</td>
                                                <td className="text-right">
                                                    <button onClick={() => onRemoveRep(i)} className="btn btn-danger">Remove</button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <div className="py-3 px-4 border rounded bg-secondary">
                                <p className="text-sm mb-0">
                                    <i className="fas fa-info-circle mr-2 text-info-original " /> 
                                    This location does not have any established reps.{' '}
                                </p>
                            </div>
                        )}
                    </div>
                ),
                
               
               
            ]}
        />
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SystemLocationEdit);