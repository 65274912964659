import * as actionTypes from '../../actions';
import store from '../../index';
import api from 'api';

export const setCompany = async() => {
    return new Promise( async resolve => {

        const company_id = store.getState().auth.viewing_user.company;
        const company = await api.companies.findById(company_id)

        if(!company.success) return resolve({success: false});
        store.dispatch({ type: actionTypes.SET_COMPANY, payload: company.data });
        resolve({success: true});
        
    })
}