import React, { useCallback } from 'react';

import Action from './Action'
import RunAssistant from './RunAssistant'
import Trigger from './Trigger'
import IfStatement from './IfStatement'
import Notification from './Notification'
import UserVerification from './UserVerification'
import Wait from './Wait'



const BackgroundProcessesSidebarRight = ({ selectedNode, onNodeUpdated }) => {

    if(!selectedNode) return <></>
   
    return (
        <div className='sidebar-right z-depth-2'>

            {selectedNode.type === 'action' ? (
                <Action 
                    selectedNode={selectedNode}
                    onNodeUpdated={onNodeUpdated}
                />
            ) : selectedNode.type === 'run_assistant' ? (
                <RunAssistant 
                    selectedNode={selectedNode}
                    onNodeUpdated={onNodeUpdated}
                />
            ) : selectedNode.type === 'trigger' ? (
                <Trigger 
                    selectedNode={selectedNode}
                    onNodeUpdated={onNodeUpdated}
                />
            ) : selectedNode.type === 'if_statement' ? (
                <IfStatement 
                    selectedNode={selectedNode}
                    onNodeUpdated={onNodeUpdated}
                />
            ) : selectedNode.type === 'notification' ? (
                <Notification 
                    selectedNode={selectedNode}
                    onNodeUpdated={onNodeUpdated}
                />
            ) : selectedNode.type === 'user_verification' ? (
                <UserVerification 
                    selectedNode={selectedNode}
                    onNodeUpdated={onNodeUpdated}
                />
            ) : selectedNode.type === 'wait' ? (
                <Wait 
                    selectedNode={selectedNode}
                    onNodeUpdated={onNodeUpdated}
                />
            )  : (
                <div className='sidebar-section'>
                    <h2 className='text-center text-warning'>Unknown Node Type, Internal Error</h2>
                </div>
            )}

        </div>

    )

}

export default BackgroundProcessesSidebarRight