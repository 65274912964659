import { Card, CardBody, CardHeader, CardTitle, CardFooter, Col, Row } from "reactstrap";
import { Link } from 'react-router-dom'

import A from 'components/markup/links/A'

const LocationsAll = () => (
    <Row>
        <Col md={4}>
            <A href="/analytics/matters">
                <Card className='text-center'>
                    <CardHeader>
                        <CardTitle className='mb-0'>Matter Analytics</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <i className="fas fa-shoe-prints display-1 text-info" />
                    </CardBody>
                    <Link to="/analyze/matters">
                        <CardFooter>
                            <p className="mb-0">Custom Reports</p>
                        </CardFooter>
                    </Link>
                </Card>
            </A>
        </Col>

        <Col md={4}>
            <A href="/analytics/contacts">
                <Card className='text-center'>
                    <CardHeader>
                        <CardTitle className='mb-0'>Contact Analytics</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <i className="fas fa-users display-1 text-purple" />
                    </CardBody>
                    <Link to="/analyze/contacts">
                        <CardFooter>
                            <p className="mb-0">Custom Reports</p>
                        </CardFooter>
                    </Link>
                </Card>
            </A>
        </Col>
        
        <Col md={4}>
            <A href="/analytics/payments">
                <Card className='text-center' style={{opacity: .4}}>
                    <CardHeader>
                        <CardTitle className='mb-0'>Payment Analytics</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <i className="fas fa-dollar-sign display-1 text-success" />
                    </CardBody>
                </Card>
            </A>
        </Col>
        <Col md={4}>
            <A href="/analytics/events">
                <Card className='text-center' style={{opacity: .4}}>
                    <CardHeader>
                        <CardTitle className='mb-0'>Events Analytics</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <i className="fas fa-calendar display-1 text-success" />
                    </CardBody>
                </Card>
            </A>
        </Col>
        
        <Col md={4}>
            <A href="/analytics/payments">
                <Card className='text-center' style={{opacity: .4}}>
                    <CardHeader>
                        <CardTitle className='mb-0'>Recurring Analytics</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <i className="fa-solid fa-credit-card display-1 text-success" />
                    </CardBody>
                </Card>
            </A>
        </Col>

        <Col md={4}>
            <A href="/analytics/calls">
                <Card className='text-center' style={{opacity: .4}}>
                    <CardHeader>
                        <CardTitle className='mb-0'>Call Analytics</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <i className="fa-solid fa-phone display-1 text-warning" />
                    </CardBody>
                </Card>
            </A>
        </Col>
        
        <Col md={4}>
            <A href="/analytics/signatures" disabled={true}>
                <Card className='text-center' style={{opacity: .4}}>
                    <CardHeader>
                        <CardTitle className='mb-0'>Signature Analytics</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <i className="fa-solid fa-signature display-1 text-danger" />
                    </CardBody>
                </Card>
            </A>
        </Col>
    </Row>
)

export default LocationsAll