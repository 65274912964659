import { Input, FormGroup } from "reactstrap";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { useCallback, useState, useEffect } from 'react';

import api from 'api'

import Modalify from 'components/functional/modals/Modalify'

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

import Associations from 'components/system/Associations'

import ModalCustomFields from 'components/system/CustomFields'

import ModalToggler from 'components/functional/modals/Toggler'

const TextTemplatesView = ({_id, onSaved, showModal, toggleModal, selected_division}) => {

    const [textTemplate, setTextTemplate] = useState(null)
    const [err, setErr] = useState(null)
    const [redirect, setRedirect] = useState(null)

    const fetchData = useCallback(async () => {
        if(!_id || _id === 'create') return setTextTemplate({ attachments: [] });
        const data = await api.text_templates.findById(_id)
        if(!data.data) return setErr(true);
        setTextTemplate(data.data)
    }, [_id])

    const onInputChange = useCallback(( field, value) => {
        const newTextTemplate = Object.assign({}, textTemplate)
        newTextTemplate[field] = value;
        if(field === 'body' && value && value.length > 1600) return
        setTextTemplate(newTextTemplate);
    }, [textTemplate])

    const onSave = useCallback(async () => {

        setErr(false)
        let newState = Object.assign({}, textTemplate);

        if(!newState.name || !newState.body) {
            return setErr([`An text template must have a name and body.`])
        }
        
        setTextTemplate(newState)

        let saved;

        // toggle loaders and update/create the data
        toggleStandardLoader(true)
        if(newState._id) {
            saved = await api.text_templates.update(newState._id, newState, true);
        } else {
            saved = await api.text_templates.create({...newState, division: selected_division._id}, true);
        }
        toggleStandardLoader(false)

        // show error messages if unsuccessful
        if(saved.success) {
            toggleAlertBS(false, "Text Template Saved Successfully")
            fetchData();
            setTextTemplate({})
            if(toggleModal) toggleModal();
            if(onSaved) onSaved(saved.data)
        } else {
            return setErr(saved.message);
        }
    
    }, [textTemplate, fetchData, toggleModal, onSaved, selected_division._id])

    useEffect(() => {
        if(toggleModal) {
            if(showModal) {
                fetchData()
            } else {
                setErr(null)
                setTextTemplate(null)
            }
        } else {
            fetchData()
        }
    }, [showModal, toggleModal, fetchData])

    useEffect(() => {
        if(!selected_division._id) {
            toggleAlertBS('info', 'A division must be created for editing a matter doc.')
            setRedirect('/system/divisions/create')
        }
    }, [selected_division._id])

    if(redirect) return <Redirect to={redirect} />

    return (
        <Modalify 
            showModal={showModal}
            toggleModal={toggleModal}
            modalTitle={textTemplate && textTemplate._id ? "Update Text Template" : "Create Text Template"}
            err={err}
            footer={(
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            )}
            sections={!textTemplate ? [] : [
                (
                    <div>

                        <h2>Identifier</h2>
                        <FormGroup>
                            <label className="form-control-label">Template Name*</label>
                            <Input 
                                type="text"
                                value={textTemplate.name || ''}
                                onChange={e => onInputChange('name', e.target.value)}
                            />
                        </FormGroup>

                    </div>
                ),
                (
                    <div>

                        <h2> Body</h2>
                        <FormGroup>
                            <label className="form-control-label">
                                Body*{' '}
                                {textTemplate.body && textTemplate.body.length > 320 ? (
                                    <span className="text-info">For best deliverability keep test messages to 320 characters or less</span>
                            ) : ''}
                            </label>
                            <Input 
                                type="textarea"
                                value={textTemplate.body || ''}
                                onChange={e => onInputChange('body', e.target.value)}
                                style={{minHeight: 100}}
                            />
                            <p className="text-sm mb-0">{textTemplate.body ? textTemplate.body.length : 0}/1600 Characters</p>
                          
                        </FormGroup>
                    </div>
                ),
                (
                    <div>
                        <ModalToggler component={ModalCustomFields}>
                            <span className="cursor-pointer"><i className="fas fa-info-circle text-info" /> Custom Fields</span>
                        </ModalToggler>
                    </div>  
                ),
                ...(textTemplate._id ? [(
                    <Associations 
                        type="text_template" 
                        _id={textTemplate._id} 
                        classNames="border p-3 bg-secondary" 
                    />
                )] : []),
            ]}
        />
    )

}

const mapStateToProps = state => {
	return {
        selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(TextTemplatesView);