import { useCallback, useState } from "react";
import { connect } from 'react-redux';
import { Badge, Card } from "reactstrap";

import api from 'api';
import formatObject from 'utils/formatObject';

import ModalToggler from 'components/functional/modals/Toggler';
import Table from 'components/functional/tables/Standard';
import TableIcons from 'components/markup/icons/TableIcons';
import ApiError from "components/markup/layout/ApiError";
import TextButtonRow from 'components/markup/layout/TextButtonRow';
import Circle from 'components/markup/loading/Circle';

const LocationsAll = ({setTab, selected_division}) => {

    const [ loading, setLoading ] = useState(false);
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ error, setError ] = useState(false);




    // helper for this.downloadCSV and this.query
    const fetchData = useCallback((params, isCSV, shouldReload) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(shouldReload) setLoading(true)
        const query = await api.locations.search({ ...params, filter: { deleted: false, division: selected_division._id }, isCSV })
        if(shouldReload) setLoading(false)

        if(!query.data) return setError(query.message)
        return resolve(query.data)
    }), [sizePerPage, selected_division._id])

    // run query to get data from the server
    const query = useCallback(async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }, [fetchData])

   
 
    const columns = [
        {
            dataField: "given_name",
            text: 'Name',
            headerFormatter: () => {
                return <div id="archk-tutorial-contacts-name">Name</div>
            },
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )
                return (
                    <ModalToggler _id={row._id} onSaved={() => fetchData(null, null, true)}>
                        {row.name}
                    </ModalToggler>
                )
                
            },
        },

        {
            dataField: "address_line_1",
            text: 'Address',
            formatter: (cell, row) => formatObject(row).address()
        },
        {
            dataField: "email",
            text: 'Email',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' :
                    row.email ? (
                        <div>
                            {row.email}{' '}
                            {row.email_2 && row.email_3 ? <Badge style={{position: 'relative', top: -2}} color="success"><i className="fas fa-plus " /> 2</Badge> : 
                            row.email_2 || row.email_3 ? <Badge style={{position: 'relative', top: -2}} color="success"><i className="fas fa-plus " /> 1</Badge> : ''}
                        </div>
                    ) : ''
            )
        },
        {
            dataField: "phone",
            text: 'Phone',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' :
                    row.phone ? (
                        <div>
                            {row.phone}{' '}
                            {row.phone_2 && row.phone_3 ? <Badge style={{position: 'relative', top: -2}} color="success"><i className="fas fa-plus " /> 2</Badge> : 
                            row.phone_2 || row.phone_3 ? <Badge style={{position: 'relative', top: -2}} color="success"><i className="fas fa-plus " /> 1</Badge> : ''}
                        </div>
                    ) : ''
            )
        },
      
        {
            dataField: "representatives",
            text: 'Reps',
            formatter: (cell, row) => row.representatives ? row.representatives.length : ''

        },
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right',},
            text: 'Actions',
            headerFormatter: () => {
                return <div id="archk-tutorial-contacts-actions">Actions</div>
            },
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                         <TableIcons
                            icons={[
                                { 
                                    icon: 'fas fa-edit',
                                    color: 'success', 
                                    wrapper: ModalToggler, 
                                    onSaved: () => fetchData(null, null, true),
                                    _id: row._id
                                },
                                {  
                                    icon: 'fas fa-trash', 
                                    color: 'danger',

                                },
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(error) return <ApiError err={error} />
    if(loading) return <div><Circle /></div>

    return (

        <>

        <TextButtonRow title={"Leads 4/28/2023"}/>

        <Card className="card-color card-primary table-fixed">
            <Table
                tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                placeholder="Search..."
                query={query}
                columns={columns}
                data={tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                totalDocuments={tableData.total_documents}
                sizePerPage={sizePerPage}
                onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
            />
        </Card>

        </>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(LocationsAll);