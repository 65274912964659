import React from 'react'
import PropTypes from 'prop-types';

const NameCircle = (props) => {

    let bg = 'bg-warning'
    let width = 0

    if(props.bg) { bg = props.bg }
    if(props.width) { width = props.width}

    const { contact } = props
    let initials = ''

    if(contact) {

        if(contact.given_name) initials += contact.given_name.slice(0,1)
        if(contact.family_name) initials += contact.family_name.slice(0,1)

    }

    if(!initials && !props.initials) initials = 'NF'

    const style = props.style ? props.style : {}

    return (
        <span
            style={{
                display: 'inline-block',
                paddingTop: width / 4.5,
                fontSize: width / 2.5,
                width: width,
                height: width,
                borderRadius: '100%',
                ...style

            }}
            className={`name-circle font-weight-bold ${bg} text-center text-white shadow-sm text-uppercase`}
        >
            {props.initials ? props.initials : initials}
        </span>
    );

}

NameCircle.propTypes = {
    contact   : PropTypes.object,
    initials  : PropTypes.string,
    width     : PropTypes.number.isRequired,
    bg        : PropTypes.string,
}

export default NameCircle;
