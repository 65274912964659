import { useState, useCallback } from "react";
import { connect } from 'react-redux';
import { Card, CardFooter, CardHeader, CardTitle, Col, Container, Form, FormGroup, Input, Row, Progress } from "reactstrap";

import api from 'api';
import { setCompany } from 'store/functions/company/company'

import Icon from './_images/PinwheelLink.svg'

import Dots from 'components/markup/loading/Dots'

const OnboardingSetEmail = ({viewing_user, company, onSetView}) => {

    const [email, setEmail] = useState(company.email ? company.email : viewing_user.email);
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState(false);

    const onSave = useCallback(async (e) => {

        e.preventDefault();
        setErr(null);

        setLoading(true);
        const saved = await api.companies.update(viewing_user.company, {email })

        if(saved.success) {
            await setCompany()
            onSetView('Documents')
        } else {
            setErr(saved.message)
            setLoading(false);
        }

    }, [email, viewing_user.company, onSetView])

    return (

        <Container className="mt--9 pb-5">
            <Row className="justify-content-center">
                <Col lg="5" md="7">

                    <Form role="form" style={{zIndex: 2, position: 'relative'}}>

                        <Card className="bg-white border-0 mb-0 position-relative" >

                            <img src={Icon} alt="..." className="flow-icon bg-white" />

                            <CardHeader>
                                <CardTitle className="flow-icon-header mb-0">Welcome To IntuiDesk!</CardTitle>
                            </CardHeader>
                            <CardHeader>
                                <div className="progress-wrapper mt--3 pt-0">
                                    <div className="progress-sucess">
                                        <div className="progress-label" style={{display: 'inline'}}>
                                            <span className="text-purple" style={{position: 'relative', top: 13,}}>Onboarding</span>
                                        </div>
                                        <div className="progress-percentage">
                                        <span style={{position: 'relative', top: -12}}>25%</span>
                                        </div>
                                    </div>
                                    <Progress max="100" value={25} color="purple" />
                                </div>
                            </CardHeader>

                            <CardHeader className="bg-secondary">
                                <p className="text-sm">You're about <b className="text-underline text-success">90 seconds</b> away from revolutionizing your companies document collection process.</p>
                                <p className="text-sm mb-0">Let's get started, where would you like notifications from IntuiDesk to go to? This includes notices about your account, product updates, etc.</p>
                            </CardHeader>

                            <CardHeader >

                                <FormGroup>
                                    <label className="form-control-label">Email Address*</label>
                                    <Input
                                        name="identifier"
                                        type="text"
                                        value={email}
                                        invalid={err ? true : false}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </FormGroup>

                            </CardHeader>

                            {err ? (
                            <CardHeader>
                                {err.map((e, i) => <p key={i} className="mb-0 text-sm text-danger font-weight-bold">{e}</p>)}
                            </CardHeader>
                            ) : null}

                            <CardFooter className="text-center">
                                {loading ? (
                                     <button disabled={true} className="my-2 btn-block btn btn-success btn-lg">
                                        Move On<Dots />
                                    </button>
                                ) : (
                                    <button onClick={onSave} className="my-2 btn-block btn btn-success btn-lg">
                                        Move On <i className="fas fa-arrow-right ml-2 " />
                                    </button>
                                )}
                            </CardFooter>
                        </Card>

                    </Form>

                </Col>
            </Row>
        </Container>

    )
}


const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(OnboardingSetEmail);

