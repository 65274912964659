import { useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input, Modal } from "reactstrap";

import { toast } from 'react-toastify';

const ModalDivisionOverride = ({showModal, toggleModal, reportName, saveReport}) => {

    const [name, setName] = useState('')

    const save = useCallback(() => {
        if(!name) return toast.error(`A report must have a name before it can be saved.`)

        saveReport(name)
    }, [name, saveReport])

    useEffect(() => {
        setName(reportName)
    }, [reportName])

    return (

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="sm"
        >

            <div className="modal-header">
                <h5 className="modal-title">Save Report</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">

                <FormGroup>
                    <label className='form-control-label'>Report Name</label>
                    <Input 
                        type="text"
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                </FormGroup>

            </div>

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
                <button className="btn btn-success" onClick={save}>
                    Save Report
                </button>
            </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(ModalDivisionOverride);