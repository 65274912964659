import { useCallback, useState } from "react";
import { connect } from 'react-redux';
import { Card } from "reactstrap";
import { formatCurrency } from "utils/currency";

import formatText from 'utils/formatText'
import api from 'api';

import TextButtonRow from 'components/markup/layout/TextButtonRow'
import Circle from 'components/markup/loading/Circle'
import ConfirmationModal from 'components/functional/modals/Confirmation'
import TableIcons from 'components/markup/icons/TableIcons'
import ModalToggler from 'components/functional/modals/Toggler'
import Table from 'components/functional/tables/Standard';
import ApiError from "components/markup/layout/ApiError";

import TimeEntriesEdit from '../components/Edit'
import { toggleStandardLoader } from "store/functions/system/system";

import { toast } from 'react-toastify'

const TimeEntriesAll = ({setTab, selected_division}) => {

    const [ loading, setLoading ] = useState(false);
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ toDelete, setToDelete ] = useState(false)
    const [ error, setError ] = useState(false);

    // helper for this.downloadCSV and this.query
    const fetchData = useCallback((params, isCSV, shouldReload) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(shouldReload) setLoading(true)
        const query = await api.time_entry_templates.search({ ...params, filter: { division: selected_division._id }, isCSV })
        if(shouldReload) setLoading(false)

        if(!query.data) return setError(query.message)
        return resolve(query.data)
    }), [sizePerPage, selected_division._id])

    // run query to get data from the server
    const query = useCallback(async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }, [fetchData])

    const onDeleteTimeEntry = useCallback(async () => {
        toggleStandardLoader(true)
        const deleted = await api.time_entry_templates.delete(toDelete._id);
        toggleStandardLoader(false)

        if(!deleted.success) toast.error(`Something's not right, please try again.`)
        fetchData();
    }, [fetchData, toDelete])
 
    const columns = [
        {
            dataField: "title",
            text: 'Title',
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )
                return (
                    <ModalToggler component={TimeEntriesEdit} _id={row._id} onSaved={() => fetchData(null, null, true)}>
                        {row.title}
                    </ModalToggler>
                )
                
            },
        },
        {
            dataField: "description",
            text: 'Description',
            formatter: (cell, row) => row.description ? formatText(row.description).stripHTML(30) : ''
        },
        {
            dataField: "cost",
            text: 'Cost',
            formatter: (cell, row) => row.cost === undefined ? '' : formatCurrency(row.cost)
        },
        {
            dataField: "cost_type",
            text: 'Cost',
            formatter: (cell, row) => {
                if(!row.cost_type) return ''
                if(row.cost_type === 'hour_rate') return 'Hourly Rate'
                if(row.cost_type === 'user_rate') return 'User Rate'
                if(row.cost_type === 'matter_rate') return 'Matter Rate'
                if(row.cost_type === 'fixed') return 'Fixed Rate'
                if(row.cost_type === 'non_billable') return <span><i className="fas fa-times text-danger mr-2" /> Non Billable</span>
                return ''
            }
        },
        {
            dataField: "seconds",
            text: 'Minutes',
            formatter: (cell, row) => {
                if(!row.seconds) return 0

                return Math.floor(row.seconds / 60)
            }
        },
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right',},
            text: 'Actions',
            headerFormatter: () => {
                return <div id="archk-tutorial-contacts-actions">Actions</div>
            },
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                         <TableIcons
                            icons={[
                                { 
                                    icon: 'fas fa-edit',
                                    color: 'success', 
                                    wrapper: ModalToggler, 
                                    component: TimeEntriesEdit,
                                    onSaved: () => fetchData(null, null, true),
                                    _id: row._id
                                },
                                {  
                                    icon: 'fas fa-trash', 
                                    color: 'danger',
                                    onClick: () => setToDelete(row)
                                },
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(error) return <ApiError err={error} />
    if(loading) return <div><Circle /></div>

    return (

        <>

            <TextButtonRow 
                title={"All Time Entries"}
                button={(
                    <button onClick={() => setTab('create')} className="btn btn-success"><i className="fas fa-plus mr-2 " /> Add New</button>
                )}
            />

            <Card className="card-color card-primary table-fixed">
                <Table
                    tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                    placeholder="Search..."
                    query={query}
                    columns={columns}
                    data={tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}
                    sizePerPage={sizePerPage}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>

            <ConfirmationModal 
                showModal={toDelete ? true : false}
                toggleModal={() => setToDelete(null)}
                title="Delete Time Entry"
                body={(
                    <span>
                        Are you sure you wish to delete the time_entry {toDelete ? toDelete.title : ''}? This will remove it from any existing tasks, events, etc.
                    </span>
                )}
                onConfirmation={onDeleteTimeEntry}
            />

        </>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(TimeEntriesAll);