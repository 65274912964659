import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input, Row, Col } from "reactstrap";

import api from 'api';

import Modalify from 'components/functional/modals/Modalify';


import { toggleStandardLoader } from 'store/functions/system/system';

import SearchCollections from 'components/system/Search/Collections';
import ReactSelect from 'components/functional/inputs/ReactSelect';
import ObjectFinder from 'components/system/Objects/Finder';
import PhoneInput from 'react-phone-input-2'
import SelectColor from 'components/markup/inputs/Colors';

import { openDocumentCenter } from 'store/functions/document_center';
import { onDownloadDocument } from 'utils/documents';
import { toast } from 'react-toastify';

import Associations from 'components/system/Associations'

const baseState = {
    users                           : [],
    supervisors                     : [],
    
    name                            : '',
    sort_type                       : 'LIFO',
    priority                        : 50,
    max_wait_time                   : 3600,

    callback                        : true,
    callback_complete_audio         : null,
    intro_audio                     : null,
    voicemail_audio                 : null,
    wait_audio                      : null,
    
    wait_exceeded_action            : 'voicemail',
    wait_exceeded_number            : '',

    service_level_seconds           : 45,
    audit_percentage                : 5,

}

const SystemCallFlowEdit = ({_id, onSaved, showModal, toggleModal, selected_division}) => {

    const [callQueue, setCallQueue] = useState(null)
    const [err, setErr] = useState(null)

    const [auditOptions, setAuditOptions] = useState([])

    const fetchData = useCallback(async () => {
        if(!_id || _id === 'create') return setCallQueue({ ...baseState });
        const user = await api.call_queues.findById(_id)
        if(!user.data) return setErr(true);
        setCallQueue(user.data)
    }, [_id])
  
    const onInputChange = useCallback(( field, value) => {
        const _callQueue = JSON.parse(JSON.stringify(callQueue))
        _callQueue[field] = value;
        setCallQueue(_callQueue);
    }, [callQueue])

    const onAddArrayItem = useCallback((field, id) => {
        const _callQueue = JSON.parse(JSON.stringify(callQueue));
        if(!_callQueue[field].includes(id) ) _callQueue[field].push(id)
        setCallQueue(_callQueue)
    }, [callQueue])
    
    const onRemoveArrayItem = useCallback((field, id) => {
        let _callQueue = JSON.parse(JSON.stringify(callQueue));
        _callQueue[field] = _callQueue[field].filter(u => u !== id);
        setCallQueue(_callQueue)
    }, [callQueue])

    const onUploadFile = (documents, property) => {
        const doc = documents ? documents[documents.length - 1 ] : ''
        if(!doc) return;
        if(doc.mimetype !== "audio/mpeg") return toast.info(`An MP3 file must be uploaded here.`)
        onInputChange(property, doc._id)
    }

    const onSave = useCallback(async () => {

        setErr(false)
        let newState = JSON.parse(JSON.stringify(callQueue));

        if(!newState.name) {
            return setErr([`A name must be specified.`])
        }
        if(!newState.sort_type) {
            return setErr([`Sort Type must be selected.`])
        }
        if(!newState.priority) {
            return setErr([`A priority must be selected.`])
        }
        
        let saved;

        newState.priority = parseInt(newState.priority)

        // toggle loaders and update/create the user
        toggleStandardLoader(true)
        if(newState._id) {
            saved = await api.call_queues.update(newState._id, newState, true);
        } else {
            saved = await api.call_queues.create({...newState, division: selected_division._id}, true);
        }

        toggleStandardLoader(false)

        // show error messages if unsuccessful
        if(saved.success) {
            toast.success('Call Queue Saved Successfully')
            fetchData();
            if(toggleModal) toggleModal();
            if(onSaved) onSaved(saved.data)
        } else {
            return setErr(saved.message);
        }
    
    }, [callQueue, fetchData, toggleModal, onSaved, selected_division._id])

    useEffect(() => {
        let values = []
        for (let i = 1; i < 101; i++) {
            values.push({label: i + '%', value: i}) 
        }

        setAuditOptions(values)
    }, [])

    useEffect(() => {
        if(toggleModal) {
            if(showModal) {
                fetchData()
            } else {
                setErr(null)
                setCallQueue(baseState)
            }
        } else {
            fetchData()
        }
    }, [showModal, toggleModal, fetchData])

    return (
        <Modalify 
            showModal={showModal}
            toggleModal={toggleModal}
            modalTitle={callQueue && callQueue._id ? "Update Call Queue" : "Create Call Queue"}
            err={err}
            footer={(
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            )}
            sections={!callQueue ? [] :  [
                (
                    <div>

                        <h2>Queue Info</h2>

                        <FormGroup>
                            <label className="form-control-label">Queue Name</label>
                            <p className='text-sm mb-0'>For internal use only</p>
                            <Input
                                type="text"
                                value={callQueue.name || ''}
                                onChange={e => onInputChange('name', e.target.value)}
                            />
                        </FormGroup>      
                      
                        <FormGroup>
                            <label className="form-control-label">Queue Entry Title</label>
                            <p className='text-sm mb-0'>This will appear on the alert to users within the queue.</p>
                            <Input
                                type="text"
                                value={callQueue.queue_entry_title || ''}
                                onChange={e => onInputChange('queue_entry_title', e.target.value)}
                            />
                        </FormGroup>    

                        <SelectColor 
                            title="Queue Entry Color"
                            value={callQueue.queue_entry_color}
                            onChange={(c) =>  onInputChange('queue_entry_color', c)}
                            menuPlacement="top"
                        />

                        
                        <FormGroup>
                            <label className="form-control-label">Queue Priority</label>
                            <p className='text-sm mb-0'>Queue priority spans across all divisions. Queues that have a lower priority will always be taken before queues with higher priority. Priority 1 before Priority 10, etc.</p>
                            <Input
                                type="number"
                                value={callQueue.priority || ''}
                                onChange={e => onInputChange('priority', e.target.value)}
                            />
                        </FormGroup>      

                        <ReactSelect 
                            formGroup={true}
                            title="Queue Sorting Order"
                            description="This determines how calls are distributed to agents."
                            value={callQueue.sort_type}
                            onChange={(obj) => onInputChange('sort_type', obj.value)}
                            options={[
                                { value: 'LIFO', label: 'LIFO (Calls Get Taken In The Order They Come In)' },
                                { value: 'FIFO', label: 'FIFO (The Person Who Most Recently Called In Will Be Taken First)' },
                            ]}
                        />

                    </div>
                ),
                (
                    <div>

                        <h2>Audits & Service Level</h2>

                        {/* service_level_seconds           : { type: Number, default: 45 },    
    audit_percentage                : { type: Number, default: 5 },     */}
                       <ReactSelect 
                            formGroup={true}
                            title="Audit Percentage"
                            description="If an audit percentage is set randomly selected calls to this queue will be pulled into a dashboard for auditing purposes."
                            value={callQueue.audit_percentage}
                            onChange={(obj) => onInputChange('audit_percentage', obj.value)}
                            options={auditOptions}
                        />     
                       
                       <ReactSelect 
                            formGroup={true}
                            title="Service Level"
                            description="The time from when a call comes in until its connected that is considered acceptable."
                            value={callQueue.service_level_seconds}
                            onChange={(obj) => onInputChange('service_level_seconds', obj.value)}
                            options={[
                                { label: 'Ignore Entirely', value: 0 },
                                { label: '30 Seconds', value: 30 },
                                { label: '45 Seconds', value: 45 },
                                { label: '1 Minute', value: 60 },
                                { label: '1.5 Minutes', value: 60 * 1.5 },
                                { label: '2 Minutes', value: 60 * 2 },
                                { label: '3 Minutes', value: 60 * 3 },
                                { label: '4 Minutes', value: 60 * 4 },
                                { label: '5 Minutes', value: 60 * 5 },
                                { label: '6 Minutes', value: 60 * 6 },
                                { label: '7 Minutes', value: 60 * 7 },
                                { label: '8 Minutes', value: 60 * 8 },
                                { label: '9 Minutes', value: 60 * 9 },
                                { label: '10 Minutes', value: 60 * 10 },
                                { label: '15 Minutes', value: 60 * 15 },
                                { label: '20 Minutes', value: 60 * 20 },
                                { label: '30 Minutes', value: 60 * 30 },
                                { label: '60 Minutes', value: 60 * 60 },
                            ]}
                        />     
                      
                    </div>
                ),
                (
                    <div>
                        <h2>Agents & Supervisors</h2>

                        <Row>
                            <Col md={6}>
                                <SearchCollections

                                    collection="users" 
                                    title="Add Queue Agent"
                                    value={null}
                                    onChange={(obj) => onAddArrayItem('users', obj.value)}
                                    filter={{
                                        division: selected_division._id
                                    }}
                                />     

                                <div className='table-responsive'>
                                    <table className='table border'>
                                        <thead>
                                            <tr>
                                                <th>Agent Name</th>
                                                <th className='text-right'>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {callQueue.users.length ? callQueue.users.map(u => (
                                                <tr key={u}>
                                                    <td><ObjectFinder collection="users" _id={u} /></td>
                                                    <td className='text-right'>
                                                        <button onClick={() => onRemoveArrayItem('users', u)} className='btn btn-danger btn-sm'>Remove</button>
                                                    </td>
                                                </tr>
                                            )) : (
                                                <tr>
                                                    <td>No Agents Have Been Added</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </Col>    
                            <Col md={6}>
                                <SearchCollections

                                    collection="users" 
                                    title="Add Queue Supervisor"
                                    value={null}
                                    onChange={(obj) => onAddArrayItem('supervisors', obj.value)}
                                    filter={{
                                        division: selected_division._id
                                    }}
                                />     

                                <div className='table-responsive'>
                                    <table className='table border'>
                                        <thead>
                                            <tr>
                                                <th>Supervisor Name</th>
                                                <th className='text-right'>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {callQueue.supervisors.length ? callQueue.supervisors.map(u => (
                                                <tr key={u}>
                                                    <td><ObjectFinder collection="users" _id={u} /></td>
                                                    <td className='text-right'>
                                                        <button onClick={() => onRemoveArrayItem('supervisors', u)} className='btn btn-danger btn-sm'>Remove</button>
                                                    </td>
                                                </tr>
                                            )) : (
                                                <tr>
                                                    <td>No Supervisors Have Been Added</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </Col>    

                        </Row>
                    </div>
                ),
                (
                    <div>
                        <h2>Audio Clips</h2>
                        <Row>
                            <Col md={8} className="align-self-center">
                                <p className='text-sm mb-0'>Queue Intro Audio - This will play when a person enters the queue.</p>
                                <p className='text-sm mb-0'>
                                    {callQueue.intro_audio ? (
                                        <b className='text-dark font-weight-bold'>
                                            <ObjectFinder collection="company_documents" _id={callQueue.intro_audio} />{' '}
                                            <i onClick={() => onInputChange('intro_audio', null)}  className="fas fa-trash ml-3 text-danger cursor-pointer" />
                                            <i onClick={() => onDownloadDocument(callQueue.intro_audio, 'company_documents')}  className="fas fa-download ml-3 text-info cursor-pointer" />
                                        </b> 
                                    ) : 
                                        <span><i className="fas fa-info-circle text-warning mr-2" /> No Audio Selected</span>
                                    }
                                </p>
                            </Col>
                
                            <Col className='text-right align-self-center'>
                                <button 
                                    onClick={() => openDocumentCenter({
                                        url: '/v1/company_documents', 
                                        onUpload: (documents) => onUploadFile(documents, 'intro_audio')}
                                    )} 
                                    className="btn btn-info"
                                >
                                    Upload Audio
                                </button>
                            </Col>
                        </Row>

                        {/* {callQueue.callback ? (
                            <div>
                                <hr />
                                <Row>
                                    <Col md={8} className="align-self-center">
                                        <p className='text-sm mb-0'>Callback Requested Audio - This will play when a person requests a callback instead of waiting on hold.</p>
                                        <p className='text-sm mb-0'>
                                            {callQueue.callback_complete_audio ? (
                                                <b className='text-dark font-weight-bold'>
                                                    <ObjectFinder collection="company_documents" _id={callQueue.callback_complete_audio} />{' '}
                                                    <i onClick={() => onInputChange('callback_complete_audio', null)}  className="fas fa-trash ml-3 text-danger cursor-pointer" />
                                                    <i onClick={() => onDownloadDocument(callQueue.callback_complete_audio, 'company_documents')}  className="fas fa-download ml-3 text-info cursor-pointer" />
                                                </b> 
                                            ) : 
                                                <span><i className="fas fa-info-circle text-warning mr-2" /> No Audio Selected</span>
                                            }
                                        </p>
                                    </Col>

                                    <Col className='text-right align-self-center'>
                                        <button 
                                            onClick={() => openDocumentCenter({
                                                url: '/v1/company_documents', 
                                                onUpload: (documents) => onUploadFile(documents, 'callback_complete_audio')}
                                            )} 
                                            className="btn btn-info"
                                        >
                                            Upload Audio
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                        ) : ''} */}

                        <hr />

                        <Row>
                            <Col md={8} className="align-self-center">
                                <p className='text-sm mb-0'>Voicemail Audio - This will play any time a voicemail is started for this queue.</p>
                                <p className='text-sm mb-0'>
                                    {callQueue.voicemail_audio ? (
                                        <b className='text-dark font-weight-bold'>
                                            <ObjectFinder collection="company_documents" _id={callQueue.voicemail_audio} />{' '}
                                            <i onClick={() => onInputChange('voicemail_audio', null)}  className="fas fa-trash ml-3 text-danger cursor-pointer" />
                                            <i onClick={() => onDownloadDocument(callQueue.voicemail_audio, 'company_documents')}  className="fas fa-download ml-3 text-info cursor-pointer" />
                                        </b> 
                                    ) : 
                                        <span><i className="fas fa-info-circle text-warning mr-2" /> No Audio Selected</span>
                                    }
                                </p>
                            </Col>
                
                            <Col className='text-right align-self-center'>
                                    <button 
                                        onClick={() => openDocumentCenter({
                                            url: '/v1/company_documents', 
                                            onUpload: (documents) => onUploadFile(documents, 'voicemail_audio')}
                                        )} 
                                        className="btn btn-info"
                                    >
                                        Upload Audio
                                    </button>
                            </Col>
                        </Row>
                      
                        <hr />

                        <Row className='pb-4'>
                            <Col md={8} className="align-self-center">
                                <p className='text-sm mb-0'>Wait Audio - This will play when a user enters the queue until they are answered.</p>
                                <p className='text-sm mb-0'>
                                    {callQueue.wait_audio ? (
                                        <b className='text-dark font-weight-bold'>
                                            <ObjectFinder collection="company_documents" _id={callQueue.wait_audio} />{' '}
                                            <i onClick={() => onInputChange('wait_audio', null)}  className="fas fa-trash ml-3 text-danger cursor-pointer" />
                                            <i onClick={() => onDownloadDocument(callQueue.wait_audio, 'company_documents')}  className="fas fa-download ml-3 text-info cursor-pointer" />
                                        </b> 
                                    ) : 
                                        <span><i className="fas fa-info-circle text-warning mr-2" /> No Audio Selected</span>
                                    }
                                </p>
                            </Col>
                
                            <Col className='text-right align-self-center'>
                                    <button 
                                        onClick={() => openDocumentCenter({
                                            url: '/v1/company_documents', 
                                            onUpload: (documents) => onUploadFile(documents, 'wait_audio')}
                                        )} 
                                        className="btn btn-info"
                                    >
                                        Upload Audio
                                    </button>
                            </Col>
                        </Row>

                    </div>
                ),
                (
                    <div>
                        <h2>Wait Times</h2>

                        <ReactSelect 
                            formGroup={true}
                            title="Max Wait Time"
                            description={`The maximum amount of time someone can be "On Hold" before their call gets routed out to a voicemail or other phone number. NOTE: this length counts the time of your Queue Intro Audio, make sure the time you set is longer than the length of your uploaded message.`}
                            value={callQueue.max_wait_time}
                            onChange={(obj) => onInputChange('max_wait_time', obj.value)}
                            options={[
                                { value: 0, label: 'Infinite' },
                                { value: 10, label: '10 Seconds' },
                                { value: 30, label: '30 Seconds' },
                                { value: 45, label: '45 Seconds' },
                                { value: 60, label: '60 Seconds' },
                                { value: 90, label: '90 Seconds' },
                                { value: 120, label: '2 Minutes' },
                                { value: 180, label: '3 Minutes' },
                                { value: 240, label: '4 Minutes' },
                                { value: 5 * 60, label: '5 Minutes' },
                                { value: 10 * 60, label: '10 Minutes' },
                                { value: 15 * 60, label: '15 Minutes' },
                                { value: 30 * 60, label: '30 Minutes' },
                                { value: 45 * 60, label: '45 Minutes' },
                                { value: 60 * 60, label: '1 Hour' },
                                { value: 75 * 60, label: '1 Hour 15 minutes' },
                                { value: 90 * 60, label: '1 Hour 30 minutes' },
                                { value: 105 * 60, label: '1 Hour 45 minutes' },
                                { value: 120 * 60 * 2, label: '2 Hours' },
                                { value: 135 * 60 * 2, label: '2 Hours 15 minutes' },
                                { value: 150 * 60 * 2, label: '2 Hours 30 minutes' },
                                { value: 165 * 60 * 2, label: '2 Hours 45 minutes' },
                                { value: 180 * 60 * 2, label: '3 Hours' },
                                { value: 195 * 60 * 2, label: '3 Hours 15 minutes' },
                                { value: 210 * 60 * 2, label: '3 Hours 30 minutes' },
                                { value: 225 * 60 * 2, label: '3 Hours 45 minutes' },
                                { value: 240 * 60 * 2, label: '4 Hours' },
                            ]}
                        />

                        {callQueue.max_wait_time ? (
                            <div>

                                <ReactSelect 
                                    formGroup={true}
                                    title="Wait Time Exceeded Action"
                                    value={callQueue.wait_exceeded_action}
                                    onChange={(obj) => onInputChange('wait_exceeded_action', obj.value)}
                                    options={[
                                        { value: 'voicemail', label: 'Leave Voicemail' },
                                        { value: 'dial_number', label: 'Dial Number ' },
                                    ]}
                                />
                                {callQueue.wait_exceeded_action === 'dial_number' ? (
                                    <FormGroup>
                                        <label className="form-control-label">Phone Number</label>
                    
                                        <PhoneInput
                                            country={'us'}
                                            value={callQueue.wait_exceeded_number || ''}
                                            onChange={phone => onInputChange('wait_exceeded_number', phone)}
                                        />
                                    </FormGroup>    
                                ) : ''}
                            </div>
                        ) : ''}
                        

                    </div>
                ),
                ...(callQueue._id ? [(
                    <Associations 
                        type="call_queue" 
                        _id={callQueue._id} 
                        classNames="border p-3 bg-secondary" 
                    />
                )] : []),
                      
            ]}
        />
    )

}




const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SystemCallFlowEdit);