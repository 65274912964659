/*
Documentation

this is the wrapping component for all auth routes
if a user is already known and logged in it will redirect
them off to their destination

if not destination is know we will redirect to /dashboard

*/

import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import routes from "../layout/routes";

class Flow extends Component {

    state = {}

    componentDidUpdate(e) {

        if (e.history.pathname !== e.location.pathname) {
            document.documentElement.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;

            if(this.refs.mainContent) {
                this.refs.mainContent.scrollTop = 0;
            }
        }

    }
    getRoutes = routes => {
        return routes.map((prop, key) => {

            return (
                <Route
                    path={`/actions${prop.path}`}
                    component={prop.component}
                    key={key}
                />
            );

        });
    };

    componentDidCatch = (e) => {

        //don't redirect if we are developing
        if(window.location.hostname !== 'localhost') {
            this.setState({shouldRedirect: `/errors/standard?error=${e}&referrerurl=${window.location.href}`})
        }

    }

    render() {

        if(this.state.shouldRedirect) {
            return <Redirect to={this.state.shouldRedirect} />
        }

        return (

        <>
            <div className="main-conten" ref="mainContent">
                <Switch>{this.getRoutes(routes)}</Switch>
            </div>
        </>
        );
    }
}

export default Flow

