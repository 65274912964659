/*
Documentation

this reducer holds all the actions we can dispatch for our global user object

*** make sure to declare all action types in actions.js one folder level back

*/

import * as actionTypes from '../../actions';

const initialState = {
    selected_division: {},
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.SET_STATE:

            return {
                ...state,
                ...action.payload
            }

        default:
            return state;

    }

}

export default reducer;
