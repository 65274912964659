import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, FormGroup, Input, Row } from "reactstrap";

import api from 'api';

import { toggleStandardLoader } from 'store/functions/system/system';
import { toast } from 'react-toastify'

import ReactSelect from 'components/functional/inputs/ReactSelect';
import Modalify from 'components/functional/modals/Modalify';
import SearchCollections from 'components/system/Search/Collections';


import Notifications from './Notifications';
import Tasks from './Tasks';
import Outcomes from './Outcomes';

import Associations from 'components/system/Associations'

const minute = 60;

const baseState = {

    location_type       : null,
    time_entry_template : null,
    event_type          : null,

    name                : '',
    description         : '',
    default_length      : minute * 15,
    conference          : false,

    location_required   : false,
    phone_call_required : false,

    send_invites        : false,

    tasks               : [],
    event_outcomes      : [],
    notifications       : []
}

const SystemEventEdit = ({_id, onSaved, showModal, toggleModal, selected_division}) => {

    const [minute] = useState(60)
    const [hour] = useState(3600)
    const [day] = useState(86400)
    const [eventTemplate, setEventTemplate] = useState(null)
    const [err, setErr] = useState(null)
    const [ eventTypes, setEventTypes ] = useState([]);

    const fetchTypes = useCallback(async () => {
        const types = await api.event_types.find()
        if(!types.data) return setErr(types.message ? types.message : true);
        setEventTypes(types.data.filter(d => d.division === selected_division._id))
    }, [selected_division._id])

    const fetchData = useCallback(async () => {
        if(!_id || _id === 'create') return setEventTemplate(baseState);
        const data = await api.event_templates.findById(_id)
        if(!data.data) return setErr(true);
        setEventTemplate(data.data)
    }, [_id])

    const onInputChange = useCallback(( field, value) => {
        const newEventTemplate = JSON.parse(JSON.stringify(eventTemplate))
        newEventTemplate[field] = value;
        setEventTemplate(newEventTemplate);
    }, [eventTemplate])
    
    const onSave = useCallback(async () => {
        setErr(false)
        let newState = JSON.parse(JSON.stringify(eventTemplate));

        if(!newState.name) {
            return setErr([`An event template must have a name.`])
        }
        if(newState.location_required && !newState.location_type) {
            return setErr([`A location type must be selected if a location is required.`])
        }
        
        let saved;

        // toggle loaders and update/create the user
        toggleStandardLoader(true)
        if(newState._id) {
            saved = await api.event_templates.update(newState._id, newState, true);
        } else {
            saved = await api.event_templates.create({...newState, division: selected_division._id}, true);
        }
        toggleStandardLoader(false)

        // show error messages if unsuccessful
        if(saved.success) {
            toast.success("Event Template Saved Successfully")
            fetchData();
            if(toggleModal) toggleModal();
            if(onSaved) onSaved(saved.data)
        } else {
            return setErr(saved.message);
        }
    
    }, [eventTemplate, fetchData, toggleModal, onSaved, selected_division._id])

    useEffect(() => {
        if(toggleModal) {
            if(showModal) {
                fetchTypes()
                fetchData()
            } else {
                setErr(null)
                setEventTemplate('')
            }
        } else {
            fetchTypes()
            fetchData()
        }
    }, [showModal, toggleModal, fetchData, fetchTypes])

    return (
        <Modalify 
            showModal={showModal}
            toggleModal={toggleModal}
            modalTitle={eventTemplate && eventTemplate._id ? "Update Event Template" : "Create Event Template"}
            err={err}
            footer={(
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            )}
            sections={!eventTemplate ? [] :  [
                (
                    <div>

                        <h2>Identifiers</h2>
        
                        <Row>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Template Name</label>
                                    <Input 
                                        type="text"
                                        value={eventTemplate.name || ''}
                                        onChange={e => onInputChange('name', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Description</label>
                                    <Input 
                                        type="text"
                                        value={eventTemplate.description || ''}
                                        onChange={e => onInputChange('description', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <ReactSelect 
                            formGroup={true}
                            title="Event Type"
                            value={eventTemplate.event_type}
                            onChange={(obj) => onInputChange('event_type', obj.value)}
                            options={eventTypes.map(type => {
                                return { value: type._id, label: type.name }
                            })}
                        />

                        <ReactSelect 
                            formGroup={true}
                            title="Default Length Of Event"
                            value={eventTemplate.default_length}
                            onChange={(obj) => onInputChange('default_length', obj.value)}
                            options={[
                                // { value: minute * 5, label: '5 Minutes' },
                                // { value: minute * 10, label: '10 Minutes' },
                                { value: minute * 15, label: '15 Minutes' },
                                { value: minute * 30, label: '30 Minutes' },
                                { value: minute * 45, label: '45 Minutes' },
                                { value: minute * 60, label: '1 Hour' },
                                { value: minute * 75, label: '1 Hour 15 Minutes' },
                                { value: minute * 90, label: '1 Hour 30 Minutes' },
                                { value: minute * 105, label: '1 Hour 45 Minutes' },
                                { value: minute * 120, label: '2 Hours' },
                                { value: minute * 150, label: '2 Hours 30 Minutes' },
                                { value: minute * 180, label: '3 Hours' },
                                { value: minute * 210, label: '3 Hours 30 Minutes' },
                                { value: minute * 240, label: '4 Hours' },
                                { value: minute * 300, label: '5 Hours' },
                                { value: minute * 360, label: '6 Hours' },
                                { value: minute * 420, label: '7 Hours' },
                                { value: minute * 480, label: '8 Hours' },
                                { value: minute * 960, label: '16 Hours' },
                                { value: minute * 1440, label: '24 Hours' },
                            ]}
                        />
                        <ReactSelect 
                            formGroup={true}
                            title="Create Conference"
                            description="If set to true will add a Google Meet or Teams Meeting to a created event. Only works on Google and Microsoft linked accounts."
                            value={eventTemplate.conference}
                            onChange={(obj) => onInputChange('conference', obj.value)}
                            options={[
                                { value: false, label: 'No' },
                                { value: true, label: 'Yes' },
                            ]}
                        />
                    </div>
                ),
                (
                    <Outcomes 
                        eventTemplate={eventTemplate}
                        setEventTemplate={setEventTemplate}
                    />
                ),
                (
                    <Notifications 
                        eventTemplate={eventTemplate}
                        setEventTemplate={setEventTemplate}
                        minute={minute}
                        hour={hour}
                        day={day}
                    />
                ),

                (
                    <Tasks 
                        eventTemplate={eventTemplate}
                        setEventTemplate={setEventTemplate}
                        minute={minute}
                        hour={hour}
                        day={day}
                    />
                ),

                (
                    <div>

                        <h2>Billing</h2>

                        <SearchCollections
                            collection="time_entry_templates" 
                            title="Time Entry Template"
                            value={eventTemplate.time_entry_template}
                            onChange={(obj) => onInputChange('time_entry_template', obj.value)}
                            filter={{
                                division: selected_division._id
                            }}
                        /> 
                                                      
                    </div>
                ),
                (
                    <div>

                        <h2>Location</h2>

                        <div>
                            <label className="custom-toggle">
                                <input onChange={e => onInputChange('location_required', !eventTemplate.location_required)} checked={eventTemplate.location_required} type="checkbox" />
                                <span
                                    className="custom-toggle-slider rounded-circle"
                                    data-label-off="No"
                                    data-label-on="Yes"
                                />
                            </label>    
                            <span style={{position: 'relative', top: -7, left: 5}}>A location is required to create an event with this template</span>
                        </div>

                        {eventTemplate.location_required ? (
                            <SearchCollections
                                collection="location_types" 
                                title="Location Type"
                                value={eventTemplate.location_type}
                                onChange={(obj) => onInputChange('location_type', obj.value)}
                                filter={{
                                    division: selected_division._id
                                }}
                            /> 
                        ) : ''}
                                                      
                    </div>
                ),
                (
                    <div>

                        <h2>Strictly For Users</h2>
                        <div>
                            <label className="custom-toggle">
                                <input onChange={e => onInputChange('originator_only', !eventTemplate.originator_only)} checked={eventTemplate.originator_only} type="checkbox" />
                                <span
                                    className="custom-toggle-slider rounded-circle"
                                    data-label-off="No"
                                    data-label-on="Yes"
                                />
                            </label>    
                            <span style={{position: 'relative', top: -7, left: 5}}>Only Only Send Invitations To Users On The Event (Will Not Invite Contacts To Event)</span>
                        </div>
                                
                      
                    </div>
                ),
                (
                    <div>

                        <h2>Require Phone Call</h2>
                        <div>
                            <label className="custom-toggle">
                                <input onChange={e => onInputChange('phone_call_required', !eventTemplate.phone_call_required)} checked={eventTemplate.phone_call_required} type="checkbox" />
                                <span
                                    className="custom-toggle-slider rounded-circle"
                                    data-label-off="No"
                                    data-label-on="Yes"
                                />
                            </label>    
                            <span style={{position: 'relative', top: -7, left: 5}}>Trigger an outbound dial to the contact when starting the event.</span>
                        </div>
                                
                      
                    </div>
                ),

                ...(eventTemplate._id ? [(
                    <Associations 
                        type="event_template" 
                        _id={eventTemplate._id} 
                        classNames="border p-3 bg-secondary" 
                    />
                )] : []),

            ]}
        />
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SystemEventEdit);