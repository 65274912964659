import Redirect        from '../pages/redirect';

const routes = () =>  [

    {
        path: "/",
        component: Redirect,
    },

   
]

export default routes;
