import { useCallback, useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { Row, Col } from "reactstrap";

import { Modal } from "reactstrap";
import { toast } from "react-toastify";

import api from 'api';

import Circle from 'components/markup/loading/Circle'
import ModalToggler from "components/functional/modals/Toggler";
import ObjectFinder from 'components/system/Objects/Finder';
import SearchCollections from 'components/system/Search/Collections';
import ReactSelect from 'components/functional/inputs/ReactSelect';
import TableIcons from 'components/markup/icons/TableIcons'
import StateInput from 'components/markup/inputs/States';


const ModalAddRule = ({showModal, toggleModal, selected_division, onAddRule, workflows, rule}) => {

    const [flowOpen, setFlowOpen] = useState(rule ? rule.flow_open :null)
    const [flowClosed, setFlowClosed] = useState(rule ? rule.flow_closed :null)
    const [customField, setCustomField] = useState(rule ? rule.workflow :null)
    const [value, setValue] = useState(rule ? rule.workflow_disposition :null)

    const [err, setErr] = useState(null)
    const [customFields, setCustomFields] = useState(null)

    const populatedField = customFields ? customFields.find(p => p._id === customField) : '';

    const onSave = useCallback(() => {
        if(!customField || ! value || !flowOpen || !flowClosed) {
            return toast.info(`A workflow, disposition and both open and closed routes must be specified to add a rule`)
        }

        onAddRule({
            custom_field    : customField,
            value           : value,
            flow_open       : flowOpen,
            flow_closed     : flowClosed,
        })

        toggleModal()
    }, [customField, value, flowOpen, flowClosed, onAddRule, toggleModal])

    const onSetCustomField = useCallback((field) => {
        setCustomField(field)
        setValue('')
    }, [])

    const fetchCustomFields = useCallback(async () => {
        const _customFields = await api.custom_fields.find(selected_division._id);
        if(!_customFields.data) return setErr("Please reload the page");
        const filtered = _customFields.data.filter(d => d.type === 'select' || d.type === 'state' || d.type === 'yes-no')
        setCustomFields(filtered)
    }, [selected_division._id])

    useEffect(() => {
        fetchCustomFields()
    }, [fetchCustomFields])

    useEffect(() => {
        if(showModal && rule) {
            setCustomField(rule.custom_field)
            setFlowOpen(rule.flow_open)
            setFlowClosed(rule.flow_closed)
            setValue(rule.value)
        }
    }, [rule, showModal])

    return (

        <Modal
           className="modal-dialog-centered"
           isOpen={showModal}
           toggle={toggleModal}
           size="md"
        >

           <div className="modal-header">
               <h5 className="modal-title">Add A Custom Field Rule</h5>
               <button
                   aria-label="Close"
                   className="close"
                   data-dismiss="modal"
                   type="button"
                   onClick={toggleModal}
                >
                   <span aria-hidden={true}>×</span>
               </button>
           </div>

           <div className="modal-body">
                {err ? "Something's not right, please refresh your page" : !customFields ? <Circle /> : (
                        <div>
                             <ReactSelect 
                                formGroup={true}
                                title="Select A Custom Field"
                                value={customField}
                                onChange={(obj) => onSetCustomField(obj.value)}
                                options={customFields.map(w => {
                                    return { value: w._id, label: w.name }
                                })}
                            />

                            {populatedField ? (

                                    <div>
                                        {populatedField.type === 'select' ? (
                                            <ReactSelect 
                                                formGroup={true}
                                                title="Select A Value To Flag"
                                                value={value}
                                                onChange={(obj) => setValue(obj.value)}
                                                options={populatedField.answers.map(a => {
                                                    return { label: a, value: a }
                                                })}
                                            />
                                        ) : populatedField.type === 'state' ? (
                                            <StateInput 
                                                title="Select State"
                                                value={value}
                                                placeholder=" "
                                                onChange={(state) => setValue(state)}
                                            />
                                        ) : (
                                            <ReactSelect 
                                                formGroup={true}
                                                title="Select A Value To Flag"
                                                value={value}
                                                onChange={(obj) => setValue(obj.value)}
                                                options={[{label: 'Yes', value: 'yes'}, { label: 'No', value: 'no' }]}
                                            />
                                        ) }

                                        

                                        {value ? (
                                            <div>
                                                <SearchCollections
                                                    collection="call_flows" 
                                                    title="Redirect To Flow When OPEN"
                                                    value={flowOpen}
                                                    onChange={(obj) => setFlowOpen(obj.value)}
                                                    filter={{
                                                        division: selected_division._id
                                                    }}
                                                /> 
                                                <SearchCollections
                                                    collection="call_flows" 
                                                    title="Redirect To Flow When CLOSED"
                                                    value={flowClosed}
                                                    onChange={(obj) => setFlowClosed(obj.value)}
                                                    filter={{
                                                        division: selected_division._id
                                                    }}
                                                /> 
                                            </div>
                                    ) : ''}
                                    </div>

                            ) : ''}

                        </div>
                    )}
           </div>

           <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
                <button className="btn btn-success" onClick={onSave}>
                    Save Rule
                </button>
           </div>

        </Modal>

    )
}

const SystemCallFlowEdit = ({callFlow, onInputChange, selected_division, workflows}) => {

    // const [categories, setCategories] = useState(callFlow.custom_fields)
    const custom_fields = callFlow.custom_field_routing

    const onAddRule = useCallback((rule) => {
        const _custom_fields = JSON.parse(JSON.stringify(custom_fields))
        const foundIndex = _custom_fields.findIndex(i => i.custom_field === rule.custom_field)

        if(foundIndex !== -1) {
            _custom_fields[foundIndex] = rule
        } else {
            _custom_fields.push(rule)
        }
        onInputChange('custom_field_routing' ,_custom_fields)
    }, [custom_fields, onInputChange])

    const onRemoveRule = useCallback((index) => {
        const _custom_fields = JSON.parse(JSON.stringify(custom_fields))
        _custom_fields.splice(index, 1)
        onInputChange('custom_field_routing', _custom_fields);
    }, [custom_fields, onInputChange])

    return (
        <div>

            <Row>
                <Col md={6} className="align-self-center">
                    <h2 className="mb-0">Custom Field Routing</h2>
                </Col>
                <Col md={6} className="align-self-center text-right">
                    <ModalToggler component={ModalAddRule} onAddRule={onAddRule} selected_division={selected_division} workflows={workflows}>
                        <button className="btn btn-sm btn-info"><i className="fas fa-plus mr-2" /> Add Rule</button>
                    </ModalToggler>
                </Col>
            </Row>

            <div className="table-responsive mb-4">
                <table className="table border mt-3">
                    <thead>
                        <tr>
                            <th>Custom Field</th>
                            <th>Value</th>
                            <th>Flow When Open</th>
                            <th>Flow When Closed</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {custom_fields.length ? custom_fields.map((d, i) => (
                            <tr>
                                <td>{d.custom_field.name ? d.custom_field.name : <ObjectFinder collection="custom_fields" _id={d.custom_field} />}</td>
                                <td>{d.value}</td>
                                <td>{d.flow_open.name ? d.flow_open.name : <ObjectFinder collection="call_flows" _id={d.flow_open} />}</td>
                                <td>{d.flow_closed.name ? d.flow_closed.name : <ObjectFinder collection="call_flows" _id={d.flow_closed} />}</td>
                                <td className="text-right" >
                                    <TableIcons
                                        icons={[
                                            {  
                                                icon: 'fas fa-edit', 
                                                color: 'success',
                                                wrapper: ModalToggler, 
                                                component: ModalAddRule, 
                                                selected_division, 
                                                onAddRule, 
                                                workflows,
                                                rule: d
                                            },
                                            {  
                                                icon: 'fas fa-trash', 
                                                color: 'danger',
                                                onClick: () => onRemoveRule(i)
                                            },

                                        ]}
                                    />
                                </td>
                            </tr>
                        )) : (
                            <tr>
                                <td><i className="fas fa-info-circle mr-2 text-info" /> No rules have been created.</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

        </div>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SystemCallFlowEdit);