/*
Documentation

this function needs to run when the app loads
when it is finished it returns true if all went well or false if it did not

*/

import { load } from 'store/functions/load/load';
import { setViewingUser } from 'store/functions/auth/auth';
import { setDeviceListener } from 'store/functions/device/device'
import { initWorker } from 'utils/pdf/controller'
import { getLoginUrl } from 'utils/helpers'
import {Chart, registerables} from "chart.js";
import createDefaultOptions from 'components/charts/_functions/createDefaultOptions'
import { setSocket } from 'store/functions/socket'
import socket from 'socket.io-client';


const init = () => new Promise (async resolve => {

    // prevent accidental dropping of files into the system
    window.addEventListener("dragover",function(e){ e.preventDefault(); },false);
    window.addEventListener("drop",function(e){ e.preventDefault(); },false);
    // close all user tabs on logout

    // listen for changes in screen size, set device, etc
    setDeviceListener()

    const tryLoad = async (tries = 0) => {
        if(window.location.href.includes('/errors/')) return resolve(true);

        //run all promises that need to fire before the app loads here
        const values = await Promise.all([
            setViewingUser(),
            load(),
        ])

        const user = values[0];

        if(!user.success) {
            if(tries > 2) return resolve(false);
            return setTimeout(() => tryLoad(tries + 1), 2000);
        }

        if(!user.data) return window.location.href = getLoginUrl();
        if(!user.data.privileges.includes('VIEW.CAN_VIEW_ADMIN_DASHBOARD')) return resolve('unauthorized');

        window.addEventListener('focus', async () => {
            const user = await setViewingUser();
            if(!user.data) window.location.href = getLoginUrl();
        })

        // joins company and user removes upon start
        const websocket = socket(values[1].data.DOMAINS.SOCKETS, {
            transports: ['websocket'],
            autoConnect: true
        });
        setSocket(websocket)

        websocket.on("connect_error", (err) => {
            console.log(`connect_error due to ${err.message}`);
          });

        // on reconnect join back to the company and user rooms
        websocket.on("reconnect", () => {
            websocket.emit('JOIN_COMPANY_ROOM')
        });


        initWorker();


        Chart.register(...registerables);
        if (window.Chart) createDefaultOptions(Chart);

        return resolve(true);

    }

    tryLoad();

})

export default init