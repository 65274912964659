import { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { Modal, FormGroup, Input } from "reactstrap";
import { useCallback } from 'react';

import { setAssociationErrors } from 'store/functions/system/system';

const ModalDivisionOverride = ({association_errors}) => {

    const toggleModal = () => {
        setAssociationErrors()
    }
    const showModal = association_errors && association_errors.length ? true : false;

    return (

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">Association Error</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">
                <p className="text-sm mb-0">The Following Entries Are Currently Using The Item You Tried To Delete. <b className='text-dark'>To Continue You Must Unlink The Associations For The Below:</b> </p>
            </div>

            <div className="modal-body bg-secondary border-top">
                <ol className='pl-5 mb-0'>
                    {association_errors.map(a => (
                        <li key={a} className='text-capitalize text-sm'>
                            {a.type.replace(/_/g, ' ')} - {a.name}
                        </li>
                    ))}
                </ol>
            </div>

            {association_errors.length > 20 ? (
                <div className="modal-body">
                    <p className="text-sm mb-0 text-muted"><small>**Note the above list may be truncated and has the possibility to be larger than the result set shown.</small></p>
                </div>
            ) : ''}
            

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
            </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        association_errors: state.system.association_errors,
    };
};

export default connect(mapStateToProps, '')(ModalDivisionOverride);