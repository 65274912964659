import { connect } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import api from 'api';

import Circle from 'components/markup/loading/Circle'

const WorkflowRolesSelection = ({ selected_division, onChange, roles = [], useNullOption, multiSelect }) => {

    const [err, setErr] = useState(null)

    const [workflows, setWorkflows] = useState(null)
    const [workflowRoles, setWorkflowRoles] = useState(null)

    const isChecked = (_workflow, role_id) => {
        if(multiSelect) {
            const found = roles.find(r => r.workflow_role === role_id)
            return found ? true : false
        } else {
            const found = roles.find(r => r.workflow === _workflow)
            // return true
            if(found && found.workflow_role === role_id) return true;
            if(!found && role_id === null) return true
            return false
        }
       
    }

    const fetchWorkflows = useCallback(async () => {
        const query = await api.workflows.find()
        if(!query.success) return setErr(true)

        const _workflows = query.data.filter(workflow => workflow.division === selected_division._id)
        setWorkflows(_workflows)

        let counter = 0;
        let results = []

        _workflows.forEach(async w => {

            const data = await api.workflow_roles.find(w._id);
            if(data.data) results = results.concat(data.data)
            counter++;
            if(counter === _workflows.length) {

                setWorkflowRoles(results)
            }
        })

    }, [selected_division._id,])

    useEffect(() => {
        fetchWorkflows()
    }, [fetchWorkflows])


    if(err) return <div className='alert alert-danger'>An Unexpected Error Has Occurred, Please Refresh Your Page</div>
    if(!workflows || !workflowRoles) return <Circle />

    return (
        <div>

            {workflows && workflows.length ? workflows.map(workflow => (
                <form key={workflow._id}>
                    <h4>Workflow: {workflow.name}</h4>

                    {useNullOption && !multiSelect  ? (
                        <div className="custom-control custom-radio mb-3">
                            <input
                                className="custom-control-input"
                                id={`archk-workflow-role-email-sender-${workflow._id}`}
                                name="customRadio"
                                type="radio"
                                checked={isChecked(workflow._id, null)}
                                onChange={e => {
                                    let _roles = JSON.parse(JSON.stringify(roles))
                                    const roleIndex = _roles.findIndex(r => r.workflow === workflow._id)

                                    if(roleIndex === -1)  {
                                        _roles.push({
                                            workflow: workflow._id,
                                            workflow_role: null
                                        })
                                    } else {
                                        _roles[roleIndex].workflow_role = null
                                    }
                                
                                    onChange(_roles)
                                }}
                            />
                            <label className="custom-control-label" htmlFor={`archk-workflow-role-email-sender-${workflow._id}`}>
                                **Email Sender
                            </label>
                        </div>
                    ) : ''}
                    
                    
                    {workflowRoles.map(role => role.workflow === workflow._id ? (
                        multiSelect ? (
                            <div key={role._id + isChecked(workflow._id, role._id)} className="custom-control custom-checkbox mb-3">
                                <input
                                    className="custom-control-input"
                                    id={`archk-workflow-cc-${role._id}`}

                                    checked={isChecked(workflow._id, role._id)}
                                    type="checkbox"
                                    onChange={e => {
                                        let _roles = JSON.parse(JSON.stringify(roles))
                                        const roleIndex = _roles.findIndex(r => r.workflow === workflow._id && r.workflow_role === role._id)

                                        if(roleIndex === -1)  {
                                            _roles.push({
                                                workflow: workflow._id,
                                                workflow_role: role._id
                                            })
                                        } else {
                                            _roles = _roles.filter(r => r.workflow_role !== role._id)
                                        }
                                    
                                        onChange(_roles)
                                    }}
                                />
                                <label className="custom-control-label" htmlFor={`archk-workflow-cc-${role._id}`}>
                                    {role.name}
                                </label>
                            </div>
                        ) : (
                            <div key={role._id + isChecked(workflow._id, role._id)} className="custom-control custom-radio mb-3">
                                <input
                                    className="custom-control-input"
                                    id={`archk-workflow-role-${role._id}`}
                                    name="customRadio"
                                    checked={isChecked(workflow._id, role._id)}
                                    type="radio"
                                    onChange={e => {
                                        let _roles = JSON.parse(JSON.stringify(roles))
                                        const roleIndex = _roles.findIndex(r => r.workflow === workflow._id)

                                        if(roleIndex === -1)  {
                                            _roles.push({
                                                workflow: workflow._id,
                                                workflow_role: role._id
                                            })
                                        } else {
                                            _roles[roleIndex].workflow_role = role._id
                                        }
                                    
                                        onChange(_roles)
                                    }}
                                />
                                <label className="custom-control-label" htmlFor={`archk-workflow-role-${role._id}`}>
                                    {role.name}
                                </label>
                            </div>
                        )
                        
                    ) : '')}
                </form>
            )) : (
                <p>No Workflows Found</p>
            )}
        </div>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(WorkflowRolesSelection);