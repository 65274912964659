/*
Documentation

creates a visual representation of moving through steps to do something

*/

import React from 'react'
import PropTypes from 'prop-types';

const stepTracker = (props) => {

    const { current, steps, onChangeStep } = props;

    const canChangeStep = onChangeStep && typeof onChangeStep === 'function'

    return (
        <div className="archk-step-tracker">
            <ul className="tracker">

                {steps.map((s, i) => (
                    <li key={i} className={current > i + 1 ? 'finished' : current === i + 1 ? ' current ' : null}>
                        <span
                            className={canChangeStep ? "cursor-pointer wrapper" : "wrapper" }
                            onClick={() => {
                                if(canChangeStep) {
                                    onChangeStep(i + 1)
                                }
                            }
                        }>
                            <span className="done"><i className="fas fa-check" /></span>
                            <span className="not-done">{i + 1}</span>
                            <span className="connection" />
                            <span className="finished-connection" />
                        </span>
                        <p>{s}</p>
                    </li>
                ))}

            </ul>
        </div>
    );

}

stepTracker.propTypes = {
    //the step to show, starts at 1
    current: PropTypes.number.isRequired,
    // array of step names
    steps: PropTypes.array.isRequired,
    //what to do if a step i clicked, returns the step clicked
    onChangeStep: PropTypes.func,
}

export default stepTracker;
