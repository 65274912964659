const ActionCompleteOutstandingItems = () => (
    <div>

        <div className='sidebar-section border-bottom'>
            <p className='text-sm'>
                <i className="fas fa-info-circle text-info" /> This action will mark all outstanding items on a matter as complete. Because outstanding items are free form in their nature this is an all or nothing operation. 
            </p>
            <p className='text-sm mb-0'>
                If you wish to mark only a subset of the items on a matter complete you must run and assistant to do so. 
            </p>
        </div>

    </div>
)

export default ActionCompleteOutstandingItems