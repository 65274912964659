/*
Documentation

this page holds all the routes for the dashboard layout

*/

import ErrorStandard from '../pages/errors/Standard';
import ErrorThreatDetected from '../pages/errors/ThreatDetected';

const routes = [

    {
        component: ErrorStandard,
        show_in_sidebar: true,
        path: "/standard",
    },

    {
        component: ErrorThreatDetected,
        show_in_sidebar: true,
        path: "/threat",
    },

]

export default routes;