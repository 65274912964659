import { Row, Col } from "reactstrap";
import { connect } from 'react-redux';
import { useCallback, useState } from 'react';

import Privilege  from "./Privilege";

const UsersEditPrivilegesStandard = ({user, setUser, onPrivilegeChange, PRIVILEGES}) => {

    const [functionPrivileges] = useState([
        PRIVILEGES['SECURITY.CAN_VIEW_ALL_MATTERS'],
        PRIVILEGES['SECURITY.CAN_VIEW_ALL_LEAD_SOURCES'],
    ])

    const assignAll = useCallback(() => {
        const newUser = Object.assign({}, user)
        functionPrivileges.forEach(privilege => {
            if(!newUser.privileges.includes(privilege)) {
                newUser.privileges.push(privilege)
            }
        })
     
        setUser(newUser);
    }, [functionPrivileges, setUser, user])
   
    const removeAll = useCallback(() => {
        const newUser = Object.assign({}, user)
        functionPrivileges.forEach(privilege => {
            newUser.privileges = newUser.privileges.filter(p => p !== privilege)
        })
     
        setUser(newUser);
    }, [functionPrivileges, setUser, user])

    return (
        <div>

            <Row>
                <Col xs={6} className="align-self-center">
                    <h2>Security Privileges</h2>
                </Col>
                <Col xs={6} className="align-self-center text-right">
                    <button onClick={removeAll} className="btn btn-outline-warning">Remove All</button>
                    <button onClick={assignAll} className="btn btn-outline-info">Assign All</button>
                </Col>
            </Row>

            <Row>
                <Col lg={6}>
                    <h5>Matters</h5>
                    <Privilege 
                        privilege={PRIVILEGES['SECURITY.CAN_VIEW_ALL_MATTERS']}
                        user={user}
                        title="Can View All Matters"
                        onPrivilegeChange={onPrivilegeChange}
                        description="If enabled, this user will be able to view all matters inside their divisions and not just ones where they are assigned to the file."
                    />
                    <h5>Lead Sources</h5>
                    <Privilege 
                        privilege={PRIVILEGES['SECURITY.CAN_VIEW_ALL_LEAD_SOURCES']}
                        user={user}
                        title="Can View All Lead Sources"
                        onPrivilegeChange={onPrivilegeChange}
                        description="If enabled, this user will be able to all leads and their sources not just the ones assigned to them at the division level."
                    />


                </Col>
            </Row>

        </div>
    )

}


const mapStateToProps = state => {
	return {
	    PRIVILEGES: state.config.PRIVILEGES,
	};
};

export default connect(mapStateToProps, '')(UsersEditPrivilegesStandard);