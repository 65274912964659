import MatterDocumentsEdit from './components/Edit'
import MatterDocumentsAll from './All'

import IntuideskSectionWrapper from 'components/markup/layout/IntuideskSectionWrapper'

const SystemMatterDocuments = ({match, section}) => (
    <IntuideskSectionWrapper 
        title="Matter Docs"
        editTitle="Matter Document"
        match={match}
        section={section}
        innerSection={'matter_documents'}
        Edit={MatterDocumentsEdit}
        All={MatterDocumentsAll}
    />
)

export default SystemMatterDocuments;