import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';

import ObjectFinder from 'components/system/Objects/Finder';

export default memo(({ id, data, isConnectable }) => {
    console.log(data.user)
  return (
    <>
        <Handle
            id="left"
            type="target"
            position={Position.Left}
            style={{ top: 15 }}
            isConnectable={isConnectable}
        />

        <div className="">
            <div className='node-header'>
                <i className="fa-solid fa-pause text-danger mr-2" /> Wait For Approval
            </div>
           
            <div className='node-body'>

                {data.user ? (
                    <div className='node-item text-capitalize'><ObjectFinder collection="users" _id={data.user} /></div>
                ) : data.workflow_roles && data.workflow_roles ? data.workflow_roles.map((role, i) => (
                    <div className='node-item text-capitalize' key={i}><ObjectFinder collection="workflow_roles" _id={role.workflow_role} /></div>
                )) : (
                    <div className='node-item text-capitalize'><span className='text-danger'>User/Role Not Set</span></div>
                )}

            </div>

            <div className='node-footer'>
                <div><small>Execution ID:{id}</small></div>
            </div>

        </div>

        <Handle
            id="right"
            type="source"
            position={Position.Right}
            style={{ top: 10 }}
            isConnectable={isConnectable}
        />
        </>
  );
});