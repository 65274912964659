import LocationsEdit from './components/Edit'
import LocationsAll from './All'

import IntuideskSectionWrapper from 'components/markup/layout/IntuideskSectionWrapper'

const SystemLocations = ({match, section}) => (
    <IntuideskSectionWrapper 
        title="Time Entries"
        editTitle="Time Entry "
        match={match}
        section={section}
        innerSection={'time_entries'}
        Edit={LocationsEdit}
        All={LocationsAll}
    />
)

export default SystemLocations;