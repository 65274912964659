import { connect } from 'react-redux';
import { useCallback, useState, useEffect } from 'react';

import api from 'api'

import Modalify from 'components/functional/modals/Modalify'

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';
import { setCompany as refreshCompany } from 'store/functions/company/company'

import TimeZone from 'components/settings/TimeZone'
import DayAndTimeRange from 'components/settings/DayAndTimeRange'

const CompanyUpdateAvailability = ({onSaved, showModal, toggleModal, viewing_user}) => {

    const [company, setCompany] = useState(null)
    const [err, setErr] = useState(null)

    const fetchData = useCallback(async () => {
        const company = await api.companies.findById(viewing_user.company)
        if(!company.data) return setErr(true);
        setCompany(company.data)
    }, [viewing_user.company])

    const onInputChange = useCallback(( field, value) => {
        const newCompany = Object.assign({}, company)
        newCompany[field] = value;
        setCompany(newCompany);
    }, [company])

    const onSave = useCallback(async () => {
        setErr(false)
        let newState = Object.assign({}, company);

        if(!newState.name) {
            return setErr([`A company must have a name.`])
        }

        setCompany(newState)

        // toggle loaders and update/create the company
        toggleStandardLoader(true)
        let saved = await api.companies.update(newState._id, {
            timezone    : newState.timezone,
            times_open  : newState.times_open,
        }, true);
        toggleStandardLoader(false)

        // show error messages if unsuccessful
        if(saved.success) {
            toggleAlertBS(false, "Company Saved Successfully")
            fetchData();
            refreshCompany();
            if(toggleModal) toggleModal();
            if(onSaved) onSaved(saved.data)
        } else {
            return setErr(saved.message);
        }
    
    }, [company, fetchData, toggleModal, onSaved])

    useEffect(() => {
        if(toggleModal) {
            if(showModal) {
                fetchData()
            } else {
                setErr(null)
                setCompany('')
            }
        } else {
            fetchData()
        }
    }, [showModal, toggleModal, fetchData])

    return (
        <Modalify 
            showModal={showModal}
            toggleModal={toggleModal}
            modalTitle={company && company._id ? "Update Company" : "Create Company"}
            err={err}
            footer={(
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            )}
            sections={!company ? [] :  [
                (
                    <div>

                        <h2>Default Company Availability</h2>
                        <TimeZone 
                            timezone={company.timezone}
                            setTimezone={(value) => onInputChange('timezone', value)}
                        />    

                        <h2>Standard Business Hours</h2>
                        <DayAndTimeRange 
                            times={company.times_open}
                            setTimes={(value) => onInputChange('times_open', value)}
                        />       

                    </div>
                )
            ]}
        />
    )

}


const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(CompanyUpdateAvailability);