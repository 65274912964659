import { useCallback, useState, useEffect } from 'react'

import Sidebar from './Sidebar'
import Main from './Main'
import { toggleAlertBS } from 'store/functions/system/system';

import api from 'api'

import Circle from 'components/markup/loading/Circle'

const WorkflowsEdit = ({workflow, onSetWorkflow, onSetUnsavedChanges}) => {

    const [workflowCopy, setWorkflowCopy] = useState(JSON.parse(JSON.stringify(workflow)));
    const [err, setErr] = useState(false);
    const [step, setStep] = useState(false);
    const [steps, setSteps] = useState(false);
    const [roles, setRoles] = useState(false);
    const [folders, setFolders] = useState(false);
    const [contacts, setContacts] = useState(false);
    const [locations, setLocations] = useState(false);
    const [stepCategories, setStepCategories] = useState(false);
    const [dispositions, setStepDispositions] = useState(false);

    const onSetStep = useCallback((_step) => {
        if(_step) {
            document.body.classList.add('noScroll')
            setStep(_step)
        } else {
            document.body.classList.remove('noScroll')
            setStep(_step)
        }
    }, [setStep])

    const onSave = useCallback(() => {
        onSetWorkflow(workflowCopy)
    }, [workflowCopy, onSetWorkflow])

    const findStep = useCallback((step) => {
        if(!step._id && !step.id) return steps.find(_step => (_step.id && _step.id === step) || _step._id === step);
        return step;
    }, [steps])

    const onAddStep = useCallback(async (step, currentStep) => {
        currentStep = currentStep ? JSON.parse(JSON.stringify(findStep(currentStep))) : null;
        step = JSON.parse(JSON.stringify(step))

        let stepToEdit = currentStep;

        if(currentStep) {
            currentStep.next_steps.push(step._id ? step._id : step.id)
        } else {
            step.is_first = true
            stepToEdit = step;
        }

        const foundIndex = steps.findIndex(s => (s._id && s._id === stepToEdit._id) || (s.id && s.id === stepToEdit.id))
     
        if(foundIndex !== -1) {

            if(stepToEdit._id === step._id && !step.is_first) return toggleAlertBS('info', 'A step cannot move back to itself');
            const _steps = JSON.parse(JSON.stringify(steps));
            _steps[foundIndex] = stepToEdit

            const created = await api.workflow_steps.update(stepToEdit._id, stepToEdit)
            setSteps(_steps)
        }
    }, [findStep, steps, setSteps])

    const onRemoveStep = useCallback(async (step, currentStep) => {
        let isFirst = false;
        if(!currentStep)  {
            currentStep = steps.find(s => s.is_first === true);
            isFirst = true
        }
        if(!currentStep) return console.log('step not found - onRemoveStep')
        
        currentStep = JSON.parse(JSON.stringify(findStep(currentStep)));
        step = JSON.parse(JSON.stringify(step))

        currentStep.next_steps = currentStep.next_steps.filter(s => s !== step._id)
        const foundIndex = steps.findIndex(s => s._id === currentStep._id)

        if(foundIndex !== -1) {
            const _steps = JSON.parse(JSON.stringify(steps));
            if(isFirst) currentStep.is_first = false;
            _steps[foundIndex] = currentStep
            setSteps(_steps)
            const updated = await api.workflow_steps.update(currentStep._id, currentStep)
        }
    }, [findStep, steps, setSteps])

    const fetchData = useCallback(async () => {
        const values = await Promise.all([
            api.workflow_steps.find(workflow._id),
            api.workflow_roles.find(workflow._id),
            api.workflow_folders.find(workflow._id),
            api.workflow_contacts.find(workflow._id),
            api.workflow_locations.find(workflow._id),
            api.workflow_step_categories.find(workflow._id),
            api.workflow_dispositions.find(workflow._id),
        ])

        if(values.find(v => v.success !== true)) return setErr(true)

        setSteps(values[0].data);
        setRoles(values[1].data);
        setFolders(values[2].data);
        setContacts(values[3].data);
        setLocations(values[4].data);
        setStepCategories(values[5].data);
        setStepDispositions(values[6].data);
      
    }, [workflow._id])

    useEffect(() => {
        const header = document.querySelector('.archk-workflows-header');
        fetchData()
        if(header) {
            header.classList.add('fixed')
            return () => {
                header.classList.remove('fixed')
            }
        }
    }, [fetchData])
    
    if(err) return (
        <div className='pt-9'>
            <p className='text-sm text-center'>
                <i className="fas fa-exclamation-triangle text-danger mr-2" /> An Error Occurred, Try Refreshing Your Page
            </p>
        </div>
    )

    if(!steps) return <div className='pt-9'><Circle className="mt-9" /></div>

    return (
        <div className="archk-workflow">

            <button 
                onClick={onSave} 
                className="archk-workflow-save btn btn-success"
            >
                <i className="fas fa-save mr-2 " /> Save Workflow
            </button>
           
            <Sidebar 
                step={step}
                setStep={onSetStep}
                steps={steps}
                setSteps={setSteps}
                workflowCopy={workflowCopy}
                roles={roles}
                folders={folders}
                contacts={contacts}
                locations={locations}
                stepCategories={stepCategories}
                dispositions={dispositions}
            />

            <Main 
                steps={steps}
                setStep={onSetStep}
                setSteps={setSteps}
                onAddStep={onAddStep}
                onRemoveStep={onRemoveStep}
                findStep={findStep}
            />

        </div>
    )
}

export default WorkflowsEdit;