import { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import SearchCollections from 'components/system/Search/Collections';
import ReactSelect from 'components/functional/inputs/ReactSelect';
import PhoneInput from 'react-phone-input-2'

import Circle from 'components/markup/loading/Circle';

import { FormGroup } from 'reactstrap';

import api from 'api'

const RolesSelection = ({workflow, roleGrouping, onChange, roles, type}) => {

    const isChecked = (role_id) => {
        const found = roleGrouping ? roleGrouping.find(r => r.workflow_role === role_id) : false
        return found ? true : false
    }

    const onInputChange = useCallback((role) => {
        let _roleGrouping = roleGrouping ? JSON.parse(JSON.stringify(roleGrouping)) : []
        const roleIndex = _roleGrouping.findIndex(r => r.workflow === workflow._id && r.workflow_role === role._id)

        if(roleIndex === -1)  {
            _roleGrouping.push({
                workflow: workflow._id,
                workflow_role: role._id
            })
        } else {
            _roleGrouping = _roleGrouping.filter(r => r.workflow_role !== role._id)
        }

        onChange(type, _roleGrouping)
    }, [onChange, type, workflow._id, roleGrouping])

    const filteredRoles = roles ? roles.filter(r => r.workflow === workflow._id) : ''

    return (
        <form key={workflow._id} className="mb-4">
            <h4>Workflow: {workflow.name}</h4>

            {!roles ? <Circle /> : filteredRoles.map(role => (
                <div key={role._id + isChecked(role._id)} className="custom-control custom-checkbox mb-3">
                    <input
                        id={`archk-workflow-${type}-${role._id}`}
                        className="custom-control-input"
                        checked={isChecked(role._id)}
                        type="checkbox"
                        onChange={() => onInputChange(role)}
                    />
                    <label className="custom-control-label" htmlFor={`archk-workflow-${type}-${role._id}`}>
                        {role.name}
                    </label>
                </div>
            ))}
        </form>
    )
    
}

const SystemCallFlowEdit = ({task, onSetTask, workflows, selected_division}) => {

    const [roles, setRoles] = useState(null);

    const roles_dial = task.roles_dial
    const no_answer_roles_voicemail = task.no_answer_roles_voicemail

    const onChange = useCallback((type, array) => {
        onSetTask(type, array)
    }, [onSetTask])
    

    const fetchData = useCallback(() => {
        let counter = 0;
        let results = [];

        workflows.forEach(async w => {
            const data = await api.workflow_roles.find(w._id)
            if(data.data) results = results.concat(data.data);

            counter++
            if(counter === workflows.length) setRoles(results)
        })
    }, [workflows])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <div className='mb-4'>
                    
            <div className='bg-secondary p-3 border'>
                <p className='text-sm mb-0'><i className="fas fa-info-circle text-info mr-2" /> You have selected the Dial Roles option. Here you can add specific workflow roles who will all be dialed at the same time, first one to pickup will get the call. Note a maximum of 10 users will be dialed and only roles matching on the case found will be contenders for the call. If no role is found the call will immediately get put into the no answer workflow</p>
            </div>

            <hr />

            {workflows.map(workflow => (
                <RolesSelection 
                    type="roles_dial"
                    workflow={workflow}
                    roleGrouping={roles_dial} 
                    onChange={onChange} 
                    roles={roles} 
                />
            ))}

            <hr />

            <ReactSelect 
                formGroup={true}
                title="No Answer Routing"
                description={`Should the called parties not pickup what should happen to the call?`}
                value={task.no_answer_type}
                onChange={(obj) => onSetTask('no_answer_type', obj.value)}
                options={[
                    { value: 'voicemail_queue', label: 'Leave Voicemail - Queue' },
                    { value: 'voicemail_roles', label: 'Leave Voicemail - Roles' },
                    { value: 'outbound_dial', label: 'Dial Number ' },
                    { value: 'route_to_queue', label: 'Route To Queue ' },
                ]}
            />

            {task.no_answer_type === 'voicemail_roles' ? (
                <div>
                    {workflows.map(workflow => (
                        <RolesSelection 
                            type="no_answer_roles_voicemail"
                            workflow={workflow}
                            roleGrouping={no_answer_roles_voicemail} 
                            onChange={onChange} 
                            roles={roles} 
                        />
                    ))} 
                </div>
            ) : task.no_answer_type === 'voicemail_queue' ? (
                <SearchCollections
                    collection="call_queues" 
                    title={`Select The Roles This Voicemail Will Be Assigned To`}
                    value={task.no_answer_call_queue_voicemail}
                    onChange={(obj) => onSetTask(`no_answer_call_queue_voicemail`, obj.value)}
                    filter={{
                        division: selected_division._id
                    }}
                /> 
            ) : task.no_answer_type === 'outbound_dial' ? (
                <FormGroup>
                    <label className="form-control-label">Phone Number</label>

                    <PhoneInput
                        country={'us'}
                        value={task.no_answer_phone_number || ''}
                        onChange={phone => onSetTask('no_answer_phone_number', phone)}
                    />
                </FormGroup>    
            ) : task.no_answer_type === 'route_to_queue' ? (
                <SearchCollections
                    collection="call_queues" 
                    title={`Select Queue`}
                    value={task.no_answer_call_queue_dial}
                    onChange={(obj) => onSetTask(`no_answer_call_queue_dial`, obj.value)}
                    filter={{
                        division: selected_division._id
                    }}
                /> 
            ) : ''}

        </div>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SystemCallFlowEdit);
