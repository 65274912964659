import * as actionTypes from '../../actions';

const initialState = {
    forms: {},
    signing_templates: {},
    divisions: {},
    email_templates: {},
    text_templates: {},
    email_senders: {},
    event_templates: {},
    event_outcomes: {},
    locations: {},
    matter_documents: {},
    matter_templates: {},
    tags: {},
    users: {},
    workflows: {},
    automations: {},
    contacts: {},
    workflow_roles: {},
    custom_fields: {},
    location_types: {},
    contact_types: {},
    time_entry_templates: {},
    workflow_locations: {},
    workflow_contacts: {},
    signing_documents: {},
    call_flows: {},
    call_queues: {},
    call_phone_numbers: {},
    workflow_dispositions: {},
    company_documents: {},
    workflow_steps: {},
    task_templates: {},
    workflow_step_categories: {},
    division_departments: {},
    lead_sources: {},
    lead_source_categories: {},
    matters: {},
    ai_assistants: {},
    background_processes: {},
    custom_dates: {},
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.SET_OBJECTS:
            const {obj, collection} = action.payload

            return {
                ...state,
                [collection]: {
                    ...state[collection],
                    [obj._id]: obj
                }
            }

        case actionTypes.SET_MULTIPLE_OBJECTS:
            const objects = action.payload
            const _state = JSON.parse(JSON.stringify(state))
            const collections = Object.keys(objects);

            collections.forEach(collection => {
                const objs = objects[collection];
                for (let i = 0; i < objs.length; i++) {
                    const o = objs[i];
                    _state[collection][o._id] = o
                }
               
            })

            return _state

        default: return state;

    }

}

export default reducer;
