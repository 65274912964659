import {  useCallback } from 'react'
import { Row, Col } from 'reactstrap';
import { toast } from 'react-toastify'

import api from 'api'

import Edit from './Edit'

const WorkflowsEditSidebar = ({
    steps, 
    setSteps, 
    workflowCopy, 
    step, 
    setStep,
    roles,
    folders,
    contacts,
    locations,
    stepCategories,
    dispositions
}) => {

    const saveStep = useCallback(async (_step, cb) => {
        const _steps = JSON.parse(JSON.stringify(steps));
        const foundIndex = _steps.findIndex(s => s._id === _step._id);

        let action;
        
        if(foundIndex === -1) {
            action = await api.workflow_steps.create(_step)
        } else {
            action = await api.workflow_steps.update(_step._id, _step)
        }

        if(!action.success) {
            if(cb) cb()
            return toast.error('Something went wrong, please refresh your page or try again. Note this message may appear if this step is assigned items that have been deleted since last saving.')
        }

      
        
        // setStep(null)
        // toast.success('Step Saved Successfully!')

        
        if(foundIndex === -1) {
            _steps.push(action.data)
        } else {
            _steps[foundIndex] = action.data
        }

        setSteps(_steps)
        
        if(foundIndex === -1) {
            setStep(null)
        } else {
            setStep(action.data)
        }

        if(cb) cb()
    }, [steps, setSteps, setStep])
    
    const removeStep = useCallback(async (_step, cb) => {
        let _steps = JSON.parse(JSON.stringify(steps))

        const action = await api.workflow_steps.delete(_step._id)
        if(!action.success) {
            if(cb) cb()
            return toast.error('Something went wrong, please refresh your page or try again.')
        }

        _steps = _steps.filter(s => s._id !== _step._id)
        setSteps(_steps)
        if(cb) cb()
    }, [steps, setSteps])



    return (
        <div className="archk-workflow-sidebar">

            <div className="archk-workflow-sidebar-header">
                <div className="archk-workflow-sidebar-spacer">
                    <Row>
                        <Col xs={5} className="align-self-center">
                            <p className="mb-0">All Steps</p>
                        </Col>
                        <Col xs={7} className="align-self-center">
                            <button 
                                className="btn btn-outline-success btn-block" 
                                onClick={() => setStep({
                                    workflow                    : workflowCopy._id,
                                    name                        : '',
                                    automations                 : [],
                                    tags                        : [],
                                    requires_events             : [],
                                    next_steps                  : [],
                                    case_alerts                 : [],
                                    signing_templates           : [],
                                    matter_documents            : [],
                                    forms                       : [],
                                    tasks                       : [],
                                    custom_fields               : [],
                                    monitoring_roles            : [],
                                    monitoring_emails           : [],

                                    route_communication_to      : [],
                                    assign_docs_to              : [],
                                    
                                    background_processes        : [],

                                    recommended_next_step       : null,
                                    workflow_step_category      : null,
                                    workflow_disposition        : null,
                                    wiki                        : null,

                                    end_automations             : false,
                                  
                                    monitoring_interval         : 0,
                                    is_first                    : false,
                                    remove_pending_items        : false,
                                    remove_document_expiration  : false,
                                    communication_priority      : 5,
                                    call_priority               : 5,
                                    notifications           : {
                                        document_uploaded           : [],
                                        document_expired            : [],
                                        document_category_updated   : [],
                                        incoming_call               : [],
                                        incoming_text               : [],
                                        outgoing_call               : [],
                                        outgoing_text               : [],
                                        incoming_email              : [],
                                        outgoing_email              : [],
                                        step_moved                  : [],
                                        document_signed             : [],
                                        document_sent               : [],
                                        task_completed              : [],
                                        form_completed              : [],
                                    }
                                })}
                            >
                                <i className="fas fa-plus mr-2 " /> Add Step
                            </button>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className="archk-workflow-sidebar-body">
                {steps.length ? steps.map((step, i) => (
                    <div 
                        key={i} 
                        className="archk-workflow-sidebar-step bg-secondary cursor-pointer border-bottom" 
                        onClick={() => setStep(step)}
                    >
                        <div className="archk-workflow-sidebar-spacer">
                            <p className="archk-workflow-sidebar-step-name mb-0">
                                {step.name}
                            </p>
                            <div className="archk-workflow-sidebar-step-icons mb-0">
                                <i className="fas fa-route " />
                                <i className="fas fa-user " />
                                <i className="fas fa-tags " />
                                <i className="fas fa-tasks " />
                            </div>
                        </div>
                    </div>
                )) : (
                    <div className="archk-workflow-sidebar-step bg-secondary">
                        <div className="archk-workflow-sidebar-spacer">
                            <p className="text-sm mb-0"><i className="fas fa-exclamation-triangle text-warning mr-2" /> No Steps Added</p>
                        </div>
                    </div>
                )}
            </div>

            {step && (
                <Edit
                    step={step}
                    onClose={() => setStep(null)}
                    saveStep={saveStep}
                    removeStep={removeStep}
                    steps={steps}
                    roles={roles}
                    folders={folders}
                    contacts={contacts}
                    locations={locations}
                    stepCategories={stepCategories}
                    dispositions={dispositions}
                    workflowCopy={workflowCopy}
                />
            )}

        </div>
    )
}

export default WorkflowsEditSidebar;