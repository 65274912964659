/*
Documentation

This file holds the database calls and error logs for this system

*/

import Axios from 'axios';
import keys from '../keys'
// import { toggleAlert } from 'store/functions/system/system';

export const architeckCall = async (settings) => {
    return new Promise(async(resolve) => {

        const method = settings.method;
        const url = settings.url;
        const data = settings.data ? settings.data : {};
        const headers = settings.headers;
    
        try {
            const result = await Axios({
                method,
                url: keys.API_URL + url,
                headers: {
                    ...headers,
                    authorization: `Bearer ${keys.SYSTEM_API_KEY}`
                },
                withCredentials: true,
                data,
            })

            resolve(result.data)

            if(!result.data.success) {
                if(settings.shouldNotShowAndLogError !== true) {
                    logAndShowError(result)
                }
            }
        
        } catch(e) {

            try {
                const err = JSON.parse(JSON.stringify(e.response.data))
                if(err && err.error === 'THREAT DETECTED') {
                    return window.location.href = '/errors/threat'
                }
            } catch(e) {}

            resolve({success: false, message: 'architeckCall failed at url: ' + url + '. Error: ' + e.toString()})
            if(settings.shouldNotShowAndLogError !== true) {
                logAndShowError(e)
            }
        
        }

    })
}
 
const logAndShowError = (e) => {

    console.log('API REQUEST FAILED ON URL: ')
    console.log(window.location.href)
    console.log(' ')
    console.log(e)

    log({
        url: window.location.href, 
        file: '/src/database/js', 
        code: 'REQUEST FAILURE', 
        text: JSON.stringify(e)
    });

    // toggleAlert(true, 'danger', 'An Unexpected Error Occurred.', 6000)

}

export const log = async (payload) => {

    //log must not be a standard call to prevent infinite loop if all requests fail
    //we will only fire off a console.log if we are in development and the log fails
    try {
        const log = await Axios({
            method: 'post',
            url: keys.API_URL + '/api/v1/logs/create',
            headers: {
                authorization: `Bearer ${keys.SYSTEM_API_KEY}`
            },
            withCredentials: true,
            data: {...payload}
        })

        if(!log.data.success && window.location.hostname === 'localhost') {
            console.log('log failed 1')
            console.log(log)
        }
        
    
    } catch(e) {
    
        if(window.location.hostname === 'localhost') {
            console.log('log failed 2')
            console.log(e)
        }
        
    }

}