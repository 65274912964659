/*
Documentation

*** make sure to declare all action types in actions.js one folder level back

*/

import * as actionTypes from '../../actions';

const initialState = []

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.SET_USERS:
            return action.payload

        default: return state;

    }
}

export default reducer;
