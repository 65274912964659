

import { connect } from 'react-redux';
import { Card, CardFooter, Row, Col } from "reactstrap";
import PieArea from 'components/charts/PieArea';

import Table from 'components/functional/tables/Standard';

const AnalyticsPagesMattersResults = ({viewing_user, limit, data, setLimit, runQuery, isDownloading, columns, onSaveReport, reportID}) => {
    const hasCategories = data && data.categories && data.categories.length;
    const hasDispositions = data && data.dispositions && data.dispositions.length;

    return (
        <Card className=" archk-query-results card-color card-primary table-fixe table-no-stripes z-depth-0 mb-0 pr-3 mr--   ">


            <div className='border-right mr-' style={{overflow: 'hiden'}}>
                <Table
                    query={runQuery}
                    reportID={reportID}
                    hideSearch={true}
                    rowClasses={(row) => {
                        if(!row.roles) return ''
                        const isAssignedOnRole = row.roles.some(r => r.user === viewing_user._id)
                        return isAssignedOnRole ? 'row-mine' : '';
                    }}
                    columns={columns}
                    data={data && data.documents && data.documents.length ? data.documents : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={data ? data.total_documents : 0}
                    onSizePerPageChange={(value) => setLimit(parseInt(value))}
                    sizePerPage={limit}
                />
            </div>


        
        
            <CardFooter className='border-top z-depth-1'>
                <p className='text-sm text-right mb-0'>
                    {/* <span className='cursor-pointer mr-5' ><i className="fas fa-envelope text-purple" /> Email Report</span> */}
                    <span className='cursor-pointer mr-5' onClick={onSaveReport}><i className="fas fa-save text-success" /> Save Report</span>

                    {isDownloading ? (
                        <span className='text-muted'><i className="fas fa-download text-info" /> Export ResultsTo CSV</span>
                    ) : (
                        <span className='cursor-pointer' onClick={() => runQuery({}, true)}><i className="fas fa-download text-info" /> Export ResultsTo CSV</span>
                    )}

                </p>
            </CardFooter>

            <div style={{overflow: 'hiddn'}}>
            {hasCategories || hasDispositions ? (
                <Row className='pb-5'>
                    {hasCategories ? (
                        <Col xs={hasDispositions ? 6 : 12} className='px-0'>
                             <PieArea 
                                classNames="mb-0 border"
                                type="pie"
                                // small={true}
                                labels={data.categories.map(c => c.label)}
                                dataset={data.categories.map(c => c.data)}
                            />
                        </Col>
                    ) : ''}
                    {hasDispositions ? (
                        <Col xs={hasCategories ? 6 : 12} className='px-0'>
                            <PieArea 
                                classNames="mb-0 border"
                                type="pie"
                                // small={true}
                                labels={data.dispositions.map(c => c.label)}
                                dataset={data.dispositions.map(c => c.data)}
                            />
                        </Col>
                    ) : ''}

                </Row>
            ) : ''}
            </div>


        </Card>
        
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	    viewing_user: state.auth.viewing_user,
	    DOMAINS: state.load.DOMAINS,
	};
};

export default connect(mapStateToProps, '')(AnalyticsPagesMattersResults);
