import { memo } from 'react';
import { connect } from 'react-redux';

const ArchkAlerts = ({archk_alerts}) => {

    return (
        <div className="archk-alerts">
            {archk_alerts.map((alert, i) => (
                alert.type === 'success' ? (
                    <div key={alert.id} className="archk-alert archk-alert-success">
                        <i className="fas fa-check-circle " /> {alert.body}
                    </div>
                ) : alert.type === 'info' ? (
                    <div key={alert.id} className="archk-alert archk-alert-info">
                        <i className="fas fa-comment " /> {alert.body}
                    </div>
                ) : alert.type === 'warning' ? (
                    <div key={alert.id} className="archk-alert archk-alert-warning">
                        <i className="fas fa-info-circle " /> {alert.body}
                    </div>
                ) : alert.type === 'danger' ? (
                    <div key={alert.id} className="archk-alert archk-alert-danger">
                        <i className="fas fa-exclamation-triangle " /> {alert.body}
                    </div>
                ) : (
                    <div key={alert.id} className="archk-alert">
                        <i className="fas fa-exclamation-triangle " /> {alert.body}
                    </div>
                )
            ))}
        </div>
    )
}


const mapStateToProps = state => {
    return {
        archk_alerts: state.system.archk_alerts,
    };
};

export default connect(mapStateToProps, '')(memo(ArchkAlerts));