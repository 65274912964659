import { Input, FormGroup } from "reactstrap";
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import { useCallback, useState, useEffect } from 'react';

import api from 'api'

import Modalify from 'components/functional/modals/Modalify'

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

const AutomationsAutomationsEdit = ({automation_id, onSaved, showModal, toggleModal, selected_division}) => {

    const [automation, setAutomation] = useState(null)
    const [err, setErr] = useState(null)
    const [redirect, setRedirect] = useState(null)

    const fetchData = useCallback(async () => {
        if(!automation_id) return setAutomation({});
        const automation = await api.automations.findById(automation_id)
        if(!automation.data) return setErr(true);
        setAutomation(automation.data)
    }, [automation_id])

    const onInputChange = useCallback(( field, value) => {
        const newAutomation = Object.assign({}, automation)
        newAutomation[field] = value;
        setAutomation(newAutomation);
    }, [automation])

    const onSave = useCallback(async () => {
        setErr(false)
        let newState = Object.assign({}, automation);

        if(!newState.name) {
            return setErr([`A automation must have a name.`])
        }
        
        setAutomation(newState)

        let saved;

        toggleStandardLoader(true)
        if(newState._id) {
            saved = await api.automations.update(newState._id, newState, true);
        } else {
            saved = await api.automations.create({...newState, division: selected_division._id, active: false}, true);
        }
        toggleStandardLoader(false)

        // show error messages if unsuccessful
        if(saved.success) {
            toggleAlertBS(false, "Automation Saved Successfully")
            fetchData();
            if(toggleModal) toggleModal();
            if(onSaved) onSaved(saved.data)
        } else {
            return setErr(saved.message);
        }
    
    }, [automation, fetchData, toggleModal, onSaved, selected_division._id])

    useEffect(() => {
        if(toggleModal) {
            if(showModal) {
                fetchData()
            } else {
                setErr(null)
                setAutomation('')
            }
        } else {
            fetchData()
        }
    }, [showModal, toggleModal, fetchData])

    useEffect(() => {
        if(!selected_division._id) {
            toggleAlertBS('info', 'A division must be created for editing a automation.')
            setRedirect('/system/divisions/create')
        }
    }, [selected_division._id])

    if(redirect) return <Redirect to={redirect} />

    return (
        <Modalify 
            showModal={showModal}
            toggleModal={toggleModal}
            modalTitle={automation && automation._id ? "Update Automation" : "Create Automation"}
            err={err}
            footer={(
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            )}
            sections={!automation ? [] :  [
                (
                    <div>

                        <FormGroup>
                            <label className="form-control-label">Name*</label>
                            <Input 
                                type="text"
                                value={automation.name || ''}
                                onChange={e => onInputChange('name', e.target.value)}
                            />
                        </FormGroup>
                    </div>
                ),
            ]}
        />
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(AutomationsAutomationsEdit);