import hasPrivilege from 'utils/hasPrivilege'

import ViewPermission from 'components/markup/errors/ViewPermission';

import System        from '../pages/system';
import Layout        from '../pages/layout';
import Staffing        from '../pages/staffing';
import Analyze        from '../pages/analyze';
import AnalyzeCustomReports        from '../pages/analyze/CustomReports';
import Automations        from '../pages/automations';
import Overview        from '../pages/overview';
import AI        from '../pages/ai';
// import Monitoring        from '../pages/monitoring';
import Developers        from '../pages/developers';
import Communication        from '../pages/communication';
import Company        from '../pages/company';
import Marketing        from '../pages/marketing';
import Finances        from '../pages/finances';
import CustomViews        from '../pages/custom_views';
import DivisionUsers        from '../pages/division_users';

import Leads        from '../pages/marketing/Leads';
import LeadSourcesView        from '../pages/marketing/LeadSources/View';

// 'VIEW.CAN_VIEW_ADMIN_DASHBOARD'       : 'VIEW.CAN_VIEW_ADMIN_DASHBOARD',
//         'VIEW.ADMIN_DASHBOARD.OVERVIEW'       : 'VIEW.ADMIN_DASHBOARD.OVERVIEW',
//         'VIEW.ADMIN_DASHBOARD.MARKETING'       : 'VIEW.ADMIN_DASHBOARD.MARKETING',
//         'VIEW.ADMIN_DASHBOARD.ANALYZE'       : 'VIEW.ADMIN_DASHBOARD.ANALYZE',
//         'VIEW.ADMIN_DASHBOARD.COMMUNICATE'       : 'VIEW.ADMIN_DASHBOARD.COMMUNICATE',
//         'VIEW.ADMIN_DASHBOARD.AUTOMATE'       : 'VIEW.ADMIN_DASHBOARD.AUTOMATE',
//         'VIEW.ADMIN_DASHBOARD.STAFFING'       : 'VIEW.ADMIN_DASHBOARD.STAFFING',
//         'VIEW.ADMIN_DASHBOARD.SYSTEM'       : 'VIEW.ADMIN_DASHBOARD.SYSTEM',
//         'VIEW.ADMIN_DASHBOARD.COMPANY'       : 'VIEW.ADMIN_DASHBOARD.COMPANY',

const routes = () =>  [

    
    {
        path: "/leads",

        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.MARKETING') ? Leads : ViewPermission,
    },
    {
        path: "/leads/:_id",
        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.MARKETING') ? Leads : ViewPermission,
    },
    {
        path: "/lead_sources/:_id",
        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.MARKETING') ? LeadSourcesView : ViewPermission,
    },
    {
        path: "/system",
        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.SYSTEM') ? System : ViewPermission,
    },
    {
        path: "/system/:nav1",
        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.SYSTEM') ? System : ViewPermission,
    },
    {
        path: "/system/:nav1/:nav2",
        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.SYSTEM') ? System : ViewPermission,
    },
   
    {
        path: "/layout",
        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.LAYOUT') ? Layout : ViewPermission,
    },
    {
        path: "/layout/:nav1",
        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.LAYOUT') ? Layout : ViewPermission,
    },
    {
        path: "/layout/:nav1/:nav2",
        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.LAYOUT') ? Layout : ViewPermission,
    },
   
    {
        path: "/ai",
        component: hasPrivilege('VIEW.AI') ? AI : ViewPermission,
    },
    {
        path: "/ai/:nav1",
        component: hasPrivilege('VIEW.AI') ? AI : ViewPermission,
    },
    {
        path: "/ai/:nav1/:nav2",
        component: hasPrivilege('VIEW.AI') ? AI : ViewPermission,
    },
   
    {
        path: "/staffing",
        component: Staffing,
    },
    {
        path: "/staffing/:nav1",
        component: Staffing,
    },
    {
        path: "/staffing/:nav1/:nav2",
        component: Staffing,
    },
   
   
    {
        path: "/division_users",
        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.STAFFING') ? DivisionUsers : ViewPermission,
    },

   
    {
        path: "/analyze",
        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.ANALYZE') ? Analyze : ViewPermission,
    },
    {
        path: "/analyze/:type",
        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.ANALYZE') ? AnalyzeCustomReports : ViewPermission,
    },

    {
        path: "/automations",
        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.AUTOMATE') ? Automations : ViewPermission,
    },
    {
        path: "/automations/:nav1",
        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.AUTOMATE') ? Automations : ViewPermission,
    },
    {
        path: "/automations/:nav1/:nav2",
        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.AUTOMATE') ? Automations : ViewPermission,
    },
   
    {
        path: "/finances",
        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.FINANCES') ? Finances : ViewPermission,
    },
    {
        path: "/finances/:nav1",
        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.FINANCES') ? Finances : ViewPermission,
    },
    {
        path: "/finances/:nav1/:nav2",
        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.FINANCES') ? Finances : ViewPermission,
    },
   

    // {
    //     path: "/monitoring",
    //     component: Monitoring,
    // },
    // {
    //     path: "/monitoring/:nav1",
    //     component: Monitoring,
    // },
    // {
    //     path: "/monitoring/:nav1/:nav2",
    //     component: Monitoring,
    // },
    
    
    {
        path: "/developers",
        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.DEVELOPERS') ? Developers : ViewPermission,
    },
    {
        path: "/developers/:nav1",
        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.DEVELOPERS') ? Developers : ViewPermission,
    },
    {
        path: "/developers/:nav1/:nav2",
        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.DEVELOPERS') ? Developers : ViewPermission,
    },
    
   
    {
        path: "/company",
        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.COMPANY') ? Company : ViewPermission,
    },
    {
        path: "/company/:nav1",
        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.COMPANY') ? Company : ViewPermission,
    },
    {
        path: "/company/:nav1/:nav2",
        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.COMPANY') ? Company : ViewPermission,
    },

    
    {
        path: "/communication",
        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.COMMUNICATE') ? Communication : ViewPermission,
    },
    {
        path: "/communication/:nav1",
        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.COMMUNICATE') ? Communication : ViewPermission,
    },
    {
        path: "/communication/:nav1/:nav2",
        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.COMMUNICATE') ? Communication : ViewPermission,
    },
    
    {
        path: "/marketing",
        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.MARKETING') ? Marketing : ViewPermission,
    },
    {
        path: "/marketing/:nav1",
        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.MARKETING') ? Marketing : ViewPermission,
    },
    {
        path: "/marketing/:nav1/:nav2",
        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.MARKETING') ? Marketing : ViewPermission,
    },
    

    {
        path: "/overview",
        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.OVERVIEW') ? Overview : ViewPermission,
    },
    {
        path: "/overview/:nav1",
        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.OVERVIEW') ? Overview : ViewPermission,
    },
    {
        path: "/overview/:nav1/:nav2",
        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.OVERVIEW') ? Overview : ViewPermission,
    },
    
    {
        path: "/custom_views",
        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.CUSTOM_VIEWS') ? CustomViews : ViewPermission,
    },




    {
        path: "/home",
        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.OVERVIEW') ? Overview : ViewPermission,
    },
    
    {
        path: "/",
        component: hasPrivilege('VIEW.ADMIN_DASHBOARD.OVERVIEW') ? Overview : ViewPermission,
    },

   
]

export default routes;
