import { useEffect, useCallback, useState } from 'react';
import { Modal, FormGroup, Input } from 'reactstrap';

const UpdateName = ({showModal, toggleModal, showingParty, isTemplate, updateMarkerName, index, name, configuration}) => {

    const [nameState, setNameState] = useState('');

    const onSave = useCallback(() => {

        updateMarkerName(nameState, index)
        toggleModal()

    }, [nameState, index, updateMarkerName, toggleModal])

    useEffect(() => {
        if(showModal) {
            setNameState(name ? name : '');
            setTimeout(() => {
                const el = document.getElementById('update-name-input')
                if(el) el.focus() 
            }, 500)
        }
    }, [showModal, name])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">{showingParty === 0 ? 'Document Markup' : 'Marker Question Text'}</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">
                <FormGroup>
                    <label className="form-control-label">
                        {
                            showingParty === 0 ?
                                isTemplate  ? 
                                'Enter in a question or statement that must be answered by a user before sending out this document. Ex. Enter the full amount of this retainer before sending.' :
                                'Enter any text you would like shown on the document when it goes out for signature. Make sure that all text fits on one line within the selection box.' :
                            'This text will be the question shown to contacts when filling out this pdf form field. Ex. What is your full name?'
                        }
                    </label>
                    
                    <Input 
                        id="update-name-input"
                        type="text"
                        value={nameState}
                        onChange={(e) => {
                            const val = e.target.value;
                            setNameState(val && val.length >= configuration.MAX_NAME_LENGTH ? val.slice(0, configuration.MAX_NAME_LENGTH) : val)
                        }}
                    />
                    {nameState && nameState.length >= configuration.MAX_NAME_LENGTH ? (
                        <p className="text-sm mb--3 mt-2 text-right">{nameState.length} / {configuration.MAX_NAME_LENGTH} Characters</p>
                    ) : null }
                </FormGroup>
            </div>

            <div className="modal-footer">
                <button onClick={onSave} className="btn btn-outline-success">Save Question</button>
            </div>

        </Modal>
    )
}

export default UpdateName
