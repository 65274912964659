import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from "reactstrap";

import api from 'api';

import A from 'components/markup/links/A';
import Circle from 'components/markup/loading/Circle';

import ObjectFinder from 'components/system/Objects/Finder';
import moment from 'moment';

const ExecutionLogsModalView = ({showModal, toggleModal, _id, DOMAINS}) => {

    const [log, setLog] = useState(null)

    const fetchData = useCallback(async () => {
        const data = await api.execution_logs.findById(_id);
        if(data.data) setLog(data.data)
    }, [_id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">View Log</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            {!log ? (
                <div className="modal-body border-bottom bg-secondary">
                    <Circle />
                </div>
            ) : (
                <div className="modal-body border-bottom bg-secondary">

                    <div>
                        {log.status === 'info' ? (
                            <div className="text-uppercase mb-2 text-center alert alert-info">{log.status}</div>
                        ) : log.status === 'error' ? (
                            <div className="text-uppercase mb-2 text-center alert alert-danger">{log.status}</div>
                        ) : (
                            <div className="text-uppercase mb-2 text-center alert alert-success">{log.status}</div>
                        )}
                    </div>

                    <p className='text-sm mb-0'>
                        <b style={{width: 100, display: 'inline-block'}} className='text-uppercase text-dark'>Process: </b>
                        {log.type === 'background_process' ? (
                        <A href={`/editor/background_processes/${log._id}`}>
                            <ObjectFinder collection="background_processes" _id={log.process_id} />
                        </A>
                    ) : 'Unknown Execution'}
                    </p>

                    <p className='text-sm mb-0'>
                        <b style={{width: 100, display: 'inline-block'}} className='text-uppercase text-dark'>Type: </b>
                        {log.type ? <span className="text-capitalize">{log.type.replace(/_/g, ' ')}</span> : 'Unknown Type'}
                    </p>
                    <p className='text-sm mb-0'>
                        <b style={{width: 100, display: 'inline-block'}} className='text-uppercase text-dark'>Node ID: </b>
                        {log.node_id}
                    </p>

                    <p className='text-sm mb-0'>
                        <b style={{width: 100, display: 'inline-block'}} className='text-uppercase text-dark'>Action: </b>
                        {log.action ? <span className="text-capitalize">{log.action.replace(/_/g, ' ')}</span> : 'Unknown Action'}
                    </p>

                    <p className='text-sm mb-0'>
                        <b style={{width: 100, display: 'inline-block'}} className='text-uppercase text-dark'>Run Time: </b>
                        {moment.unix(log.created_at).format('MM/DD/YYYY h:mm:ss A')}
                    </p>

                    <p className='text-sm mb-2'>
                        <b style={{width: 100, display: 'inline-block'}} className='text-uppercase text-dark'>Matter: </b>
                        {log.type === 'background_process' ? (
                            <A href={`${DOMAINS.APP}/matters/${log.matter}`}>
                                <ObjectFinder collection="matters" _id={log.matter} />
                            </A>
                        ) : 'Unknown Execution'}
                    </p>

                    

                    <hr />

                    <p className='mt-3 mb-0'>Execution Data</p>

                    <div className='p-3 bg-white border'>
                        <span style={{whiteSpace: 'pre-line'}}>
                            <pre style={{whiteSpace: 'pre-wrap'}}>{JSON.stringify(log.data, null, 4)}</pre>
                        </span>
                    </div>

                </div>
            )}

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
            </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        DOMAINS: state.load.DOMAINS,
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(ExecutionLogsModalView);




// },

// {
//     dataField: "action",
//     text: 'Action',
//     formatter: (cell, log) => log.name === 'no data' ? '' : (
        // row.action ? <span className="text-capitalize">{row.action.replace(/_/g, ' ')}</span> : 'Unknown Action'
//     )
// },

// {
//     dataField: "status",
//     text: 'Status',
//     formatter: (cell, row) => row.name === 'no data' ? '' : (
        // row.status === 'info' ? (
        //     <Badge style={{width: 70}} color="info">{row.status}</Badge>
        // ) : row.status === 'error' ? (
        //     <Badge style={{width: 70}} color="danger">{row.status}</Badge>
        // ) : (
        //     <Badge style={{width: 70}} color="success">{row.status}</Badge>
        // )
//     )
// },

// {
//     dataField: "created_at",
//     text: 'Date',
//     formatter: (cell, row) => row.name === 'no data' ? '' : (
//         moment.unix(row.created_at).format('MM/DD/YYYY h:mm A')
//     )
// },
// {
//     dataField: "matter",
//     text: 'Matter',
//     formatter: (cell, row) => row.name === 'no data' ? '' : (
        // row.type === 'background_process' ? (
        //     <A href={`${DOMAINS.APP}/matters/${row.matter}`}>
        //         <ObjectFinder collection="matters" _id={row.matter} />
        //     </A>
        // ) : 'Unknown Execution'
       

//     )
// },

// {
//     dataField: "_id",
//     headerStyle: {textAlign: 'right',},
//     text: 'Actions',
//     headerFormatter: () => {
//         return <div id="archk-tutorial-contacts-actions">Actions</div>
//     },
//     formatter: (cell, row) => {
//         return (
//             row.name === 'no data' ? '' :
//             <div className="text-right">
//                  <TableIcons
//                     icons={[
//                         {
//                             icon: 'fas fa-external-link-alt',
//                             color: 'success', 
//                             wrapper: ModalToggler, 
//                             component: ModalView,
//                             _id: row._id
//                         },
                     
//                     ]}
//                 />
//             </div>
//         )
//     }
// },
// ]