const LoadingTable = ({rows = 15}) => {

    const renderRows = () => {

        let markup = [];

        for (let i = 0; i < rows; i++) {
            markup.push(
                <tr key={i}>
                    <th><span className="skeleton-load" /></th>
                    <th><span className="skeleton-load" /></th>
                    <th><span className="skeleton-load" /></th>
                    <th><span className="skeleton-load" /></th>
                </tr>
            )
            
        }

        return markup;

    }

    return (
        <div className="skeletal-loading-table table-responsive">
            <table className="table">
                <thead>
                    <tr>
                        <th><span className="skeleton-load" /></th>
                        <th><span className="skeleton-load" /></th>
                        <th><span className="skeleton-load" /></th>
                        <th><span className="skeleton-load" /></th>
                    </tr>
                </thead>

                <tbody>
                    {renderRows()}
                </tbody>
            </table>
        </div>
    )

}

export default LoadingTable;