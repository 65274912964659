import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import ReactSelect from 'components/functional/inputs/ReactSelect';

import timeValues from '../../_helpers/timeValues'

const SidebarRightWait = ({ selectedNode, onNodeUpdated, selected_division }) => {

    const [node, setNode] = useState(selectedNode)

    const onInputChange = useCallback((field, value) => {
        const n = JSON.parse(JSON.stringify(node));
        n.data[field] = value;
        setNode(n)
    }, [node])

    useEffect(() => {
        setNode(JSON.parse(JSON.stringify(selectedNode)))
    }, [selectedNode])
   
    return (
        <div>

            <div className='sidebar-section border-bottom'>
                <div className="sidebar-item pb-0"><h2>Wait For X Time</h2></div>
            </div>

            <div className='sidebar-section border-bottom'>
                <ReactSelect
                    title={"Select Time To Wait For"}
                    description="The amount of time from when this item runs to when the process should continue it's execution."
                    formGroup={true}
                    placeholder=""
                    onChange={(obj) => onInputChange('wait_time', obj.value)}
                    options={timeValues}
                    value={node.data.wait_time}
                />    
            </div>

            <div className='sidebar-section text-right'>
                <button onClick={() => onNodeUpdated(node)} className='btn btn-success'>Save Node</button>
            </div>


        </div>
    )

}



const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SidebarRightWait);