import { useCallback, useState } from "react";
import { connect } from 'react-redux';
import { Card, Container, Badge } from "reactstrap";

import api from 'api';
import { Link } from 'react-router-dom';

import ModalToggler from 'components/functional/modals/Toggler';
import Table from 'components/functional/tables/Standard';
import TableIcons from 'components/markup/icons/TableIcons';
import ApiError from "components/markup/layout/ApiError";
import ObjectFinder from 'components/system/Objects/Finder';

import Circle from 'components/markup/loading/Circle';
import TextButtonRow from 'components/markup/layout/TextButtonRow';
import A from 'components/markup/links/A'

import moment from 'moment'

import { formatCurrency } from 'utils/currency';
import formatText from 'utils/formatText';


const LocationsAll = ({setTab, match, selected_division, DOMAINS}) => {

    const [ loading, setLoading ] = useState(false);
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ error, setError ] = useState(false);


    // helper for this.downloadCSV and this.query
    const fetchData = useCallback((params, isCSV, shouldReload) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(shouldReload) setLoading(true)

        let filter = { division: selected_division._id }
        if(match.params._id && match.params._id !== 'all') filter.lead_source = match.params._id

        const query = await api.leads.search({ ...params, filter, isCSV })
        if(shouldReload) setLoading(false)

        if(!query.data) return setError(query.message)
        return resolve(query.data)
    }), [sizePerPage, selected_division._id, match.params._id])

    // run query to get data from the server
    const query = useCallback(async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }, [fetchData])

   
 
    const columns = [
        {
            dataField: "given_name",
            text: 'Matter',
            formatter: (cell, row) => row.name === 'no data' ? (
                <div style={{minHeight: 150}}>No Data To Show</div> 
            ) : (
                <A href={`${DOMAINS.APP}/contacts/${row.contact}`}>
                    <div>
                        <div>{row.display_name ? row.display_name : <span className="text-warning">Unknown Name</span>}</div>
                        {row.postal_code ? (
                            <div>ZIP: {row.postal_code}</div>
                        ) : ''}
                    </div>
                </A>

            )
        },
        {
            dataField: "likely_hood_of_converting",
            text: 'Conversion % / Value',
            formatter: (cell, row) => row.name === 'no data' ? '' : (
                <div>
                    <div>{row.likely_hood_of_converting !== -1 ? row.likely_hood_of_converting + '%' : '-'}</div>
                    <div>{row.estimated_value !== -1 ? formatCurrency(row.estimated_value) : '-'}</div>
                </div>
            )
        },
        {
            dataField: "created_at",
            text: 'Time',
            formatter: (cell, row) => row.name === 'no data' ? '' : (
                <div>
                    <div>{moment.unix(row.created_at).format('h:mm A')}</div>
                    <div>{moment.unix(row.created_at).format('MMM Do, YYYY')}</div>
                </div>
            )
        },
        {
            dataField: "duplicate",
            text: 'Duplicate',
            formatter: (cell, row) => row.name === 'no data' ? '' : (
                row.duplicate ? (
                    <Badge style={styles.badge} color="danger">YES</Badge>
                ) : (
                    <Badge style={styles.badge} color="success">NO</Badge>
                )
            )
        },
       
        {
            dataField: "lead_source",
            text: 'Lead Source',
            formatter: (cell, row) => row.name === 'no data' ? '' : <ObjectFinder collection="lead_sources" _id={row.lead_source} />
        },
        {
            dataField: "address_line_1",
            text: 'Contact Info',
            formatter: (cell, row) => row.name === 'no data' ? '' : (
                <div>
                    <div>{row.email}</div>
                    <div>{formatText(row.phone).phone()}</div>
                </div>
            )
        },
        {
            dataField: "audit_required",
            text: 'Audited',
            headerStyle: { textAlign: 'center' },
            formatter: (cell, row) => row.name === 'no data' ? '' : (
                <div className="text-center">
                    {!row.audit_required ? (
                        <Badge style={styles.badge} color="warning">NA</Badge>
                    ) : row.audited_at ? (
                        <Badge style={styles.badge} color="success"><i className="fas fa-check" /></Badge>
                    ) : (
                        <Badge style={styles.badge} color="success"><i className="fas fa-times" /></Badge>
                    )}
                </div>
            )
        },
        {
            dataField: "claimed_by",
            text: 'Claimed By',
            formatter: (cell, row) => row.name === 'no data' ? '' : !row.claimed_by ? '' : <ObjectFinder collection="users" _id={row.claimed_by} />
        },

       
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right',},
            text: 'Actions',
            headerFormatter: () => {
                return <div id="archk-tutorial-contacts-actions">Actions</div>
            },
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                         <TableIcons
                            icons={[
                                {
                                    icon: 'fas fa-external-link-alt',
                                    color: 'success', 
                                    wrapper: A, 
                                    href: `${DOMAINS.APP}/contacts/${row.contact}`,
                                },
                                // { 
                                //     icon: 'fas fa-door-open',
                                //     color: 'success', 
                                //     wrapper: ModalToggler, 
                                //     onSaved: () => fetchData(null, null, true),
                                //     _id: row._id
                                // },
                                { 
                                    icon: 'fas fa-phone',
                                    color: 'info', 
                                    wrapper: ModalToggler, 
                                    onSaved: () => fetchData(null, null, true),
                                    _id: row._id
                                },
                                // { 
                                //     icon: 'fas fa-trash',
                                //     color: 'warning', 
                                //     wrapper: ModalToggler, 
                                //     onSaved: () => fetchData(null, null, true),
                                //     _id: row._id
                                // },  
                             
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(error) return <ApiError err={error} />
    if(loading) return <div><Circle /></div>

    const content = (
        <div>
            <TextButtonRow 
                title={
                    <span>Lead Source: {match.params._id ? <ObjectFinder collection="lead_sources" _id={match.params._id} />  : 'All Sources' } </span>}

            />

            <Card className="card-color card-primary table-fixed">
                <Table
                    tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                    placeholder="Search..."
                    query={query}
                    columns={columns}
                    data={tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}
                    sizePerPage={sizePerPage}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>
        </div>
    )

    if(window.location.href.includes('marketing')) return content;
    return <Container fluid className="pt-4">{content}</Container>

}

const styles = {
    badge: {
        width: 100  
    }
}

const mapStateToProps = state => {
	return {
        DOMAINS: state.load.DOMAINS,
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(LocationsAll);