import EmailTemplatesEdit from './components/Edit'
import EmailTemplatesAll from './All'

import IntuideskSectionWrapper from 'components/markup/layout/IntuideskSectionWrapper'

const AutomationsEmailTemplates = ({match, section}) => (
    <IntuideskSectionWrapper 
        title="Email Templates"
        editTitle="Email Template"
        match={match}
        section={section}
        innerSection={'email_templates'}
        Edit={EmailTemplatesEdit}
        All={EmailTemplatesAll}
    />
)

export default AutomationsEmailTemplates;