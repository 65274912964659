import { Input, FormGroup, Badge } from "reactstrap";
import { connect } from 'react-redux';
import { useCallback, useState, useEffect } from 'react';

import api from 'api'

import Modalify from 'components/functional/modals/Modalify'

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';
import { setCompany as refreshCompany } from 'store/functions/company/company'

const CompanyUpdateAlerts = ({onSaved, showModal, toggleModal, viewing_user}) => {

    const [company, setCompany] = useState(null)
    const [email, setEmail] = useState('')
    const [err, setErr] = useState(null)

    const fetchData = useCallback(async () => {
        const company = await api.companies.findById(viewing_user.company)
        if(!company.data) return setErr(true);
        setCompany(company.data)
    }, [viewing_user.company])

    const onRemoveEmail = (_email) => {
        const c = JSON.parse(JSON.stringify(company));
        c.notification_emails = c.notification_emails.filter(e => e !== _email)
        setCompany(c)
    }

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            if(email && !company.notification_emails.includes(email)) {
                const c = JSON.parse(JSON.stringify(company));
                c.notification_emails.push(email)
                setCompany(c)
                setEmail('')
            }
        }
    }

    const onSave = useCallback(async () => {
        setErr(false)
        let newState = Object.assign({}, company);

        if(!newState.name) {
            return setErr([`A company must have a name.`])
        }

        setCompany(newState)

        // toggle loaders and update/create the company
        toggleStandardLoader(true)
        let saved = await api.companies.update(newState._id, {
            notification_emails : newState.notification_emails,
        }, true);
        toggleStandardLoader(false)

        // show error messages if unsuccessful
        if(saved.success) {
            toggleAlertBS(false, "Company Saved Successfully")
            fetchData();
            refreshCompany();
            if(toggleModal) toggleModal();
            if(onSaved) onSaved(saved.data)
        } else {
            return setErr(saved.message);
        }
    
    }, [company, fetchData, toggleModal, onSaved])

    useEffect(() => {
        if(toggleModal) {
            if(showModal) {
                fetchData()
            } else {
                setErr(null)
                setCompany('')
            }
        } else {
            fetchData()
        }
    }, [showModal, toggleModal, fetchData])

    return (
        <Modalify 
            showModal={showModal}
            toggleModal={toggleModal}
            modalTitle={company && company._id ? "Update Company" : "Create Company"}
            err={err}
            footer={(
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            )}
            sections={!company ? [] :  [
                (
                    <div>
                        <h2>System Alerts</h2>

                        <FormGroup>
                            <label className="form-control-label">Email Address*</label>
                            <p className="text-sm mb-0"><i className="fas fa-info-circle text-info"></i> Enter up to 10 email addresses below that will receive any system alerts that go out from IntuiDesk. Press ENTER to add an address.</p>
                            <Input 
                                type="text"
                                value={email}
                                onKeyDown={_handleKeyDown}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </FormGroup>

                        {company.notification_emails.length ? company.notification_emails.map((e, i) => (
                            <Badge color="info" key={i}>{e} <i className="fas fa-times ml-3 cursor-pointer" onClick={() => onRemoveEmail(e)}></i></Badge>
                        )) : (
                            <p className="text-warning text-sm mb-0">You do not have any alert emails set up.</p>
                        )}

                    </div>
                )
            ]}
        />
    )

}


const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(CompanyUpdateAlerts);