const getMarkerErrors = (markers, contacts, isTemplate) => {

    let errs = [];

    // signing party markers
    const party1Markers = markers.filter(m => m.party === 1);
    const party2Markers = markers.filter(m => m.party === 2);
    const party3Markers = markers.filter(m => m.party === 3);
    const party4Markers = markers.filter(m => m.party === 4);

    if(!markers) {
        errs.push('The document must have at least 1 signature marker assigned to party 1');
        return errs
    }

    // make sure all parties before the last one have been filled
    if(party4Markers.length) {
        if(!party3Markers.length) {
            errs.push('Party 3 must be assigned at least 1 marker if party 4 is given fields.')
        }
        if(!party2Markers.length) {
            errs.push('Party 2 must be assigned at least 1 marker if party 4 is given fields.')
        }
        if(!party1Markers.length) {
            errs.push('Party 1 must be assigned at least 1 marker if party 4 is given fields.')
        }
    } else if(party3Markers.length) {
        if(!party2Markers.length) {
            errs.push('Party 2 must be assigned at least 1 marker if party 3 is given fields.')
        }
        if(!party1Markers.length) {
            errs.push('Party 1 must be assigned at least 1 marker if party 3 is given fields.')
        }
    } else if(party2Markers.length) {
        if(!party1Markers.length) {
            errs.push('Party 1 must be assigned at least 1 marker if party 2 is given fields.')
        }
    }

    // make sure each party has a signature
    if(party4Markers.length) {
        if(!markers.find(m => m.party === 4 && m.type === 'signature')) {
            errs.push('Party 4 must be given at least 1 signature field on the document if fields are assigned to them.')
        }
    }
    if(party3Markers.length) {
        if(!markers.find(m => m.party === 3 && m.type === 'signature')) {
            errs.push('Party 3 must be given at least 1 signature field on the document if fields are assigned to them.')
        }
    }
    if(party2Markers.length) {
        if(!markers.find(m => m.party === 2 && m.type === 'signature')) {
            errs.push('Party 2 must be given at least 1 signature field on the document if fields are assigned to them.')
        }
    }

    if(!markers.find(m => m.party === 1 && m.type === 'signature')) {
        errs.push('Party 1 must be given at least 1 signature field on the document.')
    }

    markers.forEach(m => {
        if(!m.type) {
            errs.push(`A marker on page ${m.pageNumber} for party ${m.party === 0 ? 'Markup' : m.party} must be assigned a type.`)
        }
        if(m.type === 'text' && !m.name) {
            errs.push(`A marker on page ${m.pageNumber} for party ${m.party === 0 ? 'Markup' : m.party} was assigned the type of "text" but was not given a description.`)
        }
    })
    
    if(!isTemplate) {
        if(!contacts.length) errs.push(`At least 1 client must be assigned to sign this document.`);

        if(contacts[0] && !party1Markers.length) errs.push(`Party 1 has not been assigned any fields.`);
        if(contacts[1] && !party2Markers.length) errs.push(`Party 2 has not been assigned any fields.`);
        if(contacts[2] && !party3Markers.length) errs.push(`Party 3 has not been assigned any fields.`);
        if(contacts[3] && !party4Markers.length) errs.push(`Party 4 has not been assigned any fields.`);
    }


    return errs;

}

export default getMarkerErrors;