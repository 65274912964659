/*

Example Usage

<BarLine 
    type="line"
    title="New Leads"
    labels={['1/1', '1/2', '1/3', '1/4', '/15', '1/6', '1/7', '1/8']}
    datasets={[
        {
            label: 'Source 1',
            data: [2, 4, 1, 6, 3, 5, 8, 10],
        },
        {
            label: 'Source 2',
            data: [12, 19, 3, 5, 2, 3, 5, 3],
        },
    ]}
/>

*/

import { useEffect, useState } from 'react';
import { Bar, Line } from "react-chartjs-2";
import { formatMoney } from 'utils/currency';
import { formatNumber } from '../_functions/utils';

import settings from '../settings'

const ChartBarLine = ({title, horizontal, format, type, stacked, labels, datasets}) => {

    const [data, setData] = useState(null);

    useEffect(() => {

        let _datasets = [];

        datasets.forEach((d, i) => {
            const color = settings.colors[Object.keys(settings.colors)[i]]
            _datasets.push({
                label: d.label,
                data: d.data,
                backgroundColor: d.color ? d.color: color + '0.7)',
                borderColor: d.color ? d.color : color + '1)',
                borderWidth: type === 'line' ? 3 : 2,
            })
        })

        setData({
            labels: labels,
            datasets: _datasets,
          })
    }, [type, labels, datasets])

    if(!data) return null

    const Component = type === 'line' ? Line : Bar;

    return (
        <div className="border z-depth-1 mb-4">

            <div className="p-4 border-bottom">
                <h2 className=" mb-0">{title}</h2>
            </div>

            <div className="p-4">

                <div className="chart">

                    <Component
                        data={data}
                        options={{
                            indexAxis: horizontal ? 'y' : 'x',

                            maintainAspectRatio: false,
                            scales: {
                                y: {
                                    stacked: stacked ? stacked : false,
                                    ticks: {
                                        // Include a dollar sign in the ticks
                                        callback: function(value, index, ticks) {
                                            if(format === 'percent') {
                                                value = value * 100
                                                return  value + '%'
                                            }
                                            if(format === 'currency') return  formatMoney(value, 0)
                                            return  value
                                        }
                                    }
                                },
                                x: {
                                    stacked: stacked ? stacked : false,

                                }
                            },

                            // options: {
                                plugins: {
                                    tooltip: {
                                        callbacks: {
                                            label: function(a, b) {
                                                var currentValue = a.dataset.data[a.dataIndex];

                                                if(format === 'percent') {
                                                    currentValue = currentValue * 100
                                                    return currentValue + '%'
                                                }

                                                return currentValue
                                            },
                                            // title: function(tooltipItem, data) {
                                            //     console.log(111)
                                            //     return data.labels[tooltipItem[0].index];
                                            // }
                                        }
                                    }
                                },
                            // },


                            // options: {

                            //     plugins: {


                                tooltip: {
                                    callbacks: {
                                        label: function(tooltipItem, data) {
    
                                            var dataset = data.datasets[tooltipItem.datasetIndex];
                                            var currentValue = dataset.data[tooltipItem.index];
    
                                            // let defaultLabel = dataset.label + ' ' + formatNumber(currentValue, false, 0)
    
                                            // if(data.datasets.length >= 3 && tooltipItem.datasetIndex !== 0) {
    
                                            //     let total = 0
                                            //     data.datasets.forEach((d, i) => total += i === 0 ? 0 : d.data[tooltipItem.index])
    
                                            //     var percentage = parseFloat((currentValue/total*100).toFixed(1));
                                            //     return defaultLabel + ' (' + percentage + '%)';
    
                                            // }
                                            if(format === 'percent') {
                                                dataset.label = parseFloat(dataset.label) * 100
                                                return dataset.label + '%'
                                            }
    
                                            return  dataset.label
    
                                        },
                                        title: function(tooltipItem, data) {
                                            return data.labels[tooltipItem[0].index];
                                        }
                                    }
                                }
                                // }
                                // }

                           
                        }}

                        className="chart-canvas"
                    />
                </div>  
            </div>  
        </div>

    )
}

export default ChartBarLine;
