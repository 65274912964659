
import * as actionTypes from '../../actions';
import store from '../../index';

export const openDocumentCenter = async({matter, task, matter_document, url, onUpload}) => {
    store.dispatch({ type: actionTypes.SET_DOCUMENT_CENTER, payload: { 
        matter, 
        task, 
        matter_document, 
        open: true,
        url: url ? url : '/v1/documents',
        onUpload
    } });
}

export const closeDocumentCenter = async(newDocs) => {

    if(newDocs && newDocs.length) {
        const onUpload = store.getState().document_center.onUpload
        if(onUpload) onUpload(newDocs)
    }

    store.dispatch({ type: actionTypes.SET_DOCUMENT_CENTER, payload: { 
        matter: null,
        task: null,
        matter_document: null,
        open: false,
        url: '/v1/documents',
        onUpload: null,
    } });
}

