
function formatString (string) {

    this.string = string

}

// capitalize each word in a string
formatString.prototype.capitalize = function () {

    if(!this.string) return undefined
    const split = this.string.split(' ');
    let finalString;

    try {
        split.forEach(s => {
            if(finalString) {
                finalString += ' ' + s.replace(/^./, s[0].toUpperCase())
            } else {
                finalString = s.replace(/^./, s[0].toUpperCase())
            }
        })
    } catch(e) {
        return this.string;
    }
    
    return finalString
}

// shortens an email for example from johnmaher@architeck.io to jo..@architeck.io
formatString.prototype.trimEmail = function (maxLength) {

    if(!maxLength) maxLength = 20;

    const email = this.string;
    if(!email) return email;
    if(!email.includes('@')) return email;
    if(email.length <= maxLength) return email;

    const split = email.split('@')
    const address = split[0];
    const domain = split[1];

    if(!address || !domain) return email;



    if(domain.length >= maxLength) {
        return address.slice(0, 1) + '..@' + domain;
    }

    let remainingLength = maxLength - domain.length + 1;
    return address.slice(0, remainingLength) + '..@' + domain;
}


formatString.prototype.phone = function () {

    const phone = this.string;

    if(!phone) return undefined
    if(phone.length !== 12) return phone

    let string1 = phone.slice(2,5)
    let string2 = phone.slice(5, 8)
    let string3 = phone.slice(8, 12)

    return `(${string1}) ${string2}-${string3}`

}

/*
Documentation

strips html from a string

*/

formatString.prototype.stripHTML = function(maxLength) {

    const html = this.string;
    if(!html) return undefined

    var tmp = document.implementation.createHTMLDocument("New").body;
    tmp.innerHTML = html;

    let text = tmp.textContent || tmp.innerText || "";
    
    return text && maxLength && text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
 }

formatString.prototype.withCommas = function () {
    return this.string ? this.string.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : this.string
}
formatString.prototype.secondsToTime = function () {
    const number = this.string ? parseInt(this.string) : 0

    const day       = 86400;
    const hour      = 3600;
    const minute    = 60;
    const second    = 1;

    if(number === 0) return '0 Seconds'
    if(!number || number === 'N/A') return 'N/A'

    const days = Math.floor(number / day);
    const hours = Math.floor((number - (days * day)) / hour);
    const minutes = Math.floor((number - (days * day) - (hours * hour)) / minute);
    const seconds = Math.floor((number - (days * day) - (hours * hour)  - (minutes * minute)) / second);

    if(days !== 0) return days + ' Days ' + hours + ' Hrs ' + minutes + ' Mins ';
    if(hours !== 0) return hours + ' Hrs ' + minutes + ' Mins ';
    if(minutes !== 0) return minutes + ' Mins ';
    if(seconds !== 0) return seconds + ' Seconds ';
    
    return 'N/A';
}

const exports = (string) => new formatString(string)
export default exports;