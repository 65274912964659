import { connect } from 'react-redux'
import { Badge, FormGroup, Input } from 'reactstrap';
import { useCallback, useState } from 'react';
import ReactSelect from 'components/functional/inputs/ReactSelect';
import SearchCollections from 'components/system/Search/Collections';
import { toast } from 'react-toastify'

// monitoring_roles
// monitoring_emails
const WorkflowSidebarMonitoringInterval = ({ stepToEdit, onRemoveArrayField, onAddArrayField, onSetStepToEdit, selected_division, roles }) => {

    const [email, setEmail] = useState('')

    const _handleKeyDown = useCallback((e) => {
        if (e.key === 'Enter') {
            if(email.includes('@') && email.includes('.') ) {
                onAddArrayField('monitoring_emails', email)
                setEmail('')
            } else {
                toast.info('Please include a valid email address')
            }
        }
        
    }, [email, onAddArrayField])

    const renderRoleName = useCallback((_id) => {
        const role = roles.find(r => r._id === _id)  
        return role ? role.name : ''
    }, [roles])

    return (
        <div>
    
            <h2 className='text-warning'>Staff Assistance</h2>
    
            <ReactSelect 
                formGroup={true}
                title="Monitoring Interval"
                value={stepToEdit.monitoring_interval}
                onChange={(obj) => onSetStepToEdit('monitoring_interval', obj.value)}
                options={[
                    { value: 0, label: 'None' },
                    { value: 60 * 1, label: '1 Minute' },
                    { value: 60 * 5, label: '5 Minutes' },
                    { value: 60 * 10, label: '10 Minutes' },
                    { value: 60 * 15, label: '15 Minutes' },
                    { value: 60 * 20, label: '20 Minutes' },
                    { value: 60 * 25, label: '25 Minutes' },
                    { value: 60 * 30, label: '30 Minutes' },
                    { value: 60 * 45, label: '45 Minutes' },
                    { value: 60 * 50, label: '50 Minutes' },
                    { value: 60 * 55, label: '55 Minutes' },
                    { value: 60 * 60, label: '60 Minutes' },
                    { value: 60 * 75, label: '75 Minutes' },
                    { value: 60 * 90, label: '90 Minutes' },
                    { value: 60 * 60 * 2, label: '2 Hours' },
                    { value: 60 * 60 * 3, label: '3 Hours' },
                    { value: 60 * 60 * 4, label: '4 Hours' },
                    { value: 60 * 60 * 5, label: '5 Hours' },
                    { value: 60 * 60 * 6, label: '6 Hours' },
                    { value: 60 * 60 * 7, label: '7 Hours' },
                    { value: 60 * 60 * 8, label: '8 Hours' },
                    { value: 60 * 60 * 9, label: '9 Hours' },
                    { value: 60 * 60 * 9, label: '9 Hours' },
                    { value: 60 * 60 * 10, label: '10 Hours' },
                    { value: 60 * 60 * 11, label: '11 Hours' },
                    { value: 60 * 60 * 12, label: '12 Hours' },
                    { value: 60 * 60 * 13, label: '13 Hours' },
                    { value: 60 * 60 * 14, label: '14 Hours' },
                    { value: 60 * 60 * 15, label: '15 Hours' },
                    { value: 60 * 60 * 16, label: '16 Hours' },
                    { value: 60 * 60 * 17, label: '17 Hours' },
                    { value: 60 * 60 * 18, label: '18 Hours' },
                    { value: 60 * 60 * 19, label: '19 Hours' },
                    { value: 60 * 60 * 20, label: '20 Hours' },
                    { value: 60 * 60 * 21, label: '21 Hours' },
                    { value: 60 * 60 * 22, label: '22 Hours' },
                    { value: 60 * 60 * 23, label: '23 Hours' },
                    { value: 60 * 60 * 24, label: '24 Hours' },
                    { value: 60 * 60 * 30, label: '30 Hours' },
                    { value: 60 * 60 * 36, label: '36 Hours' },
                    { value: 60 * 60 * 42, label: '42 Hours' },
                    { value: 60 * 60 * 48, label: '48 Hours' },
                    { value: 86400 * 3, label: '3 Days' },
                    { value: 86400 * 4, label: '4 Days' },
                    { value: 86400 * 5, label: '5 Days' },
                    { value: 86400 * 6, label: '6 Days' },
                    { value: 86400 * 7, label: '7 Days' },
                    { value: 86400 * 10, label: '10 Days' },
                    { value: 86400 * 14, label: '14 Days' },
                    { value: 86400 * 21, label: '21 Days' },
                    { value: 86400 * 28, label: '28 Days' },
                    { value: 86400 * 30, label: '30 Days' },
                    { value: 86400 * 45, label: '45 Days' },
                    { value: 86400 * 60, label: '60 Days' },
                    { value: 86400 * 75, label: '75 Days' },
                    { value: 86400 * 90, label: '90 Days' },
                    { value: 86400 * 120, label: '120 Days' },
                    { value: 86400 * 150, label: '150 Days' },
                    { value: 86400 * 180, label: '180 Days' },
                    { value: 86400 * 210, label: '210 Days' },
                    { value: 86400 * 240, label: '240 Days' },
                    { value: 86400 * 270, label: '270 Days' },
                    { value: 86400 * 300, label: '300 Days' },
                    { value: 86400 * 330, label: '330 Days' },
                ]}
            />

            {stepToEdit.monitoring_interval ? (
                <div className='p-4 bg-secondary rounded'>

                    <FormGroup>
                        <label className='form-control-label'>Notify Emails (press ENTER to add)</label>
                        <Input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onKeyDown={_handleKeyDown}
                        /> 
                    </FormGroup>

                    {stepToEdit.monitoring_emails.length ? stepToEdit.monitoring_emails.map((e, i) => (
                        <Badge key={e} color="success" className="ml-0 mb-2 text-left mr-3">
                            {e}
                            <span className="pr-2 pl-3 cursor-pointer float-right" onClick={() => onRemoveArrayField('monitoring_emails', e)}>
                                <i className="fas fa-times " />
                            </span>
                        </Badge>
                    )) : null}

                   
                    <ReactSelect 
                        formGroup={true}
                        title="Notify Roles"
                        value={""}
                        onChange={(obj) => onAddArrayField('monitoring_roles', obj.value)}
                        options={roles.map((role, i) => {
                            return { value: role._id, label: role.name }
                        })}
                    />

                    {stepToEdit.monitoring_roles.length ? stepToEdit.monitoring_roles.map((role, i) => (
                        <Badge key={role} color="success" className="ml-0 mb-2 text-left mr-3">
                            {renderRoleName(role)}
                            <span className="pr-2 pl-3 cursor-pointer float-right" onClick={() => onRemoveArrayField('monitoring_roles', role)}>
                                <i className="fas fa-times " />
                            </span>
                        </Badge>
                    )) : null}

                   

                </div>
            ) : ''}
    
            <SearchCollections
                collection="wikis" 
                title={"Step Wiki"}
                value={stepToEdit.wiki}
                addNull={true}
                onChange={(obj) => onSetStepToEdit('wiki', obj.value === 'none' ? null : obj.value)}
                filter={{
                    division: selected_division._id
                }}
            /> 
        </div>
    )
}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(WorkflowSidebarMonitoringInterval);