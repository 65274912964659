// import { useState } from 'react'

// import InternalSidebar from 'components/markup/layout/InternalSidebar'

// import EmailSendersEdit from './components/Edit'
// import EmailSendersAll from './All'

// import TextButtonRow from 'components/markup/layout/TextButtonRow'
// import NotFound from 'components/markup/layout/NotFound'

// const SystemEmailSenders = ({match}) => {
//     const [tab, setTab] = useState(match.params.nav2 ? match.params.nav2 : 'all')

//     return (
//         <div>

//             <InternalSidebar
//                 title="Email Senders"
//                 tab={tab}
//                 setTab={(tab) => {
//                     setTab(tab)
//                     window.history.pushState('data', 'email_senders', `/system/email_senders/${tab.toLowerCase()}`);
//                 }}
//                 tabs={[
//                     { value: 'all', name: 'View All' },
//                     { value: 'create', name: 'Create' },
//                 ]}
//             >
//                 {tab === 'create' ? (
//                     <>
//                         <TextButtonRow 
//                             title={"Create An Email Sender"} 
//                             button={(
//                                 <button 
//                                     onClick={() => setTab('all')} 
//                                     className="btn btn-outline-info"
//                                 >
//                                     <i className="fas fa-arrow-left mr-2 " /> All Email Senders
//                                 </button>
//                             )}
//                         />
//                         <EmailSendersEdit email_sender_id={tab} />
//                     </>
//                 )  : tab === 'all' ? (
//                     <EmailSendersAll 
//                         setTab={setTab}
//                     />
//                 ) : (
//                     <NotFound />
//                 )}
//             </InternalSidebar>

//         </div>
//     )

// }

// export default SystemEmailSenders;

import EmailSendersEdit from './components/Edit'
import EmailSendersAll from './All'

import IntuideskSectionWrapper from 'components/markup/layout/IntuideskSectionWrapper'

const AutomationsEmailSenders = ({match, section}) => (
    <IntuideskSectionWrapper 
        title="Email Senders"
        editTitle="Email Sender"
        match={match}
        section={section}
        innerSection={'email_senders'}
        Edit={EmailSendersEdit}
        All={EmailSendersAll}
    />
)

export default AutomationsEmailSenders;