import { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { Container, Row, Col, Card, CardHeader,  CardFooter, CardTitle } from 'reactstrap';

import Logo from 'assets/img/brand/Favicon.png'

import { setCompany } from 'store/functions/company/company'
import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

import api from 'api';

import Circle from 'components/markup/loading/Circle';

import Content from './Content';

const Agreement = ({company}) => {

    const [redirect, setRedirect] = useState(false);
    const [accepted, setAccepted] = useState(false);
    const [err, setErr] = useState(false);

    const onAgree = useCallback(async () => {
        if(!accepted) return setErr(`You must check the box above to move on.`);

        toggleStandardLoader(true);
        const acceptAgreement = await api.companies.acceptAgreement();
        toggleStandardLoader(false);

        if(!acceptAgreement.success) return toggleAlertBS(true, `Something's not right. Please refresh your page and try again.`);
        setRedirect('/onboarding/start');
    }, [accepted])

    useEffect(() => {
        setCompany();
    }, [])

    useEffect(() => {
        if(company.agreement_accepted_at) {
            setRedirect('/dashboard')
        }
    }, [company.agreement_accepted_at])

    if(redirect) return <Redirect to={redirect} />
    if(!company._id) return <div className="py-6"><Circle /></div>

    return (
        <Container className="pb-5">
            <Row className="justify-content-center">
                <Col lg="5" md="7">

                    <div className="text-center my-5">
                        <img src={Logo} alt="IntuiDesk" style={{maxWidth: 200}} />
                    </div>

                    <Card className="bg-white border-0  position-relative" >

                        <CardHeader>
                            <CardTitle className="mb-0">Service Agreement</CardTitle>
                        </CardHeader>

                        <CardFooter className="bg-secondary border-bottom ">
                            <p className="text-sm mb-0">Before proceeding you must consent to the IntuiDesk Service Agreement Below.</p>
                        </CardFooter>

                        <CardFooter style={{maxHeight: 400, overflow: 'auto'}}>
                            <Content />
                        </CardFooter>

                        <CardFooter>
                            <div className="custom-control custom-checkbox">
                                <input
                                    className="custom-control-input"
                                    id="confirm-terms-of-service"
                                    type="checkbox"
                                    onChange={(e) => setAccepted(!accepted)}
                                />
                                <label className="custom-control-label" htmlFor="confirm-terms-of-service">
                                    I agree to the IntuiDesk Service Agreement Above
                                </label>
                            </div>
                        </CardFooter>

                        {err ? (
                            <CardFooter className="bg-secondary">
                                <p className="text-sm mb-0 text-danger">{err}</p>
                            </CardFooter>
                       ) : null}

                        <CardFooter className="text-right">
                            <button className="btn btn-outline-success" onClick={onAgree}>
                                Accept Agreement
                            </button>
                        </CardFooter>
                       
                    </Card>

                </Col>
            </Row>
        </Container>
    )
}


const mapStateToProps = state => {
	return {
	    company: state.company,
	};
};

export default connect(mapStateToProps, '')(Agreement);
