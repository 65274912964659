import { Input, FormGroup } from "reactstrap";
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import { useCallback, useState, useEffect } from 'react';

import api from 'api'

import Modalify from 'components/functional/modals/Modalify'

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

import Associations from 'components/system/Associations'

const SystemTagsEdit = ({_id, onSaved, showModal, toggleModal, selected_division}) => {

    const [tag, setTag] = useState(null)
    const [err, setErr] = useState(null)
    const [redirect, setRedirect] = useState(null)

    const fetchData = useCallback(async () => {
        if(!_id || _id === 'create') return setTag({});
        const tag = await api.tags.findById(_id)
        if(!tag.data) return setErr(true);
        setTag(tag.data)
    }, [_id])

    const onInputChange = useCallback(( field, value) => {
        const newTag = Object.assign({}, tag)
        newTag[field] = value;
        setTag(newTag);
    }, [tag])

    const onSave = useCallback(async () => {
        setErr(false)
        let newState = Object.assign({}, tag);

        if(!newState.name) {
            return setErr([`A tag must have a name.`])
        }
        
        setTag(newState)

        let saved;

        // toggle loaders and update/create the tag
        toggleStandardLoader(true)
        if(newState._id) {
            saved = await api.tags.update(newState._id, newState, true);
        } else {
            saved = await api.tags.create({...newState, division: selected_division._id}, true);
        }
        toggleStandardLoader(false)

        // show error messages if unsuccessful
        if(saved.success) {
            toggleAlertBS(false, "Tag Saved Successfully")
            fetchData();
            if(toggleModal) toggleModal();
            if(onSaved) onSaved(saved.data)
        } else {
            return setErr(saved.message);
        }
    
    }, [tag, fetchData, toggleModal, onSaved, selected_division._id])

    useEffect(() => {
        if(toggleModal) {
            if(showModal) {
                fetchData()
            } else {
                setErr(null)
                setTag('')
            }
        } else {
            fetchData()
        }
    }, [showModal, toggleModal, fetchData])

    useEffect(() => {
        if(!selected_division._id) {
            toggleAlertBS('info', 'A division must be created for editing a tag.')
            setRedirect('/system/divisions/create')
        }
    }, [selected_division._id])

    if(redirect) return <Redirect to={redirect} />

    return (
        <Modalify 
            size="md"
            showModal={showModal}
            toggleModal={toggleModal}
            modalTitle={tag && tag._id ? "Update Tag" : "Create Tag"}
            err={err}
            footer={(
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            )}
            sections={!tag ? [] :  [
                (
                    <div>

                        <FormGroup>
                            <label className="form-control-label">Name*</label>
                            <Input 
                                type="text"
                                value={tag.name || ''}
                                onChange={e => onInputChange('name', e.target.value)}
                            />
                        </FormGroup>
                    </div>
                ),
                ...(tag._id ? [(
                    <Associations 
                        type="tag" 
                        _id={tag._id} 
                        classNames="border p-3 bg-secondary" 
                    />
                )] : []),
            ]}
        />
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SystemTagsEdit);