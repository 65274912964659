import { FormGroup, Input } from 'reactstrap';

import formatText from 'utils/formatText';

const Tab3 = ({contacts, hints, usePassword, passwords, onSetHints, onSetPasswords, onSetUsePasswords}) => (
    <div className="modal-body">

        <p className="mb-0">If you wish to add a password for any client before they sign this document you may enter it here. For instance, if you wish a client to enter their birthday before signing a document enter their birthday as the password and enter their password hint as "Enter you birthday MM/DD/YYYY" </p>

        <div className="table-responsive">
            <table className="table table-no-effects mb-0 border-left border-right border-bottom my-3">
                <thead>
                    <tr>
                        <th>Clients</th>
                        <th>Enabled</th>
                        <th>Hint</th>
                        <th>Password</th>
                    </tr>
                </thead>

                <tbody>
                    {contacts.map((contact, i) => (
                        <tr key={contact._id}>
                            <td> {contact.display_name ? contact.display_name : contact.email ? contact.email : contact.phone ? formatText(contact.phone).phone() : 'No Identifier Found'}</td>
                            <td>
                                <FormGroup>
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            className="custom-control-input"
                                            id={`archk-password-${i}`}
                                            type="checkbox"
                                            checked={usePassword[`password${i + 1}`]}
                                            onChange={(e) => onSetUsePasswords(`password${i + 1}`, !usePassword[`password${i + 1}`])}
                                        />
                                        <label className="custom-control-label" htmlFor={`archk-password-${i}`} />
                                    </div>
                                </FormGroup>
                            </td>
                            <td>
                                <FormGroup>
                                    <Input 
                                        disabled={!usePassword[`password${i + 1}`]}
                                        value={hints[`hint${i + 1}`]}
                                        onChange={(e) => onSetHints(`hint${i + 1}`, e.target.value)}
                                        placeholder="Enter your birthday MM/DD/YYYY"
                                    />
                                </FormGroup>
                            </td>
                            <td>
                                <FormGroup>
                                    <Input 
                                        disabled={!usePassword[`password${i + 1}`]}
                                        value={passwords[`password${i + 1}`]}
                                        onChange={(e) => onSetPasswords(`password${i + 1}`, e.target.value)}
                                    />
                                </FormGroup>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
       
    </div>
)

export default Tab3;