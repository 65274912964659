import PropTypes from 'prop-types';
import { useState, useCallback } from "react";
import { UncontrolledTooltip } from 'reactstrap';

import ModalUpdateName from '../Modals/UpdateName/index'

import { getMarkerPositionOnPage, getMarkerIcon, getMarkerName } from '../_functions/utils';

const CanvasMarkers = ({markers, setMarkerType, removeMarker, updateMarkerName, showingParty, isTemplate, configuration}) => {

    const [state, setState] = useState({
        showModal: false,
        marker: {},
        index: 0
    })

    const { showModal, marker, index } = state;

    const getStyle = useCallback((_marker) => {
        return {
            position    : 'absolute',
            ...getMarkerPositionOnPage (_marker)
        }
    }, [])

    const getToolbarClass = useCallback((_marker, type) => {
        return _marker.type === type ? "list-style-none d-inline-block active bg-dark" : "list-style-none d-inline-block bg-dark"
    }, [])

    const toggleModal = useCallback((marker, index) => {
        setState({marker, index, showModal: !state.showModal})
    }, [state.showModal])

    const onSetTextField = useCallback((marker, i) => {
        setMarkerType(i, 'text')
        toggleModal(marker, i)
    }, [toggleModal, setMarkerType])

    return (
        <div>

            {markers.map((marker, i) => (
                <span 
                    key={marker.top.toString() + marker.left.toString()} 
                    data-marker-index={i} 
                    className={`pdf-marker z-depth-2 ${marker.party === 0 ? 'is-markup' : ''} ${showingParty === marker.party ? 'active' : 'inactive'}`} 
                    style={getStyle(marker)} 
                >

                    <div style={{width: marker.right - marker.left, overflow: 'hidden'}} className="marker-name">
                        <span className={marker.type !== 'text' ? 'text-lowercase' : ''}>
                        {getMarkerName(marker)}
                        </span>
                    </div>

                    <div className="position-relative">
                        <span className="toolbar" style={{ minWidth: '100%', left: marker > 500 ? 'auto' : 0, right: marker > 500 ? 0 : 'auto', }}>
                            <ul className="pl-0 mb-0">
                                <li
                                    id="archk-canvas-toolbar-text"
                                    onClick={() => onSetTextField(marker, i)}
                                    className={getToolbarClass(marker, 'text')}
                                >
                                    <i className="fas fa-text-width" />
                                    <UncontrolledTooltip delay={0} placement="top" target="archk-canvas-toolbar-text" >
                                        Text Field
                                    </UncontrolledTooltip>
                                </li>
                                {showingParty !== 0 ? (
                                    <>
                                    <li
                                        id={`archk-canvas-toolbar-date-${marker.right}-${marker.left}`}
                                        onClick={() => setMarkerType(i, 'date')}
                                        className={getToolbarClass(marker, 'date')}
                                    >
                                        <i className="fas fa-calendar" />
                                        <UncontrolledTooltip delay={0} placement="top" target={`archk-canvas-toolbar-date-${marker.right}-${marker.left}`} >
                                            Date Field
                                        </UncontrolledTooltip>
                                    </li>
                                    <li
                                        id={`archk-canvas-toolbar-initial-${marker.right}-${marker.left}`}
                                        onClick={() => setMarkerType(i, 'initial')}
                                        className={getToolbarClass(marker, 'initial')}
                                    >
                                        <i className="fas fa-signature" />
                                        <UncontrolledTooltip delay={0} placement="top" target={`archk-canvas-toolbar-initial-${marker.right}-${marker.left}`} >
                                            Initial Field
                                        </UncontrolledTooltip>
                                    </li>
                                    <li
                                        id={`archk-canvas-toolbar-signature-${marker.right}-${marker.left}`}
                                        onClick={() => setMarkerType(i, 'signature')}
                                        className={getToolbarClass(marker, 'signature')}
                                    >
                                        <i className="fas fa-file-signature" />
                                        <UncontrolledTooltip delay={0} placement="top" target={`archk-canvas-toolbar-signature-${marker.right}-${marker.left}`} >
                                            Signature Field
                                        </UncontrolledTooltip>
                                    </li>
                                    </>
                                ) : null}
                                <li
                                    id={`archk-canvas-toolbar-remove-${marker.right}-${marker.left}`}
                                    onClick={() => removeMarker(i)}
                                    className={getToolbarClass(marker, 'delete')}
                                >
                                    <i className="fas fa-trash text-danger" />
                                    <UncontrolledTooltip delay={0} placement="top" target={`archk-canvas-toolbar-remove-${marker.right}-${marker.left}`} >
                                        Remove Field
                                    </UncontrolledTooltip>
                                </li>
                            </ul>
                        </span>
                    </div>

                    <div className="position-relative h-100">
                        {marker.type ? (
                            <span className="identifier z-depth-3 text">
                                {getMarkerIcon(marker)}
                            </span>
                        ) : null}
                    </div>

                </span>
            ))}

            <ModalUpdateName 
                toggleModal={() => toggleModal({}, 0)}
                showModal={showModal}
                updateMarkerName={updateMarkerName}
                name={marker.name}
                index={index}
                isTemplate={isTemplate}
                showingParty={showingParty}
                configuration={configuration}
            />

        </div>
    )
}

CanvasMarkers.propTypes = {
    markers             : PropTypes.array.isRequired,
    setMarkerType       : PropTypes.func.isRequired,
    removeMarker        : PropTypes.func.isRequired,
    updateMarkerName    : PropTypes.func.isRequired,
}

export default CanvasMarkers
