

import DayAndTimeRange from 'components/settings/DayAndTimeRange';
import TimeZone from 'components/settings/TimeZone';

const SystemCallFlowEdit = ({callFlow, onInputChange}) => {

    return (
        <div>

            <TimeZone 
                timezone={callFlow.timezone}
                setTimezone={(value) => onInputChange('timezone', value)}
            />    

            <DayAndTimeRange 
                times={callFlow.times_open}
                setTimes={(value) => onInputChange('times_open', value)}
            />       


        </div>
    )

}

export default SystemCallFlowEdit