import { useEffect, useState } from 'react';
import { FormGroup, Input, Row, Col } from "reactstrap";

import ReactSelect from 'components/functional/inputs/ReactSelect';
import ObjectFinder from 'components/system/Objects/Finder';

import { openDocumentCenter } from 'store/functions/document_center';
import { onDownloadDocument } from 'utils/documents';
    
import { toast } from 'react-toastify'

const SystemCallFlowEdit = ({callFlow, onInputChange}) => {

    // const [auditOptions, setAuditOptions] = useState([])

    // useEffect(() => {
    //     let values = []
    //     for (let i = 1; i < 101; i++) {
    //         values.push({label: i + '%', value: i}) 
    //     }

    //     setAuditOptions(values)
    // }, [])

    const onIntentClipUploaded = (documents) => {
        const doc = documents ? documents[documents.length - 1 ] : ''
        if(!doc) return;
        if(doc.mimetype !== "audio/mpeg") return toast.info(`An MP3 file must be uploaded here.`)
        onInputChange('gather_intent_clip', doc._id)
    }


    return (
        <div>

            <FormGroup>
                <label className="form-control-label">Route Name</label>
                <Input 
                    type="text"
                    value={callFlow.name || ''}
                    onChange={e => onInputChange('name', e.target.value)}
                />
            </FormGroup>    

            {callFlow.gather_intent ? (
                <div>
                    <hr />
                    <h2>Audio Clips</h2>
                    <Row>
                        <Col md={8} className="align-self-center">
                            <p className='text-sm mb-0'>Gather Intent Audio - This will play when a enters the call flow.</p>
                            <p className='text-sm mb-0'>
                                {callFlow.gather_intent_clip ? (
                                    <b className='text-dark font-weight-bold'>
                                        <ObjectFinder collection="company_documents" _id={callFlow.gather_intent_clip} />{' '}
                                        <i onClick={() => onInputChange('gather_intent_clip', null)}  className="fas fa-trash ml-3 text-danger cursor-pointer" />
                                        <i onClick={() => onDownloadDocument(callFlow.gather_intent_clip, 'company_documents')}  className="fas fa-download ml-3 text-info cursor-pointer" />
                                    </b> 
                                ) : 
                                    <span><i className="fas fa-info-circle text-warning mr-2" /> No Audio Selected</span>
                                }
                            </p>
                        </Col>
            
                        <Col className='text-right align-self-center'>
                            <button onClick={() => openDocumentCenter({url: '/v1/company_documents', onUpload: onIntentClipUploaded})} className="btn btn-info">Upload Audio</button>
                        </Col>
                    </Row>
                    <hr />
                </div>
            ) : ''}
            

            <ReactSelect 
                formGroup={true}
                title="Unknown Consideration"
                description={`After the first incoming call a contact is no longer considered known. This setting allows for the changing of how long we consider someone unknown. Think "A lead calls, in and calls back in 5 minutes, how long should we route them to the unknown flow?"`}
                value={callFlow.unknown_consideration}
                onChange={(obj) => onInputChange('unknown_consideration', obj.value)}
                options={[
                    { value: 60, label: '60 Seconds' },
                    { value: 5 * 60, label: '5 Minutes' },
                    { value: 10 * 60, label: '10 Minutes' },
                    { value: 15 * 60, label: '15 Minutes' },
                    { value: 30 * 60, label: '30 Minutes' },
                    { value: 45 * 60, label: '45 Minutes' },
                    { value: 60 * 60, label: '1 Hour' },
                    { value: 60 * 60 * 2, label: '2 Hours' },
                    { value: 60 * 60 * 4, label: '4 Hours' },
                    { value: 60 * 60 * 6, label: '6 Hours' },
                    { value: 60 * 60 * 8, label: '8 Hours' },
                    { value: 60 * 60 * 16, label: '16 Hours' },
                    { value: 60 * 60 * 24, label: '24 Hours' },
                    { value: 60 * 60 * 24 * 3, label: '3 Days' },
                    { value: 60 * 60 * 24 * 7, label: '7 Days' },
                    { value: 60 * 60 * 24 * 14, label: '14 Days' },
                    { value: 60 * 60 * 24 * 21, label: '21 Days' },
                    { value: 60 * 60 * 24 * 28, label: '28 Days' },
                    { value: 60 * 60 * 24 * 30, label: '30 Days' },
                    { value: 60 * 60 * 24 * 45, label: '45 Days' },
                    { value: 60 * 60 * 24 * 60, label: '60 Days' },
                    { value: 60 * 60 * 24 * 90, label: '90 Days' },
                    { value: 60 * 60 * 24 * 120, label: '120 Days' },
                    { value: 60 * 60 * 24 * 150, label: '150 Days' },
                    { value: 60 * 60 * 24 * 180, label: '180 Days' },
                    { value: 60 * 60 * 24 * 210, label: '210 Days' },
                    { value: 60 * 60 * 24 * 240, label: '240 Days' },
                    { value: 60 * 60 * 24 * 270, label: '270 Days' },
                    { value: 60 * 60 * 24 * 300, label: '300 Days' },
                    { value: 60 * 60 * 24 * 365, label: '365 Days' },
                ]}
            />

            {/* <ReactSelect 
                formGroup={true}
                title="Audit Percentage"
                description="If an audit percentage is set randomly selected calls will be pulled into a dashboard for auditing purposes."
                value={callFlow.audit_percentage}
                onChange={(obj) => onInputChange('audit_percentage', obj.value)}
                options={auditOptions}
            /> */}

            <ReactSelect 
                formGroup={true}
                title="Record Calls"
                value={callFlow.record}
                onChange={(obj) => onInputChange('record', obj.value)}
                options={[
                    { value: false, label: 'No' },
                    { value: true, label: 'Yes' }
                ]}
            />
           
            <ReactSelect 
                formGroup={true}
                title="Gather Caller Intent"
                value={callFlow.gather_intent}
                onChange={(obj) => onInputChange('gather_intent', obj.value)}
                options={[
                    { value: false, label: 'No' },
                    { value: true, label: 'Yes' }
                ]}
            />

           

        </div>
    )

}

export default SystemCallFlowEdit

{/* <ReactSelect 
    formGroup={true}
    title="Time In Hotline"
    description="If set, all calls waiting in the hotline will be forced to dial a phone number of your choice after the specified time below."
    value={callFlow.hotline_time}
    onChange={(obj) => onInputChange('hotline_time', obj.value)}
    options={[
        { value: 0, label: 'NONE' },
        { value: 15, label: '15 Seconds' },
        { value: 30, label: '30 Seconds' },
        { value: 45, label: '45 Seconds' },
        { value: 60, label: '60 Seconds' },
        { value: 75, label: '75 Seconds' },
        { value: 90, label: '90 Seconds' },
        { value: 2 * 60, label: '2 Minutes' },
        { value: 3 * 60, label: '3 Minutes' },
        { value: 4 * 60, label: '4 Minutes' },
        { value: 5 * 60, label: '5 Minutes' },
        { value: 6 * 60, label: '6 Minutes' },
        { value: 7 * 60, label: '7 Minutes' },
        { value: 8 * 60, label: '8 Minutes' },
        { value: 9 * 60, label: '9 Minutes' },
        { value: 10 * 60, label: '10 Minutes' },
        { value: 15 * 60, label: '15 Minutes' },
        { value: 20 * 60, label: '20 Minutes' },
        { value: 25 * 60, label: '25 Minutes' },
        { value: 30 * 60, label: '30 Minutes' },
        { value: 35 * 60, label: '35 Minutes' },
        { value: 40 * 60, label: '40 Minutes' },
        { value: 45 * 60, label: '45 Minutes' },
        { value: 50 * 60, label: '50 Minutes' },
        { value: 55 * 60, label: '55 Minutes' },
        { value: 60 * 60, label: '60 Minutes' },
    ]}
/> */}
