import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import routes from "./routes";

class RedirectLayout extends React.Component {
    state = {
        loaded: false,
        sidenavOpen: true,
        showMobileNav: false,
    };

    //get routes for this layout
    getRoutes = routes => routes().map((prop, key) => {

        return (
            <Route
                exact path={`/redirect${prop.path}`}
                component={prop.component}
                key={key}
            />
        )
    })

    

    render() {

        return (
            <div className="">

                <Switch>
                    {this.getRoutes(routes)}
                    <Redirect from="*" to="/" />
                </Switch>
            </div>

        );
    }
}

export default RedirectLayout