import { useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardHeader, Input } from "reactstrap";

import api from 'api';

import TextButtonRow from 'components/markup/layout/TextButtonRow'
import ConfirmationModal from 'components/functional/modals/Confirmation'
import TableIcons from 'components/markup/icons/TableIcons'

import { toggleAlertBS } from "store/functions/system/system";
import moment from "moment";
import LoadingTable from 'components/markup/loading/Table';

import ApiError from "components/markup/layout/ApiError";

const SystemWorkflowAll = ({setTab, selected_division}) => {
    const [ tableData, setTableData ] = useState(null)
    const [ workflowToDelete, setWorkflowToDelete ] = useState(false)
    const [ error, setError ] = useState(false);
    const [ search, setSearch ] = useState('');

    // helper for this.downloadCSV and this.query
    const fetchData = useCallback(() => new Promise (async resolve => {
        const query = await api.workflows.find()
        if(query.data) {
            return setTableData(query.data.filter(workflow => workflow.division === selected_division._id))
        } else {
            setError(query.message)
        }
    }), [selected_division._id])

    const onDeleteWorkflow = useCallback(async () => {
        const deleted = await api.workflows.delete(workflowToDelete._id);
        if(deleted.success) {
            fetchData();
            toggleAlertBS(false, 'Workflow Successfully Removed')
        } else {
            toggleAlertBS(true, `Something's not right, please try again.`)
        }
    }, [fetchData, workflowToDelete])

    const shouldRender = useCallback((workflow) => {
        if(!search) return true;
        
        const s = search.toLowerCase().trim();
        const n = workflow.name.toLowerCase().trim();
        if(n.includes(s)) return true;
        if(n === s) return true;

        return false;
    }, [search])


    useEffect(() => {
        fetchData()
    }, [fetchData])
 
    if(error) return <ApiError err={error} />

    const results = search && tableData ? tableData.filter(workflow => shouldRender(workflow)) : tableData

    return (

        <>

            <TextButtonRow 
                title={"All Workflows"}
                button={(
                    <button onClick={() => setTab('create')} className="btn btn-success"><i className="fas fa-plus mr-2 " /> Add New</button>
                )}
            />

            <Card className="card-color card-primary table-fixed">
                <CardHeader className="pl-2 pr-4 pb pt-3 pb-3 ml-1">
                    <Input 
                        className="ml-1"
                        style={{width: 190, maxWidth: '100'}}
                        placeholder="Search..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </CardHeader>
                
                {!results ?  <LoadingTable /> : (
                    <div className="table-responsive ">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Workflow</th>
                                    <th className="text-right">Created</th>
                                    <th className="text-right">Date</th>
                                </tr>
                            </thead>

                            <tbody>
                                {results.length ? (
                                    results.map(workflow => (
                                        <tr key={workflow._id}>
                                            <td>
                                                <Link className="text-capitalize" to={`/editor/workflows/${workflow._id}`}>
                                                    <span className="cursor-pointer">{workflow.name}</span>
                                                </Link>
                                            </td>

                                            <td className="text-right">
                                                <div>{moment.unix(workflow.created_at).format('MMM Do, YYYY')}</div>
                                            </td>

                                            <td className="text-right">
                                                <TableIcons
                                                    icons={[
                                                        { 
                                                            icon: 'fas fa-edit',
                                                            color: 'success', 
                                                            wrapper: Link, 
                                                            to: `/editor/workflows/${workflow._id}`,
                                                            workflow_id: workflow._id
                                                        },
                                                        {  
                                                            icon: 'fas fa-trash', 
                                                            color: 'danger',
                                                            onClick: () => setWorkflowToDelete(workflow)
                                                        },
                                                    ]}
                                                />

                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td>No Workflows Found</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}

                            </tbody>
                        </table>
                    </div>
                )}

            </Card>

            <ConfirmationModal 
                showModal={workflowToDelete ? true : false}
                toggleModal={() => setWorkflowToDelete(null)}
                title="Delete Workflow"
                body={(
                    <span>
                        Are you sure you wish to delete the workflow {workflowToDelete ? workflowToDelete.name : ''}? This will prevent any associations from being attached to it in the future.
                    </span>
                )}
                onConfirmation={onDeleteWorkflow}
            />

        </>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SystemWorkflowAll);