import Circle from 'components/markup/loading/Circle';
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, CardTitle, Col, FormGroup, Input, Row } from 'reactstrap';

import api from 'api';
import { toast } from 'react-toastify';

import LinkRow from './LinkRow';


const WorkflowEditLinks = ({workflow}) => {

    const [name, setName] = useState('')
    const [link, setLink] = useState('')
    const [description, setDescription] = useState('')

    const [allLinks, setAllLinks] = useState(workflow.allLinks)

    const _handleKeyDown = useCallback((e) => {
		if (e.key === 'Enter') document.getElementById("archk-add-link-button").click();
	}, [])

    const onAdd = useCallback(async () => {
        if(!name || !description || !link) return toast.info("Both a name and link type must be specified to create a workflow link.")
        const _allLinks = JSON.parse(JSON.stringify(allLinks));

            const created = await api.workflow_links.create({
                workflow: workflow._id,
                name: name,
                link: link,
                description: description,
            })
            if(created.success) {
                _allLinks.push(created.data)
                setAllLinks(_allLinks);
                setName('')   
                setLink('')   
                setDescription('')   
            } else {
                toast.error("Something went wrong creating this link, please try again and make sure the link is unique.")
            }
      
    }, [allLinks, name, link, description, workflow._id])
    
    const onDelete = useCallback(async (_link) => {
        let _allLinks = JSON.parse(JSON.stringify(allLinks));

        const deleted = await api.workflow_links.delete(_link._id)
        if(deleted.success) {
            _allLinks = _allLinks.filter(r => r._id !== deleted.data._id)
            setAllLinks(_allLinks);
        } else {
            toast.error("Something went wrong deleting this link, please try again.")
        }
    }, [allLinks])

    const fetchData = useCallback(async () => {
        const links = await api.workflow_links.find(workflow._id);
        if(links.data) setAllLinks(links.data)
    }, [workflow._id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <Card>
            <CardHeader>
                <CardTitle>Add Workflow Link</CardTitle>
                <p className="text-sm mb-0"><i className="fas fa-info-circle mr-2 info-circle text-info " /> Workflow links allow you to create connections to outbound websites/softwares inside of a matter. For instance you can create a link <b className='text-info'>https://mysoftware.com/contact/{`[[value]]`}</b> and inside the matter a team member can specify the contact id. This will create a link that opens up a profile outside of Intuidesk injecting the value of the link at {`[[value]]`}. For a static link not requiring a value do not include {`[[value]]`} in the link. Users can set the {`[[value]]`} to whatever is required inside of a case file.</p>

            </CardHeader>
            <CardHeader>
                <Row>
                    <div className="col-auto" style={{width: 'calc(35% - 84px)'}}>
                        <label className="form-control-label">Link Name*</label>
                    </div>

                    <div className="col-auto" style={{width: 'calc(35% - 84px)'}}>
                        <label className="form-control-label">Description*</label>
                    </div>
                    <div className="col-auto" style={{width: 'calc(35% - 84px)'}}>
                        <label className="form-control-label">URL*</label>
                    </div>
                    <Col></Col>
                </Row>

                <Row>
                    <div className="col-auto" style={{width: 'calc(35% - 84px)'}}>
                        <FormGroup>
                            <Input 
                                onKeyDown={_handleKeyDown}
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}

                            />
                        </FormGroup>
                    </div>

                    <div className="col-auto" style={{width: 'calc(35% - 84px)'}}>
                        <FormGroup>
                            <Input 
                                onKeyDown={_handleKeyDown}
                                type="text"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}

                            />
                        </FormGroup>
                    </div>
                    <div className="col-auto" style={{width: 'calc(35% - 84px)'}}>
                        <FormGroup>
                            <Input 
                                onKeyDown={_handleKeyDown}
                                type="text"
                                value={link}
                                onChange={(e) => setLink(e.target.value)}

                            />
                        </FormGroup>
                    </div>
                    <Col>
                        <button 
                            id="archk-add-link-button"
                            onClick={onAdd} 
                            className="btn btn-outline-info btn-block"
                        >
                            <i className="fas fa-plus mr-2 " /> Add Link
                        </button>
                    </Col>
                </Row>
            </CardHeader>

            {!allLinks ? <Circle /> : (
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Link Name</th>
                                <th>Description</th>
                                <th>URL</th>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allLinks.length ? allLinks.map((link) => <LinkRow link={link} onDelete={onDelete} />) : (
                                <tr>
                                    <td>No links have been created yet for this workflow</td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}


        </Card>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(WorkflowEditLinks);