// /*
// Documentation

// this component works with the redux store "object"

// it takes a collection name and and _id, if the _id is not found on the collection
// store "objects" we look up it and its name from the server. If it is found we pull
// its value through by memory.

// very useful for returning the names of objects that we only have an id of without
// needing to run a full find query to the database when only a few objects are needed by id
// _
// */

// import { useState, useEffect, useCallback, memo } from 'react';
// import { connect } from 'react-redux';

// import api from 'api'
// import { setObjects } from 'store/functions/objects'

// import formatObject from 'utils/formatObject'
// import Dots from 'components/markup/loading/Dots';

// const ObjectFinder = ({collection, _id, objects, errMessage, populate}) => {
//     const [foundObject, setFoundObject] = useState(populate ? null : objects[collection][_id] ? objects[collection][_id] : null)
//     const [err, setErr] = useState(false)

//     const query = useCallback(async () => {
//         const obj = await api.query.findById(collection, _id, populate);
//         if(obj.data) {
//             if(!populate) setObjects(collection, obj.data)
//             setFoundObject(obj.data)
//         } else {
//             setErr(true)
//         }
//     }, [collection, _id, populate])

//     useEffect(() => {
//         if(!foundObject) query()
//     }, [query, foundObject]);

//     if(err) return errMessage ? errMessage : 'Document Not Found';
//     if(foundObject === null) return <Dots />

//     let string = _id;
//     if(foundObject.name) {
//         string = foundObject.name;
//     } else if(foundObject.given_name) {
//         string = formatObject(foundObject.name).name()
//     } else {
//         string = errMessage ? errMessage : "Name Not Found";
//     }

//     return string;
// }


// const mapStateToProps = state => {
// 	return {
// 	    objects: state.objects,
// 	};
// };

// export default memo(connect(mapStateToProps, '')(ObjectFinder));


/*
Documentation

this component works with the redux store "object"

it takes a collection name and and _id, if the _id is not found on the collection
store "objects" we look up it and its name from the server. If it is found we pull
its value through by memory.

very useful for returning the names of objects that we only have an id of without
needing to run a full find query to the database when only a few objects are needed by id
_
*/

import { useState, useEffect, memo } from 'react';
import { connect } from 'react-redux';

import api from 'api';
import { setMultipleObjects } from 'store/functions/objects'

import formatObject from 'utils/formatObject'
import Dots from 'components/markup/loading/Dots';

let queue = {};
let timeout;

const batchQueue = (collection, _id) => {
    if(!queue[collection]) {
        queue[collection] = []
    }

    if(!queue[collection].includes(_id)) {
        queue[collection].push(_id)
    }
    clearTimeout(timeout)
    let tries = 0
    timeout = setTimeout(async () => {
        tries ++;
        if(tries > 5) return
        const keys = Object.keys(queue);
        const collections = queue;
        queue = {}
        if(!keys) return;
        const data = await api.query.batch(collections);
        if(data.data) {
            Object.keys(data.data).forEach(key => {
                if(!data.data[key].length) {
                    delete data.data[key];
                }
            })
            if(Object.keys(data.data).length) setMultipleObjects(data.data)
        }
    }, 100)
}

const ObjectFinder = ({collection, _id, errMessage, populate, objects, formatter}) => {
    // we only want to get the store at the time this loads so we don't wrap this in
    // react-redux connect; This will cause this component to rerender any time an 
    // object gets added to state
    // const objects = store.getState().objects;
    const [foundObject, setFoundObject] = useState(null)
    const [err, setErr] = useState(null)

    useEffect(() => {
        let timeout = setTimeout(() => {
            if(!foundObject) setErr(true)
        }, 3000)

        return () => {
            clearTimeout(timeout)
        }
    }, [foundObject])

    useEffect(() => {

        if(!foundObject) {
            try {
                const obj = objects[collection][_id] ? objects[collection][_id] : null;
                if(obj) return setFoundObject(obj);
                batchQueue(collection, _id, populate)
            } catch(e) {
                console.log(collection + ' is not found in redux store')
            }
        }
    }, [collection, _id, foundObject, populate, objects]);

    if(err) return <><i className="fas fa-info-circle mr-2 text-info" /> Item Not Found</>
    if(foundObject === null) return <Dots />
    if(formatter) return formatter(foundObject)
    let string = _id;
    if(foundObject.display_name) {
        string = foundObject.display_name
    } else if(foundObject.name) {
        string = foundObject.name;
    } else if(foundObject.title) {
        string = foundObject.title;
    } else if(foundObject.given_name) {
        string = formatObject(foundObject.name).name()
    } else if(foundObject.outcome) {
        string = foundObject.outcome
    }  else {
        string = errMessage ? errMessage : "Name Not Found";
    }

    return string;
}


const mapStateToProps = state => {
	return {
	    objects: state.objects,
	};
};

export default memo(connect(mapStateToProps, '')(ObjectFinder));