import { connect } from 'react-redux';

import SearchCollections from 'components/system/Search/Collections';


const SystemCallFlowEdit = ({task, onSetTask, selected_division}) => {

    return (
        <div className='mb-4'>
                    
            <hr />

            <div>
                <SearchCollections
                    collection="call_queues" 
                    title={`Select The Queue This Call Will Be Assigned To`}
                    value={task.call_queue_dial}
                    onChange={(obj) => onSetTask(`call_queue_dial`, obj.value)}
                    filter={{
                        division: selected_division._id
                    }}
                /> 

            </div>

        </div>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SystemCallFlowEdit);