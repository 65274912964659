import AutomationsAll from './All'
import AutomationsEdit from './components/Edit'

import IntuideskSectionWrapper from 'components/markup/layout/IntuideskSectionWrapper'

const AutomationsAutomations = ({match, section}) => (
    <IntuideskSectionWrapper 
        title="Automations"
        editTitle="Automation"
        match={match}
        section={section}
        innerSection={'automations'}
        Edit={AutomationsEdit}
        All={AutomationsAll}
    />
)

export default AutomationsAutomations;