import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input } from "reactstrap";

import api from 'api';

import Modalify from 'components/functional/modals/Modalify';

import { toast } from 'react-toastify';
import { toggleStandardLoader } from 'store/functions/system/system';

const FormInput = ({onInputChange, keys, baseKeys, prop, title, description}) => {
    const isBaseKey = keys[prop] === baseKeys[prop]
    return (
        (
            <FormGroup>
                <label className="form-control-label">{title}</label>
                {description ? (
                    <p className="text-sm mb-0">
                        <i className="fas fa-info-circle text-info"></i> {description}</p>
                ) : ''}
               
                <Input 
                    type="text"
                    value={isBaseKey ? '' : keys[prop] || ''}
                    placeholder={isBaseKey && keys[prop] ? '......' + keys[prop] : ''}
                    onChange={e => onInputChange(prop, !e.target.value && baseKeys[prop] ? baseKeys[prop] : e.target.value)}
                />
            </FormGroup>
        )
    )
}

const CompanyUpdateNames = ({onSaved, showModal, toggleModal, viewing_user}) => {

    const [keys, setKeys] = useState(null)
    const [baseKeys, setBaseKeys] = useState(null)
    const [err, setErr] = useState(null)

    const fetchData = useCallback(async () => {
        const company = await api.stored_keys.find()

        if(!company.data) return setErr(true);
        setKeys(company.data)
        setBaseKeys(company.data)
    }, [])

    const onInputChange = useCallback(( field, value) => {
        const newKeys = JSON.parse(JSON.stringify(keys))
        newKeys[field] = value;
        setKeys(newKeys);
    }, [keys])

    const onSave = useCallback(async () => {
        setErr(false)

        const objKeys = Object.keys(keys)
        const changedKeys = {}

        objKeys.forEach(k => {
            if(keys[k] !== baseKeys[k]) changedKeys[k] = keys[k]
        })

        // toggle loaders and update/create the company
        toggleStandardLoader(true)
        let saved = await api.stored_keys.save(changedKeys);
        toggleStandardLoader(false)

        // show error messages if unsuccessful
        if(saved.success) {
            toast.success('Keys Updated Successfully')
            if(toggleModal) toggleModal();
            if(onSaved) onSaved(saved.data)
        } else {
            return setErr(saved.message);
        }
    
    }, [keys, toggleModal, onSaved, baseKeys])

    useEffect(() => {
        if(toggleModal) {
            if(showModal) {
                fetchData()
            } else {
                setErr(null)
                setKeys('')
            }
        } else {
            fetchData()
        }
    }, [showModal, toggleModal, fetchData])

    return (
        <Modalify 
            showModal={showModal}
            toggleModal={toggleModal}
            modalTitle={"Edit Keys"}
            err={err}
            footer={(
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            )}
            sections={!keys ? [] :  [
                (
                    <div>
                        <h2>SYSTEM API KEYS</h2>
                        <p className="text-sm mb-0 text-warning font-weight-bold">These are your divisions API keys for connecting with external providers.</p>
                        <p className="text-sm mb-0 text-warning font-weight-bold">Once added they are encrypted and will never be shown again minus the last 4 digits.</p>
                        <p className="text-sm mb-0 text-warning font-weight-bold">Do not share these with anyone as they make your license of IntuiDesk work.</p>
                        <p className="text-sm mb-0 text-warning font-weight-bold">Note that api keys can be updated per division should you need to change out your connected accounts on a granular level.</p>
                    </div>
                ),
                (
                    <div>
                        <h2>Twilio</h2>

                        <FormInput 
                            prop="TWILIO_ACCOUNT_SID"
                            title="Twilio Account SID"
                            description=""
                            keys={keys}
                            baseKeys={baseKeys}
                            onInputChange={onInputChange}
                        />
                       
                        <FormInput 
                            prop="TWILIO_API_KEY"
                            title="Twilio API Key"
                            description=""
                            keys={keys}
                            baseKeys={baseKeys}
                            onInputChange={onInputChange}
                        />

                        <FormInput 
                            prop="TWILIO_API_SECRET"
                            title="Twilio API SECRET"
                            description=""
                            keys={keys}
                            baseKeys={baseKeys}
                            onInputChange={onInputChange}
                        />

                        <FormInput 
                            prop="TWILIO_APP_SID"
                            title="Twilio APP SID"
                            description=""
                            keys={keys}
                            baseKeys={baseKeys}
                            onInputChange={onInputChange}
                        />
                        
                        <FormInput 
                            prop="TWILIO_AUTH_TOKEN"
                            title="Twilio Auth Token"
                            description=""
                            keys={keys}
                            baseKeys={baseKeys}
                            onInputChange={onInputChange}
                        />
                        

                    </div>
                ),
                (
                    <div>
                        <h2>Payconex</h2>

                        <FormInput 
                            prop="PAYCONEX_ACCOUNT_ID"
                            title="Payconex Account ID"
                            description=""
                            keys={keys}
                            baseKeys={baseKeys}
                            onInputChange={onInputChange}
                        />
                       
                        <FormInput 
                            prop="PAYCONEX_API_KEY"
                            title="Payconex API Key"
                            description=""
                            keys={keys}
                            baseKeys={baseKeys}
                            onInputChange={onInputChange}
                        />

                        <FormInput 
                            prop="PAYCONEX_API_SECRET"
                            title="Payconex API Secret"
                            description=""
                            keys={keys}
                            baseKeys={baseKeys}
                            onInputChange={onInputChange}
                        />

                    </div>
                ),
                (
                    <div>
                        <h2>Sendgrid</h2>

                        <FormInput 
                            prop="SENDGRID_API_KEY"
                            title="Sendgrid API Key"
                            description=""
                            keys={keys}
                            baseKeys={baseKeys}
                            onInputChange={onInputChange}
                        />
                       
                    </div>
                ),
                (
                    <div>
                        <h2>Nylas</h2>

                        <FormInput 
                            prop="NYLAS_CLIENT_ID"
                            title="Nylas Client ID"
                            description=""
                            keys={keys}
                            baseKeys={baseKeys}
                            onInputChange={onInputChange}
                        />

                        <FormInput 
                            prop="NYLAS_SECRET_KEY"
                            title="Nylas Secret Key"
                            description=""
                            keys={keys}
                            baseKeys={baseKeys}
                            onInputChange={onInputChange}
                        />
                        
                        <FormInput 
                            prop="NYLAS_WEBHOOK_SECRET"
                            title="Nylas Webhook Secret"
                            description=""
                            keys={keys}
                            baseKeys={baseKeys}
                            onInputChange={onInputChange}
                        />
                       
                    </div>
                ),
                (
                    <div>
                        <h2>Open AI</h2>

                        <FormInput 
                            prop="OPEN_AI_KEY"
                            title="Open AI Key"
                            description=""
                            keys={keys}
                            baseKeys={baseKeys}
                            onInputChange={onInputChange}
                        />
                       
                    </div>
                ),
                (
                    <div>
                        <h2>Court Drive</h2>

                        <FormInput 
                            prop="COURT_DRIVE_APP_ID"
                            title="Court Drive APP ID"
                            description=""
                            keys={keys}
                            baseKeys={baseKeys}
                            onInputChange={onInputChange}
                        />

                        <FormInput 
                            prop="COURT_DRIVE_SECRET_KEY"
                            title="Court Drive API SECRET KEY"
                            description=""
                            keys={keys}
                            baseKeys={baseKeys}
                            onInputChange={onInputChange}
                        />
                       
                    </div>
                ),
                (
                    <div>
                        <h2>Array</h2>

                        <FormInput 
                            prop="ARRAY_APP_KEY"
                            title="Array APP Key"
                            description=""
                            keys={keys}
                            baseKeys={baseKeys}
                            onInputChange={onInputChange}
                        />

                        <FormInput 
                            prop="ARRAY_API_TOKEN"
                            title="Array API Token"
                            description=""
                            keys={keys}
                            baseKeys={baseKeys}
                            onInputChange={onInputChange}
                        />
                       
                    </div>
                ),

            ]}
        />
    )

}


const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(CompanyUpdateNames);