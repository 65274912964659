import keys from 'keys';
import { useEffect, useState } from 'react';
import { FormGroup, Input } from "reactstrap";

import Circle from 'components/markup/loading/Circle';

import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

const AddressLookup = ({onChange, onSelect, title, value}) => {

    const [shouldShow, setShouldShow] = useState(false)
    const [places, setPlaces] = useState([])

    const {
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
      } = usePlacesService({
        apiKey: keys.GOOGLE_API_KEY,
        debounce: 1500
      });
  
    useEffect(() => {
        // fetch place details for the first element in placePredictions array
        if (placePredictions.length) {
            setPlaces(placePredictions.map(p => {
                const a = window.parseAddress.parseLocation(p.description)

                return {
                    description: p.description,
                    address: {
                        address_line_1: a.number + ' ' + a.street + ' ' + a.type,
                        city: a.city,
                        state: a.state,
                    } 
                }
            }))
        }
      }, [placePredictions]);
    


    return (
        <FormGroup className="archk-address-lookup">
            <span className="position-relative">
                <label className="form-control-label">{title}</label>
                <Input
                    debounce={1000}
                    placeholder="Search..."
                    value={value}
                    onBlur={() => {
                        setTimeout(() => {
                            setShouldShow(false)
                        }, 250)
                    }}
                    onChange={(e) => {
                        const val = e.target.value;
                        getPlacePredictions({ input: val });
                        onChange(val)
                        setShouldShow(true)
                    }}
                />

                {isPlacePredictionsLoading ? (
                    <span style={{position: 'absolute', right: 7, top: 23}}>
                        <Circle sm={true}/>
                    </span>
                ) : ''}
                        
                {value && shouldShow && (
                    <div className="archk-address-lookup-results z-depth-1">

                        <div className="archk-address-lookup-results-header">
                            <p className="text-sm font-weight-bold mb-0">
                                <i className="fas fa-search mr-2" /> Results
                            </p>
                        </div>
                        
                        <div className="archk-address-lookup-results-body">
                            {places.map((item, i) => (
                                <div 
                                    onClick={() =>{
                                        onSelect(item.address)
                                        setShouldShow(false)
                                    }} 
                                    key={i} 
                                    className="archk-address-lookup-result"
                                >
                                    {item.description}
                                </div>
                            ))}
                        </div>
                    </div>
                )}

            </span>
        </FormGroup>
    )

}

export default AddressLookup