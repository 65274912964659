import TextTemplatesEdit from './components/Edit'
import TextTemplatesAll from './All'

import IntuideskSectionWrapper from 'components/markup/layout/IntuideskSectionWrapper'

const AutomationsTextTemplates = ({match, section}) => (
    <IntuideskSectionWrapper 
        title="Text Templates"
        editTitle="Text Template"
        match={match}
        section={section}
        innerSection={'text_templates'}
        Edit={TextTemplatesEdit}
        All={TextTemplatesAll}
    />
)

export default AutomationsTextTemplates;