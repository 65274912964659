import CustomFieldEdit from './components/Edit'
import CustomFieldAll from './All'

import IntuideskSectionWrapper from 'components/markup/layout/IntuideskSectionWrapper'

const SystemWikis = ({match, section}) => (
    <IntuideskSectionWrapper 
        title="Custom Fields"
        editTitle="Custom Field"
        match={match}
        section={section}
        innerSection={'custom_fields'}
        Edit={CustomFieldEdit}
        All={CustomFieldAll}
    />
)

export default SystemWikis;