
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { getUrlParameter } from 'utils/urls'
import { Container } from 'reactstrap';
import { log } from 'database';
import moment from 'moment'

class Standard extends Component {

    state = {

    };

    render() {

        const error = getUrlParameter('error')
        const referrerurl = getUrlParameter('referrerurl')
        const time = moment().valueOf()

        log({
            url: referrerurl,
            file: '/src/views/errors/pages/standard.js',
            code: 'Unexpected Client Error',
            text: error
        });

        if(error) {
            return (
                <Container>
                <div className="text-center py-6">
                    <h2 className="text-uppercase mb-4">
                        An unexpected error occurred. <br />
                        <span className="text-underline">We are working on the issue and will have it resolved soon.</span>
                    </h2>


                        <div className="text-sm bg-white p-4 rounded">
                            {/* <h3 className="text-danger">Database Failed To Connect.</h3>

                            <br /> */}

                            <b>Time: {' '}
                                { moment.utc(time, 'x').format('MMMM Do, YYYY')  }
                                {' at '}
                                { moment.utc(time, 'x').format('hh:mm A')  } (GMT)
                            </b>
                        </div>

                </div>
            </Container>
            )
        }

        return (
            <Redirect to="/" />
        );

    }

}

export default Standard;
