import Matters    from '../pages/matters';
import Contacts    from '../pages/contacts';

const routes = () =>  [

    {
        path: "/matters",
        component: Matters,
    },
    {
        path: "/contacts",
        component: Contacts,
    },
   
]

export default routes;
