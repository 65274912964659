import formatText from 'utils/formatText';
import A from 'components/markup/links/A';

const SendDocumentTab3 = ({usePassword, passwords, hints, contacts}) => (
    <div className="modal-body">
        <p className="mb-0">This document is ready to be sent and will go out to <b>{contacts.length}</b> parties. Note that if any passwords were given to sign this document they will never be shown again.</p>

        <div className="table-responsive">
            <table className="table table-no-effects mb-0 border-left border-right border-bottom my-3">
                <thead>
                    <tr>
                        <th>Contact</th>
                        <th>Access</th>
                    </tr>
                </thead>
                <tbody>
                    {contacts[0] ? (
                        <tr>
                            <td style={{width: '50%'}}>
                                <p className="text-sm mb-1 text-uppercase">
                                    <A href={`/contacts/${contacts[0]._id}`}>
                                        {contacts[0].display_name ? contacts[0].display_name : 'Name Not Found'}
                                    </A>
                                </p>
                                <p className="text-sm mb--1">{contacts[0].email ? formatText(contacts[0].email).trimEmail(20) : null}</p>
                                <p className="text-sm mb-0">{contacts[0].phone ? formatText(contacts[0].phone).phone() : null}</p>
                            </td>
                            <td>
                                {usePassword.password1 ? (
                                    <p className="text-sm mb-0">To sign this document this contact will be presented the message <b className="text-dark">"{hints.hint1}"</b>. They will need to enter the following phrase exactly to proceed <b className="text-dark">"{passwords.password1}"</b></p>
                                ) : (
                                    <p className="text-sm mb-0">No Password Set</p>
                                )}
                            </td>
                        </tr>
                    ) : null}
                    {contacts[1] ? (
                        <tr>
                            <td style={{width: '50%'}}>
                                <p className="text-sm mb-1 text-uppercase">
                                    <A href={`/contacts/${contacts[1]._id}`}>
                                        {contacts[1].display_name ? contacts[1].display_name : 'Name Not Found'}
                                    </A>
                                </p>
                                <p className="text-sm mb--1">{contacts[1].email ? formatText(contacts[1].email).trimEmail(20) : null}</p>
                                <p className="text-sm mb-0">{contacts[1].phone ? formatText(contacts[1].phone).phone() : null}</p>
                            </td>
                            <td>
                                {usePassword.password2 ? (
                                    <p className="text-sm mb-0">To sign this document this contact will be presented the message <b className="text-dark">"{hints.hint2}"</b>. They will need to enter the following phrase exactly to proceed <b className="text-dark">"{passwords.password2}"</b></p>
                                ) : (
                                    <p className="text-sm mb-0">No Password Set</p>
                                )}
                            </td>
                        </tr>
                    ) : null}
                    {contacts[2] ? (
                        <tr>
                            <td style={{width: '50%'}}>
                                <p className="text-sm mb-1 text-uppercase">
                                    <A href={`/contacts/${contacts[2]._id}`}>
                                        {contacts[2].display_name ? contacts[2].display_name : 'Name Not Found'}
                                    </A>
                                </p>
                                <p className="text-sm mb--1">{contacts[2].email ? formatText(contacts[2].email).trimEmail(20) : null}</p>
                                <p className="text-sm mb-0">{contacts[2].phone ? formatText(contacts[2].phone).phone() : null}</p>
                            </td>
                            <td>
                                {usePassword.password3 ? (
                                    <p className="text-sm mb-0">To sign this document this contact will be presented the message <b className="text-dark">"{hints.hint3}"</b>. They will need to enter the following phrase exactly to proceed <b className="text-dark">"{passwords.password3}"</b></p>
                                ) : (
                                    <p className="text-sm mb-0">No Password Set</p>
                                )}
                            </td>
                        </tr>
                    ) : null}
                    {contacts[3] ? (
                        <tr>
                            <td style={{width: '50%'}}>
                                <p className="text-sm mb-1 text-uppercase">
                                    <A href={`/contacts/${contacts[3]._id}`}>
                                        {contacts[3].display_name ? contacts[3].display_name : 'Name Not Found'}
                                    </A>
                                </p>
                                <p className="text-sm mb--1">{contacts[3].email ? formatText(contacts[3].email).trimEmail(20) : null}</p>
                                <p className="text-sm mb-0">{contacts[3].phone ? formatText(contacts[3].phone).phone() : null}</p>
                            </td>
                            <td>
                                {usePassword.password4 ? (
                                    <p className="text-sm mb-0">To sign this document this contact will be presented the message <b className="text-dark">"{hints.hint4}"</b>. They will need to enter the following phrase exactly to proceed <b className="text-dark">"{passwords.password4}"</b></p>
                                ) : (
                                    <p className="text-sm mb-0">No Password Set</p>
                                )}
                            </td>
                        </tr>
                    ) : null}
                </tbody>
            </table>
        </div>
    </div>
)


export default SendDocumentTab3;
