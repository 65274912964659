import { connect } from 'react-redux';
import { useState, useEffect, useCallback } from 'react'
import { Modal, FormGroup, Input } from "reactstrap";

import { toast } from 'react-toastify'

import api from 'api'

const inputID = `archk-division-departments-create-input`
const createID = `archk-division-departments-create-button`

const ModalAddDepartment = ({showModal, toggleModal, onSaved, selected_division}) => {

    const [name, setName] = useState('')

    const _handleKeyDown = useCallback((e) => {
        if (e.key === 'Enter') {
            document.getElementById(createID).click();
        }
    }, [])

    const addDepartment = useCallback(async () => {
        if(!name) return toast.info(`A department's name cannot be left blank`)

        const saved = await api.division_departments.create({ name, division: selected_division._id })
        if(!saved.success) return toast.error(`Something's not right, please try again`)

        toggleModal();
        if(onSaved) onSaved(saved.data)
    }, [name, toggleModal, onSaved, selected_division._id])

    useEffect(() => {
        if(!showModal) {
            setName('')
        } else {
            setTimeout(() => {
                const el = document.getElementById(inputID)
                if(el) el.focus();
            }, 350)
        }
    }, [showModal])

    return (

        <Modal
           className="modal-dialog-centered"
           isOpen={showModal}
           toggle={toggleModal}
           size="md"
        >

            <div className="modal-header">
                <h5 className="modal-title">Add A Department</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                    >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">

                <FormGroup>
                    <label className='form-control-label'>Department Name</label>
                    <Input 
                        id={inputID}
                        onKeyDown={_handleKeyDown}
                        type="text"
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                </FormGroup>

            </div>

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
                <button id={createID} className="btn btn-success" onClick={addDepartment}>
                    Add Department
                </button>
            </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
	    selected_division: state.state.selected_division,
    };
};

export default connect(mapStateToProps, '')(ModalAddDepartment);