const colors = {
    green   : 'rgb(45,  206, 137, ',
    teal    : 'rgb(44,  196, 228, ',
    orange  : 'rgb(251, 99,  64, ',
    red     : 'rgb(245, 73,  108, ',
    yellow  : 'rgb(237, 203, 49, ',
    cyan    : 'rgb(43,  255, 198, ',
    purple  : 'rgb(137, 101, 224, ',
    indigo  : 'rgb(86,  3,   173, ',
}

const toExport = {
    colors
}

export default toExport