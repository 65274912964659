import { Input, FormGroup, Row, Col } from "reactstrap";
import { useCallback, useState, useEffect } from 'react';

import api from 'api'

import Modalify from 'components/functional/modals/Modalify'

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

const baseState = {
    name: '',
    value: ''
}

const CompanyStringTemplatesEdit = ({_id, onSaved, showModal, toggleModal}) => {

    const [stringTemplate, setStringTemplate] = useState(baseState)
    const [err, setErr] = useState(null)

    const fetchData = useCallback(async () => {
        if(!_id || _id === 'create') return setStringTemplate({});
        const user = await api.string_templates.findById(_id)
        if(!user.data) return setErr(true);
        setStringTemplate(user.data)
    }, [_id])

    const onInputChange = useCallback(( field, value) => {
        const newStringTemplate = Object.assign({}, stringTemplate)
        newStringTemplate[field] = value;
        setStringTemplate(newStringTemplate);
    }, [stringTemplate])
    
    const onSave = useCallback(async () => {
        setErr(false)
        let newState = Object.assign({}, stringTemplate);

        if(!newState.name) {
            return setErr([`A string template must have a name.`])
        }
        
        setStringTemplate(newState)

        let saved;

        // toggle loaders and update/create the user
        toggleStandardLoader(true)
        if(newState._id) {
            saved = await api.string_templates.update(newState._id, newState, true);
        } else {
            saved = await api.string_templates.create(newState, true);
        }
        toggleStandardLoader(false)

        // show error messages if unsuccessful
        if(saved.success) {
            toggleAlertBS(false, "Template Saved Successfully")
            fetchData();
            if(toggleModal) toggleModal();
            if(onSaved) onSaved(saved.data)
        } else {
            return setErr(saved.message);
        }
    
    }, [stringTemplate, fetchData, toggleModal, onSaved])

    useEffect(() => {
        if(toggleModal) {
            if(showModal) {
                fetchData()
            } else {
                setErr(null)
                setStringTemplate(baseState)
            }
        } else {
            fetchData()
        }
    }, [showModal, toggleModal, fetchData])

    return (
        <Modalify 
            showModal={showModal}
            toggleModal={toggleModal}
            modalTitle={stringTemplate && stringTemplate._id ? "Update Template" : "Create Template"}
            err={err}
            footer={(
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            )}
            sections={!stringTemplate ? [] :  [
                (
                    <div>
                        <h2>String Template</h2>

                        <FormGroup>
                            <label className="form-control-label">Internal Name*</label>
                            <p className="text-sm mb-0"><i className="fas fa-info-circle text-info"></i> This is used to reference the string template in IntuiDesk</p>
                            <Input 
                                type="text"
                                value={stringTemplate.name || ''}
                                onChange={e => onInputChange('name', e.target.value)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <label className="form-control-label">Value*</label>
                            <p className="text-sm mb-0">This is the value that will be injected into documents, emails, texts, etc. when this template is used.</p>
                            <Input 
                                type="text"
                                value={stringTemplate.value || ''}
                                placeholder="Example: https://google.com"
                                onChange={e => onInputChange('value', e.target.value)}
                            />
                        </FormGroup>

                    </div>
                ),
            ]}
        />
    )

}

export default CompanyStringTemplatesEdit