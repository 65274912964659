import { useState, useCallback } from "react";
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { Card, CardFooter, CardHeader, CardTitle, Col, Container, Form, FormGroup, Input, Row, Progress } from "reactstrap";

import api from 'api';

import Icon from './_images/PinwheelLink.svg'

import Dots from 'components/markup/loading/Dots'

const OnboardingReplyTo = ({viewing_user, company, onSetView}) => {

    const [emailReplyTo, setEmailReplyTo] = useState(company.email_reply_to ? company.email_reply_to : company.email);
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(false)
    const [err, setErr] = useState(false);

    const onSave = useCallback(async (e) => {

        e.preventDefault();
        setErr(null);

        setLoading(true);
        const saved = await api.companies.update(viewing_user.company, { email_reply_to: emailReplyTo })

        if(saved.success) {
            setRedirect('/home?o=true')
        } else {
            setErr(saved.message)
            setLoading(false);
        }

    }, [emailReplyTo, viewing_user.company])

    if(redirect) return <Redirect to={redirect} />

    return (

        <Container className="mt--9 pb-5">
            <Row className="justify-content-center">
                <Col lg="5" md="7">

                    <Form role="form" style={{zIndex: 2, position: 'relative'}}>

                        <Card className="bg-white border-0 mb-0 position-relative" >

                            <img src={Icon} alt="..." className="flow-icon bg-white" />

                            <CardHeader>
                                <CardTitle className="flow-icon-header mb-0">Welcome To IntuiDesk!</CardTitle>
                            </CardHeader>
                            <CardHeader>
                                <div className="progress-wrapper mt--3 pt-0">
                                    <div className="progress-sucess">
                                        <div className="progress-label" style={{display: 'inline'}}>
                                            <span className="text-sucess" style={{position: 'relative', top: 13,}}>Onboarding</span>
                                        </div>
                                        <div className="progress-percentage">
                                        <span style={{position: 'relative', top: -12}}>75%</span>
                                        </div>
                                    </div>
                                    <Progress max="100" value={75} color="success" />
                                </div>
                            </CardHeader>

                            <CardHeader className="bg-secondary">
                                <p className="text-sm">Almost done, where would you like reply emails to be sent to?</p>
                                <p className="text-sm">This will be the address that all emails from clients to your IntuiDesk account will get forwarded to.</p>
                                <p className="text-sm mb-0 font-weight-bold">Example: <span className="text-muted">support@mycompany.com</span></p>
                            </CardHeader>

                            <CardHeader >

                                <FormGroup>
                                    <label className="form-control-label">Email Address*</label>
                                    <Input
                                        name="identifier"
                                        type="text"
                                        value={emailReplyTo}
                                        invalid={err ? true : false}
                                        onChange={(e) => setEmailReplyTo(e.target.value)}
                                    />
                                </FormGroup>

                            </CardHeader>

                            {err ? (
                            <CardHeader>
                                {err.map((e, i) => <p key={i} className="mb-0 text-sm text-danger font-weight-bold">{e}</p>)}
                            </CardHeader>
                            ) : null}

                            <CardFooter className="text-center">
                                {loading ? (
                                     <button disabled={true} className="my-2 btn-block btn btn-success btn-lg">
                                        Move On<Dots />
                                    </button>
                                ) : (
                                    <button onClick={onSave} className="my-2 btn-block btn btn-success btn-lg">
                                        Move On <i className="fas fa-arrow-right ml-2 " />
                                    </button>
                                )}
                            </CardFooter>
                        </Card>

                        <p className="text-sm text-center mt-3">
                            <span onClick={() => onSetView('Documents')} className="cursor-pointer">
                                <i className="fas fa-arrow-left mr-2 " /> Back
                            </span>
                        </p>

                    </Form>

                </Col>
            </Row>
        </Container>

    )
}


const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(OnboardingReplyTo);

