import { Input, FormGroup } from "reactstrap";
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import { useCallback, useState, useEffect } from 'react';

import api from 'api'

import Modalify from 'components/functional/modals/Modalify'

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';
import ReactSelect from 'components/functional/inputs/ReactSelect'

const baseState = {
    outcome: '',
    quality_control: 0
}

const SystemEventTypes = ({_id, onSaved, showModal, toggleModal, selected_division}) => {

    const [event_type, setEventType] = useState(null)
    const [err, setErr] = useState(null)
    const [redirect, setRedirect] = useState(null)


    //on enter simulate the form being submitted for better UI
	const _handleKeyDown = useCallback((e) => {
		if (e.key === 'Enter') {
            const el = document.getElementById("archk-add-event-outcome-create")
            if(el) el.click();
        }
	}, [])

    const fetchData = useCallback(async () => {
        setTimeout(() => {
            const el = document.getElementById('archk-add-event-outcome-outcome')
            if(el) el.focus();
        }, 200)
        if(!_id || _id === 'create') return setEventType(baseState);
        const event_type = await api.event_outcomes.findById(_id)
        if(!event_type.data) return setErr(true);
        setEventType(event_type.data)
    }, [_id])

    const onInputChange = useCallback(( field, value) => {
        const newEventType = Object.assign({}, event_type)
        newEventType[field] = value;
        setEventType(newEventType);
    }, [event_type])

    const onSave = useCallback(async () => {
        setErr(false)
        let newState = Object.assign({}, event_type);

        if(!newState.outcome) {
            return setErr([`A event outcome must have a outcome.`])
        }
        
        let saved;

        // toggle loaders and update/create the event_type
        toggleStandardLoader(true)
        if(newState._id) {
            saved = await api.event_outcomes.update(newState._id, newState, true);
        } else {
            saved = await api.event_outcomes.create({...newState, division: selected_division._id}, true);
        }
        toggleStandardLoader(false)

        if(!saved.success) return setErr(saved.message)
        
        fetchData();
        if(toggleModal) toggleModal();
        if(onSaved) onSaved(saved.data)

    }, [event_type, fetchData, toggleModal, onSaved, selected_division._id])

    useEffect(() => {
        if(toggleModal) {
            if(showModal) {
                fetchData()
            } else {
                setErr(null)
                setEventType(baseState)
            }
        } else {
            fetchData()
        }
    }, [showModal, toggleModal, fetchData])

    useEffect(() => {
        if(!selected_division._id) {
            toggleAlertBS('info', 'A division must be created for editing a event type.')
            setRedirect('/system/divisions/create')
        }
    }, [selected_division._id])

    if(redirect) return <Redirect to={redirect} />

    return (
        <Modalify 
            showModal={showModal}
            toggleModal={toggleModal}
            modalTitle={event_type && event_type._id ? "Update event type" : "Create event type"}
            err={err}
            footer={(
                <button id="archk-add-event-outcome-create" className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            )}
            sections={!event_type ? [] :  [
                (
                    <div>

                        <FormGroup>
                            <label className="form-control-label">Outcome*</label>
                            <Input 
                                id="archk-add-event-outcome-outcome"
                                type="text"
                                value={event_type.outcome || ''}
                                onChange={e => onInputChange('outcome', e.target.value)}
                                onKeyDown={_handleKeyDown}
                            />
                        </FormGroup>

                        <ReactSelect 
                            formGroup={true}
                            title="Quality Control"
                            description="If this field is set, any events marked finished with this outcome will be pushed into the quality control list at the priority set below."
                            value={event_type.quality_control}
                            onChange={(obj) => onInputChange('quality_control', obj.value)}
                            options={[
                                { value: 0, label: '-' },
                                { value: 5, label: 'Standard Priority' },
                                { value: 7, label: 'High Priority' },
                                { value: 10, label: 'Highest Priority' },
                            ]}
                        />

                    </div>
                ),
            ]}
        />
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SystemEventTypes);