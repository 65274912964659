import { FormGroup } from "reactstrap";

import ReactSelect from 'components/functional/inputs/ReactSelect';
import PhoneInput from 'react-phone-input-2';

const SystemCallFlowEdit = ({callFlow, onInputChange}) => {

    return (
        <div>

            <FormGroup>
                <label className="form-control-label">Third Party Dial</label>
                <p className="text-sm mb-0">If a number is specified all calls to this flow will immediately be forwarded to the number below.</p>
                <PhoneInput
                    country={'us'}
                    value={callFlow.force_redirect || ''}
                    onChange={phone => onInputChange('force_redirect', phone)}
                />
            </FormGroup>      

            <ReactSelect 
                formGroup={true}
                title="Set As Closed"
                description="If set to yes all incoming calls to this flow will use the After Hours call flow of the related phone number."
                value={callFlow.force_closed}
                onChange={(obj) => onInputChange('force_closed', obj.value)}
                options={[
                    { value: false, label: 'No' },
                    { value: true, label: 'Yes' }
                ]}
            />
            
        </div>
    )

}

export default SystemCallFlowEdit