import React from 'react'

const LoadingCircle = (props) => {

    return (
        <div className="text-center" style={props.style ? props.style : {}}>
            <div className={`lds-ring ${props.sm ? 'lds-ring-sm' : ''}`}><div /><div /><div /><div /></div>
        </div>
    );

}

export default LoadingCircle;