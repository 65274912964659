import { Row, Col } from "reactstrap";
import { connect } from 'react-redux';
import { useCallback, useState } from 'react';

import Privilege  from "./Privilege";

const UsersEditPrivilegesStandard = ({user, setUser, onPrivilegeChange, PRIVILEGES}) => {

    const [functionPrivileges] = useState([
        PRIVILEGES['FUNCTION.RANDOM_COMMUNICATION_ASSIGNMENT'],
        PRIVILEGES['FUNCTION.JUMP_STEPS'],
    ])

    const assignAll = useCallback(() => {
        const newUser = Object.assign({}, user)
        functionPrivileges.forEach(privilege => {
            if(!newUser.privileges.includes(privilege)) {
                newUser.privileges.push(privilege)
            }
        })
     
        setUser(newUser);
    }, [functionPrivileges, setUser, user])
   
    const removeAll = useCallback(() => {
        const newUser = Object.assign({}, user)
        functionPrivileges.forEach(privilege => {
            newUser.privileges = newUser.privileges.filter(p => p !== privilege)
        })
     
        setUser(newUser);
    }, [functionPrivileges, setUser, user])

    return (
        <div>

            <Row>
                <Col xs={6} className="align-self-center">
                    <h2>Functional Privileges</h2>
                </Col>
                <Col xs={6} className="align-self-center text-right">
                    <button onClick={removeAll} className="btn btn-outline-warning">Remove All</button>
                    <button onClick={assignAll} className="btn btn-outline-info">Assign All</button>
                </Col>
            </Row>

            <Row>
                <Col lg={6}>
                    <h5>Communication</h5>
                    <Privilege 
                        privilege={PRIVILEGES['FUNCTION.RANDOM_COMMUNICATION_ASSIGNMENT']}
                        user={user}
                        title="Random Communication Assignment"
                        onPrivilegeChange={onPrivilegeChange}
                        description="If enabled, this user will be considered when assigning texts, voicemails, support tickets, etc. where a direct assignment match is not found."
                    />
                    <h5>Matters</h5>
                    <Privilege 
                        privilege={PRIVILEGES['FUNCTION.JUMP_STEPS']}
                        user={user}
                        title="Can Jump Steps"
                        onPrivilegeChange={onPrivilegeChange}
                        description="If enabled, this user will be able to move matters to any step at any time regardless of pending items."
                    />


                </Col>
            </Row>

        </div>
    )

}


const mapStateToProps = state => {
	return {
	    PRIVILEGES: state.config.PRIVILEGES,
	};
};

export default connect(mapStateToProps, '')(UsersEditPrivilegesStandard);