
import { useCallback, useState } from 'react';
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from "reactstrap";

import ReactSelect from 'components/functional/inputs/ReactSelect';
import DatePicker from "react-datepicker";

import moment from 'moment';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const getRangeByValue = (valStart, valEnd) => {
    const day = 86400;

    let end = parseInt(moment().endOf('day').format('X'))
    let start;

    // if we have no value we are searching for either a set cookie value OR 
    // to return the start and end of today
    if(!valStart) {
        valStart = cookies.get('archk-query-time-group')
        if(!valStart) {
            start = parseInt(moment().startOf('day').format('X'))
            end = parseInt(moment().endOf('day').format('X'))
            return { start, end }
        }
       
    }

    switch (valStart) {
        case "Today": {
            start = end - (day * 1) + 1;  
            cookies.set('archk-query-time-group', 'Today', { path: '/' });
            break;
        }
        case "Yesterday": {
            start = end - (day * 2)  + 1;
            end = end - (day * 1);
            cookies.set('archk-query-time-group', 'Yesterday', { path: '/' });
            break;
        }
        case "This Week": {
            start = parseInt(moment().startOf('week').format('X'))
            end = parseInt(moment().endOf('week').format('X'))
            cookies.set('archk-query-time-group', 'This Week', { path: '/' });
            break;
        }
        case "Last Week": {
            start = parseInt(moment().subtract(1, 'week').startOf('week').format('X'))
            end = parseInt(moment().subtract(1, 'week').endOf('week').format('X'))
            cookies.set('archk-query-time-group', 'Last Week', { path: '/' });
            break;
        }
        case "This Month": {
            start = parseInt(moment().startOf('month').format('X'))
            end = parseInt(moment().endOf('month').format('X'))
            cookies.set('archk-query-time-group', 'This Month', { path: '/' });
            break;
        }
        case "Last Month": {
            start = parseInt(moment().subtract(1, 'month').startOf('month').format('X'))
            end = parseInt(moment().subtract(1, 'month').endOf('month').format('X'))
            cookies.set('archk-query-time-group', 'Last Month', { path: '/' });
            break;
        }
        case "This Quarter": {
            start = parseInt(moment().startOf('quarter').format('X'))
            end = parseInt(moment().endOf('quarter').format('X'))
            cookies.set('archk-query-time-group', 'This Quarter', { path: '/' });
            break;
        }
        case "Last Quarter": {
            start = parseInt(moment().subtract(1, 'quarter').startOf('quarter').format('X'))
            end = parseInt(moment().subtract(1, 'quarter').endOf('quarter').format('X'))
            cookies.set('archk-query-time-group', 'Last Quarter', { path: '/' });
            break;
        }
        case "Last 30 Days": 
            start =  parseInt(moment().subtract(30, 'day').startOf('day').format('X'));  
            cookies.set('archk-query-time-group', 'Last 30 Days', { path: '/' });
            break;
        case "Last 60 Days": 
            start =  parseInt(moment().subtract(60, 'day').startOf('day').format('X'));  
            cookies.set('archk-query-time-group', 'Last 60 Days', { path: '/' });
            break;
        case "Last 90 Days": 
            start =  parseInt(moment().subtract(90, 'day').startOf('day').format('X'));  
            cookies.set('archk-query-time-group', 'Last 90 Days', { path: '/' });
            break;
        case "Last 180 Days": 
            start =  parseInt(moment().subtract(180, 'day').startOf('day').format('X'));  
            cookies.set('archk-query-time-group', 'Last 180 Days', { path: '/' });
            break;
        case "Last 365 Days": 
            start =  parseInt(moment().subtract(365, 'day').startOf('day').format('X'));  
            cookies.set('archk-query-time-group', 'Last 365 Days', { path: '/' });
            break;

        default: 
            if(valStart && typeof valStart === 'string' && valStart.includes('-')) {
                const split = valStart.split('-');
                start = parseInt(split[0]);
                end = parseInt(split[1]);
            } else {
                if(valStart) start = parseInt(moment(valStart).format('X'))
                if(valEnd) end = parseInt(moment(valEnd).format('X'))
            }
        ;
    }

    return { start, end }

}

const getMonthOptions = () => {
    let options = []

    for (let i = 0; i < 36; i++) {
        const month = moment().subtract(i, 'months').startOf('month');
        options.push({
            label: month.format('MMM - YYYY'),
            value: `${month.format('X')}-${month.endOf('month').format('X')}`
        })
    }
    return options;
}

const RangeSelection = ({onSetRange, title, type}) => (
    <p className='text-sm mb-0 mt--1 font-weight-bold text-uppercase'>
        <UncontrolledDropdown nav >
            <DropdownToggle 
                className="nav-link pl-0 pr-0 py-0 cursor-pointer font-weight-bold" 
                color="" 
                tag="a"
            >
                {title ? title : 'Start Date'} <i className="fas fa-caret-down " />
            </DropdownToggle>

            <DropdownMenu>
                <div className="dropdown-content">
                    <DropdownItem  className="noti-title" header tag="div">
                        <h5 className="text-overflow m-0">Selection Categories</h5>
                    </DropdownItem>

                    <DropdownItem onClick={() => onSetRange('type', 'range')} >
                        <span className=''> Range</span>
                    </DropdownItem>

                    <DropdownItem  onClick={() => onSetRange('type', 'day')} >
                        <span className=''>Single Day</span>
                    </DropdownItem>

                    <DropdownItem  onClick={() => onSetRange('type', 'time_group',)} >
                        <span className=''> Time Group</span>
                    </DropdownItem>

                </div>
            </DropdownMenu>

        </UncontrolledDropdown>
    </p>
)

const AnalyticsRangeSelection = ({fields, sort, onSetSort, onRangeChange, tableType}) => {

    const [range, setRange ] = useState({
        type: 'time_group',
        start: cookies.get('archk-query-time-group') || 'Today',
    })

    const { type, field } = sort
    const { start, end } = range
    const multiplier = tableType === 'contacts' ? 2 : 1

    const onSetRange = useCallback((field, value) => {
        const _range = JSON.parse(JSON.stringify(range))
        _range[field] = value;
       

        if(field === 'type') {
            if(value === 'time_group') {
                _range.start = 'Today';
                _range.end = '';
            } else {
                _range.start = moment().startOf('day').toDate()
                _range.end = moment().endOf('day').toDate()
            }
        } else {
            if(_range.type === 'range') {
                if(_range.start) _range.start = moment(_range.start).toDate()
                if(_range.end) _range.end = moment(_range.end).endOf('day').toDate()
            } else if(_range.type === 'day') {
                _range.start = moment(value).startOf('day').toDate()
                _range.end = moment(value).endOf('day').toDate()
            }
        }
        // console.log(range)
        const { start, end } = getRangeByValue(_range.start, _range.end)
        // console.log(start)
        // console.log(end)
        // console.log(moment.unix(start).format('MMM Do, YYYY h:mm A'))
        // console.log(moment.unix(end).format('MMM Do, YYYY h:mm A'))


        onRangeChange({start, end})
        setRange(_range)
    }, [range, onRangeChange])

 
    return (
        <Row>
            {tableType === 'matters' ? (
                <Col lg={type.includes('specific') || type === 'custom_date'  ? 3 : 6}>
                    <p className='text-sm mb-0 mt--1 font-weight-bold text-uppercase'>Sort Type</p>
                    <ReactSelect
                        title="Sort Type"
                        placeholder=" "
                        onChange={(obj) => onSetSort('type', obj.value)}
                        options={[
                            { label: 'Matter Creation Date', value: 'created_at' },
                            { label: 'Last Incoming Communication', value: 'incoming_communication_at' },
                            { label: 'Last Outgoing Communication', value: 'outgoing_communication_at' },
                            { label: 'Step Last Moved', value: 'current_step_start' },
                            { label: 'Step Overdue At', value: 'current_step_overdue_at' },
                            { label: 'Payment Plan Overdue', value: 'payment_overdue_since' },
                            { label: 'Category Last Updated', value: 'categorized_at' },
                            { label: 'Disposition Last Updated', value: 'dispositioned_at' },
                            { label: 'Last Note Created', value: 'last_note_at' },
                            { label: 'Specific Step Moved To*', value: 'specific_step' },
                            { label: 'Specific Category Moved To*', value: 'specific_category' },
                            { label: 'Specific Disposition Moved To*', value: 'specific_disposition' },
                            { label: 'Custom Date Entered*', value: 'custom_date' },
                        ]}
                        value={type}
                        // styles={{
                        //     control: base => ({
                        //         ...base,
                        //         height: 25,
                        //         minHeight: 25
                        //       })
                        // }}
                    />    
                </Col>
            ) : ''}

            {type === 'specific_step' ? (
                <Col lg={3 * multiplier}>
                    <p className='text-sm mb-0 mt--1 font-weight-bold text-uppercase'>Select Step</p>
                    <ReactSelect
                        formGroup={false}
                        placeholder=" "
                        onChange={(obj) => onSetSort('field', obj.value)}
                        options={fields.workflow_steps.map(s => { return { value: s._id, label: s.name } })}
                        value={field}
                    />    
                </Col>
            
            ) : ''}
            
            {type === 'specific_category' ? (
                <Col lg={3 * multiplier}>
                    <p className='text-sm mb-0 mt--1 font-weight-bold text-uppercase'>Select Category</p>
                    <ReactSelect
                        formGroup={false}
                        placeholder=" "
                        onChange={(obj) => onSetSort('field', obj.value)}
                        options={fields.workflow_step_categories.map(s => { return { value: s._id, label: s.name } })}
                        value={field}
                    />    
                </Col>
            ) : ''}

            {type === 'specific_disposition' ? (
                <Col lg={3 * multiplier}>
                    <p className='text-sm mb-0 mt--1 font-weight-bold text-uppercase'>Select Disposition</p>
                    <ReactSelect
                        formGroup={false}
                        placeholder=" "
                        onChange={(obj) => onSetSort('field', obj.value)}
                        options={fields.workflow_dispositions.map(s => { return { value: s._id, label: s.name } })}
                        value={field}
                    />    
                </Col>
            
            ) : ''}

            {type === 'custom_date' ? (
                <Col lg={3 * multiplier}>
                    <p className='text-sm mb-0 mt--1 font-weight-bold text-uppercase'>Select Date</p>
                    <ReactSelect
                        formGroup={false}
                        placeholder=" "
                        onChange={(obj) => onSetSort('field', obj.value)}
                        options={fields.custom_dates.map(s => { return { value: s._id, label: s.name } })}
                        value={field}
                    />    
                </Col>
            
            ) : ''}

            {range.type === 'range' ? (
                <>
                    <Col md={3  * multiplier}>
                        <RangeSelection onSetRange={onSetRange} />
                        <DatePicker selected={start} onChange={(date) => onSetRange('start', date)} />
                    </Col>
                    <Col md={3  * multiplier}>
                        <p className='text-sm mb-0 mt--1 font-weight-bold text-uppercase'>End Date</p>
                        <DatePicker selected={end} onChange={(date) => onSetRange('end', date)} />
                    </Col>
                </>
            ) : range.type === 'time_group' ? (
                <Col md={6  * multiplier}>
                    <RangeSelection onSetRange={onSetRange} title="Time Group" />

                    <ReactSelect
                        placeholder=" "
                        onChange={(obj) => onSetRange('start', obj.value)}
                        options={[
                            { label: 'Today', value: 'Today' },
                            { label: 'Yesterday', value: 'Yesterday' },
                            { label: 'This Week', value: 'This Week' },
                            { label: 'Last Week', value: 'Last Week' },
                            { label: 'This Month', value: 'This Month' },
                            { label: 'Last Month', value: 'Last Month' },
                            { label: 'This Quarter', value: 'This Quarter' },
                            { label: 'Last Quarter', value: 'Last Quarter' },
                            { label: 'Last 30 Days', value: 'Last 30 Days' },
                            { label: 'Last 60 Days', value: 'Last 60 Days' },
                            { label: 'Last 90 Days', value: 'Last 90 Days' },
                            { label: 'Last 180 Days', value: 'Last 180 Days' },
                            { label: 'Last 365 Days', value: 'Last 365 Days' },
                            ...getMonthOptions()
                        ]}
                        value={range.start}
                    />    
                </Col>
            ) : range.type === 'day' ? (
                <Col md={6  * multiplier}>
                    <RangeSelection onSetRange={onSetRange} title="Select Day" />
                    <DatePicker selected={start} onChange={(date) => onSetRange('start', date)} />
                </Col>
            ) : ''}
        
        </Row>
    )
}

export default AnalyticsRangeSelection