import { useState, useCallback, useEffect } from 'react';
import { Card, CardHeader, CardTitle, Row, Col, Input, FormGroup } from 'reactstrap';
import Circle from 'components/markup/loading/Circle'
import { connect } from 'react-redux'

import api from 'api'
import { toast } from 'react-toastify';

import ReactSelect from 'components/functional/inputs/ReactSelect';

import ContactRow from './ContactRow';

const WorkflowEditContacts = ({workflow, selected_division}) => {

    const [name, setName] = useState('')
    const [mainContact, setMainContact] = useState(true)
    const [contact, setContactType] = useState('')

    const [allContacts, setAllContacts] = useState(workflow.allContacts)
    const [contactTypes, setContactTypes] = useState(workflow.allContacts)

    const _handleKeyDown = useCallback((e) => {
		if (e.key === 'Enter') document.getElementById("archk-add-contact-button").click();
	}, [])

    const onAdd = useCallback(async () => {
        if(!name || !contact) return toast.info("Both a name and contact type must be specified to create a workflow contact.")
        const _allContacts = JSON.parse(JSON.stringify(allContacts));

            const created = await api.workflow_contacts.create({
                workflow: workflow._id,
                name: name,
                contact_type: contact,
                main_contact: mainContact
            })
            if(created.success) {
                _allContacts.push(created.data)
                setAllContacts(_allContacts);
                setName('')   
                setContactType('')   
                setMainContact(true)
            } else {
                toast.error("Something went wrong creating this contact, please try again and make sure the contact is unique.")
            }
      
    }, [allContacts, name, contact, mainContact, workflow._id])
    
    const onDelete = useCallback(async (_contact) => {
        let _allContacts = JSON.parse(JSON.stringify(allContacts));

        const deleted = await api.workflow_contacts.delete(_contact._id)
        if(deleted.success) {
            _allContacts = _allContacts.filter(r => r._id !== deleted.data._id)
            setAllContacts(_allContacts);
        } else {
            toast.error("Something went wrong deleting this contact, please try again.")
        }
    }, [allContacts])

    const fetchData = useCallback(async () => {
        const values = await Promise.all([
            api.workflow_contacts.find(workflow._id),
            api.contact_types.find(),
        ])
        if(values[0] && values[1].data) {
            setAllContacts(values[0].data)
            setContactTypes(values[1].data.filter(d => d.division === selected_division._id))
        }
    }, [workflow._id, selected_division._id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <>
            <Card>
                <CardHeader>
                    <CardTitle>Add Workflow Contact</CardTitle>
                    <p className="text-sm mb-0"><i className="fas fa-info-circle mr-2 info-circle text-info " /> Workflow contacts allow you to create relationships between contact types and a named matter entry. For instance, you can create Workflow Contact called "Judge" and associate it with a Contact Type of "Legal Professionals". This will allow staff to only assign contacts deemed as a "Legal Professional" to the matter which will read as "Judge: John Doe" inside the matter.</p>

                </CardHeader>
                <CardHeader>
                    <Row>
                        <div className="col-auto" style={{width: 'calc(33.33% - 84px)'}}>
                            <label className="form-control-label">Contact Name*</label>
                        </div>

                        <div className="col-auto" style={{width: 'calc(33.33% - 84px)'}}>
                            <label className="form-control-label">Is Main Contact*</label>
                        </div>
                        <div className="col-auto" style={{width: 'calc(33.33% - 84px)'}}>
                            <label className="form-control-label">Contact Type*</label>
                        </div>
                        <Col></Col>
                    </Row>

                    <Row>
                        <div className="col-auto" style={{width: 'calc(33.33% - 84px)'}}>
                            <FormGroup>
                                <Input 
                                    onKeyDown={_handleKeyDown}
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}

                                />
                            </FormGroup>
                        </div>
                        <div className="col-auto" style={{width: 'calc(33.33% - 84px)'}}>
                            <ReactSelect 
                                formGroup={true}
                                value={mainContact}
                                onChange={(obj) => setMainContact(obj.value)}
                                options={[
                                    { label: 'Yes', value: true },
                                    { label: 'No', value: false },
                                ]}
                            />
                        </div>

                        <div className="col-auto" style={{width: 'calc(33.33% - 84px)'}}>
                            <ReactSelect 
                                formGroup={true}
                                value={contact}
                                onChange={(obj) => setContactType(obj.value)}
                                options={contactTypes ? contactTypes.map(l => {
                                    return { label: l.name, value: l._id }
                                }) : []}
                            />
                        </div>
                        <Col>
                            <button 
                                id="archk-add-contact-button"
                                onClick={onAdd} 
                                className="btn btn-outline-info btn-block"
                            >
                                <i className="fas fa-plus mr-2 " /> Add Contact
                            </button>
                        </Col>
                    </Row>
                </CardHeader>

                {!allContacts ? <Circle /> : (
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Contact Name</th>
                                    <th>Main Contact</th>
                                    <th>Type</th>
                                    <th>Field Map ID</th>
                                    <th className="text-right">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allContacts.length ? allContacts.map((contact) => <ContactRow contact={contact} contactTypes={contactTypes} onDelete={onDelete} />) : (
                                    <tr>
                                        <td>No contacts have been created yet for this workflow</td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    
                    </div>
                )}

            </Card>

            {allContacts && allContacts.length ? (
                <p className='text-sm mt--3'><i className="fas fa-info-circle text-info" /> Note that changing the above values will automatically save them to the workflow.</p>
            ) : ''}

        </>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(WorkflowEditContacts);