import UsersEdit from './components/Edit'
import UsersAll from './All'

import IntuideskSectionWrapper from 'components/markup/layout/IntuideskSectionWrapper'

const SystemUsers = ({match, section}) => (
    <IntuideskSectionWrapper 
        title="Users"
        editTitle="User"
        match={match}
        section={section}
        innerSection={'users'}
        Edit={UsersEdit}
        All={UsersAll}
    />
)

export default SystemUsers;