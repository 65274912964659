import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';

import ObjectFinder from 'components/system/Objects/Finder';


export default memo(({ id, data, isConnectable }) => {
  return (
    <>
        <Handle
            id="left"
            type="target"
            position={Position.Left}
            style={{top: 55 }}
            isConnectable={isConnectable}
        />
       
        <div className="">
            <div className='node-header'>
                <i className="fa-regular fa-circle-question text-warning mr-2" /> { data.name ? data.name : <span className='text-danger'>If Statement Not Set</span> }
            </div>
           
            <div className='node-body'>
                <div className='node-item text-capitalize bg-secondary text-muted'>
                    {data.ai_assistant ? (
                        <ObjectFinder _id={data.ai_assistant} collection="ai_assistants" />
                    ) : (
                        <span className="text-danger">Assistant Not Set</span>
                    )}
                </div>
                <div className='node-item text-capitalize'>True</div>
                <div className='node-item text-capitalize'>False</div>
            </div>

            <div className='node-footer'>
                <div><small>Execution ID:{id}</small></div>
            </div>

        </div>


        <Handle
            id="true"
            type="source"
            position={Position.Right}
            style={{ bottom: 75, top: 'auto', }}
            isConnectable={isConnectable}
        />
        <Handle
            id="false"
            type="source"
            position={Position.Right}
            style={{ bottom: 42, top: 'auto', }}
            isConnectable={isConnectable}
        />
        </>
  );
});