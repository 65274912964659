import { useState, useEffect } from 'react'
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';

import { toast } from 'react-toastify';

import { toggleStandardLoader } from 'store/functions/system/system';

import api from 'api'

import ReactSelect from 'components/functional/inputs/ReactSelect';
import SearchCollections from 'components/system/Search/Collections'

import TypeEmail from './TypeEmail'
import TypeText from './TypeText'

const minute = 60;
const hour = minute * 60;
const day = 86400;

const AutmationsEditAddTask = ({fetchTasks, automation_id, selected_division, movesTo, movesFrom, workflows, onSetShowEdit, toEdit, tasks}) => {

    const [errs, setErrs] = useState('');
    const [id, setId] = useState('');
    const [type, setType] = useState('email');
    const [delay, setDelay] = useState(0);
    const [emailSender, setEmailSender] = useState('');
    const [callPhoneNumber, setCallPhoneNumber] = useState('');
    const [textTemplate, setTextTemplate] = useState('');
    const [emailTemplate, setEmailTemplate] = useState('');
    const [automationToStart, setAutomationToStart] = useState('');
    const [replyToEmail, setReplyToEmail] = useState(null);
    const [automationToEnd, setAutomationToEnd] = useState('');
    const [replyEmails, setReplyEmails] = useState('');
    const [roles, setRoles] = useState(workflows.map(workflow => {
        return {
            workflow: workflow._id,
            workflow_role: null
        }
    }));
    const [ccRoles, setCCRoles] = useState(workflows.map(workflow => {
        return {
            workflow: workflow._id,
            workflow_role: null
        }
    }));
    const [toContacts, setToContacts] = useState(workflows.map(workflow => {
        return {
            workflow: workflow._id,
            workflow_contact: null
        }
    }));

    const onSave = async () => {

        const errs = [];

        if(!type) errs.push('type');
        if(type === 'wait' && !delay) errs.push(delay);
        if(type === 'text' && !textTemplate) errs.push(textTemplate);
        if(type === 'email' && !emailTemplate) errs.push(emailTemplate);
        if(type === 'email' && !emailSender) errs.push(emailTemplate);
        if(type === 'start' && !automationToStart) errs.push(automationToStart);
        if(type === 'end' && !automationToEnd) errs.push(automationToEnd);

        setErrs(errs);

        if(errs.length) return;

        toggleStandardLoader(true);

        let action;

        if(toEdit && toEdit._id) {
            action = await api.automation_tasks.update(toEdit._id, {
                automation: automation_id, 
                email_sender: emailSender ? emailSender : null,
                call_phone_number: callPhoneNumber ? callPhoneNumber : null,
                text_template: textTemplate ? textTemplate : null,
                email_template: emailTemplate ? emailTemplate : null,
                automation_to_start: automationToStart ? automationToStart : null,
                automation_to_end: automationToEnd ? automationToEnd : null,
                delay: type === 'wait' ? parseInt(delay): undefined, 
                type, 
                moves_to: movesTo,
                moves_from: movesFrom,
                workflow_roles: roles,
                cc_roles: ccRoles,
                to_contacts: toContacts,
                reply_to_email: replyToEmail,
            })
        } else {
            action = await api.automation_tasks.create({
                automation: automation_id, 
                email_sender: emailSender ? emailSender : null,
                call_phone_number: callPhoneNumber ? callPhoneNumber : null,
                text_template: textTemplate ? textTemplate : null,
                email_template: emailTemplate ? emailTemplate : null,
                automation_to_start: automationToStart ? automationToStart : null,
                automation_to_end: automationToEnd ? automationToEnd : null,
                delay: type === 'wait' ? parseInt(delay): undefined, 
                type, 
                moves_to: movesTo,
                moves_from: movesFrom,
                workflow_roles: roles,
                cc_roles: ccRoles,
                to_contacts: toContacts,
                reply_to_email: replyToEmail,
            })
        }

       
        toggleStandardLoader(false);

        if(action.success) {
            fetchTasks()
            onSetShowEdit(false)
        } else {
            toast.error("Something's not right, please try again")
        }

    }

    useEffect(() => {
        if(toEdit && toEdit._id) {
            setId(toEdit._id);
            setType(toEdit.type);
            setDelay(toEdit.delay);
            setEmailSender(toEdit.email_sender);
            setCallPhoneNumber(toEdit.call_phone_number);
            setTextTemplate(toEdit.text_template);
            setEmailTemplate(toEdit.email_template);
            setAutomationToStart(toEdit.automation_to_start);
            setAutomationToEnd(toEdit.automation_to_end);
            setRoles(toEdit.workflow_roles);
            setCCRoles(toEdit.cc_roles);
            setToContacts(toEdit.to_contacts);
            setReplyToEmail(toEdit.reply_to_email);
        }
    }, [toEdit])

    return (
        <div className="mt-3 pt-2">

            <Row>
                <Col lg={6} className="align-self-center">
                    <h2>Edit A Task</h2>
                </Col>
                <Col lg={6} className="text-right align-self-center">
                    <button onClick={() => onSetShowEdit(false)} className='btn btn-outline-warning'><i className="fas fa-arrow-left"></i> Cancel</button>
                </Col>
            </Row>


            <ReactSelect 
                formGroup={true}
                title="Task Type*"
                value={type}
                onChange={(obj) => setType(obj.value)}
                options={[
                    { value: 'email', label: 'Send Email' },
                    { value: 'text', label: 'Send Text' },
                    { value: 'start', label: 'Start an Automation' },
                    { value: 'end', label: 'End an Automation' },
                    { value: 'wait', label: 'Wait' },
                ]}
            />
            
            {type === 'email' ? (
                <TypeEmail 
                    roles={roles}
                    setRoles={setRoles}
                    toContacts={toContacts}
                    setToContacts={setToContacts}
                    ccRoles={ccRoles}
                    setCCRoles={setCCRoles}
                    tasks={tasks}
                    id={id}
                    replyToEmail={replyToEmail}
                    setReplyToEmail={setReplyToEmail}
                    emailTemplate={emailTemplate}
                    setEmailTemplate={setEmailTemplate}
                    emailSender={emailSender}
                    setEmailSender={setEmailSender}
                    replyEmails={replyEmails}
                    setReplyEmails={setReplyEmails}
                />
                
            ) : type === 'text' ? (
                <TypeText 
                    toContacts={toContacts}
                    setToContacts={setToContacts}
                    textTemplate={textTemplate}
                    setTextTemplate={setTextTemplate}
                />
            ) : type === 'start' || type === 'end' ? (
                <SearchCollections
                    collection="automations"
                    title={type === 'start' ? 'Automation To Start' : 'Automation To End'}
                    value={type === 'start' ? automationToStart : automationToEnd}
                    onChange={(obj) => type === 'start' ? setAutomationToStart(obj.value) : setAutomationToEnd(obj.value)}
                    filter={{
                        division: selected_division._id
                    }}
                /> 
            ) : type === 'wait' ? (
                <ReactSelect 
                    formGroup={true}
                    title="Wait Time*"
                    value={delay}
                    onChange={(obj) => setDelay(obj.value)}
                    options={[
                        {value: minute, label: '1 Minute' },
                        {value: minute * 5, label: '5 Minutes' },
                        {value: minute * 10, label: '10 Minutes' },
                        {value: minute * 15, label: '15 Minutes' },
                        {value: minute * 20, label: '20 Minutes' },
                        {value: minute * 30, label: '30 Minutes' },
                        {value: minute * 45, label: '45 Minutes' },
                        {value: hour * 1, label: '1 Hour' },
                        {value: hour * 1.5, label: '1.5 Hours' },
                        {value: hour * 2, label: '2 Hours' },
                        {value: hour * 3, label: '3 Hours' },
                        {value: hour * 4, label: '4 Hours' },
                        {value: hour * 5, label: '5 Hours' },
                        {value: hour * 6, label: '6 Hours' },
                        {value: hour * 7, label: '7 Hours' },
                        {value: hour * 8, label: '8 Hours' },
                        {value: hour * 16, label: '16 Hours' },
                        {value: day * 1, label: '1 Day' },
                        {value: day * 2, label: '2 Days' },
                        {value: day * 3, label: '3 Days' },
                        {value: day * 4, label: '4 Days' },
                        {value: day * 5, label: '5 Days' },
                        {value: day * 6, label: '6 Days' },
                        {value: day * 7, label: '7 Days' },
                        {value: day * 10, label: '10 Days' },
                        {value: day * 14, label: '14 Days' },
                        {value: day * 21, label: '21 Days' },
                        {value: day * 28, label: '28 Days' },
                        {value: day * 30, label: '30 Days' },
                        {value: day * 45, label: '45 Days' },
                        {value: day * 60, label: '60 Days' },
                        {value: day * 75, label: '75 Days' },
                        {value: day * 90, label: '90 Days' },
                        {value: day * 120, label: '120 Days' },
                        {value: day * 150, label: '150 Days' },
                        {value: day * 180, label: '180 Days' },
                        {value: day * 210, label: '210 Days' },
                        {value: day * 240, label: '240 Days' },
                        {value: day * 270, label: '270 Days' },
                        {value: day * 300, label: '300 Days' },
                        {value: day * 365, label: '365 Days' },
                    ]}
                />
            ) : ''}

            {errs.length ? (
                <div className="modal-body border-top border-left border-right bg-secondary">
                    <p className="text-sm mb-0 text-warning"><i className="fas fa-info-circle" /> Automation task must have a type and and associated document OR wait time respectively. Emails must also have a specified email sender</p>
                </div>
            ) : null}

            <div className="modal-footer text-right">
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            </div>

        </div>

    )

}


const mapStateToProps = state => {
    return {
        selected_division: state.state.selected_division,
    };
};
export default connect(mapStateToProps, '')(AutmationsEditAddTask);;