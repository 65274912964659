import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import ReactSelect from 'components/functional/inputs/ReactSelect';

import triggers from '../../_helpers/triggers';

const SidebarRightTrigger = ({ selectedNode, onNodeUpdated }) => {

    const [node, setNode] = useState(selectedNode)

    const onInputChange = useCallback((field, value) => {
        const n = JSON.parse(JSON.stringify(node));
        n.data[field] = value;
        setNode(n)
    }, [node])

    useEffect(() => {
        setNode(JSON.parse(JSON.stringify(selectedNode)))
    }, [selectedNode])
   
    return (
        <div>

            <div className='sidebar-section border-bottom'>
                <div className="sidebar-item pb-0"><h2>Run Assistant</h2></div>

            </div>
            
            <div className='sidebar-section border-bottom'>
                <ReactSelect
                    title={"**Trigger Type"}
                    formGroup={true}
                    placeholder=""
                    onChange={(obj) => onInputChange('type', obj.value)}
                    options={triggers.map(t => {
                        return { value: t, label: <span className='text-capitalize'>{t.replace(/_/g, ' ')}</span> }
                    })}
                    value={node.data.type}
                />    
            </div>

            <div className='sidebar-section text-right'>
                <button onClick={() => onNodeUpdated(node)} className='btn btn-success'>Save Node</button>
            </div>

        </div>

    )

}



const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SidebarRightTrigger);