import React from "react";
import { connect } from 'react-redux';
import {  Redirect, Route, Switch } from "react-router-dom";

import routes from "./routes";

import { setCompany } from 'store/functions/company/company'
import { setConfig } from 'store/functions/config/config'
import { setUsers } from 'store/functions/users/users'

import setDivisions from 'store/functions/divisions'
import { setSelectedDivision } from 'store/functions/state'

import ArchkTutorial from 'components/functional/tutorial'
import ArchkAlerts from 'components/functional/alerts'

class Dashboard extends React.Component {
    state = {
        loaded: false,
        sidenavOpen: true,
        showMobileNav: false,
    };

    //get routes for this layout
    getRoutes = routes => routes().map((prop, key) => {

        return (
            <Route
                exact path={`/editor${prop.path}`}
                component={prop.component}
                key={key}
            />
        )
    })

    setScreenSize = () => {

        // const { device } = this.props

        // if(!device.isLG) {
        //     document.body.classList.remove("g-sidenav-show");
        //     document.body.classList.remove("g-sidenav-pinned");
        //     document.body.classList.remove("g-sidenav-hidden");
        //     this.setState({ sidenavOpen: false, showMobileNav: true });
        // } else {
        //     document.body.classList.add("g-sidenav-pinned");
        //     this.setState({ sidenavOpen: true, showMobileNav: false });
        // }

    }

    componentDidCatch = (e) => {
        //don't redirect if we are developing
        if(window.location.hostname !== 'localhost') {
            this.setState({redirect: `/errors/standard?error=${e}&referrerurl=${window.location.href}`})
        }
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.setScreenSize)
    }

    componentDidMount = async () => {
        if(!this.props.company._id) {
            await setConfig();
            await Promise.all([
                setCompany(),
                setUsers(),
                setDivisions(),
            ])
            setSelectedDivision();
        }


        this.setScreenSize()
        this.setState({loaded: true})

        window.addEventListener('resize', this.setScreenSize)
        
    }

    render() {

        const { redirect, loaded } = this.state
        const { tutorial } = this.props;

        if(redirect) return <Redirect to={redirect} />;
        if(!loaded) return <div />;
        // if(!company.agreement_accepted_at) return <Redirect to="/actions/agreement" />;

        return (
            <div>
                  <ArchkTutorial 
                    isOpen={tutorial.showTutorial}
                    steps={tutorial.steps}
                />

                <ArchkAlerts />

                <div className="editor-content">
                    <Switch>
                        {this.getRoutes(routes)}
                        <Redirect from="*" to="/" />
                    </Switch>
                </div>

            </div>
            
        );
    }
}

const mapStateToProps = state => {
	return {
	    device: state.device.info,
	    company: state.company,
	    tutorial: state.tutorial.tutorial,
	    load: state.load,
	};
};

export default connect(mapStateToProps, '')(Dashboard);
