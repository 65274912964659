import { connect } from 'react-redux';

import WorkflowRolesSelection from 'components/system/WorkflowRolesSelection';
import ReactSelect from 'components/functional/inputs/ReactSelect';

import { FormGroup, Input } from 'reactstrap';

import timeValues from '../../_helpers/timeValues'

const ActionCreateOutstandingItem = ({ node, onInputChange, selected_division }) => {

    return (
        <div>
    
            <div className='sidebar-section border-bottom'>

                <FormGroup>
                    <label className='form-control-label'>**Item Name</label>
                    <Input
                        type="text"
                        value={node.data.name}
                        onChange={e => onInputChange('name', e.target.value)}
                    />
                </FormGroup>

                <FormGroup>
                    <label className='form-control-label'>Optional Description</label>
                    <Input 
                        type="text"
                        value={node.data.description}
                        onChange={e => onInputChange('description', e.target.value)}
                    />
                </FormGroup>

            </div>
    
            <div className='sidebar-section border-bottom'>
                <ReactSelect
                    title={"Due Date"}
                    description="The amount of time from when this item is created until when it is due."
                    formGroup={true}
                    placeholder=""
                    onChange={(obj) => onInputChange('due_date', obj.value)}
                    options={timeValues}
                    value={node.data.due_date}
                />    
            </div>

            <div className='sidebar-section border-bottom'>
                <ReactSelect
                    title={"Next Follow Up At"}
                    description="The amount of time from when this item is created until when it will be followed up on."
                    formGroup={true}
                    placeholder=""
                    onChange={(obj) => onInputChange('next_follow_up_at', obj.value)}
                    options={timeValues}
                    value={node.data.next_follow_up_at}
                />    
                
                <ReactSelect
                    title={"Follow Up Interval"}
                    formGroup={true}
                    placeholder=""
                    onChange={(obj) => onInputChange('follow_up_interval', obj.value)}
                    options={[
                        { label: 'None', value: '0' },
                        { label: '1st Of Month', value: '1st_of_the_month' },
                        { label: 'Every Day', value: '1' },
                        { label: 'Every 2 Days', value: '2' },
                        { label: 'Every 3 Days', value: '3' },
                        { label: 'Every 4 Days', value: '4' },
                        { label: 'Every 5 Days', value: '5' },
                        { label: 'Every 6 Days', value: '6' },
                        { label: 'Every Week', value: '7' },
                        { label: 'Every 2 Weeks', value: '14' },
                        { label: 'Every 3 Weeks', value: '21' },
                        { label: 'Every 4 Weeks', value: '28' },
                    ]}
                    value={node.data.follow_up_interval}
                />    
            </div>

            <div className='sidebar-section border-bottom'>
                <h2>Assigned To</h2>
                <WorkflowRolesSelection 
                    roles={node.data.assigned_to} 
                    onChange={(assigned_to) => onInputChange('assigned_to', assigned_to)} 
                />
            </div>

            <div className='sidebar-section border-bottom'>
                <p className='text-sm'>
                    <i className="fas fa-info-circle text-info" /> This action creates an outstanding item to be due and followed up on at a set interval after the action runs. 
                </p>
                <p className='text-sm mb-0'>
                    To create an outstanding item based on a date found within a document, email, text message, etc. you will need to run an assistant to do so instead of creating one here through an action.
                </p>
            </div>
    
        </div>
    )
}



const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(ActionCreateOutstandingItem);