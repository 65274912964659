import { connect } from 'react-redux';
import { Modal } from "reactstrap";
import { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Row = ({field, description, value}) => {

    const [copied, setCopied] = useState(false);

    const onCopy = () => {
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, [1000])
    }

    return (
        <tr>
            <td>{field}</td>
            <td>{description ? description : ''}</td>
            <td className='text-right'>
                {value ? (
                     <CopyToClipboard 
                        text={`[[${value}]]`}
                        onCopy={onCopy}
                    >
                        <span className={copied ? 'text-success' : ' cursor-pointer'}>[[{value}]] <i className="fas fa-copy ml-2 text-success"></i></span> 
                   </CopyToClipboard>
           
                ): ''}
            </td>
        </tr>
    )
}

const Divider = ({text, description}) => (
    <tr className='bg-dark'>
        <td><b className='text-white'>{text}</b></td>
        <td className='text-white'>{description ? <i className="fas fa-info-circle text-info"></i> : ''} {description}</td>
        <td></td>
    </tr>
)

const ModalCustomFields = ({showModal, toggleModal}) => {

    return (

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">IntuiDesk Custom Fields</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th style={{width: 200}}>Field</th>
                            <th>Discription</th>
                            <th className='text-right' style={{width: 250}}>Text</th>
                        </tr>
                    </thead>
                    <tbody>
                        <Divider text="Company Fields" />

                        <Row 
                            field="Name"
                            description="The internal name of your company"
                            value="company.name"
                        />
                        <Row 
                            field="Display Name"
                            description="The display name of your company"
                            value="company.display_name"
                        />
                        <Row 
                            field="Legal Name"
                            description="The legal name of your company"
                            value="company.legal_name"
                        />
                        <Row 
                            field="Phone"
                            description="Main Company Number"
                            value="company.phone"
                        />
                        <Row 
                            field="Email"
                            description="Main Company Email"
                            value="company.email"
                        />
                        <Row 
                            field="Timezone"
                            description=""
                            value="company.timezone"
                        />
                        <Row 
                            field="Hours Of Operation"
                            description="This will create a line by line breakdown of you company's open hours inside an email or a sideby side and comma separating list inside a text message"
                            value="company.hours_of_operation"
                        />
                        <Row 
                            field="Address Line 1"
                            description=""
                            value="company.address_line_1"
                        />
                        <Row 
                            field="Address Line 2"
                            description=""
                            value="company.address_line_2"
                        />
                        <Row 
                            field="City"
                            description=""
                            value="company.city"
                        />
                        <Row 
                            field="State"
                            description=""
                            value="company.state"
                        />
                        <Row 
                            field="Postal Code (Zip Code)"
                            description=""
                            value="company.postal_code"
                        />
                    
                
                        <Divider text="Division Fields" description="Must be used in emails/texts related to a matter or fields will come up blank" />

                        <Row 
                            field="Name"
                            description="The internal name of the division "
                            value="division.name"
                        />
                        <Row 
                            field="Display Name"
                            description="The display name of the division"
                            value="division.display_name"
                        />
                        <Row 
                            field="Legal Name"
                            description="The legal name of the division"
                            value="division.legal_name"
                        />
                        <Row 
                            field="Phone"
                            description="Main Division Number"
                            value="division.phone"
                        />
                        <Row 
                            field="Email"
                            description="Main Division Email"
                            value="division.email"
                        />
                        <Row 
                            field="Timezone"
                            description=""
                            value="division.timezone"
                        />
                        <Row 
                            field="Hours Of Operation"
                            description="This will create a line by line breakdown of the referenced division open hours inside an email or a sideby side and comma separating list inside a text message"
                            value="division.hours_of_operation"
                        />
                        <Row 
                            field="Address Line 1"
                            description=""
                            value="division.address_line_1"
                        />
                        <Row 
                            field="Address Line 2"
                            description=""
                            value="division.address_line_2"
                        />
                        <Row 
                            field="City"
                            description=""
                            value="division.city"
                        />
                        <Row 
                            field="State"
                            description=""
                            value="division.state"
                        />
                        <Row 
                            field="Postal Code (Zip Code)"
                            description=""
                            value="division.postal_code"
                        />
                                                
                        <Divider text="Location Fields" description="Must be used in emails/texts related to a matter or fields will come up blank"  />

                        <Row 
                            field="Name"
                            description="The name of the location"
                            value="location.{workflow location id}.name"
                        />

                        <Row 
                            field="Phone"
                            description="Main Location Number"
                            value="location.{workflow location id}.phone"
                        />
                        <Row 
                            field="Email"
                            description="Main Location Email"
                            value="location.{workflow location id}.email"
                        />
                        <Row 
                            field="Fax Number"
                            description="Main Location Fax Number"
                            value="location.{workflow location id}.fax_number"
                        />
                        <Row 
                            field="Address Line 1"
                            description=""
                            value="location.{workflow location id}.address_line_1"
                        />
                        <Row 
                            field="Address Line 2"
                            description=""
                            value="location.{workflow location id}.address_line_2"
                        />
                        <Row 
                            field="City"
                            description=""
                            value="location.{workflow location id}.city"
                        />
                        <Row 
                            field="State"
                            description=""
                            value="location.{workflow location id}.state"
                        />
                        <Row 
                            field="Postal Code (Zip Code)"
                            description=""
                            value="location.{workflow location id}.postal_code"
                        />
                        <Row 
                            field="NOTE****"
                            description="All locations must have the workflow location id passed in to associate the correct link between location. and .{field}"

                        />

                        <Divider text="Matter Fields" />

                        <Row 
                            field="identifier"
                            description="Field that is used to relate a matter to an outside system such as a case id."
                            value="matter.identifier"
                        />
                        <Row 
                            field="Step"
                            description="The name of the step the matter is currently on."
                            value="matter.step"
                        />
                        <Row 
                            field="Name"
                            description="The name of the matter."
                            value="matter.name"
                        />
                        <Row 
                            field="Missing Documents"
                            description="A numbered list of all matter documents in either the expired or pending status."
                            value="matter.missing_documents"
                        />

                        <Divider text="Contact Fields" />

                        <Row 
                            field="First Name"
                            description="Field is stored in all lowercase letters and will have a forced capitalization of the first letter when used"
                            value="contact.{workflow contact id}.given_name"
                        />
                        <Row 
                            field="Last Name"
                            description="Field is stored in all lowercase letters and will have a forced capitalization of the first letter when used"
                            value="contact.{workflow contact id}.family_name"
                        />
                        <Row 
                            field="Display Name"
                            description="Full name of contact as entered in the system, not stored in lowercase"
                            value="contact.{workflow contact id}.display_name"
                        />

                        <Row 
                            field="Phone"
                            description="Primary Contact Phone Number"
                            value="contact.{workflow contact id}.phone"
                        />
                        <Row 
                            field="Secondary Phone"
                            description="Secondary Contact Phone Number"
                            value="contact.{workflow contact id}.phone_2"
                        />
                        <Row 
                            field="Tertiary Phone"
                            description="Tertiary Contact Phone Number"
                            value="contact.{workflow contact id}.phone_3"
                        />
                        <Row 
                            field="Email"
                            description="Primary Contact Email"
                            value="contact.{workflow contact id}.email"
                        />
                        <Row 
                            field="Secondary Email"
                            description="Secondary Contact Email"
                            value="contact.{workflow contact id}.email_2"
                        />
                        <Row 
                            field="Tertiary Email"
                            description="Tertiary Contact Email"
                            value="contact.{workflow contact id}.email_3"
                        />

                        <Row 
                            field="Address Line 1"
                            description=""
                            value="contact.{workflow contact id}.address_line_1"
                        />
                        <Row 
                            field="Address Line 2"
                            description=""
                            value="contact.{workflow contact id}.address_line_2"
                        />
                        <Row 
                            field="City"
                            description=""
                            value="contact.{workflow contact id}.city"
                        />
                        <Row 
                            field="State"
                            description=""
                            value="contact.{workflow contact id}.state"
                        />
                        <Row 
                            field="Postal Code (Zip Code)"
                            description=""
                            value="contact.{workflow contact id}.postal_code"
                        />
                        <Row 
                            field="Internal ID"
                            description="The IntuiDesk ID of the contact"
                            value="contact.{workflow contact id}.id"
                        />


                        <Divider text="User Role Fields" description="Must be used in emails/texts related to a matter or fields will come up blank"  />

                        <Row 
                            field="First Name"
                            description="Field is stored in all lowercase letters and will have a forced capitalization of the first letter when used"
                            value="role.{role id}.given_name"
                        />
                        <Row 
                            field="Last Name"
                            description="Field is stored in all lowercase letters and will have a forced capitalization of the first letter when used"
                            value="role.{role id}.family_name"
                        />
                        <Row 
                            field="Display Name"
                            description="Full name of a user as entered in the system, not stored in lowercase"
                            value="role.{role id}.display_name"
                        />
                        <Row 
                            field="Phone"
                            description="User Division Phone Number (defaults to main user number if division is not found - use carefully)"
                            value="role.{role id}.phone"
                        />
                        <Row 
                            field="Email"
                            description="User Division Email (defaults to main user number if division is not found - use carefully)"
                            value="role.{role id}.email"
                        />
                        <Row 
                            field="Title"
                            description="User Title (defaults to main user number if division is not found - use carefully)"
                            value="role.{role id}.title"
                        />

                        
                        <Row 
                            field="NOTE****"
                            description="All role fields must have the role id passed in to associate the correct link between role. and .{field}"

                        />


                                                

                    
                        <Divider text="Custom Fields" description="Must be used in emails/texts related to a matter or fields will come up blank"  />

                        <Row 
                            field="custom.{id}"
                            description="Custom fields can be inserted by typing custom.{field id}. This will grab the value of the field and insert it wihin an email or text."
                            value="custom.{custom_field_id}"
                        />

                        <Divider text="Misc Fields" description=""  />

                        <Row 
                            field="Portal Link"
                            description="The url where a contact can access their portal"
                            value="link.portal"
                        />
                        <Row 
                            field="Payment Link"
                            description="A portal url link that will take the contact directly to where they can make a payment. Will match with a matter if a matter is found"
                            value="link.payment"
                        />
                        <Row 
                            field="Signature Link"
                            description="A link to sign the most recent document sent out to the contact for signatue. Will match with a matter if a matter is found."
                            value="link.signature"
                        />

                        <Divider text="String Templates" />

                        <Row 
                            field="string_template.{id}"
                            description="String templates can be inserted by typing string_template.{field id}. This will grab the value of the template and can be used as a url or description."
                            value="string_template.abu18d7..."
                        />

                    </tbody>
                </table>
            </div>

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
            </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(ModalCustomFields);
