import { Card, CardFooter, CardHeader, CardTitle, Modal } from "reactstrap";

import Circle from 'components/markup/loading/Circle'

import ApiError from 'components/markup/layout/ApiError'

const Modalify = ({showModal, toggleModal, title, modalTitle, sections, footer, err, size}) => {
    const loading = !sections.length

    if(toggleModal) return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size={size ? size : 'lg'}
        >

            <div className="modal-header">
                <h5 className="modal-title">{modalTitle}</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            {loading ? (
                <div className="pb-4 pt-5 mb-2">
                    <Circle />
                </div>
            ) : (
                sections.map((section, i) => (
                    <div key={i} className={`modal-body ${i === 0 ? '' : 'border-top'}`}>
                        {section}
                    </div>
                ))
            )}

            {err ? (
                <ApiError err={err} />
            ) : null}

            <div className="modal-footer text-right">
                {footer}
            </div>

        </Modal>
    )

    return (
        <Card>
            {title ? (
                <CardHeader>
                    <CardTitle className="mb-0">{title}</CardTitle>
                </CardHeader>
            ) : null}
            {loading ? (
                <div className="pb-4 pt-5 mb-2">
                    <Circle />
                </div>
            ) : sections.map((section, i) => (
                !section ? '' :
                <CardFooter key={i} style={i === 0 ? {borderTop: 'none'} : {}}>
                    {section}
                </CardFooter>
            ))}


            {err ? (
                <ApiError err={err} />
            ) : null}

            <CardFooter className="text-right">
                {footer}
            </CardFooter>

        </Card>
    );

}


export default Modalify;