import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row } from "reactstrap";


import BarLine from 'components/charts/BarLine';
import PieArea from 'components/charts/PieArea';

const LocationsAll = ({setTab, selected_division}) => {



    return (

        <>

        <Row>
            <Col md={6}>
                <BarLine 
                    type="line"
                    title="Incoming Calls By Hour"
                    labels={[ '12AM', '1AM', '2AM', '3AM', '4AM', '5AM', '6AM', '7AM', '8AM', '9AM', '10AM', '11AM', '12PM', '1PM', '2PM', '3PM', '4PM', '5PM', '6PM', '7PM', '8PM', '9PM', '10PM', '11PM', ]}
                    datasets={[
                        {
                            label: 'Total',
                            data: [0,0,0,0,0,0,0,0,3, 7, 14, 9, 14, 15, 13, 14, 7,5,0,0,0,0,0,0,],
                        },
                        {
                            label: 'Connected < 1 Min',
                            data: [0,0,0,0,0,0,0,0,2, 4, 1, 6, 3, 5, 8, 10, 0,0,0,0,0,0,0,0,],
                        },
                        {
                            label: 'Connected 1 - 3 Min',
                            data: [0,0,0,0,0,0,0,0,1, 2, 6, 2, 1, 8, 12, 3, 3,2,0,0,0,0,0,0,],
                        },
                        {
                            label: 'Connected 3 Min +',
                            data: [0,0,0,0,0,0,0,0,1, 2, 4, 2, 4, 3, 5, 2, 1,1,0,0,0,0,0,0,],
                        },
                    ]}
                />

            </Col>
            <Col md={6}>
                <BarLine 
                    type="line"
                    title="Outgoing Calls By Hour"
                    labels={[ '12AM', '1AM', '2AM', '3AM', '4AM', '5AM', '6AM', '7AM', '8AM', '9AM', '10AM', '11AM', '12PM', '1PM', '2PM', '3PM', '4PM', '5PM', '6PM', '7PM', '8PM', '9PM', '10PM', '11PM', ]}
                    datasets={[
                        {
                            label: 'Total',
                            data: [0,0,0,0,0,0,0,0,3, 6, 5, 8, 7, 8, 13, 4, 1,1,0,0,0,0,0,0,],
                        },
                        {
                            label: 'Sales',
                            data: [0,0,0,0,0,0,0,0,1, 2, 4, 2, 4, 3, 5, 2, 1,1,0,0,0,0,0,0,],
                        },
                        {
                            label: 'Other',
                            data: [0,0,0,0,0,0,0,0,2, 4, 1, 6, 3, 5, 8, 1, 0,0,0,0,0,0,0,0,],
                        },
                    ]}
                />

            </Col>

            <Col md={3}>
                <PieArea 
                    type="pie"
                    title="Incoming Category Breakdown"
                    labels={['Pre-Filed', 'Post Filed', 'Confirmation', "Now Show"]}
                    dataset={[12, 19, 3, 5]}
                />
            </Col>
            <Col md={3}>
                <PieArea 
                    type="pie"
                    title="Incoming Disposition Breakdown"
                    labels={['Hired', 'DNQ', 'Potential', 'Other']}
                    dataset={[2, 14, 2, 8]}
                />
            </Col>
            <Col md={3}>
                <PieArea 
                    type="pie"
                    title="Outgoing Category Breakdown"
                    labels={['Pre-Filed', 'Post Filed', 'Confirmation', "Now Show"]}
                    dataset={[9, 1, 2, 4]}
                />
            </Col>
            <Col md={3}>
                <PieArea 
                    type="pie"
                    title="Outgoing Disposition Breakdown"
                    labels={['Hired', 'DNQ', 'Potential', 'Other']}
                    dataset={[3, 1, 18, 7]}
                />
            </Col>

        </Row>

        <Row>
            <Col lg={3}>
                <Card className='text-center'>
                    <CardHeader>
                        <CardTitle className="mb-0">Avg Incoming Call Time</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <h2 className='display-2 mb-0'>4:37 <small><small className='text-muted'>Minutes</small></small></h2>
                    </CardBody>
                    <CardFooter>
                        12 Weeks Ago: <b className='text-dark font-weight-bold '>3:59 Seconds</b>
                    </CardFooter>
                </Card>
            </Col>
            <Col lg={3}>
                <Card className='text-center'>
                    <CardHeader>
                        <CardTitle className="mb-0">Avg Outgoing Call Time</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <h2 className='display-2 mb-0'>47 <small><small className='text-muted'>Seconds</small></small></h2>
                    </CardBody>
                    <CardFooter>
                        12 Weeks Ago: <b className='text-dark font-weight-bold '>69 Seconds</b>
                    </CardFooter>
                </Card>
            </Col>
            <Col lg={3}>
                <Card className='text-center'>
                    <CardHeader>
                        <CardTitle className="mb-0">Avg Hold Time</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <h2 className='display-2 mb-0'>29 <small><small className='text-muted'>Seconds</small></small></h2>
                    </CardBody>
                    <CardFooter>
                        12 Weeks Ago: <b className='text-dark font-weight-bold '>19 Seconds</b>
                    </CardFooter>
                </Card>
            </Col>
            <Col lg={3}>
                <Card className='text-center'>
                    <CardHeader>
                        <CardTitle className="mb-0">Incoming Calls</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <h2 className='display-2 mb-0'>587 <small><small className='text-muted pl-4'>+13%</small></small></h2>
                    </CardBody>
                    <CardFooter>
                        12 Weeks Ago: <b className='text-dark font-weight-bold '>58</b>
                    </CardFooter>
                </Card>
            </Col>
            <Col lg={3}>
                <Card className='text-center'>
                    <CardHeader>
                        <CardTitle className="mb-0">Outgoing Calls <small><small className='text-muted pl-4'>-14%</small></small></CardTitle>
                    </CardHeader>
                    <CardBody>
                        <h2 className='display-2 mb-0'>3,173</h2>
                    </CardBody>
                    <CardFooter>
                        12 Weeks Ago: <b className='text-dark font-weight-bold '>5,198</b>
                    </CardFooter>
                </Card>
            </Col>
           
            <Col lg={3}>
                <Card className='text-center'>
                    <CardHeader>
                        <CardTitle className="mb-0">Callbacks Requested</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <h2 className='display-2 mb-0'>93 <small><small className='text-muted'>-18%</small></small></h2>
                    </CardBody>
                    <CardFooter>
                        12 Weeks Ago: <b className='text-dark font-weight-bold '>108</b>
                    </CardFooter>
                </Card>
            </Col>
            <Col lg={3}>
                <Card className='text-center'>
                    <CardHeader>
                        <CardTitle className="mb-0">Missed Dials</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <h2 className='display-2 mb-0'>36 <small><small className='text-muted'>-22%</small></small></h2>
                    </CardBody>
                    <CardFooter>
                        12 Weeks Ago: <b className='text-dark font-weight-bold '>46</b>
                    </CardFooter>
                </Card>
            </Col>
           
            <Col lg={3}>
                <Card className='text-center'>
                    <CardHeader>
                        <CardTitle className="mb-0">Current Queue </CardTitle>
                    </CardHeader>
                    <CardBody>
                        <h2 className='display-2 mb-0'>18 <small><small className='text-muted'>-59  %</small></small></h2>
                    </CardBody>
                    <CardFooter>
                        12 Weeks Ago: <b className='text-dark font-weight-bold '>39</b>
                    </CardFooter>
                </Card>
            </Col>
            <Col lg={3}>
                <Card className='text-center'>
                    <CardHeader>
                        <CardTitle className="mb-0">Unread Texts </CardTitle>
                    </CardHeader>
                    <CardBody>
                        <h2 className='display-2 mb-0'>12</h2>
                    </CardBody>
                    <CardFooter>
                        Across 7 Users
                    </CardFooter>
                </Card>
            </Col>
            <Col lg={3}>
                <Card className='text-center'>
                    <CardHeader>
                        <CardTitle className="mb-0">Unread Emails </CardTitle>
                    </CardHeader>
                    <CardBody>
                        <h2 className='display-2 mb-0'>12</h2>
                    </CardBody>
                    <CardFooter>
                        Across 7 Users
                    </CardFooter>
                </Card>
            </Col>
        </Row>

        </>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(LocationsAll);