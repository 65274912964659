import { useCallback, useState } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Card, Badge } from "reactstrap";
import moment from 'moment';

import api from 'api';

import TextButtonRow from 'components/markup/layout/TextButtonRow'
import ConfirmationModal from 'components/functional/modals/Confirmation'
import TableIcons from 'components/markup/icons/TableIcons'
import Table from 'components/functional/tables/Standard';
import LoadingTable from 'components/markup/loading/Table';
import ApiError from "components/markup/layout/ApiError";

import { toast } from 'react-toastify'

import { setAssociationErrors } from 'store/functions/system/system'

const AutomationsAll = ({setTab, selected_division}) => {

    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ automationToDelete, setAutomationToDelete ] = useState(false)
    const [ error, setError ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    // helper for this.downloadCSV and this.query
    const fetchData = useCallback((params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        const query = await api.automations.search({ ...params, filter: { division: selected_division._id }, isCSV })
        
        if(!query.data) return setError(query.message)
        return resolve(query.data)
    }), [sizePerPage, selected_division._id])

    // run query to get data from the server
    const query = useCallback(async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }, [fetchData])

    const onDeleteAutomation = useCallback(async () => {
        const deleted = await api.automations.delete(automationToDelete._id);
        if(deleted.success) {
            setLoading(true)
            fetchData();
            setTimeout(() => setLoading(false), 250)
            toast.success('Automation Successfully Removed')
        } else {
            if(deleted.message[0] && deleted.message[0].err === 'associations') {
                setAssociationErrors(deleted.message[0].associations)
            } else {
                toast.error(`Could not delete automation, please try again.`)
            }
        }
    }, [fetchData, automationToDelete])
 
    const columns = [
        {
            dataField: "name",
            text: 'Name',
            formatter: (cell, row) => row.name === 'no data' ? 'No Data' : (
                <Link className="text-capitalize" to={`/editor/automations/${row._id}`}>
                    {row.name}
                </Link>
            )
        },
        {
            dataField: "active",
            text: 'Active',
            formatter: (cell, row) => (
               row.name === 'no data' ? '' : row.active ? <Badge color="success"><i className="fas fa-check mr-2 " /> Yes</Badge> : <Badge color="danger"><i className="fas fa-times mr-2 " /> No</Badge>
            )
        },
        {
            dataField: "created_at",
            text: 'Created',
            formatter: (cell, row) => row.name === 'no data' ? '' :  moment.unix(row.created_at).format('MMM Do, YYYY')

        },
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right',},
            text: 'Actions',
            headerAutomationatter: () => {
                return <div id="archk-tutorial-contacts-actions">Actions</div>
            },
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                        <TableIcons
                            icons={[
                                { 
                                    icon: 'fas fa-edit',
                                    color: 'success', 
                                    wrapper: Link, 
                                    to: `/editor/automations/${row._id}`,
                                    form_id: row._id
                                },
                                {  
                                    icon: 'fas fa-trash', 
                                    color: 'danger',
                                    onClick: () => setAutomationToDelete(row)
                                },
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(error) return <ApiError err={error} />
    if(loading) return <LoadingTable />

    return (

        <>

            <TextButtonRow 
                title={"All Automations"}
                button={(
                    <button onClick={() => setTab('create')} className="btn btn-success"><i className="fas fa-plus mr-2 " /> Add Automation</button>
                )}
            />

            <Card className="card-color card-primary table-fixed">
                <Table
                    tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                    placeholder="Search..."
                    query={query}
                    columns={columns}
                    data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}
                    sizePerPage={sizePerPage}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>

            <ConfirmationModal 
                showModal={automationToDelete ? true : false}
                toggleModal={() => setAutomationToDelete(null)}
                title="Delete Automation"
                body={(
                    <span>
                        Are you sure you wish to delete the automation <b className="text-dark text-uppercase">"{automationToDelete ? automationToDelete.name : ''}"</b>? This will cancel any currently running automations assigned to it and prevent any workflows from triggering its start in the future.
                    </span>
                )}
                onConfirmation={onDeleteAutomation}
            />

        </>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(AutomationsAll);