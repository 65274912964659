import ObjSearch from 'components/system/Search/ObjSearch';
import { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Badge, Card, CardBody, Col, FormGroup, Row } from 'reactstrap';

import SearchCollections from 'components/system/Search/Collections';
import ReactSelect from 'components/functional/inputs/ReactSelect';

import { v4 } from 'uuid';

import Values from './Values';

const WorkflowSidebarSigningTemplates = ({customFields, contacts, fetchCustomFields, stepToEdit, setStepToEdit, selected_division, steps, roles, errs}) => {

    const onAddSigningTemplate = useCallback(() => {
        const _stepToEdit = JSON.parse(JSON.stringify(stepToEdit));
        const templates = _stepToEdit.signing_templates;
        templates.push({
            id: v4(),
            signing_template    : '' ,
            move_to_step        : null ,
            email_roles         : [] ,
            send_on_move        : false,
            send_to_contact     : contacts[0] ? contacts[0]._id : null,
            values: []
        })

        setStepToEdit(_stepToEdit);
    }, [stepToEdit, setStepToEdit])

    const onRemoveSigningTemplate = useCallback((id) => {
        const _stepToEdit = JSON.parse(JSON.stringify(stepToEdit));
        let templates = _stepToEdit.signing_templates;

        _stepToEdit.signing_templates = templates.filter(t => {
            if(t.id) return t.id !== id
            return t._id !== id
        })
        setStepToEdit(_stepToEdit);
    }, [stepToEdit, setStepToEdit])

    const onChange = useCallback((id, field, value) => {
        const _stepToEdit = JSON.parse(JSON.stringify(stepToEdit));
        const step = _stepToEdit.signing_templates.find(s => s._id === id || s.id === id)
        if(!step) return;
        step[field] = value;
        setStepToEdit(_stepToEdit);
    }, [stepToEdit, setStepToEdit])
    
    const onAddEmailRole = useCallback((id, value) => {
        const _stepToEdit = JSON.parse(JSON.stringify(stepToEdit));
        const step = _stepToEdit.signing_templates.find(s => s._id === id || s.id === id)
        if(!step) return;
        if(!step.email_roles.includes(value)) step.email_roles.push(value)

        setStepToEdit(_stepToEdit);
    }, [stepToEdit, setStepToEdit])
    
    const onRemoveEmailRole = useCallback((id, value) => {
        const _stepToEdit = JSON.parse(JSON.stringify(stepToEdit));
        const step = _stepToEdit.signing_templates.find(s => s._id === id || s.id === id)
        if(!step) return;
        step.email_roles = step.email_roles.filter(r => r !== value)
        setStepToEdit(_stepToEdit);
    }, [stepToEdit, setStepToEdit])
    
    const renderRoleName = useCallback((_id) => {
        const role = roles.find(r => r._id === _id)  
        return role ? role.name : ''
    }, [roles])

    const options = steps.filter(step => {
        const id = step._id ? step._id : step.id;
        return stepToEdit.next_steps.includes(id)
    })
    options.unshift({_id: null, name: "**DO NOT MOVE**"})

    useEffect(() => {
        if(!customFields) fetchCustomFields()
    }, [customFields, fetchCustomFields])

    return (
        <>
            <Row className='mb-3'>
                <Col xs={6} className="align-self-center">
                    <h2 className='text-warning'>STEP SIGNATURES</h2>
                </Col>
                <Col xs={6} className="align-self-center text-right">
                    <button className='btn btn-sm btn-info' onClick={onAddSigningTemplate}><i className="fas fa-plus mr-2" /> Add New</button>
                </Col>
            </Row>

            {stepToEdit.signing_templates.length ? stepToEdit.signing_templates.map((template, i) => {
                const id = template.id ? template.id : template._id ;

                return (
                    <Card key={id} className="z-depth-0 ">
                        <CardBody className='label-block'>
                            <FormGroup className='mb-0'>
                                <label className='form-control-label'>
                                    <Row>
                                        <Col xs={6} className="align-self-center">
                                        Signing Template #{i + 1}
                                        </Col>
                                        <Col xs={6} className="align-self-center text-right">
                                            <i onClick={() => onRemoveSigningTemplate(id)} className="fas fa-trash mr-2 text-danger cursor-pointer pl-3" />
                                        </Col>
                                    </Row>
                                </label>
                            </FormGroup>
                            <SearchCollections
                                collection="signing_templates" 
                                value={template.signing_template}
                                onChange={(obj) => onChange(id, 'signing_template', obj.value)}
                                filter={{
                                    division: selected_division._id
                                }}
                            /> 
                            <SearchCollections
                                title="Email Sender"
                                collection="email_senders" 
                                value={template.email_sender}
                                onChange={(obj) => onChange(id, 'email_sender', obj.value)}
                                filter={{
                                    division: selected_division._id
                                }}
                            /> 

                            <Row>
                                <Col lg={6}>
                                    <ReactSelect 
                                        formGroup={true}
                                        title="Send For Signature On Move"
                                        value={template.send_on_move}
                                        onChange={(obj) => onChange(id, 'send_on_move', obj.value)}
                                        options={
                                            [
                                                { value: false, label: <span><i className="fas fa-times text-danger mr-2 " />No</span> },
                                                { value: true, label: <span><i className="fas fa-check text-success mr-2 " />Yes</span> },
                                            ]
                                        }
                                    />

                                    {template.send_on_move ? (
                                        <ReactSelect 
                                            formGroup={true}
                                            title="Which Contact Should This Document Be Sent To?"
                                            value={template.send_to_contact}
                                            onChange={(obj) => onChange(id, 'send_to_contact', obj.value)}
                                            options={contacts.map((role, i) => {
                                                return { value: role._id, label: role.name }
                                            })}
                                        />
                                    ) : ''}

                                </Col>
                             
                                <Col lg={6}>
                                    <ObjSearch 
                                        objs={options}
                                        label={(
                                            <span>
                                                Move To Step When Signed
                                            </span>
                                        )}
                                        value={template.move_to_step}
                                        onChange={(obj) => onChange(id, 'move_to_step', obj.value)}
                                    />
                                </Col>
                            </Row>

                            <FormGroup>

                                <ReactSelect 
                                    formGroup={true}
                                    title="Email Roles When Signed"
                                    value={""}
                                    onChange={(obj) => onAddEmailRole(id, obj.value)}
                                    options={roles.map((role, i) => {
                                        return { value: role._id, label: role.name }
                                    })}
                                />

                                {template.email_roles.length ? template.email_roles.map((role) => (
                                    <Badge key={role} color="success" className="ml-0 mb-2 text-left mr-3">
                                        {renderRoleName(role)}
                                        <span className="pr-2 pl-3 cursor-pointer float-right" onClick={() => onRemoveEmailRole(id, role)}>
                                            <i className="fas fa-times " />
                                        </span>
                                    </Badge>
                                )) : null}

                            </FormGroup>

                            <Values 
                                onChange={onChange} 
                                template={template} 
                                customFields={customFields}  
                                contacts={contacts}  
                            />
                            
                        </CardBody>
                    </Card>
            )}) : (
                <p className='text-sm mb-0'><i className="fas fa-info-circle text-info" /> No Signing Templates Have Been Added</p>
            )}

            {errs.includes('signing_template') ? (
                <div className='alert alert-warning'>
                    <i className="fas fa-info-circle" /> Each template requires an associated signing template to be selected.
                </div>
            ) : ''}

        </>
    )
}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(WorkflowSidebarSigningTemplates);