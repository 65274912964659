import { Row, Col } from "reactstrap";
import { connect } from 'react-redux';
import { useCallback, useState } from 'react';

import Privilege  from "./Privilege";

const UsersEditPrivilegesAdmin = ({user, setUser, onPrivilegeChange, PRIVILEGES}) => {

    const [standardPrivileges] = useState([
        PRIVILEGES['AUTOMATIONS.WRITE'],
        PRIVILEGES['BACKGROUND_PROCESSES.READ'],
        PRIVILEGES['BACKGROUND_PROCESSES.WRITE'],

        PRIVILEGES['CALLS.WRITE'],
        PRIVILEGES['CALL_PHONE_NUMBERS.WRITE'],
        PRIVILEGES['CALL_FLOWS.WRITE'],
        
        PRIVILEGES['COMPANIES.WRITE'],

        PRIVILEGES['CONTACT_TYPES.WRITE'],
        
        PRIVILEGES['CUSTOM_FIELDS.WRITE'],
        PRIVILEGES['CUSTOM_VIEWS.WRITE'],

        PRIVILEGES['DIVISIONS.WRITE'],
        
        PRIVILEGES['EVENT_TEMPLATES.WRITE'],
        PRIVILEGES['EVENT_OUTCOMES.WRITE'],
        PRIVILEGES['EMAIL_SENDERS.WRITE'],
        PRIVILEGES['EMAIL_TEMPLATES.WRITE'],
        
        PRIVILEGES['EXECUTION_LOGS.READ'],
        PRIVILEGES['EXECUTION_LOGS.WRITE'],
        
        PRIVILEGES['FORMS.WRITE'],

        PRIVILEGES['LEAD_SOURCES.WRITE'],

        PRIVILEGES['LOCATIONS.WRITE'],


        PRIVILEGES['PIPELINES.WRITE'],

        PRIVILEGES['SIGNING.TEMPLATES.WRITE'],

        PRIVILEGES['STRING_TEMPLATES.WRITE'],

        PRIVILEGES['SOCIAL_SECURITY_NUMBERS.READ'],

        PRIVILEGES['STORED_KEYS.READ'],
        PRIVILEGES['STORED_KEYS.WRITE'],

        PRIVILEGES['TAGS.WRITE'],

        PRIVILEGES['TIME_LOGS.READ'],
        PRIVILEGES['TIME_LOGS.WRITE'],
        PRIVILEGES['TIME_OFF_REQUESTS.WRITE'],
        PRIVILEGES['TIME_OFF_REQUESTS.READ'],
        
        PRIVILEGES['TEXT_TEMPLATES.WRITE'],

        PRIVILEGES['TIME_ENTRY_TEMPLATES.WRITE'],
       
        PRIVILEGES['USERS.WRITE'],

        PRIVILEGES['WIKIS.WRITE'],

        PRIVILEGES['WORKFLOWS.WRITE'],
    ])

    const assignAll = useCallback(() => {
        const newUser = Object.assign({}, user)
        standardPrivileges.forEach(privilege => {
            if(!newUser.privileges.includes(privilege)) {
                newUser.privileges.push(privilege)
            }
        })
     
        setUser(newUser);
    }, [standardPrivileges, setUser, user])
   
    const removeAll = useCallback(() => {
        const newUser = Object.assign({}, user)
        standardPrivileges.forEach(privilege => {
            newUser.privileges = newUser.privileges.filter(p => p !== privilege)
        })
     
        setUser(newUser);
    }, [standardPrivileges, setUser, user])

    return (
        <div>

            <Row>
                <Col xs={6} className="align-self-center">
                    <h2>Admin Privileges</h2>
                </Col>
                <Col xs={6} className="align-self-center text-right">
                    <button onClick={removeAll} className="btn btn-outline-warning">Remove All</button>
                    <button onClick={assignAll} className="btn btn-outline-info">Assign All</button>
                </Col>
            </Row>

            <Row>
                <Col lg={6}>
                    <h5 className="text-uppercase">Automations</h5>
                    <Privilege 
                        privilege={PRIVILEGES['AUTOMATIONS.WRITE']}
                        user={user}
                        title="Can Create / Update Automations"
                        onPrivilegeChange={onPrivilegeChange}
                        description="Gives the user the create and update automations."
                    />
                    <h5 className="text-uppercase">Background Processes</h5>
                    <Privilege 
                        privilege={PRIVILEGES['BACKGROUND_PROCESSES.READ']}
                        user={user}
                        title="Can view processes that run in the background."
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['BACKGROUND_PROCESSES.WRITE']}
                        user={user}
                        title="Can Create / Update Background Processes"
                        onPrivilegeChange={onPrivilegeChange}
                        description="Gives the user the create and update process that run behind the scenes."
                    />

                    <h5 className="text-uppercase">Calls</h5>
                    <Privilege 
                        privilege={PRIVILEGES['CALLS.WRITE']}
                        user={user}
                        title="Can update calls, delete recordings, audit calls, etc."
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['CALL_PHONE_NUMBERS.WRITE']}
                        user={user}
                        title="Can update/create phone numbers."
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['CALL_FLOWS.WRITE']}
                        user={user}
                        title="Can update/create flows."
                        onPrivilegeChange={onPrivilegeChange}
                    />

                    <h5 className="text-uppercase">Companies</h5>
                    <Privilege 
                        privilege={PRIVILEGES['COMPANIES.WRITE']}
                        user={user}
                        title="Can Update / Create Company Info"
                        onPrivilegeChange={onPrivilegeChange}
                        description="Gives the user the ability to edit company details like logo, setting, etc."
                    />

                    <h5 className="text-uppercase">Contact Types</h5>
                    <Privilege 
                        privilege={PRIVILEGES['CONTACT_TYPES.WRITE']}
                        user={user}
                        title="Can Create / Update Contact Types"
                        onPrivilegeChange={onPrivilegeChange}
                        description="Gives the user the ability to change contact types on a division level."
                    />

                    <h5 className="text-uppercase">Custom Views</h5>
                    <Privilege 
                        privilege={PRIVILEGES['CUSTOM_VIEWS.WRITE']}
                        user={user}
                        title="Can Create Custom Views"
                        onPrivilegeChange={onPrivilegeChange}
                    />

                    <h5 className="text-uppercase">Custom Fields</h5>
                    <Privilege 
                        privilege={PRIVILEGES['CUSTOM_FIELDS.WRITE']}
                        user={user}
                        title="Can Create / Update Custom Fields"
                        onPrivilegeChange={onPrivilegeChange}
                        description="Gives the user the ability to change mapped fields."
                    />

                    <h5 className="text-uppercase">Divisions</h5>
                    <Privilege 
                        privilege={PRIVILEGES['DIVISIONS.WRITE']}
                        user={user}
                        title="Can Update / Create Division Info"
                        onPrivilegeChange={onPrivilegeChange}
                        description="Allows the user to create and change divisions."
                    />

                    <h5 className="text-uppercase">Emails</h5>
                    <Privilege 
                        privilege={PRIVILEGES['EMAIL_SENDERS.WRITE']}
                        user={user}
                        title="Can Create / Update Email Senders"
                        onPrivilegeChange={onPrivilegeChange}
                        description="Gives the user the ability to change and create default email senders."
                    />
                    <Privilege 
                        privilege={PRIVILEGES['EMAIL_TEMPLATES.WRITE']}
                        user={user}
                        title="Can Create / Update Email Templates"
                        onPrivilegeChange={onPrivilegeChange}
                        description="Gives the user the create and update email templates."
                    />

                    <h5 className="text-uppercase">Events</h5>
                    <Privilege 
                        privilege={PRIVILEGES['EVENT_TEMPLATES.WRITE']}
                        user={user}
                        title="Can Create / Update Event Templates"
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['EVENT_OUTCOMES.WRITE']}
                        user={user}
                        title="Can Create / Update Event Outcomes"
                        onPrivilegeChange={onPrivilegeChange}
                    />
                   
                    <h5 className="text-uppercase">Execution Logs</h5>
                    <Privilege 
                        privilege={PRIVILEGES['EXECUTION_LOGS.READ']}
                        user={user}
                        title="Can Read Execution Logs"
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['EXECUTION_LOGS.WRITE']}
                        user={user}
                        title="Can Create / Update Execution Logs"
                        onPrivilegeChange={onPrivilegeChange}
                    />

                    <h5 className="text-uppercase">Forms</h5>
                    <Privilege 
                        privilege={PRIVILEGES['FORMS.WRITE']}
                        user={user}
                        title="Can Update / Create System Forms"
                        onPrivilegeChange={onPrivilegeChange}
                        description="Gives the user the ability to create and make changes to forms."
                    />

                    <h5 className="text-uppercase">Leads</h5>
                    <Privilege 
                        privilege={PRIVILEGES['LEAD_SOURCES.WRITE']}
                        user={user}
                        title="Can create/update lead sources."
                        onPrivilegeChange={onPrivilegeChange}
                    />

                    <h5 className="text-uppercase">Locations</h5>
                    <Privilege 
                        privilege={PRIVILEGES['LOCATIONS.WRITE']}
                        user={user}
                        title="Can Create / Update System Locations"
                        onPrivilegeChange={onPrivilegeChange}
                        description="Gives the user the ability to change system locations."
                    />
                  
                </Col>

                <Col lg={6}>
                    
                    <h5 className="text-uppercase">Pipelines</h5>
                    <Privilege 
                        privilege={PRIVILEGES['PIPELINES.WRITE']}
                        user={user}
                        title="Can Create / Update Pipelines"
                        onPrivilegeChange={onPrivilegeChange}
                        description="Gives the user the create and change pipeline templates."
                    />

                    <h5 className="text-uppercase">Signature Document Templates</h5>   
                    <Privilege 
                        privilege={PRIVILEGES['SIGNING.TEMPLATES.WRITE']}
                        user={user}
                        title="Can Update / Create Signature Document Templates"
                        onPrivilegeChange={onPrivilegeChange}
                        description="Gives the user the ability to create new template documents to be sent for signature."
                    />   
                    
                    <h5 className="text-uppercase">Stored Keys</h5>
                    <Privilege 
                        privilege={PRIVILEGES['STORED_KEYS.READ']}
                        user={user}
                        title="Can view a preview of all keys stored"
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['STORED_KEYS.WRITE']}
                        user={user}
                        title="Can update stored keys"
                        onPrivilegeChange={onPrivilegeChange}
                    />

                    <h5 className="text-uppercase">Social Security Numbers</h5>
                    <Privilege 
                        privilege={PRIVILEGES['SOCIAL_SECURITY_NUMBERS.READ']}
                        user={user}
                        title="Can View Full Social Security Numbers"
                        onPrivilegeChange={onPrivilegeChange}
                    />
                     <h5 className="text-uppercase">String Templates</h5>
                    <Privilege 
                        privilege={PRIVILEGES['STRING_TEMPLATES.WRITE']}
                        user={user}
                        title="Can Create / Update String Templates"
                        onPrivilegeChange={onPrivilegeChange}
                    />

                    <h5 className="text-uppercase">TAGS</h5>
                    <Privilege 
                        privilege={PRIVILEGES['TAGS.WRITE']}
                        user={user}
                        title="Can Create / Update System Tags"
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    
                    <h5 className="text-uppercase">Time Entry Templates</h5>
                    <Privilege 
                        privilege={PRIVILEGES['TIME_ENTRY_TEMPLATES.WRITE']}
                        user={user}
                        title="Can Create / Update Time Entry Templates"
                        onPrivilegeChange={onPrivilegeChange}
                    />

                    <h5 className="text-uppercase">Text Templates</h5>
                    <Privilege 
                        privilege={PRIVILEGES['TEXT_TEMPLATES.WRITE']}
                        user={user}
                        title="Can Create / Update Text Templates"
                        onPrivilegeChange={onPrivilegeChange}
                        description="Gives the user the create and update text templates."
                    />
                    
                    <h5 className="text-uppercase">TimeLogs</h5>
                    <Privilege 
                        privilege={PRIVILEGES['TIME_LOGS.READ']}
                        user={user}
                        title="Can View Time Logs"
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['TIME_LOGS.WRITE']}
                        user={user}
                        title="Can Create / Update Time Logs"
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    
                    <h5 className="text-uppercase">Time Off Requests</h5>
                    <Privilege 
                        privilege={PRIVILEGES['TIME_OFF_REQUESTS.READ']}
                        user={user}
                        title="Can View Time Off Requests"
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['TIME_OFF_REQUESTS.WRITE']}
                        user={user}
                        title="Can Create / Update / Approve Time Off Requests"
                        onPrivilegeChange={onPrivilegeChange}
                    />
                 
                    <h5 className="text-uppercase">Users</h5>
                    <Privilege 
                        privilege={PRIVILEGES['USERS.WRITE']}
                        user={user}
                        title="Can Create / Update Other Users"
                        onPrivilegeChange={onPrivilegeChange}
                        description={<span><i className="fas fa-exclamation-triangle mr-2 text-danger " /> Allows a user to make changes to other users including accessing this page!</span>}
                    />


                    <h5 className="text-uppercase">Workflows</h5>
                    <Privilege 
                        privilege={PRIVILEGES['WORKFLOWS.WRITE']}
                        user={user}
                        title="Can Create / Update Workflows"
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <h5 className="text-uppercase">Wikis</h5>
                    <Privilege 
                        privilege={PRIVILEGES['WIKIS.WRITE']}
                        user={user}
                        title="Can Create / Update Wikis"
                        onPrivilegeChange={onPrivilegeChange}
                    />

                  

                </Col>
            </Row>

        </div>
    )

}


const mapStateToProps = state => {
	return {
	    PRIVILEGES: state.config.PRIVILEGES,
	};
};

export default connect(mapStateToProps, '')(UsersEditPrivilegesAdmin);  