import r from './request'

const api = {

    auth: {
        mfa: {
            send: (d)  => r('SERVER', 'get', `/v1/auth/mfa?_id=${d._id}&company=${d.company}&type=${d.type}`),   
            set: (d)   => r('SERVER', 'post', `/v1/auth/mfa/set`, d),   
        },
        password: {
            update: (d)  => r('SERVER', 'post', `/v1/auth/password/update`, d),
            reset:  (d)  => r('SERVER', 'post', `/v1/auth/password/reset`, d),
        },
        sso: {
            fetch:   ()   => r('SERVER', 'get', `/v1/auth/sso`),
            signOn:  (d)  => r('SERVER', 'post', `/v1/auth/sso`, d),
        },
        admin: {
            updatePassword:   (d)   => r('SERVER', 'post', `/v1/auth/password/update/admin`, d),
        },
        viewing_user: ()    => r('SERVER', 'get', `/v1/auth/viewing_user`),
    },

    admin: {
        associations : (t, i) => r('SERVER', 'get',  `/v1/admin/associations/${t}/${i}`),
    },
   
    analytic_reports: {
        findById: (m) =>  r('SERVER', 'get', `/v1/analytic_reports/${m}` ),
        find: (d, t) =>  r('SERVER', 'get', `/v1/analytic_reports/${d}/${t}` ),
        create: (d) =>  r('SERVER', 'post', `/v1/analytic_reports`, d ),
        update: (g, d) =>  r('SERVER', 'patch', `/v1/analytic_reports/${g}`, d ),
        delete: (g) =>  r('SERVER', 'delete', `/v1/analytic_reports/${g}`),
    },

    background_processes: {
        findById    : (i)     => r('SERVER', 'get',  `/v1/background_processes/${i}`),
        create    : (d)     => r('SERVER', 'post',  `/v1/background_processes`, d),
        update    : (i, d)     => r('SERVER', 'patch',  `/v1/background_processes/${i}`, d),
        delete    : (i)     => r('SERVER', 'delete',  `/v1/background_processes/${i}`),
        search    : (d)     => r('SERVER', 'post',  `/v1/background_processes/search`, d),
    },

    analyze: {
        fields: (d)    => r('SERVER', 'get', `/v1/analyze/fields/${d}`),
        matters: (d)    => r('SERVER', 'post', `/v1/analyze/matters`, d),
        contacts: (d)    => r('SERVER', 'post', `/v1/analyze/contacts`, d),
    },

    execution_logs: {
        search: (d)    => r('SERVER', 'post', `/v1/execution_logs/search`, d),
        findById: (i)    => r('SERVER', 'get', `/v1/execution_logs/${i}`,),
    },
    
    marketing: {
        overview: (d)    => r('SERVER', 'post', `/v1/marketing/overview`, d),
    },

    automations: {
        create    : (d)     => r('SERVER', 'post',  `/v1/automations`, d),
        search    : (d)     => r('SERVER', 'post',  `/v1/automations/search`, d),
        findById  : (i)     => r('SERVER', 'get',  `/v1/automations/${i}`),
        update    : (i, d)  => r('SERVER', 'patch', `/v1/automations/${i}`, d),
        delete    : (i)     => r('SERVER', 'delete', `/v1/automations/${i}`),
    },
    
    automation_tasks: {
        create    : (d)     => r('SERVER', 'post',  `/v1/automation_tasks`, d),
        find      : (i)     => r('SERVER', 'get',  `/v1/automation_tasks/${i}`),
        delete    : (i, d)  => r('SERVER', 'delete', `/v1/automation_tasks/${i}`, d),
        update    : (i, d)  => r('SERVER', 'patch', `/v1/automation_tasks/${i}`, d),
    },
    
    automation_assignments: {
        create    : (d)     => r('SERVER', 'post',  `/v1/automation_assignments`, d),
        search    : (d)     => r('SERVER', 'post',  `/v1/automation_assignments/search`, d),
        find      : (i)     => r('SERVER', 'get',  `/v1/automation_assignments/${i}`),
        delete    : (i, d)  => r('SERVER', 'delete', `/v1/automation_assignments/${i}`, d),
        update    : (i, d)  => r('SERVER', 'patch', `/v1/automation_assignments/${i}`, d),
    },

    call_blocked_numbers: {
        create   : (d)      =>  r('SERVER', 'post', `/v1/call_blocked_numbers`, d),
        search   : (d)      =>  r('SERVER', 'post', `/v1/call_blocked_numbers/search`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/call_blocked_numbers/${i}`),
    },

    call_phone_numbers: {
        findById : (i)      =>  r('SERVER', 'get',  `/v1/call_phone_numbers/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/call_phone_numbers`, d),
        search   : (d)      =>  r('SERVER', 'post', `/v1/call_phone_numbers/search`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/call_phone_numbers/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/call_phone_numbers/${i}`),
        sync     : (i)      =>  r('SERVER', 'post',  `/v1/call_phone_numbers/${i}/sync`),
    },

    call_flows: {
        findById : (i)      =>  r('SERVER', 'get',  `/v1/call_flows/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/call_flows`, d),
        search   : (d)      =>  r('SERVER', 'post', `/v1/call_flows/search`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/call_flows/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/call_flows/${i}`),
    },
    
    call_queues: {
        findById : (i)      =>  r('SERVER', 'get',  `/v1/call_queues/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/call_queues`, d),
        search   : (d)      =>  r('SERVER', 'post', `/v1/call_queues/search`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/call_queues/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/call_queues/${i}`),
    },
    
    companies: {
        findByName    : (name)  =>  r('SERVER', 'get', `/v1/companies/name/${name}`),
        findById      : (i)     =>  r('SERVER', 'get', `/v1/companies/${i}`),
        update        : (i, d)  =>  r('SERVER', 'patch', `/v1/companies/${i}`, d),
    },

    contact_types: {
        find     : ()       =>  r('SERVER', 'get',  `/v1/contact_types`),
        findById : (i)      =>  r('SERVER', 'get',  `/v1/contact_types/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/contact_types`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/contact_types/${i}`, d),
        search   : (d)   =>  r('SERVER', 'post',  `/v1/contact_types/search`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/contact_types/${i}`),
    },

    contacts: {
        search    : (d)         =>  r('SERVER', 'post',  `/v1/contacts/search`, d),
        find      : (query)     =>  r('SERVER', 'get',   `/v1/contacts${query}`),
        findById  : (i)         =>  r('SERVER', 'get',   `/v1/contacts/${i}`),
        overview  : (i)         =>  r('SERVER', 'get',   `/v1/contacts/${i}/overview`),
        create    : (d)         =>  r('SERVER', 'post',  `/v1/contacts`, d),
        update    : (i, d)      =>  r('SERVER', 'patch', `/v1/contacts/${i}`, d),
        delete    : (i)         =>  r('SERVER', 'delete', `/v1/contacts/${i}`,),   
    },

    company_documents: {
        search    : (d) =>  r('SERVER', 'post',  `/v1/company_documents/search`, d),
        byContact : (i) =>  r('SERVER', 'get',  `/v1/company_documents/contact/${i}`),
        findById  : (i) => r('SERVER', 'get', `/v1/company_documents/${i}`),
        delete    : (i) => r('SERVER', 'delete', `/v1/company_documents/${i}`),
    },

    custom_dates: {
        find : (w)       => r('SERVER', 'get',    `/v1/custom_dates?workflow=${w}`),
        findById : (i)  => r('SERVER', 'get',    `/v1/custom_dates/${i}`),
        create : (d)    => r('SERVER', 'post',   `/v1/custom_dates/`, d),
        update : (i, d) => r('SERVER', 'patch',  `/v1/custom_dates/${i}`, d),
        delete : (i)    => r('SERVER', 'delete', `/v1/custom_dates/${i}`),
        search   : (d)  =>  r('SERVER', 'post', `/v1/custom_dates/search`, d),
    },
    
    custom_fields: {
        find     : (d)      =>  r('SERVER', 'get',  `/v1/custom_fields/${d}/division`),
        findById : (i)      =>  r('SERVER', 'get',  `/v1/custom_fields/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/custom_fields`, d),
        search   : (d)      =>  r('SERVER', 'post', `/v1/custom_fields/search`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/custom_fields/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/custom_fields/${i}`),
    },

    custom_layouts: {
        findOne : (d, p)       =>  r('SERVER', 'get',  `/v1/custom_layouts/${d}/${p}`),
        save    : (d, p, data) =>  r('SERVER', 'post',  `/v1/custom_layouts/${d}/${p}`, data),
    },
  
    custom_views: {
        find     : (d)      =>  r('SERVER', 'get',  `/v1/custom_views`),
        findById : (i)      =>  r('SERVER', 'get',  `/v1/custom_views/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/custom_views`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/custom_views/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/custom_views/${i}`),
    },
    
    divisions: {
        find     : ()       =>  r('SERVER', 'get',  `/v1/divisions`),
        findById : (i)      =>  r('SERVER', 'get',  `/v1/divisions/${i}`),
        exportIDs : (i)      =>  r('SERVER', 'get',  `/v1/divisions/${i}/export_ids`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/divisions`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/divisions/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/divisions/${i}`),
        search   : (d)      =>  r('SERVER', 'post',  `/v1/divisions/search`, d),
    },
   
    division_departments: {
        findById : (i)      =>  r('SERVER', 'get',  `/v1/division_departments/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/division_departments`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/division_departments/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/division_departments/${i}`),
        search   : (d)      =>  r('SERVER', 'post',  `/v1/division_departments/search`, d),
        userAdd   : (i, d)  =>  r('SERVER', 'post',  `/v1/division_departments/${i}/user`, d),
        userRemove   : (i, d) =>  r('SERVER', 'delete',  `/v1/division_departments/${i}/user`, d),
    },

    documents: {
        search    : (d) =>  r('SERVER', 'post',  `/v1/documents/search`, d),
        byContact : (i) =>  r('SERVER', 'get',  `/v1/documents/contact/${i}`),
        findById  : (i) => r('SERVER', 'get', `/v1/documents/${i}`),
        delete    : (i) => r('SERVER', 'delete', `/v1/documents/${i}`),
    },
  

    email_senders: {
        find     : ()       =>  r('SERVER', 'get',  `/v1/email_senders`),
        findById : (i)      =>  r('SERVER', 'get',  `/v1/email_senders/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/email_senders`, d),
        search   : (d)      =>  r('SERVER', 'post', `/v1/email_senders/search`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/email_senders/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/email_senders/${i}`),                                                  
    },

    email_templates: {
        find     : ()       =>  r('SERVER', 'get',  `/v1/email_templates`),
        findById : (i)      =>  r('SERVER', 'get',  `/v1/email_templates/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/email_templates`, d),
        search   : (d)      =>  r('SERVER', 'post', `/v1/email_templates/search`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/email_templates/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/email_templates/${i}`),                                              
    },

    event_outcomes: {
        find     : (t)      =>  r('SERVER','get',  `/v1/event_outcomes/template/${t}`),
        findById : (i)      =>  r('SERVER','get',  `/v1/event_outcomes/${i}`),
        create   : (d)      =>  r('SERVER','post', `/v1/event_outcomes`, d),
        search   : (d)      =>  r('SERVER','post', `/v1/event_outcomes/search`, d),
        update   : (i, d)   =>  r('SERVER','patch',  `/v1/event_outcomes/${i}`, d),
        delete   : (i)      =>  r('SERVER','delete',  `/v1/event_outcomes/${i}`),                                       
    },

    event_templates: {
        find     : ()       =>  r('SERVER','get',  `/v1/event_templates`),
        findById : (i)      =>  r('SERVER','get',  `/v1/event_templates/${i}`),
        create   : (d)      =>  r('SERVER','post', `/v1/event_templates`, d),
        search   : (d)      =>  r('SERVER','post', `/v1/event_templates/search`, d),
        update   : (i, d)   =>  r('SERVER','patch',  `/v1/event_templates/${i}`, d),
        delete   : (i)      =>  r('SERVER','delete',  `/v1/event_templates/${i}`),                                       
    },

    event_types: {
        find     : ()       =>  r('SERVER', 'get',  `/v1/event_types`),
        findById : (i)      =>  r('SERVER', 'get',  `/v1/event_types/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/event_types`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/event_types/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/event_types/${i}`),
        search   : (d)      =>  r('SERVER','post', `/v1/event_types/search`, d),

    },


    emails: {
        send        : (d) => r('SERVER', 'post',   `/v1/emails/send`, d),
        getThread   : (i) => r('SERVER', 'get',   `/v1/emails/${i}/thread`),
    },
  
    form_answers: {
        // company_id, form_id, contact_id
        find    : (c, f, co)    => r('SERVER', 'get',   `/v1/form_answers/${c}/${f}/${co}`),
        save    : (d)           => r('SERVER', 'post',   `/v1/form_answers/save`, d),
        search  : (d)           => r('SERVER', 'post',   `/v1/form_answers/search`, d),
    },

    form_submissions: {
        findById        : (i)   => r('SERVER', 'get',   `/v1/api/form_submissions/${i}`),
        findByContact   : (i)   => r('SERVER', 'get',   `/v1/api/form_submissions/contact/${i}`),
        search          : (d)   => r('SERVER', 'post',   `/v1/api/form_submissions/search`, d),
    },
    
    forms: {
        find                    : ()        => r('SERVER', 'get',   `/v1/forms`),
        create                  : (d)       => r('SERVER', 'post',   `/v1/forms`, d),
        update                  : (i, d)    => r('SERVER', 'patch',  `/v1/forms/${i}`, d),
        delete                  : (i)       => r('SERVER', 'delete', `/v1/forms/${i}`),
        search                  : (d)       => r('SERVER', 'post',   `/v1/forms/search`, d),
        findById                : (i)       => r('SERVER', 'get',    `/v1/forms/${i}`),
        viewDocument            : (Key)     => r('SERVER', 'get',    `/v1/forms/documents/key?Key=${Key}`),
        getConfig               : ()        => r('SERVER', 'get',    `/v1/forms/configuration`),
        // id, contact_id
        request                 : (i, co)   => r('SERVER', 'post',    `/v1/forms/${i}/request/${co}`),
    },
   
    leads: {
        findById : (i)      =>  r('SERVER', 'get',  `/v1/leads/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/leads`, d),
        search   : (d)      =>  r('SERVER', 'post', `/v1/leads/search`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/leads/${i}`, d),
    },

    lead_sources: {
        analyze : (i, s, e, d)      =>  r('SERVER', 'get',  `/v1/lead_sources/${i}/analyze?start=${s}&end=${e}&division=${d}`),
        findById : (i)      =>  r('SERVER', 'get',  `/v1/lead_sources/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/lead_sources`, d),
        search   : (d)      =>  r('SERVER', 'post', `/v1/lead_sources/search`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/lead_sources/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/lead_sources/${i}`),
        rollKeys : (i)      =>  r('SERVER', 'patch',  `/v1/lead_sources/${i}/keys`),
    },
  
    lead_source_categories: {
        find     : (d)      =>  r('SERVER', 'get',  `/v1/lead_source_categories?division=${d}`),
        findById : (i)      =>  r('SERVER', 'get',  `/v1/lead_source_categories/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/lead_source_categories`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/lead_source_categories/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/lead_source_categories/${i}`),
        search   : (d)      =>  r('SERVER', 'post',  `/v1/lead_source_categories/search`, d),

    },
    
    load: {
        standard    : ()    => r('SERVER', 'get',   `/v1/load/standard`),
        config      : ()    => r('SERVER', 'get',   `/v1/load/config`),
    },

    locations: {
        find     : ()       =>  r('SERVER', 'get',  `/v1/locations`),
        findById : (i)      =>  r('SERVER', 'get',  `/v1/locations/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/locations`, d),
        search   : (d)      =>  r('SERVER', 'post', `/v1/locations/search`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/locations/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/locations/${i}`),
    },
  
    location_types: {
        find     : ()       =>  r('SERVER', 'get',  `/v1/location_types`),
        findById : (i)      =>  r('SERVER', 'get',  `/v1/location_types/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/location_types`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/location_types/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/location_types/${i}`),
        search   : (d)      =>  r('SERVER', 'post',  `/v1/location_types/search`, d),

    },

    matter_documents: {
        find     : (d)       =>  r('SERVER', 'get',  `/v1/matter_documents?division=${d}`),
        findById : (i)      =>  r('SERVER', 'get',  `/v1/matter_documents/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/matter_documents`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/matter_documents/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/matter_documents/${i}`),
        search   : (d)      => r('SERVER', 'post',  `/v1/matter_documents/search`, d),
    },

    matters: {
        search    : (d)     =>  r('SERVER', 'post',  `/v1/matters/search`, d),
        findById  : (i)     =>  r('SERVER', 'get',   `/v1/matters/${i}`),
        create    : (d)     =>  r('SERVER', 'post',  `/v1/matters`, d),
        update    : (i, d)  =>  r('SERVER', 'patch', `/v1/matters/${i}`, d),
        delete    : (i)     =>  r('SERVER', 'delete', `/v1/matters/${i}`,),
    },
    
    notifications: {
        findById : (i) => r('SERVER', 'get', `/v1/monitoring/notifications/${i}`),
        seen     : (i) => r('SERVER', 'patch', `/v1/monitoring/notifications/${i}`),
        search   : (d)  => r('SERVER', 'post',  `/v1/monitoring/notifications/search`, d),
    },
    
    notes: {
        find     : (q)    => r('SERVER', 'get',   `/v1/notes${q}`),
        create   : (d)    => r('SERVER', 'post',   `/v1/notes`, d),
        update   : (i, d) => r('SERVER', 'post',   `/v1/notes/${i}`, d),
        delete   : (i)    => r('SERVER', 'post',   `/v1/notes/${i}`),
    },
    
    page_histories: {
        find    : ()  => r('SERVER', 'get', `/v1/page_histories/me`),
        create  : (d) => r('SERVER', 'post', `/v1/page_histories`, { pathname: d }),
    },

    pipelines: {
        find          : (q)    => r('SERVER', 'get',   `/v1/pipelines?division=${q}`),
        create        : (d)    => r('SERVER', 'post',   `/v1/pipelines`, d),
        update        : (i, d) => r('SERVER', 'patch',   `/v1/pipelines/${i}`, d),
        findById    : (i) => r('SERVER', 'get',   `/v1/pipelines/${i}`),
        findByUser    : (i, d) => r('SERVER', 'get',   `/v1/pipelines/${i}/user`, d),
        delete        : (i)    => r('SERVER', 'post',   `/v1/pipelines/${i}`),
    },
    

    query: {
        findById    : (c, i, p) => r('SERVER', 'get',  `/v1/query/${c}/${i}${p ? '?populate=' + p : ''}`),
        batch       : (d)       => r('SERVER', 'post',  `/v1/query/batch`, d),
    },

    signing_documents: {
        create : (d)    => r('SERVER', 'post',  `/v1/signing/documents`, d),
        search : (d)    => r('SERVER', 'post',  `/v1/signing/documents/search`, d),
        view   : (i)    => r('SERVER', 'get',  `/v1/signing/documents/${i}/view`),
        void   : (i, d) => r('SERVER', 'patch',  `/v1/signing/documents/${i}`, d),
    },

    signing_templates: {
        search    : (d)     => r('SERVER', 'post',  `/v1/signing/templates/search`, d),
        findById  : (i)     => r('SERVER', 'get',   `/v1/signing/templates/${i}`),
        delete    : (i)     => r('SERVER', 'delete',   `/v1/signing/templates/${i}`),
        update    : (i, d)  => r('SERVER', 'patch', `/v1/signing/templates/${i}`, d),
        getConfig : ()      => r('SERVER', 'get', `/v1/signing/templates/configuration`),
    },
  
    stored_keys: {
        save    : (d)     => r('SERVER', 'post',  `/v1/stored_keys`, d),
        find    : ()      => r('SERVER', 'get',  `/v1/stored_keys`),
    },

    string_templates: {
        find      : (d)     => r('SERVER', 'get',  `/v1/string_templates`, d),
        findById  : (i)     => r('SERVER', 'get',   `/v1/string_templates/${i}`),
        delete    : (i)     => r('SERVER', 'delete',   `/v1/string_templates/${i}`),
        update    : (i, d)  => r('SERVER', 'patch', `/v1/string_templates/${i}`, d),
        create    : (d)     => r('SERVER', 'post', `/v1/string_templates/`, d),
    },

    tags: {
        find     : ()       =>  r('SERVER', 'get',  `/v1/tags`),
        findById : (i)      =>  r('SERVER', 'get',  `/v1/tags/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/tags`, d),
        search   : (d)      =>  r('SERVER', 'post', `/v1/tags/search`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/tags/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/tags/${i}`),
    },

    text_templates: {
        find     : ()       =>  r('SERVER', 'get',  `/v1/text_templates`),
        findById : (i)      =>  r('SERVER', 'get',  `/v1/text_templates/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/text_templates`, d),
        search   : (d)      =>  r('SERVER', 'post', `/v1/text_templates/search`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/text_templates/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/text_templates/${i}`),
    },
    
    task_templates: {
        findById : (i)      =>  r('SERVER', 'get',  `/v1/task_templates/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/task_templates`, d),
        search   : (d)      =>  r('SERVER', 'post', `/v1/task_templates/search`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/task_templates/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/task_templates/${i}`),
    },

    time_entry_templates: {
        findById : (i)      =>  r('SERVER', 'get',  `/v1/time_entry_templates/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/time_entry_templates`, d),
        search   : (d)      =>  r('SERVER', 'post', `/v1/time_entry_templates/search`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/time_entry_templates/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/time_entry_templates/${i}`),
    },

    users: {
        search              : (d)         => r('SERVER', 'post',    `/v1/users/search`, d),
        find                : (query)     => r('SERVER', 'get',    `/v1/users${query}`),
        findOne             : (string)    => r('SERVER', 'get',    `/v1/users/find/${string}`),
        findById            : (i)         => r('SERVER', 'get',    `/v1/users/${i}`),
        create              : (d)         => r('SERVER', 'post',   `/v1/users/`, d),
        update              : (i, d)      => r('SERVER', 'patch',  `/v1/users/${i}`, d),
        divisionOverride    : (i, d)      => r('SERVER', 'patch',  `/v1/users/${i}/division_override`, d),
        delete              : (i)         => r('SERVER', 'delete', `/v1/users/${i}`),
    },

    wikis: {
        findById : (i)      =>  r('SERVER', 'get',  `/v1/wikis/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/wikis`, d),
        search   : (d)      =>  r('SERVER', 'post', `/v1/wikis/search`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/wikis/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/wikis/${i}`),
    
    },

    word_documents: {
        search : (d)    => r('SERVER', 'post',   `/v1/word_documents/search`, d),
        download : (i)  => r('SERVER', 'get',    `/v1/word_documents/${i}/download`),
        create : (d)    => r('SERVER', 'post',   `/v1/word_documents/`, d),
        update : (i, d) => r('SERVER', 'patch',  `/v1/word_documents/${i}`, d),
        delete : (i)    => r('SERVER', 'delete', `/v1/word_documents/${i}`),
    },
   
    workflows: {
        find : ()       => r('SERVER', 'get',    `/v1/workflows`),
        findById : (i)  => r('SERVER', 'get',    `/v1/workflows/${i}`),
        create : (d)    => r('SERVER', 'post',   `/v1/workflows/`, d),
        search : (d)    => r('SERVER', 'post',   `/v1/workflows/search`, d),
        update : (i, d) => r('SERVER', 'patch',  `/v1/workflows/${i}`, d),
        delete : (i)    => r('SERVER', 'delete', `/v1/workflows/${i}`),
    },
   
    workflow_roles: {
        find : (w)      => r('SERVER', 'get',    `/v1/workflow_roles?workflow=${w}`),
        findById : (i)  => r('SERVER', 'get',    `/v1/workflow_roles/${i}`),
        create : (d)    => r('SERVER', 'post',   `/v1/workflow_roles/`, d),
        update : (i, d) => r('SERVER', 'patch',  `/v1/workflow_roles/${i}`, d),
        delete : (i)    => r('SERVER', 'delete', `/v1/workflow_roles/${i}`),
    },
      
    workflow_folders: {
        find : (w)      => r('SERVER', 'get',    `/v1/workflow_folders?workflow=${w}`),
        findById : (i)  => r('SERVER', 'get',    `/v1/workflow_folders/${i}`),
        create : (d)    => r('SERVER', 'post',   `/v1/workflow_folders/`, d),
        update : (i, d) => r('SERVER', 'patch',  `/v1/workflow_folders/${i}`, d),
        delete : (i)    => r('SERVER', 'delete', `/v1/workflow_folders/${i}`),
    },
   
    workflow_contacts: {
        find : (w)       => r('SERVER', 'get',    `/v1/workflow_contacts?workflow=${w}`),
        findById : (i)  => r('SERVER', 'get',    `/v1/workflow_contacts/${i}`),
        create : (d)    => r('SERVER', 'post',   `/v1/workflow_contacts/`, d),
        update : (i, d) => r('SERVER', 'patch',  `/v1/workflow_contacts/${i}`, d),
        delete : (i)    => r('SERVER', 'delete', `/v1/workflow_contacts/${i}`),
    },
    
    workflow_links: {
        find : (w)      => r('SERVER', 'get',    `/v1/workflow_links?workflow=${w}`),
        findById : (i)  => r('SERVER', 'get',    `/v1/workflow_links/${i}`),
        create : (d)    => r('SERVER', 'post',   `/v1/workflow_links/`, d),
        update : (i, d) => r('SERVER', 'patch',  `/v1/workflow_links/${i}`, d),
        delete : (i)    => r('SERVER', 'delete', `/v1/workflow_links/${i}`),
    },

    workflow_locations: {
        find : (w)      => r('SERVER', 'get',    `/v1/workflow_locations`),
        findById : (i)  => r('SERVER', 'get',    `/v1/workflow_locations/${i}`),
        create : (d)    => r('SERVER', 'post',   `/v1/workflow_locations/`, d),
        update : (i, d) => r('SERVER', 'patch',  `/v1/workflow_locations/${i}`, d),
        delete : (i)    => r('SERVER', 'delete', `/v1/workflow_locations/${i}`),
    },
    
    workflow_step_categories: {
        find : (w)      => r('SERVER', 'get',    `/v1/workflow_step_categories?workflow=${w}`),
        findById : (i)  => r('SERVER', 'get',    `/v1/workflow_step_categories/${i}`),
        create : (d)    => r('SERVER', 'post',   `/v1/workflow_step_categories/`, d),
        update : (i, d) => r('SERVER', 'patch',  `/v1/workflow_step_categories/${i}`, d),
        delete : (i)    => r('SERVER', 'delete', `/v1/workflow_step_categories/${i}`),
        search   : (d)      =>  r('SERVER', 'post', `/v1/workflow_step_categories/search`, d),

    },

    workflow_dispositions: {
        find : (w)      => r('SERVER', 'get',    `/v1/workflow_dispositions?workflow=${w}`),
        findById : (i)  => r('SERVER', 'get',    `/v1/workflow_dispositions/${i}`),
        create : (d)    => r('SERVER', 'post',   `/v1/workflow_dispositions/`, d),
        update : (i, d) => r('SERVER', 'patch',  `/v1/workflow_dispositions/${i}`, d),
        delete : (i)    => r('SERVER', 'delete', `/v1/workflow_dispositions/${i}`),
        search   : (d)      =>  r('SERVER', 'post', `/v1/workflow_dispositions/search`, d),

    },

    workflow_steps: {
        search : (d)    => r('SERVER', 'post',   `/v1/workflow_steps/search`, d),
        find : (w)      => r('SERVER', 'get',    `/v1/workflow_steps?workflow=${w}`),
        findById : (i)  => r('SERVER', 'get',    `/v1/workflow_steps/${i}`),
        create : (d)    => r('SERVER', 'post',   `/v1/workflow_steps/`, d),
        update : (i, d) => r('SERVER', 'patch',  `/v1/workflow_steps/${i}`, d),
        delete : (i)    => r('SERVER', 'delete', `/v1/workflow_steps/${i}`),
    },
      
}

export default api;