import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Input } from 'reactstrap';

import api from 'api';
import { toast } from 'react-toastify';

import ReactSelect from 'components/functional/inputs/ReactSelect';
import CopyText from 'components/functional/text/Copy';

import ConfirmationModal from 'components/functional/modals/Confirmation';


const WorkflowEditContactsRole = ({contact, contactTypes, onDelete}) => {

    const [showDelete, setShowDelete] = useState(false)
    const [name, setName] = useState(contact.name)
    const [mainContact, setMainContact] = useState(contact.main_contact)
    const [contactType, setContactType] = useState(contact.contact_type)

    const onSave = useCallback(async (field, value) => {
        const data = await api.workflow_contacts.update(contact._id, { [field]: value })
        if(!data.success) toast.error(`Could not save workflow contact, please try again`)
    }, [contact._id])
    
    return (
        <React.Fragment key={contact._id}>
            <tr >
                <td>
                    <Input 
                        value={name}
                        onChange={e => setName(e.target.value)}
                        onBlur={() => onSave('name', name)}
                    />
                </td>
                <td>
                    <ReactSelect
                        formGroup={false}
                        placeholder=""
                        onChange={(obj) => {
                            setMainContact(obj.value)
                            onSave('main_contact', obj.value)
                        }}
                        value={mainContact}
                        options={[
                            { value: false, label: <span className="-danger">No</span > },
                            { value: true, label: <span className="-success">Yes</span > },
                        ]}

                    />  
                </td>
                <td>
                    <ReactSelect 
                        formGroup={true}
                        value={contactType}
                        onChange={(obj) => {
                            setContactType(obj.value)
                            onSave('contact_type', obj.value)
                        }}
                        options={contactTypes ? contactTypes.map(l => {
                            return { label: l.name, value: l._id }
                        }) : []}
                    />
                </td>
                <td><CopyText text={contact._id} /></td>

                <td className="text-right">
                    <button className="btn btn-danger" onClick={() => setShowDelete(true)}>
                        <i className="fas fa-trash mr-2 " /> Remove Contact
                    </button>
                </td>
            </tr>

            <ConfirmationModal 
                showModal={showDelete}
                toggleModal={() => setShowDelete(false)}
                title={"Delete Workflow Contact"}
                onConfirmation={() => onDelete(contact)}
                body={(
                    <span>
                        Are you sure you wish to delete the workflow contact <b className='text-underline text-dark'>"{contact.name}"</b>? This cannot be undone and any email emails, text, etc. that use their field map ID will return empty values potentially creating breaking changes.
                    </span>
                )}
            />
  

        </React.Fragment>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(WorkflowEditContactsRole);