import { useCallback, useState } from "react";
import { connect } from 'react-redux';
import { Card } from "reactstrap";

import api from 'api';

import ConfirmationModal from 'components/functional/modals/Confirmation';
import ModalToggler from 'components/functional/modals/Toggler';
import Table from 'components/functional/tables/Standard';
import TableIcons from 'components/markup/icons/TableIcons';
import ApiError from "components/markup/layout/ApiError";
import TextButtonRow from 'components/markup/layout/TextButtonRow';
import Circle from 'components/markup/loading/Circle';

import { toggleAlertBS } from "store/functions/system/system";
import CallPhoneNumbersEdit from '../components/Edit';

import formatText from 'utils/formatText';

import ObjectFinder from 'components/system/Objects/Finder';

import Edit from '../components/Edit'

const CallPhoneNumbersAll = ({setTab, selected_division}) => {

    const [ loading, setLoading ] = useState(false);
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ numberToDelete, setCallPhoneNumberToDelete ] = useState(false)
    const [ error, setError ] = useState(false);


    // helper for this.downloadCSV and this.query
    const fetchData = useCallback((params, isCSV, shouldReload) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(shouldReload) setLoading(true)
        const query = await api.call_blocked_numbers.search({ ...params, filter: { division: selected_division._id }, isCSV })

        if(shouldReload) setLoading(false)

        if(!query.data) return setError(query.message)
        return resolve(query.data)
    }), [sizePerPage, selected_division._id])

    // run query to get data from the server
    const query = useCallback(async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }, [fetchData])

    const onDeleteCallPhoneNumber = useCallback(async (location) => {
        const deleted = await api.call_blocked_numbers.delete(numberToDelete._id);

        if(deleted.success) {
            fetchData(null, false, true);
            toggleAlertBS(false, 'Blocked Number Successfully Removed')
        } else {
            toggleAlertBS(true, `Something's not right, please try again.`)
        }
    }, [fetchData, numberToDelete])
 
    const columns = [
        {
            dataField: "number",
            text: 'Number',
            formatter: (cell, row) => {
                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )
                return formatText(row.number).phone()
            }
                

        },
      
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right',},
            text: 'Actions',
            headerFormatter: () => {
                return <div id="archk-tutorial-contacts-actions">Actions</div>
            },
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                         <TableIcons
                            icons={[
                                {  
                                    icon: 'fas fa-trash', 
                                    color: 'danger',
                                    onClick: () => setCallPhoneNumberToDelete(row)
                                },
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(error) return <ApiError err={error} />
    if(loading) return <div><Circle /></div>

    return (

        <>

            <TextButtonRow 
                title={"Blocked Numbers"}
                button={(
                    <ModalToggler component={Edit} onSaved={() => fetchData(null, false, true)}>
                        <button className="btn btn-success">
                            <i className="fas fa-plus mr-2 " /> Add Number
                        </button>
                    </ModalToggler> 
                )}
            />

            <Card className="card-color card-primary table-fixed">
                <Table
                    tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                    placeholder="Search..."
                    query={query}
                    columns={columns}
                    data={tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}
                    sizePerPage={sizePerPage}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>

            <ConfirmationModal 
                showModal={numberToDelete ? true : false}
                toggleModal={() => setCallPhoneNumberToDelete(null)}
                title="Delete Blocked Number"
                body={(
                    <span>
                        Are you sure you wish to delete the blocked number {numberToDelete ? formatText(numberToDelete.number).phone() : ''}? This will immediately allow them to call in again.
                    </span>
                )}
                onConfirmation={onDeleteCallPhoneNumber}
            />

        </>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(CallPhoneNumbersAll);