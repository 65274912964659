import Onboarding from '../pages/Onboarding';

const routes = [

    {
        path: "/start",
        component: Onboarding,
    },

]

export default routes;