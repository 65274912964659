import EventOutcomesEdit from './components/Edit'
import EventOutcomesAll from './All'

import IntuideskSectionWrapper from 'components/markup/layout/IntuideskSectionWrapper'

const SystemEventOutcomes = ({match, section}) => (
    <IntuideskSectionWrapper 
        title="Event Outcomes"
        editTitle="Event Outcome"
        match={match}
        section={section}
        innerSection={'event_outcomes'}
        Edit={EventOutcomesEdit}
        All={EventOutcomesAll}
    />
)

export default SystemEventOutcomes;