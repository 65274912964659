import { useCallback, useEffect, useState } from "react";
import { Card, CardHeader, Input } from "reactstrap";
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux';

import moment from "moment";

import api from 'api';

import TextButtonRow from 'components/markup/layout/TextButtonRow'
import ConfirmationModal from 'components/functional/modals/Confirmation'
import TableIcons from 'components/markup/icons/TableIcons'
import ModalToggler from 'components/functional/modals/Toggler'
import LoadingTable from 'components/markup/loading/Table';
import ApiError from "components/markup/layout/ApiError";

import { toast } from 'react-toastify'

import CustomViewsEdit from '../components/Edit'

const SystemCustomViewAll = ({setTab, selected_division}) => {
    const [ tableData, setTableData ] = useState(null)
    const [ viewToDelete, setViewToDelete ] = useState(false)
    const [ error, setError ] = useState(false);
    const [ search, setSearch ] = useState('');
    const [ redirect, setRedirect ] = useState(null);

    // helper for this.downloadCSV and this.query
    const fetchData = useCallback((params, isCSV) => new Promise (async resolve => {
        const query = await api.custom_views.find()
        
        if(query.data) {
            return setTableData(query.data)
        } else {
            setError(query.message)
        }
    }), [])

    const onDeleteView = useCallback(async () => {
        const deleted = await api.custom_views.delete(viewToDelete._id);
        if(!deleted.success) return toast.error('Something went wrong deleting this view.')
          fetchData();
      
    }, [fetchData, viewToDelete._id])

    useEffect(() => {
        fetchData()
    }, [fetchData])
 
    if(error) return <ApiError err={error} />

    return (

        <>

            <TextButtonRow 
                title={"All Custom Views"}
                button={(
                    <button onClick={() => setTab('create')} className="btn btn-success"><i className="fas fa-plus mr-2 " /> Add Custom View</button>
                )}
            />

            <Card className="card-color card-primary table-fixed">
                <CardHeader className="pl-2 pr-4 pb pt-3 pb-3 ml-1">
                    <Input 
                        className="ml-1"
                        style={{width: 190, maxWidth: '100'}}
                        placeholder="Search..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </CardHeader>
                {!tableData ?  <LoadingTable /> : (
                    <div className="table-responsive ">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Custom View</th>
                                    <th className="text-right">User</th>
                                    <th className="text-right">Admin</th>
                                    <th className="text-right">Links</th>
                                    <th className="text-right"></th>
                                </tr>
                            </thead>

                            <tbody>
                                {tableData.length ? (
                                    tableData.map(obj => {

                                        let links = 0;

                                        if(obj.link_1) links++
                                        if(obj.link_2) links++
                                        if(obj.link_3) links++
                                        if(obj.link_4) links++

                                        return (
                                            <tr key={obj._id}>
                                                <td>
                                                    <ModalToggler component={CustomViewsEdit} onSaved={fetchData} _id={obj._id}>
                                                        <span className="cursor-pointer">{obj.name}</span>
                                                    </ModalToggler>
                                                </td>
    
                                                <td className="text-right">
                                                    {obj.user ? <i className="fas fa-check text-success" /> : <i className="fas fa-times text-danger" />}
                                                </td>
    
                                                <td className="text-right">
                                                    {obj.admin ? <i className="fas fa-check text-success" /> : <i className="fas fa-times text-danger" />}
                                                </td>
                                                <td className="text-right">
                                                    {links}
                                                </td>
    
                                                <td className="text-right">
                                                    <TableIcons
                                                        icons={[
                                                            
                                                            { 
                                                                icon: 'fas fa-edit',
                                                                color: 'success', 
                                                                wrapper: Link, 
    
                                                                _id: obj._id,
                                                                to: `/company/custom_views/${obj._id}`
                                                            },
                                                            {  
                                                                icon: 'fas fa-trash', 
                                                                color: 'danger',
                                                                onClick: () => setViewToDelete(obj)
                                                            },
                                                        ]}
                                                    />
    
                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr>
                                        <td>No Views Created Yet</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}

                            </tbody>
                        </table>
                    </div>
                )}

            </Card>

            <ConfirmationModal 
                showModal={viewToDelete ? true : false}
                toggleModal={() => setViewToDelete(null)}
                title="Delete Custom View"
                body={(
                    <span>
                        Are you sure you wish to delete the custom view {viewToDelete ? viewToDelete.name : ''}? This action cannot be undone.
                    </span>
                )}
                onConfirmation={onDeleteView}
            />

        </>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SystemCustomViewAll);