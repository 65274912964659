import EventTypesEdit from './components/Edit'
import EventTypesAll from './All'

import IntuideskSectionWrapper from 'components/markup/layout/IntuideskSectionWrapper'

const SystemEventTypes = ({match, section}) => (
    <IntuideskSectionWrapper 
        title="Event Types"
        editTitle="Event Type"
        match={match}
        section={section}
        innerSection={'event_types'}
        Edit={EventTypesEdit}
        All={EventTypesAll}
    />
)

export default SystemEventTypes;