/*
Documentation

*** make sure to declare all action types in actions.js one folder level back

*/

import * as actionTypes from '../../actions';

const initialState = {
    text_templates: [
       { _id: 123, name: 'text_template test 1' },
       { _id: 456, name: 'text_template test 2' },
    ],
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.SET_TEXT_TEMPLATES:

            return {
                ...state,
                text_templates: action.payload,
            }

        default: return state;

    }

}

export default reducer;
