// tasks: {
//     type: [{
//         task_template           : { type: Schema.Types.ObjectId, ref: 'task_templates', required: true },
//         assigned_roles          : [{ type: Schema.Types.ObjectId, ref: 'workflow_roles', default: [] }],
//         complete_before_move    : { type: Boolean, required: true, default: false },
            // due,
//         create_tasks_on_finish: {
//             type: [{
//                 task_template   : { type: Schema.Types.ObjectId, ref: 'task_templates', required: true },
//                 assigned_roles  : { type: Array, default: [], required: true, },
//                 due             : { type: Number, default: 0, required: true, },
//             }],
//             required: true,
//             default: []
//         },
//     }],
//     default : []
// },


import { useCallback } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Col, FormGroup, Row, Badge } from 'reactstrap';

import ReactSelect from 'components/functional/inputs/ReactSelect';
import SearchCollections from 'components/system/Search/Collections';

import TasksOnCompletion from './TasksOnCompletion';

import { v4 } from 'uuid';

import A from 'components/markup/links/A'

const dueDates = [
    { value: '0', label: 'Never' },
    { value: 'immediately', label: 'Immediately' },
    { value: '1st_of_the_month', label: '1st Of The Month' },
    { value: '15th_of_the_month', label: '15th Of The Month' },
    { value: '15m', label: 'In 15 Minutes' },
    { value: '30m', label: 'In 30 Minutes' },
    { value: '45m', label: 'In 45 Minutes' },
    { value: '60m', label: 'In 1 Hour' },
    { value: '90m', label: 'In 1.5 Hours' },
    { value: '120m', label: 'In 2 Hours' },
    { value: '180m', label: 'In 3 Hours' },
    { value: '240m', label: 'In 4 Hours' },
    { value: '300m', label: 'In 5 Hours' },
    { value: '360m', label: 'In 6 Hours' },
    { value: '420m', label: 'In 7 Hours' },
    { value: '480m', label: 'In 8 Hours' },
    { value: '540m', label: 'In 9 Hours' },
    { value: '600m', label: 'In 10 Hours' },
    { value: '660m', label: 'In 11 Hours' },
    { value: '720m', label: 'In 12 Hours' },
    { value: '780m', label: 'In 13 Hours' },
    { value: '840m', label: 'In 14 Hours' },
    { value: '900m', label: 'In 15 Hours' },
    { value: '960m', label: 'In 16 Hours' },
    { value: '1020m', label: 'In 17 Hours' },
    { value: '1080m', label: 'In 18 Hours' },
    { value: '1140m', label: 'In 19 Hours' },
    { value: '1200m', label: 'In 20 Hours' },
    { value: '1260m', label: 'In 21 Hours' },
    { value: '1320m', label: 'In 22 Hours' },
    { value: '1380m', label: 'In 23 Hours' },
    { value: '1', label: 'In 1 day' },
    { value: '2', label: 'In 2 days' },
    { value: '3', label: 'In 3 days' },
    { value: '4', label: 'In 4 days' },
    { value: '5', label: 'In 5 days' },
    { value: '6', label: 'In 6 days' },
    { value: '7', label: 'In 7 days' },
    { value: '10', label: 'In 10 days' },
    { value: '14', label: 'In 14 days' },
    { value: '14', label: 'In 21 days' },
    { value: '28', label: 'In 28 days' },
    { value: '30', label: 'In 30 days' },
    { value: '45', label: 'In 45 days' },
    { value: '60', label: 'In 60 days' },
    { value: '75', label: 'In 75 days' },
    { value: '90', label: 'In 90 days' },
    { value: '120', label: 'In 120 days' },
    { value: '150', label: 'In 150 days' },
    { value: '180', label: 'In 180 days' },
    { value: '210', label: 'In 210 days' },
    { value: '240', label: 'In 240 days' },
    { value: '270', label: 'In 270 days' },
    { value: '300', label: 'In 300 days' },
    { value: '365', label: 'In 365 days' },
]

const WorkflowSidebarMatterDocuments = ({stepToEdit, setStepToEdit, workflowCopy, roles, contacts, errs}) => {

    const onAddTask = useCallback(() => {
        const _stepToEdit = JSON.parse(JSON.stringify(stepToEdit));
        const docs = _stepToEdit.tasks;
        docs.unshift({
            id                      : v4(),
            task_template           : '' ,
            due                     : 0,
            complete_before_move    : true ,
            assigned_roles          : [] ,
            assigned_contacts       : [] ,
            create_tasks_on_finish  : [] ,
            required                : false
        })
        _stepToEdit.tasks = docs;
        setStepToEdit(_stepToEdit);
    }, [stepToEdit, setStepToEdit])

    const onRemoveTask = useCallback((id) => {
        const _stepToEdit = JSON.parse(JSON.stringify(stepToEdit));
        let docs = stepToEdit.tasks;

        _stepToEdit.tasks = docs.filter(t => {
            const _id = t._id ? t._id : t.id
            return _id !== id
        })
        setStepToEdit(_stepToEdit);
    }, [stepToEdit, setStepToEdit])

    const onAddRole = useCallback((id, value) => {
        const _stepToEdit = JSON.parse(JSON.stringify(stepToEdit));
        const step = _stepToEdit.tasks.find(s => s._id === id || s.id === id)
        if(!step) return;
        if(!step.assigned_roles.includes(value)) step.assigned_roles.push(value)
        setStepToEdit(_stepToEdit);
    }, [stepToEdit, setStepToEdit])
    
    const onRemoveRole = useCallback((id, value) => {
        const _stepToEdit = JSON.parse(JSON.stringify(stepToEdit));
        const step = _stepToEdit.tasks.find(s => s._id === id || s.id === id)
        if(!step) return;
        step.assigned_roles = step.assigned_roles.filter(r => r !== value)
        setStepToEdit(_stepToEdit);
    }, [stepToEdit, setStepToEdit])
  
    const onAddContact = useCallback((id, value) => {
        const _stepToEdit = JSON.parse(JSON.stringify(stepToEdit));
        const step = _stepToEdit.tasks.find(s => s._id === id || s.id === id)
        if(!step) return;
        if(!step.assigned_contacts.includes(value)) step.assigned_contacts.push(value)
        // step.assigned_contacts = [value]
        setStepToEdit(_stepToEdit);
    }, [stepToEdit, setStepToEdit])
    
    const onRemoveContact = useCallback((id, value) => {
        const _stepToEdit = JSON.parse(JSON.stringify(stepToEdit));
        const step = _stepToEdit.tasks.find(s => s._id === id || s.id === id)
        if(!step) return;
        step.assigned_contacts = step.assigned_contacts.filter(r => r !== value)
        setStepToEdit(_stepToEdit);
    }, [stepToEdit, setStepToEdit])
    
    const onChange = useCallback((id, field, value, type) => {
        const _stepToEdit = JSON.parse(JSON.stringify(stepToEdit));
        const step = _stepToEdit.tasks.find(s => s._id === id || s.id === id)
        if(!step) return;
        step[field] = value;
        if(type) step.type = type
        setStepToEdit(_stepToEdit);
    }, [stepToEdit, setStepToEdit])

    const renderRoleName = useCallback((_id) => {
        const role = roles.find(r => r._id === _id)  
        return role ? role.name : ''
    }, [roles])
    const renderContactName = useCallback((_id) => {
        const contact = contacts.find(r => r._id === _id)  
        return contact ? contact.name : ''
    }, [contacts])
    
    return (
        <>
            <Row className='mb-3'>
                <Col xs={6} className="align-self-center">
                    <h2 className='text-warning'>STEP TASKS</h2>
                </Col>
                <Col xs={6} className="align-self-center text-right">
                    <button className='btn btn-sm btn-info' onClick={onAddTask}><i className="fas fa-plus mr-2" /> Add New</button>
                </Col>
            </Row>

            {stepToEdit.tasks.length ? stepToEdit.tasks.map((doc, i) => {
                const id = doc.id ? doc.id : doc._id ;
                return (
                    <Card key={id} className="z-depth-0 ">
                        <CardBody className='label-block'>
                            
                            <FormGroup className='mb-0'>
                                <h3 className='form-control-labl'>
                                    <Row>
                                        <Col xs={6} className="align-self-center">
                                        Task #{i + 1}
                                        </Col>
                                        <Col xs={6} className="align-self-center text-right">
                                            <i onClick={() => onRemoveTask(id)} className="fas fa-trash mr-2 text-danger cursor-pointer pl-3" />
                                        </Col>
                                    </Row>
                                </h3>
                            </FormGroup>

                            <SearchCollections
                                collection="task_templates" 
                                title={(
                                    <div>
                                        Select Task 
                                        {doc.task_template ? (
                                            <A href={`/editor/workflows/${workflowCopy._id}?tab=Tasks&task=${doc.task_template}`}><i className="fas fa-link pl-3 cursor-pointer text-info" /></A>
                                        ) : ''}
                                    </div>
                                )}
                                value={doc.task_template}
                                includeFields={['type']}
                                onChange={(obj) => onChange(id, 'task_template', obj.value, obj.obj.type)}
                                filter={{
                                    workflow: workflowCopy._id
                                }}
                            /> 

                            {doc.type === 'send_email' || doc.type === 'send_text' ? (
                                <div className='bg-secondary ml-5 p-3 border my-3'>
                                    <ReactSelect 
                                        formGroup={true}
                                        title={doc.type === 'send_email' ? "Email Recipient" : "Text Recipient"}
                                        value={""}
                                        onChange={(obj) => onAddContact(id, obj.value)}
                                        options={contacts.map((role, i) => {
                                            return { value: role._id, label: role.name }
                                        })}
                                    />

                                    {doc.assigned_contacts.length ? doc.assigned_contacts.map((contact) => (
                                        <Badge key={contact} color="success" className="ml-0 mb-2 text-left mr-3">
                                            {renderContactName(contact)}
                                            <span className="pr-2 pl-3 cursor-pointer float-right" onClick={() => onRemoveContact(id, contact)}>
                                                <i className="fas fa-times " />
                                            </span>
                                        </Badge>
                                    )) : null}
                                </div>
                            ): ''}
                           
                            <ReactSelect 
                                formGroup={true}
                                title="Complete On Move"
                                value={doc.complete_before_move}
                                onChange={(obj) => onChange(id, 'complete_before_move', obj.value)}
                                options={[
                                    { value: false, label: 'No' },
                                    { value: true, label: 'Yes' }
                                ]}
                            />

                            {!doc.complete_before_move ? (
                                <div>
                                    <ReactSelect 
                                        formGroup={true}
                                        title="Assigned To Role(s)"
                                        value={""}
                                        onChange={(obj) => onAddRole(id, obj.value)}
                                        options={roles.map((role, i) => {
                                            return { value: role._id, label: role.name }
                                        })}
                                    />

                                    {doc.assigned_roles.length ? doc.assigned_roles.map((role) => (
                                        <Badge key={role} color="success" className="ml-0 mb-2 text-left mr-3">
                                            {renderRoleName(role)}
                                            <span className="pr-2 pl-3 cursor-pointer float-right" onClick={() => onRemoveRole(id, role)}>
                                                <i className="fas fa-times " />
                                            </span>
                                        </Badge>
                                    )) : null}

                                    <div className='bg-secondary ml-5 p-3 border my-3'>
                                        <ReactSelect 
                                            formGroup={true}
                                            title="Due"
                                            value={doc.due}
                                            onChange={(obj) => onChange(id, 'due', obj.value)}
                                            options={dueDates}
                                        />
                                        <ReactSelect 
                                            formGroup={true}
                                            title="Required"
                                            value={doc.required}
                                            onChange={(obj) => onChange(id, 'required', obj.value)}
                                            options={[
                                                { value: true, label: "Yes" },
                                                { value: false, label: "No" },
                                            ]}
                                        />
                                    </div>

                                    <TasksOnCompletion 
                                        stepToEdit={stepToEdit} 
                                        setStepToEdit={setStepToEdit}
                                        workflowCopy={workflowCopy} 
                                        roles={roles}
                                        task={doc}
                                        contacts={contacts}
                                    />
                            
                                </div>
                            ) : ''}
                        
                        </CardBody>
                    </Card>
            )}) : (
                <p className='text-sm mb-0'><i className="fas fa-info-circle text-info" /> No contactss Have Been Added</p>
            )}

            {errs.includes('task') ? (
                <div className='alert alert-warning'>
                    <i className="fas fa-info-circle" /> Each task requires an associated task template to be selected.
                </div>
            ) : ''}
          
        </>
    )
}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(WorkflowSidebarMatterDocuments);