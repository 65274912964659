import { connect } from 'react-redux';

import WorkflowContactSelection from 'components/system/WorkflowContactSelection';
import SearchCollections from "components/system/Search/Collections";

const ActionSendText = ({ node, onInputChange, selected_division }) => {

    return (
        <div>
    
            <div className='sidebar-section border-bottom'>
                <SearchCollections
                    collection={'text_templates'}
                    title="**Select The Text Message To Send"
                    placeholder="Search..."
                    value={node.data.text_template}
                    onChange={(obj) => onInputChange('text_template', obj.value)}
                    filter={{
                        division: selected_division._id,
                    }}
                /> 
            </div>

            <div className='sidebar-section border-bottom'>
                <h2>To Contacts</h2>
                <WorkflowContactSelection 
                    contacts={node.data.workflow_contacts} 
                    onChange={(workflow_contacts) => onInputChange('workflow_contacts', workflow_contacts)} 
                />
            </div>


            <div className='sidebar-section border-bottom'>
                <p className='text-sm'>
                    <i className="fas fa-info-circle text-info" /> This action sends the selected text message when it runs. 
                </p>
                <p className='text-sm mb-0'>
                    Note that if multiple contacts are selected 1 text will go out to each of them
                </p>
            </div>
    
        </div>
    )
}



const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(ActionSendText);