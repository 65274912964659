import { connect } from 'react-redux';
import { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from "reactstrap";

import api from 'api';

import logo from 'assets/img/brand/Favicon.png'

import Circle from 'components/markup/loading/Circle'

import SetEmail from './SetEmail'
import Documents from './Documents'
import ReplyTo from './ReplyTo'

const Onboarding = ({viewing_user}) => {

    const [view, setView] = useState('SetEmail');
    const [company, setCompany] = useState(null);

    const fetchCompany = useCallback(async () => {
        const _company = await api.companies.findById(viewing_user.company)
        if(_company.data) setCompany(_company.data)
    }, [viewing_user.company])

    const onSetView = useCallback((_view) => {
        setView(_view)
        fetchCompany();
    }, [fetchCompany])

    useState(() => {
        fetchCompany()
    }, [fetchCompany])


    return (
        <Container fluid>

            <Helmet>
                <title>{`Onboarding`}</title>
                <meta name="description" content="Onboarding" />
            </Helmet>

            <div className="header  pt-5 pb-6 pb-md-8 py-lg-8 pt-lg-9">
                <Container className="pb-4">
                    <div className="header-body text-center mb-7">
                        <Row className="justify-content-center">
                            <Col className="px-5" lg="6" md="8" xl="5">
                                <img style={{width: 200}} src={logo} alt="IntuiDesk"/>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            {!company ? (
                <Circle />
            ) : view === 'SetEmail' ? (
                <SetEmail 
                    company={company}
                    fetchCompany={fetchCompany}
                    onSetView={onSetView}
                />
            ) : view === 'Documents' ? (
                <Documents 
                    company={company}
                    fetchCompany={fetchCompany}
                    onSetView={onSetView}
                />
            ) : view === 'ReplyTo' ? (
                <ReplyTo 
                    company={company}
                    fetchCompany={fetchCompany}
                    onSetView={onSetView}
                />
            ) : null}

            
           
        </Container>
    )

}


const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(Onboarding);
