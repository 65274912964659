import { Col, Row } from 'reactstrap';

import A from 'components/markup/links/A';
import formatText from 'utils/formatText';

const SendDocumentTab1 = ({contacts}) => (
    <>
    <div className="modal-body border-bottom">
        <p className="text-sm text-center mb-0"><i className="fas fa-users text-info mr-2 " /> This document will be sent to the following parties</p>
    </div>
    <div className="modal-body">
        <Row>
            <Col md={3}>
                <h4><i className="fas fa-user-circle text-dark " />  Party 1</h4>
                {contacts[0] ? (
                    <div>
                        <p className="text-sm mb-1 text-uppercase">
                            <A href={`/contacts/${contacts[0]._id}`}>
                                {contacts[0].display_name ? contacts[0].display_name : 'Name Not Found'}
                            </A>
                        </p>
                        <p className="text-sm mb--1">{contacts[0].email ? formatText(contacts[0].email).trimEmail(20) : null}</p>
                        <p className="text-sm mb-0">{contacts[0].phone ? formatText(contacts[0].phone).phone() : null}</p>
                    </div>
                ) : (
                    <p className="text-sm text-muted">Not Assigned</p>
                )}
            </Col>
            <Col md={3}>
                <h4><i className="fas fa-user-circle text-dark " /> Party 2</h4>
                {contacts[1] ? (
                    <div>
                        <p className="text-sm mb-1 text-uppercase">
                            <A href={`/contacts/${contacts[1]._id}`}>
                                {contacts[1].display_name ? contacts[1].display_name : 'Name Not Found'}
                            </A>
                        </p>
                        <p className="text-sm mb--1">{contacts[1].email ? formatText(contacts[1].email).trimEmail(20) : null}</p>
                        <p className="text-sm mb-0">{contacts[1].phone ? formatText(contacts[1].phone).phone() : null}</p>
                    </div>
                ) : (
                    <p className="text-sm text-muted">Not Assigned</p>
                )}
            </Col>
            <Col md={3}>
                <h4><i className="fas fa-user-circle text-dark " /> Party 3</h4>
                {contacts[2] ? (
                    <div>
                        <p className="text-sm mb-1 text-uppercase">
                            <A href={`/contacts/${contacts[2]._id}`}>
                                {contacts[2].display_name ? contacts[2].display_name : 'Name Not Found'}
                            </A>
                        </p>
                        <p className="text-sm mb--1">{contacts[2].email ? formatText(contacts[2].email).trimEmail(20) : null}</p>
                        <p className="text-sm mb-0">{contacts[2].phone ? formatText(contacts[2].phone).phone() : null}</p>
                    </div>
                ) : (
                    <p className="text-sm text-muted">Not Assigned</p>
                )}
            </Col>
            <Col md={3}>
                <h4><i className="fas fa-user-circle text-dark " /> Party 4</h4>
                {contacts[3] ? (
                    <div>
                        <p className="text-sm mb-1 text-uppercase">
                            <A href={`/contacts/${contacts[3]._id}`}>
                                {contacts[3].display_name ? contacts[3].display_name : 'Name Not Found'}
                            </A>
                        </p>
                        <p className="text-sm mb--1">{contacts[3].email ? formatText(contacts[3].email).trimEmail(20) : null}</p>
                        <p className="text-sm mb-0">{contacts[3].phone ? formatText(contacts[3].phone).phone() : null}</p>
                    </div>
                ) : (
                    <p className="text-sm text-muted">Not Assigned</p>
                )}
            </Col>
        </Row>
    </div>
    </>
)


export default SendDocumentTab1;
