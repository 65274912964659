import { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { Row, Col, Card, CardHeader, CardBody, Container, CardFooter, Badge } from 'reactstrap';
import HeaderNavigation from 'components/markup/layout/HeaderNavigation'

import api from 'api'

import A from 'components/markup/links/A'

import Circle from 'components/markup/loading/Circle'
import ObjectFinder from 'components/system/Objects/Finder';

import formatText from 'utils/formatText'

import EditTask from './EditTask'
import DeleteTask from './DeleteTask'
import Update from './Update'

const AutomationsEdit = ({match, selected_division}) => {

    const [automation_id] = useState(match.params.automation_id)
    const [automation, setAutomation] = useState(null)
    const [tasks, setTasks] = useState(null)
    const [movesTo, setMovesTo] = useState(undefined)
    const [movesFrom, setMovesFrom] = useState(undefined)
    const [showEdit, setShowEdit] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalUpdate, setShowModalUpdate] = useState(false);
    const [taskToDelete, setTaskToDelete] = useState(false);
    const [workflows, setWorkflows] = useState([]);
    const [workflowRoles, setWorkflowRoles] = useState([]);
    const [workflowContacts, setWorkflowContacts] = useState([]);
    const [toEdit, setToEdit] = useState(null);

    const onSetShowEdit = (_showEdit, task) => {
        setShowEdit(_showEdit)
        setToEdit(task)
    }

    const getName = useCallback((obj) => {
        switch (obj.type) {
            case 'start': return <ObjectFinder collection="automations" _id={obj.automation_to_start} />
            case 'end': return <ObjectFinder collection="automations" _id={obj.automation_to_end} />
            case 'email': return <ObjectFinder collection="email_templates" _id={obj.email_template} />
            case 'text': return <ObjectFinder collection="text_templates" _id={obj.text_template} />
            default: return ''
        }
    }, [])

    const renderTasks = useCallback((_tasks) => {

        const markup = [];
        const task = _tasks.find(t => !t.moves_to);
        if(!task && _tasks.length) return alert('An internal error occurred contact support. Code FL1')
        if(!task) return (
            <div className="py-1 text-center">
                <i 
                    onClick={() => {
                        setMovesFrom(undefined)
                        setMovesTo(undefined)
                        onSetShowEdit(true)
                    }}
                    className="cursor-pointer fas fa-plus-circle text-success display-2" 
                />
            </div>
        )
        let counter = _tasks.length + 1
        let firstTask = null


        const pushToMarkup = (task => {
            const lastTask = tasks.find(t => t.moves_to === task._id)
            counter--;
            markup.unshift((
                <div key={task._id}>
                    <Card className='mb-2'>
                        <CardHeader className="text-capitalize">
                            <Row>
                                <Col xs={9} className="col-auto">
                                    {task.type === 'email' ? (
                                        <i className="fas fa-envelope text-success " />
                                    ) : task.type === 'text' ? (
                                        <i className="fas fa-mobile text-info " />
                                    ) : task.type === 'start' ? (
                                        <i className="fas fa-check text-success " />
                                    ) : task.type === 'end' ? (
                                        <i className="fas fa-times text-danger " />
                                    ) : task.type === 'wait' ? (
                                        <i className="fas fa-clock text-purple " />
                                    ) : ''}
                                    <span className="ml-3">
                                        {
                                            task.type === 'start' ? 'Start An Automation' :    
                                            task.type === 'end' ? 'End An Automation' :
                                            task.type === 'text' ? 'Send A Text Message' :
                                            task.type === 'email' ? 'Send An Email' :
                                            task.type === 'wait' ? 'Delay Next Task' :
                                            ''
                                        }
                                    </span>
                                </Col>
                                <Col className='text-right'>
                                    #{counter}
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            {task.type === 'email' ? (
                                <div>
                                  
                                    <div>
                                        <A href={`/automations/email_templates/${task.id}`}>
                                            Email:{' '}
                                            <span className="font-weight-bold text-dark text-underline"> {getName(task)}</span>
                                        </A>
                                    </div>
                                    Default Sender:{' '}
                                    <b className="font-weight-bold text-dark">
                                        <ObjectFinder 
                                            collection="email_senders" 
                                            _id={task.email_sender} 
                                            errMessage="Default Sender" 
                                        />
                                    </b>
                                    <div>
                                        Workflow Roles:{' '}
                                        {task.workflow_roles.map((r, i) => {

                                            return (
                                                <span>
                                                    {i === 0 ? '' : ', '}
                                                    {!r.workflow_role ? <span key={i}>Default Sender</span> : 
                                                    <b key={r._id} className="font-weight-bold text-dark">
                                                        <ObjectFinder 
                                                            collection="workflow_roles" 
                                                            _id={r.workflow_role} 
                                                            errMessage="None Found" 
                                                        />
                                                    </b>}
                                                </span>
                                            )
                                        })}
                                    </div>
                                </div>
                            ) : task.type === 'text' ? (
                                <div>
                                    Send Text: {' '}

                                    <A href={`/automations/text_templates/${task.id}`}>
                                        <span className="font-weight-bold text-dark text-underline">{getName(task)}</span>
                                    </A>
                                </div>
                            ) : task.type === 'start' ? (
                                <div>
                                    Start Automation:{' '}
                                    <A href={`/editor/automations/${task.id}`}>
                                        <span className="font-weight-bold text-dark text-underline">{getName(task)}</span>
                                    </A>
                                </div>
                            ) : task.type === 'end' ? (
                                <div>
                                    End Automation:{' '}
                                    <A href={`/editor/automations/${task.id}`}>
                                        <span className="font-weight-bold text-dark text-underline">{getName(task)}</span>
                                    </A>
                                </div>
                            ) : task.type === 'wait' ? (
                                <div>Wait {formatText(task.delay).secondsToTime()}</div>
                            ) : ''}
                        </CardBody>

                        <CardFooter className='text-right'>
                            <button className="btn btn-danger btn-sm" onClick={() => {
                                setTaskToDelete(task)
                                setShowModalDelete(true)
                            }}>
                                <i className="fas fa-trash mr-2 " />
                                Delete
                            </button>
                            <button className="btn btn-success btn-sm ml-0" onClick={() => {
                                onSetShowEdit(true, task)
                            }}>
                                <i className="fas fa-edit mr-2 " /> Edit
                            </button>
                        </CardFooter>

                        {task.id === automation_id ? (
                            <CardFooter className="bg-danger border py-3 px-4 text-white">
                                {task.type === 'start' ? (
                                    'This tasks will not do anything as it is starting the current automation.'
                                ) : (
                                    'This tasks will end the current automation, not tasks after this one will run.'
                                )}
                            </CardFooter>
                        ) : ''}

                    </Card>

                    <div className="py-1 text-center">
                        <i 
                            onClick={() => {
                                setMovesFrom(task._id)
                                setMovesTo(task.moves_to ? task.moves_to : undefined)
                                onSetShowEdit(true)
                            }}
                            className="cursor-pointer fas fa-plus-circle text-success display-2" 
                        />
                    </div>
                </div>
            ))

            if(lastTask) {
                pushToMarkup(lastTask)
            } else {
                firstTask = task;
            }
        })

        pushToMarkup(task)

        if(firstTask) {
            markup.unshift((
                <div key={1} className="py-1 text-center">
                    <i 
                        onClick={() => {
                            setMovesFrom(undefined)
                            setMovesTo(firstTask._id)
                            onSetShowEdit(true)
                        }}
                        className="cursor-pointer fas fa-plus-circle text-success display-2" 
                    />
                </div>
            ))
        }

        return markup

    }, [getName, tasks, automation_id])
    
    const fetchAutomations = useCallback(async () => {
        if(automation_id === 'new') return setAutomation({})
        const data = await api.automations.findById(automation_id);
        if(data.data) {
            setAutomation(data.data)
        } else {
            setAutomation('not found')
        }
    }, [automation_id])

    const fetchTasks = useCallback(async () => {
        if(automation_id === 'new') return setTasks([])
        const data = await api.automation_tasks.find(automation_id);
        if(data.data) {
            setTasks(data.data)
        } else {
            setAutomation('not found')
        }
    }, [automation_id])
    
    const fetchWorkflows = useCallback(async () => {
        const query = await api.workflows.find()
        if(query.data) {
            const _workflows = query.data.filter(workflow => workflow.division === selected_division._id)
            setWorkflows(_workflows)
        }
    }, [selected_division._id,])

    useEffect(() => {
        fetchAutomations();
        fetchTasks();
        fetchWorkflows();
    }, [fetchAutomations, fetchTasks, fetchWorkflows])

    if(!tasks || !automation) return <div className="py-6"><Circle /></div>
    if(automation === 'not found') return <Redirect to="/automations/automations/all" />

    return (
        <div>

            <div className="archk-workflows-header mb--4">
                <HeaderNavigation 
                    title={(
                        <span>
                            <i className="fas fa-edit mr-0 text-info " />{' '}
                            Editing Workflow{' '}
                            {automation.active ? (
                                <Badge onClick={() => setShowModalUpdate(true)} style={btnStyle} className=" text-sm px-4 cursor-pointer" color="success">Active</Badge>
                            ) : (
                                <Badge onClick={() => setShowModalUpdate(true)} style={btnStyle} className=" text-sm px-4 cursor-pointer" color="danger">Inactive</Badge>
                            )}
                        </span>
                        )}
                    description={`${automation.name ? automation.name.length > 30 ? automation.name.slice(0, 30) + '...' : automation.name : 'Creating New'} - 
                    ${tasks.length} Tasks - ${tasks.filter(t => t.type === 'email').length} Emails - ${tasks.filter(t => t.type === 'texts').length} Text Messages`}
                    actionComponent={(
                        <div>
                            <Link to="/automations" className="btn btn-outline-warning">
                                <i className="fas fa-arrow-left mr-2" /> Back
                            </Link>
                            <button className="btn btn-info" onClick={() => setShowModalUpdate(true)}>
                                <i className="fas fa-cogs mr-2" /> Settings
                            </button>
                        </div>
                    )}
                />
            </div>

            <Container fluid>
                <Row>
                    <div className='col-auto bg-secondary pr-5' style={{width: 350, height: 'calc(100vh - 70px)'}}>
                        <p className='text-center mt-3 mb-0 pt-2 font-weight-bold'>Begin Automation Flow </p>
                        
                        {renderTasks(tasks)}
                    </div>
                    <Col >
                        <div className='p-3'>
                            {showEdit ? (
                                <EditTask 
                                    fetchTasks={fetchTasks}
                                    automation_id={automation_id}
                                    movesFrom={movesFrom}
                                    movesTo={movesTo}
                                    automations={[]}
                                    workflows={workflows}
                                    workflowRoles={workflowRoles}
                                    workflowContacts={workflowContacts}
                                    onSetShowEdit={onSetShowEdit}
                                    toEdit={toEdit}
                                    tasks={tasks}
                                />
                            ) : (
                                <h2 className='text-center mt-3'><i className="fas fa-info-circle text-info "></i> No Task Selected</h2>
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>

            <DeleteTask 
                showModal={showModalDelete}
                toggleModal={() => setShowModalDelete(false)}
                taskToDelete={taskToDelete}
                tasks={tasks}
                getName={getName}
                fetchTasks={() => {
                    setMovesFrom(undefined)
                    setMovesTo(undefined)
                    onSetShowEdit(false)
                    fetchTasks()
                }}
            />
            <Update 
                showModal={showModalUpdate}
                toggleModal={() => setShowModalUpdate(false)}
                automation={automation}
                setAutomation={setAutomation}
            />

        </div>
    )

}

const btnStyle = {display: 'inline-block', position: 'relative', top: -3}

const mapStateToProps = state => {
    return {
        selected_division: state.state.selected_division,
    };
};
export default connect(mapStateToProps, '')(AutomationsEdit);;