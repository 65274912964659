import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { FormGroup, Input } from 'reactstrap';

import SearchCollections from "components/system/Search/Collections";

const SidebarRightIfStatement = ({ selectedNode, onNodeUpdated, selected_division }) => {

    const [node, setNode] = useState(selectedNode)
    const tools = node.data.tools || []

    const onToolChange = useCallback((toolName) => {
        const n = JSON.parse(JSON.stringify(node));
        if(!n.data.tools) n.data.tools = []

        if(n.data.tools.includes(toolName)) {
            n.data.tools = n.data.tools.filter(t => t !== toolName)
        } else {
            n.data.tools.push(toolName)
        }

        setNode(n)
    }, [node])

    const onInputChange = useCallback((field, value) => {
        const n = JSON.parse(JSON.stringify(node));
        n.data[field] = value;
        setNode(n)
    }, [node])

    const createCheckBox = (name, toolName) => (
        <FormGroup>
            <div className="custom-control custom-checkbox">
                <input
                    className="custom-control-input"
                    id={`archk-tool-${toolName}`}
                    type="checkbox"
                    checked={tools.includes(toolName)}
                    onChange={(e) => onToolChange(toolName)}
                />
                <label className="custom-control-label" htmlFor={`archk-tool-${toolName}`}>
                    {name} ("{toolName}")
                </label>
            </div>
        </FormGroup>
    )

    useEffect(() => {
        setNode(JSON.parse(JSON.stringify(selectedNode)))
    }, [selectedNode])
   
    return (
        <div>

            <div className='sidebar-section border-bottom'>
                <div className="sidebar-item pb-0"><h2>If Statement</h2></div>
            </div>

            <div className='sidebar-section border-bottom'>
                <FormGroup>
                    <label className='form-control-label'>Logic Name</label>
                    <p className='text-sm mb-0'>Internal use only, this will show as a way to see is being checked within this background process. Example: Client Has Outstanding Items?.</p>
                    
                    <Input 
                        type="text"
                        value={node.data.name}
                        onChange={(e) => onInputChange('name', e.target.value)}
                    />
                </FormGroup>
            </div>

            <div className='sidebar-section border-bottom'>
                <SearchCollections
                    collection={'ai_assistants'}
                    title="**Select Assistant To Check The Statement Against"
                    placeholder="Search..."
                    value={node.data.ai_assistant}
                    onChange={(obj) => onInputChange('ai_assistant', obj.value)}
                    filter={{
                        division: selected_division._id,
                    }}
                /> 
            </div>
            
            <div className='sidebar-section border-bottom'>
                <FormGroup>
                    <div className="custom-control custom-checkbox">
                        <input
                            className="custom-control-input"
                            id={`archk-tool-inject-trigger-object`}
                            type="checkbox"
                            checked={node.data.inject_trigger_object}
                            onChange={(obj) => onInputChange('inject_trigger_object', !node.data.inject_trigger_object)}
                            />
                        <label className="custom-control-label" htmlFor={`archk-tool-inject-trigger-object`}>
                            Inject Trigger Object
                        </label>
                    </div>
                    <p className='text-sm mb-0 mt-3'><i className="fas fa-info-circle text-info mr-2" /> If you opt to Inject the trigger object the data associated with it will be passed when running the assistant. For instance if you say "Was this call handled properly" you should inject the trigger object to pass the call when running this assistant.</p>
                </FormGroup>
            </div>


            <div className='sidebar-section border-bottom'>
                <FormGroup>
                    <label className='form-control-label'>Unique Instructions</label>
                    <p className='text-sm mb-0'>If entered this will be told to the assistant before it runs. If set,  the prompt should be unique to this process's step creating an unique response outside of the assistants training.</p>

                    <Input 
                        type="textarea"
                        style={{minHeight: 100}}
                        value={node.data.unique_instructions}
                        onChange={(e) => onInputChange('unique_instructions', e.target.value)}
                    />
                </FormGroup>
            </div>

            {/* <div className='sidebar-section border-bottom'>

                <h2>Assistant Tool Access</h2>

                {createCheckBox('Create Outstanding Item', 'create_outstanding_item')}
                {createCheckBox('Send Text', 'send_text_message')}
                {createCheckBox('Send Email', 'send_email')}
                {createCheckBox('Fetch Calls', 'fetch_calls')}
                {createCheckBox('Fetch Events', 'fetch_events')}
                {createCheckBox('Fetch Matters', 'fetch_matter')}
                {createCheckBox('Fetch Matter Parties', 'fetch_contacts_and_clients')}
                {createCheckBox('Fetch Matter Roles', 'fetch_users_and_roles')}
                {createCheckBox('Fetch Outstanding Items', 'fetch_outstanding_items')}
                {createCheckBox('Fetch Payments', 'fetch_payments')}
                {createCheckBox('Fetch Tasks', 'fetch_tasks')}
                {createCheckBox('Extract Information', 'extract_information')}
               
            </div> */}

            <div className='sidebar-section border-bottom'>
                <p className='text-sm'>
                    <i className="fas fa-info-circle text-info" /> Assistant Tools open up access to create things or get information directly inside of intuidesk. If your assistant is ever stuck or running the wrong action add something similar to the following sentence in the assistants training to correct any misbehavior: 
                </p>
                <p className='text-sm mb-0 text-dark font-weight-bold text-uppercase'>Example Tool Instruction:</p>
                <p className='text-sm mb-0'>
                    "Use the tool 'fetch_users_and_roles' to get the sending user and the tool 'send_email' to successfully deliver the email to the client."
                </p>
            </div>

            <div className='sidebar-section text-right'>
                <button onClick={() => onNodeUpdated(node)} className='btn btn-success'>Save Node</button>
            </div>

        </div>

    )

}



const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SidebarRightIfStatement);