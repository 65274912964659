import { connect } from 'react-redux';

import WorkflowRolesSelection from 'components/system/WorkflowRolesSelection';

import ReactSelect from 'components/functional/inputs/ReactSelect';
import SearchCollections from "components/system/Search/Collections";

import timeValues from '../../_helpers/timeValues'

const ActionCreateTask = ({ node, onInputChange, selected_division }) => {

    return (
        <div>
    
            <div className='sidebar-section border-bottom'>

                <SearchCollections
                    collection={'task_templates'}
                    title="**Select The Task To Create"
                    placeholder="Search..."
                    value={node.data.task_template}
                    onChange={(obj) => onInputChange('task_template', obj.value)}
                    filter={{
                        division: selected_division._id,
                    }}
                /> 

            </div>
    
            <div className='sidebar-section border-bottom'>
                <ReactSelect
                    title={"Due Date Date"}
                    description="The amount of time from when this item is created until when the task is due."
                    formGroup={true}
                    placeholder=""
                    onChange={(obj) => onInputChange('due_date', obj.value)}
                    options={timeValues}
                    value={node.data.due_date}
                />    
            </div>
            
            <div className='sidebar-section border-bottom'>
                <h2>Assigned Users</h2>
                <WorkflowRolesSelection 
                    roles={node.data.assigned_to} 
                    onChange={(assigned_to) => onInputChange('assigned_to', assigned_to)} 
                    multiSelect={true}
                />
            </div>

            <div className='sidebar-section border-bottom'>
                <ReactSelect
                    title={"Required"}
                    formGroup={true}
                    placeholder=""
                    onChange={(obj) => onInputChange('required', obj.value)}
                    options={[
                        { label: 'No', value: false },
                        { label: 'Yes', value: true },
                    ]}
                    value={node.data.required}
                />    
            </div>
            


            <div className='sidebar-section border-bottom'>
                <p className='text-sm'>
                    <i className="fas fa-info-circle text-info" /> This action creates a task to be completed on the matter. 
                </p>
                <p className='text-sm mb-0'>
                    To create a task based with a due date found within a document, email, text message, etc. you will need to run an assistant to do so instead of creating one here through an action.
                </p>
            </div>
    
        </div>
    )
}



const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(ActionCreateTask);