
import { Row, Col } from 'reactstrap'

const NotFound = () => (
    <div className="bg-white border py-3 px-5 rounded archk-api-error">
        <Row>
            <div className="col-auto align-self-center" style={{width: 75}}>
                <h2 className="display-2 mb-0">
                    <i className={`fas fa-info-circle mr-2 text-info`} />
                </h2>
            </div>
            <Col className="align-self-center">
                <p className="mb-0">
                    <span>The page or resource you are looking for cannot be found.</span>
                </p>
            </Col>
        </Row>
    </div>
)

export default NotFound