import { useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, CardHeader, CardTitle, Col, Container, Row } from "reactstrap";

import A from 'components/markup/links/A';
import Circle from 'components/markup/loading/Circle';

import api from 'api';

const LocationsAll = ({ selected_division, match }) => {

    const [reports, setReports] = useState(null)

    const fetchReports = useCallback(async () => {
        const result = await api.analytic_reports.find(selected_division._id, match.params.type)
        if(!result) return toast.error(`Could not load reports, please try again`)

        setReports(result.data)

    }, [match.params.type, selected_division._id])

    useEffect(() => {
        fetchReports()
    }, [fetchReports])

    if(!reports) return <Circle />

    return (
        <Container className="pt-4">
            <Card>
                <CardHeader>
                    <Row>
                        <Col md={6} className="align-self-center">
                            <CardTitle className="mb-0 text-capitalize">Custom Reports - {match.params.type}</CardTitle>
                        </Col>
                        <Col md={6} className="align-self-center text-right">
                            <Link className="btn btn-sm btn-outline-warning" to="/analyze"><i className="fas fa-arrow-left mr-2" /> Back</Link>
                            <A className="btn btn-sm btn-success" href={`/analytics/${match.params.type}`}><i className="fas fa-plus mr-2" /> Create New Report</A>
                        </Col>
                    </Row>
                </CardHeader>

                <div className="table-responsive border">
                    <table className="table">

                        <thead>
                            <tr>
                                <th>Report</th>
                                <th>Time Group</th>
                                <th>Sort Type</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            {reports.length ? reports.map(r => (
                                <tr key={r._id}>
                                    <td>
                                        <A href={`/analytics/${match.params.type}?report=${r._id}`}>{r.name ? r.name : 'unknown report'}</A>
                                    </td>
                                    <td className="text-capitalize">
                                        {r.group}
                                    </td>
                                    <td className="text-capitalize">
                                        {r.sort && r.sort.type ? r.sort.type.replace(/_/g, ' ') : '-'}
                                    </td>
                                    <td className="text-right">
                                        <A  className="btn btn-sm btn-outline-info" href={`/analytics/${match.params.type}?report=${r._id}`}><i className="fas fa-link mr-2" /> Open</A>
                                    </td>
                                </tr>
                            )) : (
                                <tr>
                                    <td>No Reports Found</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

            </Card> 
        </Container>
    )
}



const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(LocationsAll);