import Agreement from '../pages/Agreement';
import Banned from '../pages/Banned';

const routes = [

    {
        path: "/agreement",
        component: Agreement,
    },
    {
        path: "/banned",
        component: Banned,
    },

]

export default routes;