import { Modal } from 'reactstrap';


const ModalErrors = ({showModal, toggleModal, errs}) => (
    <Modal
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={toggleModal}
        size="md"
    >
        <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Send Document</h5>
            <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggleModal}
            >
                <span aria-hidden={true}>×</span>
            </button>
        </div>

        <div className="modal-body">
            <p className="text-sm">Please correct the following error(s) before saving this template:</p>
            <ol className='pl-4'>
                {errs.map((err, i) => (
                    <li key={i} className="text-sm mb-2">{err}</li>
                ))}
            </ol>
        </div>

        <div className="modal-footer">
            <button onClick={toggleModal} className="btn btn-outline-warning">
                <i className="fas fa-arrow-left mr-2" /> Back
            </button>
        </div>

    </Modal>
)

export default ModalErrors
