import { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Input } from 'reactstrap';

import hasPrivilege from 'utils/hasPrivilege'
// hasPrivilege('VIEW.ADMIN_DASHBOARD.STAFFING') || hasPrivilege('VIEW.ADMIN_DASHBOARD.COMPANY') || hasPrivilege('VIEW.ADMIN_DASHBOARD.SYSTEM') 

const Right = () =>  <i className="fas fa-chevron-right text-muted mr-2 ml-2" /> 
const Icon = ({i}) => <i className={`${i} mr-2 ml-2 mr-2`} />

const returnResults = ((opts, val, onSetShowResults) => {
    const results = opts.filter(o => o.val.includes(val));
    if(!results.length) return null

    return results.map(o => (
        <Link to={o.link} key={o.val}>
            <li 
                onClick={() => {
                    onSetShowResults(false)
                    // history.push(option.link);
                }}
                className={`display-block w-100 ${o.indent ? 'pl-7' : ''} ${o.endSection ? 'mb-4' : ''}`} 
                key={o.val}
            >
                <div className="pl-5">
                    <div className="pl-2">
                    {o.name}
                    </div>
                </div>
            </li>
        </Link>
    ))
})

const mapOptions = (_options, val, onSetShowResults) => {
    const markup = _options.map((option, i) => {
        const results = returnResults(option.options, val, onSetShowResults);
        if(!results) return ''
        return (
            <div className={`pt-3 px-3 pb-0`} key={option.val}>
                <ul className="bg-secondary border">
                    <h4 className="text-uppercase mb-0">{option.name}</h4>
                    {results}
                </ul>
            </div>
        )
    })
    if(markup.some(a => a)) return markup;
    return (
        <div className={`pt-3 px-3`}>
            <ul className="bg-secondary border">
                <h4 className="text-uppercase mb-0">No Results Found</h4>
                <li className="py-3"><i className="fas fa-search mr-2 " /> Try a different search above.</li>
            </ul>
        </div>
    )
    
}

const NavbarSearch = () => {
    const [options, setOptions] = useState([])
    const [val, setVal] = useState('')
    const [showResults, setShowResults] = useState(false);

    const onSetShowResults = useCallback((bool) => {
        setShowResults(bool)
        if(bool) {
            document.body.classList.add('noScroll');
        } else {
            document.body.classList.remove('noScroll');
        }
    }, [])

    const onSetVal = useCallback((e) => {
        const _val = e && e.target ? e.target.value : e;
        setVal(_val ? _val.toLowerCase() : _val)

        if(_val) onSetShowResults(true);
    }, [onSetShowResults])

    useEffect(() => {
        setOptions([
            { 
                wrapper: true,
                val: 'system', 
                name: <span><Icon i={'fas fa-laptop'} />System</span>, 
                link: '/system' ,
                options: [
                    ...(hasPrivilege('VIEW.ADMIN_DASHBOARD.SYSTEM') ? [
                        { 
                            val: 'system workflows create', 
                            name: <span><Icon i={'fas fa-route'} /><Right /><span className="nav-section">Workflows</span><Right />Create</span>, 
                            link: '/system/workflows/create' ,
                        },
                        { 
                            val: 'system workflows all', 
                            name: <span><Icon i={'fas fa-route'} /><Right /><span className="nav-section">Workflows</span><Right />View</span>, 
                            link: '/system/workflows/all' ,
                            endSection: true
                        },
                        
                        { 
                            val: 'system contact_types create contacts create', 
                            name: <span><Icon i={'fas fa-users'} /><Right /><span className="nav-section">Contacts</span><Right />Create</span>, 
                            link: '/system/contact_types/create',
                        },
                        { 
                            val: 'system contact_types all contacts all', 
                            name: <span><Icon i={'fas fa-users'} /><Right /><span className="nav-section">Contacts</span><Right />View</span>, 
                            link: '/system/contact_types/all',
                            endSection: true
                        },
                       
                        { 
                            val: 'system forms create', 
                            name: <span><Icon i={'fas fa-edit'} /><Right /><span className="nav-section">Forms</span><Right />Create</span>, 
                            link: '/editor/forms/new',
                        },
                        { 
                            val: 'system forms all', 
                            name: <span><Icon i={'fas fa-edit'} /><Right /><span className="nav-section">Forms</span><Right />View</span>, 
                            link: '/system/forms',
                            endSection: true
                        },
                    
                        { 
                            val: 'system tags create', 
                            name: <span><Icon i={'fas fa-tags'} /><Right /><span className="nav-section">Tags</span><Right />Create</span>, 
                            link: '/system/tags/create' ,
                        },
                        { 
                            val: 'system tags all', 
                            name: <span><Icon i={'fas fa-tags'} /><Right /><span className="nav-section">Tags</span><Right />View</span>, 
                            link: '/system/tags/all' ,
                            endSection: true
                        },
                       
                        { 
                            val: 'system locations create', 
                            name: <span><Icon i={'fas fa-building'} /><Right /><span className="nav-section">Locations</span><Right />Create</span>, 
                            link: '/system/locations/create' ,
                        },
                        { 
                            val: 'system locations all', 
                            name: <span><Icon i={'fas fa-building'} /><Right /><span className="nav-section">Locations</span><Right />View</span>, 
                            link: '/system/locations/all' ,
                            endSection: true
                        },
                        
                        { 
                            val: 'system documents docs create', 
                            name: <span><Icon i={'fas fa-folder-plus'} /><Right /><span className="nav-section">Docs</span><Right />Create</span>, 
                            link: '/system/matter_documents/create' ,
                        },
                        { 
                            val: 'system documents docs all', 
                            name: <span><Icon i={'fas fa-folder-plus'} /><Right /><span className="nav-section">Docs</span><Right />View</span>, 
                            link: '/system/matter_documents/all' ,
                            endSection: true
                        },
                       
                        { 
                            val: 'system signing signature create', 
                            name: <span><Icon i={'fas fa-signature'} /><Right /><span className="nav-section">Signatures</span><Right />Create</span>, 
                            link: '/editor/signing/new?template=true' ,
                        },
                        { 
                            val: 'system signing signature all', 
                            name: <span><Icon i={'fas fa-signature'} /><Right /><span className="nav-section">Signatures</span><Right />View</span>, 
                            link: '/system/signing_templates/all' ,
                            endSection: true
                        },
                      
                        { 
                            val: 'system events create', 
                            name: <span><Icon i={'fas fa-calendar'} /><Right /><span className="nav-section">Events</span><Right />Create</span>, 
                            link: '/system/event_templates/create' ,
                        },
                        { 
                            val: 'system events all', 
                            name: <span><Icon i={'fas fa-calendar'} /><Right /><span className="nav-section">Events</span><Right />View</span>, 
                            link: '/system/event_templates/all' ,
                            endSection: true
                        },
                      
                        // { 
                        //     val: 'system wikis create', 
                        //     name: <span><Icon i={'fas fa-info-circle'} /><Right /><span className="nav-section">Wikis</span><Right />Create</span>, 
                        //     link: '/system/wikis/create' ,
                        // },
                        // { 
                        //     val: 'system wikis all', 
                        //     name: <span><Icon i={'fas fa-info-circle'} /><Right /><span className="nav-section">Wikis</span><Right />View</span>, 
                        //     link: '/system/wikis/all' ,
                        //     endSection: true
                        // },
                    ] : []),
                    
                ]
            },
            { 
                wrapper: true,
                val: 'staffing', 
                name: <span><Icon i={'fas fa-users'} />Staffing</span>, 
                link: '/staffing' ,
                options: [
                    ...(hasPrivilege('VIEW.ADMIN_DASHBOARD.STAFFING') ? [
                        { 
                            val: 'staffing users create', 
                            name: <span><Icon i={'fas fa-user'} /><Right /><span className="nav-section">Users</span><Right />Create</span>, 
                            link: '/staffing/users/create' ,
                        },
                        { 
                            val: 'staffing users all', 
                            name: <span><Icon i={'fas fa-user'} /><Right /><span className="nav-section">Users</span><Right />View</span>, 
                            link: '/staffing/users/all' ,
                            endSection: true
                        },
                       
                        { 
                            val: 'staffing divisions create', 
                            name: <span><Icon i={'fas fa-puzzle-piece'} /><Right /><span className="nav-section">Divisions</span><Right />Create</span>, 
                            link: '/staffing/divisions/create' ,
                        },
                        { 
                            val: 'staffing divisions all', 
                            name: <span><Icon i={'fas fa-puzzle-piece'} /><Right /><span className="nav-section">Divisions</span><Right />View</span>, 
                            link: '/staffing/divisions/all' ,
                            endSection: true
                        },
                    ] : []),
                ]
            },
            { 
                wrapper: true,
                val: 'automations', 
                name: <span><Icon i={'fas fa-bolt'} />Automations</span>, 
                link: '/automations' ,
                options: [
                    ...(hasPrivilege('VIEW.ADMIN_DASHBOARD.AUTOMATE') ? [
                        { 
                            val: 'automations create', 
                            name: <span><Icon i={'fas fa-code-branch'} /><Right /><span className="nav-section">Automations</span><Right />Create</span>, 
                            link: '/automations/automations/create' ,
                        },
                        { 
                            val: 'automations users all', 
                            name: <span><Icon i={'fas fa-code-branch'} /><Right /><span className="nav-section">Automations</span><Right />View</span>, 
                            link: '/automations/automations/all' ,
                            endSection: true
                        },
                       
                        { 
                            val: 'automations assignments all', 
                            name: <span><Icon i={'fas fa-route'} /><Right /><span className="nav-section">Assignments</span><Right />View</span>, 
                            link: '/automations/automation_assignments/all' ,
                            endSection: true
                        },
            
                        { 
                            val: 'automations email senders create', 
                            name: <span><Icon i={'fas fa-paper-plane'} /><Right /><span className="nav-section">Email Senders</span><Right />Create</span>, 
                            link: '/automations/email_senders/create' ,
                        },
                        { 
                            val: 'automations email senders all', 
                            name: <span><Icon i={'fas fa-paper-plane'} /><Right /><span className="nav-section">Email Senders</span><Right />View</span>, 
                            link: '/automations/email_senders/all' ,
                            endSection: true
                        },
                       
                        { 
                            val: 'automations email templates create', 
                            name: <span><Icon i={'fas fa-envelope'} /><Right /><span className="nav-section">Email Templates</span><Right />Create</span>, 
                            link: '/automations/email_templates/create' ,
                        },
                        { 
                            val: 'automations email templates all', 
                            name: <span><Icon i={'fas fa-envelope'} /><Right /><span className="nav-section">Email Templates</span><Right />View</span>, 
                            link: '/automations/email_templates/all' ,
                            endSection: true
                        },
            
                        { 
                            val: 'automations text templates create', 
                            name: <span><Icon i={'fas fa-mobile'} /><Right /><span className="nav-section">Text Templates</span><Right />Create</span>, 
                            link: '/automations/text_templates/create' ,
                        },
                        { 
                            val: 'automations text templates all', 
                            name: <span><Icon i={'fas fa-mobile'} /><Right /><span className="nav-section">Text Templates</span><Right />View</span>, 
                            link: '/automations/text_templates/all' ,
                            endSection: true
                        },
                    ] : []),
                ]
            },
        
            
        ])
    }, [])

    return (
        <div className="archk-master-search">
            <div className="position-relative search-wrapper">
                <Input 
                    // type="select"
                    value={val}
                    onChange={onSetVal}
                    placeholder="Search..."
                    onFocus={() => onSetShowResults(true)}
                />
                <i className="fas fa-search search-icon " />
            </div>

            {!showResults ? '' : (
                <>
                    <div className="results-backdrop" onClick={() => onSetShowResults(false)} />

                    <div className="results z-depth-3 pb-3">
                        <h4 className="border-bottom"><i className="fas fa-list mr--3 " /> Search Results</h4>
                        {mapOptions(options, val, onSetShowResults)}
                    </div>

                </>
            )}
            
        </div>
    )
}

export default NavbarSearch;