const triggers = [
    // 'incoming_email',
    'incoming_text',
    'call_finished',
    'voicemail_created',
    'event_created',
    'event_updated',
    'document_uploaded',
    'form_submitted',
    'signature_finalized',
    'note_left_by_user',
    'outstanding_item_created',
    'outstanding_item_completed',
    'task_created',
    'task_completed',
    'step_moved',
]

module.exports = triggers