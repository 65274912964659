import { useEffect } from 'react';
import { Row, Col } from 'reactstrap'

import Circle from 'components/markup/loading/Circle';

const WorkflowSidebarDisposition = ({ customFields, fetchCustomFields, stepToEdit, onAddArrayField, onRemoveArrayField }) => {

    useEffect(() => {
        if(!customFields) fetchCustomFields()
    }, [customFields, fetchCustomFields])

    let assignedFields = [];
    let possibleFields = [];

    if(customFields) {
        let foundFields = [];

        stepToEdit.custom_fields.forEach(c => {
            const found = customFields.find(cc => cc._id === c)
            if(found) {
                assignedFields.push(found)
                foundFields.push(found._id)
            }
        })

        customFields.forEach(c => {
            if(!foundFields.includes(c._id)) possibleFields.push(c)
        })

    }

    return (
        <div>
    
            <h2 className='text-warning'>Custom Fields</h2>
            <p className='text-sm'>Any fields selected below are considered "relevant" anytime someone views a case on this step and will be shown on the home page of the case. They will appear in the order they are selected here.</p>
    
            {!customFields ? (
                <Circle />
            ) : !customFields.length ? (
                <p className='text-sm mb-0 text-warning'>No custom fields have been created for this division</p>
            ) : (
                <Row>
                    <Col md={6}>
                        <table className='table border'>
                            <thead>
                                <tr>
                                    <th>Possible Fields</th>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                {possibleFields.length ? possibleFields.map(p => (
                                    <tr key={p._id}>
                                        <td>{p.name}</td>
                                        <td className='text-right' style={{verticalAlign: 'top'}}>
                                            <button onClick={() => onAddArrayField('custom_fields', p._id)} className='btn btn-success btn-sm'>
                                                <i className="fas fa-plus" />
                                            </button>
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td><i className="fas fa-info-circle mr-2 text-info" /> No Fields Found</td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </Col>
                    <Col md={6}>
                        <table className='table border'>
                            <thead>
                                <tr>
                                    <th>Assigned Fields</th>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                {assignedFields.length ? assignedFields.map(p => (
                                    <tr key={p._id}>
                                        <td>{p.name}</td>
                                        <td className='text-right'>
                                            <button onClick={() => onRemoveArrayField('custom_fields', p._id)} className='btn btn-danger btn-sm'>
                                                <i className="fas fa-trash" />
                                            </button>
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td><i className="fas fa-info-circle mr-2 text-info" /> No Fields Found</td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            )}
            {/* <FormGroup>
                <label className="form-control-label">Step Name</label>
                <Input 
                    id="archk-workflow-step-name"
                    type="text"
                    value={stepToEdit.name}
                    onChange={e => onSetStepToEdit('name', e.target.value)}
                />
                <p className="form-control-invalid">Step Name Required</p>
            </FormGroup>
    
            <ReactSelect 
                formGroup={true}
                title="Step Disposition"
                value={stepToEdit.workflow_disposition}
                onChange={(obj) => onSetStepToEdit('workflow_disposition', obj.value)}
                options={
                    [
                        { value: null, label: <span><i className="fas fa-times text-danger mr-2 " />None</span> },
                        ...dispositions.map((category, i) => {
                            return { value: category._id, label: category.name }
                        })
                    ]
                }
            />
    
            <ReactSelect 
                formGroup={true}
                title="Step Category"
                value={stepToEdit.workflow_step_category}
                onChange={(obj) => onSetStepToEdit('workflow_step_category', obj.value)}
                options={
                    [
                        { value: null, label: <span><i className="fas fa-times text-danger mr-2 " />None</span> },
                        ...stepCategories.map((disposition, i) => {
                            return { value: disposition._id, label: disposition.name }
                        })
                    ]
                }
            />
    
            {!stepToEdit.name && errs.includes('name') && (
                <div className='alert alert-warning'><i className="fas fa-info-circle text-in" /> This step must have a name.</div>
            )}
     */}
        </div>
    )
}



export default WorkflowSidebarDisposition