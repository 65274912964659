import { memo } from 'react'
import { Card, CardBody, CardHeader, CardTitle, CardFooter } from "reactstrap";

import { toggleStandardLoader } from 'store/functions/system/system';
import DragAndDrop from 'components/functional/uploads/DragAndDrop';
import Canvas from './Canvas/index'

import SelectContacts from './Modals/SelectContact'

import ModalToggler from 'components/functional/modals/Toggler'

const CanvasMain = ({
    markers, 
    addMarker, 
    removeMarker, 
    setMarkerType, 
    onFileAdded, 
    pdfData, 
    updateMarkerName, 
    shouldFireUploads, 
    onUpload, 
    showingParty, 
    isTemplate, 
    contacts, 
    onAddContact, 
    configuration,
    err,
    setErr
}) => (
    err ? (
        <div className="archk-template-docs-main">
            <div className="alert alert-warning text-center"><i className="fas fa-info-circle mr-2 " /> Something went wrong, please refresh your page.</div>
        </div>
    ) : 
    <div className="archk-template-docs-main">

        {isTemplate ? <div className="alert alert-info text-center py-2">You Are Currently Editing A Template Document</div> : null}

        <Card style={{display: pdfData ? 'none' : 'block', height: 'auto', width: 720, margin: 'auto'}} className="z-depth-3">

            {contacts.length ? (
                <div>
                    <CardHeader className="bg-secondary mt-5">
                        <CardTitle className="mb-0">Upload PDF To Send</CardTitle>
                    </CardHeader>

                    <CardBody className="text-center py-6">
                        <DragAndDrop
                            // only allow pdf documents
                            acceptedFiles="application/pdf"
                            uploadOne={true}
                            zone_id="documents_1"
                            url={`/api/v1/core/documents/create`}
                            hidePreview={true}
                            onFileAdded={onFileAdded}
                            shouldShowButton={false}
                            shouldFireUploads={shouldFireUploads}
                            onUpload={(a, b) => { onUpload(a, b) }}
                            onError={(e) => toggleStandardLoader(false)}
                            defaultValue={`Contract`}
                            extraData={{bucket: `/template docs`}}
                        />
                    </CardBody>
                </div>
            ) : null}

        </Card>

        {!contacts.length ? (
            <Card className="mt-3" style={{ width: 720, margin: 'auto'}}>
                <CardHeader className="bg-secondary ">
                    <CardTitle className="mb-0"><i className="fas fa-users mr-2 text-info " /> Select A Client</CardTitle>
                </CardHeader>

                <CardBody className="">
                    <p className="text-sm mb-0">Before you upload a document to be signed please select the client(s) you will sending the document to here.</p>
                </CardBody>
                <CardFooter className="text-right">
                    <ModalToggler component={SelectContacts} onAddContact={onAddContact} configuration={configuration} contacts={contacts}>
                        <button className="btn btn-success">Search Clients</button>
                    </ModalToggler>
                </CardFooter>
            </Card>
        ) : pdfData ? (
            <div style={{height: 'auto', width: 720,  margin: 'auto'}} >
                <Canvas
                    setErr={setErr}
                    pdfData={pdfData}
                    markers={markers}
                    addMarker={addMarker}
                    removeMarker={removeMarker}
                    setMarkerType={setMarkerType}
                    updateMarkerName={updateMarkerName}
                    showingParty={showingParty}
                    isTemplate={isTemplate}
                    configuration={configuration}
                />
            </div>
        ) : null}

    </div>
)

export default memo(CanvasMain)