import { useCallback, useState } from "react";
import { connect } from 'react-redux';
import { Badge, Card } from "reactstrap";
import { Link } from 'react-router-dom'

import api from 'api';

import ConfirmationModal from 'components/functional/modals/Confirmation';
import Table from 'components/functional/tables/Standard';
import TableIcons from 'components/markup/icons/TableIcons';
import ApiError from "components/markup/layout/ApiError";
import TextButtonRow from 'components/markup/layout/TextButtonRow';
import Circle from 'components/markup/loading/Circle';

import formatText from 'utils/formatText';

import { toast } from 'react-toastify'
import { setAssociationErrors } from 'store/functions/system/system'

const CallFlowsAll = ({setTab, selected_division}) => {

    const [ loading, setLoading ] = useState(false);
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ locationToDelete, setCallFlowToDelete ] = useState(false)
    const [ error, setError ] = useState(false);


    // helper for this.downloadCSV and this.query
    const fetchData = useCallback((params, isCSV, shouldReload) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(shouldReload) setLoading(true)
        const query = await api.call_flows.search({ ...params, filter: { division: selected_division._id }, isCSV })
        if(shouldReload) setLoading(false)

        if(!query.data) return setError(query.message)
        return resolve(query.data)
    }), [sizePerPage, selected_division._id])

    // run query to get data from the server
    const query = useCallback(async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }, [fetchData])

    const onDeleteCallFlow = useCallback(async (location) => {
        const deleted = await api.call_flows.delete(locationToDelete._id);
       
        if(deleted.success) {
            fetchData(null, null, true);
            toast.success('Call Flow Successfully Removed')
        } else {
            if(deleted.message[0] && deleted.message[0].err === 'associations') {
                setAssociationErrors(deleted.message[0].associations)
            } else {
                toast.error(`Internal Error, Please Try Again`)
            }
        }
    }, [fetchData, locationToDelete])
 
    const columns = [
        {
            dataField: "given_name",
            text: 'Name',
            headerFormatter: () => {
                return <div id="archk-tutorial-contacts-name">Name</div>
            },
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )
                return (
                    <Link to={`/communication/call_flows/${row._id}`}>
                        {row.name}
                    </Link>
                )
                
            },
        },


        {
            dataField: "audit_percent",
            text: 'Audit',
            formatter: (cell, row) => row.name === 'no data'  ? '' : row.audit_percent + '%'
        },
        {
            dataField: "record",
            text: 'Record',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' :
                    row.record ? <Badge color="success text-sm">Yes</Badge> :  <Badge color="danger text-sm">No</Badge>
            )
        },
        {
            dataField: "force_closed",
            text: 'Closed',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' :
                    row.force_closed ? <Badge color="danger text-sm">Yes</Badge> :  <Badge color="success text-sm">No</Badge>
            )
        },
        {
            dataField: "force_redirect",
            text: 'Redirect',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' :
                    row.force_redirect ?  <Badge color="warning text-sm">{formatText(row.force_redirect).phone()}</Badge> : <Badge color="success text-sm">No</Badge>
            )
        },
        {
            dataField: "timezone",
            text: 'Timezone',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' :
                    row.timezone.replace('America/', '').replace(/_/g, ' ')
            )
        },

        {
            dataField: "_id",
            headerStyle: {textAlign: 'right',},
            text: 'Actions',
            headerFormatter: () => {
                return <div id="archk-tutorial-contacts-actions">Actions</div>
            },
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                         <TableIcons
                            icons={[
                                { 
                                    icon: 'fas fa-edit',
                                    color: 'success', 
                                    wrapper: Link, 
                                    to: `/communication/call_flows/${row._id}`,
                                    onSaved: () => fetchData(null, null, true),
                                    _id: row._id
                                },
                                {  
                                    icon: 'fas fa-trash', 
                                    color: 'danger',
                                    onClick: () => setCallFlowToDelete(row)
                                },
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(error) return <ApiError err={error} />
    if(loading) return <div><Circle /></div>

    return (

        <>

            <TextButtonRow 
                title={"All Call Flows"}
                button={(
                    <div>
                        <button onClick={() => setTab('create')} className="btn btn-success"><i className="fas fa-plus mr-2 " /> Add New</button>
                    </div>
                )}
            />

            <Card className="card-color card-primary table-fixed">
                <Table
                    tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                    placeholder="Search..."
                    query={query}
                    columns={columns}
                    data={tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}
                    sizePerPage={sizePerPage}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>

            <ConfirmationModal 
                showModal={locationToDelete ? true : false}
                toggleModal={() => setCallFlowToDelete(null)}
                title="Delete Call Flow"
                body={(
                    <span>
                        Are you sure you wish to delete the location {locationToDelete ? locationToDelete.name : ''}? This will prevent the location from logging in or coming up in search results in the future.
                    </span>
                )}
                onConfirmation={onDeleteCallFlow}
            />

        </>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(CallFlowsAll);