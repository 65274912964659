import { FormGroup } from "reactstrap";

import PhoneInput from 'react-phone-input-2';
import { connect } from 'react-redux';

const SystemCallFlowEdit = ({task, onSetTask, selected_division}) => {

    return (
        <div>

           <FormGroup>
                <label className="form-control-label">Phone Number</label>

                <PhoneInput
                    country={'us'}
                    value={task.phone_number || ''}
                    onChange={phone => onSetTask('phone_number', phone)}
                />
            </FormGroup>    

        </div>
    )

}


const mapStateToProps = state => {
    return {
        selected_division: state.state.selected_division,
    };
};

export default connect(mapStateToProps, '')(SystemCallFlowEdit);
