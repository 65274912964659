import SigningDocumentsAll from './All'

import IntuideskSectionWrapper from 'components/markup/layout/IntuideskSectionWrapper'

const SystemSigningTemplates = ({match, section}) => (
    <IntuideskSectionWrapper 
        title="Word Documents"
        match={match}
        section={section}
        innerSection={'word_documents'}
        All={SigningDocumentsAll}
        editLink="/editor/signing/new?template=true"
    />
)

export default SystemSigningTemplates;