// 'VIEW.CAN_VIEW_APP_DASHBOARD' : 'VIEW.CAN_VIEW_APP_DASHBOARD',
// 'VIEW.CAN_VIEW_AFFILIATE_DASHBOARD' : 'VIEW.CAN_VIEW_AFFILIATE_DASHBOARD',
// 'VIEW.CAN_VIEW_ADMIN_DASHBOARD' : 'VIEW.CAN_VIEW_ADMIN_DASHBOARD',

import { Row, Col } from "reactstrap";
import { connect } from 'react-redux';
import { useCallback, useState } from 'react';

import Privilege  from "./Privilege";

const UsersEditPrivilegesStandard = ({user, setUser, onPrivilegeChange, PRIVILEGES, DOMAINS}) => {

    const [standardPrivileges] = useState([
        PRIVILEGES['VIEW.CAN_VIEW_AFFILIATE_DASHBOARD'],
        PRIVILEGES['VIEW.AI'],
    ])

    const assignAll = useCallback(() => {
        const newUser = Object.assign({}, user)
        standardPrivileges.forEach(privilege => {
            if(!newUser.privileges.includes(privilege)) {
                newUser.privileges.push(privilege)
            }
        })
     
        setUser(newUser);
    }, [standardPrivileges, setUser, user])
   
    const removeAll = useCallback(() => {
        const newUser = Object.assign({}, user)
        standardPrivileges.forEach(privilege => {
            newUser.privileges = newUser.privileges.filter(p => p !== privilege)
        })
     
        setUser(newUser);
    }, [standardPrivileges, setUser, user])

    return (
        <div>

            <Row>
                <Col xs={6} className="align-self-center">
                    <h2>Viewing Privileges</h2>
                </Col>
                <Col xs={6} className="align-self-center text-right">
                    <button onClick={removeAll} className="btn btn-outline-warning">Remove All</button>
                    <button onClick={assignAll} className="btn btn-outline-info">Assign All</button>
                </Col>
            </Row>


            <h5>AFFILIATE DASHBOARD</h5>
            <Privilege 
                privilege={PRIVILEGES['VIEW.CAN_VIEW_AFFILIATE_DASHBOARD']}
                user={user}
                title={`Can View Affiliate Dashboard - ${DOMAINS.AFFILIATE}`}
                onPrivilegeChange={onPrivilegeChange}
            />

            <h5>AI Components</h5>
                
            <Privilege 
                privilege={PRIVILEGES['VIEW.AI']}
                user={user}
                title="Can view items related to AI."
                onPrivilegeChange={onPrivilegeChange}
            />

        </div>
    )

}


const mapStateToProps = state => {
	return {
	    PRIVILEGES: state.config.PRIVILEGES,
        DOMAINS: state.load.DOMAINS,

	};
};

export default connect(mapStateToProps, '')(UsersEditPrivilegesStandard);