import { useCallback, useState } from "react";
import { Link } from 'react-router-dom';
import { Card } from "reactstrap";
import { connect } from 'react-redux';

import api from 'api';

import TextButtonRow from 'components/markup/layout/TextButtonRow'
import Circle from 'components/markup/loading/Circle'
import ConfirmationModal from 'components/functional/modals/Confirmation'
import TableIcons from 'components/markup/icons/TableIcons'
import Table from 'components/functional/tables/Standard';
import ApiError from "components/markup/layout/ApiError";
import ModalToggler from 'components/functional/modals/Toggler'

import { toast } from "react-toastify";

import EditOutcome from "../components/Edit";

const EventOutcomesAll = ({selected_division}) => {

    const [ hideTable, setHideTable ] = useState(false);
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ toDelete, setToDelete ] = useState(false)
    const [ error, setError ] = useState(false);

    // helper for this.downloadCSV and this.query
    const fetchData = useCallback((params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        const query = await api.event_outcomes.search({ ...params, filter: { division: selected_division._id }, isCSV })
        
        if(!query.data) return setError(query.message)
        return resolve(query.data)
    }), [sizePerPage, selected_division._id])

    const onSaved = useCallback(async (params) => {
        setHideTable(true)
        setTimeout(() => {
            setHideTable(false)
        }, 250)
    }, [])

    // run query to get data from the server
    const query = useCallback(async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }, [fetchData])

    const onDelete = useCallback(async (user) => {
        const deleted = await api.event_outcomes.delete(toDelete._id);
        if(!deleted.success) return toast.error(`Something's not right, please try again`)

        onSaved();

    }, [toDelete, onSaved])
 
    const columns = [
        {
            dataField: "name",
            text: 'Name',
            headerFormatter: () => {
                return <div>Name</div>
            },
            formatter: (cell, row) => {

                if(row.outcome === 'no data') return (
                    <div>No Data To Show</div>
                )
                return (
                    <Link to={`/system/event_outcomes/${row._id}`} _id={row._id} onSaved={() => fetchData(null, null, true)}>
                        <span className="cursor-pointer text-capitalize">
                            {row.outcome}
                        </span>
                    </Link>
                )
                
            },
        },
        {
            dataField: "quality_control",
            text: 'Quality Control',
            formatter: (cell, row) => {
                if(row.outcome === 'no data') return ''
                if(row.quality_control === 0) return '-'
                if(row.quality_control === 5) return 'Standard Priority'
                if(row.quality_control === 7) return 'High Priority'
                if(row.quality_control === 10) return 'Highest Priority'
                return '-'
            }
        },      
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right',},
            text: 'Actions',
            headerFormatter: () => {
                return <div>Actions</div>
            },
            formatter: (cell, row) => {
                return (
                    row.outcome === 'no data' ? '' :
                    <div className="text-right">
                        <TableIcons
                            icons={[
                                { 
                                    icon: 'fas fa-edit',
                                    color: 'success', 
                                    wrapper: Link, 
                                    to: `/system/event_outcomes/${row._id}`,
                                    // component: EmailTemplatesEdit,
                                    _id: row._id,
                                    onSaved: () => fetchData(null, null, true)
                                },
                                {  
                                    icon: 'fas fa-trash', 
                                    color: 'danger',
                                    onClick: () => setToDelete(row)
                                },
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(error) return <ApiError err={error} />

    return (

        <>

            <TextButtonRow 
                title={"All Event Outcomes"}
                leftColSize={4}
                button={(
                    <div>
                        <Link to="/system/event_templates" className="btn btn-info"><i className="fas fa-arrow-left ml-2" /> All Events</Link>
                        <Link to="/system/event_types" className="btn btn-warning"><i className="fas fa-plus ml-2" /> Event Type</Link>
                        <ModalToggler component={EditOutcome} onSaved={onSaved}>
                            <button className="btn btn-success">
                                <i className="fas fa-plus mr-2 " /> Add Outcome
                            </button>
                        </ModalToggler> 
                    </div>
                )}
            />

            <Card className="card-color card-primary table-fixed">
                {hideTable ? <Circle /> : (
                    <Table
                        tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                        placeholder="Search..."
                        query={query}
                        columns={columns}
                        data={tableData.data.length ? tableData.data : [{_id: 'test', outcome: 'no data'}]}
                        totalDocuments={tableData.total_documents}
                        sizePerPage={sizePerPage}
                        onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                    />
                )}
            </Card>

            <ConfirmationModal 
                showModal={toDelete ? true : false}
                toggleModal={() => setToDelete(null)}
                title="Delete Event Outcome"
                body={(
                    <span>
                        Are you sure you wish to delete the event outcome: {toDelete ? toDelete.outcome : ''}? 
                    </span>
                )}
                onConfirmation={onDelete}
            />

        </>
    )

}


const mapStateToProps = state => {
	return {
        selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(EventOutcomesAll);