import { connect } from 'react-redux'
import IntuideskSection from "components/markup/layout/IntuideskSection";

import TodaysActivity from './TodaysActivity'

const SystemView = ({match, selected_division}) => (
    <IntuideskSection 
        title={`Page Layouts ${selected_division ? ' - ' + selected_division.name : ''}`}
        section="layout"
        defaultRoute="todays_activity"
        match={match}
        pages={[
            { value: 'todays_activity', icon: 'fas fa-route', name: 'Todays Activity', Component: TodaysActivity },
        ]}
    />
)


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SystemView);