/*
Documentation

USAGE:

import InputStates from 'components/markup/inputs/States'

<InputStates 
    value={user.state}
    onChange={state => onInputChange('state', state)}
/>

*/

import { connect } from 'react-redux';
import ReactSelect from 'components/functional/inputs/ReactSelect'

const InputStates = ({value, onChange, title, placeholder, STATES}) => {
    const foundState = STATES.find(state => state.code === value);
    const formattedValue = foundState ? { value: foundState.code, label: foundState.name } : '';

    return (
        <ReactSelect 
            formGroup={true}
            placeholder={placeholder}
            title={title ? title :"State"}
            value={formattedValue}
            onChange={(state) => onChange(state.value)}
            options={STATES.map(state => {
                return { value: state.code, label: state.code + ' - ' + state.name }
            })}
        />
    )

}

const mapStateToProps = state => {
	return {
	    STATES: state.config.STATES,
	};
};

export default connect(mapStateToProps, '')(InputStates);