
import React from "react";
import { Redirect } from 'react-router-dom';
import { getUrlParameter } from 'utils/urls';

const FullRedirect = () => {

    const redirect = getUrlParameter('redirect')
    return <Redirect to={redirect ? redirect : '/'} />

}

export default FullRedirect;