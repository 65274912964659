import CustomViewsEdit from './components/Edit'
import CustomViewsAll from './All'

import IntuideskSectionWrapper from 'components/markup/layout/IntuideskSectionWrapper'

const SystemCustomViews = ({match, section}) => (
    <IntuideskSectionWrapper 
        title="CustomViews"
        editTitle="Custom Views"
        match={match}
        section={section}
        innerSection={'custom_views'}
        Edit={CustomViewsEdit}
        All={CustomViewsAll}
    />
)

export default SystemCustomViews;