import Circle from 'components/markup/loading/Circle';
import { useCallback, useEffect, useState } from 'react';
import { Card, CardHeader, CardTitle, Col, FormGroup, Input, Row } from 'reactstrap';

import api from 'api';
import { toast } from 'react-toastify';

import CategoryRow from './CategoryRow';

const WorkflowEditCategories = ({workflow}) => {

    const [category, setCategory] = useState('')
    const [categories, setCategories] = useState(workflow.categories)

    const _handleKeyDown = useCallback((e) => {
		if (e.key === 'Enter') document.getElementById("archk-add-category-button").click();
	}, [])

    const onAdd = useCallback(async () => {
        if(!category) return;
        const _categories = JSON.parse(JSON.stringify(categories));

        const created = await api.workflow_step_categories.create({
            workflow: workflow._id,
            name: category,
        })
        if(created.success) {
            _categories.push(created.data)
            setCategories(_categories);
            setCategory('')   
        } else {
            toast.error("Something went wrong creating this category, please try again and make sure the category is unique.")
        }
      
    }, [categories, category, workflow._id])
    
    const onDelete = useCallback(async (_category) => {
        let _categories = JSON.parse(JSON.stringify(categories));

        const deleted = await api.workflow_step_categories.delete(_category._id)
        if(deleted.success) {
            _categories = _categories.filter(r => r._id !== deleted.data._id)
            setCategories(_categories);
        } else {
            toast.error("Something went wrong deleting this category, please try again.")
        }
    }, [categories])

    const fetchData = useCallback(async () => {
        const data = await api.workflow_step_categories.find(workflow._id);
        if(data.data) setCategories(data.data)
    }, [workflow._id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <Card>
            <CardHeader>
                <CardTitle>Add Workflow Categories</CardTitle>
                <p className="text-sm mb-0"><i className="fas fa-info-circle mr-2 info-circle text-info " /> Categories may be associated to steps to allow you so search and aggregate data based on matters in that category. For instance, you can create two separate categories called "Pre Filing" and "Post Filing" to be able to pull reports based on any matters inside each designated category.</p>

            </CardHeader>
            <CardHeader>
            
                <label className="form-control-label">Category Name*</label>

                <Row>
                    <div className="col-auto" style={{width: 'calc(100% - 168px)'}}>
                        <FormGroup>
                            <Input 
                                onKeyDown={_handleKeyDown}
                                type="text"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}

                            />
                        </FormGroup>
                    </div>
                    <Col>
                        <button 
                            id="archk-add-category-button"
                            onClick={onAdd} 
                            className="btn btn-outline-info btn-block"
                        >
                            <i className="fas fa-plus mr-2 " /> Add Category
                        </button>
                    </Col>
                </Row>
            </CardHeader>

            {!categories ? <Circle /> : (
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Workflow Categories</th>
                                <th>Field Map ID</th>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {categories.length ? categories.map((category) => <CategoryRow category={category} onDelete={onDelete} />) : (
                                <tr>
                                    <td>No categories have been created for this workflow</td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}


        </Card>
    )

}

export default WorkflowEditCategories;