import { useEffect, useState, useCallback, memo } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Container } from "reactstrap";

import HeaderNavigation from 'components/markup/layout/HeaderNavigation'
import NotFound from 'components/markup/layout/NotFound'

const IntuideskSection = ({section, pages, title, defaultRoute, match}) => {
    const history = useHistory();
    const location = useLocation()

    const [ tab, setTab ] = useState(match.params.nav1 ? match.params.nav1 : defaultRoute ? defaultRoute : '')

    const onSetTab = useCallback((tab) => {
            setTab(tab)
            history.push(`/${section}/${tab.toLowerCase()}`);
            window.scrollTo(0, 0)
    }, [history, section])

    const returnComponent = useCallback(() => {
        const foundPage = pages.find(page => page.value === tab);
        if(!foundPage || (foundPage && !foundPage.Component)) return  <NotFound />
        const props = foundPage.extraProps ? foundPage.extraProps : {}
        return <foundPage.Component match={match} section={section} {...props} />
    }, [match, pages, tab, section])

    useEffect(() => {
        try {
            const pathname = location.pathname.split(section)
            const nav1 = pathname[1].split('/')[1]
            if(nav1 && nav1 !== tab) setTab(nav1)
            window.scrollTo(0, 0)
        } catch(e) {}
    }, [location, tab, section])

    return (

        <>
            <HeaderNavigation 
                title={title}
                tab={tab}
                setTab={onSetTab}
                tabs={pages.map(page => {
                    return { hidden: page.hidden, value: page.value, name: <span><i className={`${page.icon} mr-2 `} /> {page.name}</span>  }
                })}

            />

            <Container fluid>
                {returnComponent()}
            </Container>
        </>

    );

}

export default memo(IntuideskSection);