import { useCallback } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Col, FormGroup, Row } from 'reactstrap';

import ReactSelect from 'components/functional/inputs/ReactSelect';
import SearchCollections from 'components/system/Search/Collections';

import { v4 } from 'uuid';

const WorkflowSidebarMatterDocuments = ({stepToEdit, setStepToEdit, selected_division, errs}) => {

    const onAddMatterDoc = useCallback((field, value) => {
        const _stepToEdit = JSON.parse(JSON.stringify(stepToEdit));
        const docs = _stepToEdit.matter_documents;
        docs.push({
            id                : v4(),
            matter_document   : '' ,
            expiry_interval   : '' ,
        })

        setStepToEdit(_stepToEdit);
    }, [stepToEdit, setStepToEdit])

    const onRemoveMatterDoc = useCallback((id) => {
        const _stepToEdit = JSON.parse(JSON.stringify(stepToEdit));
        let docs = _stepToEdit.matter_documents;

        _stepToEdit.matter_documents = docs.filter(t => {
            if(t.id) return t.id !== id
            return t._id !== id
        })
        setStepToEdit(_stepToEdit);
    }, [stepToEdit, setStepToEdit])

    const onChange = useCallback((id, field, value) => {
        const _stepToEdit = JSON.parse(JSON.stringify(stepToEdit));
        const step = _stepToEdit.matter_documents.find(s => s._id === id || s.id === id)
        if(!step) return;
        step[field] = value;
        setStepToEdit(_stepToEdit);
    }, [stepToEdit, setStepToEdit])
    
    return (
        <>
            <Row className='mb-3'>
                <Col xs={6} className="align-self-center">
                    <h2 className='text-warning'>STEP DOCUMENTS</h2>
                </Col>
                <Col xs={6} className="align-self-center text-right">
                    <button className='btn btn-sm btn-info' onClick={onAddMatterDoc}><i className="fas fa-plus mr-2" /> Add New</button>
                </Col>
            </Row>
        
            {stepToEdit.matter_documents.length ? stepToEdit.matter_documents.map((doc, i) => {
                const id = doc.id ? doc.id : doc._id ;
                return (
                    <Card key={id} className="z-depth-0 ">
                        <CardBody className='label-block'>
                            <FormGroup className='mb-0'>
                                <label className='form-control-label'>
                                    <Row>
                                        <Col xs={6} className="align-self-center">
                                        Matter Document #{i + 1}
                                        </Col>
                                        <Col xs={6} className="align-self-center text-right">
                                            <i onClick={() => onRemoveMatterDoc(id)} className="fas fa-trash mr-2 text-danger cursor-pointer pl-3" />
                                        </Col>
                                    </Row>
                                </label>
                            </FormGroup>
                            <SearchCollections
                                collection="matter_documents" 
                                value={doc.matter_document}
                                onChange={(obj) => onChange(id, 'matter_document', obj.value)}
                                filter={{
                                    division: selected_division._id
                                }}
                            /> 

                            <FormGroup>

                                <ReactSelect 
                                    formGroup={true}
                                    title="Expiration Interval"
                                    value={doc.expiry_interval}
                                    onChange={(obj) => onChange(id, 'expiry_interval', obj.value)}
                                    options={[
                                        { value: '0', label: 'None' },
                                        { value: '1st_of_the_month', label: '1st Of The Month' },
                                        // { value: '15th_of_the_month', label: '15th Of The Month' },
                                        { value: '1', label: '1 Day' },
                                        { value: '2', label: '2 Days' },
                                        { value: '3', label: '3 Days' },
                                        { value: '4', label: '4 Days' },
                                        { value: '5', label: '5 Days' },
                                        { value: '6', label: '6 Days' },
                                        { value: '7', label: '7 Days' },
                                        { value: '10', label: '10 Days' },
                                        { value: '14', label: '14 Days' },
                                        { value: '14', label: '21 Days' },
                                        { value: '28', label: '28 Days' },
                                        { value: '30', label: '30 Days' },
                                        { value: '45', label: '45 Days' },
                                        { value: '60', label: '60 Days' },
                                        { value: '75', label: '75 Days' },
                                        { value: '90', label: '90 Days' },
                                        { value: '120', label: '120 Days' },
                                        { value: '150', label: '150 Days' },
                                        { value: '180', label: '180 Days' },
                                        { value: '210', label: '210 Days' },
                                        { value: '240', label: '240 Days' },
                                        { value: '270', label: '270 Days' },
                                        { value: '300', label: '300 Days' },
                                        { value: '365', label: '365 Days' },
                                    ]}
                                />

                            </FormGroup>
                        
                        </CardBody>
                    </Card>
            )}) : (
                <p className='text-sm mb-0'><i className="fas fa-info-circle text-info" /> No Documents Have Been Added</p>
            )}

            {errs.includes('matter_document') ? (
                <div className='alert alert-warning'>
                    <i className="fas fa-info-circle" /> Each template requires an associated document to be selected.
                </div>
            ) : ''}
          
        </>
    )
}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(WorkflowSidebarMatterDocuments);