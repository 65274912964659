import formatDoc from './formatDoc';

const getDoc = (docs, value) => {
    if(value) {
        if(value._id || value.id) {
            value = formatDoc(value)
        } else {
            const foundDoc = docs.find(a => {
                if(a._id) return a._id === value
                return a.id === value
            });
            if(foundDoc) {
                value = formatDoc(foundDoc)
            }
        }
    }

    return value;
}

export default getDoc;