import TagsEdit from './components/Edit'
import TagsAll from './All'

import IntuideskSectionWrapper from 'components/markup/layout/IntuideskSectionWrapper'

const SystemAIAssistants = ({match, section}) => (
    <IntuideskSectionWrapper 
        title="AI Assistants"
        editTitle="AI Assistant"
        match={match}
        section={section}
        innerSection={'ai_assistants'}
        Edit={TagsEdit}
        All={TagsAll}
    />
)

export default SystemAIAssistants;