import { Col } from 'reactstrap';

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import PageList from './PageList';

const ColPage = ({ questions, setQuestions, form, page, onAddPage, onSetPage, unsavedQuestion, setUnsavedForm, config}) => (
    <Col lg={4} className="col col-page z-depth-">
        <DndProvider backend={HTML5Backend}>
            <PageList 
                allQuestions={questions}
                page={page}
                pages={form.pages}
                onAddPage={onAddPage}
                setQuestions={setQuestions}
                onSetPage={onSetPage}
                unsavedQuestion={unsavedQuestion}
                setUnsavedForm={setUnsavedForm}
                config={config}
            />
        </DndProvider>
    </Col>
)

export default ColPage