import TaskEdit from './components/Edit'
import TaskAll from './All'

import IntuideskSectionWrapper from 'components/markup/layout/IntuideskSectionWrapper'

const SystemWikis = ({match, section, workflow}) => (
    <IntuideskSectionWrapper 
        title="Tasks"
        editTitle="Task"
        match={match}
        section={section}
        innerSection={'tasks'}
        Edit={TaskEdit}
        All={TaskAll}
        params={{
            workflow
        }}
    />
)

export default SystemWikis;