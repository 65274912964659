import { useCallback, useState } from "react";
import { connect } from 'react-redux';
import { Card } from "reactstrap";

import api from 'api';
import formatText from 'utils/formatText'

import TextButtonRow from 'components/markup/layout/TextButtonRow'
import Circle from 'components/markup/loading/Circle'
import ConfirmationModal from 'components/functional/modals/Confirmation'
import TableIcons from 'components/markup/icons/TableIcons'
import ModalToggler from 'components/functional/modals/Toggler'
import Table from 'components/functional/tables/Standard';
import ApiError from "components/markup/layout/ApiError";

import CustomFieldsEdit from '../components/Edit'
import CopyText from 'components/functional/text/Copy'

import { toast } from 'react-toastify'
import { setAssociationErrors } from 'store/functions/system/system'

const CustomFieldsAll = ({setTab, selected_division}) => {

    const [ loading, setLoading ] = useState(false);
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ customFieldToDelete, setCustomFieldToDelete ] = useState(false)
    const [ error, setError ] = useState(false);

    // helper for this.downloadCSV and this.query
    const fetchData = useCallback((params, isCSV, shouldReload) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(shouldReload) setLoading(true)
        const filter = {};
        if(selected_division._id) filter.division = selected_division._id

        const query = await api.custom_fields.search({ ...params, filter, isCSV })
        if(shouldReload) setLoading(false)

        if(!query.data) return setError(query.message)
        return resolve(query.data)
    }), [sizePerPage, selected_division._id])

    // run query to get data from the server
    const query = useCallback(async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }, [fetchData])

    const onDeleteCustomField = useCallback(async (customField) => {
        const deleted = await api.custom_fields.delete(customFieldToDelete._id);

        if(deleted.success) {
            setLoading(true)
            await fetchData();
            setLoading(false)
        } else {
            if(deleted.message[0] && deleted.message[0].err === 'associations') {
                setAssociationErrors(deleted.message[0].associations)
            } else {
                toast.error(`Internal Error, Please Try Again`)
            }
        }
     
    }, [fetchData, customFieldToDelete])
 
    const columns = [
        {
            dataField: "name",
            text: 'Name',
            headerFormatter: () => {
                return <div>Name</div>
            },
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )
                return (
                    <ModalToggler component={CustomFieldsEdit} onSaved={fetchData} _id={row._id}>
                        <span className="cursor-pointer">{formatText(row.name).stripHTML(40)}</span>
                    </ModalToggler>
                )
                
            },
        },
        {
            dataField: "created_at",
            text: 'Field Map Id',
            formatter: (cell, row) => {

                if(row.name === 'no data') return ''
                return (
                    <CopyText text={row._id} />
                )
                
            },
        },
        {
            dataField: "description",
            text: 'Description',
            formatter: (cell, row) => {

                if(row.name === 'no data') return ''
                return (
                    <span className="cursor-pointer">{formatText(row.description).stripHTML(40)}</span>
                )
                
            },
        },
        {
            dataField: "type",
            text: 'Type',
            formatter: (cell, row) => {

                if(row.name === 'no data') return ''

                if(row.type === 'text') return 'Text'
                if(row.type === 'select') return 'Select'
                if(row.type === 'number') return 'Number'
                if(row.type === 'location') return 'Location'
                if(row.type === 'date') return 'Date'
                if(row.type === 'event') return 'Event'
                if(row.type === 'document') return 'Document'
                if(row.type === 'contact_type') return 'Contact Type'
                if(row.type === 'address') return 'Address'
                if(row.type === 'location_type') return 'Location'
                if(row.type === 'state') return 'State'
                if(row.type === 'user') return 'User'
                return row.type

            },
        },
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right',},
            text: 'Actions',
            headerFormatter: () => {
                return <div id="archk-tutorial-contacts-actions">Actions</div>
            },
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                         <TableIcons
                            icons={[
                                { 
                                    icon: 'fas fa-edit',
                                    color: 'success', 
                                    wrapper: ModalToggler, 
                                    component: CustomFieldsEdit,
                                    onSaved: fetchData,
                                    _id: row._id
                                },
                                {  
                                    icon: 'fas fa-trash', 
                                    color: 'danger',
                                    onClick: () => setCustomFieldToDelete(row)
                                },
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(error) return <ApiError err={error} />
    if(loading) return <div><Circle /></div>

    return (

        <>

            <TextButtonRow 
                title={"All Custom Fields"}
                button={(
                    <button onClick={() => setTab('create')} className="btn btn-success"><i className="fas fa-plus mr-2 " /> Add New</button>
                )}
            />

            <Card className="card-color card-primary table-fixed">
                <Table
                    tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                    placeholder="Search Name Or Type..."
                    query={query}
                    columns={columns}
                    data={tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}
                    sizePerPage={sizePerPage}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>

            <ConfirmationModal 
                showModal={customFieldToDelete ? true : false}
                toggleModal={() => setCustomFieldToDelete(null)}
                title="Delete Custom Field"
                body={(
                    <span>
                        Are you sure you wish to delete the custom field {customFieldToDelete ? customFieldToDelete.name : ''}?
                    </span>
                )}
                onConfirmation={onDeleteCustomField}
            />

        </>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(CustomFieldsAll);