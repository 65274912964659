// archk-tutorial-documents-completed
import { useEffect, useState, useCallback } from 'react';
import { closeTutorial } from 'store/functions/tutorial'

const maxWidth = 325;
const width = '100vw';
const margin = 45;

const ArchkTutorial = ({steps, isOpen}) => {

    const [state, setState] = useState({
        step: {},
        arrow: <div />,
        styles: {},
        stylesWhiteOut: {},
    })

    const { styles, step, arrow, stylesWhiteOut } = state;


    const index = steps.findIndex(s => s.id === step.id);

    const onClose = useCallback(() => {
        const highlights = document.querySelectorAll('.archk-tutorial-highlight');

        for (let i = 0; i < highlights.length; i++) {
            const element = highlights[i];
            element.classList.remove('archk-tutorial-highlight')
        }
        closeTutorial()
    }, [])

    const onSetStep = useCallback((_step) => {

        const highlights = document.querySelectorAll('.archk-tutorial-highlight');

        for (let i = 0; i < highlights.length; i++) {
            const element = highlights[i];
            element.classList.remove('archk-tutorial-highlight')
        }

        const newHighlight = document.getElementById(_step.id);
        if(!newHighlight) return onClose();

        const windowWidth = window.innerWidth;

        let _styles = {};
        let arrow = <div />

        newHighlight.classList.add('archk-tutorial-highlight') 
        var rect = newHighlight.getBoundingClientRect();

        const position = {
            left: rect.left + window.scrollX,
            right: rect.right + window.scrollX,
            top: rect.top + window.scrollY,
            bottom: rect.bottom  + window.scrollY,
        }

        const overflowsRight = position.right + margin + maxWidth > windowWidth
        const leftIsGreaterThanMax = position.left  > maxWidth;
        const widthIsGreaterThanRight = maxWidth > windowWidth - position.right;
        const widthIsGreaterThanArea = maxWidth > position.right - position.left;
        const isOnLeftSide = position.left < windowWidth / 2;

        if(overflowsRight) {

            if(widthIsGreaterThanRight && leftIsGreaterThanMax) {
                _styles = { top: position.bottom + margin, right: windowWidth - position.right }
                arrow = <i className="arrow fas fa-arrow-up text-warning font-weight-bold " style={{top: -margin / 2 - 5, right: margin, left: 'auto'}} />
            } else {
                if(widthIsGreaterThanArea && !isOnLeftSide) {
                    _styles = { top: position.bottom + margin, right: windowWidth - position.right }
                    arrow = <i className="arrow fas fa-arrow-up text-warning font-weight-bold " style={{top: -margin / 2 - 5, right: margin, left: 'auto'}} />
                } else {
                    _styles = { top: position.bottom + margin, left: position.left }
                    arrow = <i className="arrow fas fa-arrow-up text-warning font-weight-bold " style={{top: -margin / 2 - 5}} />
                }
                
            }

        } else {
            arrow = <i className="arrow fas fa-arrow-left text-warning font-weight-bold " style={{left: -margin / 2 - 5, top: 5}} />

            _styles = {
                top: position.top,
                left: position.right + margin,
            }
        }

        window.scrollTo({
            top: position.top - margin * 2
        })

        setState({
            step: _step,
            styles: _styles,
            stylesWhiteOut: position,
            arrow,
        })
           
    }, [onClose])

    const moveForward = useCallback(() => {
        if(index !== -1 && steps[index + 1]) {
            onSetStep(steps[index + 1])
        } else {
            onClose();
        }
    }, [index, steps, onSetStep, onClose])

    const moveBack = useCallback(() => {
        if(index !== -1 && steps[index - 1]) {
            onSetStep(steps[index - 1])
        }
    }, [index, steps, onSetStep])

    useEffect(() => {
        if(!isOpen) return;
        onSetStep(steps[0])
    }, [steps, onSetStep, isOpen])



    if(!isOpen) return <></>;



    return (
        <div className="archk-tutorial">
            <div className="blackout"></div>
            <div className="archk-tutorial-whiteout" style={stylesWhiteOut}></div>

            <div 
                className="archk-tutorial-modal"
                style={{
                    ...styles,
                    width,
                    maxWidth
                }}
            >
                {arrow}

                <div className="title border-bottom position-relative">
                    <p className="mb-0">{step.title}</p>
                    <i onClick={onClose} className="fas fa-times p-3 cursor-pointer" style={{position: 'absolute', top: 0, right: 2}} />
                </div>
                <div className="body border-bottom">{step.body}</div>
                <div className="controls text-right">
                    <small className="float-left">{index + 1} / {steps.length}</small>

                    {steps[index - 1] ? <small onClick={moveBack} className="mr-4 cursor-pointer"><i className="fas fa-arrow-left mr-2" /> Back</small> : null}

                    <button onClick={moveForward} className="btn btn-sm btn-outline-success">
                        {index === steps.length - 1 ? 'Finish' : 'Next'}
                    </button>
                </div>
            </div>

        </div>
    )

}

export default ArchkTutorial;