// import UsersEdit from './components/Edit'
// import UsersAll from './All'

// import IntuideskSectionWrapper from 'components/markup/layout/IntuideskSectionWrapper'

import { Row, Col, Card, CardBody, CardHeader, CardTitle, CardFooter, Badge } from 'reactstrap'

import Categories from './Categories'
import CallCenter from './CallCenter'
import Pipeline from './Pipeline'
import Monitoring from './Monitoring'
import Intake from './Intake'
import Quotas from './Quotas'

const SystemUsers = ({match, section}) => (
    <div>
        <div className='alert alert-info'>Section Under Development</div>
        {/* <Quotas />
        <Intake />
        <Categories />
        <Pipeline />
        <CallCenter />
        <Monitoring /> */}
    </div>
)

export default SystemUsers;