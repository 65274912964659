import { useCallback, useState } from "react";
import { connect } from 'react-redux';
import { Card } from "reactstrap";

import api from 'api';

import TextButtonRow from 'components/markup/layout/TextButtonRow'
import Circle from 'components/markup/loading/Circle'
import ConfirmationModal from 'components/functional/modals/Confirmation'
import TableIcons from 'components/markup/icons/TableIcons'
import ModalToggler from 'components/functional/modals/Toggler'
import Table from 'components/functional/tables/Standard';
import ApiError from "components/markup/layout/ApiError";

import TasksEdit from '../components/Edit'
import { toggleAlertBS } from "store/functions/system/system";
import ObjectFinder from 'components/system/Objects/Finder';
import { getUrlParameter } from "utils/urls";

const TasksAll = ({setTab, workflow}) => {

    const [ loading, setLoading ] = useState(false);
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ taskToDelete, setTaskToDelete ] = useState(false)
    const [ error, setError ] = useState(false);
    const [ editingTask, setEditingTask ] = useState(getUrlParameter('task'));

    // helper for this.downloadCSV and this.query
    const fetchData = useCallback((params, isCSV, shouldReload) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(shouldReload) setLoading(true)
        const query = await api.task_templates.search({ ...params, filter: { workflow: workflow._id }, isCSV })
        if(shouldReload) setLoading(false)

        if(!query.data) return setError(query.message)
        return resolve(query.data)
    }), [sizePerPage, workflow._id])

    // run query to get data from the server
    const query = useCallback(async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }, [fetchData])

    const onDeleteTask = useCallback(async () => {
        const deleted = await api.task_templates.delete(taskToDelete._id);
        if(deleted.success) {
            fetchData();
            toggleAlertBS(false, 'Task Successfully Removed')
        } else {
            toggleAlertBS(true, `Something's not right, please try again.`)
        }
    }, [fetchData, taskToDelete])
 
    const columns = [
        {
            dataField: "name",
            text: 'Name',
            formatter: (cell, row) => {
                if(row.name && row.name.length > 70) {
                    return row.name.slice(0, 70) + '...'
                }
                return (
                    <span className="cursor-pointer">
                        <ModalToggler
                            component={TasksEdit}
                            onSaved={fetchData}
                            workflow={workflow}
                            _id={row._id}
                        >
                            {row.name}
                        </ModalToggler>
                    </span>
                )
            },
        },
        {
            dataField: "description",
            text: 'Description',
            formatter: (cell, row) => {
                if(row.description && row.description.length > 30) {
                    return row.description.slice(0, 30) + '...'
                }
                return row.description
            },
        },
     
        {
            dataField: "type",
            text: 'Type',
            formatter: (cell, row) => {
                if(row.type ==='send_text') return 'Send A Text';
                if(row.type ==='send_email') return 'Send An Email';
                if(row.type ==='assign_location') return 'Assign A Location';
                if(row.type ==='assign_contact') return 'Assign A Contact';
                if(row.type ==='assign_role') return 'Assign A Role';
                if(row.type ==='create_event') return 'Schedule An Event';
                if(row.type ==='send_form') return 'Send Out A Form';
                if(row.type ==='send_document_for_signature') return 'Send A Document For Signature';
                if(row.type ==='upload') return 'Upload A Document';
                if(row.type ==='assign_tag') return 'Assign Tag If Answered "Yes"';
                if(row.type ==='select_tag') return 'Assign Tag Based On Preset Options';
                if(row.type ==='confirmation') return 'Confirm Something Is Done';
                if(row.type ==='create_outstanding_item') return 'Create An Outstanding Item';
                if(row.type ==='custom_field') return 'Custom Field';
                return row.type;
            }
        },
        {
            dataField: "automations",
            text: 'Automations',
            formatter: (cell, row) => {
                if(!row.automations || (row.automations && !row.automations.length)) return '';

                return row.automations.map((a, i) => (
                    i === 0 ? (
                        <ObjectFinder collection="automations" _id={a} />
                    ) : (
                        <span>,  <ObjectFinder collection="automations" _id={a} /></span>
                    )
                ))
            }
        },
        {
            dataField: "time_entry",
            text: 'Time Entry',
            formatter: (cell, row) => {
                if(!row.time_entry_template) return ''
                return <ObjectFinder collection="time_entry_templates" _id={row.time_entry_template} />
            }
        },
        {
            dataField: "created_at",
            text: 'Action',
            formatter: (cell, row) => {
                if(row.type ==='send_text') return <ObjectFinder collection="text_templates" _id={row.text_template} />
                if(row.type ==='send_email') <ObjectFinder collection="email_templates" _id={row.email_template} />
                if(row.type ==='assign_location') return <ObjectFinder collection="workflow_locations" _id={row.workflow_location} />
                if(row.type ==='assign_contact') return <ObjectFinder collection="workflow_contacts" _id={row.workflow_contact} />
                if(row.type ==='assign_role') return <ObjectFinder collection="workflow_roles" _id={row.workflow_role} />
                if(row.type ==='create_event') return <ObjectFinder collection="event_templates" _id={row.event_template} />
                if(row.type ==='send_form') return <ObjectFinder collection="forms" _id={row.form} />
                if(row.type ==='send_document_for_signature') return <ObjectFinder collection="signing_templates" _id={row.signing_template} />
                if(row.type ==='upload') return <ObjectFinder collection="matter_documents" _id={row.matter_document} />
                if(row.type ==='assign_tag') return <ObjectFinder collection="tags" _id={row.tag} />
                if(row.type ==='custom_field') return <ObjectFinder collection="custom_fields" _id={row.custom_field} />
                if(row.type ==='confirmation') return 'Confirm Via Checkbox'
                if(row.type ==='select_tag') return 'Assign Tag From Dropdown'
                return '-';
            }
        },
        {
            dataField: "priority",
            text: 'Priority',
        },
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right',},
            text: 'Actions',
            headerFormatter: () => {
                return <div id="archk-tutorial-contacts-actions">Actions</div>
            },
            formatter: (cell, row) => {
                return row.name === 'No Tasks Created Yet' ? '' :  (
                    <div className="text-right">
                         <TableIcons
                            icons={[
                                { 
                                    icon: 'fas fa-edit',
                                    color: 'success', 
                                    wrapper: ModalToggler, 
                                    component: TasksEdit,
                                    onSaved: fetchData,
                                    workflow: workflow,
                                    _id: row._id,
                                    // showModal: window.location.href.includes(row._id)
                                },
                                {  
                                    icon: 'fas fa-trash', 
                                    color: 'danger',
                                    onClick: () => setTaskToDelete(row)
                                },
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(error) return <ApiError err={error} />
    if(loading) return <div><Circle /></div>

    return (

        <>

        {editingTask ? (
            <TasksEdit 
                _id={editingTask}
                workflow={workflow}
                showModal={editingTask ? true : false}
                toggleModal={() => setEditingTask(null)}
            />
        ) : ''}

            <TextButtonRow 
                title={"All Tasks"}
                button={(
                    <button onClick={() => setTab('create')} className="btn btn-success"><i className="fas fa-plus mr-2 " /> Add Task</button>
                )}
            />

            <Card className="card-color card-primary table-fixed">
                <Table
                    tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                    placeholder="Search..."
                    query={query}
                    columns={columns}
                    data={tableData.data.length ? tableData.data : [{_id: 'test', name: 'No Tasks Created Yet'}]}
                    totalDocuments={tableData.total_documents}
                    sizePerPage={sizePerPage}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>
            
            <ConfirmationModal 
                showModal={taskToDelete ? true : false}
                toggleModal={() => setTaskToDelete(null)}
                title="Delete Task"
                body={(
                    <span>
                        Are you sure you wish to delete the task {taskToDelete ? taskToDelete.name : ''}?
                    </span>
                )}
                onConfirmation={onDeleteTask}
            />

        </>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(TasksAll);