import { Row, Col, Container } from 'reactstrap'

import RangeSelection from '../RangeSelection'

const MattersHeader = ({fields, sort, onSetSort, onRangeChange, runQuery, type}) => (
    <div className='bg-white archk-range-selection border-left'>
        <Container fluid className='py-3 px-0 '>
            <Row>

                <Col className="align-self-center">
                    <RangeSelection 
                        sort={sort}
                        onSetSort={onSetSort}
                        fields={fields}
                        onRangeChange={onRangeChange}
                        tableType={type}
                    />

                </Col>

                <div className="col-auto align-self-end " style={{width: 145}}>
                    <button onClick={runQuery} className='btn btn-lg btn-success btn-block'>
                        <i className="fas fa-chart-line" /> Run Report
                    </button>
                </div>

            </Row>
        </Container>
    </div>
)

export default MattersHeader