import IntuideskSection from "components/markup/layout/IntuideskSection";

import ExecutionLogs from "./ExecutionLogs";

const DevelopersView = ({match}) => (
    <IntuideskSection 
        title="Developers"
        section="developers"
        defaultRoute="execution_logs"
        match={match}
        pages={[
            { value: 'execution_logs', icon: 'fas fa-database', name: 'Execution Logs', Component: ExecutionLogs },
        ]}
    />
)

export default DevelopersView;