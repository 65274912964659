import store from 'store'

const hasPrivilege = (privilege) => {
    const state = store.getState();
    const PRIVILEGES = state.config.PRIVILEGES

    if(!PRIVILEGES[privilege]) {
        throw new Error(`The privilege ${privilege} does not exist.`);
    }

    return state.auth.viewing_user.privileges.includes(PRIVILEGES[privilege])

}

export default hasPrivilege