import React, { useState } from 'react';

const ButtonSave = ({onQuestionSaved, unsavedQuestion}) =>  {

    const [saved, setSaved] = useState(false);

    const onClick = () => {

        const saved = onQuestionSaved();

        if(saved) {
            setSaved(true);
            setTimeout(() => {
                setSaved(false)
            }, 1000)
        }

    }

    if(saved) return (
        <button disabled={true} className="btn btn-success">
            <i className="fas fa-check mr-2 " /> Saved!
        </button>
    )

    return (
        unsavedQuestion ? (
            <button className="btn btn-outline-success" onClick={onClick}>
                <i className="fas fa-save mr-2 " /> Save Question
            </button>
        ) : (
            <button className="btn btn-outline-success" disabled={true}>
                No Changes To Save
            </button>
        )
        
    )
};

export default React.memo(ButtonSave);

