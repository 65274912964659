import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from "reactstrap";


import { toggleAlertBS } from 'store/functions/system/system';
import formatText from 'utils/formatText';

import ReactSelect from 'components/functional/inputs/ReactSelect';
import ObjectFinder from 'components/system/Objects/Finder';
import SearchCollections from 'components/system/Search/Collections';

const SystemEventEdit = ({eventTemplate, setEventTemplate, selected_division, minute, hour, day}) => {

    const [task, setTask] = useState({ task_template: '', time_before: 0 })
    const [showTaskInfo, setShowTaskInfo] = useState(false)
    const [hideSearch, setHideSearch] = useState(false);



    const onTaskInputChange = useCallback(( field, value) => {
        const newTask = Object.assign({}, task)
        newTask[field] = value;
        setTask(newTask);
    }, [task])

    const onAddTask = useCallback(() => {
        const newEventTemplate = JSON.parse(JSON.stringify(eventTemplate))
        if(!task.task_template) return;
        if(!task.time_before && task.time_before !== 0) return;

        const tasks = newEventTemplate.tasks ? newEventTemplate.tasks : []
        tasks.push(task);

        if(tasks.length > 5) return toggleAlertBS('info', 'You may assign a maximum of 5 tasks per event template')
        newEventTemplate.tasks = tasks;
        setEventTemplate(newEventTemplate)
        setTask({ task_template: '', time_before: 0 })

        setHideSearch(true)
        setTimeout(() => {
            setHideSearch(false)
        }, [250])
    }, [eventTemplate, setEventTemplate, task])
   
    const onRemoveTask = useCallback((index) => {
        const newEventTemplate = JSON.parse(JSON.stringify(eventTemplate))

        const tasks = newEventTemplate.tasks
        tasks.splice(index, 1)
   
        newEventTemplate.tasks = tasks;
        setEventTemplate(newEventTemplate)
    }, [eventTemplate, setEventTemplate])
   
    return (
        <div>
            <Row>
                <Col lg={5}>
                    <div>

                        <h2>
                            Event Tasks 
                            <i onClick={() => setShowTaskInfo(!showTaskInfo)} className="fas fa-info-circle cursor-pointer ml-2 text-info" /> 
                        </h2>
                        {showTaskInfo ? (
                            <p className="text-sm ">Event tasks are used to automatically create and assign a task at the time an event is created. Note that updating the time of an event will subsequently update the due dates of any assigned tasks but will not create a new set of tasks for the event. Also note that because events are division based and not workflow based any assigned tasks will only be created should the task template match the workflow of the matter the event is created for.</p>
                        ) : ''}
                    

                        {hideSearch ? '' : (
                            <SearchCollections
                                collection="task_templates" 
                                title="Task Template"
                                value={task.task_template}
                                onChange={(obj) => onTaskInputChange('task_template', obj.value)}
                                filter={{
                                    division: selected_division._id
                                }}
                            /> 
                        )}

                        <ReactSelect 
                            formGroup={true}
                            title="When is this task due in relation to the event start time?*"
                            value={task.time_before}
                            onChange={(obj) => onTaskInputChange('time_before', obj.value)}
                            menuPlacement="top"
                            options={[
                                { value: -1, label: 'When the event is created' },
                                { value: 0, label: 'At the time of the event' },
                                { value: minute * 5, label: '5 Minutes Before' },
                                { value: minute * 10, label: '10 Minutes Before' },
                                { value: minute * 15, label: '15 Minutes Before' },
                                { value: minute * 30, label: '30 Minutes Before' },
                                { value: minute * 45, label: '45 Minutes Before' },
                                { value: hour * 1, label: '1 Hour Before' },
                                { value: hour * 1.5, label: '1 Hour 30 Minutes Before' },
                                { value: hour * 2, label: '2 Hours Before' },
                                { value: hour * 3, label: '3 Hours Before' },
                                { value: hour * 4, label: '4 Hours Before' },
                                { value: hour * 5, label: '5 Hours Before' },
                                { value: hour * 6, label: '6 Hours Before' },
                                { value: hour * 7, label: '7 Hours Before' },
                                { value: hour * 8, label: '8 Hours Before' },
                                { value: hour * 16, label: '16 Hours Before' },
                                { value: day * 1, label: '1 Day Before' },
                                { value: day * 2, label: '2 Days Before' },
                                { value: day * 3, label: '3 Days Before' },
                                { value: day * 5, label: '5 Days Before' },
                                { value: day * 7, label: '7 Days Before' },
                                { value: day * 10, label: '10 Days Before' },
                                { value: day * 14, label: '14 Days Before' },
                                { value: day * 21, label: '21 Days Before' },
                                { value: day * 28, label: '28 Days Before' },
                                { value: day * 30, label: '30 Days Before' },
                                { value: day * 45, label: '45 Days Before' },
                                { value: day * 60, label: '60 Days Before' },
                                { value: day * 75, label: '75 Days Before' },
                                { value: day * 90, label: '90 Days Before' },
                                
                                { value: minute * -5, label: '5 Minutes After' },
                                { value: minute * -10, label: '10 Minutes After' },
                                { value: minute * -15, label: '15 Minutes After' },
                                { value: minute * -30, label: '30 Minutes After' },
                                { value: minute * -45, label: '45 Minutes After' },
                                { value: hour * -1, label: '1 Hour After' },
                                { value: hour * -1.5, label: '1 Hour 30 Minutes After' },
                                { value: hour * -2, label: '2 Hours After' },
                                { value: hour * -3, label: '3 Hours After' },
                                { value: hour * -4, label: '4 Hours After' },
                                { value: hour * -5, label: '5 Hours After' },
                                { value: hour * -6, label: '6 Hours After' },
                                { value: hour * -7, label: '7 Hours After' },
                                { value: hour * -8, label: '8 Hours After' },
                                { value: hour * -16, label: '16 Hours After' },
                                { value: day * -1, label: '1 Day After' },
                                { value: day * -2, label: '2 Days After' },
                                { value: day * -3, label: '3 Days After' },
                                { value: day * -5, label: '5 Days After' },
                                { value: day * -7, label: '7 Days After' },
                                { value: day * -10, label: '10 Days After' },
                                { value: day * -14, label: '14 Days After' },
                                { value: day * -21, label: '21 Days After' },
                                { value: day * -28, label: '28 Days After' },
                                { value: day * -30, label: '30 Days After' },
                                { value: day * -45, label: '45 Days After' },
                                { value: day * -60, label: '60 Days After' },
                                { value: day * -75, label: '75 Days After' },
                                { value: day * -90, label: '90 Days After' },
                            ]}
                        />   

                        <div className="text-right" onClick={onAddTask}>
                            <button className="btn btn-outline-success"><i className="fas fa-plus mr-2 " /> Add Task</button>
                        </div>

                    </div>
                </Col>
                <Col lg={7}>
                    <div>
                        <h2>Assigned Tasks</h2>

                        <div className="table-responsive border">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Task</th>
                                        <th className="text-right">Time Before</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {eventTemplate.tasks && eventTemplate.tasks.length ? eventTemplate.tasks.map((task, i) => (
                                        <tr key={task.task_template + i}>
                                            <td className="text-capitalize">
                                                <ObjectFinder collection="task_templates" _id={task.task_template} />{' '}
                                            </td>
                                            <td className="text-right">
                                                {
                                                    task.time_before === -1 ? 'When the event is created' :
                                                    task.time_before === 0 ? 'At the time of the event' :
                                                    formatText(task.time_before).secondsToTime()}
                                                </td>
                                            <td className="text-right">
                                                <button onClick={() => onRemoveTask(i)} className="btn btn-danger">Remove</button>
                                            </td>
                                        </tr>
                                    )) : (
                                        <tr>
                                            <td><i className="fas fa-info-circle mr-2 text-info" /> No Tasks Selected</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </Col>
            </Row>
        </div>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SystemEventEdit);