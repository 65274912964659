import { Row, Col } from 'reactstrap'
import Advanced from './Advanced'
import Standard from './Standard'

import { useState } from 'react';

const Privileges = ({ user, setUser, onPrivilegeChange }) => {

    const [showAdvanced, setShowAdvanced] = useState(false)

    return (
        <div>
            <Row>
                <Col md={6}>
                    <h2>User Role</h2>
                </Col>
                <Col md={6} className='text-right'>
                    <label className="custom-toggle">
                        <input onChange={() => setShowAdvanced(!showAdvanced)} checked={showAdvanced} type="checkbox" />
                        <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Yes"
                        />
                    </label>    
                    <span style={{position: 'relative', top: -7, left: 5}}>Toggle Advanced Options</span>
                </Col>
            </Row>
            {showAdvanced ? (
                <Advanced 
                user={user}
                setUser={setUser} 
                onPrivilegeChange={onPrivilegeChange}
            /> 
            ) : (
                <Standard 
                user={user}
                setUser={setUser} 
            />
            )}
            {/* */}
           
        </div>
    )
}

export default Privileges;