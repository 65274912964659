import { connect } from 'react-redux';
import ReactSelect from 'components/functional/inputs/ReactSelect';
import { Row, Col } from 'reactstrap'
import { useCallback } from 'react';

import roles from '../roles'

const States = ({user, setUser}) => {

    const getValue = useCallback(() => {
        let isAdmin = true;
        let isManager = true;
        let isUser = true;
        
        roles.ADMIN.forEach(p => {
            if(!user.privileges.includes(p)) isAdmin = false;
        })
        if(isAdmin) return 'ADMIN'
    

        roles.MANAGER.forEach(p => {
            if(!user.privileges.includes(p)) isManager = false;
        })
        if(isManager) return 'MANAGER'
    

        roles.USER.forEach(p => {
            if(!user.privileges.includes(p)) isUser = false;
        })
        if(isUser) return 'USER'

    }, [user.privileges])

    const assignAll = useCallback((val) => {
        const newUser = JSON.parse(JSON.stringify(user))
        newUser.privileges = roles[val]
     
        setUser(newUser);
    }, [setUser, user])

    const value = getValue();

    console.log(value)

    return (
        <ReactSelect
            title="Select Privileges / Role"
            formGroup={true}
            placeholder={!value ? 'Custom Configuration Used' : ''}
            // description={description}
            onChange={(obj) => assignAll(obj.value)}
            options={[
                // { label: 'Custom Configuration', value: 'custom' }
                { label: 'User', value: 'USER' },
                { label: 'Manager', value: 'MANAGER' },
                { label: 'Admin', value: 'ADMIN' },
            ]}
            value={value}
        />    
    )
}


const mapStateToProps = state => {
    return {
        STATES: state.config.STATES,
    };
};

export default connect(mapStateToProps, '')(States);

