import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';

import ObjectFinder from 'components/system/Objects/Finder';


export default memo(({ id, data, isConnectable }) => {
  return (
    <>
        <Handle
            id="left"
            type="target"
            position={Position.Left}
            style={{ top: 15 }}
            isConnectable={isConnectable}
        />

        <div className="">
            <div className='node-header'>
                <i className="fa-solid fa-envelope-circle-check text-purple mr-2 mr-2" /> Send Notification
            </div>
           
            <div className='node-body bg-secondary '>
                <div className='node-item text-capitalize text-muted'>
                    {data.subject ? <b>Subject: {data.subject}</b> : <span className='text-danger'>Notification Not Set</span>}
                </div>

                {data.user ? (
                    <div className='node-item text-capitalize'><ObjectFinder collection="users" _id={data.user} /></div>
                ) : ''}

                {data.workflow_roles ? data.workflow_roles.map((role, i) => (
                    <div key={i} className='node-item text-capitalize'><ObjectFinder collection="workflow_roles" _id={role.workflow_role} /></div>
                )) : ''}

            </div>

            <div className='node-footer'>
                <div><small>Execution ID:{id}</small></div>
            </div>

           

        </div>

        <Handle
            id="right"
            type="source"
            position={Position.Right}
            style={{ top: 10 }}
            isConnectable={isConnectable}
        />
        </>
  );
});