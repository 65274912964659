import { useEffect, useState, memo } from 'react';

const TableStandardHeader = ({ columns }) => {

    const [header, setHeader] = useState([]);

    useEffect(() => {
        const _header = columns.map((c, i) => {

            return (
                <th key={i} style={c.headerStyle ? c.headerStyle : {}}>
                    {c.headerFormatter ? c.headerFormatter() : c.text}
                </th>
            )
        })
        setHeader(_header)
    }, [columns])
  
    return (
        <thead>
            <tr>
                {header}
            </tr>
        </thead>
    )

}

export default memo(TableStandardHeader)