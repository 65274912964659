import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";

const Box = ({onClick, color, title, icon, category}) => (
    <Col md={4}>
        <Card onClick={onClick} className={`card-color card-primary text-center bg-gradient-${color} text-white cursor-pointer`}>
            <CardHeader className="bg-transparent">
                <CardTitle className="mb-0">{category}</CardTitle>
            </CardHeader>
            <CardBody className="py-4 bg-transparent">
                <h2 className="display-1 text-white"><i className={icon} /></h2>
                <p className="mb-0">{title}</p>
            </CardBody>
        </Card>
    </Col>
)

const WorkflowEditNavigation = ({onSetTab}) => (
    <div>
        <Row>
            <Box 
                onClick={() => onSetTab('Steps')}
                color="warning"
                title="Steps"
                icon="fas fa-route"
                category="Flow"
            />
            <Box 
                onClick={() => onSetTab('Locations')}
                color="success"
                title="Locations"
                icon="fas fa-home"
                category="Customize"
            />
            <Box 
                onClick={() => onSetTab('Links')}
                color="success"
                title="Links"
                icon="fas fa-link"
                category="Customize"
            />
            <Box 
                onClick={() => onSetTab('Tasks')}
                color="success"
                title="Tasks"
                icon="fa-solid fa-list-check"
                category="Customize"
            />
            <Box 
                onClick={() => onSetTab('Contacts')}
                color="success"
                title="Contacts"
                icon="fas fa-user-plus"
                category="Customize"
            />
            <Box 
                onClick={() => onSetTab('Roles')}
                color="success"
                title="Roles"
                icon="fas fa-users"
                category="Customize"
            />
            {/* <Box 
                onClick={() => onSetTab('Folders')}
                color="success"
                title="Folders"
                icon="fas fa-file"
                category="Customize"
            /> */}
            <Box 
                onClick={() => onSetTab('Categories')}
                color="purple"
                title="Categories"
                icon="fas fa-tags"
                category="Customize"
            />
            <Box 
                onClick={() => onSetTab('Dispositions')}
                color="purple"
                title="Dispositions"
                icon="fas fa-smile"
                category="Customize"
            />

            <Box 
                onClick={() => onSetTab('Settings')}
                color="info"
                title="Settings"
                icon="fas fa-cogs"
                category="Workflow"
            />
           

        </Row>
       

    </div>
)

export default WorkflowEditNavigation;