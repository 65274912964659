import { connect } from 'react-redux';
import ReactSelect from 'components/functional/inputs/ReactSelect';

const SelectColor = ({ value, onChange, COLORS, title, menuPlacement }) => {

    return (
        <ReactSelect
            menuPlacement={menuPlacement}
            title={title ? title : 'Select Color'}
            formGroup={true}
            placeholder=""
            onChange={(obj) => onChange(obj.value)}
            options={Object.keys(COLORS).map(k => {
                return { 
                    value: COLORS[k], 
                    label: (
                        <span className='text-capitalize'>
                            <span className='mr-4' style={{background: COLORS[k], position: 'relative', top: 1, borderRadius: 6, display: 'inline-block', width: 30, height: 10}}></span>
                            {k} 
                        </span> 
                    )
                }
            })}
            value={value}
        />    
    )
}


const mapStateToProps = state => {
    return {
        COLORS: state.config.COLORS,
    };
};

export default connect(mapStateToProps, '')(SelectColor);