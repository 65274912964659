import IntuideskSection from "components/markup/layout/IntuideskSection";

import Overview from './Overview'
import Predictions from './Predictions'
import LeadSources from './LeadSources'
import SourceAnalysis from './SourceAnalysis'
import LeadSourceCategories from './LeadSourceCategories'
import Leads from './Leads'

const MarketingView = ({match}) => (
    <IntuideskSection 
        title="Marketing"
        section="marketing"
        defaultRoute="days_30"
        match={match}
        pages={[
            // { value: 'days_30', icon: 'fas fa-chart-line', name: '30 Days', Component: Overview, extraProps: {range: 30} },
            // { value: 'days_90', icon: 'fas fa-chart-line', name: '90 Days', Component: Overview, extraProps: {range: 90} },
            // { value: 'days_180', icon: 'fas fa-chart-line', name: '180 Days', Component: Overview, extraProps: {range: 180} },
            // { value: 'days_365', icon: 'fas fa-chart-line', name: '365 Days', Component: Overview, extraProps: {range: 365} },
            { value: 'lead_sources', icon: 'fa-solid fa-chart-simple', name: 'Lead Sources', Component: LeadSources },
            { value: 'leads', icon: 'fas fa-users', name: 'Leads', Component: Leads },
            { value: 'lead_source_categories', icon: 'fa-solid fa-chart-simple', name: 'Lead Sources', Component: LeadSourceCategories, hidden: true },
            // { value: 'analysis', icon: 'fa-solid fa-chart-pie', name: 'Source Analysis', Component: SourceAnalysis },
            // { value: 'predictions', icon: 'fa-solid fa-comments-dollar', name: 'Predictions', Component: Predictions },
        ]}
    />
)

export default MarketingView;