const Privilege = ({title, user, privilege, onPrivilegeChange, description}) => (
    <div>
        <label className="custom-toggle">
            <input onChange={() => onPrivilegeChange(privilege)} checked={user.privileges.includes(privilege)} type="checkbox" />
            <span
                className="custom-toggle-slider rounded-circle"
                data-label-off="No"
                data-label-on="Yes"
            />
        </label>    
        <span style={{position: 'relative', top: -7, left: 5}}>{title}</span>
        {description ? (
            <p className="text-sm mt--2"><small>{description}</small></p>
        ) : null}
    </div>
)

export default Privilege;