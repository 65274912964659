import FormsAll from './All'

import IntuideskSectionWrapper from 'components/markup/layout/IntuideskSectionWrapper'

const SystemForms = ({match, section}) => (
    <IntuideskSectionWrapper 
        title="Forms"
        match={match}
        section={section}
        innerSection={'forms'}
        All={FormsAll}
        editLink="/editor/forms/new"
    />
)

export default SystemForms;