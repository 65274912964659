import * as actionTypes from '../../actions';
import store from '../../index';
import api from 'api';

export const setUsers = async() => new Promise (async resolve => {

    const users = await api.users.find('?')
    if(!users.success) return resolve(null);;
    
    store.dispatch({ type: actionTypes.SET_USERS, payload: users.data.documents });
    resolve({success: true, data: users.data.documents});

})