import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, FormGroup, Input, Row } from 'reactstrap';

import ReactSelect from 'components/functional/inputs/ReactSelect';
import SearchCollections from 'components/system/Search/Collections';

import ToContacts from './ToContacts';
import CCRoles from './CCRoles';
import WorkflowRoles from './WorkflowRoles';

import ObjectFinder from 'components/system/Objects/Finder';

const AutomationsEditEmail = ({
    selected_division,
    toContacts,
    setToContacts,
    roles,
    setRoles,
    ccRoles,
    setCCRoles,
    tasks,
    id,
    replyToEmail,
    setReplyToEmail,
    emailTemplate,
    setEmailTemplate,
    emailSender,
    setEmailSender,
    replyEmails,
    setReplyEmails
}) => {

    useEffect(() => {
        let _order = []
        let task = tasks.find(t => !t.moves_to);

        const createOrder = () => {
            if(!task) return;
            _order.unshift(task)
            task = tasks.find(t => t.moves_to === task._id);
            if(!task) {
                let previousEmails = []
                const taskIndex = _order.findIndex(t => t._id === id)

                if(taskIndex !== -1 && taskIndex >0) {

                    let pastTasks = _order.slice(0, taskIndex)
                    previousEmails = pastTasks.filter(t => t.type === 'email')
                }

                return setReplyEmails(previousEmails)
            }
            createOrder()
        }

        createOrder()

    }, [tasks, id, setReplyEmails])

    return (
        <div>
            <SearchCollections
                collection="email_templates"
                title="Email Template"
                value={emailTemplate}
                onChange={(obj) => setEmailTemplate(obj.value)}
                filter={{
                    division: selected_division._id
                }}
            />

            {replyEmails.length ? (
                <ReactSelect 
                    formGroup={true}
                    title="Reply To Email"
                    description="If set, the template email will be sent as a reply to the email thread of the task specified. Make sure to CC all sending roles on the original email that got sent out or the email may fail to send. This will only work if the sender is a party on the case and not an 'Email Sender'"
                    value={replyToEmail}
                    onChange={(obj) => setReplyToEmail(obj.value)}
                    options={[{label: '**SEND AS NEW EMAIL', value: null}].concat(replyEmails.map(e => {
                        return { label: <ObjectFinder collection="email_templates" _id={e.email_template} />, value: e._id }
                    }))}
                />
            ) : (
                <FormGroup>
                    <label className='form-control-label'>Reply To Email</label>
                    <p className='text-sm mb-0'>***There are no previous emails in this sequence to reply to.</p>
                    <Input
                        type="text"
                        placeholder='Select...'
                        disabled={true}
                    />
                </FormGroup>
            )}

            <SearchCollections
                collection="email_senders"
                title="Email Sender"
                description="An email sender is required as this will be used in case of an unlinked email address or if we do not have a found user to send out the automations email from. All mass marketing emails should default to use the 'Email Sender' below and not a workflow role to avoid sending spam from a real business address"
                value={emailSender}
                onChange={(obj) => setEmailSender(obj.value)}
                filter={{
                    division: selected_division._id
                }}
            />

            <Row>
                <Col lg={4}>
                    <WorkflowRoles 
                        roles={roles}
                        setRoles={setRoles}
                    />
                </Col>
                <Col lg={4}>
                    <CCRoles 
                        ccRoles={ccRoles}
                        setCCRoles={setCCRoles}
                    />
                </Col>
                <Col lg={4}>
                    <ToContacts 
                        toContacts={toContacts}
                        setToContacts={setToContacts}
                    />
                </Col>
            </Row>

        </div>
    )

}


const mapStateToProps = state => {
    return {
        selected_division: state.state.selected_division,
    };
};
export default connect(mapStateToProps, '')(AutomationsEditEmail);;