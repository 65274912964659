import { connect } from 'react-redux'
import IntuideskSection from "components/markup/layout/IntuideskSection";


import AIAssistants from './AIAssistants'
import BackgroundProcesses from './BackgroundProcesses'


const AIView = ({match, selected_division}) => (
    <IntuideskSection 
        title={`AI ${selected_division ? ' - ' + selected_division.name : ''}`}
        section="ai"
        defaultRoute="ai_assistants"
        match={match}
        pages={[
            { value: 'ai_assistants', icon: 'fas fa-users', name: 'Assistants', Component: AIAssistants },
            { value: 'background_processes', icon: 'fas fa-database', name: 'Background Processes', Component: BackgroundProcesses },
        ]}
    />
)


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(AIView);