import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row } from "reactstrap";


import BarLine from 'components/charts/BarLine';
import PieArea from 'components/charts/PieArea';

import Table from './Table';

const LocationsAll = ({setTab, selected_division}) => {

    

    return (

        <>


        <Row>

            <Col md={6}>
                <PieArea 
                    type="pie"
                    title="Clock In Time"
                    labels={['On Time', '< 15 Min Late  ', '15+ Min Late']}
                    dataset={[12, 19, 3]}
                />      
            </Col>   
            <Col md={6}>

                <PieArea 
                    type="pie"
                    title="Clock Out Time"
                    labels={['Early', 'On Time', '< 15 Min Late  ', '15+ Min Late']}
                    dataset={[80, 18, 2]}
                />      
              
            </Col>
        </Row>

        <Row>
            <Col md={4}>
                <Card className='text-center'>
                    <CardHeader>
                        <CardTitle className="mb-0">Estimated Payroll</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <h2 className='display-2 mb-0'>$38,391 <small><small className='text-muted pl-4'>+12%</small></small></h2>
                    </CardBody>
                    <CardFooter>
                        12 Weeks Ago: <b className='text-dark font-weight-bold '>$29,583</b>
                    </CardFooter>
                </Card>
            </Col>
            <Col md={4}>
                <Card className='text-center'>
                    <CardHeader>
                        <CardTitle className="mb-0">Working</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <h2 className='display-2 mb-0'>19 <small><small className='text-muted pl-4'>87% Capacity</small></small></h2>
                    </CardBody>
                    <CardFooter>
                        12 Weeks Ago: <b className='text-dark font-weight-bold '>8</b>
                    </CardFooter>
                </Card>
            </Col>
            <Col md={4}>
                <Card className='text-center'>
                    <CardHeader>
                        <CardTitle className="mb-0">Out / Sick</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <h2 className='display-2 mb-0'>2 <small><small className='text-muted pl-4'>13% Unused</small></small></h2>
                    </CardBody>
                    <CardFooter>
                        12 Weeks Ago: <b className='text-dark font-weight-bold '>8</b>
                    </CardFooter>
                </Card>
            </Col>
            <Col md={4}>
                <Card className='text-center'>
                    <CardHeader>
                        <CardTitle className="mb-0">Power/Tech Issues</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <h2 className='display-2 mb-0'>2 <small><small className='text-muted pl-4'>13%</small></small></h2>
                    </CardBody>
                    <CardFooter>
                        12 Weeks Ago: <b className='text-dark font-weight-bold '>8</b>
                    </CardFooter>
                </Card>
            </Col>
            <Col md={4}>
                <Card className='text-center'>
                    <CardHeader>
                        <CardTitle className="mb-0">Late Clock In</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <h2 className='display-2 mb-0'>27 <small><small className='text-muted pl-4'><i className="fas fa-arrow-right" /> 27%</small></small></h2>
                    </CardBody>
                    <CardFooter>
                        12 Weeks Ago: <b className='text-dark font-weight-bold '>8</b>
                    </CardFooter>
                </Card>
            </Col>
            <Col md={4}>
                <Card className='text-center'>
                    <CardHeader>
                        <CardTitle className="mb-0">Late Clock Out</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <h2 className='display-2 mb-0'>27 <small><small className='text-muted pl-4'><i className="fas fa-arrow-right" /> 3%</small></small></h2>
                    </CardBody>
                    <CardFooter>
                        12 Weeks Ago: <b className='text-dark font-weight-bold '>8</b>
                    </CardFooter>
                </Card>
            </Col>
            <Col md={4}>
                <Card className='text-center'>
                    <CardHeader>
                        <CardTitle className="mb-0">Has Overdue Tasks</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <h2 className='display-2 mb-0'>18 <small><small className='text-muted pl-4'>+87%</small></small></h2>
                    </CardBody>
                    <CardFooter>
                        12 Weeks Ago: <b className='text-dark font-weight-bold '>19</b>
                    </CardFooter>
                </Card>
            </Col>
            <Col md={4}>
                <Card className='text-center'>
                    <CardHeader>
                        <CardTitle className="mb-0">Has Overdue Events</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <h2 className='display-2 mb-0'>18 <small><small className='text-muted pl-4'>+87%</small></small></h2>
                    </CardBody>
                    <CardFooter>
                        12 Weeks Ago: <b className='text-dark font-weight-bold '>19</b>
                    </CardFooter>
                </Card>
            </Col>
            <Col md={4}>
                <Card className='text-center'>
                    <CardHeader>
                        <CardTitle className="mb-0">Has Unread Emails</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <h2 className='display-2 mb-0'>18 <small><small className='text-muted pl-4'>+87%</small></small></h2>
                    </CardBody>
                    <CardFooter>
                        12 Weeks Ago: <b className='text-dark font-weight-bold '>19</b>
                    </CardFooter>
                </Card>
            </Col>
            <Col md={4}>
                <Card className='text-center'>
                    <CardHeader>
                        <CardTitle className="mb-0">Has Unread Texts</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <h2 className='display-2 mb-0'>18 <small><small className='text-muted pl-4'>+87%</small></small></h2>
                    </CardBody>
                    <CardFooter>
                        12 Weeks Ago: <b className='text-dark font-weight-bold '>19</b>
                    </CardFooter>
                </Card>
            </Col>
            <Col md={4}>
                <Card className='text-center'>
                    <CardHeader>
                        <CardTitle className="mb-0">Has Not Made A Call</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <h2 className='display-2 mb-0'>18 <small><small className='text-muted pl-4'>+87%</small></small></h2>
                    </CardBody>
                    <CardFooter>
                        12 Weeks Ago: <b className='text-dark font-weight-bold '>19</b>
                    </CardFooter>
                </Card>
            </Col>
            <Col md={4}>
                <Card className='text-center'>
                    <CardHeader>
                        <CardTitle className="mb-0">Missed A Call While Not On Call</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <h2 className='display-2 mb-0'>18 <small><small className='text-muted pl-4'>+87%</small></small></h2>
                    </CardBody>
                    <CardFooter>
                        12 Weeks Ago: <b className='text-dark font-weight-bold '>19</b>
                    </CardFooter>
                </Card>
            </Col>
        </Row>


        <Table />


        </>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(LocationsAll);