import * as actionTypes from '../../actions';
import store from 'store';
import Cookies from 'universal-cookie';
const cookies = new Cookies();


export const setSelectedDivision = (division) => {
    const division_id = division ? division : cookies.get('archk-selected-division')
    const divisions = store.getState().divisions;
    const foundDivision = divisions.find(d => d._id === division_id);

    if(foundDivision) cookies.set('archk-selected-division', foundDivision._id, { path: '/' });

    store.dispatch({
        type: actionTypes.SET_STATE,
        payload: { selected_division: foundDivision ? foundDivision : divisions[0] ? divisions[0] : {} },
    });

}
