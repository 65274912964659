import FormsEdit    from '../pages/forms/Edit/index';
import Signing    from '../pages/signing/Edit/index';
import Workflows    from '../pages/worklows/Edit/index';
import Automations    from '../pages/automations';
import CustomViews    from '../pages/custom_views';
import BackgroundProcesses    from '../pages/background_processes';

const routes = () =>  [

    {
        path: "/forms/:form_id",
        component: FormsEdit,
    },
    {
        path: "/signing/:template_id",
        component: Signing,
    },
    {
        path: "/workflows/:template_id",
        component: Workflows,
    },
    {
        path: "/automations/:automation_id",
        component: Automations,
    },
   
    {
        path: "/background_processes/:_id",
        component: BackgroundProcesses,
    },
   
    {
        path: "/custom_views/:custom_view_id",
        component: CustomViews,
    },
   
]

export default routes;
