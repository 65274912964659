import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Badge, FormGroup, Input } from "reactstrap";

import api from 'api';

import Modalify from 'components/functional/modals/Modalify';
import Circle from 'components/markup/loading/Circle';
import SearchCollections from 'components/system/Search/Collections';

import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';

import ObjectFinder from 'components/system/Objects/Finder';

const baseState = { users: [], workflow_steps: [] }

const PipelinesView = ({_id, onSaved, showModal, toggleModal, selected_division}) => {

    const [workflow, setWorkflow] = useState(null)
    const [pipeline, setPipeline] = useState(null)
    const [err, setErr] = useState(null)
    const [loading, setLoading] = useState(false)
    const [redirect, setRedirect] = useState(null)

    const fetchData = useCallback(async () => {
        if(!_id || _id === 'create') return setPipeline(baseState);
        const data = await api.pipelines.findById(_id)
        if(!data.data) return setErr(true);
        setPipeline(data.data)
    }, [_id])

    const onInputChange = useCallback(( field, value) => {
        const newPipeline = JSON.parse(JSON.stringify(pipeline))
        newPipeline[field] = value;
        setPipeline(newPipeline);
    }, [pipeline])

    const onSave = useCallback(async () => {

        setErr(false)
        let newState = JSON.parse(JSON.stringify(pipeline));

        if(!newState.name) return setErr([`An pipeline must have a unique name.`])
        if(!newState.users.length) return setErr([`An pipeline must have at least 1 user.`])
        if(!newState.workflow_steps.length) return setErr([`An pipeline must have at least 1 workflow step.`])
        
        setPipeline(newState)

        let saved;

        // toggle loaders and update/create the data
        toggleStandardLoader(true)
        if(newState._id) {
            saved = await api.pipelines.update(newState._id, newState, true);
        } else {
            saved = await api.pipelines.create({...newState, division: selected_division._id}, true);
        }
        toggleStandardLoader(false)

        // show error messages if unsuccessful
        if(saved.success) {
            toggleAlertBS(false, "Email Template Saved Successfully")
            fetchData();
            setLoading(true)
            setTimeout(() => setLoading(false), 500)
            if(toggleModal) toggleModal();
            if(onSaved) onSaved(saved.data)
        } else {
            return setErr(saved.message);
        }
    
    }, [pipeline, fetchData, toggleModal, onSaved, selected_division._id])

    useEffect(() => {
        if(toggleModal) {
            if(showModal) {
                fetchData()
            } else {
                setErr(null)
                setPipeline(baseState)
            }
        } else {
            fetchData()
        }
    }, [showModal, toggleModal, fetchData])

    const onAddItem = (field, _id) => {
        const _pipeline = JSON.parse(JSON.stringify(pipeline))
        let items = _pipeline[field] ? _pipeline[field] : []
        if(!items.includes(_id)) items.push(_id)
        _pipeline[field] = items
        setPipeline(_pipeline)
    }
    
    const onRemoveItem = (field, _id) => {
        const _pipeline = JSON.parse(JSON.stringify(pipeline))
        let items = _pipeline[field] ? _pipeline[field] : []
        items = items.filter(i => i !== _id)
        _pipeline[field] = items
        setPipeline(_pipeline)
    }
    
    useEffect(() => {
        if(!selected_division._id) {
            toggleAlertBS('info', 'A division must be created for editing a matter doc.')
            setRedirect('/system/divisions/create')
        }
    }, [selected_division._id])

    if(redirect) return <Redirect to={redirect} />

    return (
        <Modalify 
            showModal={showModal}
            toggleModal={toggleModal}
            modalTitle={pipeline && pipeline._id ? "Update Email Template" : "Create Email Template"}
            err={err}
            footer={(
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            )}
            sections={!pipeline ? [] : loading ? <Circle /> :  [
                (
                    <div>

                        <h2>Pipeline Info</h2>
                        <FormGroup>
                            <label className="form-control-label">Name*</label>
                            <Input 
                                type="text"
                                value={pipeline.name || ''}
                                onChange={e => onInputChange('name', e.target.value)}
                            />
                        </FormGroup>
                    </div>
                ),
                (
                    <div>

                        <h2><i className="fas fa-users mr-2 text-info" /> Pipeline Users</h2>

                        <SearchCollections
                            hideSelected={true}
                            collection="users" 
                            description="Only users selected below will able to view this pipeline configuration on their dashboard"
                            title="Search Users"
                            value={null}
                            onChange={(obj) => onAddItem('users', obj.value)}
                            filter={{
                            }}
                        /> 

                        {pipeline.users && pipeline.users.length ? pipeline.users.map(a => (
                            <Badge key={a._id ? a._id : a} color="info" className="mr-3 my-2">{<ObjectFinder collection="users" _id={a} />} <i onClick={() => onRemoveItem('users', a)} className="fas fa-trash pl-2 cursor-pointer" /></Badge>
                        )) : ''}
                        
                    </div>
                ),
                (
                    <div>

                        <h2><i className="fas fa-shoe-prints mr-2 text-success" /> Pipeline Steps</h2>

                        <SearchCollections
                            collection="workflows" 
                            title="Select Workflow"
                            value={null}
                            onChange={(obj) => {
                                setWorkflow()
                                setTimeout(() => {
                                    setWorkflow(obj.value)
                                }, 250)
                            }}
                            filter={{
                                division: selected_division._id
                            }}
                        /> 

                        {workflow ? (
                            <div>
                                <SearchCollections
                                    hideSelected={true}
                                    collection="workflow_steps" 
                                    title="Search Steps"
                                    value={null}
                                    onChange={(obj) => onAddItem('workflow_steps', obj.value)}
                                    filter={{
                                        workflow
                                    }}
                                /> 

                            </div>
                        ) : ''}

                        {pipeline.workflow_steps && pipeline.workflow_steps.length ? (
                            <div>
                                {pipeline.workflow_steps.map(a => (
                                    <Badge key={a._id ? a._id : a} color="success" className="mr-3 my-2">
                                        <ObjectFinder collection="workflow_steps" _id={a} /> {' '}
                                        <i onClick={() => onRemoveItem('workflow_steps', a)} className="fas fa-trash pl-2 cursor-pointer" />
                                    </Badge>
                                ))}
                                <p className='mb-0 text-sm mt-3'><i className="fas fa-arrow-up mr-2 text-info" /> Pipeline Steps Will Show Up In The Order They Are Selected Here</p>
                            </div>
                        ) : ''}
                        
                    </div>
                ),               
            ]}
        />
    )

}

const mapStateToProps = state => {
	return {
        selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(PipelinesView);