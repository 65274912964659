import { FormGroup, Input } from 'reactstrap';

const SendDocumentTab2 = ({onSetRequiredAnswers, requiredInternalAnswers, requiredAnswers}) => (
    <div className="modal-body">
        {!requiredInternalAnswers.length ? (
            <p className="my-4 text-center"><i className="fas fa-check text-success mr-2 " /><br /> This document does not have any custom fields to fill out. <br />Proceed to the next step.</p>
        ) : (
            <div>
                <p className="text-sm">The following answers must be filled out before sending this document out for signature:</p>
                {requiredInternalAnswers.map((marker, i) => (
                    <div key={i}>
                        <FormGroup>
                            <label className="form-control-label">{marker.name}</label>
                            <Input 
                                type="text"
                                value={requiredAnswers[marker._id] || ''}
                                onChange={(e) => onSetRequiredAnswers(marker._id, e.target.value) }
                            />
                        </FormGroup>
                    </div>
                ))}
            </div>
        )}
    </div>
)


export default SendDocumentTab2;
