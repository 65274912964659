import { memo } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { useDrag, useDrop } from 'react-dnd';

import { toggleAlertBS } from 'store/functions/system/system';

const Question = memo(function Question({ question, moveQuestion, newParentTree, findQuestion, children, acceptNested, onInjectQuestion, onSelect, onDragEnd, unsavedQuestion, i}) {

    const originalIndex = findQuestion(question.id).index;

    const [{ isDragging }, drag] = useDrag({
        type: acceptNested,
        accept: acceptNested,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        item: () => {
            // onDragStart()
            if(unsavedQuestion) {
                return toggleAlertBS('info', 'The question you have selected has unsaved changes. Please save current question you are on before changing the overall question order.')
            }
            return  { id: question.id, originalIndex, type: acceptNested }
        },
        end: (item, monitor) => {
            const { id: droppedId, originalIndex } = item;
            const didDrop = monitor.didDrop();

            onDragEnd()
            if (!didDrop) {
                moveQuestion(droppedId, originalIndex);
            }
        },
    }, [question.id, originalIndex, moveQuestion]);
    
    const [, drop] = useDrop({
        accept: acceptNested,
        canDrop: () => false,
        hover({ id: draggedId }) {
            if (draggedId !== question.id) {
                const { index: overIndex } = findQuestion(question.id);
                moveQuestion(draggedId, overIndex);
            }
        },
    }, [findQuestion, moveQuestion]);

    let className = 'question';
    if(isDragging) className += ' question-drag';
    if(question.error) className += ' question-error';
    
    return (

        <>

        <div  ref={(node) => drag(drop(node))} data-id={question.id} className={className} >
            <div className="question-inner-wrapper" onClick={onSelect}>
                {i === 0 ? <i className="indent-icon fas fa-reply fa-rotate-180" /> : null}

                <p className="text-sm mb-0">
                    <span style={{wordBreak: 'break-word', maxWidth: 'calc(100% - 30px)', display: 'inline-block'}}>
                        Q{i+1} -{' '}
                        {question.name ? 
                            question.name.length > 100 ? question.name.slice(0,100) + '...' : question.name
                        : 'New Question'}
                    </span>
                </p>
            </div>

            <i id={`archk-add-question-${question.id}`} className="fas fa-plus float-right text-success font-weight-bold edit-icon" onClick={() => onInjectQuestion(question, newParentTree)} />

            <UncontrolledTooltip
                delay={750}
                trigger="hover focus"
                target={`archk-add-question-${question.id}`}
                placement="top"
            >
                Insert Question After
            </UncontrolledTooltip>

		</div>
        {children}
        </>
    );
});


export default Question