import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Input } from 'reactstrap';

import api from 'api';
import { toast } from 'react-toastify';

import CopyText from 'components/functional/text/Copy';

import ConfirmationModal from 'components/functional/modals/Confirmation';


const WorkflowEditRolesRow = ({role, onDelete}) => {

    const [showDelete, setShowDelete] = useState(false)
    const [name, setName] = useState(role.name)

    const onSave = useCallback(async (field, value) => {
        const data = await api.workflow_roles.update(role._id, { [field]: value })
        if(!data.success) toast.error(`Could not save workflow role, please try again`)
    }, [role._id])
    
    return (
        <React.Fragment key={role._id}>

            <tr>
                <td>
                    <Input 
                        value={name}
                        onChange={e => setName(e.target.value)}
                        onBlur={() => onSave('name', name)}
                    />
                </td>
                <td><CopyText text={role._id} /></td>

                <td className="text-right">
                    <button className="btn btn-danger" onClick={() => setShowDelete(true)}>
                        <i className="fas fa-trash mr-2 " /> Remove Role
                    </button>
                </td>
            </tr>

            <ConfirmationModal 
                showModal={showDelete}
                toggleModal={() => setShowDelete(false)}
                title={"Delete Workflow Role"}
                onConfirmation={() => onDelete(role)}
                body={(
                    <span>
                        Are you sure you wish to delete the workflow role <b className='text-underline text-dark'>"{role.name}"</b>? This cannot be undone and any email emails, text, etc. that use their field map ID will return empty values potentially creating breaking changes.
                    </span>
                )}
            />
  

        </React.Fragment>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(WorkflowEditRolesRow);