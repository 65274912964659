import { Input, FormGroup, Row, Col } from "reactstrap";
import { useCallback, useState, useEffect } from 'react';

import api from 'api'

import Modalify from 'components/functional/modals/Modalify'

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';
import { connect } from 'react-redux';

import TimeZone from 'components/settings/TimeZone'
import DayAndTimeRange from 'components/settings/DayAndTimeRange'
import InputStates from 'components/markup/inputs/States'
import ReactSelect from 'components/functional/inputs/ReactSelect';
import SearchCollections from "components/system/Search/Collections";

const baseState = {
    affiliate_value_type : 'invoice',
    affiliate_value_price : 0,
    email_settings: {
        include_address: true,
        include_email: true,
        include_logo: true,
        include_name: true,
        include_phone: true,
        include_title: true,
        disclaimer: '',
    },
    times_open: {
        mon: { start: 32400, end: 61200 },
        tue: { start: 32400, end: 61200 },
        wed: { start: 32400, end: 61200 },
        thu: { start: 32400, end: 61200 },
        fri: { start: 32400, end: 61200 },
        sat: { start: 0, end: 0 },
        sun: { start: 0, end: 0 },
    }
}

const SystemDivisionEdit = ({_id, selected_division, onSaved, showModal, toggleModal}) => {

    const [day] = useState(86400)
    const [division, setDivision] = useState('')
    const [err, setErr] = useState(null)

    const fetchData = useCallback(async () => {
        if(!_id || _id === 'create') return setDivision(baseState);
        const user = await api.divisions.findById(_id)
        if(!user.data) return setErr(true);
        setDivision(user.data)
    }, [_id])

    const onInputChange = useCallback(( field, value) => {
        const newDivision = Object.assign({}, division)
        newDivision[field] = value;
        setDivision(newDivision);
    }, [division])
    
    const onEmailSettingChange = useCallback(( field, value) => {
        const newDivision = Object.assign({}, division)
        newDivision.email_settings[field] = value;
        setDivision(newDivision);
    }, [division])

    const onSave = useCallback(async () => {
        setErr(false)
        let newState = Object.assign({}, division);

        if(!newState.name) {
            return setErr([`A division must have a name.`])
        }
        
        setDivision(newState)

        const affiliate_value_price = newState.affiliate_value_price ? parseFloat(newState.affiliate_value_price) : 0

        let saved;

        // toggle loaders and update/create the user
        toggleStandardLoader(true)
        if(newState._id) {
            saved = await api.divisions.update(newState._id, { ...newState, affiliate_value_price }, true);
        } else {
            saved = await api.divisions.create({ ...newState, affiliate_value_price }, true);
        }
        toggleStandardLoader(false)

        // show error messages if unsuccessful
        if(saved.success) {
            toggleAlertBS(false, "Division Saved Successfully")
            fetchData();
            if(toggleModal) toggleModal();
            if(onSaved) onSaved(saved.data)
        } else {
            return setErr(saved.message);
        }
    
    }, [division, fetchData, toggleModal, onSaved])

    useEffect(() => {
        if(toggleModal) {
            if(showModal) {
                fetchData()
            } else {
                setErr(null)
                setDivision(baseState)
            }
        } else {
            fetchData()
        }
    }, [showModal, toggleModal, fetchData])

    return (
        <Modalify 
            showModal={showModal}
            toggleModal={toggleModal}
            modalTitle={division && division._id ? "Update Division" : "Create Division"}
            err={err}
            footer={(
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            )}
            sections={!division ? [] :  [
                (
                    <div>
                        <h2>Divison Names</h2>

                        <FormGroup>
                            <label className="form-control-label">Internal Name*</label>
                            <p className="text-sm mb-0"><i className="fas fa-info-circle text-info"></i> This is the name of this division that will be shown <b className="text-underline">inside of IntuiDesk.</b> Example: Bankruptcy</p>
                            <Input 
                                type="text"
                                value={division.name || ''}
                                onChange={e => onInputChange('name', e.target.value)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <label className="form-control-label">Display Name*</label>
                            <p className="text-sm mb-0"><i className="fas fa-info-circle text-info"></i> This is the name of the division that will <b className="text-underline">show up for contacts.</b> Example: ACME Bankruptcy</p>
                            <Input 
                                type="text"
                                value={division.display_name || ''}
                                onChange={e => onInputChange('display_name', e.target.value)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <label className="form-control-label">Legal Name*</label>
                            <p className="text-sm mb-0"><i className="fas fa-info-circle text-info"></i> This is the <b className="text-underline">registered name of your division (if you have one).</b> Example: ACME Bankruptcy, LLC</p>
                            <Input 
                                type="text"
                                value={division.legal_name || ''}
                                onChange={e => onInputChange('legal_name', e.target.value)}
                            />
                        </FormGroup>
                    </div>
                ),
                (
                    <div>

                        <h2>Division Timezone</h2>
                        <TimeZone 
                            timezone={division.timezone}
                            setTimezone={(value) => onInputChange('timezone', value)}
                        />    

                        <h2>Standard Business Hours</h2>
                        <DayAndTimeRange 
                            times={division.times_open}
                            setTimes={(value) => onInputChange('times_open', value)}
                        />       

                    </div>
                ),
                (
                    <div>

                        <h2>Call Settings</h2>
                        
                        <SearchCollections
                            collection={'call_phone_numbers'}
                            showEnhanced="call_phone_numbers"
                            title="Default Phone Number"
                            description="Used for transferring via cell phone, etc."
                            placeholder="Search Phone Numbers..."
                            value={division.default_phone_number}
                            onChange={(obj) => onInputChange('default_phone_number', obj.value)}
                            filter={{
                                division: { $in: [selected_division._id] },
                            }}
                        /> 

                        <ReactSelect
                            title={"Delete Call Recordings After"}
                            formGroup={true}
                            onChange={(obj) => onInputChange('call_recording_time_till_deleted', obj.value)}
                            options={[
                                { value: 0, label: 'Twilio Default' },
                                { value: day * 1, label: '1 Day' },
                                { value: day * 2, label: '2 Days' },
                                { value: day * 3, label: '3 Days' },
                                { value: day * 4, label: '4 Days' },
                                { value: day * 5, label: '5 Days' },
                                { value: day * 6, label: '6 Days' },
                                { value: day * 7, label: '7 Days' },
                                { value: day * 8, label: '8 Days' },
                                { value: day * 9, label: '9 Days' },
                                { value: day * 10, label: '10 Days' },
                                { value: day * 14, label: '14 Days' },
                                { value: day * 21, label: '21 Days' },
                                { value: day * 28, label: '28 Days' },
                                { value: day * 30, label: '30 Days' },
                                { value: day * 45, label: '45 Days' },
                                { value: day * 60, label: '60 Days' },
                                { value: day * 90, label: '90 Days' },
                                { value: day * 180, label: '180 Days' },
                            ]}
                            value={division.call_recording_time_till_deleted}
                        />    
                    </div>
                ),
                (
                    <div>
                        <h2>Email Settings</h2>


                        <SearchCollections
                            collection={'email_senders'}
                            showEnhanced="email_senders"
                            title="Default Email Sender"
                            description="Used as a fallback for sending emails."
                            placeholder="Search Email Senders..."
                            value={division.default_email_sender}
                            onChange={(obj) => onInputChange('default_email_sender', obj.value)}
                            filter={{
                                division: { $in: [selected_division._id] },
                            }}
                        /> 

                        <hr />

                        <FormGroup>
                            <div className="custom-control custom-checkbox">
                                <input
                                    className="custom-control-input"
                                    id="archk-division-edit-include-email"
                                    type="checkbox"
                                    checked={division.email_settings.include_email}
                                    onChange={e => onEmailSettingChange('include_email', !division.email_settings.include_email)}

                                />
                                <label className="custom-control-label" htmlFor="archk-division-edit-include-email">
                                    Include User's Email Address In Email Signature
                                </label>
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <div className="custom-control custom-checkbox">
                                <input
                                    className="custom-control-input"
                                    id="archk-division-edit-include-phone"
                                    type="checkbox"
                                    checked={division.email_settings.include_phone}
                                    onChange={e => onEmailSettingChange('include_phone', !division.email_settings.include_phone)}
                                />
                                <label className="custom-control-label" htmlFor="archk-division-edit-include-phone">
                                    Include User's Phone Number In Email Signature
                                </label>
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <div className="custom-control custom-checkbox">
                                <input
                                    className="custom-control-input"
                                    id="archk-division-edit-include-name"
                                    type="checkbox"
                                    checked={division.email_settings.include_name}
                                    onChange={e => onEmailSettingChange('include_name', !division.email_settings.include_name)}
                                />
                                <label className="custom-control-label" htmlFor="archk-division-edit-include-name">
                                    Include User's Full Name In Email Signature
                                </label>
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <div className="custom-control custom-checkbox">
                                <input
                                    className="custom-control-input"
                                    id="archk-division-edit-include-title"
                                    type="checkbox"
                                    checked={division.email_settings.include_title}
                                    onChange={e => onEmailSettingChange('include_title', !division.email_settings.include_title)}
                                />
                                <label className="custom-control-label" htmlFor="archk-division-edit-include-title">
                                    Include User's Division Title In Email Signature
                                </label>
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <div className="custom-control custom-checkbox">
                                <input
                                    className="custom-control-input"
                                    id="archk-division-edit-include-address"
                                    type="checkbox"
                                    checked={division.email_settings.include_address}
                                    onChange={e => onEmailSettingChange('include_address', !division.email_settings.include_address)}
                                />
                                <label className="custom-control-label" htmlFor="archk-division-edit-include-address">
                                    Include Division Address In Email Signature
                                </label>
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <div className="custom-control custom-checkbox">
                                <input
                                    className="custom-control-input"
                                    id="archk-division-edit-include-logo"
                                    type="checkbox"
                                    checked={division.email_settings.include_logo}
                                    onChange={e => onEmailSettingChange('include_logo', !division.email_settings.include_logo)}
                                />
                                <label className="custom-control-label" htmlFor="archk-division-edit-include-logo">
                                    Include Division Logo In Email Signature
                                </label>
                            </div>
                        </FormGroup>

                    </div>
                ),

                
                (
                    <div>
                        <h2>Email Disclaimer</h2>

                        <FormGroup>
                            <label className="form-control-label">Signature Disclaimer</label>
                            <p className="text-sm mb-0"><i className="fas fa-info-circle text-info"></i> If set, the text below will go out at the bottom of all email signatures for this division.</p>
                            <Input 
                                type="text"
                                value={division.email_settings.dislaimer || ''}
                                onChange={e => onEmailSettingChange('dislaimer', e.target.value)}
                            />
                        </FormGroup>
                    </div>
                ),
                (
                    <div>

                        <h2>Main Division Address</h2>
        
                        <Row>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Address Line 1</label>
                                    <Input 
                                        type="text"
                                        value={division.address_line_1 || ''}
                                        onChange={e => onInputChange('address_line_1', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Address Line 2</label>
                                    <Input 
                                        type="text"
                                        value={division.address_line_2 || ''}
                                        onChange={e => onInputChange('address_line_2', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                        </Row>
        
                        <Row>
                            <Col lg={4}>
                                <FormGroup>
                                    <label className="form-control-label">City</label>
                                    <Input 
                                        type="text"
                                        value={division.city || ''}
                                        onChange={e => onInputChange('city', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                            <Col lg={4}>
                                <InputStates 
                                    value={division.state}
                                    onChange={state => onInputChange('state', state)}
                                />
                            </Col>
                            <Col lg={4}>
                                <FormGroup>
                                    <label className="form-control-label">Zip</label>
                                    <Input 
                                        type="text"
                                        value={division.postal_code || ''}
                                        onChange={e => onInputChange('postal_code', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                        </Row>
                    </div>
                ),
                (
                    <div>
                        <h2>Affiliates</h2>

                        <ReactSelect
                            menuPlacement="top"
                            title={"Value Type"}
                            formGroup={true}
                            placeholder=""
                            onChange={(obj) => onInputChange('affiliate_value_type', obj.value)}
                            options={[
                                { label: 'Invoice', value: 'invoice' },
                                { label: 'Designated Value', value: 'designated_value' },
                                { label: 'Multiplier Price', value: 'multiplier' },
                            ]}
                            value={division.affiliate_value_type ? division.affiliate_value_type : 'invoice'}
                        />    

                        {division.affiliate_value_type === 'multiplier' ? (
                            <FormGroup>
                                <label className="form-control-label">Multiplier Price</label>
                                <Input 
                                    type="text"
                                    value={division.affiliate_value_price || ''}
                                    onChange={e => onInputChange('affiliate_value_price', e.target.value)}
                                />
                            </FormGroup>    
                        ) : null}
                    </div>
                ),

                (
                    <div>
                        <h2>AI Settings</h2>

                        <FormGroup>
                            <div className="custom-control custom-checkbox">
                                <input
                                    className="custom-control-input"
                                    id="archk-division-ai-enabled"
                                    type="checkbox"
                                    checked={division.ai_enabled}
                                    onChange={e => onInputChange('ai_enabled', !division.ai_enabled)}

                                />
                                <label className="custom-control-label" htmlFor="archk-division-ai-enabled">
                                    AI Is Enabled
                                </label>
                            </div>
                        </FormGroup>
                       
                        <FormGroup>
                            <div className="custom-control custom-checkbox">
                                <input
                                    className="custom-control-input"
                                    id="archk-division-ai-use-oracle"
                                    type="checkbox"
                                    checked={division.ai_oracle_use}
                                    onChange={e => onInputChange('ai_oracle_use', !division.ai_oracle_use)}

                                />
                                <label className="custom-control-label" htmlFor="archk-division-ai-use-oracle">
                                    Create An Oracle Behind Each Matter To Talk With Other Agents
                                </label>
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <div className="custom-control custom-checkbox">
                                <input
                                    className="custom-control-input"
                                    id="archk-division-ai-transcribe-calls"
                                    type="checkbox"
                                    checked={division.ai_transcribe_calls}
                                    onChange={e => onInputChange('ai_transcribe_calls', !division.ai_transcribe_calls)}

                                />
                                <label className="custom-control-label" htmlFor="archk-division-ai-transcribe-calls">
                                    Use AI To Transcribe All Calls Within This Division
                                </label>
                            </div>
                        </FormGroup>
                       
                        {division.ai_transcribe_calls ? (
                            <FormGroup className="ml-6">
                                <div className="custom-control custom-checkbox">
                                    <input
                                        className="custom-control-input"
                                        id="archk-division-ai-summarize-calls"
                                        type="checkbox"
                                        checked={division.ai_summarize_calls}
                                        onChange={e => onInputChange('ai_summarize_calls', !division.ai_summarize_calls)}

                                    />
                                    <label className="custom-control-label" htmlFor="archk-division-ai-summarize-calls">
                                        Use AI To Summarize Calls After They Have Been Transcribed
                                    </label>
                                </div>
                            </FormGroup>
                        ) : ''}
                       
                        <FormGroup>
                            <div className="custom-control custom-checkbox">
                                <input
                                    className="custom-control-input"
                                    id="archk-division-ai-create-email-drafts"
                                    type="checkbox"
                                    checked={division.ai_create_email_drafts}
                                    onChange={e => onInputChange('ai_create_email_drafts', !division.ai_create_email_drafts)}

                                />
                                <label className="custom-control-label" htmlFor="archk-division-ai-create-email-drafts">
                                    Use AI To Create Draft Responses To All Incoming Emails
                                </label>
                            </div>
                        </FormGroup>
                      
                        <FormGroup>
                            <div className="custom-control custom-checkbox">
                                <input
                                    className="custom-control-input"
                                    id="archk-division-ai-create-text-drafts"
                                    type="checkbox"
                                    checked={division.ai_create_text_drafts}
                                    onChange={e => onInputChange('ai_create_text_drafts', !division.ai_create_text_drafts)}

                                />
                                <label className="custom-control-label" htmlFor="archk-division-ai-create-text-drafts">
                                    Use AI To Create Draft Responses To All Incoming Text Messages
                                </label>
                            </div>
                        </FormGroup>
                       
                        <FormGroup>
                            <div className="custom-control custom-checkbox">
                                <input
                                    className="custom-control-input"
                                    id="archk-division-ai-document-analysis"
                                    type="checkbox"
                                    checked={division.ai_document_analysis}
                                    onChange={e => onInputChange('ai_document_analysis', !division.ai_document_analysis)}

                                />
                                <label className="custom-control-label" htmlFor="archk-division-ai-document-analysis">
                                    Use AI To Analyze Documents Sent Via Email Or Uploaded By The Client Portal
                                </label>
                            </div>
                        </FormGroup>
                        

                    </div>
                ),
             
            ]}
        />
    )

}



const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SystemDivisionEdit);