import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Badge, FormGroup, Input } from "reactstrap";

import api from 'api';

import Modalify from 'components/functional/modals/Modalify';

import ReactSelect from 'components/functional/inputs/ReactSelect';
import { toast } from 'react-toastify';
import { toggleStandardLoader } from 'store/functions/system/system';

import SearchCollections from 'components/system/Search/Collections';


const baseState = {
    lead_source_categories: [],
    name: '',
    price_per_lead: 0,
    charge_for_duplicates: false,
    audit_percentage: 0,
    default_percent: 0,
    call_queue: null
}

const MarketingLeadSourcesEdit = ({_id, onSaved, showModal, toggleModal, selected_division, DOMAINS}) => {

    const [showDirections, setShowDirections] = useState(false)
    const [leadSource, setLeadSource] = useState(null)
    const [leadSourceCategories, setLeadSourceCategories] = useState([])
    const [err, setErr] = useState(null)

    const fetchCategories = useCallback(async () => {
        const categories = await api.lead_source_categories.find(selected_division._id)
        if(!categories.data) return setErr(true);
        setLeadSourceCategories(categories.data)
    }, [selected_division._id])

    const getAuditOptions = useCallback(() => {
        let markup = []
        for (let i = 0; i < 101; i++) {
            markup.push({ label: i + '%', value: i })
        }
        return markup
    }, [])

    const fetchData = useCallback(async () => {
        if(!_id || _id === 'create') return setLeadSource(baseState);
        const user = await api.lead_sources.findById(_id)
        if(!user.data) return setErr(true);
        setLeadSource(user.data)
    }, [_id])
  
    const onInputChange = useCallback(( field, value) => {
        const newLeadSource = JSON.parse(JSON.stringify(leadSource))
        newLeadSource[field] = value;
        setLeadSource(newLeadSource);
    }, [leadSource])
    
   
    const onAddCategory = useCallback((_id) => {
        const newLeadSource = JSON.parse(JSON.stringify(leadSource))

        const categories = newLeadSource.lead_source_categories ? newLeadSource.lead_source_categories : []
        if(!categories.includes(_id) )categories.push(_id);

        newLeadSource.lead_source_categories = categories;
        setLeadSource(newLeadSource)
    }, [leadSource])
 
    const onRemoveCategory = useCallback((index) => {
        const newLeadSource = JSON.parse(JSON.stringify(leadSource))

        const categories = newLeadSource.lead_source_categories
        categories.splice(index, 1)
   
        newLeadSource.lead_source_categories = categories;
        setLeadSource(newLeadSource)
    }, [leadSource])
   


    const onSave = useCallback(async () => {
        setErr(false)
        let newState = JSON.parse(JSON.stringify(leadSource));

        if(!newState.name) {
            return setErr([`A lead_source must have a name.`])
        }

        let saved;

        let price_per_lead = newState.price_per_lead ? parseInt(newState.price_per_lead) : 0;
        let default_percent = newState.default_percent ? parseFloat(newState.default_percent) : 0;


        // toggle loaders and update/create the user
        toggleStandardLoader(true)
        if(newState._id) {
            saved = await api.lead_sources.update(newState._id, { ...newState, price_per_lead, default_percent }, true);
        } else {
            saved = await api.lead_sources.create({...newState, division: selected_division._id, price_per_lead, default_percent}, true);
        }
        toggleStandardLoader(false)

        // show error messages if unsuccessful
        if(saved.success) {
            toast.success('Lead Source Saved Successfully')
            fetchData();
            if(toggleModal) toggleModal();
            if(onSaved) onSaved(saved.data)
        } else {
            return setErr(saved.message);
        }
    
    }, [leadSource, fetchData, toggleModal, onSaved, selected_division._id])

    useEffect(() => {
        if(toggleModal) {
            if(showModal) {
                fetchData()
                fetchCategories()
            } else {
                setErr(null)
                setLeadSource(baseState)
            }
        } else {
            fetchData()
            fetchCategories()
        }
    }, [showModal, toggleModal, fetchData, fetchCategories])

    return (
        <Modalify 
            showModal={showModal}
            toggleModal={toggleModal}
            modalTitle={leadSource && leadSource._id ? "Update Lead Source" : "Create Lead Source"}
            err={err}
            footer={(
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            )}
            sections={!leadSource ? [] :  [
                (
                    <div>

                        <h2>Identifiers</h2>
        
                        <FormGroup>
                            <label className="form-control-label">Lead Source Name</label>
                            <Input 
                                type="text"
                                value={leadSource.name || ''}
                                onChange={e => onInputChange('name', e.target.value)}
                            />
                        </FormGroup>

                        <ReactSelect 
                            formGroup={true}
                            title="Source Categories"
                            value={{}}
                            onChange={(obj) => onAddCategory(obj.value)}
                            options={leadSourceCategories.map(type => {
                                return { value: type._id, label: type.name }
                            })}
                        />

                        <div>
                            {leadSource.lead_source_categories ? leadSource.lead_source_categories.map((tt, i) => {
                                const type = leadSourceCategories.find(t => t._id === tt)
                                return <Badge key={i} color="success">{type ? type.name : ''} <i className="fas fa-times pl-3 cursor-pointer" onClick={() => onRemoveCategory(i)} /></Badge>
                            }) : ''}
                        </div>
                    </div>
                ),
                (
                    <div>

                        <h2>Billing & Audits</h2>
        
                        <FormGroup>
                            <label className="form-control-label">Price Per Lead</label>
                            <Input 
                                type="number"
                                value={leadSource.price_per_lead !== undefined ? leadSource.price_per_lead : ''}
                                onChange={e => onInputChange('price_per_lead', e.target.value)}
                            />
                        </FormGroup>
                        
                        {leadSource.price_per_lead && leadSource.price_per_lead > 0 ? (
                           <div className="pl-5">
                             <ReactSelect 
                                formGroup={true}
                                title="Charge For Duplicates"
                                value={leadSource.charge_for_duplicates}
                                onChange={(obj) => onInputChange('charge_for_duplicates', obj.value)}
                                options={[
                                    { value: false, label: 'No' },
                                    { value: true, label: 'Yes' }
                                ]}
                            />
                            </div>
                        ) : ''}
                      
                        <ReactSelect 
                            formGroup={true}
                            title="Audit Percentage"
                            value={leadSource.audit_percentage}
                            onChange={(obj) => onInputChange('audit_percentage', obj.value)}
                            options={getAuditOptions()}
                        />

                        <FormGroup>
                            <label className="form-control-label">Default Percentage</label>
                            <Input 
                                type="number"
                                value={leadSource.default_percent !== undefined ? leadSource.default_percent : ''}
                                onChange={e => onInputChange('default_percent', e.target.value)}
                            />
                        </FormGroup>
                        

                    </div>
                ),
                (
                    <div>
                        <h2>Call Triggers</h2>

                        <SearchCollections
                            collection="call_queues" 
                            title="Call Queue"
                            addNull={true}
                            description="If selected, any lead that comes into this source will create an entry inside of the selected queue to be called in priority order. It is recommended to only set this field if this lead source is used via form submissions or incoming api requests."
                            value={leadSource.call_queue}
                            onChange={(obj) => onInputChange('call_queue', obj.value)}
                            filter={{
                                division: selected_division._id
                            }}
                        /> 
                    </div>
                ),
                ...!leadSource._id ? [] : [(
                    <div >
                        <h2>API Posting Instructions</h2>

                        {!showDirections ? (
                            <p className='text-sm mb-0'><span className='cursor-pointer text-underline' onClick={() => setShowDirections(true)}>Show Directions</span></p>
                        ) : (
                            <div className='bg-dark p-4 text-white rounded border '>
                                <p className='text-sm text-white'>To programmatically post a lead into this source give the following text to the team or developer wishing to push leads to your account.</p>
                                <hr />
                                <pre className='text-white'>

{
`POST URL:
${DOMAINS.SERVER}/v1/leads

REQUEST HEADERS
{
    lead-api-key    : "${leadSource.api_key}",
    lead-api-secret : "${leadSource.api_secret}",
}

REQUEST NOTES
All fields are optional however at least one of the following properties 
must exist on the POST request: family_name, email, or phone.

**Note that any invalid phone numbers or emails will be rejected entirely.

**If an existing match is found on either a phone or email lookup 
the duplicate property will turn to true.

REQUEST BODY: 
{
    "type"            : "form submission",
        
    given_name:       : "John",
    family_name:      : "Smith",

    email             : "johnsmith@gmail.com",
    phone             : "+15556667777",

    address_line_1    : "1 Manhattan Ave",
    address_line_2    : "Suite 200",
    city              : "Manhattan",
    state             : "NY"    // 2 letter state code,
    postal_code       : "12345" // 5 digits,
 
    provider_id       : "your systems unique id",
  
    url               : "the url this lead came from",
    device            : "mobile, tablet, desktop, etc.",
    notes             : "string",
    cost              : 10.50,  //and optional cost in dollars paid for the lead
}

RESPONSE UPON SUCCESS
{
    "success": true,
    "message": [],
    "user_message": [],
    "api_version": "1.0.0",
    "path": "POST: /v1/leads",
    "time": {unix timestamp},
    "milliseconds": miliseconds request took to complete,
    "data": {
        "claimed_by": null,
        "audited_by": null,
        "audited_at": 0,
        "audit_required": false,
        "referred_by": null,
        "referred_by_chain": [],
        "phone": "+15556667777",
        "identifiers": "string",
        "url": "unknown",
        "device": "unknown",
        "provider_id": "",
        "notes": "",
        "estimated_value": 0,
        "likely_hood_of_converting": 0,
        "address_line_1": "1 Manhattan Ave",
        "address_line_2": "Suite 200",
        "city": "Manhattan",
        "state": "NY",
        "postal_code": "12345",
        "area_code": "555",
        "day_of_week": "mon",
        "hour_of_day": 5,
        "in_business_hours": false,
        "duplicate": false,
        "cost": 0,
        "_id": "65c15ceb4002edd251d4d834",
        "lead_source": "${leadSource._id}",
        "division": "${leadSource.division}",
        "type": "form submission",
        "given_name": "john",
        "family_name": "smith",
        "email": "johnsmith@gmail.com",
        "id": "14",
        "display_name": "Jeff Van Grough",
        "date": "2024-02-05",
        "contact": "6568bd7ebcb8bb3fcc9061a5",
        "created_at": {unix timestamp},
        "updated_at": {unix timestamp},
        "company": "${leadSource.company}",
        "__v": 0
    }
}
`
}
                                </pre>
                            </div>
                        )}

                    </div>
                )]

               
            ]}
        />
    )

}


const mapStateToProps = state => {
	return {
        DOMAINS: state.load.DOMAINS,
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(MarketingLeadSourcesEdit);