import { connect } from 'react-redux';

import SearchCollections from 'components/system/Search/Collections';

import UploadAudio from './UploadAudio';

const SystemCallFlowEdit = ({task, onSetTask, onUpload, selected_division}) => {

    const getGatherOptions = () => {
        const markup = []

        for (let i = 1; i < 10; i++) {
            markup.push(
                <SearchCollections
                    collection="call_flows" 
                    title={`User Presses ${i}`}
                    value={task[`on_press_${i}`]}
                    onChange={(obj) => onSetTask(`on_press_${i}`, obj.value)}
                    filter={{
                        division: selected_division._id
                    }}
                /> 
            )
            
        }
        return markup;
    }

    return (
        <div>
                    
            <div className='bg-secondary p-3 border'>
                <p className='text-sm mb-0'><i className="fas fa-info-circle text-info mr-2" /> You have selected an IVR type option. Here you can upload and audio clip and use the options below to move to other call routes depending on the option a user presses.</p>
            </div>

            <UploadAudio task={task} onUpload={onUpload} />
            
            {getGatherOptions()}

        </div>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SystemCallFlowEdit);