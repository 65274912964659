import IntuideskSection from "components/markup/layout/IntuideskSection";

import Overview from './Overview'

const AnalyticsView = ({match}) => (
    <IntuideskSection 
        title="Analytics"
        section="analytics"
        defaultRoute="overview"
        match={match}
        pages={[
            { value: 'overview', icon: 'fas fa-chart-pie', name: 'Select', Component: Overview },
        ]}
    />
)

export default AnalyticsView;