import image from './image.svg'

const Unauthorized = () => (
    <div className='text-center pl-4 pr-4' style={{maxWidth: 550, marginLeft: 'auto', marginRight: 'auto'}}>
        <img className='pt-5 pb-5' src={image} style={{width: 300}} alt="Unauthorized"/>
        <div className='border py-3 px-5 rounde mt-4 z-depth-1 bg-secondary'>
            <h1 className='mt-4'> Unauthorized Access </h1>
            <p>Your account does not have access to view this page, if you believe this to be an error please contact your system administrator</p>
        </div>
    </div>
)

export default Unauthorized