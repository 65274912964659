/*
Documentation

this component renders 3 dots that will show a loading animation
the prop dotInterval can be passed in for custom timing of the dots

*/

import { Component } from 'react';

class Dots extends Component {


    state = {
        dots: 1
    };

    setTimeInterval = () => {

        this.timeCheckInterval = setInterval(() => {

            let dots = this.state.dots

            dots = dots + 1;

            if(dots === 4) {
                dots = 1
            }
            this.setState({dots})

        }, this.props.dotInterval ? this.props.dotInterval : 400)

    }

    componentDidMount = () => {

        this.setTimeInterval()

    }

    componentWillUnmount = () => {
        clearInterval(this.timeCheckInterval)
    }

    render() {

        if(this.state.dots === 1) {
            return '.'
        } else if(this.state.dots === 2) {
            return '..'
        } else if(this.state.dots === 3) {
            return '...'
        } 

    }

}

export default Dots;