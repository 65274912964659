import { useCallback, useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Badge, Card, Row, Col, FormGroup } from "reactstrap";

import api from 'api';

import ConfirmationModal from 'components/functional/modals/Confirmation';
import Table from 'components/functional/tables/Standard';
import TableIcons from 'components/markup/icons/TableIcons';
import ApiError from "components/markup/layout/ApiError";

import TextButtonRow from 'components/markup/layout/TextButtonRow';
import A from 'components/markup/links/A';
import Circle from 'components/markup/loading/Circle';

import ObjectFinder from 'components/system/Objects/Finder';
import moment from 'moment';


import { toast } from 'react-toastify';

import ModalToggler from 'components/functional/modals/Toggler';

import ModalView from './ModalView';

import SearchCollections from "components/system/Search/Collections";
import ReactSelect from 'components/functional/inputs/ReactSelect';
import DatePicker from 'react-datepicker'


const ExecutionLogs = ({selected_division, DOMAINS}) => {

    const [ loading, setLoading ] = useState(false);
    const [ date, setDate ] = useState(null);
    const [ status, setStatus ] = useState(null);
    const [ matter, setMatter ] = useState(false);
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ error, setError ] = useState(false);


    // helper for this.downloadCSV and this.query
    const fetchData = useCallback((params, isCSV, shouldReload) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(shouldReload) setLoading(true)

        let filter = { division: selected_division._id }
        if(matter) filter.matter = matter;
        if(status) filter.status = status;

        if(date) {
            const start = parseInt(moment(date).format('X'))
            filter.created_at = { $gte: start, $lt: start + 86399 }
        }

        const query = await api.execution_logs.search({ ...params, filter, isCSV })
        if(shouldReload) setLoading(false)

        if(!query.data) return setError(query.message)
        return resolve(query.data)
    }), [sizePerPage, selected_division._id, matter, status, date])

    // run query to get data from the server
    const query = useCallback(async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }, [fetchData])

    useEffect(() => {
        setLoading(true)
        setTableData({ data: [], total_documents: 0 })
        setTimeout(() => {
            setLoading(false)
        }, 250)
    }, [matter, status, date])
    
    const columns = [
        {
            dataField: "process_id",
            text: 'Process',
            formatter: (cell, row) => row.name === 'no data' ? (
                <div style={{minHeight: 150}}>No Data To Show</div> 
            ) : (
                row.type === 'background_process' ? (
                    <A href={`/editor/background_processes/${row.process_id}`}>
                        <ObjectFinder collection="background_processes" _id={row.process_id} />
                    </A>
                ) : 'Unknown Execution'
               

            )
        },
        {
            dataField: "type",
            text: 'Type',
            formatter: (cell, row) => row.name === 'no data' ? '' : (
                row.type ? <span className="text-capitalize">{row.type.replace(/_/g, ' ')}</span> : 'Unknown Type'
            )
        },
       
        {
            dataField: "action",
            text: 'Action',
            formatter: (cell, row) => row.name === 'no data' ? '' : (
                row.action ? <span className="text-capitalize">{row.action.replace(/_/g, ' ')}</span> : 'Unknown Action'
            )
        },
       
        {
            dataField: "status",
            text: 'Status',
            formatter: (cell, row) => row.name === 'no data' ? '' : (
                row.status === 'info' ? (
                    <Badge style={{width: 70}} color="info">{row.status}</Badge>
                ) : row.status === 'error' ? (
                    <Badge style={{width: 70}} color="danger">{row.status}</Badge>
                ) : (
                    <Badge style={{width: 70}} color="success">{row.status}</Badge>
                )
            )
        },
       
        {
            dataField: "created_at",
            text: 'Date',
            formatter: (cell, row) => row.name === 'no data' ? '' : (
                moment.unix(row.created_at).format('MM/DD/YYYY h:mm:ss A')
            )
        },
        {
            dataField: "matter",
            text: 'Matter',
            formatter: (cell, row) => row.name === 'no data' ? '' : (
                row.type === 'background_process' ? (
                    <A href={`${DOMAINS.APP}/matters/${row.matter}`}>
                        <ObjectFinder collection="matters" _id={row.matter} />
                    </A>
                ) : 'Unknown Execution'
               

            )
        },
       
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right',},
            text: 'Actions',
            headerFormatter: () => {
                return <div id="archk-tutorial-contacts-actions">Actions</div>
            },
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                         <TableIcons
                            icons={[
                                {
                                    icon: 'fas fa-external-link-alt',
                                    color: 'success', 
                                    wrapper: ModalToggler, 
                                    component: ModalView,
                                    _id: row._id
                                },
                             
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(error) return <ApiError err={error} />

    console.log(date);

    return (
        <div>
            {/* <TextButtonRow title="View Logs" /> */}

            <Row>
                <Col md={4}>
                    <SearchCollections
                        collection={'matters'}
                        title="Select Matter"
                        placeholder="Search..."
                        value={matter}
                        onChange={(obj) => setMatter(obj.value)}
                        filter={{
                            division: selected_division._id,
                        }}
                    /> 
                </Col>
                <Col md={4}>
                    <ReactSelect
                        title={"Execution Status"}
                        formGroup={true}
                        placeholder=""
                        onChange={(obj) => setStatus(obj.value)}
                        options={[
                            { value: null, label: 'All' },
                            { value: 'error', label: 'Error' },
                            { value: 'info', label: 'Info' },
                            { value: 'success', label: 'Success' },
                        ]}
                        value={status}
                    />   
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <label className='form-control-label'>Date</label> 

                        <DatePicker 
                            selected={date} 
                            onChange={(date) => setDate(date)} 
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Card className="card-color card-primary table-fixed">
                {!loading ? (
                    <Table
                        tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                        placeholder="Search..."
                        query={query}
                        columns={columns}
                        data={tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                        totalDocuments={tableData.total_documents}
                        sizePerPage={sizePerPage}
                        onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                        hideSearch={true}
                    />
                ) : (
                    <div className="py-3"><Circle /></div>
                )}
                
            </Card>


        </div>
    )


}

const mapStateToProps = state => {
	return {
        DOMAINS: state.load.DOMAINS,
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(ExecutionLogs);