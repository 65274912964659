// https://reactflow.dev/api-reference/react-flow#node-origin
// https://reactflow.dev/learn/concepts/core-concepts
// https://reactflow.dev/examples

import { useEffect, useCallback, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'

import HeaderNavigation from 'components/markup/layout/HeaderNavigation'
import Circle from 'components/markup/loading/Circle'

import NodesIfStatement from './nodes/IfStatement';
import NodesAction from './nodes/Action';
import NodesTrigger from './nodes/Trigger';
import NodesRunAssistant from './nodes/RunAssistant';
import NodesNotification from './nodes/Notification';
import NodesUserVerification from './nodes/UserVerification';
import NodesUserWait from './nodes/Wait';

import SidebarLeft from './SidebarLeft'
import SidebarRight from './SidebarRight'
import ModalValidation from './ModalValidation'
import ModalSettings from './ModalSettings'

import { DnDProvider, useDnD } from './_helpers/DnDContext';

import { toggleStandardLoader } from 'store/functions/system/system';

import api from 'api'

import ReactFlow, {
  Controls,
  Background,
  addEdge,
  BackgroundVariant,
  ReactFlowProvider,
  useReactFlow,
  applyEdgeChanges, 
  applyNodeChanges
} from 'reactflow';

const initialNodes =  [{
    id: '1',
    type: 'trigger',
    data: {},
    position: { x: 30, y: 30 },
    sourcePosition: 'right',
}]

const initialEdges = []

const nodeTypes = {
    if_statement: NodesIfStatement,
    action: NodesAction,
    trigger: NodesTrigger,
    run_assistant: NodesRunAssistant,
    notification: NodesNotification,
    user_verification: NodesUserVerification,
    wait: NodesUserWait,
};

let id = initialNodes.length

const getId = () => {
    id++;
    return id.toString()
};

const BackgroundProcesses = ({match, selected_division}) => {

    const reactFlowWrapper = useRef(null);
    const { screenToFlowPosition } = useReactFlow();
    const [type] = useDnD();

    const [errs, setErrs] = useState(null);
    const [nodes, setNodes] = useState(initialNodes);
    const [edges, setEdges] = useState(initialEdges);
    const [selectedNode, setSelectedNode] = useState(null);
    const [showSettings, setShowSettings] = useState(false);

    const [_id, setId] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [active, setActive] = useState(false);
    
    const [redirect, setRedirect] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const onNodesChange = useCallback(
        (changes) => {
            if(changes[0] && changes[0].id === '1' && changes[0].type === 'remove') {
                return toast.info(`You may not delete the originating trigger node.`)
            }
            setNodes((nds) => applyNodeChanges(changes, nds))
        },
        [setNodes],
    );
    const onEdgesChange = useCallback(
        (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [setEdges],
    );
    const onConnect = useCallback(
        (connection) => {
            if(parseInt(connection.target) < parseInt(connection.source)) {
                return toast.info(`To prevent creating infinite pipeline loops a node may not be connected to a node with an ID smaller than itself`)
            }
            if(edges.some(e => e.source === connection.source && e.sourceHandle === connection.sourceHandle)) {
                return toast.info(`To node may only connect to one other at a time.`)
            }
            setEdges((eds) => addEdge({ ...connection, animated: true, style: { stroke: '#fff' } }, eds))
        },
        [setEdges, edges],
    );

    const onDragOver = useCallback((e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'move';
    }, []);
    
    const onDrop = useCallback((e) => {
        e.preventDefault();
        if (!type) return;
    
        const position = screenToFlowPosition({
            x: e.clientX,
            y: e.clientY,
        });
    
        setNodes((nds) => nds.concat({
            id: getId(),
            type,
            position,
            data: { label: `${type} node` },
        }));
    }, [screenToFlowPosition, type]);

    const onError = useCallback((code, message) => {
        console.log('react flow error')
        console.log(code)
        console.log(message)
        toast.error(`Something went wrong behind the scenes, please refresh your page. For advanced cases check console and contact support.`)
    }, [])
  
    const onNodeClick = useCallback((e, node) => {
       setSelectedNode(node)
    }, [])

    const onNodeDragStart = useCallback((e, node) => {
       setSelectedNode(node)
    }, [])

    const onPaneClick = useCallback(() => {
        setSelectedNode(null)
    }, [])
    
    const onNodesDelete = useCallback(() => {
        setSelectedNode(null)
    }, [])

    const onNodeUpdated = useCallback((node) => {
        const _nodes = JSON.parse(JSON.stringify(nodes));
        const index = _nodes.findIndex(n => n.id === node.id);
        if(index === -1) return toast.error(`Error Updating Node, Contact Support`);

        _nodes[index] = node;

        applyNodeChanges([node], _nodes)
        setNodes(_nodes)

        setSelectedNode(null)
    }, [nodes])

    const onSave = useCallback(async () => {

        let _errs = [];

        if(!name) _errs.push(`This process must be given a name, this can be updated within the settings tab.`)

        nodes.forEach(node => {
            const { id, type, data } = node;

            const pushErr = (err) => _errs.push(`Node ID ${id} - ${err}`)

            if(type === 'action') {

                if(!data.type) return pushErr(`A type must be set for an action node to be valid.`)

                if(data.type === 'complete_outstanding_items') {
                    // nothing needed here
                } else if(data.type === 'complete_task') {
                    
                    if(!data.task_template) return pushErr(`A task template must be selected.`)
                    
                } else if(data.type === 'send_form') {

                    if(!data.form) return pushErr(`A form must be selected.`)
                    if(!data.workflow_contacts || !data.workflow_contacts.length) return pushErr(`At least one contact must be selected.`)
                    
                } else if(data.type === 'send_document_for_signature') {

                    if(!data.signing_template) return pushErr(`A form must be selected.`)
                    if(!data.workflow_contacts || !data.workflow_contacts.length) return pushErr(`At least one contact must be selected.`)
                    
                } else if(data.type === 'move_step') {

                    if(!data.workflow_step) return pushErr(`A workflow must be selected.`)
                    
                } else if(data.type === 'create_outstanding_item') {
                    
                    if(!data.name) return pushErr(`An outstanding item must have a name.`)

                } else if(data.type === 'create_event') {

                    if(!data.event_template) return pushErr(`An event must have an event template specified.`)
                    if(!data.unix_start) return pushErr(`An event must have a date.`)
                    if(!data.from) return pushErr(`An event must have an originating user.`)
                    
                } else if(data.type === 'create_task') {
                    
                    if(!data.task_template) return pushErr(`A task must have a task template associated.`)

                } else if(data.type === 'send_email') {

                    if(!data.email_template) return pushErr(`An email must have an associated email template.`)
                    if(!data.email_sender) return pushErr(`An email must have a default email sender selected.`)
                    // if(!data.workflow_contacts || !data.workflow_contacts.length) return pushErr(`At least one contact must be selected.`)
                    
                } else if(data.type === 'send_text') {
                    
                    if(!data.text_template) return pushErr(`An email must have an associated email template.`)
                    if(!data.workflow_contacts || !data.workflow_contacts.length) return pushErr(`At least one contact must be selected.`)

                }

            } else if(type === 'run_assistant') {

                if(!data.ai_assistant) return pushErr(`An AI Assistant must be selected.`)

            } else if(type === 'trigger') {
                
                if(!data.type) return pushErr(`A trigger type must be selected.`)

            } else if(type === 'if_statement') {

                if(!data.ai_assistant) return pushErr(`An AI Assistant must be selected.`)
                
            } else if(type === 'notification') {

                if(!data.subject) return pushErr(`A notification must have a subject.`)
                if(!data.body) return pushErr(`A notification must have a body.`)
                                
            } else if(type === 'user_verification') {

                if(!data.user && (!data.workflow_roles || !data.workflow_roles.length)) return pushErr(`A user or role must be selected.`)
                
            } else if(type === 'wait') {
                
                if(!data.wait_time) return pushErr(`A time period must be selected.`)

            }

        })

        if(_errs.length) return setErrs(_errs);

        let saved;

        const payload = { name, active, nodes, edges, description, division: selected_division._id }

        toggleStandardLoader(true)
        if(_id) {
            saved = await api.background_processes.update(_id, payload)
        } else {
            saved = await api.background_processes.create(payload)
        }
        toggleStandardLoader(false)

        if(!saved.success) return toast.error(`Could not save process, please try again.`)
        
        toast.success(`Process Saved Successfully!`)
        setRedirect('/ai/background_processes')

    }, [nodes, name, active, edges, _id, description, selected_division._id])

    const fetchData = useCallback(async () => {
        if(match.params._id === 'new') return setLoaded(true);

        const data = await api.background_processes.findById(match.params._id);

        if(!data.success) return toast.error(`Something went wrong loading this page, please refresh and try again.`)
        if(!data.data) {
            toast.info(`The process you have tried to view cannot be found.`)
            return setRedirect(`/ai/background_processes`)
        }

        setId(data.data._id);
        setName(data.data.name);
        setActive(data.data.active);
        setNodes(data.data.nodes);
        setEdges(data.data.edges);
        setDescription(data.data.description);
        setLoaded(true)
        id = parseInt(data.data.nodes[data.data.nodes.length - 1].id)

    }, [match.params._id])

    useEffect(() => {
        fetchData();
        id = initialNodes.length
        document.body.classList.add('noScroll');

        return () => {
            document.body.classList.remove('noScroll')
        }
    }, [fetchData])

    if(redirect) return <Redirect to={redirect} />
    if(!loaded) return <div className='py-6'><Circle /></div>
  
    return (
        <div className='archk-background-processes'>

            <div className="archk-workflows-header">
                <HeaderNavigation 
                    classNames="py-1 z-depth-3 bg-white"
                    noMargin={true}
                    title={(
                        <small>
                            <i className="fas fa-edit mr-0 text-info " />{' '}
                            {name ? name : 'Editing Background Process' }
                        </small>
                    )}
                    actionComponent={(
                        <div>
                            <Link to="/ai/background_processes" className="btn btn-sm btn-outline-warning">
                                <i className="fas fa-arrow-left mr-2" /> Back
                            </Link>

                            <button className="btn btn-sm btn-info" onClick={() => setShowSettings(true)}>
                                <i className="fas fa-cogs mr-2" /> Settings
                            </button>

                            <button className="btn btn-sm btn-success" onClick={onSave}>
                                <i className="fas fa-save mr-2" /> Save Process
                            </button>
                        </div>
                    )}
                />
            </div>

            <SidebarLeft />

            <div className='main ' ref={reactFlowWrapper}>

                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    nodeTypes={nodeTypes}
                    onDrop={onDrop}
                    onDragOver={onDragOver}
                    onError={onError}
                    onNodeClick={onNodeClick}
                    onNodeDragStart={onNodeDragStart}
                    onPaneClick={onPaneClick}
                    onNodesDelete={onNodesDelete}
                    // snapToGrid={true}
                >
                    <Controls showFitView={true} orientation="horizontal" /> 
                    <Background color="#fff" style={{padding: 0}} variant={BackgroundVariant.Dots} />
                </ReactFlow>

            </div>

            <SidebarRight 
                selectedNode={selectedNode}
                onNodeUpdated={onNodeUpdated}
            />

            <ModalSettings 
                showModal={showSettings}
                toggleModal={() => setShowSettings(null)}
                name={name}
                setName={setName}
                active={active}
                setActive={setActive}
                description={description}
                setDescription={setDescription}
            />
            <ModalValidation 
                showModal={errs}
                toggleModal={() => setErrs(null)}
                errs={errs || []}
            />

        </div>
    )

}

const toExport = ({ selected_division, match }) => (
    <ReactFlowProvider>
        <DnDProvider>
            <BackgroundProcesses selected_division={selected_division} match={match} />
        </DnDProvider>
    </ReactFlowProvider>
)


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(toExport);