import { useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Card, Badge } from "reactstrap";
import { Link } from 'react-router-dom';

import formatObject from 'utils/formatObject';
import api from 'api';

import TextButtonRow from 'components/markup/layout/TextButtonRow'
import Circle from 'components/markup/loading/Circle'
import ConfirmationModal from 'components/functional/modals/Confirmation'
import TableIcons from 'components/markup/icons/TableIcons'
import ModalToggler from 'components/functional/modals/Toggler'
import Table from 'components/functional/tables/Standard';
import ApiError from "components/markup/layout/ApiError";

import LocationsEdit from '../components/Edit'
import { toggleAlertBS } from "store/functions/system/system";

const LocationsAll = ({setTab, selected_division}) => {

    const [ loading, setLoading ] = useState(false);
    const [ locationTypes, setLocationTypes ] = useState(false);
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ locationToDelete, setLocationToDelete ] = useState(false)
    const [ error, setError ] = useState(false);

    const fetchTypes = useCallback(async () => {
        const types = await api.location_types.find()
        setLocationTypes(types.data.filter(d => d.division === selected_division._id))
    }, [selected_division._id])

    const renderType = useCallback((type) => {
        const found = locationTypes ? locationTypes.find(t => t._id === type) : '';
        return found ? found.name : ''
    }, [locationTypes])

    // helper for this.downloadCSV and this.query
    const fetchData = useCallback((params, isCSV, shouldReload) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(shouldReload) setLoading(true)
        const query = await api.locations.search({ ...params, filter: { deleted: false, division: selected_division._id }, isCSV })
        if(shouldReload) setLoading(false)

        if(!query.data) return setError(query.message)
        return resolve(query.data)
    }), [sizePerPage, selected_division._id])

    // run query to get data from the server
    const query = useCallback(async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }, [fetchData])

    const onDeleteLocation = useCallback(async (location) => {
        const deleted = await api.locations.delete(locationToDelete._id);
        if(deleted.success) {
            fetchData();
            toggleAlertBS(false, 'Location Successfully Removed')
        } else {
            toggleAlertBS(true, `Something's not right, please try again.`)
        }
    }, [fetchData, locationToDelete])
 
    const columns = [
        {
            dataField: "given_name",
            text: 'Name',
            headerFormatter: () => {
                return <div id="archk-tutorial-contacts-name">Name</div>
            },
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )
                return (
                    <ModalToggler component={LocationsEdit} _id={row._id} onSaved={() => fetchData(null, null, true)}>
                        {row.name}
                    </ModalToggler>
                )
                
            },
        },
        {
            dataField: "location_types",
            text: 'Type',
            formatter: (cell, row) => row.location_types ? row.location_types.map((t, i) => (
                i === 0 ? renderType(t) : ', ' + renderType(t)
            )) : ''
        },
        {
            dataField: "address_line_1",
            text: 'Address',
            formatter: (cell, row) => formatObject(row).address()
        },
        {
            dataField: "email",
            text: 'Email',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' :
                    row.email ? (
                        <div>
                            {row.email}{' '}
                            {row.email_2 && row.email_3 ? <Badge style={{position: 'relative', top: -2}} color="success"><i className="fas fa-plus " /> 2</Badge> : 
                            row.email_2 || row.email_3 ? <Badge style={{position: 'relative', top: -2}} color="success"><i className="fas fa-plus " /> 1</Badge> : ''}
                        </div>
                    ) : ''
            )
        },
        {
            dataField: "phone",
            text: 'Phone',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' :
                    row.phone ? (
                        <div>
                            {row.phone}{' '}
                            {row.phone_2 && row.phone_3 ? <Badge style={{position: 'relative', top: -2}} color="success"><i className="fas fa-plus " /> 2</Badge> : 
                            row.phone_2 || row.phone_3 ? <Badge style={{position: 'relative', top: -2}} color="success"><i className="fas fa-plus " /> 1</Badge> : ''}
                        </div>
                    ) : ''
            )
        },
      
        {
            dataField: "representatives",
            text: 'Reps',
            formatter: (cell, row) => row.representatives ? row.representatives.length : ''

        },
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right',},
            text: 'Actions',
            headerFormatter: () => {
                return <div id="archk-tutorial-contacts-actions">Actions</div>
            },
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                         <TableIcons
                            icons={[
                                { 
                                    icon: 'fas fa-edit',
                                    color: 'success', 
                                    wrapper: ModalToggler, 
                                    component: LocationsEdit,
                                    onSaved: () => fetchData(null, null, true),
                                    _id: row._id
                                },
                                {  
                                    icon: 'fas fa-trash', 
                                    color: 'danger',
                                    onClick: () => setLocationToDelete(row)
                                },
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    useEffect(() => {
        fetchTypes()
    }, [fetchTypes])

    if(error) return <ApiError err={error} />
    if(loading || !locationTypes) return <div><Circle /></div>

    return (

        <>

            <TextButtonRow 
                title={"All Locations"}
                button={(
                    <div>
                        <Link to="/system/location_types" className="btn btn-info"><i className="fas fa-plus mr-2 " /> Add Location Type</Link>
                        <button onClick={() => setTab('create')} className="btn btn-success"><i className="fas fa-plus mr-2 " /> Add New</button>
                    </div>
                )}
            />

            <Card className="card-color card-primary table-fixed">
                <Table
                    tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                    placeholder="Search..."
                    query={query}
                    columns={columns}
                    data={tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}
                    sizePerPage={sizePerPage}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>

            <ConfirmationModal 
                showModal={locationToDelete ? true : false}
                toggleModal={() => setLocationToDelete(null)}
                title="Delete Location"
                body={(
                    <span>
                        Are you sure you wish to delete the location {locationToDelete ? locationToDelete.name : ''}? This will prevent the location from logging in or coming up in search results in the future.
                    </span>
                )}
                onConfirmation={onDeleteLocation}
            />

        </>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(LocationsAll);