import { connect } from 'react-redux'
import { Badge, Row, Col } from 'reactstrap';
import DragAndDrop from 'components/functional/uploads/DragAndDrop';
import { useCallback, useEffect, useState } from 'react';

import Search from './Search'
import prettyBytes from 'pretty-bytes'

import ObjectFinder from 'components/system/Objects/Finder';
import moment from 'moment'
import { onDownloadDocument } from 'utils/documents';
import { closeDocumentCenter } from 'store/functions/document_center'

import ConfirmationModal from 'components/functional/modals/Confirmation'

const Upload = ({document_center, socket}) => {

    const [confirmCancel, setConfirmCancel] = useState(false)
    const [newDocs, setNewDocs] = useState([])
    const [currentDocs, setCurrentDocs] = useState([])

    const onDocUploaded = useCallback((obj) => {
        const foundIndex = newDocs.findIndex(d => d._id === obj._id);
        if(foundIndex !== -1) {
            let _docs = JSON.parse(JSON.stringify(newDocs))
            let _currentDocs = JSON.parse(JSON.stringify(currentDocs))
            _docs[foundIndex] = obj;
            _currentDocs.push(obj)
            setNewDocs(_docs)
            setCurrentDocs(_currentDocs)
        }
    }, [newDocs, currentDocs])

    const onClose = useCallback(() => {
        if(newDocs.some(d => d.status === 'uploading')) {
            setConfirmCancel(true)
        } else {
            closeDocumentCenter(currentDocs)
        }
    }, [newDocs, currentDocs])
    
    const onDocFailed = useCallback((obj) => {
        const foundIndex = newDocs.findIndex(d => d._id === obj._id);
        if(foundIndex !== -1) {
            let _docs = JSON.parse(JSON.stringify(newDocs))
            _docs[foundIndex] = obj;
            setNewDocs(_docs)
        }
    }, [newDocs])
    
          

    useEffect(() => {

        if(document_center.url === '/v1/documents') {
            socket.on('DOCUMENTS.UPLOADED', onDocUploaded)
            socket.on('DOCUMENTS.FAILED', onDocFailed)
            return () => {
                socket.off('DOCUMENTS.UPLOADED', onDocUploaded)
                socket.off('DOCUMENTS.FAILED', onDocFailed)
            }
        } else {
            socket.on('COMPANY_DOCUMENTS.UPLOADED', onDocUploaded)
            socket.on('COMPANY_DOCUMENTS.FAILED', onDocFailed)
            return () => {
                socket.off('COMPANY_DOCUMENTS.UPLOADED', onDocUploaded)
                socket.off('COMPANY_DOCUMENTS.FAILED', onDocFailed)
            }
        }

      
    }, [onDocUploaded, onDocFailed, socket, document_center.url])
  
    useEffect(() => {
        if(document_center.open === true) {
            document.body.classList.add('noScroll')
        } else {
            document.body.classList.remove('noScroll')
            setCurrentDocs([])
        }
    }, [document_center.open])

    if(!document_center.open) return <></>

    const isCompanyDocument = document_center.url === '/v1/company_documents';

    return (
        <div className="archk-upload" >
            <div className="archk-upload-blackout" onClick={onClose} />
            
            <div className="archk-upload-main" >

                <div className="archk-upload-section border-bottom">
                    <Row>
                        <Col md={6} className="align-self-center">
                            <h3 className='mb-0'>Upload Center</h3>
                        </Col>
                        <Col md={6} className='align-self-center text-right'>
                            <button onClick={onClose} className='btn btn-warning'><i className="fas fa-times mr-2" /> Close</button>
                        </Col>
                    </Row>
                </div>
                <div className="archk-upload-section border-bottom">
                    <p className='text-sm mb-0'>
                        <b className='text-dark font-weight-bold'>Current Action:</b>{' '}
                        {document_center.matter_document ? (
                            <span>Uploading files to document category <b className='text-underline'><ObjectFinder collection="matter_documents" _id={document_center.matter_document} /></b> on the case: <b className='text-underline'><ObjectFinder collection="matters" _id={document_center.matter} /></b>.</span>
                        ) : isCompanyDocument ? (
                            <span>Uploading Documents</span>
                        ) : (
                            <span>Uploading documents to the case <b className='text-underline'><ObjectFinder collection="matters" _id={document_center.matter} /></b>.</span>
                        )}
                        
                    </p>
                </div>

                <div className="archk-upload-section">
                    <DragAndDrop
                        zone_id="documents_1"
                        url={document_center.url ? document_center.url : '/v1/documents'}
                        onError={(e) => console.log(e)}
                        resizePixels={1200}
                    
                        onSuccess={(file, request) => {
                            if(request && request.data) {
                                let _docs = JSON.parse(JSON.stringify(newDocs));
                                _docs.push(request.data)
                                setNewDocs(_docs)
                            }
                        }}
                        // defaultValue="Logo" 
                        extraData={{
                            matter: document_center.matter,
                            task: document_center.task ? document_center.task : undefined,
                            matter_document: document_center.matter_document ? document_center.matter_document : undefined,
                        }}
                        // acceptedFiles="image/jpeg,image/png"
                    />
                </div>

                {newDocs && newDocs.length ? (
                    <div className="archk-upload-section">
                        <h4>New Uploads</h4>
                        <table className='table border-left border-right border-bottom'>
                        <thead>
                            <tr>
                                {!isCompanyDocument ? (
                                    <td>Matter</td>
                                ) : ''}
                                
                                <td>File</td>
                                <td>Size</td>
                                <td>Date</td>
                                <td>Status</td>
                                <td className='text-right'>Download</td>
                            </tr>
                        </thead>
                        <tbody>
                            {newDocs.map(d => (
                                <tr key={d._id}>
                                    {!isCompanyDocument ? (
                                        <td><ObjectFinder collection="matters" _id={d.matter} /></td>
                                    ) : ''}
                                    
                                    <td>{d.name}</td>
                                    <td>{prettyBytes(d.size)}</td>
                                    <td>{moment.unix(d.created_at).format('MM/DD/YYYY h:mm A')}</td>
                                    <td>
                                        {d.status === 'uploading' ? (
                                            <Badge color="info">Uploading</Badge>
                                        ) : d.status === 'succeeded' ? (
                                            <Badge color="success">Succeeded</Badge>
                                        ) : (
                                            <Badge color="danger">Failed</Badge>
                                        )}
                                    </td>
                                    <td className='text-right'>
                                        {d.status === 'succeeded' ? (
                                            <button 
                                                onClick={() => onDownloadDocument(d, isCompanyDocument ? 'company_documents' : '')} 
                                                className='btn btn-sm btn-success'
                                            >
                                                <i className="fas fa-download mr-2" /> Download
                                            </button>
                                        ) : ''}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table> 
                    </div>
                ) : ''}

                <div className="archk-upload-section border-top ">
                    <h4>My Upload History (Last 30 Days)</h4>
                    <Search isCompanyDocument={isCompanyDocument} />
                </div>

            </div>

            <ConfirmationModal 
                showModal={confirmCancel}
                toggleModal={() => setConfirmCancel(false)}
                title="Uploads In Progress"
                zIndex={2000}
                body={(
                    <span>
                        You uploads are still in progress, are you sure you wish to close this tab?
                    </span>
                )}
                onConfirmation={() => closeDocumentCenter(currentDocs)}
            />
            
        </div>
    )
}


const mapStateToProps = state => {
	return {
        socket: state.socket,
	    document_center: state.document_center,
	};
};

export default connect(mapStateToProps, '')(Upload);