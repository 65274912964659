const _types = [
    { value: 'not_a_lead', label: 'Not A Lead' },
    { value: 'dnq', label: 'Does Not Qualify' },
    { value: 'no_hire', label: 'No Hire' },
    { value: 'potential', label: 'Potential' },
    { value: 'refer_out', label: 'Refer Out' },
    { value: 'hire', label: 'Hire' },
    { value: 'won', label: 'Won' },
]

export default _types;