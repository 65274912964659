import LocationTypesEdit from './components/Edit'
import LocationTypesAll from './All'

import IntuideskSectionWrapper from 'components/markup/layout/IntuideskSectionWrapper'

const SystemLocationTypes = ({match, section}) => (
    <IntuideskSectionWrapper 
        title="Location Types"
        editTitle="Location Type"
        match={match}
        section={section}
        innerSection={'location_types'}
        Edit={LocationTypesEdit}
        All={LocationTypesAll}
    />
)

export default SystemLocationTypes;