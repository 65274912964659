import { useCallback, useEffect, useState } from 'react'

import { Modal, FormGroup, Input } from "reactstrap";
import { toggleAlertBS } from 'store/functions/system/system';

import { toggleStandardLoader } from 'store/functions/system/system';

import api from 'api'

import ReactSelect from 'components/functional/inputs/ReactSelect';

import { toast } from 'react-toastify'

import Associations from 'components/system/Associations'

const AutomationsEditAddTask = ({showModal, toggleModal, automation, setAutomation}) => {

    const [name, setName] = useState(automation.name);
    const [active, setActive] = useState(automation.active);
    const [errs, setErrs] = useState('');
    
    const [associations, setAssociations] = useState(null);

    const fetchAssociations = useCallback(async () => {
        if(!automation._id) return setAssociations([]);
        const data = await api.admin.associations('automation', automation._id)
        console.log(data)
        if(!data.success) return toast.error(`Could not fetch associations for this matter.`)
        setAssociations(data.data)
    }, [automation._id])

    const onSave = useCallback(async (state) => {

        const errs = [];
        if(!name) errs.push('name')

        setErrs(errs);

        if(errs.length) return;

        toggleStandardLoader(true);
        const data = await api.automations.update(automation._id, {
            name,
            active: active === 'true' || active === true ? true : false,
        })
        toggleStandardLoader(false);

        if(data.success) {
            setAutomation(data.data)
            toggleModal();
        } else {
            toggleAlertBS(true, 'Please try again.')
        }

    }, [name, active, setAutomation, toggleModal, automation._id])

    useEffect(() => {
        fetchAssociations()
    }, [fetchAssociations])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >

            <div className="modal-header">
                <h5 className="modal-title">Update Automation</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">

                <FormGroup>
                    <label className="form-control-label">Name</label>
                    <Input 
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        invalid={errs.includes('name')}
                   />
                </FormGroup>
                
                <ReactSelect 
                    formGroup={true}
                    title="Active"
                    value={active}
                    onChange={(obj) => setActive(obj.value)}
                    options={[
                        { value: false, label: 'No' },
                        { value: true, label: 'Yes' },
                    ]}
                />

            </div>

            <Associations type="automation" _id={automation._id} classNames="modal-body bg-secondary border-top" />


            <div className="modal-footer text-right">
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            </div>

        </Modal>

    )

}

export default AutomationsEditAddTask;