/*
Documentation

This component takes an image url and returns it if as a circular image
if it does not it will return a default image

*/

import PropTypes from 'prop-types'
import React from "react";

const unsupportedFileTypes = [
    'image/svg+xml'
]

class DropZonePreview extends React.Component {

    state = {
        error: false
    }

    getSource = (file) => {

        try {

            return file.dataURL ? file.dataURL : file.previewElement.childNodes[0].src

        } catch(e) {
            
            return null

        }

    }

    onError = () =>  this.setState({ error: true });

    // once this mounts it never needs to render again
    shouldComponentUpdate = (nextProps, nextState)  => nextState.error ? true : false

    render() {

        const { error } = this.state
        const { file } = this.props;

        const src = this.getSource(file)

        const unsupported = unsupportedFileTypes.includes(file.type)

        if(!src || error || unsupported) {

            return <div className="text-muted text-sm font-weight-bold text-right">No Preview Available</div>

        } else {

            return (
                <div style={{maxHeight: 150, overflow: 'hidden'}} className="z-depth-1 mb-2">
                    <img  src={file.previewElement.childNodes[0].src} style={{width: '100%'}} alt={file.name} onError={this.onError}/>
                </div>

            )
            
        }

    }

}

DropZonePreview.propTypes = {
    src         : PropTypes.string,
    alt         : PropTypes.string,
    style       : PropTypes.object,
    className   : PropTypes.string,
}

export default DropZonePreview;
