import { connect } from 'react-redux';

import SearchCollections from "components/system/Search/Collections";

import WorkflowContactSelection from 'components/system/WorkflowContactSelection';

const ActionSendDocumentForSignature = ({ node, onInputChange, selected_division }) => {

    return (
        <div>
    
            <div className='sidebar-section border-bottom'>
                <SearchCollections
                    collection={'signing_templates'}
                    title="**Select The Documents To Send And The Parties Below"
                    placeholder="Search..."
                    value={node.data.signing_template}
                    onChange={(obj) => onInputChange('signing_template', obj.value)}
                    filter={{
                        division: selected_division._id,
                    }}
                /> 
            </div>
    
            <div className='sidebar-section border-bottom'>
                <WorkflowContactSelection 
                    contacts={node.data.workflow_contacts} 
                    onChange={(workflow_contacts) => onInputChange('workflow_contacts', workflow_contacts)} 
                />
            </div>
    
            <div className='sidebar-section border-bottom'>
                <p className='text-sm'>
                    <i className="fas fa-info-circle text-info" /> This action automatically sends the document selected above for signature to the parties selected below. 
                </p>
            </div>
    
        </div>
    )
}



const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(ActionSendDocumentForSignature);