import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import formatText from 'utils/formatText'



export default memo(({ id, data, isConnectable }) => {
  return (
    <>
        <Handle
            id="left"
            type="target"
            position={Position.Left}
            style={{ top: 15 }}
            isConnectable={isConnectable}
        />

        <div className="">
            <div className='node-header'>
                <i className="fa-solid fa-hourglass text-yellow mr-2" /> Wait
            </div>
           
            <div className='node-body'>
                <div className='node-item text-capitalize'>{data.wait_time ? formatText(data.wait_time).secondsToTime() : <span className='text-danger'>Time Not Set</span>}</div>
            </div>

            <div className='node-footer'>
                <div><small>Execution ID:{id}</small></div>
            </div>

        </div>

        <Handle
            id="rights"
            type="source"
            position={Position.Right}
            style={{ top: 10 }}
            isConnectable={isConnectable}
        />
        </>
  );
});