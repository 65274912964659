import { useCallback, useState } from "react";

import { Card } from "reactstrap";
import { connect } from 'react-redux';

import formatObject from 'utils/formatObject';

import api from 'api';

import TextButtonRow from 'components/markup/layout/TextButtonRow'
import Circle from 'components/markup/loading/Circle'
import ConfirmationModal from 'components/functional/modals/Confirmation'
import TableIcons from 'components/markup/icons/TableIcons'
import ModalToggler from 'components/functional/modals/Toggler'
import Table from 'components/functional/tables/Standard';
import ApiError from "components/markup/layout/ApiError";

import EmailSendersEdit from '../components/Edit'

import { toast } from 'react-toastify'
import { setAssociationErrors } from 'store/functions/system/system'

const EmailSendersAll = ({setTab, selected_division}) => {

    const [ loading, setLoading ] = useState(false);
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ emailSenderToDelete, setEmailSenderToDelete ] = useState(false)
    const [ error, setError ] = useState(false);

    // helper for this.downloadCSV and this.query
    const fetchData = useCallback((params, isCSV, shouldReload) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(shouldReload) setLoading(true)
        const query = await api.email_senders.search({ ...params, filter: {division: selected_division._id}, isCSV })
        if(shouldReload) setLoading(false)

        if(!query.data) return setError(query.message)
        return resolve(query.data)
    }), [sizePerPage, selected_division._id])

    // run query to get data from the server
    const query = useCallback(async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }, [fetchData])

    const onDeleteEmailSender = useCallback(async (user) => {
        const deleted = await api.email_senders.delete(emailSenderToDelete._id);
        if(deleted.success) {
            fetchData(null, null, true);
            toast.success('Email Sender Successfully Removed')
        } else {
            if(deleted.message[0] && deleted.message[0].err === 'associations') {
                setAssociationErrors(deleted.message[0].associations)
            } else {
                toast.error(`Internal Error, Please Try Again`)
            }
        }
    }, [fetchData, emailSenderToDelete])
 
    const columns = [
        {
            dataField: "given_name",
            text: 'Name',
            headerFormatter: () => {
                return <div>Name</div>
            },
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div>No Data To Show</div>
                )
                return (
                    <ModalToggler component={EmailSendersEdit} _id={row._id} onSaved={() => fetchData(null, null, true)}>
                        <span className="cursor-pointer text-capitalize">
                            {row.name}
                        </span>
                    </ModalToggler>
                )
                
            },
        },
        {
            dataField: "address_from",
            text: 'Emails',
            headerStyle: {width: 300},
            formatter: (cell, row) => (
                row.name === 'no data' ? '' :
                <div>
                    <div style={{whiteSpace: 'pre'}}>
                        <b className="text-dark" style={{width: 40, display: 'inline-block'}}>From: </b> {row.address_from}
                    </div>
                    <div style={{whiteSpace: 'pre'}}>
                        <b className="text-dark" style={{width: 40, display: 'inline-block'}}>Reply:</b> {row.address_reply}
                    </div>
                </div>
            )
        },
        {
            dataField: "address_line_1",
            text: 'Address',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : formatObject(row).address()
            )
        },
        {
            dataField: "created_at",
            text: 'Website',
            formatter: (cell, row) => row.name === 'no data' ? '' : row.website
        },
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right',},
            text: 'Actions',
            headerFormatter: () => {
                return <div>Actions</div>
            },
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                        <TableIcons
                            icons={[
                                { 
                                    icon: 'fas fa-edit',
                                    color: 'success', 
                                    wrapper: ModalToggler, 
                                    component: EmailSendersEdit,
                                    _id: row._id,
                                    onSaved: () => fetchData(null, null, true)
                                },
                                {  
                                    icon: 'fas fa-trash', 
                                    color: 'danger',
                                    onClick: () => setEmailSenderToDelete(row)
                                },
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(error) return <ApiError err={error} />
    if(loading) return <div><Circle /></div>

    return (

        <>

            <TextButtonRow 
                title={"All Email Senders"}
                button={(
                    <button onClick={() => setTab('create')} className="btn btn-success"><i className="fas fa-plus mr-2 " /> Add Email Sender</button>
                )}
            />

            <Card className="card-color card-primary table-fixed">
                <Table
                    tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                    placeholder="Search..."
                    query={query}
                    columns={columns}
                    data={tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}
                    sizePerPage={sizePerPage}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>

            <ConfirmationModal 
                showModal={emailSenderToDelete ? true : false}
                toggleModal={() => setEmailSenderToDelete(null)}
                title="Delete Email Sender"
                body={(
                    <span>
                        Are you sure you wish to delete the email sender {emailSenderToDelete ? emailSenderToDelete.name : ''}? This will break all ties for emails sending with this name, address, and reply addresses.
                    </span>
                )}
                onConfirmation={onDeleteEmailSender}
            />

        </>
    )

}


const mapStateToProps = state => {
	return {
        selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(EmailSendersAll);