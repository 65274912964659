import keys from 'keys';
import { useState, useCallback } from "react";
import { Row, Col, Input } from "reactstrap";
import { getMarkerPositionOnPage, getMarkerIcon } from './_functions/utils';

import ConfirmationModal from 'components/functional/modals/Confirmation';

import SelectContacts from './Modals/SelectContact'

import ModalToggler from 'components/functional/modals/Toggler'

import formatText from 'utils/formatText'

import Axios from 'axios';

import fileDownloader from 'js-file-download';

import { toast } from 'react-toastify'

const Sidebar = ({ 
    pdfData, 
    updateMarkerName, 
    onSetShowingParty, 
    showingParty, 
    markers, 
    contacts, 
    onAddContact, 
    isTemplate, 
    onRemoveContact, 
    onClearMarkers,
    configuration,
    toggleShowModalSaveTemplate,
    toggleShowModalSendDocument,
    toggleShowModalNotifications,
    send_emails_to
}) => {

    const [contactToRemove, setContactToRemove] = useState(false);
    const [showConfirmRemoveMarkers, setShowConfirmRemoveMarkers] = useState(false);

    const scrollToMarker = useCallback((marker) => {
        const position = getMarkerPositionOnPage(marker);
        if(position) window.scrollTo({top: position.top, left: 0})
    }, [])

    const onDownload = useCallback(async () => {
        try {
            const result = await Axios({
                method: 'get',
                url: keys.API_URL + `/v1/signing/templates/${pdfData._id}/download`,
                responseType:'arraybuffer',
                headers: {
                    authorization: `Bearer ${keys.SYSTEM_API_KEY}`
                },
                withCredentials: true,
            })
    
            if(result.headers && result.headers['content-type'].includes('application/json')) {

                try {
                    var decodedString = String.fromCharCode.apply(null, new Uint8Array(result.data));
                    var body = JSON.parse(decodedString);
                    if(body.message && body.message.length) {
                        return toast.error(body.message[0] ? body.message[0] : `Something's Not Right, Please Try Again Later`)
                    }
                } catch(e) {
                    return toast.error(`Something went wrong downloading this document`)
                }
            }

            let filename = result.headers.filename;

            if(filename && filename.includes('attachment; filename=')) {
                filename = filename.replace('attachment; filename=', '')
                filename = filename.replace(/"/g, '')
            }

            if(!filename.includes('.pdf')) filename = filename + '.pdf'

            fileDownloader(result.data, filename) 

        } catch(e) {
            toast.error(`Could not download document at this time, please try again`)
        }
    }, [pdfData])

    const scrollSidebar = useCallback((i) => {
        onSetShowingParty(i + 1)

        // const contactBox = document.getElementById(`archk-docs-sidebar-contact-${i}`)
        // if(contactBox) contactBox.scrollIntoView()
    }, [onSetShowingParty])

    const renderMarker = useCallback((marker, i) => (
        <li key={marker.left.toString() + marker.top.toString()} onClick={() => scrollToMarker(marker)}>
            
            <Row className="marker-row">
                <Col xs={12}>
                <i className="indent-icon fas fa-reply fa-rotate-180 mr-2 text-muted" />
                    <span className="icon-identifier">{getMarkerIcon(marker)}</span>
                    <span className="text-capitalize text-sm">
                        { i + 1}){' '}
                        {marker.type ? marker.type : <span> <i className="fas fa-exclamation-triangle mr-2 text-danger" /> type not set</span>}
                    </span>
                    <span className={`${marker.type} party-${marker.party} page-number`}>
                        Page {marker.pageNumber}
                    </span>
                </Col>
            </Row>
            {marker.type === 'text' ? (
                <div className="mt-3">
                    <Input 
                        style={{
                            border: '1px solid rgb(221, 221, 221)',
                            background: 'white',
                            borderRadius: '8px' ,
                            fontSize: 12
                        }}
                        value={marker.name}
                        type="textarea"
                        onChange={(e) => updateMarkerName(e.target.value, i)}
                    />
                </div>
            ) : null}
        </li>
    ), [updateMarkerName, scrollToMarker])

    const renderNoMarker = useCallback(() => (
        <li><p className="text-sm mb-0 text-warning no-markers pl-5">No Markers Placed</p></li>
    ), [])

    const partyMarkers1 = markers.filter(m => m.party === 1);
    const partyMarkers2 = markers.filter(m => m.party === 2);
    const partyMarkers3 = markers.filter(m => m.party === 3);
    const partyMarkers4 = markers.filter(m => m.party === 4);
    
    const internalMarkers = markers.filter(m => m.party === 0);

    return (
        <div id="archk-template-docs-sidebar" className="archk-template-docs-sidebar">

            {isTemplate && !pdfData ? (
                <div className="sidebar-disabled" />
            ) : null}

            <div>
                <div className="section bg-secondary pt-3 pb-3 border-bottom">
                    <h3 className="mb-0 font-weight-bold text-uppercase mb-1">
                        {isTemplate ? 'Parties' : 'Contacts'} ({contacts.length})
                        <span className="float-right mr-2">
                            <ModalToggler configuration={configuration} contacts={contacts} component={SelectContacts} onAddContact={onAddContact}>
                                <i className="fas fa-user-plus cursor-pointer viewing-icon viewing-icon-contacts" />
                            </ModalToggler>
                        </span>
                    </h3>
                </div>

                <ul className="contacts-wrapper">
                    {contacts.map((contact, i) => (
                        <div key={i} className="contacts-inner-wrapper position-relative">
                            <div id={`archk-docs-sidebar-contact-${i}`} className={` ${showingParty === i + 1 ? 'active-wrapper' : 'non-active-wrapper'}`}>

                                <div onClick={() => scrollSidebar(i)}  className={showingParty === i + 1 ? 'd-none' : 'party-white-out cursor-pointer'}>
                                    <span className="text-white" style={{textShadow: '2px 2px 2px black'}}>Click To Add Markers</span>
                                </div>

                                <li>
                                    <Row>
                                        <Col xs={9} className="align-self-center">
                                            <p className="text-sm mb-0 text-uppercase"><i className="fas fa-user-circle text-dark " /> {contact.display_name ? contact.display_name : 'Name Not Found'}</p>
                                            <p className="text-sm mb-0">
                                                {
                                                    contact.email ? formatText(contact.email).trimEmail(20) : 
                                                    contact.phone ? formatText(contact.phone).phone() : 
                                                    isTemplate ? '' : 'Email & Phone Not Found'
                                                }
                                            </p>

                                        </Col>
                                        <Col xs={3} className="align-self-center text-right">
                                            <i onClick={() => setContactToRemove(contact)} className="fas fa-trash text-danger cursor-pointer viewing-icon px-3" />
                                        </Col>
                                    </Row>
                                </li>
                                <ul className="hover-pop">
                                    { 
                                        i === 0 ? partyMarkers1.length ? partyMarkers1.map((marker, i) => renderMarker(marker, i)) : renderNoMarker(0) :
                                        i === 1 ? partyMarkers2.length ? partyMarkers2.map((marker, i) => renderMarker(marker, i)) : renderNoMarker(0) :
                                        i === 2 ? partyMarkers3.length ? partyMarkers3.map((marker, i) => renderMarker(marker, i)) : renderNoMarker(0) :
                                        i === 3 ? partyMarkers4.length ? partyMarkers4.map((marker, i) => renderMarker(marker, i)) : renderNoMarker(0) : null
                                    }
                                </ul>
                            </div>
                        </div>
                    ))}

                </ul>
            </div>

            <div className="markup-inner-wrapper position-relative">
                <div id={`archk-docs-sidebar-markup`} className={` ${showingParty === 0 ? 'active-wrapper' : 'non-active-wrapper'}`}>

                    <div onClick={() => scrollSidebar(-1)}  className={showingParty === 0? 'd-none' : 'party-white-out cursor-pointer'}>
                        <span className="text-white" style={{textShadow: '2px 2px 2px black'}}>Click To Add Markers</span>
                    </div>

                    <div className="section sidebar-party bg-secondary">
                        <h3 className="mb-0 font-weight-bold text-uppercase">Markup ({markers.filter(p => p.party === 0).length})</h3>
                    </div>
                    <ul>
                        <ul className="hover-pop">
                            {internalMarkers.length ? internalMarkers.map((marker, i) => renderMarker(marker, i)) : renderNoMarker(3)}
                        </ul>
                    </ul>
                </div>
            </div>
           

            {pdfData ? (
                <div className="mt--2">
                    <div className="section bg-secondary border-top">
                        <h3 className="mb-2 text-center mt-3">PDF Actions</h3>
                    </div>

                    <ul>
                        {pdfData._id ? (
                            <li className="py-3">
                                <button 
                                    onClick={() => onDownload()} 
                                    className="btn btn-sm btn-outline-info btn-block"
                                >
                                    Download Document
                                </button>
                            </li>
                        ) : ''}
                        <li className="py-3">
                            <button 
                                onClick={() => setShowConfirmRemoveMarkers(true)} 
                                className="btn btn-sm btn-outline-warning btn-block"
                            >
                                Clear
                            </button>
                        </li>
                        <li className="py-3">
                            <button 
                                onClick={toggleShowModalNotifications} 
                                className="btn btn-sm btn-outline-info btn-block"
                            >
                                Notifications ({send_emails_to.length})
                            </button>
                        </li>

                        {isTemplate ? (
                            <li className="py-3">
                                <button onClick={toggleShowModalSaveTemplate} className="btn btn-sm btn-outline-success btn-block">
                                    <i className="fas fa-save mr-2 " />Save Template
                                </button>
                            </li>
                        ) : (
                            <li className="py-3">
                                <button onClick={toggleShowModalSendDocument} className="btn btn-sm btn-outline-success btn-block">
                                    <i className="fas fa-paper-plane mr-2 " />Send Document
                                </button>
                            </li>
                        )}
                        
                    </ul>

                </div>
            ) : null}

            <ConfirmationModal 
                showModal={contactToRemove ? true : false}
                toggleModal={() => setContactToRemove(false)}
                title={"Remove Client From Signing"}
                onConfirmation={() => onRemoveContact(contactToRemove)}
                body={(
                    <span>
                        Are you sure you wish to remove{' '}
                        {
                            contactToRemove.display_name ? contactToRemove.display_name : 
                            contactToRemove.email ? contactToRemove.email : 
                            contactToRemove.phone ? formatText(contactToRemove.phone).phone() : 
                            'this contact'
                        }{' '} 
                        from the list of parties who will sign this document?

                        <span className="d-block">All markers associated with this client will be removed.</span>
                        
                    </span>
                )}
            />

            <ConfirmationModal 
                showModal={showConfirmRemoveMarkers}
                toggleModal={() => setShowConfirmRemoveMarkers(false)}
                title={"Remove Document Markers"}
                onConfirmation={onClearMarkers}
                body={(
                    <span>
                        Are you sure you wish to remove all current markers that have been placed on this document?
                    </span>
                )}
            />

        </div>
    )

}

export default Sidebar
