import { Row, Col } from "reactstrap";
import { connect } from 'react-redux';
import { useCallback, useState } from 'react';

import Privilege  from "./Privilege";

const UsersEditPrivilegesStandard = ({user, setUser, onPrivilegeChange, PRIVILEGES, DOMAINS}) => {

    const [standardPrivileges] = useState([
        PRIVILEGES['VIEW.CAN_VIEW_ADMIN_DASHBOARD'],
        PRIVILEGES['VIEW.ADMIN_DASHBOARD.OVERVIEW'],
        PRIVILEGES['VIEW.ADMIN_DASHBOARD.MARKETING'],
        PRIVILEGES['VIEW.ADMIN_DASHBOARD.ANALYZE'],
        PRIVILEGES['VIEW.ADMIN_DASHBOARD.COMMUNICATE'],
        PRIVILEGES['VIEW.ADMIN_DASHBOARD.AUTOMATE'],
        PRIVILEGES['VIEW.ADMIN_DASHBOARD.STAFFING'],
        PRIVILEGES['VIEW.ADMIN_DASHBOARD.SYSTEM'],
        PRIVILEGES['VIEW.ADMIN_DASHBOARD.LAYOUT'],
        PRIVILEGES['VIEW.ADMIN_DASHBOARD.COMPANY'],
        // PRIVILEGES['VIEW.ADMIN_DASHBOARD.FINANCES'],
        // PRIVILEGES['VIEW.ADMIN_DASHBOARD.DEVELOPERS'],
        PRIVILEGES['VIEW.ADMIN_DASHBOARD.CUSTOM_VIEWS'],
        PRIVILEGES['VIEW.ADMIN_DASHBOARD.DEVELOPERS'],
    ])

    const assignAll = useCallback(() => {
        const newUser = Object.assign({}, user)
        standardPrivileges.forEach(privilege => {
            if(!newUser.privileges.includes(privilege)) {
                newUser.privileges.push(privilege)
            }
        })
     
        setUser(newUser);
    }, [standardPrivileges, setUser, user])
   
    const removeAll = useCallback(() => {
        const newUser = Object.assign({}, user)
        standardPrivileges.forEach(privilege => {
            newUser.privileges = newUser.privileges.filter(p => p !== privilege)
        })
     
        setUser(newUser);
    }, [standardPrivileges, setUser, user])

    return (
        <div>

            <Row>
                <Col xs={6} className="align-self-center">
                    <h2>ADMIN DASHBOARD</h2>
                </Col>
                <Col xs={6} className="align-self-center text-right">
                    <button onClick={removeAll} className="btn btn-outline-warning">Remove All</button>
                    <button onClick={assignAll} className="btn btn-outline-info">Assign All</button>
                </Col>
            </Row>

            <Privilege 
                privilege={PRIVILEGES['VIEW.CAN_VIEW_ADMIN_DASHBOARD']}
                user={user}
                title={`Can View Admin Application Dashboard - ${DOMAINS.ADMIN}`}
                onPrivilegeChange={onPrivilegeChange}
            />

            <div className="pl-7 pt-3">
                <div className="pl-3">
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.ADMIN_DASHBOARD.OVERVIEW']}
                        user={user}
                        title={`Can View Pages In The Overview Tab`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.ADMIN_DASHBOARD.MARKETING']}
                        user={user}
                        title={`Can View Pages In The Marketing Tab`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.ADMIN_DASHBOARD.ANALYZE']}
                        user={user}
                        title={`Can View Pages In The Analyze Tab`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.ADMIN_DASHBOARD.COMMUNICATE']}
                        user={user}
                        title={`Can View Pages In The Communicate Tab`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.ADMIN_DASHBOARD.AUTOMATE']}
                        user={user}
                        title={`Can View Pages In The Automate Tab`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.ADMIN_DASHBOARD.STAFFING']}
                        user={user}
                        title={`Can View Pages In The Staffing Tab`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.ADMIN_DASHBOARD.SYSTEM']}
                        user={user}
                        title={`Can View Pages In The System Tab`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.ADMIN_DASHBOARD.LAYOUT']}
                        user={user}
                        title={`Can View Pages In The Layout Tab`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.ADMIN_DASHBOARD.COMPANY']}
                        user={user}
                        title={`Can View Pages In The Company/Divisions Tabs`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.ADMIN_DASHBOARD.CUSTOM_VIEWS']}
                        user={user}
                        title={`Can View Pages In The Custom Views Tab`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                    <Privilege 
                        privilege={PRIVILEGES['VIEW.ADMIN_DASHBOARD.DEVELOPERS']}
                        user={user}
                        title={`Can View Pages In The Developers Tab`}
                        onPrivilegeChange={onPrivilegeChange}
                    />
                </div>
            </div>

        </div>
    )

}


const mapStateToProps = state => {
	return {
	    PRIVILEGES: state.config.PRIVILEGES,
        DOMAINS: state.load.DOMAINS,

	};
};

export default connect(mapStateToProps, '')(UsersEditPrivilegesStandard);