import { useCallback, useEffect, useState } from 'react';
import { FormGroup, Input } from "reactstrap";

import api from 'api';

import Modalify from 'components/functional/modals/Modalify';

import { toggleStandardLoader } from 'store/functions/system/system';
import { toast } from 'react-toastify';

const baseState = {
    admin: false,
    user: false,
    link_1: '',
    link_2: '',
    link_3: '',
    link_4: '',
}

const SystemCustomViewEdit = ({_id, onSaved, showModal, toggleModal}) => {

    const [customView, setView] = useState('')
    const [err, setErr] = useState(null)

    const fetchData = useCallback(async () => {
        if(!_id || _id === 'create') return setView(baseState);
        const user = await api.custom_views.findById(_id)
        if(!user.data) return setErr(true);
        setView(user.data)
    }, [_id])

    const onInputChange = useCallback(( field, value) => {
        const newView = JSON.parse(JSON.stringify(customView))
        newView[field] = value;
        setView(newView);
    }, [customView])
   
    const onSave = useCallback(async () => {
        setErr(false)
        let newState = JSON.parse(JSON.stringify(customView));

        if(!newState.user && !newState.admin) {
            return setErr([`A custom view must be displayed to either a user or an admin.`])
        }
      
        if(!newState.name) {
            return setErr([`A custom view must have a name.`])
        }
       
        if(!newState.link_1) {
            return setErr([`A custom view must at least one link specified under link #1.`])
        }
        
        setView(newState)

        let saved;

        // toggle loaders and update/create the user
        toggleStandardLoader(true)
        if(newState._id) {
            saved = await api.custom_views.update(newState._id, newState);
        } else {
            saved = await api.custom_views.create(newState);
        }
        toggleStandardLoader(false)

        // show error messages if unsuccessful
        if(saved.success) {
            toast.success('Custom View Saved Successfully')
            fetchData();
            if(toggleModal) toggleModal();
            if(onSaved) onSaved(saved.data)
        } else {
            return setErr(saved.message);
        }
    
    }, [customView, fetchData, toggleModal, onSaved])

    useEffect(() => {
        if(toggleModal) {
            if(showModal) {
                fetchData()
            } else {
                setErr(null)
                setView(baseState)
            }
        } else {
            fetchData()
        }
    }, [showModal, toggleModal, fetchData])

    return (
        <Modalify 
            showModal={showModal}
            toggleModal={toggleModal}
            modalTitle={customView && customView._id ? "Update Custom View" : "Create Custom View"}
            err={err}
            footer={(
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            )}
            sections={!customView ? [] :  [
                (
                    <div>
                        <h2>View Name</h2>
        
                        <FormGroup>
                            <label className="form-control-label">City</label>
                            <Input 
                                type="text"
                                value={customView.name || ''}
                                onChange={e => onInputChange('name', e.target.value)}
                            />
                        </FormGroup>    
                    </div>
                ),
                (
                    <div>
                        <h2>View Links</h2>
        
                        <FormGroup>
                            <label className="form-control-label">URL #1*</label>
                            <Input 
                                type="text"
                                value={customView.link_1 || ''}
                                onChange={e => onInputChange('link_1', e.target.value)}
                            />
                        </FormGroup>    
                        <FormGroup>
                            <label className="form-control-label">URL #2</label>
                            <Input 
                                type="text"
                                value={customView.link_2 || ''}
                                onChange={e => onInputChange('link_2', e.target.value)}
                            />
                        </FormGroup>    
                        <FormGroup>
                            <label className="form-control-label">URL #3</label>
                            <Input 
                                type="text"
                                value={customView.link_3 || ''}
                                onChange={e => onInputChange('link_3', e.target.value)}
                            />
                        </FormGroup>    
                        <FormGroup>
                            <label className="form-control-label">URL #4</label>
                            <Input 
                                type="text"
                                value={customView.link_4 || ''}
                                onChange={e => onInputChange('link_4', e.target.value)}
                            />
                        </FormGroup>    
                    </div>
                ),
                (
                    <div>
                        <h2>Permission Types</h2>

                        <FormGroup>
                            <div className="custom-control custom-checkbox">
                                <input
                                    className="custom-control-input"
                                    id="archk-custom-view-admin"
                                    type="checkbox"
                                    checked={customView.admin}
                                    onChange={e => onInputChange('admin', !customView.admin)}
                                />
                                <label className="custom-control-label" htmlFor="archk-custom-view-admin">
                                    Display On Admin Dashboard
                                </label>
                            </div>
                        </FormGroup>
                        
                        <FormGroup>
                            <div className="custom-control custom-checkbox">
                                <input
                                    className="custom-control-input"
                                    id="archk-custom-view-user"
                                    type="checkbox"
                                    checked={customView.user}
                                    onChange={e => onInputChange('user', !customView.user)}
                                />
                                <label className="custom-control-label" htmlFor="archk-custom-view-user">
                                    Display On User Dashboard
                                </label>
                            </div>
                        </FormGroup>


                    </div>
                )
            ]}
        />
    )

}

export default SystemCustomViewEdit