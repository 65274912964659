import { useCallback, useState } from 'react'

import { Modal, FormGroup } from "reactstrap";

import { toggleStandardLoader } from 'store/functions/system/system';

import api from 'api'

import ReactSelect from 'components/functional/inputs/ReactSelect'

import { toast } from 'react-toastify'

const AutomationsEditAddTask = ({showModal, toggleModal, fetchTasks, tasks, taskToDelete, getName}) => {

    const [migrateTo, setMigrateTo] = useState('false')

    const onSave = useCallback(async (state) => {

        toggleStandardLoader(true);
        const data = await api.automation_tasks.delete(taskToDelete._id, {
            migrate_to: migrateTo && migrateTo !== 'false' ? migrateTo : undefined
        })
        toggleStandardLoader(false);

        if(data.success) {
            fetchTasks()
            toggleModal();
        } else {
            toast.error(`Please try again`)

        }


    }, [fetchTasks, migrateTo, taskToDelete._id, toggleModal])

    let canMoveTo = [];

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >

            <div className="modal-header">
                <h5 className="modal-title">Delete A Task</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body bg-secondary">
                <p className="text-sm">Are you sure you wish to delete the following task:</p>
                <p className="text-sm text-capitalize mb-0 font-weight-bold">Type: {taskToDelete.type}</p>
                <p className="text-sm mb-0">{getName(taskToDelete)}</p>
            </div>
          
            <div className="modal-body border-top">
                <FormGroup>
                    <label className="form-control-label">If you wish you may move any running automations currently assigned to this task to another task within this automation. If you chose not to move any currently assigned automations, all assigned automations on this task will be deleted.</label>

                    <ReactSelect 
                        value={{}}
                        onChange={(obj) => setMigrateTo(obj.value)}
                        options={[
                            { value: false, label: 'DO NOT MOVE ASSIGNED FLOWS' },
                            ...canMoveTo
                        ]}
                    />

                </FormGroup>
            </div>

            <div className="modal-footer text-right">
                <button className="btn btn-neutral" onClick={toggleModal}>
                    <i className="fas fa-arrow-left mr-2" />
                    Back
                </button>
                <button className="btn btn-danger" onClick={onSave}>
                    <i className="fas fa-delete mr-2" />
                    Delete
                </button>
            </div>

        </Modal>

    )

}

export default AutomationsEditAddTask