import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';

export default memo(({ id, data, isConnectable }) => {
  return (
    <>

        <div className="">
            <div className='node-header'>
                <i className="fa-solid fa-forward text-success mr-2" /> Process Trigger
            </div>
           
            <div className='node-body'>
                <div className='node-item text-capitalize'>{data.type ? data.type.replace(/_/g , ' ') : <span className='text-danger'>Trigger Not Set</span>}</div>
            </div>

            <div className='node-footer'>
                <div><small>Execution ID:{id}</small></div>
            </div>

        </div>

        <Handle
            id="right"
            type="source"
            position={Position.Right}
            isConnectable={isConnectable}
        />
    </>
  );
});