import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import SearchCollections from "components/system/Search/Collections";
import WorkflowRolesSelection from 'components/system/WorkflowRolesSelection';

const SidebarRightUserVerification = ({ selectedNode, onNodeUpdated, selected_division }) => {

    const [node, setNode] = useState(selectedNode)

    const onInputChange = useCallback((field, value) => {
        const n = JSON.parse(JSON.stringify(node));
        n.data[field] = value;
        setNode(n)
    }, [node])

    useEffect(() => {
        setNode(JSON.parse(JSON.stringify(selectedNode)))
    }, [selectedNode])
   
    return (
        <div>

            <div className='sidebar-section border-bottom'>
                <div className="sidebar-item pb-0"><h2>Await User Verification</h2></div>
            </div>
    
            <div className='sidebar-section border-bottom'>

                <SearchCollections
                    collection={'users'}
                    title="If you wish a specific user to always be the one to approve this step select them below. If not select a case role. Note that if this is set the user you have selected here will be the one that approval is asked for and will overwrite the role selected below."
                    placeholder="Search..."
                    value={node.data.user}
                    onChange={(obj) => onInputChange('user', obj.value)}
                    filter={{
                        division: selected_division._id,
                    }}
                /> 

            </div>

            <div className='sidebar-section border-bottom'>
                <h2>Select Role For Verification</h2>
                <WorkflowRolesSelection 
                    roles={node.data.workflow_roles} 
                    onChange={(workflow_roles) => onInputChange('workflow_roles', workflow_roles)} 
                    multiSelect={false}
                />
            </div>

            <div className='sidebar-section text-right'>
                <button onClick={() => onNodeUpdated(node)} className='btn btn-success'>Save Node</button>
            </div>


        </div>
    )

}



const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SidebarRightUserVerification);