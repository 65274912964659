import { useCallback, useEffect, useState } from 'react';

import api from 'api';

import Circle from 'components/markup/loading/Circle';
import ApiError from 'components/markup/layout/ApiError';

const EditorCustomViews = ({match}) => {

    const [view, setView] = useState(null)
    const [err, setErr] = useState(false)

    const fetchData = useCallback(async () => {
        const data = await api.custom_views.findById(match.params.custom_view_id)
        if(!data.success) return setErr(data.message);

        setView(data.data)

    }, [match.params.custom_view_id])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    if(err) return <ApiError err={err} className="mx-4 py-4" />
    if(!view) return <Circle className="py-4" />

    let links = 0;

    if(view.link_1) links++
    if(view.link_2) links++
    if(view.link_3) links++
    if(view.link_4) links++

    let className = `archk-monitor-iframe-total-links-${links}`

    return (
        <div className="archk-monitor-iframe-wrapper">
            <div className={className}>

                <div className='archk-monitor-iframe archk-monitor-iframe-1 z-depth-1'>
                    <iframe src={view.link_1} title={view.link_1} />
                </div>

                {view.link_2 ? (
                    <div className='archk-monitor-iframe archk-monitor-iframe-2 z-depth-1'>
                        <iframe src={view.link_2} title={view.link_2} />
                    </div>
                ) : ''}

                {view.link_3 ? (
                    <div className='archk-monitor-iframe archk-monitor-iframe-3 z-depth-1'>
                        <iframe src={view.link_3} title={view.link_3} />
                    </div>
                ) : ''}

                {view.link_4 ? (
                    <div className='archk-monitor-iframe archk-monitor-iframe-4 z-depth-1'>
                        <iframe src={view.link_4} title={view.link_4} />
                    </div>
                ) : ''}

            </div>
        </div>
    )

}

export default EditorCustomViews