import EmailTemplatesEdit from './components/Edit'
import EmailTemplatesAll from './All'

import IntuideskSectionWrapper from 'components/markup/layout/IntuideskSectionWrapper'

const AutomationsEmailTemplates = ({match, section}) => (
     <IntuideskSectionWrapper 
        title="Pipelines"
        editTitle="Pipelines"
        match={match}
        section={section}
        innerSection={'pipelines'}
        Edit={EmailTemplatesEdit}
        All={EmailTemplatesAll}
    />
)

export default AutomationsEmailTemplates;