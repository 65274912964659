import WorkflowRolesSelection from 'components/system/WorkflowRolesSelection';

const AutomationsEditEmailWorkflowRoles = ({workflows, workflowRoles, roles, setRoles}) => {

    return (
        <div>

            <h2>From Role</h2>

            
            <WorkflowRolesSelection 
                roles={roles} 
                onChange={(workflow_roles) => setRoles(workflow_roles)} 
                multiSelect={false}
                useNullOption={true}
            />
        </div>
    )

}

export default AutomationsEditEmailWorkflowRoles