import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Input } from 'reactstrap';

import api from 'api';
import { toast } from 'react-toastify';

import ConfirmationModal from 'components/functional/modals/Confirmation';

const WorkflowEditLinksRoleRow = ({link, onDelete}) => {

    const [showDelete, setShowDelete] = useState(false)
    const [name, setName] = useState(link.name)
    const [description, setDescription] = useState(link.description)
    const [_link, setLink] = useState(link.link)

    const onSave = useCallback(async (field, value) => {
        const data = await api.workflow_links.update(link._id, { [field]: value })
        if(!data.success) toast.error(`Could not save workflow link, please try again`)
    }, [link._id])
    
    return (
        <React.Fragment key={link._id}>

            <tr>
                <td>
                    <Input 
                        value={name}
                        onChange={e => setName(e.target.value)}
                        onBlur={() => onSave('name', name)}
                    />
                </td>
                <td>
                    <Input 
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                        onBlur={() => onSave('description', description)}
                    />
                </td>
                <td>
                    <Input 
                        value={_link}
                        onChange={e => setLink(e.target.value)}
                        onBlur={() => onSave('link', _link)}
                    />
                </td>

                <td className="text-right">
                    <button className="btn btn-danger" onClick={() => setShowDelete(true)}>
                        <i className="fas fa-trash mr-2 " /> Remove Link
                    </button>
                </td>
            </tr>

            <ConfirmationModal 
                showModal={showDelete}
                toggleModal={() => setShowDelete(false)}
                title={"Delete Workflow Link"}
                onConfirmation={() => onDelete(link)}
                body={(
                    <span>
                        Are you sure you wish to delete the workflow link <b className='text-underline text-dark'>"{link.name}"</b>? This cannot be undone.
                    </span>
                )}
            />
  

        </React.Fragment>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(WorkflowEditLinksRoleRow);