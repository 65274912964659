import { useRef } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

const Icon = ({icon}) => {
    const ref = useRef(null)

    const tooltip = icon.tooltip ? (
        <UncontrolledTooltip
            delay={0}
            placement="top"
            target={ref}
        >
            {icon.tooltip}
        </UncontrolledTooltip>
    ) : '';


    return (
        <span className="table-icon-wrapper" >
            {icon.wrapper ? (
                <icon.wrapper {...icon}>
                    <span ref={ref} className={`table-icon table-icon-${icon.color} ${icon.disabled ? 'table-icon-disabled' : ''}`}>
                        <i className={icon.icon} />
                    </span>
                </icon.wrapper>
            ) : (
                <span ref={ref} onClick={icon.onClick ? icon.onClick : () => {}} className={`table-icon table-icon-${icon.color} ${icon.disabled ? 'table-icon-disabled' : ''}`}>
                    <i className={icon.icon} />
                </span>
            )}
            {icon.disabled ? '' : tooltip}
        </span>        
    )
}

const TableIcons = ({icons}) => (
    <div className="table-icons">
    {icons.map((icon, i) => <Icon key={i} icon={icon} />)}
</div>
)

export default TableIcons