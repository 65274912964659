import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';

import SigningTemplates from './SigningTemplates'
import MatterDocuments from './MatterDocuments'
import Tasks from './Tasks'
import Forms from './Forms'

import StepInfo from './StepInfo'
import Routing from './Routing'
import StaffAssistance from './StaffAssistance'
import Assignments from './Assignments'
import CustomFields from './CustomFields'
import Notifications from './Notifications'
import BackgroundProcesses from './BackgroundProcesses'

import InternalSidebar from 'components/markup/layout/InternalSidebar'

import ConfirmationModal from 'components/functional/modals/Confirmation';

import api from 'api'

const WorkflowsEditSidebarEdit = ({
    step, 
    onClose, 
    saveStep, 
    removeStep,
    steps,
    roles,
    stepCategories,
    dispositions,
    workflowCopy,
    contacts
}) => {

    const [customFields, setCustomFields] = useState(null);
    const [baseState, setBaseState] = useState(step);
    const [stepToEdit, setStepToEdit] = useState(step);

    const [tab, setTab] = useState('Step Info');
    const [tabToMove, setTabToMove] = useState('Step Info');

    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [disabled, setDisabled] = useState(false);
   
    const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);
    const [showChangeConfirmation, setShowChangeConfirmation] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const [errs, setErrs] = useState([]);

    const onTryClose = useCallback((_tab) => {
        if(unsavedChanges) {
            setShowCloseConfirmation(true)
        } else {
            onClose()
        }
    }, [onClose, unsavedChanges])

    const onSetTab = useCallback((_tab) => {
        if(unsavedChanges) {
            setTabToMove(_tab)
            setShowChangeConfirmation(true)
        } else {
            setTab(_tab)
        }
    }, [unsavedChanges])

    const onForceMove = useCallback(() => {
        setTab(tabToMove)
        setStepToEdit(baseState)
        setUnsavedChanges(false)
    }, [tabToMove, baseState])

    const onSetStepToEdit = useCallback((field, value) => {
        const _stepToEdit = JSON.parse(JSON.stringify(stepToEdit));
        _stepToEdit[field] = value;

        setStepToEdit(_stepToEdit);
        setUnsavedChanges(true)
    }, [stepToEdit])

    const onAddArrayField = useCallback((field, value) => {
        const _stepToEdit = JSON.parse(JSON.stringify(stepToEdit));
        const arr = _stepToEdit[field];
        if(!arr.includes(value)) arr.push(value);
        setStepToEdit(_stepToEdit);
        setUnsavedChanges(true)
    }, [stepToEdit])
    
    const onRemoveArrayField = useCallback((field, value) => {
        const _stepToEdit = JSON.parse(JSON.stringify(stepToEdit));
        _stepToEdit[field] = _stepToEdit[field].filter(f => f !== value);
        setStepToEdit(_stepToEdit);
        setUnsavedChanges(true)
    }, [stepToEdit])

    const fetchCustomFields = useCallback(async () => {
        const fields = await api.custom_fields.find(step.division)
        if(fields.data) setCustomFields(fields.data)

    }, [step.division])

    const onSave = useCallback(() => {
        const _errs = [];

        let signingErr = false;
        let matterDocErr = false
        let formErr = false
        let taskErr = false

        if(!stepToEdit.name) _errs.push('name')
        
        stepToEdit.signing_templates.forEach(t => {
            if(!t.signing_template) signingErr = true;
        })

        stepToEdit.matter_documents.forEach(d => {
            if(!d.matter_document) matterDocErr = true;
        })
       
        stepToEdit.forms.forEach(d => {
            if(!d.form) formErr = true;
        })
       
        stepToEdit.tasks.forEach(d => {
            if(!d.task_template) taskErr = true;

            if(d.create_tasks_on_finish) {
                d.create_tasks_on_finish.forEach(dd => {
                    if(!dd.task_template) taskErr = true;
                })
            }
        })

        if(signingErr)   _errs.push('signing_template')
        if(matterDocErr) _errs.push('matter_document')
        if(formErr)      _errs.push('form')
        if(taskErr)      _errs.push('task')

        setErrs(_errs);
        if(_errs.length) return;

        setDisabled(true)
        saveStep(stepToEdit, () => {
            setErrs([])
            setUnsavedChanges(false)
            setBaseState(stepToEdit)
            setDisabled(false)
        });
    }, [stepToEdit, saveStep])

    const onRemoveStep = useCallback(() => {
        setDisabled(true)
        removeStep(stepToEdit, () => {
            setDisabled(false)
            onClose()
        });
    }, [stepToEdit, removeStep, onClose])

    useEffect(() => {
        setStepToEdit(step)
    }, [step])

    return (
        <div className="archk-workflow-sidebar-edit">

            <div className="archk-workflow-sidebar-edit-blackout" onClick={onTryClose} />

            <div className="archk-workflow-sidebar-edit-modal">
                
                <div className="archk-workflow-sidebar-spacer border-bottom">
                    <Row>
                        <Col xs={7} className="align-self-center">
                            <h2 className='mb-0 text-info'>
                                {
                                    stepToEdit.name && stepToEdit.name.length > 45 ? stepToEdit.name.slice(0, 50) + '...' : 
                                    stepToEdit.name ? stepToEdit.name :  
                                    '{{STEP NAME}}'
                                }
                            </h2>
                        </Col>
                        <Col xs={5} className="align-self-center text-right" >
                            <button className="btn btn-outline-info" onClick={onTryClose}>
                                <i className="fas fa-arrow-left mr-2 " /> Close Editor
                            </button>
                            <button disabled={disabled}  className="btn btn-success" onClick={onSave}>
                                <i className="fas fa-save mr-2 " /> Save
                            </button>
                        </Col>
                    </Row>
                </div>

                <div className="archk-workflow-sidebar-spacer">

                <InternalSidebar 
                    title={"Categories"}
                    tab={tab}
                    setTab={onSetTab}
                    tabs={[
                        { value: 'Step Info', name: 'Step Info' },
                        { value: 'Routing', name: 'Routing' },
                        { value: 'Staff Assistance', name: 'Staff Assistance' },
                        { value: 'Assignments', name: 'Assignments' },
                        { value: 'Signatures', name: 'Signatures' },
                        { value: 'Documents', name: 'Documents' },
                        { value: 'Forms', name: 'Forms' },
                        { value: 'Tasks', name: 'Tasks' },
                        { value: 'Notifications', name: 'Notifications' },
                        { value: 'Custom Fields', name: 'Custom Fields' },
                        { value: 'Background Processes', name: 'Processes' },
                    ]}
                >
                    {tab === 'Step Info' ? (
                        <StepInfo 
                            stepToEdit={stepToEdit} 
                            onSetStepToEdit={onSetStepToEdit} 
                            dispositions={dispositions}
                            stepCategories={stepCategories}
                            errs={errs}
                        />
                    ) : tab === 'Routing' ? (
                        <Routing 
                            stepToEdit={stepToEdit} 
                            onSetStepToEdit={onSetStepToEdit} 
                            steps={steps}
                            roles={roles}
                            onAddArrayField={onAddArrayField} 
                            onRemoveArrayField={onRemoveArrayField}
                        />
                    ) : tab === 'Staff Assistance' ? (
                        <StaffAssistance 
                            stepToEdit={stepToEdit} 
                            onSetStepToEdit={onSetStepToEdit} 
                            onRemoveArrayField={onRemoveArrayField} 
                            onAddArrayField={onAddArrayField} 
                            roles={roles} 
                        />
                    ) : tab === 'Assignments' ? (
                        <Assignments 
                            stepToEdit={stepToEdit} 
                            onAddArrayField={onAddArrayField} 
                            onRemoveArrayField={onRemoveArrayField}
                            onSetStepToEdit={onSetStepToEdit}
                        />
                    ) : tab === 'Signatures' ? (
                        <SigningTemplates
                            stepToEdit={stepToEdit} 
                            onSetStepToEdit={onSetStepToEdit} 
                            steps={steps} 
                            onAddArrayField={onAddArrayField} 
                            onRemoveArrayField={onRemoveArrayField}
                            setStepToEdit={setStepToEdit}
                            roles={roles}
                            contacts={contacts}
                            errs={errs}
                            fetchCustomFields={fetchCustomFields}
                            customFields={customFields}
                        />
                    ) : tab === 'Documents' ? (
                        <MatterDocuments 
                            stepToEdit={stepToEdit} 
                            onSetStepToEdit={onSetStepToEdit} 
                            steps={steps} 
                            setStepToEdit={setStepToEdit}
                            errs={errs}
                        />
                    ) : tab === 'Forms' ? (
                        <Forms 
                            stepToEdit={stepToEdit} 
                            onSetStepToEdit={onSetStepToEdit} 
                            steps={steps} 
                            setStepToEdit={setStepToEdit}
                            errs={errs}
                            contacts={contacts}
                        />
                    ) : tab === 'Tasks' ? (
                        <Tasks 
                            stepToEdit={stepToEdit} 
                            onSetStepToEdit={onSetStepToEdit} 
                            steps={steps} 
                            onAddArrayField={onAddArrayField} 
                            onRemoveArrayField={onRemoveArrayField}
                            setStepToEdit={setStepToEdit}
                            roles={roles}
                            workflowCopy={workflowCopy}
                            errs={errs}
                            contacts={contacts}
                        />
                    ) : tab === 'Notifications' ? (
                        <Notifications 
                            stepToEdit={stepToEdit} 
                            onSetStepToEdit={onSetStepToEdit} 
                            steps={steps} 
                            setStepToEdit={setStepToEdit}
                            roles={roles}
                            workflowCopy={workflowCopy}
                            errs={errs}
                            contacts={contacts}
                            fetchCustomFields={fetchCustomFields}
                            customFields={customFields}
                            setUnsavedChanges={setUnsavedChanges}
                        />
                    ) : tab === 'Custom Fields' ? (
                        <CustomFields 
                            stepToEdit={stepToEdit} 
                            onSetStepToEdit={onSetStepToEdit} 
                            steps={steps} 
                            onAddArrayField={onAddArrayField} 
                            onRemoveArrayField={onRemoveArrayField}
                            setStepToEdit={setStepToEdit}
                            roles={roles}
                            workflowCopy={workflowCopy}
                            errs={errs}
                            contacts={contacts}
                            fetchCustomFields={fetchCustomFields}
                            customFields={customFields}
                        />
                    ) : tab === 'Background Processes' ? (
                        <BackgroundProcesses 
                            stepToEdit={stepToEdit} 
                            onAddArrayField={onAddArrayField} 
                            onRemoveArrayField={onRemoveArrayField}
                        />
                    ) : null}
                </InternalSidebar>

                    

                </div>

                <div className="archk-workflow-sidebar-spacer border-top text-right">
                    <button disabled={disabled} className="btn btn-danger" onClick={() => setShowDeleteConfirmation(true)}>
                        <i className="fas fa-trash mr-2 " /> Delete Step
                    </button>
                    <button disabled={disabled} className="btn btn-success" onClick={onSave}>
                        <i className="fas fa-save mr-2 " /> Save
                    </button>
                </div>
                
            </div>

            <ConfirmationModal 
                showModal={showCloseConfirmation}
                toggleModal={() => setShowCloseConfirmation(false)}
                title="Unsaved Changes"
                nextButtonText="Proceed Anyway"
                body={(
                    <span>You currently have unsaved changes, are you sure you wish to close this editor? All current progress will be lost.</span>
                )}
                onConfirmation={onClose}
            />
            <ConfirmationModal 
                showModal={showChangeConfirmation}
                toggleModal={() => setShowChangeConfirmation(false)}
                title="Unsaved Changes"
                nextButtonText="Proceed Anyway"
                body={(
                    <span>You currently have unsaved changes, are you sure you wish to proceed? All current progress will be lost.</span>
                )}
                onConfirmation={onForceMove}
            />

            <ConfirmationModal 
                showModal={showDeleteConfirmation}
                toggleModal={() => setShowDeleteConfirmation(false)}
                title="Delete Step"
                nextButtonText="Yes, Delete This Step"
                body={(
                    <span>Are you sure you with to delete this step? This action cannot be undone.</span>
                )}
                onConfirmation={onRemoveStep}
            />

        </div>
    )
}

export default WorkflowsEditSidebarEdit;


