import { useCallback, useRef, useState } from 'react';
import { Col, FormGroup, Input, Row } from "reactstrap";

import { toggleAlertBS } from 'store/functions/system/system';
import formatText from 'utils/formatText';

import ReactSelect from 'components/functional/inputs/ReactSelect';

import ReactQuill from 'react-quill';


const SystemEventEdit = ({eventTemplate, setEventTemplate, minute, hour, day}) => {

    const reactQuillRef = useRef();

    const [showNotificationInfo, setShowNotificationInfo] = useState(false)
    const [notification, setNotification] = useState({ type: 'email', time_before: 0, for: 'contacts' })
    
    const onNotificationInputChange = useCallback(( field, value) => {
        const newNotification = JSON.parse(JSON.stringify(notification))
        newNotification[field] = value;
        setNotification(newNotification);
    }, [notification])

    const onAddNotification = useCallback(() => {
        const newEventTemplate = JSON.parse(JSON.stringify(eventTemplate))
        if(!notification.type) return;
        if(!notification.time_before && notification.time_before !== 0) return;
        if(!notification.body) return;

        const notifications = newEventTemplate.notifications ? newEventTemplate.notifications : []
        notifications.push(notification);

        if(notifications.length > 5) return toggleAlertBS('info', 'You may assign a maximum of 5 notifications per event template')
        
        newEventTemplate.notifications = notifications;
        setEventTemplate(newEventTemplate)
        setNotification({ type: 'email', time_before: 0, for: 'contacts' })

    }, [eventTemplate, notification, setEventTemplate])
   
    const onRemoveNotification = useCallback((index) => {
        const newEventTemplate = JSON.parse(JSON.stringify(eventTemplate))

        const notification = newEventTemplate.notifications
        notification.splice(index, 1)
   
        newEventTemplate.notifications = notification;
        setEventTemplate(newEventTemplate)
    }, [eventTemplate, setEventTemplate])

    return (
        <div>

            <Row>
                <Col lg={5}>
                    <div>

                        <h2>
                            Event Notifications 
                            <i onClick={() => setShowNotificationInfo(!showNotificationInfo)} className="fas fa-info-circle cursor-pointer ml-2 text-info" /> 
                        </h2>
                        {showNotificationInfo ? (
                            <p className="text-sm ">Event notifications are used to send emails and text messages out to either the contacts, users, or both that are assigned to an appointment. If no phone number or email address is found on a contact or user at the time the notification should go out no message will be sent.</p>
                        ) : ''}
                      
                        <ReactSelect 
                            formGroup={true}
                            title="Notification Type"
                            value={notification.type}
                            onChange={(obj) => onNotificationInputChange('type', obj.value)}
                            options={[
                                { value: 'email', label: 'Email' },
                                { value: 'sms', label: 'SMS' },
                            ]}
                        />
                        <ReactSelect 
                            formGroup={true}
                            title="Notification Recipients"
                            value={notification.for}
                            onChange={(obj) => onNotificationInputChange('for', obj.value)}
                            options={[
                                { value: 'contacts', label: 'Contacts' },
                                { value: 'users', label: 'Users' },
                                { value: 'contacts and users', label: 'Contacts & Users' },
                            ]}
                        />

                        <ReactSelect 
                            formGroup={true}
                            title="When should this notification go out before the event?*"
                            value={notification.time_before}
                            onChange={(obj) => onNotificationInputChange('time_before', obj.value)}
                            options={[
                                { value: 0, label: 'When the event is created' },
                                { value: minute * 5, label: '5 Minutes' },
                                { value: minute * 10, label: '10 Minutes' },
                                { value: minute * 15, label: '15 Minutes' },
                                { value: minute * 30, label: '30 Minutes' },
                                { value: minute * 45, label: '45 Minutes' },
                                { value: hour * 1, label: '1 Hour' },
                                { value: hour * 1.5, label: '1 Hour 30 minutes' },
                                { value: hour * 2, label: '2 hours' },
                                { value: hour * 3, label: '3 hours' },
                                { value: hour * 4, label: '4 hours' },
                                { value: hour * 5, label: '5 hours' },
                                { value: hour * 6, label: '6 hours' },
                                { value: hour * 7, label: '7 hours' },
                                { value: hour * 8, label: '8 hours' },
                                { value: hour * 16, label: '16 hours' },
                                { value: day * 1, label: '1 day' },
                                { value: day * 2, label: '2 days' },
                                { value: day * 3, label: '3 days' },
                                { value: day * 5, label: '5 days' },
                                { value: day * 7, label: '7 days' },
                                { value: day * 10, label: '10 days' },
                                { value: day * 14, label: '14 days' },
                                { value: day * 21, label: '21 days' },
                                { value: day * 28, label: '28 days' },
                                { value: day * 30, label: '30 days' },
                                { value: day * 45, label: '45 days' },
                                { value: day * 60, label: '60 days' },
                                { value: day * 75, label: '75 days' },
                                { value: day * 90, label: '90 days' },
                            ]}
                        />   

                        {notification.type === 'email' ? (
                            <>

                                <FormGroup className="hide-toolbar">
                                    <label className="form-control-label">Notification Body*</label>
                                    <ReactQuill 
                                        defaultValue={notification.body || ''}
                                        ref={reactQuillRef}
                                        onChange={(value) => onNotificationInputChange('body', value)} 
                                        theme="snow"
                                        modules={{
                                            toolbar: [
                                                ["bold", "italic"],
                                            ]
                                        }}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <label className="form-control-label">Notification Subject*</label>
                                    <Input 
                                        type="text"
                                        value={notification.subject || ''}
                                        onChange={e => onNotificationInputChange('subject', e.target.value)}
                                    />
                                </FormGroup>     

                                </>
                        ) : (
                            <FormGroup>
                                <label className="form-control-label">SMS Body*</label>
                                <Input 
                                    type="text"
                                    value={notification.body || ''}
                                    onChange={e => onNotificationInputChange('body', e.target.value)}
                                />
                            </FormGroup>     
                        )}


                        <div className="text-right" onClick={onAddNotification}>
                            <button className="btn btn-outline-success"><i className="fas fa-plus mr-2 " /> Add Notification</button>
                        </div>

                    </div>
                </Col>

                <Col lg={7}>
                    <div>
                        <h2>Assigned Notifications</h2>

                            <div className="table-responsive border">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Type</th>
                                            <th>For</th>
                                            <th>Body</th>
                                            <th>Subject</th>
                                            <th className="text-right">Time Before</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {eventTemplate.notifications && eventTemplate.notifications.length ? eventTemplate.notifications.map((notification, i) => (
                                            <tr key={notification.subject + i}>
                                                <td className="text-capitalize">{notification.type}</td>
                                                <td className="text-capitalize">{notification.for}</td>
                                                <td>{formatText(notification.body).stripHTML(40)}</td>
                                                <td>{formatText(notification.subject).stripHTML(40)}</td>
                                                <td className="text-right">{formatText(notification.time_before).secondsToTime()}</td>
                                                <td className="text-right">
                                                    <button onClick={() => onRemoveNotification(i)} className="btn btn-danger">Remove</button>
                                                </td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td><i className="fas fa-info-circle mr-2 text-info" /> No Notifications Created</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                    </div>
                </Col>
            </Row>
          
        </div>
    )

}

export default SystemEventEdit