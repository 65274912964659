import { useCallback, useRef, useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

import { CopyToClipboard } from "react-copy-to-clipboard";

const Copy = ({ text }) => {

    const ref = useRef();
    const [copied, setCopied] = useState(false);

    const onCopy = useCallback(() => {
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 2000)
    }, [])

    return (
        <>
    
            <CopyToClipboard
                text={text}
                onCopy={onCopy}
            >
                <span  ref={ref} className='cursor-pointer'>
                <i className="fas fa-copy ml-2 text-success cursor-pointer mr-2" />
                    {text}
                </span>
            </CopyToClipboard>

            <UncontrolledTooltip
                delay={0}
                trigger="hover focus"
                target={ref}
            >
                {copied ? "Copied!" : "Copy To Clipboard"}
            </UncontrolledTooltip>
        </>
    )
}

export default Copy;