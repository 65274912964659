
import { useState } from 'react';
import { connect } from 'react-redux';

import api from 'api';
import moment from 'moment';

import QueryCreator from '../_QueryCreator';

import TableIcons from 'components/markup/icons/TableIcons';
import A from 'components/markup/links/A';
import formatText from 'utils/formatText';


const AnalyticsPagesContacts = ({DOMAINS}) => {

    // this is unused and controlled on the server for cntacts
    const [sort, setSort] = useState({
        created_at: -1
    });
    
    return (
        <QueryCreator 
            title="Contact Reports"
            type="contacts"
            query={({filter, sort, range, limit, skip, isCSV}) => new Promise (async resolve => {
                const data = await api.analyze.contacts({ filter, sort, range, limit, skip, isCSV })
                return resolve(data)
            })}
            onSortChange={(_sort) => setSort(_sort)}
            sidebar={[
                {
                    title: 'Filters',
                    inputs: [
                        { type: 'trueOrFalse', property: 'display_name', name: 'Has Name Field' },
                        { type: 'trueOrFalse', property: 'phone', name: 'Has Main Phone #' },
                        { type: 'trueOrFalse', property: 'email', name: 'Has Main Email Address' },
                        { type: 'state', property: 'state', name: 'Select State' },

                        { type: 'number', property: 'calls_inbound', name: 'Calls Inbound' },
                        { type: 'number', property: 'calls_outbound', name: 'Calls Outbound' },

                        { type: 'trueOrFalse', property: 'deleted', name: 'Deleted' },
                
                    ]
                },

            ]}
            initialState={{

                deleted: null,
                phone: null,
                email: null,
                display_name: null,
                state: null,

                call_inbound: null,
                call_inbound_type: '>',
              
                call_outbound: null,
                call_outbound_type: '>',


            }}
            columns={[
                {
                    dataField: "display_name",
                    text: 'Display Name',
                    formatter: (cell, row) => {
        
                        if(row.name === 'no data') return (
                            <div className="first-cell">No Data To Show</div>
                        )
                        return (
                            <div className="first-cell">
                                <div><A href={`${DOMAINS.APP}/contacts/${row.id}`} className="text-dark font-weight-bold">{row.display_name}</A></div>
                            </div>
                        )
                    },
                },

                {
                    dataField: "phone",
                    text: "Phone Numbers",
                    formatter: (cell, row) => (
                        <div>
                            {row.phone ? <div>{formatText(row.phone).phone()}</div> : ''}
                            {row.phone_2 ? <div>{formatText(row.phone_2).phone()}</div> : ''}
                            {row.phone_3 ? <div>{formatText(row.phone_3).phone()}</div> : ''}
                        </div>
                    )
                },
                {
                    dataField: "email",
                    text: "Emails",
                    formatter: (cell, row) => (
                        <div>
                            {row.email ? <div>{row.email}</div> : ''}
                            {row.email_2 ? <div>{row.email_2}</div> : ''}
                            {row.email_3 ? <div>{row.email_3}</div> : ''}
                        </div>
                    )
                },
                {
                    dataField: "address",
                    text: "Address",
                    formatter: (cell, row) => (
                        <div>
                            <div>{row.address_line_1} {row.address_line_2 ? <span> - {row.address_line_2}</span> : ''}</div>
                            <div>{row.city ? row.city + ', ' : ''} {row.state} {row.postal_code} </div>
                        </div>
                    )
                },
                {
                    dataField: "deleted",
                    text: "Deleted",
                    formatter: (cell, row) => (
                        row.deleted ? <b className='text-danger'>Yes</b> : <b className='text-success'>No</b>
                    )
                },
                {
                    dataField: "calls_inbound",
                    text: "Calls Inbound",
                },
                {
                    dataField: "calls_outbound",
                    text: "Calls Outbound",
                },

                {
                    dataField: "created_at",
                    text: "Created At",
                    formatter: (cell, row) => {
        
                        if(row.name === 'no data') return ''
                        return moment.unix(row.created_at).format('MM/DD/YYYY h:mm A')
        
                    },
                },
              
               
                {
                    dataField: "_id",
                    headerStyle: {textAlign: 'right', width: 75},
                    text: '',
                    formatter: (cell, row) => (
                        row.name === 'no data' ? '' :
                        <div className="text-right last-cell">
                            <TableIcons
                                icons={[
                                    { 
                                        icon: 'fas fa-external-link-alt',
                                        color: 'info', 
                                        wrapper: A, 
                                        href: `${DOMAINS.APP}/contacts/${row.id}`,
                                    },

                                ]}
                            />
                        </div>
                    )
                },
            ]
        }
        />
    )
}



const mapStateToProps = state => {
	return {
	    DOMAINS: state.load.DOMAINS,
	};
};

export default connect(mapStateToProps, '')(AnalyticsPagesContacts);
