import { useEffect, useState } from 'react';
import { Modal } from 'reactstrap';

import getMarkerErrors from '../../_functions/getMarkerErrors'

const SaveTemplate = ({showModal, toggleModal, isTemplate, markers, contacts, onSaveTemplate, docName}) => {

    const [errs, setErrs] = useState([]);
    const hasErr = errs.length

    useEffect(() => {
        if(showModal) {
            const _errs = getMarkerErrors(markers, contacts, isTemplate);
            if(!docName) _errs.push('This document must have a name.')
            setErrs(_errs)
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal, docName])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Save Template</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">
                {hasErr ? (
                    <div>
                        <p className="text-sm">Please correct the following error(s) before saving this template:</p>
                        <ol className='pl-4'>
                            {errs.map((err, i) => (
                                <li key={i} className="text-sm mb-2">{err}</li>
                            ))}
                        </ol>
                    </div>
                ) : (
                    <div>All set, this document template is free of errors and is ready to be saved. </div>
                )}
            </div>

            <div className="modal-footer">
                {hasErr ? (
                    <button onClick={toggleModal} className="btn btn-outline-warning">
                        <i className="fas fa-arrow-left mr-2" /> Back
                    </button>
                ) : (
                    <button onClick={onSaveTemplate} className="btn btn-outline-success">
                        <i className="fas fa-save mr-2 " /> Save Template
                    </button>
                )}
            </div>

        </Modal>
    )
}

export default SaveTemplate
