
import { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import api from 'api';
import moment from 'moment';

import QueryCreator from '../_QueryCreator';

import TableIcons from 'components/markup/icons/TableIcons';
import A from 'components/markup/links/A';
import ObjectFinder from 'components/system/Objects/Finder';
// import { formatCurrency } from 'utils/currency';


const AnalyticsPagesMatters = ({selected_division, DOMAINS}) => {

    // only used to track column headers, does not control state anywhere other than this file
    const [sort, setSort] = useState({
        type: 'created_at',
        field: ''
    });
    
    return (
        <QueryCreator 
            title="Matter Reports"
            type="matters"
            query={({filter, sort, range, limit, skip, isCSV}) => new Promise (async resolve => {
                const data = await api.analyze.matters({ filter, sort, range, limit, skip, isCSV })
                console.log(filter)
                return resolve(data)
            })}
            onSortChange={(_sort) => setSort(_sort)}
            sidebar={[
                {
                    title: 'Associations',
                    inputs: [
                        { type: 'arrayItem', property: 'workflows', name: 'Workflows' },
                        { type: 'arrayItem', property: 'division_departments', name: 'Departments' },
                        { type: 'arrayItem', property: 'workflow_step_categories', name: 'Step Categories' },
                        { type: 'arrayItem', property: 'workflow_dispositions', name: 'Step Dispositions' },
                        { type: 'arrayItem', property: 'workflow_steps', name: 'Current Steps' },
                        { type: 'arrayItem', property: 'users', name: 'Users On Case' },
                        { type: 'arrayItem', property: 'tags', name: 'Tags' },
                        { type: 'arrayItem', property: 'lead_sources', name: 'Lead Sources' },
                        { type: 'arrayItem', property: 'created_by', name: 'Created By' },
                        { type: 'arrayItem', property: 'custom_dates', name: 'Custom Date' },
                    ]
                },
                {
                    title: 'Statuses',
                    inputs: [
                        { type: 'trueOrFalse', property: 'current_step_overdue', name: 'Step Is Overdue' },
                        { type: 'trueOrFalse', property: 'missing_events', name: 'Missing Event(s)' },
                        { type: 'trueOrFalse', property: 'upcoming_events', name: 'Has Upcoming Event(s)' },
                        { type: 'trueOrFalse', property: 'outstanding_items', name: 'Has Outstanding Items' },
                        { type: 'trueOrFalse', property: 'missing_documents', name: 'Has Missing Document(s)' },
                        { type: 'trueOrFalse', property: 'unfinished_tasks', name: 'Has Unfinished Task(s)' },
                        { type: 'trueOrFalse', property: 'unsigned_documents', name: 'Has Unsigned Document(s)' },
                        { type: 'trueOrFalse', property: 'unanswered_fields', name: 'Has Unanswered Field(s)' },
                        { type: 'trueOrFalse', property: 'unfinished_forms', name: 'Has Unfinished Form(s)' },
                    ]
                },
                {
                    title: 'Percentages',
                    inputs: [
                        { type: 'number', property: 'current_step_percentage', name: 'Overall Step %' },
                        { type: 'number', property: 'current_step_doc_percentage', name: 'Document %' },
                        { type: 'number', property: 'current_step_sign_percentage', name: 'Signature %' },
                        { type: 'number', property: 'current_step_task_percentage', name: 'Task %' },
                        { type: 'number', property: 'current_step_field_percentage', name: 'Custom Field %' },
                        { type: 'number', property: 'current_step_form_percentage', name: 'Form %' },
                    ]
                },
                {
                    title: 'Payments',
                    inputs: [
                        { type: 'number', property: 'billing_total', name: 'Total Value' },
                        { type: 'number', property: 'billing_paid', name: 'Amount Paid' },
                        { type: 'number', property: 'billing_in_trust', name: 'Amount In Trust' },
                        { type: 'number', property: 'payment_previous_amount', name: 'Last Payment Amount' },
                        { type: 'number', property: 'payment_recurring', name: 'Recurring Plan Amount' },
                        { type: 'number', property: 'next_payment_success_rate', name: 'Next Payment Success Rate' },
                        { type: 'trueOrFalse', property: 'payment_overdue', name: 'Payment Plan Overdue' },
                        { type: 'dropdown', property: 'payment_previous_status', name: 'Last Payment Status', options: [
                            { label: 'Succeeded', value: 'succeeded' },
                            { label: 'Failed', value: 'failed' },
                            { label: 'Refunded', value: 'refunded' },
                        ]},
                        { type: 'dropdown', property: 'payment_previous_type', name: 'Last Payment Type', options: [
                            { label: 'One Time', value: 'one_time' },
                            { label: 'Recurring', value: 'recurring' },
                            { label: 'Cash', value: 'cash' },
                        ]},
                    ]
                },
                {
                    title: 'Payments',
                    inputs: [
                        { type: 'number', property: 'billing_total', name: 'Total Value' },
                        { type: 'number', property: 'billing_paid', name: 'Amount Paid' },
                        { type: 'number', property: 'billing_in_trust', name: 'Amount In Trust' },
                        { type: 'number', property: 'payment_previous_amount', name: 'Last Payment Amount' },
                        { type: 'number', property: 'payment_recurring', name: 'Recurring Plan Amount' },
                        { type: 'number', property: 'next_payment_success_rate', name: 'Next Payment Success Rate' },
                        { type: 'trueOrFalse', property: 'payment_overdue', name: 'Payment Plan Overdue' },
                        { type: 'dropdown', property: 'payment_previous_status', name: 'Last Payment Status', options: [
                            { label: 'Succeeded', value: 'succeeded' },
                            { label: 'Failed', value: 'failed' },
                            { label: 'Refunded', value: 'refunded' },
                        ]},
                        { type: 'dropdown', property: 'payment_previous_type', name: 'Last Payment Type', options: [
                            { label: 'One Time', value: 'one_time' },
                            { label: 'Recurring', value: 'recurring' },
                            { label: 'Cash', value: 'cash' },
                        ]},
                    ]
                },
                {
                    title: 'State',
                    inputs: [
                        { type: 'state', property: 'query_state', name: 'Select State' },
                    ]
                },
                {
                    title: 'Custom Fields',
                    inputs: [
                        { type: 'customFields'},
                    ]
                },
            ]}
            initialState={{
                division: selected_division._id,
                created_by: [],
                workflows: [],
                division_departments: [],
                workflow_step_categories: [],
                workflow_dispositions: [],
                users: [],
                tags: [],
                workflow_steps: [],
                lead_sources: [],
                custom_dates: [],
        
                // true, false, null
                current_step_overdue: null,
                missing_events: null,
                upcoming_events: null,
                outstanding_items: null,
                missing_documents: null,
                unfinished_tasks: null,
                unsigned_documents: null,
                unanswered_fields: null,
                unfinished_forms: null,
                payment_overdue: null,
                
                // 0 - 100
                current_step_percentage: null,
                current_step_percentage_type: '>',
                current_step_doc_percentage: null,
                current_step_doc_percentage_type: '>',
                current_step_sign_percentage: null,
                current_step_sign_percentage_type: '>',
                current_step_task_percentage: null,
                current_step_task_percentage_type: '>',
                current_step_field_percentage: null,
                current_step_field_percentage_type: '>',
                current_step_form_percentage: null,
                current_step_form_percentage_type: '>',
                next_payment_success_rate: null,
                next_payment_success_rate_type: '>',
        
                // >=, =, <=
                billing_total: null,
                billing_total_type: '>',
                billing_paid: null,
                billing_paid_type: '>',
                billing_in_trust: null,
                billing_in_trust_type: '>',
                billing_for_trust: null,
                billing_for_trust_type: '>',
                payment_previous_amount: null,
                payment_previous_amount_type: '>',
                payment_recurring: null,
        
                // succeeded, failed, refunded
                payment_previous_status: null,
                payment_previous_type: null,
        
                // state selection
                query_state: null,
        
                custom_fields: {}
            }}
            columns={[
                {
                    dataField: "given_name",
                    text: 'Name',
                    headerFormatter: () => {
                        return <div className="first-cell"><div>Matter /</div><div></div></div>
                    },
                    formatter: (cell, row) => {
        
                        if(row.name === 'no data') return (
                            <div className="first-cell">No Data To Show</div>
                        )
                        return (
                            <div className="first-cell">
                                <div><A href={`${DOMAINS.APP}/matters/${row.id}`} className="text-dark font-weight-bold">{row.name}</A></div>
                                <div><A href={`${DOMAINS.APP}/matters/${row.id}`} className="text-muted">
                                    {row.last_note && row.last_note.length > 20 ? row.last_note.slice(0,20) + '...' : row.last_note}
                                </A></div>
                            </div>
                        )
                    },
                },
                {
                    dataField: "created_at",
                    headerStyle: { width: 350 },
                    text: sort.type === 'created_at' ? 'Created' : 
                    sort.type === 'incoming_communication_at' ? 'Last Incoming' : 
                    sort.type === 'outgoing_communication_at' ? 'Last Outgoing' : 
                    sort.type === 'current_step_start' ? 'Step Moved' : 
                    sort.type === 'current_step_overdue_at' ? 'Overdue Since' : 
                    sort.type === 'payment_overdue_since' ? 'Overdue Since' : 
                    sort.type === 'last_note_at' ? 'Note Created' : 
                    sort.type === 'specific_step' ? 'Moved At' : 
                    sort.type === 'specific_disposition' ? 'Disposition Moved To' : 
                    sort.type === 'specific_category' ? 'Category Moved To' : 
                    sort.type === 'categorized_at' ? 'Categorized' : 
                    sort.type === 'dispositioned_at' ? 'Dispositioned' : 
                    sort.type === 'custom_date' ? 'Dates Marked At' : 
                    '',
                    formatter: (cell, row) => {
        
                        if(row.name === 'no data') return ''
                        let field = sort.type === 'created_at' ? row.created_at : 
                        sort.type === 'incoming_communication_at' ? row.incoming_communication_at : 
                        sort.type === 'outgoing_communication_at' ? row.outgoing_communication_at : 
                        sort.type === 'current_step_start' ? row.current_step_start : 
                        sort.type === 'current_step_overdue_at' ? row.current_step_overdue_at : 
                        sort.type === 'payment_overdue_since' ? row.payment_overdue_since : 
                        sort.type === 'last_note_at' ? row.last_note_at : 
                        sort.type === 'specific_step' && row.step_dates? row.step_dates[sort.field] : 
                        sort.type === 'specific_category' && row.step_category_dates? row.step_category_dates[sort.field] : 
                        sort.type === 'specific_disposition' && row.step_disposition_dates? row.step_disposition_dates[sort.field] : 
                        sort.type === 'categorized_at' ? row.categorized_at : 
                        sort.type === 'dispositioned_at' ? row.disposition_moved_to : 
                        ''

                        if(sort.type === 'custom_date') {
                            const fields = row.custom_dates.filter(d => d.custom_date === sort.field)
                            return fields.map(f => (
                                <div style={{whiteSpace: 'pre'}} key={f._id}>{moment.unix(f.timestamp).format('MM/DD/YYYY h:mm A')}</div>
                            ))
                        }
        
                        return field ? moment.unix(field).format('MM/DD/YYYY h:mm A') : '-'
        
                    },
                },
                {
                    dataField: "roles",
                    text: 'Users',
                    headerStyle: { width: 300 },
                    formatter: (cell, row) => {
                        if(row.name === 'no data') return ''
                        const roles = row.roles ? row.roles.filter(r => r.user) : []

                        return roles.map((r, i) => (
                            <span key={i}>{i !== 0 ? ', ' : ''}<ObjectFinder collection="users" _id={r.user} /></span>
                        ))
                    }
                },
                {
                    dataField: "contact",
                    text: 'Contacts',
                    formatter: (cell, row) => (
                        row.name === 'no data' ? '' : !row.contacts ? '' :
                        row.contacts.map((c, i) => (
                            <Link key={c._id} to={`/clients/${row._id}`}>
                                <span> {i !== 0 ? ', ' : ''}<ObjectFinder collection="contacts" _id={c} /></span>
                            </Link>
                        ))
                    )
                },
              
                {
                    dataField: "current_step_percentage",
                    text: 'Step %',
                    formatter: (cell, row) => {
                        if(row.name === 'no data' ) return ''
                        let className = "";
                        if(row.current_step_percentage >= 0 && row.current_step_percentage < 19.99) className = 'text-danger'
                        if(row.current_step_percentage >= 20 && row.current_step_percentage < 49.99) className = 'text-warning'
                        if(row.current_step_percentage >= 50 && row.current_step_percentage < 97.99) className = 'text-info'
                        if(row.current_step_percentage >= 98) className = 'text-success'
        
                        return (
                            <div>
                               
                                <span className={className}>{row.current_step_percentage + '%'}</span>
                            </div>
                        )
                    }
                },
                {
                    dataField: "division_department",
                    text: 'Department',
                    formatter: (cell, row) => (
                        <div>
                            {row.name === 'no data' ? '' : row.division_department ? 
                                <ObjectFinder collection="division_departments" _id={row.division_department} /> : 
                                <span className="text-warning">No Department</span>}
                        </div>
                    )
                },
                {
                    dataField: "division",
                    text: 'Matter Type',
                    headerFormatter: () => (
                        <div><div> </div><div>Matter Type</div></div>
                    ),
                    formatter: (cell, row) => (
                        row.name === 'no data' ? '' : (
                            <div>
                                <div><Link to={`/matters/${row.id}`}><ObjectFinder collection="workflows" _id={row.workflow} /></Link></div>
                            </div>
                        )
                    )
                },
                // {
                //     dataField: "division",
                //     text: 'Billing',
                //     formatter: (cell, row) => (
                //         row.name === 'no data' ? '' : (
                //             <div>
                                
                //                 <div className={row.billing_paid && row.billing_paid === row.billing_total ? 'text-success' : ''}>
                //                     <b style={{display: 'inline-block', width: 35}}>Paid:</b> { formatCurrency(row.billing_paid) }
                //                 </div>
                //                 <div className={row.billing_paid && row.billing_paid === row.billing_total ? 'text-success' : 'text-dark'}>
                //                     <b style={{display: 'inline-block', width: 35}}>Total:</b> { formatCurrency(row.billing_total) }
                //                 </div>
                //             </div>
                //         )
                //     )
                // },
                {
                    dataField: "current_step_category",
                    text: 'Step Category',
                    headerFormatter: () => (
                        <div><div>Groupings</div></div>
                    ),
                    formatter: (cell, row) => (
                        row.name === 'no data' ? '' : (
                            <div>
                                <div>{row.workflow_step_category ? <ObjectFinder collection="workflow_step_categories" _id={row.workflow_step_category} /> : 'No Category'}</div>
                                <div>{row.workflow_disposition ? <ObjectFinder collection="workflow_dispositions" _id={row.workflow_disposition} /> : '-'}</div>
                            </div>
                        )
                    )
                },
                {
                    dataField: "current_step_start",
                    text: 'Current Step',
                    headerFormatter: () => (
                        <div><div>Moved</div></div>
                    ),
                    formatter: (cell, row) => (
                        row.name === 'no data' ? '' : (
                            <div>
                                <div>
                                    {
                                        row.current_step_name ? row.current_step_name : 
                                        <span>
                                            <i className="fas fa-exclamation-triangle mr-2 text-warning " /> None Assigned
                                        </span>
                                    }
                                </div>
                                {row.current_step_start ? <div>{moment.unix(row.current_step_start).format('MM/DD/YYYY')}</div> : ''}
                            </div>
                        )
                    )
                },
                {
                    dataField: "_id",
                    headerStyle: {textAlign: 'right', width: 75},
                    text: '',
                    formatter: (cell, row) => (
                        row.name === 'no data' ? '' :
                        <div className="text-right last-cell">
                            <TableIcons
                                icons={[
                                    { 
                                        icon: 'fas fa-external-link-alt',
                                        color: 'info', 
                                        wrapper: A, 
                                        href: `${DOMAINS.APP}/matters/${row.id}`,
                                    },

                                ]}
                            />
                        </div>
                    )
                },
            ]
        }
        />
    )
}



const mapStateToProps = state => {
	return {
	    DOMAINS: state.load.DOMAINS,
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(AnalyticsPagesMatters);