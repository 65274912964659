import { Input, FormGroup } from "reactstrap";
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import { useCallback, useState, useEffect } from 'react';

import api from 'api'

import Modalify from 'components/functional/modals/Modalify'

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

import Associations from 'components/system/Associations'

const SystemlocationTypesEdit = ({_id, onSaved, showModal, toggleModal, selected_division}) => {

    const [location_type, setLocationType] = useState(null)
    const [err, setErr] = useState(null)
    const [redirect, setRedirect] = useState(null)

    const fetchData = useCallback(async () => {
        if(!_id || _id === 'create') return setLocationType({});
        const location_type = await api.location_types.findById(_id)
        if(!location_type.data) return setErr(true);
        setLocationType(location_type.data)
    }, [_id])

    const onInputChange = useCallback(( field, value) => {
        const newlocationType = Object.assign({}, location_type)
        newlocationType[field] = value;
        setLocationType(newlocationType);
    }, [location_type])

    const onSave = useCallback(async () => {
        setErr(false)
        let newState = Object.assign({}, location_type);

        if(!newState.name) {
            return setErr([`A location type must have a name.`])
        }
        
        let saved;

        // toggle loaders and update/create the location_type
        toggleStandardLoader(true)
        if(newState._id) {
            saved = await api.location_types.update(newState._id, newState, true);
        } else {
            saved = await api.location_types.create({...newState, division: selected_division._id}, true);
        }
        toggleStandardLoader(false)

        if(!saved.success) return setErr(saved.message)
        
        fetchData();
        if(toggleModal) toggleModal();
        if(onSaved) onSaved(saved.data)

    }, [location_type, fetchData, toggleModal, onSaved, selected_division._id])

    useEffect(() => {
        if(toggleModal) {
            if(showModal) {
                fetchData()
            } else {
                setErr(null)
                setLocationType('')
            }
        } else {
            fetchData()
        }
    }, [showModal, toggleModal, fetchData])

    useEffect(() => {
        if(!selected_division._id) {
            toggleAlertBS('info', 'A division must be created for editing a location type.')
            setRedirect('/system/divisions/create')
        }
    }, [selected_division._id])

    if(redirect) return <Redirect to={redirect} />

    return (
        <Modalify 
            showModal={showModal}
            toggleModal={toggleModal}
            modalTitle={location_type && location_type._id ? "Update location type" : "Create location type"}
            err={err}
            footer={(
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            )}
            sections={!location_type ? [] :  [
                (
                    <div>

                        <FormGroup>
                            <label className="form-control-label">Name*</label>
                            <Input 
                                type="text"
                                value={location_type.name || ''}
                                onChange={e => onInputChange('name', e.target.value)}
                            />
                        </FormGroup>
                    </div>
                ),
                ...(location_type._id ? [(
                    <Associations 
                        type="location_type" 
                        _id={location_type._id} 
                        classNames="border p-3 bg-secondary" 
                    />
                )] : []),
            ]}
        />
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SystemlocationTypesEdit);