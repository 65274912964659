// runs a function at the end of a delay period no matter how many times it's called
// if call times is set we run once per timeout OR per number of times called.
// ex: if delay is 500 and call times is 5 we run this .5 seconds after the last time the function is call
// OR if this function is called 5 times within a .5 second window

const throttle = (fn, delay = 250, callTimes = 0) => {
    // time since last call
    let timeout;
    // number of times called before running function;
    let calls = 0;

    return (...args) => {
        clearTimeout(timeout);
        calls++
        if(calls > callTimes) {
            fn(...args);
            calls = 0;
        } else {
            timeout = setTimeout(() => {
                fn(...args);
                calls = 0;
            }, delay);
        }
       
    };
}

export default throttle;