import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import api from 'api';

import Circle from 'components/markup/loading/Circle'

const Associations = ({_id, type, classNames}) => {
    
    const name = type.replace(/_/g, ' ');
    const [associations, setAssociations] = useState(null);

    const fetchAssociations = useCallback(async () => {
        if(!_id) return setAssociations([]);
        
        const data = await api.admin.associations(type, _id)
        if(!data.success) return toast.error(`Could not fetch associations, internal error.`)

        setAssociations(data.data)
    }, [type, _id])

    useEffect(() => {
        fetchAssociations()
    }, [fetchAssociations])

    if(!_id) return <></>
    if(!associations) return <Circle />

    return (
        <div>

            <div className={classNames}>
                <p className='mb-0 text-sm font-weight-bold'>
                    {associations.length ? (
                        <span className='text-dark'><i className="fas fa-info-circle text-info mr-2" /> This {name} is currently being used by the following items:</span>
                    ) : (
                        <span className='text-warning'>This {name} is currently not being used by anything.</span>
                    )}
                </p>

                {associations.length ? (
                    <ol className='pl-5 mb-0'>
                        {associations.map(a => (
                            <li key={a} className='text-capitalize text-sm'>
                                {a.type.replace(/_/g, ' ')} - {a.name}
                            </li>
                        ))}
                    </ol>
                ) : ''}

                {associations.length > 20 ? (
                    <div className="">
                        <p className="text-sm mb-0"><small>**Note the above list may be truncated and has the possibility to be larger than the result set shown.</small></p>
                    </div>
                ) : ''}

            </div>

        </div>

    )

}

export default Associations;