import { connect } from 'react-redux';

import SearchCollections from "components/system/Search/Collections";

import WorkflowContactSelection from 'components/system/WorkflowContactSelection';

const ActionSendForm = ({ node, onInputChange, selected_division }) => {

    return (
        <div>
    
            <div className='sidebar-section border-bottom'>
                <SearchCollections
                    collection={'forms'}
                    title="**Select The Form To Send And The Parties Below"
                    placeholder="Search..."
                    value={node.data.form}
                    onChange={(obj) => onInputChange('form', obj.value)}
                    filter={{
                        division: selected_division._id,
                    }}
                /> 
            </div>
    
            <div className='sidebar-section border-bottom'>
                <WorkflowContactSelection 
                    contacts={node.data.workflow_contacts} 
                    onChange={(workflow_contacts) => onInputChange('workflow_contacts', workflow_contacts)} 
                />
            </div>
    
            <div className='sidebar-section border-bottom'>
                <p className='text-sm'>
                    <i className="fas fa-info-circle text-info" /> This action automatically sends the form selected above to all parties checked for completion. 
                </p>
                <p className='text-sm mb-0'>
                    Note that notifications <b className='text-underline'>WILL GO OUT</b> via text message and email to the selected parties.
                </p>
            </div>
    
        </div>
    )
}



const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(ActionSendForm);