import { useState, useCallback } from 'react';
import { Card, CardHeader, CardTitle, Row, Col, Input, FormGroup } from 'reactstrap';

const CustomFieldsEditAnswers = ({answers, onAnswerChange}) => {

    const [_answer, _setAnswer] = useState('')
    const [_answers, _setAnswers] = useState(answers)

    const _handleKeyDown = useCallback((e) => {
		if (e.key === 'Enter') document.getElementById("archk-add-answer-button").click();
	}, [])

    const onAddAnswer = useCallback(() => {
        if(!_answer) return;
        const __answers = JSON.parse(JSON.stringify(_answers));
        if(!__answers.includes(_answer)) {
            __answers.push(_answer);
        }
        onAnswerChange(__answers)
        _setAnswers(__answers);
        _setAnswer('')
    }, [_answers, _answer, onAnswerChange])
    
    const onRemoveAnswers = useCallback((__answer) => {
        let __answers = JSON.parse(JSON.stringify(_answers));
        __answers = __answers.filter(r => r !== __answer)
        onAnswerChange(__answers);
        _setAnswers(__answers)
    }, [_answers, onAnswerChange])

    return (
        <Card>
            <CardHeader>
                <CardTitle>Add Field Answers</CardTitle>
                <p className="text-sm mb-0"><i className="fas fa-info-circle mr-2 info-circle text-info " /> For a custom field with the type of "Select" at least 2 answers must be added below as possible choices for the field. Note that they will show up in selection in the order they appear below with the top answer being first in the list to select.</p>
            </CardHeader>
            
            <CardHeader>
                <label className="form-control-label">Answer Name*</label>

                <Row>
                    <div className="col-auto" style={{width: 'calc(100% - 215px)'}}>
                        <FormGroup>
                            <Input 
                                onKeyDown={_handleKeyDown}
                                type="text"
                                value={_answer}
                                onChange={(e) => _setAnswer(e.target.value)}

                            />
                        </FormGroup>
                    </div>
                    <Col>
                        <button 
                            id="archk-add-answer-button"
                            onClick={onAddAnswer} 
                            className="btn btn-outline-info btn-block"
                        >
                            <i className="fas fa-plus mr-2 " /> Add Answer
                        </button>
                    </Col>
                </Row>
            </CardHeader>

            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Field Answers</th>
                            <th className="text-right">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {_answers.length ? _answers.map((_answer, i) => (
                            <tr key={i}>
                                <td>{_answer}</td>
                                <td className="text-right">
                                    <button className="btn btn-danger" onClick={() => onRemoveAnswers(_answer)}>
                                        <i className="fas fa-trash mr-2 " /> Remove Answer
                                    </button>
                                </td>
                            </tr>
                        )) : (
                            <tr>
                                <td>No answers have been created for this workflow</td>
                                <td></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

        </Card>
    )

}

export default CustomFieldsEditAnswers;