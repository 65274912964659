import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row } from "reactstrap";


import BarLine from 'components/charts/BarLine';
import PieArea from 'components/charts/PieArea';

import Table from './Table';

const LocationsAll = ({setTab, selected_division}) => {

    

    return (

        <>


        <Row>

            <Col md={6}>
                <BarLine 
                    type="line"
                    title="New Leads Per Hour"
                    labels={[ '12AM', '1AM', '2AM', '3AM', '4AM', '5AM', '6AM', '7AM', '8AM', '9AM', '10AM', '11AM', '12PM', '1PM', '2PM', '3PM', '4PM', '5PM', '6PM', '7PM', '8PM', '9PM', '10PM', '11PM',]}
                    datasets={[
                        {
                            label: 'Source 1',
                            data: [0,0,0,0,0,0,0,0,2, 4, 1, 6, 3, 5, 8, 10, 0,0,0,0,0,0,0,0,],
                        },
                    ]}
                />
            </Col>

            <Col md={3}>
                <PieArea 
                    type="pie"
                    title="Source Breakdown"
                    labels={['TLS', 'Google', 'Facebook', "Unknown"]}
                    dataset={[12, 19, 3, 5]}
                />
            </Col>

            <Col md={3}>
                <PieArea 
                    type="pie"
                    title="Time To Call"
                    labels={['< 1 Min', '1 - 3 Min', '3 Min +']}
                    dataset={[80, 18, 2]}
                />              
            </Col>
            
        </Row>


        <Row>
            <Col md={3}>
                <Card className='text-center'>
                    <CardHeader>
                        <CardTitle className="mb-0">Estimated Revenue</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <h2 className='display-2 mb-0'>$38,391 <small><small className='text-muted pl-4'>+12%</small></small></h2>
                    </CardBody>
                    <CardFooter>
                        12 Weeks Ago: <b className='text-dark font-weight-bold '>$29,583</b>
                    </CardFooter>
                </Card>
            </Col>
            <Col md={3}>
                <Card className='text-center'>
                    <CardHeader>
                        <CardTitle className="mb-0">Hired</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <h2 className='display-2 mb-0'>27 <small><small className='text-muted pl-4'><i className="fas fa-arrow-right" /> 32*</small></small></h2>
                    </CardBody>
                    <CardFooter>
                        12 Weeks Ago: <b className='text-dark font-weight-bold '>8</b>
                    </CardFooter>
                </Card>
            </Col>
            <Col md={3}>
                <Card className='text-center'>
                    <CardHeader>
                        <CardTitle className="mb-0">DNQ</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <h2 className='display-2 mb-0'>27 <small><small className='text-muted pl-4'><i className="fas fa-arrow-right" /> 32*</small></small></h2>
                    </CardBody>
                    <CardFooter>
                        12 Weeks Ago: <b className='text-dark font-weight-bold '>8</b>
                    </CardFooter>
                </Card>
            </Col>
            <Col md={3}>
                <Card className='text-center'>
                    <CardHeader>
                        <CardTitle className="mb-0">Rejected</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <h2 className='display-2 mb-0'>27 <small><small className='text-muted pl-4'><i className="fas fa-arrow-right" /> 32*</small></small></h2>
                    </CardBody>
                    <CardFooter>
                        12 Weeks Ago: <b className='text-dark font-weight-bold '>8</b>
                    </CardFooter>
                </Card>
            </Col>
            <Col md={3}>
                <Card className='text-center'>
                    <CardHeader>
                        <CardTitle className="mb-0">Leads Without Cases</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <h2 className='display-2 mb-0'>18 <small><small className='text-muted pl-4'>+87%</small></small></h2>
                    </CardBody>
                    <CardFooter>
                        12 Weeks Ago: <b className='text-dark font-weight-bold '>19</b>
                    </CardFooter>
                </Card>
            </Col>
        </Row>


        <BarLine 
            horizontal={true}
            type="bar"
            title="Overall Lead Pipeline"
            labels={[ 'Pipeline']}
            datasets={[
                {
                    label: 'Dead',
                    data: [8],
                },
                {
                    label: 'DNQ',
                    data: [14],
                },
                {
                    label: 'Potential',
                    data: [7],
                },
                {
                    label: 'Converted',
                    data: [1],
                },
            ]}
        />

        <Table />

        </>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(LocationsAll);