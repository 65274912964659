import { Row, Col, Input, FormGroup, Badge } from "reactstrap";
import { useCallback, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom'
import api from 'api'

import Modalify from 'components/functional/modals/Modalify'

import { toggleStandardLoader } from 'store/functions/system/system';

import InputStates from 'components/markup/inputs/States'
import SearchCollections from 'components/system/Search/Collections'

import ObjectFinder from 'components/system/Objects/Finder';

import Privileges from './Privileges'

const baseState = { 
    privileges: [], 
    division_overrides: [] 
}

const UsersView = ({_id, onSaved, showModal, toggleModal}) => {

    const [redirect, setRedirect] = useState(null)
    const [user, setUser] = useState(null)
    const [err, setErr] = useState(null)

    const onPrivilegeChange = useCallback((privilege) => {
        const newUser = Object.assign({}, user)
        if(newUser.privileges.includes(privilege)) {
            newUser.privileges = newUser.privileges.filter(p => p !== privilege)
        } else {
            newUser.privileges.push(privilege)
        }
        setUser(newUser);
    }, [user])

    const onAddArrayField = useCallback((field, value) => {
        const _user = JSON.parse(JSON.stringify(user));
        const arr = _user[field] ? _user[field] : [];
        if(!arr.includes(value)) arr.push(value);
        _user[field] = arr
        setUser(_user);
    }, [user])
    
    const onRemoveArrayField = useCallback((field, value) => {
        const _user = JSON.parse(JSON.stringify(user));
        _user[field] = _user[field].filter(f => f !== value);
        setUser(_user);
    }, [user])

    const onInputChange = useCallback(( field, value) => {
        const newUser = Object.assign({}, user)
        newUser[field] = value;
        setUser(newUser);
    }, [user])


    const setUserName = useCallback((user) => {
        try {
            const name = user.display_name.split(' ');
            if(name[0]) user.given_name = name[0]
            if(name[1]) user.family_name = name[1]
            
            return user;
        } catch(e) {
            return user
        }
    }, [])

    const fetchData = useCallback(async () => {
        if(!_id || _id === 'create') return setUser(baseState);
        const user = await api.users.findById(_id)
        if(!user.data) return setErr(true);

        const formattedUser = setUserName(user.data)
        setUser(formattedUser)
    }, [_id, setUserName])


    const onSave = useCallback(async () => {

        setErr(false)
        let newState = Object.assign({}, user);

        if(!newState.given_name || !newState.family_name || !newState.email ) {
            return setErr([`A user must have a first name, last name, and email address.`])
        }

        if(!newState.username) {
            return setErr([`A user must have a unique username.`])
        }

        if(!newState._id) {
            if(!newState.password || !newState.passwordConfirm) {
                return setErr([`You must enter the password for this user before proceeding.`])
            }    
        }
        
        setUser(newState)

        let saved;

        // toggle loaders and update/create the user
        toggleStandardLoader(true)
        if(newState._id) {
            saved = await api.users.update(newState._id, newState, true);
        } else {
            saved = await api.users.create(newState, true);
        }
        toggleStandardLoader(false)

        // show error messages if unsuccessful
        if(saved.success) {
            if(toggleModal) toggleModal();
            if(onSaved) onSaved(saved.data)
            setRedirect('/staffing')
        } else {
            return setErr(saved.message);
        }
    
    }, [user, toggleModal, onSaved])

    useEffect(() => {
        if(toggleModal) {
            if(showModal) {
                fetchData()
            } else {
                setErr(null)
                setUser('')
            }
        } else {
            fetchData()
        }
    }, [showModal, toggleModal, fetchData])

    if(redirect) return <Redirect to={redirect} />

    return (
        <Modalify 
            showModal={showModal}
            toggleModal={toggleModal}
            modalTitle={user && user._id ? "Update User" : "Create User"}
            err={err}
            footer={(
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            )}
            sections={!user ? [] :  [
                (
                    <div>

                        <h2>Name</h2>
        
                        <Row>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">First Name</label>
                                    <Input 
                                        type="text"
                                        value={user.given_name || ''}
                                        onChange={e => onInputChange('given_name', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Last Name</label>
                                    <Input 
                                        type="text"
                                        value={user.family_name || ''}
                                        onChange={e => onInputChange('family_name', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <FormGroup>
                            <label className="form-control-label">Title</label>
                            <Input 
                                type="text"
                                value={user.title || ''}
                                onChange={e => onInputChange('title', e.target.value)}
                            />
                        </FormGroup>
                    </div>
                ),
                (
                    <div>

                        <h2>Contact Information</h2>

                        <FormGroup>
                            <label className="form-control-label">Username</label>
                            <Input 
                                type="text"
                                value={user.username || ''}
                                onChange={e => onInputChange('username', e.target.value)}
                            />
                        </FormGroup>      
            
                        <Row>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Account Email</label>
                                    <Input 
                                        type="text"
                                        value={user.email || ''}
                                        onChange={e => onInputChange('email', e.target.value)}
                                    />
                                </FormGroup>      
                            </Col>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Account Phone Number</label>
                                    <Input 
                                        type="text"
                                        value={user.phone || ''}
                                        onChange={e => onInputChange('phone', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                        </Row>
                    </div>
                ),
                (
                    <div>

                        <h2>User Address</h2>
        
                        <Row>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Address Line 1</label>
                                    <Input 
                                        type="text"
                                        value={user.address_line_1 || ''}
                                        onChange={e => onInputChange('address_line_1', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Address Line 2</label>
                                    <Input 
                                        type="text"
                                        value={user.address_line_2 || ''}
                                        onChange={e => onInputChange('address_line_2', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                        </Row>
        
                        <Row>
                            <Col lg={4}>
                                <FormGroup>
                                    <label className="form-control-label">City</label>
                                    <Input 
                                        type="text"
                                        value={user.city || ''}
                                        onChange={e => onInputChange('city', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                            <Col lg={4}>
                                <InputStates 
                                    value={user.state}
                                    onChange={state => onInputChange('state', state)}
                                />
                            </Col>
                            <Col lg={4}>
                                <FormGroup>
                                    <label className="form-control-label">Zip</label>
                                    <Input 
                                        type="text"
                                        value={user.postal_code || ''}
                                        onChange={e => onInputChange('postal_code', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                        </Row>
                    </div>
                ),
                (
                    <div>

                        <h2>Divisions</h2>
        
                        <SearchCollections
                            collection="divisions" 
                            title={(
                                <span>
                                    Users will only have access to matters associated to the divisions below.
                                </span>
                            )}
                            filter={{deleted: false}}
                            value={user.divisions}
                            hideSelected={true} 
                            onChange={(obj) => onAddArrayField('divisions', obj.value)}
                        /> 
                        {user.divisions ? user.divisions.map((division, i) => (
                            <Badge key={division} color="success" className="ml-0 mb-3 mr-2">
                                <ObjectFinder collection="divisions" _id={division} />
                               

                                <span className="pr-2 pl-4 cursor-pointer float-right" onClick={() => onRemoveArrayField('divisions', division)}>
                                    <i className="fas fa-times " />
                                </span>

                            </Badge>
                        )) : ''}

                    </div>
                ),
                !user._id ? (
                    <div>

                        <h2>Security</h2>
        
                        <Row>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Password*</label>
                                    <Input 
                                        type="password"
                                        value={user.password || ''}
                                        onChange={e => onInputChange('password', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Confirm Password*</label>
                                    <Input 
                                        type="password"
                                        value={user.passwordConfirm || ''}
                                        onChange={e => onInputChange('passwordConfirm', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                        </Row>
        
                    </div>
                ) : '',
                (
                    <Privileges 
                        user={user}
                        setUser={setUser} 
                        onPrivilegeChange={onPrivilegeChange}
                    />
                ),

            ]}
        />
    )

}

export default UsersView