import React from 'react';

import { Col, FormGroup, Input, Row, UncontrolledTooltip } from 'reactstrap';

import ActionOnAnswers from './ActionOnAnswers';
 
const ColOverview = ({question, onInputChange, questionCanBranch, errs, config, customFields}) =>  {

    if(!question.id) return (
        <Col lg={4} className="col col-overview">

            <Row className="archk-col-header">
                <Col lg={12} className="align-self-center">
                    <h3>Question Settings</h3>
                </Col>
            </Row>

            <p className="text-sm mb-0 ">No question selected for editing.</p>

        </Col>
    )

    const canBranch = questionCanBranch(question);
    const customField = question.type === 'custom-field' && question.custom_field ? customFields.find(f => f._id === question.custom_field) : null;

    return (

        <Col lg={4} className="col col-overview">

            <Row className="archk-col-header">
                <Col lg={7} className="align-self-center">
                    <h3>Question Settings</h3>
                </Col>
                <Col lg={5} className="align-self-center text-right ">
                    <small>
                        <i id="archk-icon-required" className={`fas fa-check-circle  ml-3 ${question.required ? 'text-success' : ''}`} />
                        <UncontrolledTooltip
                            delay={750}
                            trigger="hover focus"
                            target="archk-icon-required"
                        >
                            {question.required ? 'Question is required' : 'Question is NOT required'}
                        </UncontrolledTooltip>
                    </small>
                    <small>
                        <i id="archk-icon-brach" className={`fas fa-code-branch  ml-3 ${question.branch_on_answer && canBranch ? 'text-success' : ''}`} />
                        <UncontrolledTooltip
                            delay={750}
                            trigger="hover focus"
                            target="archk-icon-brach"
                        >
                            {question.branch_on_answer && canBranch ? 'Question can create a branch' : 'Question will NOT Create a branch'}
                        </UncontrolledTooltip>
                    </small>
                    <small>
                        <i id="archk-icon-highlight" className={`fas fa-highlighter  ml-3 ${question.highlight_on_answer && canBranch ? 'text-success' : ''}`} />
                        <UncontrolledTooltip
                            delay={750}
                            trigger="hover focus"
                            target="archk-icon-highlight"
                        >
                            {question.highlight_on_answer && canBranch ? 'Question may highlight an answer' : 'Question will NOT highlight based on an answer'}
                        </UncontrolledTooltip>
                    </small>
                    <small>
                        <i id="archk-icon-error" className={`fas fa-comment  ml-3 ${question.custom_error_message && question.required ? 'text-success' : ''}`} />
                        <UncontrolledTooltip
                            delay={750}
                            trigger="hover focus"
                            target="archk-icon-error"
                        >
                            {question.custom_error_message && question.required ? 'Question will give a custom error message' : 'Question will NOT give a custom error message'}
                        </UncontrolledTooltip>
                    </small>
                </Col>
            </Row>

            <FormGroup>
                <div className="custom-control custom-checkbox">
                    <input
                        className="custom-control-input"
                        id="confirm-terms-of-service"
                        type="checkbox"
                        checked={question.required}
                        onChange={(e) => onInputChange(!question.required, 'required')}
                        />
                    <label className="custom-control-label" htmlFor="confirm-terms-of-service">
                        Question Is Required
                    </label>
                </div>
            </FormGroup>

            <Row className="archk-col-header p-0 mt-0">
                <hr  className='my-1' />
            </Row>

            {canBranch ? (
                <>

                <FormGroup>
                    <div className="custom-control custom-checkbox">
                        <input
                            className="custom-control-input"
                            id="archk-create-branch"
                            type="checkbox"
                            checked={question.branch_on_answer}
                            disabled={question.type === 'loop' || question.type === 'month-loop' || question.type === 'two-week-loop' || question.type === 'one-week-loop'}
                            onChange={(e) => onInputChange(!question.branch_on_answer, 'branch_on_answer')}
                        />
                        <label className="custom-control-label" htmlFor="archk-create-branch">
                            Create Branch On Answer
                        </label>
                    </div>
                </FormGroup>

                {/* if we are branching show possible answers. Hide if branch is a loop */}
                {question.branch_on_answer === true && question.type !== 'loop' && question.type !== 'month-loop' && question.type !== 'two-week-loop' && question.type !== 'one-week-loop' ? (
                    <ActionOnAnswers
                        config={config}
                        errs={errs} 
                        type="branch"
                        answers={question.branch_on_answers}
                        question={question}
                        possibleAnswers={customField ? customField.answers : question.answers}
                        fieldType={customField ? customField.type : question.type}
                        onChange={(answers) => onInputChange(answers, 'branch_on_answers')}
                    />
                ) : null}

                </>
            ) : (
                <FormGroup>
                    <div className="custom-control custom-checkbox">
                        <input
                            className="custom-control-input"
                            id="archk-create-branch"
                            type="checkbox"
                            checked={false}
                            disabled={true}
                        />
                        <label className="custom-control-label" htmlFor="archk-create-branch">
                            Create Branch On Answer <i id="archk-branch-info" className="fas fa-info-circle ml-2 text-info " />
                            <UncontrolledTooltip
                                delay={0}
                                trigger="hover focus"
                                target="archk-branch-info"
                            >
                                (Only Applicable on Dropdown, Checkbox, and Yes or No Questions)
                            </UncontrolledTooltip>
                             
                        </label>
                    </div>
                </FormGroup>
            )}

            <Row className="archk-col-header p-0 mt-0">
                <hr  className='my-1' />
            </Row>

            {canBranch ? (
                <>
                <FormGroup>
                    <div className="custom-control custom-checkbox">
                        <input
                            className="custom-control-input"
                            id="archk-highlight-on-answer"
                            type="checkbox"
                            checked={question.highlight_on_answer}
                            onChange={(e) => onInputChange(!question.highlight_on_answer, 'highlight_on_answer')}
                        />
                        <label className="custom-control-label" htmlFor="archk-highlight-on-answer">
                            Highlight On Answer
                        </label>
                    </div>
                </FormGroup>


                {question.highlight_on_answer ? (
                    <ActionOnAnswers
                        config={config}
                        errs={errs} 
                        type="highlight"
                        answers={question.highlight_on_answers}
                        question={question}
                        possibleAnswers={customField ? customField.answers : question.answers}
                        fieldType={customField ? customField.type : question.type}
                        onChange={(answers) => onInputChange(answers, 'highlight_on_answers')}
                    />
                ) : null}
                </>

            ) : (
                <FormGroup>
                    <div className="custom-control custom-checkbox">
                        <input
                            className="custom-control-input"
                            id="archk-highlight-on-answer"
                            type="checkbox"
                            checked={false}
                            disabled={true}
                        />
                        <label className="custom-control-label" htmlFor="archk-highlight-on-answer">
                            Highlight On Answer <i id="archk-highlight-info" className="fas fa-info-circle ml-2 text-info " />
                            <UncontrolledTooltip
                                delay={0}
                                trigger="hover focus"
                                target="archk-highlight-info"
                            >
                                (Only Applicable on Dropdown, Checkbox, and Yes or No Questions)
                            </UncontrolledTooltip>
                            
                        </label>
                    </div>
                </FormGroup>
            )}

          
           

            <Row className="archk-col-header p-0 mt-0">
                <hr  className='my-1' />
            </Row>

            {question.required ? (
                <>
                    <FormGroup>
                        <div className="custom-control custom-checkbox">
                            <input
                                className="custom-control-input"
                                id="archk-custom-error-message"
                                type="checkbox"
                                checked={question.custom_error_message}
                                onChange={(e) => onInputChange(!question.custom_error_message, 'custom_error_message')}
                                />
                            <label className="custom-control-label" htmlFor="archk-custom-error-message">
                                Custom Error Message
                            </label>
                        </div>
                    </FormGroup>

                    {question.custom_error_message ? (
                        <FormGroup>
                            <label className="form-control-label">**Should a user try to move on without answering this question you can give an optional message to them here:</label>
                            <Input 
                                style={{height: 80, maxHeight: 400}}
                                type="textarea"
                                value={question.custom_error_message_text}
                                onChange={(e) => onInputChange(e, 'custom_error_message_text')}
                            />
                        </FormGroup>
                    ) : null}
                </>
            ) : (
                <div>
                    <FormGroup>
                        <div className="custom-control custom-checkbox">
                            <input
                                className="custom-control-input"
                                id="archk-custom-error-message"
                                type="checkbox"
                                checked={false}
                                disabled={true}
                            />
                            <label className="custom-control-label" htmlFor="archk-custom-error-message">
                                Custom Error Message <i id="archk-highlight-error-message" className="fas fa-info-circle ml-2 text-info " />
                                <UncontrolledTooltip
                                    delay={0}
                                    trigger="hover focus"
                                    target="archk-highlight-error-message"
                                >
                                    (Only Applicable if Question Is Required)
                                </UncontrolledTooltip>
                            </label>
                        </div>
                    </FormGroup>
                </div>
            )}
            
        </Col>
              
    )
};

export default React.memo(ColOverview);

