import { Row, Col } from 'reactstrap';

const TextButtonRow = ({title, button, leftColSize}) => (
    <Row className="mb-3">
        <Col xs={leftColSize ? leftColSize : 6} className="align-self-center">
            <h3 className="mb-0">{title}</h3>
        </Col>
        <Col xs={leftColSize ? 12 - leftColSize : 6} className="align-self-center text-right">
            {button ? button : ''}
        </Col>
    </Row>
)

export default TextButtonRow;