import keys from 'keys';

import formatObject from 'utils/formatObject';
import formatText from 'utils/formatText';

import Axios from 'axios';

import fileDownloader from 'js-file-download';

import { toggleAlertBS } from 'store/functions/system/system';

export const getDownloadName = (document, contact) => {
    return formatText(formatObject(contact).name() + ' - ' + document.name ).capitalize() + '.pdf'
}

export const onDownloadDocument = async (doc, type) => new Promise (async resolve => {

    const _id = doc && doc._id ? doc._id : doc

    // if document has a new icon next to it remove it from the page
    const badge = document.getElementById(`document-new-${_id}`)
    if(badge) badge.parentNode.removeChild(badge);

    let url = keys.API_URL + `/v1/documents/${_id}/download`;
    if(type === 'company_documents')  url = keys.API_URL + `/v1/company_documents/${_id}/download`;

    const result = await Axios({
        method: 'get',
        url: url,
        responseType:'arraybuffer',
        headers: {
            authorization: `Bearer ${keys.SYSTEM_API_KEY}`
        },
        withCredentials: true,
    })

    if(result.headers && result.headers['content-type'].includes('application/json')) {
        resolve(false);
        try {
            var decodedString = String.fromCharCode.apply(null, new Uint8Array(result.data));
            var body = JSON.parse(decodedString);
            if(body.message && body.message.length) {
                return toggleAlertBS('info', body.message[0] ? body.message[0] : `Something's Not Right, Please Try Again Later`)
            }
        } catch(e) {

        }
        return toggleAlertBS('info', `Something's Not Right, Please Try Again Later`)
    }

    fileDownloader(result.data, result.headers.filename) 
    resolve(true);
})

// download all documents passed in, set a 1.5 second timeout in between to no overload the server
export const onDownloadDocuments = (documents, type) => {

    const download = async (index) => {
        const doc = documents[index];
        if(!doc) return;
        await onDownloadDocument(doc, type)
        setTimeout(() => {
            download(index + 1);
        }, 1500)
    }

    download(0)
}

export const onDownloadSignedDocument = async (doc) => {

    const _id = doc && doc._id ? doc._id : doc

    const result = await Axios({
        method: 'get',
        url: keys.API_URL + `/v1/signing/documents/${_id}/download`,
        responseType:'arraybuffer',
        headers: {
            authorization: `Bearer ${keys.SYSTEM_API_KEY}`
        },
        withCredentials: true,
    })

    if(result.headers && result.headers['content-type'].includes('application/json')) {
        return toggleAlertBS('info', `Something's Not Right, Please Try Again Later`)
    }

    fileDownloader(result.data, result.headers.filename) 

}

export const needsExtension = (doc) => {
    const now = Math.floor(new Date() / 1000)
    if(doc.status !== 'completed') return false;
    if(doc.can_download_until > now) return false; 
    if(doc.finished_at > (now - (86400 * 30)) ) return false;
    return true;
}