import { useCallback, useState } from "react";
import { Link } from 'react-router-dom';
import { Card } from "reactstrap";
import { connect } from 'react-redux';

import api from 'api';

import TextButtonRow from 'components/markup/layout/TextButtonRow'
import Circle from 'components/markup/loading/Circle'
import ConfirmationModal from 'components/functional/modals/Confirmation'
import TableIcons from 'components/markup/icons/TableIcons'
import Table from 'components/functional/tables/Standard';
import ApiError from "components/markup/layout/ApiError";
import ModalToggler from 'components/functional/modals/Toggler'

import { toast } from "react-toastify";

import AddDepartment from "../components/Add";

const DepartmentsAll = ({setTab, selected_division}) => {

    const [ hideTable, setHideTable ] = useState(false);
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ departmentToDelete, setDepartmentToDelete ] = useState(false)
    const [ error, setError ] = useState(false);

    // helper for this.downloadCSV and this.query
    const fetchData = useCallback((params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        const query = await api.division_departments.search({ ...params, filter: { division: selected_division._id }, isCSV })
        
        if(!query.data) return setError(query.message)
        return resolve(query.data)
    }), [sizePerPage, selected_division._id])

    const onSaved = useCallback(async (params) => {
        setHideTable(true)
        setTimeout(() => {
            setHideTable(false)
        }, 250)
    }, [])

    // run query to get data from the server
    const query = useCallback(async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }, [fetchData])

    const onDeleteDepartment = useCallback(async (user) => {
        const deleted = await api.division_departments.delete(departmentToDelete._id);
        if(!deleted.success) return toast.error(`Something's not right, please try again`)

        onSaved();

    }, [departmentToDelete, onSaved])
 
    const columns = [
        {
            dataField: "name",
            text: 'Name',
            headerFormatter: () => {
                return <div>Name</div>
            },
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div>No Data To Show</div>
                )
                return (
                    <Link to={`/system/departments/${row._id}`} _id={row._id} onSaved={() => fetchData(null, null, true)}>
                        <span className="cursor-pointer text-capitalize">
                            {row.name}
                        </span>
                    </Link>
                )
                
            },
        },
        {
            dataField: "users",
            text: 'Users',
            formatter: (cell, row) => row.name === 'no data' ? '' : row.users.length
        },
        {
            dataField: "supervisors",
            text: 'Supervisors',
            formatter: (cell, row) => row.name === 'no data' ? '' : row.supervisors.length
        },
      
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right',},
            text: 'Actions',
            headerFormatter: () => {
                return <div>Actions</div>
            },
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                        <TableIcons
                            icons={[
                                { 
                                    icon: 'fas fa-edit',
                                    color: 'success', 
                                    wrapper: Link, 
                                    to: `/system/departments/${row._id}`,
                                    // component: EmailTemplatesEdit,
                                    _id: row._id,
                                    onSaved: () => fetchData(null, null, true)
                                },
                                {  
                                    icon: 'fas fa-trash', 
                                    color: 'danger',
                                    onClick: () => setDepartmentToDelete(row)
                                },
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(error) return <ApiError err={error} />

    return (

        <>

            <TextButtonRow 
                title={"All Departments"}
                button={(
                    <ModalToggler component={AddDepartment} onSaved={onSaved}>
                        <button className="btn btn-success">
                            <i className="fas fa-plus mr-2 " /> Add Department
                        </button>
                    </ModalToggler> 
                )}
            />

            <Card className="card-color card-primary table-fixed">
                {hideTable ? <Circle /> : (
                    <Table
                        tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                        placeholder="Search..."
                        query={query}
                        columns={columns}
                        data={tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                        totalDocuments={tableData.total_documents}
                        sizePerPage={sizePerPage}
                        onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                    />
                )}
            </Card>

            <ConfirmationModal 
                showModal={departmentToDelete ? true : false}
                toggleModal={() => setDepartmentToDelete(null)}
                title="Delete Department"
                body={(
                    <span>
                        Are you sure you wish to delete this department {departmentToDelete ? departmentToDelete.name : ''}? 
                    </span>
                )}
                onConfirmation={onDeleteDepartment}
            />

        </>
    )

}


const mapStateToProps = state => {
	return {
        selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(DepartmentsAll);