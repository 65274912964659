import React, { useCallback } from 'react';
import { useDnD } from '../_helpers/DnDContext';


const BackgroundProcessesSidebarLeft = ({match, selected_division}) => {

    const [_, setType] = useDnD();

    const onDragStart = (event, nodeType, icon) => {
      setType(nodeType);
      event.dataTransfer.effectAllowed = 'move';
    };
  
    const createDragItem = (name, type, icon) => {
        return (
            <div className="sidebar-item can-drag" onDragStart={(event) => onDragStart(event, type)} draggable>
                {icon}
                {name}
            </div>
        )
    }
   
    return (
        <div className='sidebar-left z-depth-2'>

            <div className="sidebar-item pb-0"><h2>Step Menu</h2></div>
            <div className="sidebar-item pt-0 mt-"><p className='text-sm mb-0 text-dark font-weight-bold'>Drag Items Into The Flow To Use Them.</p></div>

            <hr className='my-2' />

            <div className="sidebar-item mb--3 pb-0"><p className='text-sm mb-0'>Do Something</p></div>
            {createDragItem('Run Assistant','run_assistant', <i className="fa-solid fa-computer text-success mr-2" />)}
            {createDragItem('Create Action','action', <i className="fa-solid fa-location-arrow text-info mr-2" />)}

            <div className="sidebar-item mb--3 pb-0"><p className='text-sm mb-0'>Inject Logic</p></div>
            {createDragItem('If Statement','if_statement', <i className="fa-regular fa-circle-question text-warning mr-2" />)}

            <div className="sidebar-item mb--3 pb-0"><p className='text-sm mb-0'>Notify</p></div>
            {createDragItem('Internal Notification','notification', <i className="fa-solid fa-envelope-circle-check text-purple mr-2" />)}

            <div className="sidebar-item mb--3 pb-0"><p className='text-sm mb-0'>Pause</p></div>

            {createDragItem('User Verification','user_verification', <i className="fa-solid fa-pause text-danger mr-2" />)}
            {createDragItem('Wait','wait', <i className="fa-solid fa-hourglass text-yellow mr-2" />)}

        </div>

    )

}

export default BackgroundProcessesSidebarLeft