import { Badge } from 'reactstrap';

import ObjectFinder from 'components/system/Objects/Finder';
import SearchCollections from 'components/system/Search/Collections';

import ObjSearch from 'components/system/Search/ObjSearch';
import ReactSelect from 'components/functional/inputs/ReactSelect';

const WorkflowSidebarRecommendedStep = ({stepToEdit, onSetStepToEdit, steps, roles, onAddArrayField, onRemoveArrayField}) => (
    <div>

        <h2 className='text-warning'>STEP ROUTING</h2>

        {console.log(stepToEdit)}

        <ObjSearch 
            objs={[{_id: null, name: <span><i className="fas fa-times text-danger mr-2" /> None</span>}].concat(steps.filter(step => {
                const id = step._id ? step._id : step.id;
                return stepToEdit.next_steps.includes(id)
            }))}
            label={(
                <span>
                    Recommended Next Step
                </span>
            )}
            value={stepToEdit.recommended_next_step}
            onChange={(step) => onSetStepToEdit('recommended_next_step', step._id !== undefined ? step._id : step.id)}
        />

        <ReactSelect 
            formGroup={true}
            title="Assign Uploaded Docs To"
            value={stepToEdit.assign_docs_to}
            onChange={(obj) => onAddArrayField('assign_docs_to', obj.value)}
            options={roles.map((disposition, i) => {
                return { value: disposition._id, label: disposition.name }
            })}
        />

        {stepToEdit.assign_docs_to.length ? stepToEdit.assign_docs_to.map((automation, i) => (
            <Badge key={automation} color="success" className="ml-0 mb-2 text-left mr-3">
                <ObjectFinder collection="workflow_roles" _id={automation} />
                <span className="pr-2 pl-3 cursor-pointer float-right" onClick={() => onRemoveArrayField('assign_docs_to', automation)}>
                    <i className="fas fa-times " />
                </span>
            </Badge>
        )) : null}



        <ReactSelect 
            formGroup={true}
            title="Route Communication To"
            value={stepToEdit.route_communication_to}
            onChange={(obj) => onAddArrayField('route_communication_to', obj.value)}
            options={roles.map((disposition, i) => {
                return { value: disposition._id, label: disposition.name }
            })}
        />

        {stepToEdit.route_communication_to &&  stepToEdit.route_communication_to.length ? stepToEdit.route_communication_to.map((automation, i) => (
            <Badge key={automation} color="success" className="ml-0 mb-2 text-left mr-3">
                <ObjectFinder collection="workflow_roles" _id={automation} />
                <span className="pr-2 pl-3 cursor-pointer float-right" onClick={() => onRemoveArrayField('route_communication_to', automation)}>
                    <i className="fas fa-times " />
                </span>
            </Badge>
        )) : null}

        
        <ReactSelect 
            formGroup={true}
            title="Text & Email Priority"
            value={stepToEdit.communication_priority}
            onChange={(obj) => onSetStepToEdit('communication_priority', obj.value === 'none' ? '' : obj.value)}
            options={
                [
                    { value: 1, label: '1 (Highest)' },
                    { value: 2, label: '2' },
                    { value: 3, label: '3' },
                    { value: 4, label: '4' },
                    { value: 5, label: '5 (Default)' },
                    { value: 6, label: '6' },
                    { value: 7, label: '7' },
                    { value: 8, label: '8' },
                    { value: 9, label: '9 (Lowest)' },
                ]
            
            }
        />

    </div>
)



export default WorkflowSidebarRecommendedStep