import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input, Row, Col } from "reactstrap";

import api from 'api';

import Modalify from 'components/functional/modals/Modalify';

import PhoneInput from 'react-phone-input-2';
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';

import SearchCollections from 'components/system/Search/Collections';
import ReactSelect from 'components/functional/inputs/ReactSelect';

import moment from 'moment'

import { toast } from 'react-toastify'

const baseState = {
    lead_source       : null,
    call_flow         : null,
    number            : '+1',
    name              : '',
    record_inbound    : false,
    record_outbound   : false,
}



const CallPhoneNumberEdit = ({_id, onSaved, showModal, toggleModal, selected_division}) => {

    const [number, setNumber] = useState('')
    const [err, setErr] = useState(null)

    const onSave = useCallback(async () => {

        if(!number) {
            return setErr([`A number must be added.`])
        }

        toggleStandardLoader(true)
        let saved = await api.call_blocked_numbers.create({ number, division: selected_division._id });
        toggleStandardLoader(false)

        if(!saved.success) return toast.info(`Please enter a valid phone number before continuing.`)

        toggleModal();
        if(onSaved) onSaved(saved.data)

        // // show error messages if unsuccessful
        // if(saved.success) {
        //     toggleAlertBS(false, "Phone Number Saved Successfully")
        //     fetchData();
        //     if(toggleModal) toggleModal();
        //     if(onSaved) onSaved(saved.data)
        // } else {
        //     return setErr(saved.message);
        // }
    
    }, [number, onSaved, selected_division._id, toggleModal])

    return (
        <Modalify 
            showModal={showModal}
            toggleModal={toggleModal}
            modalTitle={"Add To Blacklist"}
            err={err}
            footer={(
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            )}
            sections={[
                (
                    <div>

                        <h2>Block A Number</h2>

                        <FormGroup>
                            <label className="form-control-label">Enter The Number Below</label>
                            <Input
                                type="text"
                                value={number}
                                onChange={e => setNumber(e.target.value)}
                            />
                        </FormGroup>      

                      
                    </div>
                ),

            
                      
            ]}
        />
    )

}




const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(CallPhoneNumberEdit);