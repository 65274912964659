import * as actionTypes from 'store/actions';
import store from 'store';

import api from 'api'

import handleUpdate from '../_helpers/handleUpdate'

const setDivisions = async() => new Promise(async(resolve) => {
    const divisions = await await api.divisions.find()
    if (!divisions.success) return resolve(divisions.message)
    
    store.dispatch({type: actionTypes.SET_DIVISIONS, payload: divisions.data});

    handleUpdate({
        type: 'divisions',
        action: 'SET_DIVISIONS',
        events: {
            created: 'DIVISIONS.CREATED',
            updated: 'DIVISIONS.UPDATED',
            deleted: 'DIVISIONS.DELETED',
        }
    })
    return resolve(null);
})

export default setDivisions;
