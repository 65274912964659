import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Input } from 'reactstrap';

import api from 'api';
import { toast } from 'react-toastify';

import ReactSelect from 'components/functional/inputs/ReactSelect';
import CopyText from 'components/functional/text/Copy';

import ConfirmationModal from 'components/functional/modals/Confirmation';


const WorkflowEditLocationsRoleRow = ({location, locationTypes, onDelete}) => {

    const [showDelete, setShowDelete] = useState(false)
    const [name, setName] = useState(location.name)
    const [isQueryLocation, setIsQueryLocation] = useState(location.is_query_location)
    const [locationType, setLocationType] = useState(location.location_type)

    const onSave = useCallback(async (field, value) => {
        const data = await api.workflow_locations.update(location._id, { [field]: value })
        if(!data.success) toast.error(`Could not save workflow location, please try again`)
    }, [location._id])
    
    return (
        <React.Fragment key={location._id}>

            <tr>
                <td>
                    <Input 
                        value={name}
                        onChange={e => setName(e.target.value)}
                        onBlur={() => onSave('name', name)}
                    />
                </td>
                <td>
                    <ReactSelect 
                        formGroup={true}
                        value={locationType}
                        onChange={(obj) => {
                            setLocationType(obj.value)
                            onSave('location_type', obj.value)
                        }}
                        options={locationTypes ? locationTypes.map(l => {
                            return { label: l.name, value: l._id }
                        }) : []}
                    />
                </td>
                <td>
                    <ReactSelect
                        formGroup={false}
                        placeholder=""
                        onChange={(obj) => {
                            setIsQueryLocation(obj.value)
                            onSave('is_query_location', obj.value)
                        }}
                        value={isQueryLocation}
                        options={[
                            { value: false, label: <span className="-danger">No</span > },
                            { value: true, label: <span className="-success">Yes</span > },
                        ]}

                    />  
                </td>

                <td ><CopyText text={location._id} /></td>

                <td className="text-right">
                    <button className="btn btn-danger" onClick={() => setShowDelete(true)}>
                        <i className="fas fa-trash mr-2 " /> Remove Location
                    </button>
                </td>
            </tr>

            <ConfirmationModal 
                showModal={showDelete}
                toggleModal={() => setShowDelete(false)}
                title={"Delete Workflow Location"}
                onConfirmation={() => onDelete(location)}
                body={(
                    <span>
                        Are you sure you wish to delete the workflow location <b className='text-underline text-dark'>"{location.name}"</b>? This cannot be undone and any email emails, text, etc. that use their field map ID will return empty values potentially creating breaking changes.
                    </span>
                )}
            />
  

        </React.Fragment>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(WorkflowEditLocationsRoleRow);