import { useCallback, useState } from "react";
import { connect } from 'react-redux';
import { Card } from "reactstrap";

import api from 'api';
import { Link } from 'react-router-dom';

import ModalToggler from 'components/functional/modals/Toggler';
import Table from 'components/functional/tables/Standard';
import TableIcons from 'components/markup/icons/TableIcons';
import ApiError from "components/markup/layout/ApiError";
import ObjectFinder from 'components/system/Objects/Finder';
import A from 'components/markup/links/A';

import Circle from 'components/markup/loading/Circle';
import TextButtonRow from 'components/markup/layout/TextButtonRow';

import Edit from '../components/Edit'

import moment from 'moment'

const LocationsAll = ({setTab, selected_division}) => {

    const [ loading, setLoading ] = useState(false);
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ error, setError ] = useState(false);


    // helper for this.downloadCSV and this.query
    const fetchData = useCallback((params, isCSV, shouldReload) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(shouldReload) setLoading(true)
        const query = await api.lead_sources.search({ ...params, filter: { deleted: false, division: selected_division._id }, isCSV })
        if(shouldReload) setLoading(false)

        if(!query.data) return setError(query.message)
        return resolve(query.data)
    }), [sizePerPage, selected_division._id])

    // run query to get data from the server
    const query = useCallback(async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }, [fetchData])

   
 
    const columns = [
        {
            dataField: "given_name",
            text: 'Matter',
            formatter: (cell, row) => row.name === 'no data' ? (
                <div style={{minHeight: 150}}>No Data To Show</div> 
            ) : (
                <Link to={`/marketing/lead_sources/${row._id}`}>{row.name}</Link>

            )
        },
        {
            dataField: "address_line_1",
            text: 'Categories',
            formatter: (cell, row) => row.name === 'no data' ? '' : (
                row.lead_source_categories ? 
                    row.lead_source_categories.map((c, i) => <span>{i === 0 ? '' : ', '}<ObjectFinder collection="lead_source_categories" _id={c} /></span>)
                    : ''
            )
        },

        {
            dataField: "address_line_1",
            text: 'Last Lead',
            formatter: (cell, row) => row.name === 'no data' ? '' : row.last_lead ? moment.unix(row.last_lead).format('h:mmA MM/DD/YYYY') : '-'
        },
        {
            dataField: "address_line_1",
            text: 'Created',
            formatter: (cell, row) => row.name === 'no data' ? '' : moment.unix(row.created_at).format('MM/DD/YYYY')
        },
       
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right',},
            text: 'Actions',
            headerFormatter: () => {
                return <div id="archk-tutorial-contacts-actions">Actions</div>
            },
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                         <TableIcons
                            icons={[
                              
                                { 
                                    icon: 'fas fa-edit',
                                    color: 'success', 
                                    wrapper: ModalToggler, 
                                    component: Edit,
                                    onSaved: () => fetchData(null, null, true),
                                    _id: row._id
                                },
                                {
                                    icon: 'fas fa-chart-line',
                                    color: 'info', 
                                    wrapper: A, 
                                    href: `/lead_sources/${row._id}`,
                                },
                                {
                                    icon: 'fas fa-users',
                                    color: 'danger', 
                                    wrapper: A, 
                                    href: `/leads/${row._id}`,
                                },
                             
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(error) return <ApiError err={error} />
    if(loading) return <div><Circle /></div>

    return (

        <>

        <TextButtonRow 
            title={"Lead Sources"}
            button={(
                <div>
                     <Link to="/marketing/lead_source_categories" className="btn btn-info"><i className="fas fa-plus mr-2 " /> Add Source Category</Link>
                        <button onClick={() => setTab('create')} className="btn btn-success"><i className="fas fa-plus mr-2 " /> Add New</button>
                </div>
            )}
        />

        <Card className="card-color card-primary table-fixed">
            <Table
                tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                placeholder="Search..."
                query={query}
                columns={columns}
                data={tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                totalDocuments={tableData.total_documents}
                sizePerPage={sizePerPage}
                onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
            />
        </Card>

        </>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(LocationsAll);