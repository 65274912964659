import Circle from 'components/markup/loading/Circle';
import { useCallback, useEffect, useState } from 'react';
import { Card, CardHeader, CardTitle, Col, FormGroup, Input, Row } from 'reactstrap';

import api from 'api';
import { toast } from 'react-toastify';
import ReactSelect from 'components/functional/inputs/ReactSelect';

import _types from './_types'

import DispositionRow from './DispositionRow'

const WorkflowEditDispositions = ({workflow}) => {

    const [disposition, setDisposition] = useState('')
    const [type, setType] = useState(undefined)
    const [dispositions, setDispositions] = useState(workflow.dispositions)

    const _handleKeyDown = useCallback((e) => {
		if (e.key === 'Enter') document.getElementById("archk-add-disposition-button").click();
	}, [])

    const onAdd = useCallback(async () => {
        if(!disposition) return;
        const _dispositions = JSON.parse(JSON.stringify(dispositions));

        const created = await api.workflow_dispositions.create({
            workflow: workflow._id,
            name: disposition,
            type: type ? type : undefined
        })
        if(created.success) {
            _dispositions.push(created.data)
            setDispositions(_dispositions);
            setDisposition('')   
            setType(undefined)   
        } else {
            toast.error("Something went wrong creating this disposition, please try again and make sure the disposition is unique.")
        }
      
    }, [dispositions, disposition, type, workflow._id])
    
    const onDelete = useCallback(async (_disposition) => {
        let _dispositions = JSON.parse(JSON.stringify(dispositions));

        const deleted = await api.workflow_dispositions.delete(_disposition._id)
        if(deleted.success) {
            _dispositions = _dispositions.filter(r => r._id !== deleted.data._id)
            setDispositions(_dispositions);
        } else {
            toast.error("Something went wrong deleting this disposition, please try again.")
        }
    }, [dispositions])

    const fetchData = useCallback(async () => {
        const data = await api.workflow_dispositions.find(workflow._id);
        if(data.data) setDispositions(data.data)
    }, [workflow._id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <Card>
            <CardHeader>
                <CardTitle>Add Workflow Dispositions</CardTitle>
                <p className="text-sm mb-0"><i className="fas fa-info-circle mr-2 info-circle text-info " /> Dispositions allow you to update the state of case when a step is moved. Examples could include "Hired", "Does Not Qualify", "Disappeared", "Etc".</p>

            </CardHeader>

            <CardHeader>
            
                <label className="form-control-label">Disposition Name*</label>

                <Row>
                    <div className="col-auto" style={{width: 'calc(50% - 84px)'}}>
                        <FormGroup>
                            <Input 
                                onKeyDown={_handleKeyDown}
                                type="text"
                                value={disposition}
                                onChange={(e) => setDisposition(e.target.value)}

                            />
                        </FormGroup>
                    </div>
                    <div className="col-auto" style={{width: 'calc(50% - 84px)'}}>
                        <ReactSelect 
                            formGroup={true}
                            value={type}
                            onChange={(obj) => setType(obj.value)}
                            options={_types}
                            placeholder="Select Type"
                        />
                    </div>
                    <Col>
                        <button 
                            id="archk-add-disposition-button"
                            onClick={onAdd} 
                            className="btn btn-outline-info btn-block"
                        >
                            <i className="fas fa-plus mr-2 " /> Add Disposition
                        </button>
                    </Col>
                </Row>
            </CardHeader>

            {!dispositions ? <Circle /> : (
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Workflow Dispositions</th>
                                <th>Type</th>
                                <th>Field Map ID</th>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dispositions.length ? dispositions.map((disposition) => <DispositionRow disposition={disposition} onDelete={onDelete} />) : (
                                <tr>
                                    <td>No dispositions have been created for this workflow</td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}


        </Card>
    )

}

export default WorkflowEditDispositions;