import Circle from 'components/markup/loading/Circle';
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, CardTitle, Col, FormGroup, Input, Row } from 'reactstrap';

import api from 'api';
import { toast } from 'react-toastify';

import ReactSelect from 'components/functional/inputs/ReactSelect';

import LocationRole from './LocationRow';

const WorkflowEditContactTypes = ({workflow, selected_division}) => {

    const [name, setName] = useState('')
    const [location, setLocation] = useState('')
    const [isQueryLocation, setIsQueryLocation] = useState(false)

    const [allLocations, setAllLocations] = useState(workflow.allLocations)
    const [locationTypes, setLocationTypes] = useState(workflow.allLocations)

    const _handleKeyDown = useCallback((e) => {
		if (e.key === 'Enter') document.getElementById("archk-add-location-button").click();
	}, [])

    const onAdd = useCallback(async () => {
        if(!name || !location) return toast.info("Both a name and location type must be specified to create a workflow location.")
        const _allLocations = JSON.parse(JSON.stringify(allLocations));

            const created = await api.workflow_locations.create({
                workflow: workflow._id,
                name: name,
                location_type: location,
                is_query_location: isQueryLocation
            })
            if(created.success) {
                _allLocations.push(created.data)
                setAllLocations(_allLocations);
                setName('')   
                setLocation('')   
            } else {
                toast.error("Something went wrong creating this location, please try again and make sure the location is unique.")
            }
      
    }, [allLocations, name, location, workflow._id, isQueryLocation])
    
    const onDelete = useCallback(async (_location) => {
        let _allLocations = JSON.parse(JSON.stringify(allLocations));

        const deleted = await api.workflow_locations.delete(_location._id)
        if(deleted.success) {
            _allLocations = _allLocations.filter(r => r._id !== deleted.data._id)
            setAllLocations(_allLocations);
        } else {
            toast.error("Something went wrong deleting this location, please try again.")
        }
    }, [allLocations])

    const fetchData = useCallback(async () => {
        const values = await Promise.all([
            api.workflow_locations.find(),
            api.location_types.find(),
        ])
        if(values[0] && values[1].data) {
            setAllLocations(values[0].data.filter(d => d.workflow === workflow._id))
            setLocationTypes(values[1].data.filter(d => d.division === selected_division._id))
        }
    }, [workflow._id, selected_division._id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <Card>
            <CardHeader>
                <CardTitle>Add Workflow Location</CardTitle>
                <p className="text-sm mb-0"><i className="fas fa-info-circle mr-2 info-circle text-info " /> Workflow locations allow you to create relationships between location types and a named matter entry. For instance, you can create Workflow Location called "341 Hearing" and associate it with a Location Type of "Court Room". This will allow staff to only assign locations deemed as a "Court Room" to the matter which will read as "341 Hearing: 123 Manhattan Ave, New York NY" inside the matter.</p>

            </CardHeader>
            <CardHeader>
                <Row>
                    <div className="col-auto" style={{width: 'calc(33% - 84px)'}}>
                        <label className="form-control-label">Location Name*</label>
                    </div>

                    <div className="col-auto" style={{width: 'calc(33% - 84px)'}}>
                        <label className="form-control-label">Location Type*</label>
                    </div>
                    <div className="col-auto" style={{width: 'calc(33% - 84px)'}}>
                        <label className="form-control-label">Query Location*</label>
                    </div>
                    <Col></Col>
                </Row>

                <Row>
                    <div className="col-auto" style={{width: 'calc(33% - 84px)'}}>
                        <FormGroup>
                            <Input 
                                onKeyDown={_handleKeyDown}
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}

                            />
                        </FormGroup>
                    </div>

                    <div className="col-auto" style={{width: 'calc(33% - 84px)'}}>
                        <ReactSelect 
                            formGroup={true}
                            value={location}
                            onChange={(obj) => setLocation(obj.value)}
                            options={locationTypes ? locationTypes.map(l => {
                                return { label: l.name, value: l._id }
                            }) : []}
                        />
                    </div>
                    <div className="col-auto" style={{width: 'calc(33% - 84px)'}}>
                        <ReactSelect 
                            formGroup={true}
                            value={location}
                            onChange={(obj) => setIsQueryLocation(obj.value)}
                            options={[
                                { label: 'No', value: false },
                                { label: 'Yes', value: true },
                            ]}
                        />
                    </div>
                    <Col>
                        <button 
                            id="archk-add-location-button"
                            onClick={onAdd} 
                            className="btn btn-outline-info btn-block"
                        >
                            <i className="fas fa-plus mr-2 " /> Add Location
                        </button>
                    </Col>
                </Row>
            </CardHeader>

            {!allLocations ? <Circle /> : (
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Location Name</th>
                                <th>Type</th>
                                <th>Is Query Location</th>
                                <th className=''>Field Map ID</th>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allLocations.length ? allLocations.map((location) => <LocationRole location={location} onDelete={onDelete} locationTypes={locationTypes} />) : (
                                <tr>
                                    <td>No locations have been created yet for this workflow</td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}


        </Card>
    )

}


const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(WorkflowEditContactTypes);