import { useCallback, useEffect, useState } from 'react';
import api from 'api';
import ReactSelect from 'components/functional/inputs/ReactSelect';

const acceptedTypes = [
    'text', 
    'select', 
    'number', 
    'phone', 
    'state', 
    'date', 
    'yes-no'
]

const WorkflowSidebarSigningTemplatesValues = ({template, onChange, customFields, contacts}) => {

    const [options, setOptions] = useState([])
    const [markers, setMarkers] = useState([])

    const fetchData = useCallback(async () => {
        if(template.signing_template) {
            const data = await api.signing_templates.findById(template.signing_template)
            if(data.data) {
                const markers = data.data.markers.filter(m => m.type === 'text')
                setMarkers(markers)
            }
        }
    }, [template.signing_template])

    const onFieldChange = useCallback((id, value) => {
        const _values = JSON.parse(JSON.stringify(template.values));
        const found = _values.find(v => v.marker === id)

        if(found) {
            found.value = value
        } else {
            _values.push({marker: id, value})
        }

        onChange(template.id, 'values', _values)
    }, [template.values, template.id, onChange])

    const getValue = useCallback((marker) => {
        const found = template.values.find(m => m.marker === marker);
        return found ? found.value : ''
    }, [template.values])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    useEffect(() => {
        let _options = [
            { label: `Matter - Name`, value: `matter.name` },
            { label: `Matter - Identifier`, value: `matter.identifier` }
        ];

        contacts.forEach(c => {
            _options.push({ label: `${c.name} - Name`, value: `workflow_contact.${c._id}.name` })
            _options.push({ label: `${c.name} - Email`, value: `workflow_contact.${c._id}.email` })
            _options.push({ label: `${c.name} - Phone`, value: `workflow_contact.${c._id}.phone` })
        })

        if(customFields) {
            customFields.forEach(c => {
                if(acceptedTypes.includes(c.type)) {
                    _options.push({ label: `Field - ${c.name}`, value: `custom_field.${c._id}` })
                }
            })
        }
       

        setOptions(_options)
    }, [contacts, customFields])

    return markers && markers.length ? (
        <div>
            <h4>Optional Pre-Filled Fields</h4>
            {markers.map(m => (
                <ReactSelect 
                    key={m._id}
                    formGroup={true}
                    title={m.name}
                    value={getValue(m._id)}
                    onChange={(obj) => onFieldChange(m._id, obj.value === 'none' ? '' : obj.value)}
                    options={
                        [
                            { value: 'none', label: <span><i className="fas fa-times text-danger mr-2 " />None</span> },
                            ...options
                        ]
                    
                    }
                />
            ))}
        </div>
    ) : ''
}


export default WorkflowSidebarSigningTemplatesValues