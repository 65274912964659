import { Modal, FormGroup, Input } from "reactstrap";
import ReactSelect from 'components/functional/inputs/ReactSelect';


const ValidationModal = ({showModal, toggleModal, name, setName, active, setActive, description, setDescription}) => (
    <Modal
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={toggleModal}
        size="md"
    >

        <div className="modal-header">
            <h5 className="modal-title">Process Settings</h5>
            <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggleModal}
            >
                <span aria-hidden={true}>×</span>
            </button>
        </div>

        <div className="modal-body border-top">
            <FormGroup>
                <label className="form-control-label">**Background Process Name</label>
                <Input 
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
            </FormGroup>

            <FormGroup>
                <label className="form-control-label">Process Description</label>
                <Input 
                    type="textarea"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                />
            </FormGroup>

            <ReactSelect
                title={"Running"}
                formGroup={true}
                placeholder=""
                onChange={(obj) => setActive(obj.value)}
                options={[
                    { label: 'No', value: false },
                    { label: 'Yes', value: true },
                ]}
                value={active}
            />   
        </div>

        <div className="modal-footer">
            <button className="btn btn-outline-warning" onClick={toggleModal}>
                Close
            </button>
        </div>

    </Modal>
)

export default ValidationModal