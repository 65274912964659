import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import api from 'api';

import Modalify from 'components/functional/modals/Modalify';

import { setCompany as refreshCompany } from 'store/functions/company/company';
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';

const CompanyUpdateNames = ({onSaved, showModal, toggleModal, viewing_user}) => {

    const [company, setCompany] = useState(null)
    const [err, setErr] = useState(null)

    const fetchData = useCallback(async () => {
        const company = await api.companies.findById(viewing_user.company)
        if(!company.data) return setErr(true);
        setCompany(company.data)
    }, [viewing_user.company])

    const onInputChange = useCallback(( field, value) => {
        const newCompany = Object.assign({}, company)
        newCompany[field] = value;
        setCompany(newCompany);
    }, [company])

    const onSave = useCallback(async () => {
        setErr(false)
        let newState = Object.assign({}, company);

        if(!newState.name) {
            return setErr([`A company must have a name.`])
        }

        setCompany(newState)

        // toggle loaders and update/create the company
        toggleStandardLoader(true)
        let saved = await api.companies.update(newState._id, {
            ai_enabled : newState.ai_enabled,
        }, true);
        toggleStandardLoader(false)

        // show error messages if unsuccessful
        if(saved.success) {
            toggleAlertBS(false, "Company Saved Successfully")
            fetchData();
            refreshCompany();
            if(toggleModal) toggleModal();
            if(onSaved) onSaved(saved.data)
        } else {
            return setErr(saved.message);
        }
    
    }, [company, fetchData, toggleModal, onSaved])

    useEffect(() => {
        if(toggleModal) {
            if(showModal) {
                fetchData()
            } else {
                setErr(null)
                setCompany('')
            }
        } else {
            fetchData()
        }
    }, [showModal, toggleModal, fetchData])

    return (
        <Modalify 
            showModal={showModal}
            toggleModal={toggleModal}
            modalTitle={company && company._id ? "Update Company" : "Create Company"}
            err={err}
            footer={(
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            )}
            sections={!company ? [] :  [
                (
                    <div>
                        <h2>Artificial Intelligence Settings</h2>

                        <div className="custom-control custom-checkbox pr-0">
                            <input
                                className="custom-control-input mr--4"
                                id={`archk-company-ai-enabled`}
                                type="checkbox"
                                checked={company.ai_enabled ? true : false}
                                onChange={() => onInputChange('ai_enabled', !company.ai_enabled)}
                            />
                            <label className="custom-control-label" htmlFor={`archk-company-ai-enabled`}>
                                AI Is Enabled
                            </label>
                        </div>

                    </div>
                ),
               
            ]}
        />
    )

}


const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(CompanyUpdateNames);