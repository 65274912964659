import IntuideskSection from "components/markup/layout/IntuideskSection";

import Update from './Update/index'
import Divisions from './Divisions'
import StringTemplates from './StringTemplates'
import CustomViews from './CustomViews'

const CompanyView = ({match}) => (
    <IntuideskSection 
        title="Company"
        section="company"
        defaultRoute="edit"
        match={match}
        pages={[
            { value: 'edit', icon: 'fas fa-building', name: 'Info',  Component: Update },
            { value: 'divisions', icon: 'fas fa-puzzle-piece', name: 'Divisions',  Component: Divisions },
            { value: 'string_templates', icon: 'fab fa-html5', name: 'String Templates',  Component: StringTemplates },
            // { value: 'branding', icon: 'fas fa-copyright', name: 'Branding',  Component: Update },
            // { value: 'integrations', icon: 'fas fa-link', name: 'Integrations',  Component: Update },
            { value: 'custom_views', icon: 'fa-solid fa-street-view', name: 'Custom Views',  Component: CustomViews },
            // { value: 'billing', icon: 'fas fa-dollar-sign', name: 'Billing',  Component: Update },
        ]}
    />
)

export default CompanyView;