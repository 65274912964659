import { connect } from 'react-redux';

import SearchCollections from 'components/system/Search/Collections';

import ToContacts from './ToContacts';

const AutomationsEditEmail = ({
    selected_division,
    toContacts,
    setToContacts,
    textTemplate,
    setTextTemplate,
}) => {

   
    return (
        <div>
            <SearchCollections
                collection="text_templates"
                title="Text Template"
                value={textTemplate}
                onChange={(obj) => setTextTemplate(obj.value)}
                filter={{
                    division: selected_division._id
                }}
            /> 

            <ToContacts 
                toContacts={toContacts}
                setToContacts={setToContacts}
            />

        </div>
    )

}


const mapStateToProps = state => {
    return {
        selected_division: state.state.selected_division,
    };
};
export default connect(mapStateToProps, '')(AutomationsEditEmail);;