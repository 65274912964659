import { useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardFooter, CardTitle, Row, Col, FormGroup, Input, Container, Badge } from "reactstrap";

import api from 'api';
import { toast } from 'react-toastify'

import ModalToggler from 'components/functional/modals/Toggler';
import Table from 'components/functional/tables/Standard';
import TableIcons from 'components/markup/icons/TableIcons';
import ApiError from "components/markup/layout/ApiError";
import ObjectFinder from 'components/system/Objects/Finder';

import Circle from 'components/markup/loading/Circle';
import TextButtonRow from 'components/markup/layout/TextButtonRow';
import A from 'components/markup/links/A'

import moment from 'moment'

import { formatCurrency } from 'utils/currency';
import formatText from 'utils/formatText';
import SearchCollections from "components/system/Search/Collections";




import ReactSelect from 'components/functional/inputs/ReactSelect';

const LayoutTodaysActivity = ({setTab, match, selected_division, DOMAINS}) => {

    const [items, setItems] = useState(null);
    const [errs, setErrs] = useState([]);

    const onItemChange = useCallback((index, field, value) => {
        const newState = JSON.parse(JSON.stringify(items));

        const item = newState[index];
        item[field] = value;

        if(field === 'type') item._id = null

        newState[index] = item;
        setItems(newState)
    }, [items])
   
    const onAddItem = useCallback(() => {
        const newState = JSON.parse(JSON.stringify(items));
        newState.push({});
        setItems(newState)
    }, [items])
    
    const onRemoveItem = useCallback((index) => {
        const newState = JSON.parse(JSON.stringify(items));
        newState.splice(index, 1)
        setItems(newState)
    }, [items])
  
    const onSave = useCallback(async () => {
        let errs = [];

        items.forEach((item, i) => {
            if(!item.type) errs.push(`Item ${i + 1} must have a type.`)
            if(!item.title) errs.push(`Item ${i + 1} must have a box title entered.`)

            if(item.type === 'custom_date' && !item._id) errs.push(`Item ${i + 1} must have a custom date selected.`)
            if(item.type === 'event_type' && !item._id) errs.push(`Item ${i + 1} must have an event type selected.`)
            if(item.type === 'workflow_step_category' && !item._id) errs.push(`Item ${i + 1} must have a category selected.`)
            if(item.type === 'workflow_disposition' && !item._id) errs.push(`Item ${i + 1} must have a disposition selected.`)
            if(item.type === 'calls' && !item._id) errs.push(`Item ${i + 1} must have a call status/type selected.`)
            if(item.type === 'outstanding_items' && !item._id) errs.push(`Item ${i + 1} must have a type selected.`)

        })

        setErrs(errs)
        if(errs.length) return;

        const saved = await api.custom_layouts.save(selected_division._id, 'MANAGER.TODAYS_ACTIVITY', { options: items })
        if(!saved.success) return setErrs(saved.message);

        toast.success(`Layout saved successfully`)
    }, [items, selected_division._id])

    const fetchData = useCallback(async () => {
        const data = await api.custom_layouts.findOne(selected_division._id, 'MANAGER.TODAYS_ACTIVITY')
        setItems(data.data && data.data.options ? data.data.options : [{}])
    }, [selected_division._id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(!items) return <Circle />

    return (


            <div>
               <Card className="card-no-shadow">
                    <CardBody>
                        <p className="text-sm mb-0"><i className="fas fa-info-circle mr-2 text-info" /> This page allows you to customize the header on the top of Today's Activity page inside the management dashboard. Add metrics you would like to show up as "Most Important" view you and your managers to view. <b className="text-underline">Note that the metrics added below will show up in the exact order they are shown here.</b></p>
                    </CardBody>
               </Card>
              
               <Card className="card-no-shadow">

                    <CardHeader>
                        <Row>
                            <Col md={6} className="align-self-center">
                                <CardTitle className="mb-0">Custom Metrics</CardTitle>
                            </Col>

                            <Col md={6} className="align-self-center text-right">
                                <button onClick={onAddItem} className="btn btn-sm btn-success"><i className="fas fa-plus mr-2" /> Add Metric</button>
                            </Col>
                        </Row>
                    </CardHeader>

                    {items.map((item, i) => (
                        <CardFooter key={i + item.type}>
                            <Row>
                                <Col md={4}>
                                    <ReactSelect
                                        title={(
                                            <span>Custom Metric <i className="fas fa-trash pl-2 text-danger cursor-pointer" onClick={() => onRemoveItem(i)} /></span>
                                        )}
                                        formGroup={true}
                                        placeholder=""
                                        onChange={(obj) => onItemChange(i, 'type', obj.value)}
                                        options={[
                                            { value: 'leads',                   label: 'New Leads'   },
                                            { value: 'contacts',                label: 'New Contacts'   },
                                            { value: 'matters',                 label: 'New Matters'   },
                                            { value: 'documents',               label: 'New Documents'   },
                                            { value: 'signing_documents',       label: 'New Signatures'   },
                                            { value: 'custom_date',             label: 'Custom Date'  },
                                            { value: 'event_type',              label: 'Event Type'  },
                                            { value: 'workflow_step_category',  label: 'Step Category'  },
                                            { value: 'workflow_disposition',    label: 'Step Disposition'  },
                                            { value: 'credit_reports',          label: 'New Credit Reports Pulled'  },
                                            { value: 'calls',                   label: 'Phone Calls'  },
                                            { value: 'outstanding_items',       label: 'Outstanding Items'  },
                                        ]}
                                        value={item.type}
                                    />    
                                </Col>

                                <Col md={4}>

                                    {item.type === 'calls' ? (
                                        <ReactSelect
                                            title={"Call Status"}
                                            formGroup={true}
                                            placeholder=""
                                            onChange={(obj) => onItemChange(i, '_id', obj.value)}
                                            options={[
                                                { value: 'all', label: 'All' },
                                                { value: 'outbound', label: 'Outbound' },
                                                { value: 'inbound', label: 'Inbound' },
                                                { value: 'short_abandoned', label: 'Inbound - Short Abandoned' },
                                                { value: 'long_abandoned', label: 'Inbound - Long Abandoned' },
                                                { value: 'voicemail', label: 'Inbound - Voicemail' },
                                                { value: 'completed', label: 'Inbound - Completed' },
                                                { value: 'in_progress', label: 'Inbound - In Progress' },
                                                { value: 'routed_out', label: 'Inbound - Routed Out' },
                                            ]}
                                            value={item._id}
                                        />    
                                    ) : item.type === 'custom_date' || item.type === 'event_type' || item.type === 'workflow_step_category' || item.type === 'workflow_disposition' ? (
                                        <div>
                                            <SearchCollections
                                                collection={
                                                    item.type === 'custom_date' ? 'custom_dates' :
                                                    item.type === 'event_type' ? 'event_types' :
                                                    item.type === 'workflow_step_category' ? 'workflow_step_categories' :
                                                    item.type === 'workflow_disposition' ? 'workflow_dispositions' :
                                                    ''
                                                }
                                                placeholder={
                                                    item.type === 'custom_date' ? 'Search Custom Dates...' :
                                                    item.type === 'event_type' ? 'Search Event Types...' :
                                                    item.type === 'workflow_step_category' ? 'Search Categories...' :
                                                    item.type === 'workflow_disposition' ? 'Search Dispositions...' :
                                                    ''
                                                }
                                                value={item._id}
                                                onChange={(obj) => onItemChange(i, '_id', obj.value)}
                                            /> 
                                        </div>
                                    ) : item.type === 'outstanding_items' ? (
                                        <ReactSelect
                                            title={"Item Type"}
                                            formGroup={true}
                                            placeholder=""
                                            onChange={(obj) => onItemChange(i, '_id', obj.value)}
                                            options={[
                                                { value: 'finished', label: 'Finished' },
                                                { value: 'unfinished', label: 'Unfinished' },
                                            ]}
                                            value={item._id}
                                        />    
                                    ) : (
                                        <FormGroup>
                                            <label className="form-control-label text-white">.</label>
                                            <Input 
                                                disabled={true}
                                            />
                                        </FormGroup>
                                    )}

                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <label className="form-control-label">Box Title</label>
                                        <Input 
                                            disabled={!item.type}
                                            type="text"
                                            value={item.title}
                                            onChange={(e) => onItemChange(i, 'title', e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                        <label className="form-control-label text-success">Green If Greater Than</label>
                                        <Input 
                                            disabled={!item.type}
                                            type="number"
                                            value={item.success}
                                            onChange={(e) => onItemChange(i, 'success', e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <label className="form-control-label text-info">Blue If Greater Than</label>
                                        <Input 
                                            disabled={!item.type}
                                            type="number"
                                            value={item.info}
                                            onChange={(e) => onItemChange(i, 'info', e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <label className="form-control-label text-warning">Orange If Greater Than</label>
                                        <Input 
                                            disabled={!item.type}
                                            type="number"
                                            value={item.warning}
                                            onChange={(e) => onItemChange(i, 'warning', e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <label className="form-control-label text-danger">Red If <b className="text-underline">LESS</b> Than</label>
                                        <Input 
                                            disabled={!item.type}
                                            type="number"
                                            value={item.danger}
                                            onChange={(e) => onItemChange(i, 'danger', e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardFooter>
                    ))}

                    {errs.length ? (
                        <CardFooter className="bg-secondary">
                            {errs.map((err, i) => (
                                <p key={i} className="text-sm text-warning font-weight-bold mb-0"><i className="fas fa-info-circle mr-2" /> {err}</p>
                            ))}
                        </CardFooter>
                    ) : ''}

                    <CardFooter className="text-right">
                        <button onClick={onSave} className="btn btn-success">Save Layout</button>
                    </CardFooter>
               </Card>
            </div>

    )
}


const mapStateToProps = state => {
	return {
        DOMAINS: state.load.DOMAINS,
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(LayoutTodaysActivity);