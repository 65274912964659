import LocationsEdit from './components/Edit'
import LocationsAll from './All'

import IntuideskSectionWrapper from 'components/markup/layout/IntuideskSectionWrapper'

const SystemLocations = ({match, section}) => (
    <IntuideskSectionWrapper 
        title="Call Routing"
        editTitle="Call Routing"
        match={match}
        section={section}
        innerSection={'call_flows'}
        Edit={LocationsEdit}
        All={LocationsAll}
    />
)

export default SystemLocations;