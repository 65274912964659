import { Input, FormGroup } from "reactstrap";
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import { useCallback, useState, useEffect } from 'react';

import api from 'api'

import Modalify from 'components/functional/modals/Modalify'

import { toggleStandardLoader } from 'store/functions/system/system';
import { toast } from 'react-toastify'

const MarketingLeadSourceCategoriesEdit = ({_id, onSaved, showModal, toggleModal, selected_division}) => {

    const [category, setCategory] = useState(null)
    const [err, setErr] = useState(null)
    const [redirect, setRedirect] = useState(null)

    const fetchData = useCallback(async () => {
        if(!_id || _id === 'create') return setCategory({});
        const category = await api.lead_source_categories.findById(_id)
        if(!category.data) return setErr(true);
        setCategory(category.data)
    }, [_id])

    const onInputChange = useCallback(( field, value) => {
        const newCategory = Object.assign({}, category)
        newCategory[field] = value;
        setCategory(newCategory);
    }, [category])

    const onSave = useCallback(async () => {
        setErr(false)
        let newState = Object.assign({}, category);

        if(!newState.name) {
            return setErr([`A lead source category must have a name.`])
        }
        
        let saved;

        // toggle loaders and update/create the category
        toggleStandardLoader(true)
        if(newState._id) {
            saved = await api.lead_source_categories.update(newState._id, newState, true);
        } else {
            saved = await api.lead_source_categories.create({...newState, division: selected_division._id}, true);
        }
        toggleStandardLoader(false)

        if(!saved.success) return setErr(saved.message)

        toast.success(`Lead Source Category Created Successfully`)
        fetchData();
        if(toggleModal) toggleModal();
        if(onSaved) onSaved(saved.data)

    }, [category, fetchData, toggleModal, onSaved, selected_division._id])

    useEffect(() => {
        if(toggleModal) {
            if(showModal) {
                fetchData()
            } else {
                setErr(null)
                setCategory('')
            }
        } else {
            fetchData()
        }
    }, [showModal, toggleModal, fetchData])

    useEffect(() => {
        if(!selected_division._id) {
            toast.info('A division must be created for editing a lead source category.')
            setRedirect('/system/divisions/create')
        }
    }, [selected_division._id])

    if(redirect) return <Redirect to={redirect} />

    return (
        <Modalify 
            showModal={showModal}
            toggleModal={toggleModal}
            modalTitle={category && category._id ? "Update Lead Source Category" : "Create Lead Source Category"}
            err={err}
            footer={(
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            )}
            sections={!category ? [] :  [
                (
                    <div>

                        <FormGroup>
                            <label className="form-control-label">Name*</label>
                            <Input 
                                type="text"
                                value={category.name || ''}
                                onChange={e => onInputChange('name', e.target.value)}
                            />
                        </FormGroup>
                    </div>
                ),
            ]}
        />
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(MarketingLeadSourceCategoriesEdit);