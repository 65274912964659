import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Card, Col, FormGroup, Input, Row } from "reactstrap";

import api from 'api';

import Modalify from 'components/functional/modals/Modalify';
import Circle from 'components/markup/loading/Circle';
import SearchCollections from 'components/system/Search/Collections';

import { toast } from 'react-toastify';
import { toggleAlertBS } from 'store/functions/system/system';

import ObjectFinder from 'components/system/Objects/Finder';

const baseState = { users: [], workflow_steps: [] }

const DepartmentsEdit = ({_id, onSaved, showModal, toggleModal, selected_division}) => {
    const [department, setDepartment] = useState(null)
    const [err, setErr] = useState(null)
    const [redirect, setRedirect] = useState(null)

    const fetchData = useCallback(async () => {
        if(!_id || _id === 'create') return setDepartment(baseState);
        const data = await api.division_departments.findById(_id)
        if(!data.data) return setErr(true);
        setDepartment(data.data)
    }, [_id])

    const onInputChange = useCallback(( field, value) => {
        const newDepartment = JSON.parse(JSON.stringify(department))
        newDepartment[field] = value;
        setDepartment(newDepartment);
    }, [department])

    const onAddItem = async (type, _id) => {
        const saved = await api.division_departments.userAdd(department._id, { type, user: _id })
        if(!saved.success) return toast.error(`Something's not right, please try again`);
        fetchData()
    }
    
    const onRemoveItem = async (type, _id) => {
        const saved = await api.division_departments.userRemove(department._id, { type, user: _id })
        if(!saved.success) return toast.error(`Something's not right, please try again`);
        fetchData()
    }
    
    const onSave = async (type, _id) => {
        const saved = await api.division_departments.update(department._id, { name: department.name })
        if(!saved.success) return toast.error(`Something's not right, please try again`);
    }

    useEffect(() => {
        fetchData()
    }, [showModal, toggleModal, fetchData])
    
    useEffect(() => {
        if(!selected_division._id) {
            toggleAlertBS('info', 'A division must be created for editing a matter doc.')
            setRedirect('/system/divisions/create')
        }
    }, [selected_division._id])

    if(redirect) return <Redirect to={redirect} />

    return (
        <Modalify 
            showModal={showModal}
            toggleModal={toggleModal}
            modalTitle={department && department._id ? "Update Email Template" : "Create Email Template"}
            err={err}
         
            sections={!department ? [<Circle />] :  [
                (
                    <div>
                        <h2>Department Info</h2>
                        <FormGroup>
                            <label className="form-control-label">Name*</label>
                            <Input 
                                type="text"
                                value={department.name || ''}
                                onChange={e => onInputChange('name', e.target.value)}
                            />
                        </FormGroup>        

                        <div className='text-riht'>
                            <button onClick={onSave} className='btn btn-sm btn-success'><i className="fas fa-save mr-2" /> Update Name</button>
                        </div>
                    </div>
                ),
                (
                    <Row>
                        <Col lg={6}>
                            <div className='bg-secondary border rounded p-4'>
                                <h2 className="mb-3">Add A User</h2>
                                <SearchCollections
                                    hideSelected={true}
                                    collection="users" 
                                    hideTitle={true}
                                    placeholder="Search To Add A User"
                                    value={null}
                                    onChange={(obj) => onAddItem('user', obj.value)}
                                    filter={{
                                        division: selected_division._id
                                    }}
                                />
                            </div>
                            <p className='text-sm mb-0'>***Users SAVE automatically once added or removed. </p>
                        </Col>

                        <Col lg={6}>
                            <div className='bg-secondary border rounded p-4'>
                                <h2 className="mb-3">Add A Supervisor</h2>
                                <SearchCollections
                                    hideSelected={true}
                                    collection="users" 
                                    hideTitle={true}
                                    placeholder="Search To Add A Supervisor"
                                    value={null}
                                    onChange={(obj) => onAddItem('supervisor', obj.value)}
                                    filter={{
                                        division: selected_division._id
                                    }}
                                /> 
                            </div>
                            <p className='text-sm mb-0'>***Supervisors SAVE automatically once added or removed. </p>
                        </Col>
                    </Row>
                ),
                (
                    <div>
                        <h2 className="mb-2">Supervisors</h2>
                        <Card className='card-no-shadow mt-0'>
                            <div className='table-responsive'>
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th>Supervisor</th>
                                            <th className='text-right'>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {department.supervisors.length ? department.supervisors.map(u => (
                                            <tr key={u}>
                                                <td><ObjectFinder collection="users" _id={u} /> </td>
                                                <td className='text-right'>
                                                    <button 
                                                        onClick={(obj) => onRemoveItem('supervisor', u)} 
                                                        className='btn btn-sm btn-danger'
                                                    >
                                                        <i className="fas fa-trash mr-2" /> Remove
                                                    </button>
                                                </td>
                                            </tr>
                                        ))  : (
                                            <tr>
                                                <td><i className="fas fa-times text-danger mr-2" /> No Users Associated</td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </Card>
                    </div>
                ),
                (
                    <div>
                        <h2 className="mb-2">Users</h2>
                        <Card className='card-no-shadow mt-3'>
                            <div className='table-responsive'>
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th>User</th>
                                            <th className='text-right'>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {department.users.length ? department.users.map(u => (
                                            <tr key={u}>
                                                <td><ObjectFinder collection="users" _id={u} /> </td>
                                                <td className='text-right'>
                                                    <button 
                                                        onClick={(obj) => onRemoveItem('user', u)} 
                                                        className='btn btn-sm btn-danger'
                                                    >
                                                        <i className="fas fa-trash mr-2" /> Remove
                                                    </button>
                                                </td>
                                            </tr>
                                        ))  : (
                                            <tr>
                                                <td><i className="fas fa-times text-danger mr-2" /> No Users Associated</td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </Card>
                    </div>
                ),
                                                
            ]}
        />
    )

}

const mapStateToProps = state => {
	return {
        selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(DepartmentsEdit);